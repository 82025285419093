/** @jsxImportSource @emotion/react */
import React from "react";
import tw from "twin.macro";
import {
  QQInventoryList,
  QQLineDetailParams,
  QQRolling,
  QuickQuoteLine,
} from "../../../../types/types";
import QuoteReadOnlyLine from "./quoteReadOnlyLine";
import QuoteEditLine from "./quoteEditLine";
import Modal from "react-modal";
import BookingsModal from "../../stock/prime/rolling/bookings/BookingsModal";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  fetchQuickQuoteLineDetail,
  updateQuickQuoteLineDetail,
} from "../../../../store/document.actions";
import { numberFormatter } from "../../../../helpers/numberFormat";
import Button from "../../../../components/atoms/Button";
import TextButton from "../../../../components/atoms/TextButton";
import {
  clearQuickQuoteLineDetail,
  updateQuickQuoteLineRolling,
  updateQuickQuoteLineStock,
  updateQuickQuoteLineNote
} from "../../../../store/document.reducer";
import TextArea from "../../../../components/atoms/TextArea";
import { stringToBase64 } from "../../../../helpers/stringToBase64";
import { formatDateToShort } from "../../../../helpers/dateHelpers";

type Props = {
  quoteId: number;
  lines?: QuickQuoteLine[];
  editing?: boolean;
  removeLine?: (id: number) => void;
  hideAddRow?: (hide: boolean) => void;
};

const QuoteLines: React.FC<Props> = ({
  quoteId,
  lines = [],
  removeLine,
  hideAddRow,
}) => {
  Modal.setAppElement("#root");
  const [editLineId, setEditLineId] = React.useState<number | null>(null);
  const [editDetailLineId, setEditDetailLineId] = React.useState<number | null>(
    null
  );
  const [detailOpen, setDetailOpen] = React.useState(false);
  const [bookingModalOpen, setBookingModalOpen] = React.useState(false);
  const dispatch = useAppDispatch();
  const currentDetail = useAppSelector(
    (state) => state.document.quickquote.quickQuoteLineDetail
  );
  const currentQuickQuote = useAppSelector(
    (state) => state.document.quickquote.quickQuoteSetup?.quickQuote
  );
  const addingRow = lines.some((line) => String(line.id).startsWith("99"));

  const handleEditClick = (id: number) => {
    setEditLineId(id);
    hideAddRow && hideAddRow(true);
  };
  const handleCancelClick = (id: number) => {
    if (String(id).startsWith("99")) {
      removeLine && removeLine(id);
    }
    setEditLineId(null);
    hideAddRow && hideAddRow(false);
  };
  const handleRemoveClick = (id: number) => {
    removeLine && removeLine(id);
  };
  const handleSaveClick = () => {
    setEditLineId(null);
    hideAddRow && hideAddRow(false);
  };
  const handleEditDetailsClick = (id: number) => {
    dispatch(clearQuickQuoteLineDetail());
    setEditDetailLineId(id);
    dispatch(fetchQuickQuoteLineDetail({ quoteId, lineId: id }));
    setDetailOpen(true);
  };

  const noteChange = (note: string) => {
    dispatch(updateQuickQuoteLineNote(note));
  };

  const stockChange = (item: QQInventoryList, checked: boolean) => {
    const updatedItem = { ...item, selected: checked };
    dispatch(updateQuickQuoteLineStock(updatedItem));
  };

  const rollingChange = (rolling: QQRolling, checked: boolean) => {
    const updatedRolling = { ...rolling, selected: checked };
    dispatch(updateQuickQuoteLineRolling(updatedRolling));
  };

  const saveDetail = () => {
    const updatedRollingsSelected = currentDetail?.rollingSelections?.filter(
      (rolling) => rolling.rollings?.some((r) => r.selected)
    );
    const updatedRollings = updatedRollingsSelected
      ?.map((resourceRolling) =>
        resourceRolling.rollings
          ?.filter((r) => r.selected)
          .map(({ rolling: { rollingCycleId, cycleLinePerSequence } }) => ({
            rollingCycleId,
            cycleLinePerSequence,
          }))
      )
      .flat();

    const updatedStocks = currentDetail?.stockSelections
      ?.filter((stock) => stock.selected)
      .map(
        ({
          inventoryItem: {
            length,
            site,
            itemNumber,
            bundleSize,
            inventoryStatus,
            warehouse,
          },
        }) => ({
          length,
          site,
          itemNumber,
          bundleSize,
          inventoryStatus,
          warehouse,
        })
      );

    const params: QQLineDetailParams = {
      quoteId: quoteId,
      lineId: editDetailLineId || 0,
      rollingSelections: updatedRollings,
      stockSelections: updatedStocks,
      encodedNotes: stringToBase64(currentDetail?.notes),
    };

    if (!editDetailLineId || editDetailLineId === 0) {
      return;
    }

    dispatch(updateQuickQuoteLineDetail(params));

    setDetailOpen(false);
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-40%",
      padding: "0px",
      transform: "translate(-50%, -50%)",
      width: "960px",
      backgroundColor: "rgb(255 255 255)",
      borderLeft: "5px solid rgb(11 99 3)",
      borderBottom: "5px solid rgb(11 99 3)",
      borderRight: "5px solid rgb(11 99 3)",
    },
    overlay: {
      zIndex: 11,
    },
  };

  function openBookingsModal() {
    setBookingModalOpen(true);
  }

  function closeModal() {
    dispatch(clearQuickQuoteLineDetail());
    setDetailOpen(false);
  }
  function closeBookingsModal() {
    setBookingModalOpen(false);
  }

  return (
    <>
      <table className="ntp-portal-table ntp-remove-hover" css={tw`w-full text-xs`}>
        <thead>
          <tr>
            <th>#</th>
            <th>
              Product <span css={tw`text-red-600`}>*</span>
            </th>
            <th>Length</th>
            <th>
              Quantity <span css={tw`text-red-600`}>*</span>
            </th>
            
            {/* <th css={tw`w-[114px]`}>Estimated Ready Date</th> */}
            <th css={tw`w-[114px]`}>Total Pcs</th>
            <th css={tw`w-[114px]`}>Total Lbs</th>
            <th css={tw`w-[114px]`}>Total Ft</th>
            <th css={tw`w-[114px]`}>Due Date</th>
            <th>
              Price <span css={tw`text-red-600`}>*</span>
            </th>
            <th>Total Cost</th>
            <th>Details</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {lines.length === 0 ? (
            <tr>
              <td colSpan={12}>No lines have been entered</td>
            </tr>
          ) : (
            lines.map((line, index) =>
              editLineId === line.id || String(line.id).startsWith("99") ? (
                <QuoteEditLine
                  quoteId={quoteId}
                  key={index}
                  itemkey={index}
                  line={line}
                  onCancelClick={handleCancelClick}
                  onSaveClick={handleSaveClick}
                  currency={currentQuickQuote?.currency}
                />
              ) : (
                <QuoteReadOnlyLine
                  key={index}
                  itemkey={index}
                  line={line}
                  editLineId={editLineId}
                  addingRow={addingRow}
                  onEditClick={handleEditClick}
                  onRemoveClick={handleRemoveClick}
                  onEditDetailsClick={handleEditDetailsClick}
                  currency={currentQuickQuote?.currency}
                />
              )
            )
          )}
          {lines.length !== 0 && (
            <tr>
              <td colSpan={4} css={tw`font-bold text-right`}>Totals</td>
              <td css={tw`font-bold text-right`}>{currentQuickQuote?.formattedTotalPieces}</td>
              <td css={tw`font-bold text-right`}>{currentQuickQuote?.formattedTotalWeight}</td>
              <td css={tw`font-bold text-right`}>{currentQuickQuote?.formattedTotalLengthFt}</td>
              <td colSpan={2}></td>
              <td css={tw`font-bold text-right`}>
                {"" !== currentQuickQuote?.formattedTotalCost ? `${currentQuickQuote?.formattedTotalCost} ${currentQuickQuote?.currency}`: ""}  
              </td>
              <td colSpan={2}></td>
            </tr>
          )}
        </tbody>
      </table>

      <Modal
        isOpen={detailOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div css={tw`font-bold flex items-center pb-1 text-xs text-white bg-[rgb(11 99 3)]`}>
          <h2 css={tw`font-bold pt-1 text-white text-xs flex-1`}>
            Quick Quote Line Details
          </h2>
          <button
            type="button"
            onClick={closeModal}
            css={tw`text-nucor-green bg-[#ccc] h-[13px] w-[13px] flex justify-center items-center font-bold mt-1`}
          >
            &times;
          </button>
        </div>

        <div css={tw`flex justify-between text-xs ml-2 mr-2 bg-[#ffffff]`}>
          <div css={tw`pt-2`}>
            <div>
              <span css={tw`font-bold text-nucor-gray`}>Product: </span>
              <span css={tw`font-bold`}>{currentDetail?.productIndex?.name}</span>
            </div>
            <div>
              <span css={tw`font-bold text-nucor-gray`}>Length: </span>
              <span css={tw`font-bold`}>{currentDetail?.lengthDescription}</span>
            </div>
            <div>
              <span css={tw`font-bold text-nucor-gray`}>Pieces: </span>
              <span css={tw`font-bold`}>{currentDetail?.formattedTotalPieces}</span>
            </div>
            <div css={[tw`text-xs text-nucor-gray flex`]}>
              <span css={[tw`font-bold text-xs text-nucor-gray flex mr-1`]}>Bundle:</span>
              <ul>
                {currentDetail?.bundling?.map(
                  (item, index) => (
                    <li css={tw`text-black text-xs`} key={index}>
                      {item}
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
          <div css={tw`flex mb-4 justify-end space-x-2 pt-2`}>
            <span css={tw`ml-4 font-bold text-nucor-gray`}>Notes:</span>
            <fieldset>
              <TextArea
                rows={3}
                cols={60}
                value={currentDetail?.notes}
                onChange={(e) => noteChange(e.target.value)}
              />
            </fieldset>
          </div>
        </div>
        {currentDetail?.errors && currentDetail?.errors.length > 0 && (
          <div css={tw`text-xs pb-2 ml-3`}>
            <ul css={tw`text-red-600 ml-4 list-disc`}>
              {currentDetail.errors.map((err, index) => (
                <li key={index}>{err}</li>
                ))}
            </ul>
          </div>
        )}
        <div css={tw`flex justify-between space-x-2 bg-[#ffffff] ml-2 text-xs`}>
          <div css={tw`grow`}>
            <div
              css={tw`uppercase text-white font-bold bg-nucor-green text-xs py-1 px-2 mb-2 flex items-center justify-between`}
            >
              <span>Floor Stock </span>
            </div>
            <div css={tw`overflow-x-auto max-h-64`}>
              <table className="ntp-portal-table" css={tw`w-full`}>
                <thead>
                  <tr css={tw`bg-[#e2e2e2] text-center`}>
                    <th>Include</th>
                    <th>Length</th>
                    <th>Mill</th>
                    <th>Lbs. Avail</th>
                    <th>Bdl Wgt</th>
                    <th>Total Pcs</th>
                    <th>Pcs / Bdl</th>
                    <th># of Bdls</th>
                  </tr>
                </thead>
                <tbody>
                  {currentDetail?.stockSelections.length === 0 ? (
                    <tr>
                      <td colSpan={8}>No Inventory Found</td>
                    </tr>
                  ) : (
                    currentDetail?.stockSelections.map((item, index) => (
                      <tr key={index}>
                        <td css={tw`text-center`}>
                          <input
                            type="checkbox"
                            checked={item.selected}
                            onChange={(e) => stockChange(item, e.target.checked)}
                          />
                        </td>
                        <td css={tw`text-right`}>{item?.inventoryItem?.lengthDescription}</td>
                        <td>{item?.inventoryItem?.site}</td>
                        <td css={tw`relative text-right`}>
                          <span className="peer">{numberFormatter(item?.inventoryItem?.totalWeight)}</span>
                          <span css={tw`absolute bg-white px-2 py-1 rounded border shadow-lg right-3 -top-7 text-nucor-gray whitespace-nowrap animate-tooltipdelay hidden peer-hover:block`}>
                            {`${item.inventoryItem.itemNumber} ${item.inventoryItem.inventoryStatus}`}
                          </span>
                        </td>
                        <td css={tw`text-right`}>{numberFormatter(item?.inventoryItem?.bundleWeight)}</td>
                        <td css={tw`text-right`}>
                          {numberFormatter(item?.inventoryItem?.totalPieces)}
                        </td>
                        <td css={tw`text-right`}>{item?.inventoryItem?.bundleSize}</td>
                        <td css={tw`text-right`}>{item?.inventoryItem?.numberOfBundles}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <div css={tw`bg-[#ffffff] mr-2 w-1/4`}>
            <div
              css={tw`uppercase text-white font-bold bg-nucor-green text-xs py-1 px-2 mb-2 flex items-center justify-between mr-2`}
            >
              <span>Rollings</span>
            </div>
            <div css={tw`overflow-x-auto max-h-48 mr-2`}>
              <table className="ntp-portal-table ntp-remove-hover">
                <thead>
                  <tr>
                    <th css={tw`w-[300px]`}>Mill</th>
                  </tr>
                </thead>
                <tbody css={tw`overflow-scroll`}>
                {currentDetail?.rollingSelections.length === 0 ? (
                    <tr>
                      <td colSpan={8}>No rollings Found</td>
                    </tr>
                  ) : (
                    currentDetail?.rollingSelections.map((item, index) => (
                      
                      <tr key={index}>
                        <td>
                          <p>{item.resource}</p>
                          {item.rollings?.map((rolling, index) => (
                            <div key={index} css={[
                              tw`font-bold flex items-center`,
                              rolling.rolling.rollingDisplayColor === "red" && tw`bg-red-300`,
                              rolling.rolling.rollingDisplayColor === "yellow" &&
                                tw`bg-nucor-yellow`,
                            ]}>
                              <span css={tw`w-[14px] ml-[2px]`}>
                                {rolling?.rolling?.prodCreated ? "✓" : ""}
                              </span>
                             
                              <input
                                type="checkbox"
                                checked={rolling?.selected}
                                onChange={(e) =>
                                  rollingChange(rolling, e.target.checked)
                                }
                              />
                              <span css={tw`ml-2`}>{`${formatDateToShort(rolling?.rolling?.startDate)} - ${formatDateToShort(rolling?.rolling?.stopDate)}`}</span>
                            </div>
                          ))}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
            <div>
              <TextButton onClick={openBookingsModal} css={tw`mt-4 text-xs`}>
                View Bookings
              </TextButton>
            </div>
            <div>
              <p css={tw`mt-4 text-xs`}>
                Dates in <span css={tw`bg-red-200 font-bold`}>red</span> are
                <strong> Not Available</strong>. For dates in{" "}
                <span css={tw`bg-nucor-yellow font-bold`}>yellow</span>, contact
                your Account Representative.
              </p>
            </div>
          </div>
        </div>

        <div css={tw`mt-5 flex justify-center items-center bg-[#ffffff] mb-4`}>
          <Button
            onClick={saveDetail}
            disabled={false}
            css={tw`text-xs ml-2 w-16 text-center px-0 py-[2px] font-normal`}
          >
            Save
          </Button>
          <Button
            onClick={closeModal}
            disabled={false}
            css={tw`text-xs ml-2 w-16 text-center px-0 py-[2px] font-normal`}
          >
            Cancel
          </Button>
        </div>
      </Modal>
      <Modal
        isOpen={bookingModalOpen}
        onRequestClose={closeBookingsModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h2>Quick Quote Line Details</h2>

        <BookingsModal
          onCancel={() => setBookingModalOpen(false)}
          rollingData={{
            bookingsRollings: currentDetail?.bookingsRollings || [],
            productIndex: currentDetail?.productIndex,
          }}
        />
      </Modal>
    </>
  );
};

export default QuoteLines;
