import React, { ReactNode } from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../features/app/Login";
import Portal from "../features/portal/Portal";
import { ProtectedRoute } from "./ProtectedRoute";
import RequestAccount from "../features/app/requestNewAccount/RequestAccount";
import Callback from "../components/organisms/Callback";
import ForgotPassword from "../features/app/ForgotPassword";
import Admin from "../features/admin/Admin";
import Error500 from "../features/app/Error500";
import Terms from "../features/app/Terms";
import Privacy from "../features/app/Privacy";
import Restricted from "../features/app/Restricted";
import NotFound from "../features/app/NotFound";
import Forbidden from "../features/app/Forbidden";

type Props = {
  children?: ReactNode;
};
const AppRouter: React.FC<Props> = () => {
  // Callback component is used for Auth0 return after loggining in

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/restricted" element={<Restricted />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/500" element={<Error500 />} />
      <Route path="/forbidden" element={<Forbidden />} />
      <Route path="/login" element={<Login />} />
      <Route path="/login/enter" element={<Login />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/callback" element={<Callback />} />
      <Route path="/portal/*" element={<ProtectedRoute component={Portal} />} />
      <Route
        path="/admin/*"
        element={<ProtectedRoute adminRoute component={Admin} />}
      />
      <Route path="/requestaccount" element={<RequestAccount />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default React.memo(AppRouter);
