/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { ReactNode, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Button from "../../../components/atoms/Button";
import Loading from "../../../components/atoms/Loading";
import Select from "../../../components/atoms/Select";
import {
  getUserPreferences,
  updateUserPreferences,
} from "../../../store/user.actions";
import { RequestStatus } from "../../../types/types";
import { PreferencesFormData, schema } from "./preferencesForm";

type Props = {
  children?: ReactNode;
};

const Preferences: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const userPreferences = useAppSelector(
    (state) => state.user.currentContext?.userPreferences
  );
  const [isLoading, setIsLoading] = useState<RequestStatus | "saving">("idle");
  const siteSelection = useAppSelector((state) => state.app.siteSelections);

  const { register, handleSubmit, control } = useForm<PreferencesFormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      startPage: "HOME",
      defaultPriceUnit: "CWT",
      defaultMill: "ALL",
      ...userPreferences,
    },
  });

  useEffect(() => {
    setIsLoading("pending");
    dispatch(getUserPreferences())
      .unwrap()
      .then(() => setIsLoading("success"))
      .catch(() => setIsLoading("error"));
  }, []);

  const formHandler = handleSubmit(
    (data) => {
      setIsLoading("saving");
      dispatch(updateUserPreferences({ preferences: data }))
        .unwrap()
        .then(() => setIsLoading("success"))
        .catch(() => setIsLoading("error"));
    },
    (error) => {
      console.error(error);
      setIsLoading("error");
    }
  );

  return (
    <div css={[tw`w-[590px]`, { border: "1px solid #ccc" }]}>
      <div
        css={[tw`text-xs font-bold text-white bg-nucor-green text-center py-1`]}
      >
        Preferences
      </div>
      <form
        css={[
          tw`relative`,
          css`
            & fieldset {
              display: flex;
              align-items: center;
              width: 100%;
              border: none;
              margin: 0px;
              margin-left: 8px;
              margin-right: 8px;
              padding: 4px;
              gap: 1rem;
              & label {
                font-size: 12px;
                font-weight: bold;
                color: #6d756e;
                width: 38%;
              }
            }
          `,
        ]}
      >
        {isLoading === "pending" && <Loading>Loading</Loading>}
        {isLoading === "saving" && <Loading>Saving</Loading>}
        <fieldset>
          <label>Home Page:</label>

          <Select
            name="startPage"
            control={control}
            minWidth="20ch"
            data={[
              { value: "HOME", label: "Home" },
              { value: "STOCK", label: "Stock" },
              { value: "MY_SHIPPING", label: "My Shipping" },
              { value: "MY_DOCUMENTS", label: "My Documents" },
            ]}
          />
        </fieldset>

        <fieldset>
          <label>Price Display:</label>
          <Select
            name="defaultPriceUnit"
            control={control}
            data={[
              { value: "CWT", label: "CWT" },
              { value: "CFT", label: "CFT" },
            ]}
            minWidth="9ch"
          />
        </fieldset>

        <fieldset>
          <label>Default Mill:</label>
          <Select
            name="defaultMill"
            control={control}
            data={siteSelection.map((site) => ({
              value: site.key,
              label: site.description,
            }))}
             minWidth="20ch"
          />
        </fieldset>

        <fieldset>
          <label>
            Hide Browse History: <br />
            (Stock Page)
          </label>
          <input {...register("hideBrowseHistory")} type="checkbox" />
        </fieldset>

        <fieldset>
          <label>Default Order Due Dates:</label>
          <input {...register("defaultOrderDueDates")} type="checkbox" />
        </fieldset>

        <fieldset>
          <label>Order Rolling Items by Bundle:</label>
          <input {...register("orderRollingItemsByBundle")} type="checkbox" />
        </fieldset>

        <fieldset>
          <label>Release/Open Orders:</label>
          <Select
            name="sortOrdersByDescription"
            control={control}
            data={[
              { value: true, label: "Sort by Item Description" },
              { value: false, label: "Sort by Part Description" },
            ]}
             minWidth="25ch"
          />
        </fieldset>

        <fieldset>
          <label>
            Confirm Ship To: <br />
            (Shopping Cart Page)
          </label>
          <input {...register("confirmShipToOnSubmit")} type="checkbox" />
        </fieldset>

        <fieldset>
          <label>
            Show Odd Lengths by Default: <br />
            (Stock Page Odd Lengths)
          </label>
          <input {...register("showOddLengths")} type="checkbox" />
        </fieldset>

        <Button
          onClick={formHandler}
          type="button"
          css={[tw`ml-[42%] my-2 text-xs font-semibold py-1`]}
        >
          Save
        </Button>
      </form>
    </div>
  );
};

export default Preferences;
