import * as yup from "yup";
import { DeliveryModeType, Phone } from "../../../../types/types";

export type RequestNewShipToData = {
  contactName: string;
  contactPhone: Phone;
  contactPhoneExtension?: string;
  shipToName: string;
  address1: string;
  address2: string;
  address3: string;
  name: string;
  city: string;
  stateCode: string;
  postalCode: string;
  country: string;
  shipToPhone: Phone;
  shipToPhoneExtension?: string;
  shipToFax?: Phone;
  shipToFaxExtension?: string;
  notes?: string;
};

export const createSchema = (shipMethod?: DeliveryModeType) => {
  return yup.object({
    contactName: yup.string().required("This is a required field"),
    contactPhone: yup.object({
      areaCode: yup
        .string()
        .required("This is a required field")
        .max(3)
        .matches(/^[0-9]{3}$/, {
          message: "This field must contain 3 numbers",
        }),
      exchange: yup
        .string()
        .required("This is a required field")
        .matches(/^[0-9]{3}$/, {
          message: "This field must contain 3 numbers",
        }),
      local4: yup
        .string()
        .required("This is a required field")
        .matches(/^[0-9]{4}$/, {
          message: "This field must contain 4 numbers",
        }),
    }),
    contactPhoneExtension: yup.string().matches(/[0-9]+/, {
      message: "Extension may only contain numbers",
      excludeEmptyString: true,
    }),
    address1: yup.string().required("This is a required field"),
    address2:
      shipMethod === "Rail"
        ? yup.string().required("This is a required field")
        : yup.string(),
    address3:
      shipMethod === "Rail"
        ? yup.string().required("This is a required field")
        : yup.string(),
    city: yup.string().required("This is a required field"),
    stateCode: yup.string().required("This is a required field"),
    postalCode: yup.string().required("This is a required field"),
    country: yup.string().required("This is a required field"),
    shipToName: yup.string().required("This is a required field"),
    shipToPhone: yup.object({
      areaCode: yup
        .string()
        .required("This is a required field")
        .matches(/^[0-9]{3}$/, {
          message: "This field must contain 3 numbers",
        }),
      exchange: yup
        .string()
        .required("This is a required field")
        .matches(/^[0-9]{3}$/, {
          message: "This field must contain 3 numbers",
        }),
      local4: yup
        .string()
        .required("This is a required field")
        .matches(/^[0-9]{4}$/, {
          message: "This field must contain 4 numbers",
        }),
    }),
    shipToPhoneExtension: yup.string().matches(/[0-9]+/, {
      message: "Extension may only contain numbers",
      excludeEmptyString: true,
    }),
    shipToFax: yup
      .object({
        areaCode: yup.string().matches(/^[0-9]{3}$/, {
          message: "This field must contain 3 numbers",
          excludeEmptyString: true,
        }),
        exchange: yup.string().matches(/^[0-9]{3}$/, {
          message: "This field must contain 3 numbers",
          excludeEmptyString: true,
        }),
        local4: yup.string().matches(/^[0-9]{4}$/, {
          message: "This field must contain 4 numbers",
          excludeEmptyString: true,
        }),
      })
      .optional(),
    shipToFaxExtension: yup.string().matches(/[0-9]+/, {
      message: "Extension may only contain numbers",
      excludeEmptyString: true,
    }),
    notes: yup.string(),
  });
};
