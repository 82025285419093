/** @jsxImportSource @emotion/react */
import React, { MouseEvent, ReactNode, useState } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useClickOutside } from "../../hooks/useClickOutside";
import { setCurrentCustomerDetails } from "../../store/customer.actions";
import ChangeShipToDialog from "../../features/portal/stock/prime/inventory/ChangeShipToDialog";
import { useLocation, useNavigate } from "react-router-dom";
import CustomerDisplay from './CustomerDisplay';
import { selectCurrentCustomerId } from "../../store/customer.reducer";

type Props = {
  children?: ReactNode;
  isStockPage: boolean;
};

const ExternalCustomerList: React.FC<Props> = ({ isStockPage }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const customers = useAppSelector(
    (state) => state.user.currentContext?.bookOfBusiness.customers
  );
  const currentCustomer = useAppSelector(
    (state) => state.customer.currentCustomer
  );
  const [requestedCustomer, setRequestedCustomer] = useState("");
  const [showCustomerList, setShowCustomerList] = useState(false);
  const ref = useClickOutside<HTMLUListElement>(() => {
    if (showCustomerList) {
      setShowCustomerList(false);
    }
  });
  
  const selectedCustomerId = useAppSelector(selectCurrentCustomerId);

  const changeCustomerHandler = (id?: string) => {
    if (!id || id === selectedCustomerId) return;
    if (isStockPage) {
      setRequestedCustomer(id);
    } else {
      dispatch(setCurrentCustomerDetails(id));
      navigate({ pathname: location.pathname, search: "?customer_id=" + id }, { replace: true });
    }
  };

  return (
    <>
      {requestedCustomer !== "" && (
        <ChangeShipToDialog
          onConfirm={() => {
            const id = requestedCustomer;
            dispatch(setCurrentCustomerDetails(requestedCustomer));
            setRequestedCustomer("");
            navigate({ pathname: location.pathname, search: "?customer_id=" + id }, { replace: true });
          }}
          onCancel={() => setRequestedCustomer("")}
        />
      )}
      {customers && customers.length === 0 && <p>No customers found</p>}
      {customers && customers.length > 0 && (
        <div css={tw`text-xs w-full flex items-center justify-end`}>
          <button
            css={tw`max-w-[calc(100% - 10px)] text-left flex gap-2 border-b border-b-nucor-light-green bg-transparent border-0 hover:(border-b-white)
              focus-visible:outline-1 focus-visible:outline-dotted focus-visible:outline-offset-2 focus-visible:outline-nucor-light-gray focus:outline-none`}
            type="button"
            onClick={(e: MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              setShowCustomerList((prev) => !prev);
            }}
          >
            <CustomerDisplay currentCustomer={currentCustomer}/>
          </button>
        </div>
      )}
      {showCustomerList && (
        <ul
          ref={ref}
          css={[
            tw`absolute right-0 top-[100%] bg-nucor-light-green p-3 list-none flex flex-col text-xs tracking-tighter z-50`,
          ]}
        >
          {customers?.map((item, index) => (
            <li
              key={index}
            >
              <button type="button" css={[
                tw`text-left w-full hover:(bg-gradient-to-b from-nucor-green to-[#0b632533] rounded cursor-pointer) focus-visible:(bg-gradient-to-b from-nucor-green to-[#0b632533] rounded) focus-visible:outline-none`,
                item?.id === currentCustomer?.id && tw`bg-gradient-to-b from-nucor-green to-[#0b632533] rounded cursor-pointer`,
                {
                  borderBottom: "1px solid #ffffff20",
                  padding: "8px",
                },
                ]}onClick={() => {
                  changeCustomerHandler(item.id);
                  setShowCustomerList(false);
                }}
              >
                <div css={tw`whitespace-nowrap`}>
                  {item.classification ?
                    <>{`${item.id} (${item.classification}) - ${item.name}`}</>
                    :
                    <>{`${item.id} - ${item.name}`}</>
                  }
                </div>
                <div>
                  {" "}
                  {item?.city}, {item?.state} {`${item?.phoneNumber ? "|" : ""} ${item?.phoneNumber ?? ""}`}
                </div>
              </button>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default ExternalCustomerList;
