/** @jsxImportSource @emotion/react */
import React, { ReactNode, useContext } from "react";
import tw from "twin.macro";
import OpenLoadsResults from "./OpenLoadsResults";
import PDFEmbed from "../../../../components/molecules/PDFEmbed";
import { PDFContext } from "./OpenLoads";
import TextButton from "../../../../components/atoms/TextButton";

type Props = {
  children?: ReactNode;
};

const OpenLoadsContent: React.FC<Props> = () => {
  const ctx = useContext(PDFContext);

  return (
    <div>
      <div css={tw`flex justify-between text-white font-bold bg-nucor-green text-xs py-1 px-2`}>
        <span css={tw`uppercase w-32`}>Open Loads</span>
        {ctx?.file && (
          <TextButton onClick={() => ctx?.setFile(null)} css={tw`text-white`}>
          Back to List
          </TextButton>
        )}
        <span className="w-32"></span>
      </div>
      <div css={[ctx?.file && tw`hidden`]}>
        <OpenLoadsResults />
      </div>
      {ctx?.file && (
        <PDFEmbed
          setFile={ctx?.setFile}
          file={ctx?.file}
          onCancel={() => ctx?.setFile(null)}
        />
      )}
    </div>
  );
};

export default OpenLoadsContent;
