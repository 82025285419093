/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";
import tw from "twin.macro";
import { useAppSelector } from "../../app/hooks";
import { numberFormatter } from "../../helpers/numberFormat";
import { selectCartSummary } from "../../store/shoppingCart.reducer";
import Sprites from "../atoms/Sprites";

type Props = {
  children?: ReactNode;
};

const ShoppingCartLink: React.FC<Props> = () => {
  const location = useLocation();
  const cartSummary = useAppSelector(selectCartSummary);

  return (
    <div css={tw`font-bold flex items-center`}>
      <Link
        css={[
          tw`text-xs text-nucor-link underline hover:text-nucor-link-hover hover:no-underline flex items-center focus:text-nucor-link-hover focus:outline-none`,
        ]}
        to={{
          pathname: "/portal/stock/cart/shopping",
          search: location.search,
        }}
      >
        <Sprites icon="cart" css={tw`mr-1`} />
        {`Shopping Cart: ${cartSummary?.totalOrderItems ?? 0} ${
          cartSummary?.totalOrderItems === 1 ? "Item" : "Items"
        }`}
      </Link>
      {cartSummary && cartSummary.totalOrderWeight > 0 ? (
        <span css={tw`text-xs ml-1 text-red-600`}>
          {`${numberFormatter(cartSummary.totalOrderWeight)} lbs`}
        </span>
      ) : null}
    </div>
  );
};

export default ShoppingCartLink;
