/** @jsxImportSource @emotion/react */
import React, {
  ReactNode,
  useContext,
  useState,
} from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Button from "../../../../components/atoms/Button";
import Input from "../../../../components/atoms/Input";
import Select from "../../../../components/atoms/Select";
import LengthComponent from "../../../../components/molecules/LengthComponent";
import { selectCurrentCustomerId } from "../../../../store/customer.reducer";
import {
  clearDocumentErrors,
  selectOpenLoadsSelectionData,
} from "../../../../store/document.reducer";
import { ProductSelectionDetails } from "../../../../types/types";
import DimensionsSelectComponent from "../components/DimensionsSelectComponent";
import { searchOpenLoads } from "../../../../store/document.actions";
import ComboBox from "../../../../components/atoms/ComboBox";
import Toast from "../../../../components/molecules/Toast";
import { PDFContext } from "./OpenLoads";
import { lengthToInches } from "../../../../helpers/lengthHelpers";
import { searchParameterExists } from "../../../../helpers/searchParameterExists";

type Props = {
  children?: ReactNode;
};

const OpenLoadsSearch: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const customerId = useAppSelector(selectCurrentCustomerId);
  const openLoadsSelectionData = useAppSelector(selectOpenLoadsSelectionData);
  const { error } = useAppSelector(
    (state) => state.document.openLoads
  );
  const ctx = useContext(PDFContext);

  const [customerSearchType, setCustomerSearchType] = useState(false);
  const [customerPONumber, setCustomerPONumber] = useState("");
  const [salesOrderNumber, setSalesOrderNumber] = useState("");
  const [customerPart, setCustomerPart] = useState("");
  const [releaseProBill, setReleaseProBill] = useState("");
  const [loadId, setLoadId] = useState("");
  const [dimensions, setDimensions] = useState<ProductSelectionDetails>({
    grade: "",
    shape: "Unknown",
    height: 0,
    width: 0,
    wall: 0,
  });
  const [length, setLength] = useState({
    feet: 0,
    inches: 0,
    fraction: 0,
  });

  const lengthInInches = lengthToInches(length);

  const criteria = {
    myCustomers: customerSearchType,
    loadId: searchParameterExists(loadId) ? loadId : undefined,
    customerPONumber: searchParameterExists(customerPONumber) ? customerPONumber : undefined,
    salesOrderNumber: searchParameterExists(salesOrderNumber) ? salesOrderNumber : undefined,
    customerPartNumber: searchParameterExists(customerPart) ? customerPart : undefined,
    proBill: searchParameterExists(releaseProBill) ? releaseProBill : undefined,
    length: lengthInInches === 0 ? undefined : lengthInInches,
    grade: dimensions?.grade === "" ? undefined : dimensions?.grade,
    shape: dimensions?.shape === "Unknown" ? undefined : dimensions?.shape,
    height: dimensions?.height === 0 ? undefined : dimensions?.height,
    width: dimensions?.width === 0 ? undefined : dimensions?.width,
    decimalWallThickness: dimensions?.wall === 0 ? undefined : dimensions?.wall,
  };

  const resetForm = () => {
    setCustomerSearchType(false);
    setCustomerPONumber("");
    setSalesOrderNumber("");
    setCustomerPart("");
    setReleaseProBill("");
    setLoadId("");
    setDimensions({
      grade: "",
      shape: "Unknown",
      height: 0,
      width: 0,
      wall: 0,
    });
    setLength({
      feet: 0,
      inches: 0,
      fraction: 0,
    });
  };

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Close pdf if open
    if (ctx?.file) {
      ctx.setFile(null);
    }

    dispatch(searchOpenLoads({customerId, criteria: { ...criteria}}));
  };

  const customerPartsList =
    openLoadsSelectionData?.customerParts.map((item) => ({
      value: item.customerPartNumber,
      label: (
        <span>
          {item.customerPartNumber}
          <span css={tw`ml-4`}>{item.displayDescription}</span>
        </span>
      ),
    })) ?? [];

  return openLoadsSelectionData ? (
    <>
      {error && (
        <Toast
          type="error"
          message={error}
          onConfirm={() => dispatch(clearDocumentErrors())}
        />
      )}
      <p css={tw`text-nucor-gray mt-2`}>Enter any combination:</p>
      <form onSubmit={submitHandler} css={tw`flex w-full flex-col`}>
        <fieldset css={tw`mt-2`}>
          <label css={tw`block`}>Show</label>
          <Select
            name="customerSearchType"
            minWidth="22ch"
            value={customerSearchType}
            onChange={setCustomerSearchType}
            data={openLoadsSelectionData?.customerSearchType.map((item) => ({
              value: item.id,
              label: item.description,
            }))}
          />
        </fieldset>
        <fieldset css={tw`mt-2`}>
          <label htmlFor="loadId" css={tw`block`}>Load ID #</label>
          <Input
            name="loadId"
            value={loadId}
            onChange={(e) => setLoadId(e.target.value)}
            css={tw`block w-[22ch]`}
          />
        </fieldset>
        <fieldset css={tw`mt-2`}>
          <label htmlFor="customerPO" css={tw`block`}>Customer PO #</label>
          <Input
            name="customerPONumber"
            value={customerPONumber}
            onChange={(e) => setCustomerPONumber(e.target.value)}
            css={tw`block w-[22ch]`}
          />
        </fieldset>
        <fieldset css={tw`mt-2`}>
          <label htmlFor="salesOrder" css={tw`block`}>Sales Order #</label>
          <Input
            name="salesOrderNumber"
            value={salesOrderNumber}
            onChange={(e) => setSalesOrderNumber(e.target.value)}
            css={tw`block w-[22ch]`}
          />
        </fieldset>
        {customerPartsList.length > 0 && (
          <fieldset css={tw`mt-2 w-[80%]`}>
            <label css={tw`block`}>Customer Part #</label>
            <ComboBox
              name="customerPartNumber"
              value={customerPart}
              onChange={setCustomerPart}
              data={customerPartsList}
            />
          </fieldset>
        )}
        <fieldset css={tw`mt-2`}>
          <label htmlFor="releaseProBill" css={tw`block`}>Release/Pro Bill</label>
          <Input
            name="releaseProBill"
            value={releaseProBill}
            onChange={(e) => setReleaseProBill(e.target.value)}
            css={tw`block w-[22ch]`}
          />
        </fieldset>
        <LengthComponent
          value={length}
          css={tw`mt-2`}
          onLengthChange={(len) => setLength(len)}
        />
        <DimensionsSelectComponent
          value={dimensions}
          onChange={(dim) => setDimensions(dim)}
        />
        <div className="w-full text-right">
          <Button css={tw`mx-4 py-[2px] text-xs`} onClick={resetForm}>
            Clear
          </Button>
          <Button css={tw`m-0 py-[2px] font-normal text-xs mt-3`} type="submit">
            Search
          </Button>
        </div>
      </form>
    </>
  ) : null;
};

export default OpenLoadsSearch;
