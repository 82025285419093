export type ObservableFunction = (len: number) => void;

let observers: Array<ObservableFunction> = [];

export default Object.freeze({
  notify: (len: number) => observers.forEach((obs) => obs(len)),
  subscribe: (func: ObservableFunction) => {
    observers.push(func);
  },
  unsubscribe: (func: ObservableFunction) => {
    observers = observers.filter((obs) => obs !== func);
  },
});
