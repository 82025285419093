/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import tw from "twin.macro";
import BillsOfLadingResults from "./BillsOfLadingResults";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { selectCurrentCustomerId } from "../../../../store/customer.reducer";
import { exportPackingSlipExcel } from "../../../../store/document.actions";
import { ErrorResponse, RequestStatus } from "../../../../types/types";
import Toast from "../../../../components/molecules/Toast";
import AnimatedLoadingSpinner from "../../../../components/atoms/AnimatedLoadingSpinner";
import TextButton from "../../../../components/atoms/TextButton";
import PDFEmbed from "../../../../components/molecules/PDFEmbed";
import { PDFContext } from "./BillsOfLading";

type Props = {
  children?: ReactNode;
};

const BillsOfLadingContent: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const customerId = useAppSelector(selectCurrentCustomerId);
  const { searchCriteria } = useAppSelector(
    (state) => state.document.packingSlip
  );
  const ctx = React.useContext(PDFContext);

  const [status, setStatus] = React.useState<RequestStatus>("idle");
  const [error, setError] = React.useState<string | ErrorResponse>();

  const excelHandler = async () => {
    try {
      if (!customerId) return;
      setStatus("pending");
      await dispatch(
        exportPackingSlipExcel({
          customerId,
          criteria: {
            myCustomers: searchCriteria.myCustomers,
            shipToCustomerId: searchCriteria.shipToCustomerId,
            deliveryMode: searchCriteria.deliveryMode,
            dateRangeType: searchCriteria.dateRangeType ?? "last30Days",
            sites: searchCriteria.sites,
          },
        })
      ).unwrap();
      setStatus("idle");
    } catch (errors) {
      setError(error);
      setStatus("idle");
    }
  };

  return (
    <div>
      {error && (
        <Toast
          message={error}
          type="error"
          onConfirm={() => setError(undefined)}
        />
      )}
      <div css={tw`text-white font-bold bg-nucor-green text-xs flex justify-between py-1 px-2 mb-2`}>
        <span className="uppercase w-56">Bills of Lading</span>
          {ctx?.file && (
            <TextButton onClick={() => ctx?.setFile(null)} css={tw`text-white`}>
              Back to List
            </TextButton>
          )}
        
        <div css={tw`flex w-56 justify-end`}>
          {status === "pending" && (
            <AnimatedLoadingSpinner colorStyle="light" message="" className="mr-2"/>
          )}
          <TextButton disabled={status === "pending"} onClick={excelHandler} css={status !== "pending" && tw`text-white`}>
            Shipment Summary Report
          </TextButton>
        </div>
      </div>
      <div css={[ctx?.file && tw`hidden`]}>
        <BillsOfLadingResults />
      </div>
      {ctx?.file && (
        <PDFEmbed
          setFile={ctx?.setFile}
          file={ctx?.file}
          onCancel={() => ctx?.setFile(null)}
        />
      )}
    </div>
  );
};

export default BillsOfLadingContent;
