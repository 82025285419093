/** @jsxImportSource @emotion/react */
import React, { ReactNode, useContext, useEffect } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import ButtonSelect from "../../../../components/atoms/ButtonSelect";
import Select from "../../../../components/atoms/Select";
import TabGroupSubGroup from "../../../../components/atoms/TabGroupSubGroup";
import { selectCurrentCustomerId } from "../../../../store/customer.reducer";
import {
  clearDocumentErrors,
  selectTestCertDocumentsSelectionData,
  updateTestCertSearchCriteria,
} from "../../../../store/document.reducer";
import { selectDefaultMill } from "../../../../store/user.reducer";
import { searchTestCertDocument } from "../../../../store/document.actions";
import Toast from "../../../../components/molecules/Toast";
import { PDFContext } from "./CertsList";
import { SearchCriteria } from "../../../../types/types";

type TestCertBrowseCriteria = {
  myCustomers?: boolean;
  shipToCustomerId?: string;
  dateRangeType?: string;
  siteKey?: string;
  sites?: string[];
};

type Props = {
  children?: ReactNode;
};

const CertsBrowse: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const customerId = useAppSelector(selectCurrentCustomerId);
  const defaultMill = useAppSelector(selectDefaultMill);
  const testCertDocumentsSelectionData = useAppSelector(
    selectTestCertDocumentsSelectionData
  );
  const { searchCriteria, error } = useAppSelector(
    (state) => state.document.testCert
  );
  const ctx = useContext(PDFContext);


  const getSites = (siteKey: string | undefined) : string[] | undefined => {
    if (!siteKey || !testCertDocumentsSelectionData) {
      return undefined;
    }
    return siteKey === "ALL" ? undefined : testCertDocumentsSelectionData.sites.find((item) => item.key === siteKey)?.sites;
  };

  const getBrowseCriteria = () : TestCertBrowseCriteria  => {
    const myCustomers = searchCriteria.myCustomers ?? false;
    const shipToCustomerId = searchCriteria.shipToCustomerId ?? "all";
    const siteKey = searchCriteria.siteKey ?? defaultMill ?? "ALL"
    const dateRangeType = searchCriteria.dateRangeType ?? "last30Days"
  
    const criteria = {myCustomers, shipToCustomerId, siteKey, dateRangeType};
  
    return criteria;
  };

  const getQueryCriteria = (browseCriteria: TestCertBrowseCriteria) : SearchCriteria => {
    const {myCustomers, shipToCustomerId, siteKey, dateRangeType} = browseCriteria;
    const criteria = {
      ...searchCriteria,
      myCustomers: myCustomers,
      shipToCustomerId:
        shipToCustomerId === "all" || myCustomers
          ? undefined
          : shipToCustomerId,
      dateRangeType,
      siteKey: siteKey,
      sites: getSites(siteKey)
    };

    return criteria;
  };

  const browseCriteria = getBrowseCriteria();
  const {myCustomers, shipToCustomerId, siteKey, dateRangeType} = browseCriteria;
  const criteria = getQueryCriteria(browseCriteria);

  const shipTos =
    testCertDocumentsSelectionData?.shipTos.map((shipTo) => {
      return {
        value: shipTo.id,
        label: <span>{`${shipTo.id} - ${shipTo.name}`}</span>,
      };
    }) ?? [];

    
  const search = (criteria: SearchCriteria) => {
    dispatch(updateTestCertSearchCriteria({ ...criteria, page: 1}));
    dispatch(searchTestCertDocument({customerId, criteria: { ...criteria, siteKey: undefined }}));
  };

  useEffect(() => {
    if (testCertDocumentsSelectionData) {
      const criteria = getQueryCriteria(getBrowseCriteria());
      search(criteria);
    }
  }, [testCertDocumentsSelectionData]);
  
  const onSelectionsChanged = (criteria: TestCertBrowseCriteria) => {
    // Close pdf if open
    if (ctx?.file) {
      ctx?.setFile(null);
    }
    search(criteria);
  };

  return (
    <>
      {error && (
        <Toast
          type="error"
          message={error}
          onConfirm={() => dispatch(clearDocumentErrors())}
        />
      )}

      <TabGroupSubGroup title="Show">
        <Select
          minWidth="22ch"
          name="showBy"
          data={testCertDocumentsSelectionData?.customerSearchType.map(
            (item) => ({
              value: item.id,
              label: item.description,
            })
          )}
          onChange={(value: boolean) => {
            onSelectionsChanged({
              ...criteria,
              myCustomers: value,
            });
          }}
          value={myCustomers}
        />
      </TabGroupSubGroup>
      {!myCustomers && (
        <TabGroupSubGroup title="By Ship To">
          <Select
            minWidth="22ch"
            maxWidth="22ch"
            name="byShipTo"
            data={[{ value: "all", label: "All" }, ...shipTos]}
            onChange={(value: string) => {
              onSelectionsChanged({
                ...criteria,
                shipToCustomerId: value === "all" ? undefined : value,
              });
            }}
            value={shipToCustomerId}
          />
        </TabGroupSubGroup>
      )}
      <TabGroupSubGroup title="By Certificate Date">
        {testCertDocumentsSelectionData?.dateSelections.map((date, index) => (
          <ButtonSelect
            key={index}
            onClick={() => {
              onSelectionsChanged({
                ...criteria,
                dateRangeType: date.id,
              });
            }}
            isActive={dateRangeType === date.id}
          >
            {date.description}
          </ButtonSelect>
        ))}
      </TabGroupSubGroup>
      <TabGroupSubGroup title="By Mill">
        <ButtonSelect
          isActive={siteKey === "ALL"}
          onClick={() => {
            onSelectionsChanged({
              ...criteria,
              siteKey: "ALL",
              sites: undefined,
            });
          }}
          css={tw`block`}
        >
          All
        </ButtonSelect>
        {testCertDocumentsSelectionData?.sites.map((item, index) => (
          <ButtonSelect
            key={index}
            isActive={siteKey === item.key}
            onClick={() => {
              onSelectionsChanged({
                ...criteria,
                siteKey: item.key,
                sites: getSites(item.key),
              });
            }}
          >
            {item.description}
          </ButtonSelect>
        ))}
      </TabGroupSubGroup>
    </>
  );
};

export default CertsBrowse;
