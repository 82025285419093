/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Button from "../../../../components/atoms/Button";
import Input from "../../../../components/atoms/Input";
import Select from "../../../../components/atoms/Select";
import PhoneComponent from "../../../../components/molecules/PhoneComponent";
import {
  ClaimsInput,
  ErrorResponse,
  PackingSlipDataForClaim,
  Phone,
  RequestStatus,
} from "../../../../types/types";
import {
  fetchClaimsDocumentsSetup,
  searchClaimsPackingSlipData,
  submitClaimForm,
} from "../../../../store/document.actions";
import { selectClaimsDocumentsSelectionData } from "../../../../store/document.reducer";
import AnimatedLoadingSpinner from "../../../../components/atoms/AnimatedLoadingSpinner";
import { numberFormatter } from "../../../../helpers/numberFormat";
import CreateClaimFormConfirmation from "./CreateClaimFormConfirmation";
import ErrorDisplay from "../../../../components/molecules/ErrorDisplay";

type Props = {
  children?: ReactNode;
};

const CreateClaimForm: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const currentCustomer = useAppSelector(
    (state) => state.customer.currentCustomer
  );
  const user = useAppSelector((state) => state.user.currentContext?.user);
  const selectionData = useAppSelector(selectClaimsDocumentsSelectionData);
  const defaultPhone = {
    ...user?.officePhone,
    extension: user?.officePhoneExt ?? "",
  };

  const [contact, setContact] = useState(
    (user && `${user?.firstName} ${user?.lastName}`) ?? ""
  );
  const [phone, setPhone] = useState<
    (Partial<Phone> & { extension?: string }) | undefined
  >(defaultPhone);
  const [site, setSite] = useState("");
  const [notes, setNotes] = useState("");
  const [disposition, setDisposition] = useState("");
  const [bolNumber, setBolNumber] = useState("");
  const [item, setItem] = useState("");
  const [entireBOL, setEntireBOL] = useState(false);
  const [contactEmail, setContactEmail] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [searchError, setSearchError] = useState("");
  const [searchStatus, setSearchStatus] = useState<RequestStatus>("idle");
  const [submitStatus, setSubmitStatus] = useState<RequestStatus>("idle");
  const [formSubmission, setFormSubmission] = useState<ClaimsInput>();
  const [errors, setErrors] = useState<ErrorResponse | string>();
  const [searchResults, setSearchResults] = useState<
    PackingSlipDataForClaim & { entireBOL?: boolean }
  >();

  const phoneRef = useRef<React.ElementRef<typeof PhoneComponent>>(null);

  const formStyles = css`
    & fieldset label {
      font-weight: bold;
      color: #6c757d;
    }
  `;

  const phoneValid =
    phone?.areaCode?.length === 3 &&
    phone?.exchange?.length === 3 &&
    phone.local4?.length === 4;

  const isValid =
    currentCustomer?.id &&
    contactEmail &&
    contact &&
    phoneValid &&
    disposition &&
    notes &&
    site &&
    searchResults;

  const sitesList =
    selectionData?.sites.map((site) => ({
      value: site.id,
      label: site.description,
    })) ?? [];

  const dispositionList =
    selectionData?.materialDispositions.map((disp) => ({
      value: disp.id,
      label: disp.description,
    })) ?? [];

  useEffect(() => {
    if (currentCustomer?.id)
      dispatch(fetchClaimsDocumentsSetup(currentCustomer.id));
  }, [currentCustomer]);

  const resetHandler = () => {
    setSite("");
    setNotes("");
    setDisposition("");
    setBolNumber("");
    setItem("");
    setContactEmail("");
    setEntireBOL(false);
    setSearchResults(undefined);
    setSearchError("");
    setErrors(undefined);
  };

  const submitHandler = () => {
    if (!isValid) return;
    const body: ClaimsInput = {
      contactName: contact,
      contactPhone: {
        areaCode: phone.areaCode ?? "",
        exchange: phone.exchange ?? "",
        local4: phone.local4 ?? "",
      },
      contactEmail: contactEmail,
      contactPhoneExtension: phone.extension ?? "",
      site: site,
      description: notes,
      materialDisposition: disposition,
      packingSlipNumber: bolNumber,
      packingSlipItem: +item,
      invoiceNumber: invoiceNumber === "" ? undefined : invoiceNumber,
    };
    setSubmitStatus("pending");
    dispatch(
      submitClaimForm({
        customerId: currentCustomer.id,
        body,
      })
    )
      .unwrap()
      .then((result) => {
        resetHandler();
        setSubmitStatus("idle");
        setFormSubmission(result);
      })
      .catch((error) => {
        setErrors(error);
        setSubmitStatus("idle");
      });
  };

  const searchPackingSlipDataHandler = () => {
    if (!currentCustomer?.id) return;
    setSearchStatus("pending");
    setInvoiceNumber("");
    setSearchError("");
    setSearchResults(undefined);
    dispatch(
      searchClaimsPackingSlipData({
        customerId: currentCustomer.id,
        body: {
          packingSlipNumber: bolNumber,
          line: entireBOL ? undefined : +item,
        },
      })
    )
      .unwrap()
      .then((result) => {
        setSearchStatus("success");
        if (Object.keys(result).length === 0) {
          setSearchResults(undefined);
        } else {
          setSearchResults({ ...result, entireBOL });
        }
      })
      .catch((error) => {
        setSearchResults(undefined);
        setSearchStatus("error");
        setSearchError(error);
      });
  };

  const invoiceNumberResults =
    searchResults?.invoiceNumbers?.map((invoice) => ({
      value: invoice,
      label: invoice,
    })) ?? [];

  if (formSubmission) {
    return (
      <div css={tw`w-full p-2`}>
        <CreateClaimFormConfirmation
          formSubmission={formSubmission}
          onConfirm={() => setFormSubmission(undefined)}
        />
      </div>
    );
  }

  return (
    <div css={tw`w-full p-2`}>
      <div
        css={tw`bg-nucor-green font-bold text-white px-2 py-1 uppercase text-xs`}
      >
        Claim
      </div>
      {
        <div>
          <ErrorDisplay error={errors} />
          <p css={tw`italic text-xs mt-2`}>
            <span css={tw`text-red-600`}>*</span> indicates required fields
          </p>

          <form css={[tw`mt-2 text-xs flex flex-col gap-2`, formStyles]}>
            <fieldset>
              <label css={tw`inline-block w-40`}>
                Customer <span css={tw`text-red-600`}>*</span>
              </label>
              <span css={tw`font-bold`}>{currentCustomer?.name}</span>
            </fieldset>
            <fieldset>
              <label css={tw`inline-block w-40`}>
                Customer Contact <span css={tw`text-red-600`}>*</span>
              </label>
              <Input
                value={contact}
                onChange={(e) => setContact(e.target.value)}
                css={tw`bg-nucor-yellow`}
              />
            </fieldset>
            <fieldset css={tw`flex items-center`}>
              <label css={tw`w-40`}>
                Phone <span css={tw`text-red-600`}>*</span>
              </label>
              <PhoneComponent
                defaultValue={defaultPhone}
                ref={phoneRef}
                highlight
                onChange={(value) => setPhone(value)}
              />
            </fieldset>
            <fieldset>
              <label css={tw`inline-block w-40`}>
                Email <span css={tw`text-red-600`}>*</span>
              </label>
              <Input
                type="email"
                value={contactEmail}
                onChange={(e) => setContactEmail(e.target.value)}
                css={tw`bg-nucor-yellow w-[32ch]`}
              />
            </fieldset>
            <fieldset>
              <label css={tw`inline-block w-40`}>
                Division <span css={tw`text-red-600`}>*</span>
              </label>
              <Select
                name="division"
                minWidth="20ch"
                value={site}
                highlight
                onChange={(value: string) => setSite(value)}
                data={[{ value: "", label: "Select" }, ...sitesList]}
              />
            </fieldset>
            <fieldset css={tw`flex`}>
              <label css={tw`inline-block w-40`} htmlFor="notes">
                Description <span css={tw`text-red-600`}>*</span>
              </label>
              <div css={tw`w-full max-w-3xl`}>
                <textarea
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  css={tw`w-full border border-[#bbb] h-20 resize-none bg-nucor-yellow resize-none outline-none focus-within:shadow focus-within:border-nucor-focus-border p-1`}
                />
                <p>
                  In your detailed description of the issue include PO#, Size
                  and Number of pieces.
                </p>
                <p>
                  Attach any relevant documentation including photos of material
                  and Tag # if available.
                </p>
              </div>
            </fieldset>
            <fieldset css={tw`flex`}>
              <label css={tw`w-40`}>
                Material Disposition <span css={tw`text-red-600`}>*</span>
              </label>
              <Select
                name="disposition"
                minWidth="27ch"
                highlight
                value={disposition}
                data={[{ value: "", label: "Select" }, ...dispositionList]}
                onChange={(value: string) => setDisposition(value)}
              />
            </fieldset>
            <div css={tw`flex items-center`}>
              <label css={tw`text-nucor-gray w-40 font-semibold`}>
                BOL Search
              </label>
              <div css={tw`flex items-end gap-4`}>
                <fieldset>
                  <label css={tw`block font-semibold`}>
                    Number <span css={tw`text-red-600`}>*</span>
                  </label>
                  <Input
                    name="bolNumber"
                    css={tw`w-[20ch] bg-nucor-yellow`}
                    value={bolNumber}
                    onChange={(e) => setBolNumber(e.target.value)}
                  />
                </fieldset>
                <fieldset>
                  <label css={tw`block`}>Item</label>
                  <Input
                    name="item"
                    css={tw`w-[7ch]`}
                    value={item}
                    disabled={entireBOL}
                    onChange={(e) => {
                      if (!/^\d*$/.test(e.target.value)) return;
                      setItem(e.target.value);
                    }}
                  />
                </fieldset>
                <fieldset css={tw`flex items-center mb-1`}>
                  <input
                    id="entireBOL"
                    type="checkbox"
                    checked={entireBOL}
                    onChange={(e) => {
                      setItem("");
                      setEntireBOL(e.target.checked);
                    }}
                  />
                  <label
                    htmlFor="entireBOL"
                    css={tw`ml-2 text-black! font-normal!`}
                  >
                    Applies to entire BOL
                  </label>
                </fieldset>
                <Button
                  onClick={searchPackingSlipDataHandler}
                  disabled={bolNumber === "" || (!item && !entireBOL)}
                >
                  Search BOL
                </Button>
              </div>
            </div>
            <div css={tw`flex`}>
              <label css={tw`w-40 font-semibold text-nucor-gray`}>
                BOL Detail
              </label>
              {searchStatus === "pending" && <AnimatedLoadingSpinner />}
              {searchStatus === "idle" && (
                <p>
                  Use the criteria above to search for bill of lading detail
                  (Item or Applies to entire BOL required)
                </p>
              )}
              {searchStatus === "success" && !searchResults && (
                <p>No bill of lading found</p>
              )}
              {searchError && <p>{searchError}</p>}

              {searchResults && (
                <div>
                  <span css={tw`font-semibold text-nucor-gray mr-1`}>
                    Bill of Lading
                  </span>
                  <span css={tw`mr-3`}>{searchResults.packingSlipNumber}</span>
                  <span css={tw`font-semibold text-nucor-gray mr-1`}>
                    Ship Date
                  </span>
                  <span css={tw`mr-3`}>{searchResults.shipDate}</span>
                  <span css={tw`font-semibold text-nucor-gray mr-1`}>
                    Weight
                  </span>
                  <span css={tw`mr-3`}>
                    {numberFormatter(searchResults.weight)}
                  </span>
                  {searchResults.entireBOL && (
                    <span css={tw`mr-3 font-bold`}>
                      (Claim for Entire Bill of Lading)
                    </span>
                  )}
                </div>
              )}
            </div>
            <div css={tw`flex`}>
              <label css={tw`w-40 font-semibold text-nucor-gray`}>
                Invoice
              </label>
              <Select
                name="invoice"
                minWidth="17ch"
                data={[{ label: "Select", value: "" }, ...invoiceNumberResults]}
                onChange={(num: string) => setInvoiceNumber(num)}
                value={invoiceNumber}
              />
            </div>
            <div css={tw`w-full flex gap-4 mt-4 justify-center`}>
              <Button
                onClick={submitHandler}
                disabled={submitStatus === "pending" || !isValid}
              >
                Submit to NTP
              </Button>
              <Button onClick={resetHandler}>Reset</Button>
            </div>
          </form>
        </div>
      }
    </div>
  );
};

export default CreateClaimForm;
