/** @jsxImportSource @emotion/react */
import { keyframes } from "@emotion/react";
import React, { ReactNode } from "react";
import tw from "twin.macro";

type Props = {
  children?: ReactNode;
} & React.ComponentProps<"div">;

const pulse = keyframes`
from {
  background-position: 100% 0;
}
to {
  background-position: 0 0;
}
`;

const Spinner: React.FC<Props> = ({ ...remainingProps }) => {
  return (
    <div
      css={[
        tw`h-2 w-full rounded-sm`,
        {
          background: `linear-gradient(118deg, rgba(11,99,37,1) 0%, rgba(11,99,37,1) 15%, rgba(23,154,68,1) 20%, rgba(11,99,37,1) 25%, rgba(11,99,37,1) 63%, rgba(23,154,68,1) 68%, rgba(11,99,37,1) 72%, rgba(11,99,37,1) 100%);`,
        },
        {
          backgroundSize: "500% 100%",
          animation: `${pulse} 2.2s linear infinite`,
        },
      ]}
      {...remainingProps}
    />
  );
};

export default Spinner;
