/** @jsxImportSource @emotion/react */
import React, { ReactNode, useState } from "react";
import tw from "twin.macro";
import {
  InventoryBundleOrderableItem,
  SecondaryShortsDetails,
} from "../../../../types/types";
import SecondaryItemDialog from "./ShortsItemDialog";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { selectCurrentCustomerId } from "../../../../store/customer.reducer";
import { removeSecondaryCartItem } from "../../../../store/shoppingCart.actions";
import { hasAuthority } from "../../../../helpers/hasAuthority";
import { selectCurrentAuthorities } from "../../../../store/user.reducer";
import ShortsListItem from "./ShortsListItem";

type Props = {
  children?: ReactNode;
  results?: SecondaryShortsDetails;
};

const ShortsListResults: React.FC<Props> = ({ results }) => {
  const dispatch = useAppDispatch();
  const customerId = useAppSelector(selectCurrentCustomerId);
  const currentAuthorities = useAppSelector(selectCurrentAuthorities);
  const bundles = results?.bundles;
  const [selectedBundle, setSelectedBundle] =
    useState<InventoryBundleOrderableItem>();

  const removeItemHandler = (bundle?: InventoryBundleOrderableItem) => {
    if (!customerId || !bundle?.salesOrderItem?.tagNumber) return;
    dispatch(
      removeSecondaryCartItem({
        customerId,
        salesOrderItemId: bundle.salesOrderItem.id,
      })
    );
  };

  return (
    <>
      {selectedBundle && (
        <SecondaryItemDialog
          bundle={selectedBundle}
          minDate={results?.minDueDate}
          maxDate={results?.maxDueDate}
          onClose={() => {
            setSelectedBundle(undefined);
          }}
        />
      )}
      <table className="ntp-portal-table" css={tw`mt-4 text-xs w-full`}>
        <thead>
          <tr>
            <th>Product</th>
            <th>Length</th>
            <th>Mill</th>
            <th>Grade</th>
            <th>Total Weight</th>
            <th>Pcs</th>
            <th>Tag Number</th>
            <th>Rej Code</th>
            <th>Remarks</th>
            {hasAuthority("createOrderQuoteLoad", currentAuthorities) && (
              <th css={tw`w-[9%]`}>Actions</th>
            )}
          </tr>
        </thead>

        <tbody>
          {bundles && bundles.length > 0 ? (
            bundles.map((item) => (
              <ShortsListItem
                key={`${item.bundle.itemNumber}-${item.bundle.tagNumber}`}
                showActions={customerId !== undefined}
                item={item}
                onAdd={(bundle) => setSelectedBundle(bundle)}
                onEdit={(bundle) => setSelectedBundle(bundle)}
                onRemove={(bundle) => removeItemHandler(bundle)}
              />
            ))
          ) : (
            <tr>
              <td css={tw`text-nucor-gray !px-2 !py-3 hover:!bg-white`} colSpan={11}>
                No inventory found for the selected criteria.
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {bundles && bundles.length > 0 ? (
        <p css={tw`mt-6 text-xs`}>
          {`Stock is sorted by product family (e.g. 4" SQ family), then width or diameter and wall thickness.`}
        </p>
      ) : null
      }
    </>
  );
};

export default ShortsListResults;
