/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import tw from "twin.macro";
import { numberFormatter } from "../../../../../../helpers/numberFormat";
import { CartSite } from "../../../../../../types/types";
import ReviewShoppingItem from "./ReviewShoppingItem";
import { useAppSelector } from "../../../../../../app/hooks";

type Props = {
  children?: ReactNode;
  site: CartSite;
  hasItemWithPartList: boolean;
  currency: string;
};

const ReviewShoppingSite: React.FC<Props> = ({ site, hasItemWithPartList, currency }) => {
  const usePoLineNumber = useAppSelector(
    (state) => state.customer.currentCustomer?.useItemPoLineNumber ?? true
  );
  const {
    site: siteName,
    address: {
      address1,
      address2,
      address3,
      city,
      stateCode,
      postalCode,
      phone,
      fax,
    },
    items,
  } = site;

  const totalPieces = items.reduce((acc, curr) => {
    return acc + curr.pieces;
  }, 0);

  const totalWeight = items.reduce((acc, curr) => {
    return acc + curr.weight;
  }, 0);

  const hasCustomerPoNumber = site.items.some((item) => item.customerPONumber);

  return (
    <>
      <div css={tw`flex mt-2`}>
        <div css={tw`bg-nucor-green w-4`} />
        <div css={tw`flex-1`}>
          <div css={tw`bg-[#e6e6e6] p-1`}>
            <p>Ship From:</p>
            <p css={tw`font-bold`}>{siteName}</p>
            <p>{address1}</p>
            <p>{address2}</p>
            <p>{address3}</p>
            <p>{`${city}, ${stateCode} ${postalCode}`}</p>
            {phone && <p>Tel: {phone}</p>}
            {fax && <p>Fax: {fax}</p>}
            <p></p>
          </div>
          <table className="ntp-portal-table ntp-remove-hover ntp-remove-stripe" css={tw`w-full`}>
            <thead>
              <tr>
                <th css={tw`w-[25%]`}>Description</th>
                <th css={tw`w-[7%]`}>Grade</th>
                <th css={tw`w-[5%]`}>Pcs</th>
                <th css={tw`w-[7%]`}>Lbs</th>
                <th css={tw`w-[7%]`}>Price</th>
                <th css={tw`w-[8%]`}>Source</th>
                {hasCustomerPoNumber ? <th css={tw`w-[10%]`}>PO</th> : null}
                {usePoLineNumber && <th css={tw`w-[6%]`}>PO Line #</th>}
                <th css={tw`w-[10%]`}>Due Date</th>
                {hasItemWithPartList && <th>Part Number</th>}
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <ReviewShoppingItem
                  usePoLineNumber={usePoLineNumber}
                  hasItemWithPartList={hasItemWithPartList}
                  hasCustomerPoNumber={hasCustomerPoNumber}
                  item={item}
                  key={index}
                  currency={currency}
                />
              ))}
              <tr>
                <td css={tw`text-nucor-gray font-bold`} colSpan={2}>
                  Sub Total:
                </td>
                <td>{numberFormatter(totalPieces)}</td>
                <td>{numberFormatter(totalWeight)}</td>
                <td colSpan={8}></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ReviewShoppingSite;
