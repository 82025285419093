/** @jsxImportSource @emotion/react */

import tw from "twin.macro";
import React, { ComponentProps } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import TextButton from "../atoms/TextButton";
import { getVersion } from "../../store/app.actions";
import { showPortalOutOfDateAlert } from "../../store/app.reducer";

type Props = ComponentProps<"section">;

const Footer: React.FC<Props> = ({ ...remainingProps }) => {
  const { pathname } = useLocation();
  const { siteContactEmail } = useAppSelector((state) => state.app);
  const dispatch = useAppDispatch();

  const checkForUpdatesHandler = () => {
    dispatch(getVersion())
      .unwrap()
      .then((appVersion) => {
        if (process.env.REACT_APP_RUN_NUMBER !== appVersion.version) {
          dispatch(showPortalOutOfDateAlert());
        }
      });
  };

  return pathname.startsWith("/terms") ||
    pathname.startsWith("/privacy") ? null : (
    <section css={tw`text-xs text-center mb-2`} {...remainingProps}>
      <span>
        &copy;{new Date().getFullYear()} Nucor Tubular Products All Rights
        Reserved.
      </span>
      <div css={tw`flex justify-center gap-1 mx-auto`}>
        <span>
          <a href="/terms" target="_blank" css={tw`text-nucor-link focus:text-nucor-link-hover focus:outline-none`}>
            Terms of Service
          </a>
        </span>
        <span>|</span>
        <span>
          <a href="/privacy" target="_blank"  css={tw`text-nucor-link focus:text-nucor-link-hover focus:outline-none`}>
            Privacy Policy
          </a>
        </span>
        <span>|</span>
        <span>
          <a
            href="https://www.nucortubular.com/"
            rel="noreferrer"
            target="_blank"
            css={tw`text-nucor-link focus:text-nucor-link-hover focus:outline-none`}
          >
            About us
          </a>
        </span>
        {siteContactEmail && (
          <>
            <span>|</span>
            <span css={tw`text-nucor-link`}>
              <a
                href={`mailto:${siteContactEmail}?subject=Contact Nucor Tubular Products`}
                css={tw`text-nucor-link focus:text-nucor-link-hover focus:outline-none`}
              >
                Contact us
              </a>
            </span>
          </>
        )}
      </div>
      <span>
        {" "}
        Build number: {process.env.REACT_APP_BUILD_VERSION ?? "Unavailable"}
      </span>
      <span> Run: {process.env.REACT_APP_RUN_NUMBER}</span>
      <span css={tw`ml-2`}>
        <TextButton onClick={checkForUpdatesHandler} css={tw``}>
          Check for updates
        </TextButton>
      </span>
    </section>
  );
};

export default Footer;
