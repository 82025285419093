/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import tw from "twin.macro";

type Props = {
  children?: ReactNode;
  title: string;
} & React.ComponentProps<"div">;

const TabGroupSubGroup: React.FC<Props> = ({
  title,
  children,
  ...remainingProps
}) => {
  return (
    <div {...remainingProps}>
      <div
        css={tw`bg-nucor-green text-white text-xs font-bold text-center py-1 rounded-t-xl`}
      >
        {title}
      </div>
      <div css={tw`bg-white rounded-b-xl p-1 px-2 pb-4 mb-4`}>{children}</div>
    </div>
  );
};

export default TabGroupSubGroup;
