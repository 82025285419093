/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import tw from "twin.macro";

type Props = {
  children?: ReactNode;
  isActive?: boolean;
} & React.ComponentProps<"button">;

const ButtonSelect: React.FC<Props> = ({
  isActive,
  children,
  ...remainingProps
}) => {
  return (
    <button
      css={[
        tw`text-xs text-nucor-link hover:text-nucor-link-hover font-bold block focus-visible:text-nucor-link-hover focus-visible:outline-none`,
        isActive && tw`text-[#BC0000]`,
      ]}
      type="button"
      {...remainingProps}
    >
      {children}
    </button>
  );
};

export default ButtonSelect;
