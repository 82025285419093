/** @jsxImportSource @emotion/react */
import React, { ReactNode, useContext, useEffect } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import ButtonSelect from "../../../../components/atoms/ButtonSelect";
import Select from "../../../../components/atoms/Select";
import TabGroupSubGroup from "../../../../components/atoms/TabGroupSubGroup";
import { selectCurrentCustomerId } from "../../../../store/customer.reducer";
import {
  clearDocumentErrors,
  selectOrderDocumentsSelectionData,
  updateOrderSearchCriteria,
} from "../../../../store/document.reducer";
import { selectDefaultMill } from "../../../../store/user.reducer";
import { searchOrderDocumentOrder } from "../../../../store/document.actions";
import Toast from "../../../../components/molecules/Toast";
import { PDFContext } from "./OrdersList";
import { SearchCriteria } from "../../../../types/types";

type OrderDocumentBrowseCriteria = {
  myCustomers?: boolean;
  shipToCustomerId?: string;
  orderStatus?: string;
  dateRangeType?: string;
  siteKey?: string;
  sites?: string[];
};

type Props = {
  children?: ReactNode;
};

const OrdersBrowse: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const customerId = useAppSelector(selectCurrentCustomerId);
  const orderDocumentsSelectionData = useAppSelector(
    selectOrderDocumentsSelectionData
  );
  const defaultMill = useAppSelector(selectDefaultMill);
  const { searchCriteria, error } = useAppSelector(
    (state) => state.document.orders
  );
  const ctx = useContext(PDFContext);

  const getSites = (siteKey: string | undefined) : string[] | undefined => {
    if (!siteKey || !orderDocumentsSelectionData) {
      return undefined;
    }
    return siteKey === "ALL" ? undefined : orderDocumentsSelectionData.sites.find((item) => item.key === siteKey)?.sites;
  };

  const getBrowseCriteria = () : OrderDocumentBrowseCriteria  => {
    const myCustomers = searchCriteria.myCustomers ?? false;
    const shipToCustomerId = searchCriteria.shipToCustomerId ?? "all";
    const orderStatus = searchCriteria.orderStatus ?? "all";
    const siteKey = searchCriteria.siteKey ?? defaultMill ?? "ALL"
    const dateRangeType = searchCriteria.dateRangeType ?? "last30Days"
  
    const criteria = {myCustomers, shipToCustomerId, orderStatus, siteKey, dateRangeType};
  
    return criteria;
  };

  const getQueryCriteria = (browseCriteria: OrderDocumentBrowseCriteria) : SearchCriteria => {
    const {myCustomers, shipToCustomerId, orderStatus, siteKey, dateRangeType} = browseCriteria;
    const criteria = {
      ...searchCriteria,
      myCustomers: myCustomers,
      shipToCustomerId:
        shipToCustomerId === "all" || myCustomers
          ? undefined
          : shipToCustomerId,
      orderStatus: orderStatus === "all" ? undefined : orderStatus,
      dateRangeType,
      siteKey: siteKey,
      sites: getSites(siteKey)
    };

    return criteria;
  };

  const browseCriteria = getBrowseCriteria();
  const {myCustomers, shipToCustomerId, orderStatus, siteKey, dateRangeType} = browseCriteria;
  const criteria = getQueryCriteria(browseCriteria);

  const shipTos =
    orderDocumentsSelectionData?.shipTos.map((shipTo) => {
      return {
        value: shipTo.id,
        label: <span>{`${shipTo.id} - ${shipTo.name}`}</span>,
      };
    }) ?? [];

    const search = (criteria: SearchCriteria) => {
      dispatch(updateOrderSearchCriteria({ ...criteria, page: 1}));
      dispatch(searchOrderDocumentOrder({customerId, criteria: { ...criteria, siteKey: undefined }}));
    };

    useEffect(() => {
      if (orderDocumentsSelectionData) {
        const criteria = getQueryCriteria(getBrowseCriteria());
        search(criteria);
      }
    }, [orderDocumentsSelectionData]);
    
    const onSelectionsChanged = (criteria: OrderDocumentBrowseCriteria) => {
      // Close pdf if open
      if (ctx?.file) {
        ctx?.setFile(null);
      }
      search(criteria);
    };

  return (
    <>
      {error && (
        <Toast
          type="error"
          message={error}
          onConfirm={() => dispatch(clearDocumentErrors())}
        />
      )}
      <TabGroupSubGroup title="Show">
        <Select
          minWidth="22ch"
          name="showBy"
          data={orderDocumentsSelectionData?.customerSearchType.map((item) => ({
            value: item.id,
            label: item.description,
          }))}
          onChange={(value: boolean) => {
            onSelectionsChanged({
              ...criteria,
              myCustomers: value,
            });
          }}
          value={myCustomers}
        />
      </TabGroupSubGroup>
      {!myCustomers && (
        <TabGroupSubGroup title="By Ship To">
          <Select
            minWidth="22ch"
            maxWidth="22ch"
            name="byShipTo"
            data={[{ value: "all", label: "All" }, ...shipTos]}
            onChange={(value: string) => {
              onSelectionsChanged({
                ...criteria,
                shipToCustomerId: value === "all" ? undefined : value,
              });
            }}
            value={shipToCustomerId}
          />
        </TabGroupSubGroup>
      )}
      <TabGroupSubGroup title="By Order Status">
        <ButtonSelect
          isActive={orderStatus === "all"}
          onClick={() => {
            onSelectionsChanged({
              ...criteria,
              orderStatus: undefined,
            });
          }}
          css={tw`block`}
        >
          All
        </ButtonSelect>
        {orderDocumentsSelectionData?.orderStatuses.map((status) => (
          <ButtonSelect
            key={status.id}
            isActive={orderStatus === status.id}
            onClick={() => {
              onSelectionsChanged({
                ...criteria,
                orderStatus: status.id,
              });
            }}
          >
            {status.description}
          </ButtonSelect>
        ))}
      </TabGroupSubGroup>
      <TabGroupSubGroup title="By Mill">
        <ButtonSelect
          isActive={siteKey === "ALL"}
          onClick={() => {
            onSelectionsChanged({
              ...criteria,
              siteKey: "ALL",
              sites: undefined,
            });
          }}
        >
          All
        </ButtonSelect>
        {orderDocumentsSelectionData?.sites.map((item, index) => {
          return (
            <ButtonSelect
              isActive={siteKey === item.key}
              onClick={() => {
                onSelectionsChanged({
                  ...criteria,
                  siteKey: item.key,
                  sites: getSites(item.key),
                });
              }}
              key={index}
            >
              {item.description}
            </ButtonSelect>
          );
        })}
      </TabGroupSubGroup>
      <TabGroupSubGroup title="By Order Date">
        {orderDocumentsSelectionData?.dateSelections.map((date, index) => {
          return (
            <ButtonSelect
              key={index}
              onClick={() => {
                onSelectionsChanged({
                  ...criteria,
                  dateRangeType: date.id,
                });
              }}
              isActive={dateRangeType === date.id}
            >
              {date.description}
            </ButtonSelect>
          );
        })}
      </TabGroupSubGroup>
    </>
  );
};

export default OrdersBrowse;
