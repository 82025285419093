/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import React, { ReactNode, useState } from "react";
import Link from "../atoms/Link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faBell,
  faCloudDownload,
  faQuestionCircle,
  faSignOut,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import ContactInfo from "./ContactInfo";
import Announcement from "./Announcement";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  selectCurrentAuthorities,
  setShowLogoutDialog,
} from "../../store/user.reducer";
import { useClickOutside } from "../../hooks/useClickOutside";
import { selectCurrentCustomerId } from "../../store/customer.reducer";
import { isAdmin } from "../../helpers/isAdmin";
import { hasAuthority } from "../../helpers/hasAuthority";
import HistoricalPriceModal from "../molecules/HistoricalPriceModal";
import { fetchHistoricalPriceSheets } from "../../store/document.actions";
import RollingSchedule from "../molecules/RollingSchedule";
import PriceSheet from "../molecules/PriceSheet";

type Props = {
  children?: ReactNode;
};
type ModalState = "none" | "announcements" | "salesreps" | "admin" | "logout";

const linkFonts = [tw`mx-[10px]`];

const NavBar: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const selectedCustomerId = useAppSelector(selectCurrentCustomerId);
  const disablePricing = useAppSelector(
    (state) => state.customer.currentCustomer?.disablePricing
  );
  const user = useAppSelector((state) => state.user.currentContext?.user);
  const hasAdminRole = isAdmin(user?.authorities);
  const accessControl = useAppSelector(
    (state) => state.user.currentContext?.accessControl
  );

  const [modalShowing, setModalShowing] = useState<ModalState>("none");
  const [historicalPriceModalShowing, setHistoricalPriceModalShowing] =
    useState(false);

  const dismissListener = () => {
    setModalShowing("none");
  };

  const ref = useClickOutside<HTMLDivElement>(dismissListener);

  const currentAuthorities = useAppSelector(selectCurrentAuthorities);
  const hasViewPriceSheetAuthority = hasAuthority(
    "viewPriceSheets",
    currentAuthorities
  );
  const hasViewRollingScheduleAuthority = hasAuthority(
    "viewRollings",
    currentAuthorities
  );
  const isInternal = hasAuthority("internal", currentAuthorities);

  return (
    <nav css={tw`flex items-center text-white text-[13px]`}>
      {user && (
        <>
          {historicalPriceModalShowing && (
            <HistoricalPriceModal
              checkShipToPricing={true}
              action={fetchHistoricalPriceSheets}
              onCancel={() => setHistoricalPriceModalShowing(false)}
            />
          )}
          {selectedCustomerId && isInternal && (
            <button
              css={[linkFonts, tw`mt-1`]}
              onClick={() => setHistoricalPriceModalShowing(true)}
            >
              Historical Price Sheets
            </button>
          )}
          {!disablePricing &&
            selectedCustomerId &&
            hasViewPriceSheetAuthority && (
              <PriceSheet/>
            )}

          {hasViewRollingScheduleAuthority && <RollingSchedule />}
          {(hasViewPriceSheetAuthority || hasViewRollingScheduleAuthority) && (
            <span css={tw`-mt-1 text-[22px] text-white mx-[10px]`}>|</span>
          )}

          <div tw="mx-2 relative">
            <button
              onClick={() =>
                setModalShowing((prev) =>
                  prev === "announcements" ? "none" : "announcements"
                )
              }
            >
              <FontAwesomeIcon tw="h-5 mt-[-2px]" icon={faBell} />
              {accessControl?.message && (
                <div
                  css={[
                    tw`bg-red-500 h-3 w-3 flex justify-center text-white font-bold text-[8px] rounded-full leading-4 absolute top-0 -right-1`,
                  ]}
                >
                  <span tw="mt-[2px] leading-none">1</span>
                </div>
              )}
            </button>
            {modalShowing === "announcements" && (
              <Announcement
                message={accessControl?.message}
                onClose={() => {
                  setModalShowing("none");
                }}
                css={[tw`absolute right-0 top-2 z-[1000]`]}
              />
            )}
          </div>

          {selectedCustomerId && (
            <div css={[tw`relative`]}>
              <button
                type="button"
                onClick={() =>
                  setModalShowing((prev) =>
                    prev === "salesreps" ? "none" : "salesreps"
                  )
                }
                css={[linkFonts, tw`bg-transparent text-white border-0`]}
              >
                <FontAwesomeIcon css={tw`h-5`} icon={faQuestionCircle} />
              </button>
              {modalShowing === "salesreps" && (
                <ContactInfo
                  onClose={() => {
                    setModalShowing("none");
                  }}
                  css={[tw`absolute right-0 top-2 z-[1000]`]}
                />
              )}
            </div>
          )}

          <a
            css={[linkFonts, tw`text-white`]}
            rel="noreferrer"
            href="https://www.nucortubular.com/resources/"
            target={"_blank"}
          >
            <FontAwesomeIcon css={tw`h-5`} icon={faCloudDownload} />
          </a>

          {hasAdminRole && (
            <div ref={ref} css={tw`mx-[10px] relative`}>
              <button
                onClick={() =>
                  setModalShowing((prev) =>
                    prev === "admin" ? "none" : "admin"
                  )
                }
              >
                <FontAwesomeIcon tw="h-5" icon={faBars} />
              </button>
              {modalShowing === "admin" && (
                <div
                  css={[
                    tw`absolute right-0 top-6 w-40 bg-white border-2 border-nucor-green rounded-md flex flex-col px-2 py-1 font-bold text-xs`,
                  ]}
                >
                  <Link
                    onClick={() => setModalShowing("none")}
                    css={[
                      tw`text-nucor-link underline hover:(text-nucor-link-hover no-underline)`,
                    ]}
                    to={{
                      pathname: "/portal",
                      search: selectedCustomerId
                        ? `?customer_id=${selectedCustomerId}`
                        : "",
                    }}
                  >
                    Portal
                  </Link>
                  <Link
                    onClick={() => setModalShowing("none")}
                    css={[
                      tw`text-nucor-link underline hover:(text-nucor-link-hover no-underline)`,
                    ]}
                    to="/admin"
                  >
                    Admin
                  </Link>
                </div>
              )}
            </div>
          )}

          <Link css={[linkFonts, tw`text-white`]} to="/portal/userinfo">
            <FontAwesomeIcon css={tw`h-5`} icon={faUser} />
          </Link>
        </>
      )}

      <div css={[tw`relative`]}>
        <button
          type="button"
          css={[linkFonts, tw`bg-transparent text-white border-0`]}
          onClick={() => dispatch(setShowLogoutDialog(true))}
        >
          <FontAwesomeIcon css={tw`h-5`} icon={faSignOut} />
        </button>
      </div>
    </nav>
  );
};

export default NavBar;
