/** @jsxImportSource @emotion/react */
import React, { ReactNode, useEffect, useState } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  fetchStockListExcel,
  fetchStockListPDF,
  searchStockList,
} from "../../../../store/product.actions";
import {
  ErrorResponse,
  PrimeInventoryItemSummaryResult,
} from "../../../../types/types";
import Button from "../../../../components/atoms/Button";
import Select from "../../../../components/atoms/Select";
import AllStockListResults from "./AllStockListResults";
import { selectMillsForSelectComponent } from "../../../../store/app.reducer";
import { selectDefaultMill } from "../../../../store/user.reducer";
import { selectAvailableGrades } from "../../../../store/product.reducer";
import Toast from "../../../../components/molecules/Toast";
import AnimatedLoadingSpinner from "../../../../components/atoms/AnimatedLoadingSpinner";

type Props = {
  children?: ReactNode;
};

const AllStockList: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const mills = useAppSelector(selectMillsForSelectComponent);
  const defaultMill = useAppSelector(selectDefaultMill);
  const grades = useAppSelector(selectAvailableGrades);
  const [selectedMill, setSelectedMill] = useState(defaultMill);
  const [selectedGrade, setSelectedGrade] = useState("");
  const [roundFlag, setRoundFlag] = useState(false);
  const [excelFlag, setExcelFlag] = useState(false);
  const [pdfFlag, setPdfFlag] = useState(false);
  const [stockListResults, setStockListResults] = useState<
    PrimeInventoryItemSummaryResult | undefined | "excelorpdf"
  >();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<ErrorResponse | string>();

  const currentCustomer = useAppSelector(
    (state) => state.customer.currentCustomer
  );

  const gradeSelections = [
    { value: "", label: "Select" },
    ...grades.map((grade) => ({ value: grade.grade, label: grade.grade })),
  ];

  const clickHandler = () => {
    setStockListResults(undefined);
    if (excelFlag || pdfFlag) {
      excelFlag && excelHandler();
      pdfFlag && pdfHandler();
    } else {
      searchStockListHandler();
    }
  };

  const searchStockListHandler = () => {
    if (selectedGrade === "") return;
    setIsLoading(true);
    const customerId = currentCustomer?.id;
    dispatch(
      searchStockList({
        customerId: customerId,
        grade: selectedGrade,
        site: selectedMill,
        roundFlag: roundFlag,
      })
    )
      .unwrap()
      .then((result) => {
        setStockListResults(result);
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    const roundsOnly = grades.find(
      (grade) => grade.grade === selectedGrade
    )?.onlyRound;
    setRoundFlag(roundsOnly || false);
  }, [grades, selectedGrade]);

  const excelHandler = () => {
    setIsLoading(true);
    setStockListResults("excelorpdf");
    const customerId = currentCustomer?.id;
    dispatch(
      fetchStockListExcel({
        customerId: customerId,
        grade: selectedGrade,
        site: selectedMill,
        roundFlag: roundFlag,
      })
    )
      .unwrap()
      .then(() => setIsLoading(false))
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  };

  const pdfHandler = () => {
    setIsLoading(true);
    setStockListResults("excelorpdf");
    const customerId = currentCustomer?.id;
    dispatch(
      fetchStockListPDF({
        customerId: customerId,
        grade: selectedGrade,
        site: selectedMill,
        roundFlag: roundFlag,
      })
    )
      .unwrap()
      .then(() => setIsLoading(false))
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (undefined == mills.find((m) => selectedMill == m.value)) {
      setSelectedMill("ALL");
    }
    setStockListResults(undefined);
  }, [mills]);

  return (
    <div css={tw`p-2 w-full`}>
      {error && (
        <Toast
          message={error}
          type="error"
          onConfirm={() => setError(undefined)}
        />
      )}
      <div
        css={tw`flex justify-between items-center bg-nucor-green w-full text-xs py-1 px-2 mt-2`}
      >
        <h2 css={[tw`text-white uppercase font-bold`]}>Floor Stock</h2>
      </div>
      <div
        css={tw`flex p-2 bg-gradient-to-b from-[#e6e6e6] via-[#fdfdfd] to-[#e6e6e6] gap-1 text-xs items-center`}
      >
        <span>Mill:</span>
        <Select
          name="mill"
          value={selectedMill}
          onChange={(mill: string) => setSelectedMill(mill)}
          minWidth={"20ch"}
          data={mills}
        />
        <span css={tw`ml-4`}>Product:</span>
        <Select
          name="grade"
          minWidth={"14ch"}
          value={selectedGrade}
          onChange={(grade: string) => setSelectedGrade(grade)}
          data={gradeSelections}
          testId="grade-select"
        />
        <span css={tw`ml-4`}>Show Rounds:</span>
        <input
          type="checkbox"
          name="round"
          checked={roundFlag}
          onChange={(e) => setRoundFlag(e.target.checked)}
        />
        <span css={tw`ml-4`}>Run as Excel:</span>
        <input
          type="checkbox"
          name="excel"
          checked={excelFlag}
          onChange={(e) => {
            setPdfFlag(false);
            setExcelFlag(e.target.checked);
          }}
        />
        <span css={tw`ml-4`}>Run as PDF:</span>
        <input
          type="checkbox"
          name="pdf"
          checked={pdfFlag}
          onChange={(e) => {
            setExcelFlag(false);
            setPdfFlag(e.target.checked);
          }}
        />
        <Button
          disabled={selectedGrade === "" || isLoading}
          onClick={clickHandler}
          css={tw`ml-4`}
          type="button"
        >
          Search
        </Button>
      </div>
      {stockListResults === "excelorpdf" && isLoading && (
        <AnimatedLoadingSpinner css={tw`mt-4 ml-4`} />
      )}
      {stockListResults !== "excelorpdf" && (
        <AllStockListResults isLoading={isLoading} results={stockListResults} />
      )}
    </div>
  );
};

export default AllStockList;
