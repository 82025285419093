/** @jsxImportSource @emotion/react */
import React, { ReactNode, useState } from "react";
import tw from "twin.macro";
import Loading from "../../../../components/atoms/Loading";
import { DeliveryModeType, ShipToRequest } from "../../../../types/types";
import RequestNewShipToForm from "./RequestNewShipToForm";
import { useAppSelector } from "../../../../app/hooks";
import RequestNewShipToConfirmation from "./RequestNewShipToConfirmation";

type Props = {
  children?: ReactNode;
};

const RequestNewShipTo: React.FC<Props> = () => {
  const [shipMethod, setShipMethod] = useState<DeliveryModeType>();
  const [customerPayingForFreight, setCustomerPayingForFreight] =
    useState<boolean>();
  const [freightOnInvoice, setFreightOnInvoice] = useState<boolean | undefined>(
    undefined
  );
  const [sendStatus, setSendStatus] = useState<
    "idle" | "submitting" | "error" | "sent"
  >("idle");
  const currentCustomer = useAppSelector(
    (state) => state.customer.currentCustomer
  );
  const [submittedDetails, setSubmittedDetails] = useState<ShipToRequest>();

  const formResetHandler = () => {
    setSendStatus("idle");
    setShipMethod(undefined);
    setCustomerPayingForFreight(undefined);
    setFreightOnInvoice(undefined);
  };

  if (!currentCustomer) {
    return (
      <p css={tw`text-base italic text-nucor-green leading-relaxed ml-3 mb-1`}>
        Please select a customer
      </p>
    );
  }

  if (submittedDetails) {
    return (
      <RequestNewShipToConfirmation
        onReset={() => {
          setSubmittedDetails(undefined);
          formResetHandler();
        }}
        details={submittedDetails}
      />
    );
  }

  return (
    <>
      <h2
        css={tw`bg-nucor-green text-white font-bold py-1 px-2 uppercase text-xs w-full`}
      >
        Request New Ship To
      </h2>
      <p css={tw`text-xs text-nucor-green leading-relaxed ml-3 mb-1`}>
        Fill out the form below to request a new Ship To for your account.
      </p>
      <p css={tw`italic text-xs ml-3`}>
        <span css={tw`text-red-600`}>*</span> indicates required fields
      </p>
      <div css={tw`max-w-xl relative`}>
        {sendStatus === "submitting" && <Loading>Submitting</Loading>}
        <p css={[tw`text-xs my-2 ml-3`]}>
          How will this ship? <span css={tw`text-red-600`}>*</span>
          <input
            checked={shipMethod === "Truck"}
            id="truck"
            css={tw`ml-4`}
            type="radio"
            name="shipMethod"
            value="truck"
            onChange={() => {
              setSendStatus("idle");
              setShipMethod("Truck");
              setCustomerPayingForFreight(undefined);
              setFreightOnInvoice(undefined);
            }}
          />
          <label
            css={tw`ml-1 font-bold text-xs text-[#6d756e]`}
            htmlFor="truck"
          >
            Truck
          </label>
          <input
            checked={shipMethod === "Rail"}
            id="rail"
            css={tw`ml-4`}
            type="radio"
            name="shipMethod"
            value="rail"
            onChange={() => {
              setSendStatus("idle");
              setShipMethod("Rail");
              setCustomerPayingForFreight(undefined);
              setFreightOnInvoice(undefined);
            }}
          />
          <label css={tw`ml-1 font-bold text-xs text-[#6d756e]`} htmlFor="rail">
            Rail
          </label>
          <input
            checked={shipMethod === "Barge"}
            id="barge"
            css={tw`ml-4`}
            type="radio"
            name="shipMethod"
            value="barge"
            onChange={() => {
              setSendStatus("idle");
              setShipMethod("Barge");
              setCustomerPayingForFreight(undefined);
              setFreightOnInvoice(undefined);
            }}
          />
          <label css={tw`ml-1 font-bold text-xs text-[#6d756e]`} htmlFor="barge">
            Barge
          </label>
        </p>
        {shipMethod && (
          <p css={[tw`text-xs my-2 ml-3`]}>
            Who pays the freight bill? <span css={tw`text-red-600`}>*</span>
            <input
              checked={customerPayingForFreight === true}
              id="customer"
              css={tw`ml-4`}
              type="radio"
              name="responsibleForFreight"
              value="customer"
              onChange={() => {
                setCustomerPayingForFreight(true);
                setFreightOnInvoice(undefined);
              }}
            />
            <label
              css={tw`ml-1 font-bold text-xs text-[#6d756e]`}
              htmlFor="customer"
            >
              Customer
            </label>
            <input
              checked={customerPayingForFreight === false}
              id="nucor"
              css={tw`ml-4`}
              type="radio"
              name="responsibleForFreight"
              value="nucor"
              onChange={() => {
                setCustomerPayingForFreight(false);
                setFreightOnInvoice(undefined);
              }}
            />
            <label
              css={tw`ml-1 font-bold text-xs text-[#6d756e]`}
              htmlFor="nucor"
            >
              Nucor Tubular Products
            </label>
          </p>
        )}
        {customerPayingForFreight !== undefined &&
          customerPayingForFreight === false && (
            <p css={[tw`text-xs my-2 ml-3`]}>
              Add freight to the invoice? <span css={tw`text-red-600`}>*</span>
              <input
                checked={freightOnInvoice === true}
                id="yes"
                css={tw`ml-4`}
                type="radio"
                name="freightOnInvoice"
                value="customer"
                onChange={() => setFreightOnInvoice(true)}
              />
              <label
                css={tw`ml-1 font-bold text-xs text-[#6d756e]`}
                htmlFor="yes"
              >
                Yes
              </label>
              <input
                checked={freightOnInvoice === false}
                id="no"
                css={tw`ml-4`}
                type="radio"
                name="freightOnInvoice"
                value="no"
                onChange={() => setFreightOnInvoice(false)}
              />
              <label
                css={tw`ml-1 font-bold text-xs text-[#6d756e]`}
                htmlFor="no"
              >
                No
              </label>
            </p>
          )}
        {customerPayingForFreight !== undefined &&
          (freightOnInvoice !== undefined ||
            customerPayingForFreight === true) && (
            <RequestNewShipToForm
              onReset={formResetHandler}
              onSubmitted={(details) => setSubmittedDetails(details)}
              onStatusChange={setSendStatus}
              shipMethod={shipMethod}
              freightOnInvoice={freightOnInvoice}
              customerPayingForFreight={customerPayingForFreight}
            />
          )}
      </div>
    </>
  );
};

export default RequestNewShipTo;
