/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import tw from "twin.macro";
import { SalesRep } from "../../types/types";

type Props = {
  children?: ReactNode;
  contact: SalesRep;
  title?: string;
};

const ContactInfoBlock: React.FC<Props> = ({ contact, title }) => {
  return (
    <div css={[tw`mt-2 text-left`]}>
      <p css={[tw`text-[#6d756e] font-bold`]}>{title}</p>
      {contact?.contactName && (
        <p css={[tw`font-bold`]}>{contact?.contactName}</p>
      )}
      <p>Direct: {contact?.phoneNumber}</p>
      {contact?.emailAddress && (
        <a css={[tw`underline`]} href={`mailto:${contact.emailAddress}`}>
          {contact.emailAddress}
        </a>
      )}
    </div>
  );
};

export default ContactInfoBlock;
