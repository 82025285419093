/** @jsxImportSource @emotion/react */
import React, { ComponentProps, ReactNode } from "react";
import tw from "twin.macro";

type Props = {
  children?: ReactNode;
} & ComponentProps<"textarea">;

const TextArea: React.FC<Props> = ({ ...remainingProps }) => {
  return (
    <textarea
      css={[
        tw`resize-none min-h-[5rem]`,
      ]}
      {...remainingProps}
    />
  );
};

export default TextArea;
