/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import BrowseSearch from "../../mydocuments/BrowseSearch";
import ReleaseOrdersBrowse from "./ReleaseOrdersBrowse";
import ReleaseOrdersSearch from "./ReleaseOrdersSearch";

type Props = {
  children?: ReactNode;
};

const ReleaseOrdersLeft: React.FC<Props> = () => {

  return (
    <BrowseSearch
      browse={<ReleaseOrdersBrowse />}
      search={<ReleaseOrdersSearch />}
    />
  );
};

export default ReleaseOrdersLeft;
