/** @jsxImportSource @emotion/react */
import React, { ComponentProps, ReactNode } from "react";
import tw from "twin.macro";
import { useClickOutside } from "../../hooks/useClickOutside";

type Props = {
  children?: ReactNode;
  onClose: () => void;
  message?: string;
} & ComponentProps<"div">;

const Announcement: React.FC<Props> = ({
  onClose,
  message,
  ...remainingProps
}) => {
  const ref = useClickOutside<HTMLDivElement>(onClose);
  return (
    <div
      ref={ref}
      css={[tw`w-80 bg-nucor-green p-2 flex flex-col text-xs text-black`]}
      {...remainingProps}
    >
      <div css={[tw`flex`]}>
        <p css={[tw`text-white text-left text-xs font-bold flex-grow`]}>
          Messages
        </p>
        <button
          onClick={onClose}
          css={[
            tw`bg-white text-nucor-green text-xs h-4 font-bold w-6 flex justify-center items-center ml-8 border-0`,
          ]}
          dangerouslySetInnerHTML={{ __html: `\u00d7` }}
        />
      </div>
      {!message && (
        <div css={[tw`bg-white mt-2 flex-grow py-2 px-4`]}>No messages.</div>
      )}
      {message && (
        <div css={[tw`bg-white mt-2 flex-grow py-2 px-4`]}>{message}</div>
      )}
    </div>
  );
};

export default Announcement;
