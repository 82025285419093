import { DocumentType } from "../types/types";

export const labelFromDocType = (docType: DocumentType): string => {
  switch (true) {
    case docType === "combinedtestcert":
      return "Combined Test Certificates";
    case docType === "testcert":
      return "Test Certificates";
    case docType === "invoice":
      return "Invoices";
    case docType === "load":
      return "Loads";
    case docType === "quote":
      return "Quotes";
    case docType === "order":
      return "Orders";
    case docType === "packingslip":
      return "Bills of Lading";
    case docType === "pricesheet":
      return "Price Sheets";
    default:
      return "";
  }
};
