/** @jsxImportSource @emotion/react */
import React, { ReactNode, createContext, useEffect } from "react";
import tw from "twin.macro";
import QuoteContent from "./quoteContent";
import QuoteLeft from "./quoteLeft";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { selectCurrentCustomerId } from "../../../../store/customer.reducer";
import { resetQuickQuoteSearchCriteria, updateSelectedQuoteId } from "../../../../store/document.reducer";
import { fetchQuickQuoteSelectionData } from "../../../../store/document.actions";
import { PDFFile } from "../../../../types/types";
import { useParams } from 'react-router-dom';

type Props = {
  children?: ReactNode;
};

export const PDFContext = createContext<{
  file: PDFFile | null;
  setFile: React.Dispatch<React.SetStateAction<PDFFile | null>>;
} | null>(null);

const QuoteList: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const customerId = useAppSelector(selectCurrentCustomerId);
  const [file, setFile] = React.useState<PDFFile | null>(null);
  const selectedQuoteID = useAppSelector((state) => state.document.quickquote.selectedQuoteId);
  const [isSidebarHidden, setIsSidebarHidden] = React.useState(false);
  const { quoteId } = useParams<{ quoteId: string }>();

  useEffect(() => {
    if (quoteId) {
      dispatch(updateSelectedQuoteId(parseInt(quoteId)));
    }
  }, [quoteId]);

  useEffect(() => {
    if (!customerId) return;
    dispatch(fetchQuickQuoteSelectionData(customerId));
    return () => {
      dispatch(resetQuickQuoteSearchCriteria());
      dispatch(updateSelectedQuoteId(0));
    };
  }, [customerId]);

  if (!customerId)
    return (
      <div css={[tw`flex p-4 w-full`]}>
        <p css={tw`text-nucor-green text-xs font-bold`}>Select a customer</p>
      </div>
    );

  return (
    <PDFContext.Provider value={{ file, setFile }}>
      <div css={[tw`flex p-2 w-full`]}>
        {!isSidebarHidden && selectedQuoteID === 0 &&(
          <div css={[tw`w-[220px] px-1`]}>
            <QuoteLeft />
          </div>
        )}
        <div css={[tw`flex-grow px-1`]}>
          <QuoteContent setIsSidebarHidden={setIsSidebarHidden}/>
        </div>
      </div>
    </PDFContext.Provider>
  );
};

export default QuoteList;
