/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import tw from "twin.macro";

type Props = {
  children?: ReactNode;
  message: string;
} & React.HTMLAttributes<HTMLSpanElement>;

const Tooltip: React.FC<Props> = ({ message, ...remainingProps }: Props) => {
  return (
    <span
      css={tw`absolute bg-white px-2 py-1 rounded border hidden animate-tooltipdelay peer-hover:block shadow-lg`}
      {...remainingProps}
    >
      {message}
    </span>
  );
};

export default Tooltip;
