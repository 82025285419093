/** @jsxImportSource @emotion/react */
import React, { ComponentProps, ReactNode } from "react";
import tw from "twin.macro";

type Props = {
  children?: ReactNode;
  disabled?: boolean;
} & ComponentProps<"button">;

const TextButton: React.FC<Props> = ({
  disabled,
  children,
  ...remainingProps
}) => {
  return (
    <button
      type="button"
      css={[
        tw`font-semibold text-nucor-link hover:text-nucor-link-hover underline hover:no-underline focus-visible:text-nucor-link-hover focus-visible:outline-none`,
        disabled &&
          tw`text-nucor-gray cursor-default hover:text-nucor-gray no-underline`,
      ]}
      disabled={disabled}
      {...remainingProps}
    >
      {children}
    </button>
  );
};

export default TextButton;
