/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import tw from "twin.macro";
import { RobinHoodResults } from "../../../../types/types";
import { numberFormatter } from "../../../../helpers/numberFormat";

type Props = {
  children?: ReactNode;
  results?: RobinHoodResults | null;
};

const PortalResultsList: React.FC<Props> = ({ results }) => {
  return (
    <div css={tw`mt-4 text-xs`}>
      {
        <>
          <table className="ntp-portal-table ntp-shrink-y-padding" css={tw`w-full`}>
            <thead>
              <tr>
                <th css={tw`w-[4%]`}>Item</th>
                <th css={tw`w-[14%]`}>Product</th>
                <th css={tw`w-[4%]`}>Site</th>
                <th css={tw`w-[4%]`}>Inv Status</th>
                <th css={tw`w-[4%]`}>Length</th>
                <th css={tw`w-[4%]`}>Weight</th>
                <th css={tw`w-[4%]`}>Bundle<br/>Wgt</th>
                <th css={tw`w-[4%]`}>Total<br/>Pcs</th>
                <th css={tw`w-[4%]`}>Std Bdl<br/>Size</th>
                <th css={tw`w-[4%]`}>Tag<br/>Disposition</th>
                <th css={tw`w-[4%]`}>Hide From<br/>Prime</th>
                <th css={tw`w-[4%]`}>Pcs/Bdl</th>
                <th css={tw`w-[4%]`}>Bundles</th>
                <th css={tw`w-[4%]`}>Std Bdl</th>
                <th css={tw`w-[4%]`}>Total<br/>Physical</th>
                <th css={tw`w-[4%]`}>Category</th>
                <th css={tw`w-[16%]`}>Avail Bdl<br/>Details</th>

              </tr>
            </thead>
     
            <tbody>

            {results?.inventorySummaryList.length && results.inventorySummaryList.length > 0 ? (
              results?.inventorySummaryList.map((item, index) => {
                return (
                  <tr key={index}>
                    <td css={tw`w-[4%]`}>{item?.itemNumber}</td>
                    <td css={tw`w-[14%]`}>{item?.productName}</td>
                    <td css={tw`w-[4%]`}>{item?.site}</td>
                    <td css={tw`w-[4%]`}>{item?.inventoryStatus}</td>
                    <td css={tw`w-[4%] text-right`}>{item?.lengthDescription}</td>
                    <td css={tw`w-[4%] text-right`}>
                      {item.totalWeight && item.totalWeight !== 0
                        ? numberFormatter(item.totalWeight)
                        : null}
                    </td>
                    <td css={tw`w-[4%] text-right`}>
                      {item.bundleWeight && item.bundleWeight !== 0
                        ? numberFormatter(item.bundleWeight)
                        : null}
                    </td>
                    <td css={tw`w-[4%] text-right`}>
                      {item.totalPieces && item.totalPieces !== 0
                        ? numberFormatter(item.totalPieces)
                        : null}
                      </td>
                    <td css={tw`w-[4%] text-right`}>
                      {item?.standardBundleSizePieces}
                    </td>
                    <td css={tw`w-[4%]`}>{item?.tagDisposition}</td>
                    <td css={tw`w-[4%]`}>{(item?.hideFromPortalPrime && "Yes") || "No"}</td>
                    <td css={tw`w-[4%] text-right`}>
                      {item?.bundleSize}
                    </td>
                    <td css={tw`w-[4%] text-right`}>
                      {item.numberOfBundles && item.numberOfBundles !== 0
                          ? numberFormatter(item.numberOfBundles)
                          : null}
                    </td>
                    <td css={tw`w-[4%]`}>{item?.standardBundleSize ? "Yes" : "No"}</td>
                    <td css={tw`w-[4%] text-right`}>
                      {item.totalPhysicalInventory && item.totalPhysicalInventory !== 0
                        ? numberFormatter(item.totalPhysicalInventory)
                        : null}
                    </td>
                    <td css={tw`w-[4%]`}>{item?.availableCategory}</td>
                    <td css={tw`w-[16%]`}>{item?.availableBundlesDetails}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td css={tw`text-nucor-gray !px-2 !py-3 hover:!bg-white`} colSpan={17}>
                  No results for the selected criteria.
                </td>
              </tr>
            )}
            </tbody>
          </table>
        </>
      }
    </div>
  );
};

export default PortalResultsList;
