/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import { FieldError } from "react-hook-form";
import tw from "twin.macro";

type Props = {
  children?: ReactNode;
  errors?: FieldError;
  showErrorMessage?: boolean;
} & React.ComponentProps<"input">;

const Input = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { errors, ...remainingProps } = props;

  return (
    <div className="inline relative group">
      <input
        ref={ref}
        css={[
         errors && tw`border-red-600!`,
        ]}
        {...remainingProps}
      />
      {errors && (
        <span className="hidden text-red-600 border border-red-600 px-1 py-[2px] rounded bg-white absolute left-0 -top-6 whitespace-nowrap group-focus-within:hidden group-hover:block">
          {errors.message}
        </span>
      )}
    </div>
  );
});
Input.displayName = "Input";
export default Input;
