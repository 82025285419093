/** @jsxImportSource @emotion/react */
import React, { ReactNode, useEffect, useState } from "react";
import tw from "twin.macro";
import POSearchForm from "./POSearchForm";
import POSearchResults from "./POSearchResults";
import { useAppDispatch } from "../../../../app/hooks";
import { clearPoSearchResults } from "../../../../store/document.reducer";
import { PDFFile } from "../../../../types/types";
import PDFEmbed from "../../../../components/molecules/PDFEmbed";
import TextButton from "../../../../components/atoms/TextButton";

type Props = {
  children?: ReactNode;
};

const POSearch: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const [file, setFile] = useState<PDFFile | null>(null);

  useEffect(() => {
    return () => {
      dispatch(clearPoSearchResults());
    };
  }, []);

  return (
    <div css={tw`text-xs p-2 w-full`}>
      <div css={tw`flex justify-between text-white font-bold bg-nucor-green text-xs py-1 px-2`}>
        <span css={tw`uppercase w-44`}>PO Document Search</span>
        {file && (
          <TextButton onClick={() => setFile(null)} css={tw`text-white`}>
            Back to List
          </TextButton>
        )}
        <span className="w-44"></span>
      </div>

      <div css={[file && tw`hidden`]}>
        <POSearchForm />
        <POSearchResults setFile={setFile} />
      </div>
      {file && (
        <PDFEmbed
          setFile={setFile}
          file={file}
          onCancel={() => setFile(null)}
        />
      )}
    </div>
  );
};

export default POSearch;
