/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import tw from "twin.macro";

type Props = {
  children?: ReactNode;
};

const Downloads: React.FC<Props> = () => {

  const epdUrl = `${process.env.REACT_APP_API_URL}/download/Nucor EPD HSS v5.0.pdf`;

  return (
    <div css={tw`text-xs`}>
      <a css={tw`font-semibold text-nucor-link hover:text-nucor-link-hover underline hover:no-underline focus-visible:text-nucor-link-hover focus-visible:outline-none`} rel="noreferrer" target="_blank" href={`${epdUrl}`}>
        Environmental Product Declaration (EPD) Hollow Structural Sections
      </a>
    </div>
  );
};

export default Downloads;
