/** @jsxImportSource @emotion/react */
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactNode } from "react";
import tw from "twin.macro";
import Button from "../../components/atoms/Button";
import Link from "../../components/atoms/Link";

type Props = {
  children?: ReactNode;
};

const ForgotPassword: React.FC<Props> = () => {
  return (
    <section css={tw`flex-grow`}>
      <div
        css={[
          tw`min-w-[45ch] p-6 shadow-lg mt-36 flex flex-col text-sm items-center`,
          { border: "1px solid #ddd" },
        ]}
      >
        <p css={tw`text-nucor-light-green self-start`}>
          <FontAwesomeIcon
            css={tw`mr-2 text-nucor-light-green`}
            icon={faQuestionCircle}
          />
          Forgot Password
        </p>
        <input
          css={tw`border focus-within:shadow my-2 px-2 py-1 outline-none mt-6 w-full`}
          type="text"
          name="username"
          placeholder="Username"
        />
        <input
          css={tw`border focus-within:shadow my-2 px-2 py-1 outline-none w-full`}
          type="email"
          name="email"
          placeholder="e-Mail"
        />
        <Button css={tw`text-base mt-2`} onClick={undefined}>
          Submit
        </Button>
        <Link
          css={tw`self-start mt-4 text-nucor-light-green bg-transparent border-0`}
          to={"/login"}
        >
          Return to login page
        </Link>
      </div>
    </section>
  );
};

export default ForgotPassword;
