/** @jsxImportSource @emotion/react */
import React, { ReactNode, useState } from "react";
import tw from "twin.macro";
import RollingScheduleModal from "./RollingScheduleModal";

type Props = {
  children?: ReactNode;
};

const RollingSchedule: React.FC<Props> = () => {
  const [showModal, setShowModal] = useState(false);
  const linkFonts = [tw`mx-[10px]`];

  const hideModalHandler = () => {
    setShowModal(false);
  };

  return (
    <div className="relative">
      <button
        css={[linkFonts, tw`mt-1`]}
        onClick={() => setShowModal((prev) => !prev)}
      >
        Rolling Schedule
      </button>
      {showModal && <RollingScheduleModal onCancel={hideModalHandler} />}
    </div>
  );
};

export default RollingSchedule;
