import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  CustomerPart,
  CustomerPartDetail,
  ErrorResponse,
} from "../types/types";
import { AxiosError } from "axios";
import { axiosWithAuth } from "../app/axios";
import { downloadFile } from "../helpers/downloadExcel";
import { isBlobError } from "../types/predicates";
import { blobToErrorResponse } from "../helpers/blobToErrorResponse";

export const fetchCustomerParts = createAsyncThunk<
  CustomerPart[],
  string,
  { rejectValue: string | ErrorResponse }
>("parts/fetchCustomerParts", async (customerId, { rejectWithValue }) => {
  try {
    const response = axiosWithAuth.get(`
      ${process.env.REACT_APP_API_URL}/parts?customerId=${customerId}`);
    return (await response).data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 400:
          return rejectWithValue("Invalid customer id supplied");
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 404:
          return rejectWithValue(error.response?.data);
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal Server Error");
        default:
          return rejectWithValue(
            "There was an issue retreiving customer parts list"
          );
      }
    }
    return rejectWithValue("There was an issue retreiving customer parts list");
  }
});

export const fetchCustomerPartDetails = createAsyncThunk<
  CustomerPartDetail[],
  string,
  { rejectValue: string | ErrorResponse }
>("parts/fetchCustomerPartDetails", async (customerId, { rejectWithValue }) => {
  try {
    const response = axiosWithAuth.get(`
      ${process.env.REACT_APP_API_URL}/parts/detail?customerId=${customerId}`);
    return (await response).data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 400:
          return rejectWithValue("Invalid customer id supplied");
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 404:
          return rejectWithValue(error.response?.data);
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal Server Error");
        default:
          return rejectWithValue(
            "There was an issue retreiving customer parts list"
          );
      }
    }
    return rejectWithValue("There was an issue retreiving customer parts list");
  }
});

export const fetchCustomerPartsExcel = createAsyncThunk<
  void,
  string,
  { rejectValue: string | ErrorResponse }
>("parts/fetchCustomerPartsExcel", async (customerId, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/parts/excel?customerId=${customerId}`,
      {
        responseType: "blob",
      }
    );
    const file = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const filename = response.headers["content-disposition"].split("=")[1];
    downloadFile(file, filename);
    return;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      if (isBlobError(error.response?.data) && error.response) {
        error.response.data = await blobToErrorResponse(error.response.data);
      }
      switch (error.response?.status) {
        case 400:
          return rejectWithValue("Invalid customer id supplied");
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 404:
          return rejectWithValue(error.response?.data);
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal Server Error");
        default:
          return rejectWithValue(
            "There was an issue retreiving customer parts excel sheet"
          );
      }
    }
    return rejectWithValue(
      "There was an issue retreiving customer partsexcel sheet"
    );
  }
});

export const fetchCustomerPartsPDF = createAsyncThunk<
  void,
  string,
  { rejectValue: string | ErrorResponse }
>("parts/fetchCustomerPartsPDF", async (customerId, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/parts/pdf?customerId=${customerId}`,
      {
        responseType: "blob",
      }
    );
    const file = new Blob([response.data], {
      type: "application/pdf",
    });
    const filename = response.headers["content-disposition"].split("=")[1];
    downloadFile(file, filename);
    return;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      if (isBlobError(error.response?.data) && error.response) {
        error.response.data = await blobToErrorResponse(error.response.data);
      }
      switch (error.response?.status) {
        case 400:
          return rejectWithValue("Invalid customer id supplied");
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 404:
          return rejectWithValue(error.response?.data);
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal Server Error");
        default:
          return rejectWithValue(
            "There was an issue retreiving customer parts PDF"
          );
      }
    }
    return rejectWithValue("There was an issue retreiving customer parts PDF");
  }
});
