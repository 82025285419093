/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import tw from "twin.macro";
import { deliveryIcon } from "../../../../../helpers/deliveryIcon";
import { LoadCartHeader } from "../../../../../types/types";
import LoadCartData from "./LoadCartData";
import ReviewLoadSite from "./ReviewLoadSite";
import { formatDateToShort } from "../../../../../helpers/dateHelpers";
import TextButton from "../../../../../components/atoms/TextButton";

type Props = {
  children?: ReactNode;
  onCancel: () => void;
  loadCart?: LoadCartHeader;
};

const ReleaseLoadConfirmation: React.FC<Props> = ({ onCancel, loadCart }) => {
  const transportIcon = deliveryIcon(loadCart?.deliveryMode);

  return (
    <>
      <div css={tw`text-white font-bold bg-nucor-green text-xs flex justify-between py-1 px-2`}>
        <span className="uppercase w-48">Review / Release Load</span>
        <TextButton onClick={onCancel} css={tw`text-white`}>
          Back to List
        </TextButton>
        <span className="w-48"></span>
      </div>
      <p css={tw`text-xs ml-2 my-4`}>
        Thank you for submitting your shipment release! Check your email for a
        confirmation.
      </p>
      <ul css={tw`list-disc ml-8 my-2 text-xs`}>
        {loadCart?.warnings.map((warning, index) => (
          <li css={tw`text-nucor-green`} key={index}>
            {warning}
          </li>
        ))}
      </ul>
      <h2 css={tw`text-red-600 text-lg font-bold flex-1 my-2`}>
        <span>Load Confirmation</span>{" "}
        <span>{loadCart?.loadIds?.join(", ")}</span>
      </h2>
      <div
        css={tw`h-[80px] bg-gradient-to-b from-nucor-gradient-outer via-nucor-gradient-inner to-nucor-gradient-outer flex justify-end p-2 text-xs`}
      >
        <div css={tw`flex-1 w-full flex flex-col`}>
          <p css={tw`flex-1`}>
            Requested Ship Date:{" "}
            {formatDateToShort(loadCart?.requestedShippingDate)}
          </p>
          <img css={tw`h-5 mx-auto`} src={transportIcon} />
        </div>
        <div css={tw`flex flex-col`}>
          <p css={tw`text-nucor-gray font-bold mr-4 text-right flex-1`}>
            {`${
              loadCart?.ntpNotifyCarrier ? "NTP" : "Customer"
            } will notify the carrier.`}
          </p>
          <LoadCartData />
        </div>
      </div>
      <div css={tw`bg-[#eee] p-2 flex text-xs`}>
        <div css={tw`flex-1`} />
        <div css={tw`w-[500px]`}>
          <p>{`Release Pro/Bill: ${loadCart?.proBillNumber ?? ""}`}</p>
          <p>{`Notes: ${loadCart?.notes ?? ""}`}</p>
        </div>
      </div>
      {loadCart?.shipToLoads.map((item) => (
        <ReviewLoadSite
          onCancel={onCancel}
          readOnly
          shipToLoad={item}
          key={item.shipTo.id}
        />
      ))}
    </>
  );
};

export default ReleaseLoadConfirmation;
