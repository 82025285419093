import React, { ReactNode } from "react";
import QuoteBrowse from "./quoteBrowse";
import QuoteSearch from "./quoteSearch";
import BrowseSearch from "../BrowseSearch";

type Props = {
  children?: ReactNode;
};

const QuoteLeft: React.FC<Props> = () => {
  return (
    <BrowseSearch
      browse={<QuoteBrowse />}
      search={<QuoteSearch />}
    />
  );
};

export default QuoteLeft;
