import { createAsyncThunk } from "@reduxjs/toolkit";
import { PageTimeSpentPayload } from "../hooks/usePageTracking";
import axios from "axios";
import { CartHeader } from "../types/types";
import { RootState } from "./store";
import { userType } from "../helpers/userType";

export const sendPageTimeSpent = createAsyncThunk<
  void,
  Partial<PageTimeSpentPayload>,
  { rejectValue: string }
>("analytics/sendPageTimeSpent", async (pageDetails, { rejectWithValue }) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_ANALYTICS_URL}/InsertUserPageTimeSpent`,
      pageDetails
    );
    return;
  } catch (error: unknown) {
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    } else {
      return rejectWithValue("There was an issue sending the page time spent");
    }
  }
});

export type LoginAnalyticsPayload = {
  Login: string;
  UserType: string;
  LoginDate: string;
  Source: string;
};

export const sendLoginAnalytics = createAsyncThunk<
  void,
  Partial<LoginAnalyticsPayload>,
  { rejectValue: string }
>("analytics/sendLoginAnalytics", async (loginDetails, { rejectWithValue }) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_ANALYTICS_URL}/InsertUserLogins`,
      loginDetails
    );
    return;
  } catch (error: unknown) {
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    } else {
      return rejectWithValue("There was an issue sending the login analytics");
    }
  }
});

export const formatDateToAnalyticsFormat = (date: Date) => {
  const datePart = date.toLocaleDateString().split("T")[0];
  const timePart = date.toLocaleTimeString();
  return `${datePart} ${timePart}`;
};

type OrderAnalyticsRequest = CartHeader & {
  login: string;
};

export const sendShoppingCartAnalytics = createAsyncThunk<
  void,
  OrderAnalyticsRequest,
  { rejectValue: string }
>(
  "analytics/sendShoppingCartAnalytics",
  async (orderDetails, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      await axios.post(
        `${process.env.REACT_APP_ANALYTICS_URL}/InsertProductsOrder`,
        {
          UserType: userType(state.user.currentContext),
          ...orderDetails,
        }
      );
      return;
    } catch (error: unknown) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      } else {
        return rejectWithValue(
          "There was an issue sending the shopping cart analytics"
        );
      }
    }
  }
);
