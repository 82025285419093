/** @jsxImportSource @emotion/react */
import React, { ReactNode, useEffect, useState } from "react";
import tw from "twin.macro";
import { SalesOrderItem } from "../../../../../types/types";
import ConfirmationItem from "./ConfirmationItem";
import TextButton from "../../../../../components/atoms/TextButton";
import observable from "./observable";

type Props = {
  children?: ReactNode;
  rollingItems?: SalesOrderItem[];
  onLoading?: (loading: boolean) => void;
  onEdit: (item?: SalesOrderItem) => void;
};

const RollingItemConfirmation: React.FC<Props> = ({
  rollingItems,
  onLoading,
  onEdit,
}) => {
  const [currentItemIndex, setCurrentItemIndex] = useState(0);

  const numberOfItems = rollingItems?.length;
  const currentRollingItem = rollingItems?.at(currentItemIndex);

  useEffect(() => {
    if (!numberOfItems) return;
    if (currentItemIndex > numberOfItems - 1) {
      setCurrentItemIndex(numberOfItems - 1);
    }
  }, [numberOfItems]);

  useEffect(() => {
    const goToLast = (len: number) => {
      if (!len) return;
      setCurrentItemIndex(len - 1);
    };
    observable.subscribe(goToLast);
    return () => {
      observable.unsubscribe(goToLast);
    };
  }, []);

  return (
    <>
      <ConfirmationItem
        onLoading={onLoading}
        onEdit={onEdit}
        rollingItem={currentRollingItem}
        itemNumber={currentItemIndex}
      />
      <div css={tw`flex mt-2 items-center px-4`}>
        <div css={tw`text-left flex-1`}>
          <TextButton
            css={currentItemIndex === 0 && tw`hidden`}
            onClick={() => {
              if (currentItemIndex === 0) return;
              setCurrentItemIndex((prev) => prev - 1);
            }}
          >
            &lt; Previous
          </TextButton>
        </div>
        <div css={tw`flex-1 text-right`}>
          <TextButton
            onClick={() => {
              if (!numberOfItems) return;
              if (currentItemIndex >= numberOfItems - 1) return;
              setCurrentItemIndex((prev) => prev + 1);
            }}
            css={
              numberOfItems &&
              currentItemIndex === numberOfItems - 1 &&
              tw`hidden`
            }
          >
            Next &gt;
          </TextButton>
        </div>
      </div>
    </>
  );
};

export default RollingItemConfirmation;
