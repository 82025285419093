/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import tw from "twin.macro";
import { useAppSelector } from "../../../../../app/hooks";
import { numberFormatter } from "../../../../../helpers/numberFormat";

type Props = {
  children?: ReactNode;
};

const LoadCartData: React.FC<Props> = () => {
  const loadCart = useAppSelector((state) => state.shipping.loads.loadCart);

  return (
    <div css={tw`border border-[#bbb] w-[500px] flex self-end`}>
      <div
        css={tw`bg-nucor-light-green text-white font-bold w-[90px] py-1 text-center`}
      >
        Shipping Cart
      </div>
      <div
        css={tw`flex text-xs text-nucor-gray justify-evenly w-full relative px-2`}
      >
        <div css={tw`flex-1 p-[2px]`}>
          <label>Capacity</label>
          <p css={tw`font-bold mt-1`}>{loadCart?.deliveryMode}</p>
        </div>
        <div css={tw`flex-1 p-[2px]`}>
          <label>Loaded %</label>
          <p css={tw`font-bold mt-1`}>{loadCart?.percentLoaded}%</p>
        </div>
        <div css={tw`flex-1 p-[2px]`}>
          <label>Loaded Lbs</label>
          <p css={tw`font-bold mt-1`}>
            {`${numberFormatter(loadCart?.loadedWeight)} of
            ${numberFormatter(loadCart?.capacity)}`}
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoadCartData;
