/** @jsxImportSource @emotion/react */
import React from 'react';
import tw from "twin.macro";
import { QuickQuoteLine } from '../../../../types/types';
import { currencyFormatter } from '../../../../helpers/currencyFormatter';
import { formatDateToShort } from '../../../../helpers/dateHelpers';

type Props = {
  itemkey: number;
  line: QuickQuoteLine;
  editLineId: number | null;
  onEditClick: (id: number) => void;
  onRemoveClick: (id: number) => void;
  onEditDetailsClick: (id: number) => void;
  addingRow: boolean;
  currency: string | undefined;
};

  const QuoteReadOnlyLine: React.FC<Props> = ({ itemkey, line, onEditClick, onRemoveClick, onEditDetailsClick, editLineId, addingRow, currency }) => {

    return (
        <tr key={itemkey}>
          <td css={tw`text-center`}>{line.ordinal}</td>
          <td>
            {line.itemType === "Part" && `Part: ${line.partNo} - ${line.itemName}`}
            {line.itemType === "Item" && `${line.itemId} - ${line.itemName}`}
            {line.itemType === "Text" && `${line.itemName}`}
          </td>
          <td css={tw`text-right`}>{line.lengthDescription}</td>
          <td>{line.quantityDescription}</td>
          <td css={tw`text-right`}>{line.formattedTotalPieces}</td>
          <td css={tw`text-right`}>{line.formattedTotalWeight}</td>
          <td css={tw`text-right`}>{line.formattedTotalLengthFt}</td>
          <td>{formatDateToShort(line.customerRequestDate)}</td>
          {/* <td>{formatDateToShort(line.estimatedReadyDate)}</td> */}
          <td css={tw`text-right`}>{currencyFormatter(line.unitPrice)} {line.unitPriceUOM} {currency}</td>
          <td css={tw`text-right`}>
            {line.totalCost ? `${currencyFormatter(line.totalCost)} ${currency}`: ""}
          </td>
          <td css={[tw`text-center`, line.valid === false && tw`border-red-500 border-2!`]}>
          {(line.detail.rollingSelections?.length > 0 || line.detail.stockSelections?.length > 0) ? (
            <button
              css={[tw`text-xs`, !editLineId && !addingRow && tw`font-semibold text-nucor-link underline hover:(text-nucor-link-hover no-underline) focus-visible:text-nucor-link-hover focus-visible:outline-none`]}
              onClick={() => onEditDetailsClick(line.id)}
              disabled={editLineId !== null || addingRow}
            >
              {line.detail.stockSelections?.length > 0 ? `${line.detail.stockSelections?.length} Stock` : ""}&nbsp;
              {line.detail.rollingSelections?.length > 0 ? `${line.detail.rollingSelections?.length} Rolling` : ""}
            </button>
          ) : ( 
            <button css={[tw`text-xs`, !editLineId && !addingRow && tw`font-semibold text-nucor-link underline focus-visible:text-nucor-link-hover focus-visible:outline-none`]}
              onClick={() => onEditDetailsClick(line.id)}
              disabled={editLineId !== null || addingRow}
            >  
              Add
            </button>
          )}
          </td>
          <td css={tw`text-center`}>
            <button 
              css={[tw`text-xs`, !editLineId && !addingRow && tw`font-semibold text-nucor-link underline hover:(text-nucor-link-hover no-underline) focus-visible:text-nucor-link-hover focus-visible:outline-none`]}
              disabled={editLineId !== null || addingRow}
              onClick={() => {
                if (line.id !== undefined) {
                  onEditClick(line.id);
                }
              }}>Edit
            </button>
            <button 
              css={[tw`ml-2 text-xs`, !editLineId && !addingRow && tw`font-semibold text-nucor-link underline hover:(text-nucor-link-hover no-underline) focus-visible:text-nucor-link-hover focus-visible:outline-none`]}
              disabled={editLineId !== null || addingRow}
              onClick={() => {
                onRemoveClick(line.id);
              }}>Remove
            </button>
          </td>
        </tr>
    );
  };

export default QuoteReadOnlyLine;