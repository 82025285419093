import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { axiosWithAuth } from "../app/axios";
import { objectToQueryString } from "../helpers/objectToQueryString";
import {
  PackingSlipSetup,
  InvoiceDocumentSetup,
  OrdersDocumentSetup,
  PackingSlipPageResult,
  TestCertsSetup,
  InvoiceDocumentPagedResult,
  TestCertPagedResult,
  SearchCriteria,
  ClaimsDocumentsSetup,
  PackingSlipDataForClaim,
  ClaimsInput,
  OrderSummaryPagedResult,
  OpenLoadsSetup,
  LoadSummary,
  QuoteSummaryPagedResult,
  QuoteSelectionData,
  POSearchSelectionData,
  SearchDocument,
  DocumentType,
  DateString,
  PDFFile,
  QuickQuoteSelectionData,
  QuickQuoteSummaryResult,
  ClaimsPagedResults,
  ClaimDetailResults,
  ClaimsSearchSetup,
  QuickQuoteView,
  QuickQuoteSetup,
  QQLineUpdate,
  QuickQuoteLineDetail,
  QQLineDetailParams,
  AddQuoteForm,
  QQSetupRequestParams,
  QQPriceUpdateParams,
  QQDateUpdateParams,
  QQItemPricingRequestParams,
  QuickQuoteItemPricing,
} from "../types/types";
import { downloadFile } from "../helpers/downloadExcel";
import { ErrorResponse } from "../types/types";
import { isBlobError } from "../types/predicates";
import { blobToErrorResponse } from "../helpers/blobToErrorResponse";
import { setOpenLoadsLastCriteria } from "./document.reducer";
// ---- Documents - Orders ----


export const fetchOrderDocumentsSetup = createAsyncThunk<
  OrdersDocumentSetup,
  string,
  { rejectValue: string | ErrorResponse }
>(
  "document/fetchOrdersDocumentsSetup",
  async (customerId, { rejectWithValue }) => {
    try {
      const response = await axiosWithAuth.get(
        `${process.env.REACT_APP_API_URL}/document/order/setup?customerId=${customerId}`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue(error.response?.data);
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue(
              "There was an issue fetching orders documents setup"
            );
        }
      } else {
        return rejectWithValue(
          "There was an issue fetching orders documents setup"
        );
      }
    }
  }
);

export type FetchOrderDocumentDetailPDFRequest = {
  customerId: string;
  id: string;
};

export const fetchOrderDocumentDetailPDF = createAsyncThunk<
  PDFFile,
  FetchOrderDocumentDetailPDFRequest,
  { rejectValue: string | ErrorResponse }
>(
  "document/fetchOrderDocumentDetailPDF",
  async (params, { rejectWithValue }) => {
    const { customerId, id } = params;
    try {
      const response = await axiosWithAuth.get(
        `${process.env.REACT_APP_API_URL}/document/order/${customerId}/${id}/pdf`,
        {
          responseType: "blob",
        }
      );
      const file = new Blob([response.data], {
        type: "application/pdf",
      });
      const fileUrl = URL.createObjectURL(file);
      const fileName = response.headers["content-disposition"].split("=")[1];
      // downloadFile(file, filename);
      return { fileUrl, fileName, documentType: "order", id };
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (isBlobError(error.response?.data) && error.response) {
          error.response.data = await blobToErrorResponse(error.response.data);
        }
        switch (error.response?.status) {
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue(
              "Customer or order not found. Contact your Account Rep."
            );
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue(
              "There was an issue fetching orders documents detail PDF"
            );
        }
      } else {
        return rejectWithValue(
          "There was an issue fetching orders documents detail PDF"
        );
      }
    }
  }
);

type SearchOrderDocumentOrderRequest = {
  customerId?: string;
  criteria?: SearchCriteria;
};

export const searchOrderDocumentOrder = createAsyncThunk<
  OrderSummaryPagedResult,
  SearchOrderDocumentOrderRequest,
  { rejectValue: string | ErrorResponse }
>("document/searchOrderDocumentOrder", async (params, { rejectWithValue }) => {
  try {
    const queryString = objectToQueryString({
      ...params.criteria,
      customerId: params.customerId,
    });
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/document/order/search${queryString}`
    );
    const result = response.data;
    result.lastCriteria = params.criteria;
    return result;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 400:
          return rejectWithValue(error.response?.data ?? "Invalid criteria");
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 404:
          return rejectWithValue("Customer not found");
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue(
            "There was an issue searching orders documents lines"
          );
      }
    } else {
      return rejectWithValue(
        "There was an issue searching orders documents lines"
      );
    }
  }
});

type FetchOrderDocumentExcelRequest = {
  customerId: string;
  criteria?: SearchCriteria;
};


export const fetchOrderDocumentExcel = createAsyncThunk<
  void,
  FetchOrderDocumentExcelRequest,
  { rejectValue: string | ErrorResponse }
>("document/fetchOrderExcel", async (params, { rejectWithValue }) => {
  try {
    const query = objectToQueryString(params.criteria);
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/document/order/${params.customerId}/excel${query}`,
      {
        responseType: "blob",
      }
    );
    const file = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const filename = response.headers["content-disposition"].split("=")[1];
    downloadFile(file, filename);
    return;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      if (isBlobError(error.response?.data) && error.response) {
        error.response.data = await blobToErrorResponse(error.response.data);
      }
      switch (error.response?.status) {
        case 400:
          return rejectWithValue("Invalid criteria");
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");

        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue(
            "There was an issue retrieving order documents excel sheet"
          );
      }
    } else {
      return rejectWithValue(
        "There was an issue retrieving order documents excel sheet"
      );
    }
  }
});

// ---- Documents - Invoice ----

export const fetchInvoiceDocumentsSetup = createAsyncThunk<
  InvoiceDocumentSetup,
  string,
  { rejectValue: string | ErrorResponse }
>(
  "document/fetchInvoiceDocumentsSetup",
  async (customerId, { rejectWithValue }) => {
    try {
      const response = await axiosWithAuth.get(
        `${process.env.REACT_APP_API_URL}/document/invoice/setup?customerId=${customerId}`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue(error.response?.data);
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue(
              "There was an issue fetching invoice documents setup"
            );
        }
      } else {
        return rejectWithValue(
          "There was an issue fetching invoice documents setup"
        );
      }
    }
  }
);

type SearchInvoiceDocumentsRequest = {
  customerId?: string;
  criteria?: SearchCriteria;
};

export const searchInvoiceDocuments = createAsyncThunk<
  InvoiceDocumentPagedResult,
  SearchInvoiceDocumentsRequest,
  { rejectValue: string | ErrorResponse }
>("document/searchInvoiceDocuments", async (params, { rejectWithValue }) => {
  try {
    const queryString = objectToQueryString({
      ...params.criteria,
      customerId: params.customerId,
    });
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/document/invoice/search${queryString}`
    );
    const result = response.data;
    result.query = queryString;
    result.lastCriteria = params.criteria;
    return result;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 400:
          return rejectWithValue(error.response?.data ?? "Invalid criteria");
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue(
            "There was an issue searching invoice documents"
          );
      }
    } else {
      return rejectWithValue("There was an issue searching invoice documents");
    }
  }
});

export const fetchInvoiceDocumentExcel = createAsyncThunk<
  void,
  string,
  { rejectValue: string | ErrorResponse }
>("document/fetchInvoiceDocument", async (query, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/document/invoice/search/excel${query}`,
      {
        responseType: "blob",
      }
    );
    const file = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const filename = response.headers["content-disposition"].split("=")[1];
    downloadFile(file, filename);
    return;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      if (isBlobError(error.response?.data) && error.response) {
        error.response.data = await blobToErrorResponse(error.response.data);
      }
      switch (error.response?.status) {
        case 400:
          return rejectWithValue(error.response?.data ?? "Invalid criteria");
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue(
            "There was an issue retrieving invoice document excel sheet"
          );
      }
    } else {
      return rejectWithValue(
        "There was an issue retrieving invoice document excel sheet"
      );
    }
  }
});

export type FetchInvoiceDocumentDetailPDFRequest = {
  customerId: string;
  id: string;
};

export const fetchInvoiceDocumentDetailPDF = createAsyncThunk<
  PDFFile,
  FetchInvoiceDocumentDetailPDFRequest,
  { rejectValue: string | ErrorResponse }
>(
  "document/fetchInvoiceDocumentDetailPDF",
  async (params, { rejectWithValue }) => {
    const { customerId, id } = params;
    try {
      const response = await axiosWithAuth.get(
        `${process.env.REACT_APP_API_URL}/document/invoice/${customerId}/${id}/pdf`,
        {
          responseType: "blob",
        }
      );
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileUrl = URL.createObjectURL(file);
      const fileName = response.headers["content-disposition"].split("=")[1];
      return { fileUrl, fileName, documentType: "invoice", id };
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (isBlobError(error.response?.data) && error.response) {
          error.response.data = await blobToErrorResponse(error.response.data);
        }
        switch (error.response?.status) {
          case 400:
            return rejectWithValue("Invalid id supplied");
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue(
              "Invoice not found. Contact your Account Rep."
            );
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue(
              "There was an issue fetching orders documents detail PDF"
            );
        }
      } else {
        return rejectWithValue(
          "There was an issue fetching orders documents detail PDF"
        );
      }
    }
  }
);

// ---- Documents - Bill of Lading (Packing Slip) ----

export const fetchPackingSlipSetup = createAsyncThunk<
  PackingSlipSetup,
  string,
  { rejectValue: string | ErrorResponse }
>("document/fetchPackingSlipSetup", async (customerId, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/document/ps/setup?customerId=${customerId}`
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 404:
          return rejectWithValue(error.response?.data);
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue(
            "There was an issue fetching bill of lading documents setup"
          );
      }
    } else {
      return rejectWithValue(
        "There was an issue fetching bill of lading documents setup"
      );
    }
  }
});

type SearchPackingSlipsRequest = {
  customerId?: string;
  criteria?: SearchCriteria;
};

export const searchPackingSlips = createAsyncThunk<
  PackingSlipPageResult,
  SearchPackingSlipsRequest,
  { rejectValue: string | ErrorResponse }
>(
  "documents/searchPackingSlips",
  async (params: SearchPackingSlipsRequest, { rejectWithValue }) => {
    try {
      const response = await axiosWithAuth.get(
        `${
          process.env.REACT_APP_API_URL
        }/document/ps/search${objectToQueryString({
          customerId: params.customerId,
          ...params.criteria,
        })}`
      );
      const result = response.data;
      result.lastCriteria = params.criteria;
      return result;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 400:
            return rejectWithValue(error.response?.data ?? "Invalid criteria");
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue(
              "There was an issue searching bill of lading documents"
            );
        }
      } else {
        return rejectWithValue(
          "There was an issue searching bill of lading documents"
        );
      }
    }
  }
);

export type ExportPackingSlipPDFRequest = {
  customerId: string;
  id: string;
};

export const exportPackingSlipPDF = createAsyncThunk<
  PDFFile,
  ExportPackingSlipPDFRequest,
  { rejectValue: string | ErrorResponse }
>("document/exportPackingSlipPDF", async (params, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/document/ps/${params.customerId}/${params.id}/pdf`,
      {
        responseType: "blob",
      }
    );
    const file = new Blob([response.data], { type: "application/pdf" });
    const fileUrl = URL.createObjectURL(file);
    const fileName = response.headers["content-disposition"].split("=")[1];
    return { fileUrl, fileName, documentType: "packingslip", id: params.id };
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      if (isBlobError(error.response?.data) && error.response) {
        error.response.data = await blobToErrorResponse(error.response.data);
      }
      switch (error.response?.status) {
        case 400:
          return rejectWithValue("Invalid id supplied");
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 404:
          return rejectWithValue(
            "Packing slip not found. Contact your Account Rep."
          );
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue(
            "There was an issue fetching packing slip PDF"
          );
      }
    } else {
      return rejectWithValue("There was an issue fetching packing slip PDF");
    }
  }
});

type ExportPackingSlipExcel = {
  customerId: string;
  criteria?: SearchCriteria;
};

export const exportPackingSlipExcel = createAsyncThunk<
  void,
  ExportPackingSlipExcel,
  { rejectValue: string | ErrorResponse }
>("document/exportPackingSlipExcel", async (params, { rejectWithValue }) => {
  try {
    const query = objectToQueryString({
      customerId: params.customerId,
      ...params.criteria,
    });
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/document/ps/search/excel${query}`,
      {
        responseType: "blob",
      }
    );
    const file = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const filename = response.headers["content-disposition"].split("=")[1];
    downloadFile(file, filename);
    return;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      if (isBlobError(error.response?.data) && error.response) {
        error.response.data = await blobToErrorResponse(error.response.data);
      }
      switch (error.response?.status) {
        case 400:
          return rejectWithValue("Invalid criteria");
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue(
            "There was an issue retrieving packing slip excel sheet"
          );
      }
    } else {
      return rejectWithValue(
        "There was an issue retrieving packing slip excel sheet"
      );
    }
  }
});

// ---- Documents - Test Certificates ----

export const fetchTestCertDocumentsSetup = createAsyncThunk<
  TestCertsSetup,
  string,
  { rejectValue: string | ErrorResponse }
>(
  "document/fetchTestCertDocumentsSetup",
  async (customerId, { rejectWithValue }) => {
    try {
      const response = await axiosWithAuth.get(
        `${process.env.REACT_APP_API_URL}/document/tc/setup?customerId=${customerId}`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue(error.response?.data);
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue(
              "There was an issue fetching test certificate documents setup"
            );
        }
      } else {
        return rejectWithValue(
          "There was an issue fetching test certificate documents setup"
        );
      }
    }
  }
);

type SearchTestCertRequest = {
  customerId?: string;
  criteria?: SearchCriteria;
};

export const searchTestCertDocument = createAsyncThunk<
  TestCertPagedResult,
  SearchTestCertRequest,
  { rejectValue: string | ErrorResponse }
>(
  "documents/searchTestCertDocument",
  async (params: SearchTestCertRequest, { rejectWithValue }) => {
    try {
      const queryString = objectToQueryString({
        ...params.criteria,
        customerId: params.customerId,
      });
      const response = await axiosWithAuth.get(
        `${process.env.REACT_APP_API_URL}/document/tc/search${queryString}`
      );
      const result = response.data;
      result.lastCriteria = params.criteria;
      return result;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 400:
            return rejectWithValue(error.response?.data ?? "Invalid criteria");
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue("Customer not found");
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue(
              "There was an issue searching test certificate documents"
            );
        }
      } else {
        return rejectWithValue(
          "There was an issue searching test certificate documents"
        );
      }
    }
  }
);

export type ExportTestCertPDFRequest = {
  customerId: string;
  id: string;
};

export const exportTestCertPDF = createAsyncThunk<
  PDFFile,
  ExportTestCertPDFRequest,
  { rejectValue: string | ErrorResponse }
>("document/exportTestCertPDF", async (params, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/document/tc/${params.customerId}/${params.id}/pdf`,
      {
        responseType: "blob",
      }
    );
    const file = new Blob([response.data], { type: "application/pdf" });
    const fileUrl = URL.createObjectURL(file);
    const filename = response.headers["content-disposition"].split("=")[1];
    return {
      fileUrl,
      fileName: filename,
      documentType: "testcert",
      id: params.id,
    };
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      if (isBlobError(error.response?.data) && error.response) {
        error.response.data = await blobToErrorResponse(error.response.data);
      }
      switch (error.response?.status) {
        case 400:
          return rejectWithValue("Invalid id supplied");
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 404:
          return rejectWithValue(
            "Test certificate not found. Contact your Account Rep."
          );
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue(
            "There was an issue fetching test certificate PDF"
          );
      }
    } else {
      return rejectWithValue(
        "There was an issue fetching test certificate PDF"
      );
    }
  }
});

type ExportTestCertExcel = {
  customerId: string;
  criteria?: SearchCriteria;
};

export const exportTestCertExcel = createAsyncThunk<
  void,
  ExportTestCertExcel,
  { rejectValue: string | ErrorResponse }
>("document/exportTestCertExcel", async (params, { rejectWithValue }) => {
  try {
    const query = objectToQueryString(params.criteria);
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/document/tc/${params.customerId}/search/excel${query}`,
      {
        responseType: "blob",
      }
    );
    const file = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const filename = response.headers["content-disposition"].split("=")[1];
    downloadFile(file, filename);
    return;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      if (isBlobError(error.response?.data) && error.response) {
        error.response.data = await blobToErrorResponse(error.response.data);
      }
      switch (error.response?.status) {
        case 400:
          return rejectWithValue("Invalid criteria");
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue(
            "There was an issue retrieving test certificate excel sheet"
          );
      }
    } else {
      return rejectWithValue(
        "There was an issue retrieving test certificate excel sheet"
      );
    }
  }
});

export type FetchCombinedTestCertPDFRequest = {
  customerId: string;
  id: string;
};

export const fetchCombinedTestCertPDF = createAsyncThunk<
  PDFFile,
  FetchCombinedTestCertPDFRequest,
  { rejectValue: string | ErrorResponse }
>("document/fetchCombinedTestCertPDF", async (params, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/document/tc/${params.customerId}/${params.id}/combined_pdf`,
      {
        responseType: "blob",
      }
    );
    const file = new Blob([response.data], { type: "application/pdf" });
    const fileUrl = URL.createObjectURL(file);
    const filename = response.headers["content-disposition"].split("=")[1];
    return {
      fileUrl,
      fileName: filename,
      documentType: "combinedtestcert",
      id: params.id,
    };
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      if (isBlobError(error.response?.data) && error.response) {
        error.response.data = await blobToErrorResponse(error.response.data);
      }
      switch (error.response?.status) {
        case 400:
          return rejectWithValue("Invalid id supplied");
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 404:
          return rejectWithValue(
            "Customer, packing slip or file not found. Contact your Account Rep."
          );
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue(
            "There was an issue fetching combined test certificate PDF"
          );
      }
    } else {
      return rejectWithValue(
        "There was an issue fetching combined test certificate PDF"
      );
    }
  }
});

export const fetchCombinedInvoicePDF = createAsyncThunk<
  PDFFile,
  FetchCombinedTestCertPDFRequest,
  { rejectValue: string | ErrorResponse }
>("document/fetchCombinedTestCertPDF", async (params, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/document/invoice/${params.customerId}/${params.id}/combined_pdf`,
      {
        responseType: "blob",
      }
    );
    const file = new Blob([response.data], { type: "application/pdf" });
    const fileUrl = URL.createObjectURL(file);
    const filename = response.headers["content-disposition"].split("=")[1];
    return {
      fileUrl,
      fileName: filename,
      documentType: "combinedtestcert",
      id: params.id,
    };
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      if (isBlobError(error.response?.data) && error.response) {
        error.response.data = await blobToErrorResponse(error.response.data);
      }
      switch (error.response?.status) {
        case 400:
          return rejectWithValue("Invalid id supplied");
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 404:
          return rejectWithValue(
            "Customer, combined invoices or file not found. Contact your Account Rep."
          );
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue(
            "There was an issue fetching combined invoices PDF"
          );
      }
    } else {
      return rejectWithValue(
        "There was an issue fetching combined invoices PDF"
      );
    }
  }
});

// --- Claims section

export const fetchClaimsDocumentsSetup = createAsyncThunk<
  ClaimsDocumentsSetup,
  string,
  { rejectValue: string | ErrorResponse }
>(
  "document/fetchClaimsDocumentsSetup",
  async (customerId, { rejectWithValue }) => {
    try {
      const response = await axiosWithAuth.get(
        `${process.env.REACT_APP_API_URL}/document/claim/${customerId}/setup`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue(error.response?.data);
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue("There was an error setup in claims page");
        }
      } else {
        return rejectWithValue("There was an error setup in claims page");
      }
    }
  }
);

export const fetchClaimsSearchSetup = createAsyncThunk<
  ClaimsSearchSetup,
  string,
  { rejectValue: string | ErrorResponse }
>(
  "document/fetchClaimsSearchSetup",
  async (customerId, { rejectWithValue }) => {
    try {
      const response = await axiosWithAuth.get(
        `${process.env.REACT_APP_API_URL}/document/claim/search/setup`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue(error.response?.data);
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue("There was an error setup in claims search page");
        }
      } else {
        return rejectWithValue("There was an error setup in claims search page");
      }
    }
  }
);
export const fetchClaimDetail = createAsyncThunk<
  ClaimDetailResults,
  string,
  { rejectValue: string | ErrorResponse }
>(
  "document/fetchClaimDetail",
  async (caseId, { rejectWithValue }) => {
    try {
      const response = await axiosWithAuth.get(
        `${process.env.REACT_APP_API_URL}/document/claim/detail/${caseId}`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue(error.response?.data);
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue("There was an error setup in claims search page");
        }
      } else {
        return rejectWithValue("There was an error setup in claims search page");
      }
    }
  }
);


type SearchClaimsRequest = {
  customerId: string;
  criteria?: SearchCriteria;
};

export const fetchClaims = createAsyncThunk<
  ClaimsPagedResults,
  SearchClaimsRequest,
  { rejectValue: string | ErrorResponse }
>("document/fetchClaims", async (params, { rejectWithValue }) => {
  try {
    const queryString = objectToQueryString({
      ...params.criteria,
      customerId: params.customerId,
    });
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/document/claim/search${queryString}`
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 400:
          return rejectWithValue(error.response?.data ?? "Invalid criteria");
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue(
            "There was an issue searching claims"
          );
      }
    } else {
      return rejectWithValue("There was an issue searching claims");
    }
  }
});

type FetchClaimsExcel = {
  customerId: string;
  criteria?: SearchCriteria;
};

export const fetchClaimsExcel = createAsyncThunk<
  void,
  FetchClaimsExcel,
  { rejectValue: string | ErrorResponse }
>("document/fetchClaimsExcel", async (params, { rejectWithValue }) => {
  try {
    const query = objectToQueryString({
      customerId: params.customerId,
      ...params.criteria,
    });
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/document/claim/search/excel${query}`,
      {
        responseType: "blob",
      }
    );
    const file = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const filename = response.headers["content-disposition"].split("=")[1];
    downloadFile(file, filename);
    return;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      if (isBlobError(error.response?.data) && error.response) {
        error.response.data = await blobToErrorResponse(error.response.data);
      }
      switch (error.response?.status) {
        case 400:
          return rejectWithValue("Invalid criteria");
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue(
            "There was an issue retrieving claims excel sheet"
          );
      }
    } else {
      return rejectWithValue(
        "There was an issue retrieving claims excel sheet"
      );
    }
  }
});




type SearchClaimsPackingSlipDataRequest = {
  customerId: string;
  body: {
    packingSlipNumber: string;
    line?: number;
  };
};

export const searchClaimsPackingSlipData = createAsyncThunk<
  PackingSlipDataForClaim,
  SearchClaimsPackingSlipDataRequest,
  { rejectValue: string | ErrorResponse }
>(
  "document/searchClaimsPackingSlipData",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosWithAuth.get(
        `${process.env.REACT_APP_API_URL}/document/claim/${
          params.customerId
        }/ps${objectToQueryString(params.body)}`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 400:
            return rejectWithValue(error.response?.data);
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue(error.response?.data);
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue(
              "There was an error retrieving claims packing slip data"
            );
        }
      } else {
        return rejectWithValue(
          "There was an error retrieving claims packing slip data"
        );
      }
    }
  }
);

type SubmitClaimFormRequest = {
  customerId: string;
  body: ClaimsInput;
};

export const submitClaimForm = createAsyncThunk<
  ClaimsInput,
  SubmitClaimFormRequest,
  { rejectValue: string | ErrorResponse }
>(
  "document/submitClaimForm",
  async (params: SubmitClaimFormRequest, { rejectWithValue }) => {
    try {
      // if params.body.packingSlipItem = 0, then we need to remove params.body.packingSlipItem from the params.body
      if (params.body.packingSlipItem === 0) {
        delete params.body.packingSlipItem;
      }
      const response = await axiosWithAuth.post(
        `${process.env.REACT_APP_API_URL}/document/claim/${params.customerId}`,
        params.body
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 400:
            return rejectWithValue(error.response?.data);
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue(error.response?.data);
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue(
              `There was an error submitting claims form.`
            );
        }
      } else {
        return rejectWithValue("There was an error submitting claims form");
      }
    }
  }
);

// Open Loads

export const fetchOpenLoadsSetup = createAsyncThunk<
  OpenLoadsSetup,
  string,
  { rejectValue: string | ErrorResponse }
>(
  "document/fetchOpenLoadsSetup",
  async (customerId: string, { rejectWithValue }) => {
    try {
      const response = await axiosWithAuth.get(
        `${process.env.REACT_APP_API_URL}/document/ol/setup?customerId=${customerId}`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue(error.response?.data);
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal Server Error");
          default:
            rejectWithValue("There was an issue fetching ");
        }
      }
    }
  }
);

type SearchOpenLoadsRequest = {
  customerId?: string;
  criteria?: SearchCriteria;
};

export const searchOpenLoads = createAsyncThunk<
  LoadSummary[],
  SearchOpenLoadsRequest,
  { rejectValue: string | ErrorResponse }
>("document/searchOpenLoads", async (params, { rejectWithValue, dispatch }) => {
  try {
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/document/ol/search${objectToQueryString(
        { ...params.criteria, customerId: params.customerId }
      )}`
    );
    dispatch(setOpenLoadsLastCriteria(params.criteria));
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 400:
          return rejectWithValue(error.response?.data);
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 404:
          return rejectWithValue("Customer, packing slip or file not found");
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal Server Error");
        default:
          rejectWithValue("There was an issue fetching ");
      }
    }
  }
});

export type FetchOpenLoadPDFRequest = {
  customerId: string;
  id: string;
};

export const fetchOpenLoadPDF = createAsyncThunk<
  PDFFile,
  FetchOpenLoadPDFRequest,
  { rejectValue: string | ErrorResponse }
>("document/fetchOpenLoadPDF", async (params, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/document/ol/${params.customerId}/${params.id}/pdf`,
      {
        responseType: "blob",
      }
    );
    const file = new Blob([response.data], { type: "application/pdf" });
    const fileUrl = URL.createObjectURL(file);
    const filename = response.headers["content-disposition"].split("=")[1];
    return { fileUrl, fileName: filename, documentType: "load", id: params.id };
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      if (isBlobError(error.response?.data) && error.response) {
        error.response.data = await blobToErrorResponse(error.response.data);
      }
      switch (error.response?.status) {
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 404:
          return rejectWithValue(
            "Customer, packing slip or file not found. Contact your Account Rep."
          );
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue("There was an issue fetching open load PDF");
      }
    } else {
      return rejectWithValue("There was an issue fetching open load PDF");
    }
  }
});

// --- Quick Quotes --- //
export const fetchQuickQuoteSelectionData = createAsyncThunk<
  QuickQuoteSelectionData,
  string,
  { rejectValue: string | ErrorResponse }
>(
  "document/fetchQuickQuoteSelectionData",
  async (customerId, { rejectWithValue }) => {
    try {
      const response = await axiosWithAuth.get(
        `${process.env.REACT_APP_API_URL}/qq/search/setup`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue(error.response?.data);
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue(
              "There was an issue fetching quote documents setup"
            );
        }
      } else {
        return rejectWithValue(
          "There was an issue fetching quote documents setup"
        );
      }
    }
  }
);


// --- Quotes (Inquiries) --- //

export const fetchQuoteSelectionData = createAsyncThunk<
  QuoteSelectionData,
  string,
  { rejectValue: string | ErrorResponse }
>(
  "document/fetchQuoteSelectionData",
  async (customerId, { rejectWithValue }) => {
    try {
      const response = await axiosWithAuth.get(
        `${process.env.REACT_APP_API_URL}/document/quote/setup?customerId=${customerId}`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue(error.response?.data);
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue(
              "There was an issue fetching quick quote documents setup"
            );
        }
      } else {
        return rejectWithValue(
          "There was an issue fetching quick quote documents setup"
        );
      }
    }
  }
);

type FetchQuoteResultsRequest = {
  customerId?: string;
  criteria?: SearchCriteria;
};

export const fetchQuoteResults = createAsyncThunk<
  QuoteSummaryPagedResult,
  FetchQuoteResultsRequest,
  { rejectValue: string | ErrorResponse }
>("document/fetchQuoteResults", async (params, { rejectWithValue }) => {
  try {
    const query = objectToQueryString({
      customerId: params.customerId,
      ...params.criteria,
    });
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/document/quote/search${query}`
    );
    const result = response.data;
    result.lastCriteria = params.criteria;
    return result;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 400:
          return rejectWithValue(error.response?.data ?? "Invalid criteria");
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 404:
          return rejectWithValue("Customer not found");
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue("There was an issue fetching quote results");
      }
    } else {
      return rejectWithValue("There was an issue fetching quote results");
    }
  }
});

type FetchQuickQuoteResultsRequest = {
  customerId?: string;
  criteria?: SearchCriteria;
};

export const fetchQuickQuoteResults = createAsyncThunk<
  QuickQuoteSummaryResult,
  FetchQuickQuoteResultsRequest,
  { rejectValue: string | ErrorResponse }
>("document/fetchQuickQuoteResults", async (params, { rejectWithValue }) => {
  try {
    const query = objectToQueryString({
      customerId: params.customerId,
      ...params.criteria,
    });
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/qq/search${query}`
    );
    const result = response.data;
    result.lastCriteria = params.criteria;
    return result;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 400:
          return rejectWithValue(error.response?.data ?? "Invalid criteria");
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 404:
          return rejectWithValue("Customer not found");
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue("There was an issue fetching quote results");
      }
    } else {
      return rejectWithValue("There was an issue fetching quote results");
    }
  }
});

type FetchQuotePDFRequest = {
  customerId: string;
  id: string;
};

export const fetchQuotePDF = createAsyncThunk<
  PDFFile,
  FetchQuotePDFRequest,
  { rejectValue: string | ErrorResponse }
>("document/fetchQuotePDF", async (params, { rejectWithValue }) => {
  const { customerId, id } = params;
  try {
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/document/quote/${customerId}/${id}/pdf`,
      {
        responseType: "blob",
      }
    );
    const file = new Blob([response.data], { type: "application/pdf" });
    const fileUrl = URL.createObjectURL(file);
    const fileName = response.headers["content-disposition"].split("=")[1];
    // downloadFile(file, filename);
    return { fileUrl, fileName, documentType: "quote", id };
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      if (isBlobError(error.response?.data) && error.response) {
        error.response.data = await blobToErrorResponse(error.response.data);
      }
      switch (error.response?.status) {
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 404:
          return rejectWithValue(
            "Customer or order not found. Contact your Account Rep."
          );
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue(
            "There was an issue fetching quote detail PDF"
          );
      }
    } else {
      return rejectWithValue("There was an issue fetching quote detail PDF");
    }
  }
});

type FetchQuoteExcelRequest = {
  customerId: string;
  criteria?: SearchCriteria;
};

export const fetchQuoteExcel = createAsyncThunk<
  void,
  FetchQuoteExcelRequest,
  { rejectValue: string | ErrorResponse }
>("document/fetchQuoteExcel", async (params, { rejectWithValue }) => {
  try {
    const query = objectToQueryString({
      customerId: params.customerId,
      ...params.criteria,
    });
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/document/quote/excel${query}`,
      {
        responseType: "blob",
      }
    );
    const file = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const filename = response.headers["content-disposition"].split("=")[1];
    downloadFile(file, filename);
    return;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      if (isBlobError(error.response?.data) && error.response) {
        error.response.data = await blobToErrorResponse(error.response.data);
      }
      switch (error.response?.status) {
        case 400:
          return rejectWithValue("Invalid criteria");
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");

        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue(
            "There was an issue retrieving quote excel sheet"
          );
      }
    } else {
      return rejectWithValue("There was an issue retrieving quote excel sheet");
    }
  }
});

// --- Po Search --- //

export const fetchPOSearchSelectionData = createAsyncThunk<
  POSearchSelectionData,
  void,
  { rejectValue: string | ErrorResponse }
>("document/fetchPOSearchSelectionData", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/document/po/setup`
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");

        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue(
            "There was an issue retrieving PO search selection data"
          );
      }
    } else {
      return rejectWithValue(
        "There was an issue retrieving PO search selection data"
      );
    }
  }
});

type FetchPOSearchRequest = {
  customerPONumber: string;
  customerId?: string;
};

export const fetchPOSearchResults = createAsyncThunk<
  SearchDocument[],
  FetchPOSearchRequest,
  { rejectValue: string | ErrorResponse }
>("document/fetchPOSearchResults", async (params, { rejectWithValue }) => {
  try {
    const query = objectToQueryString(params);
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/document/po/search${query}`
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 400:
          return rejectWithValue("Invalid criteria");
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");

        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue(
            "There was an issue retrieving PO search results"
          );
      }
    } else {
      return rejectWithValue("There was an issue retrieving PO search results");
    }
  }
});

// --- Document search --- //

export type FetchDocumentRequest = {
  customerId?: string;
  documentType: DocumentType;
  id: string;
};

export const fetchDocument = createAsyncThunk<
  PDFFile,
  FetchDocumentRequest,
  { rejectValue: string | ErrorResponse }
>("document/fetchDocument", async (params, { rejectWithValue }) => {
  try {
    const query = objectToQueryString(params);
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/document/search/pdf${query}`,
      {
        responseType: "blob",
      }
    );
    const file = new Blob([response.data], { type: "application/pdf" });
    const fileUrl = URL.createObjectURL(file);
    const filename = response.headers["content-disposition"].split("=")[1];
    return {
      fileUrl,
      fileName: filename,
      documentType: params.documentType,
      id: params.id,
    };
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 400:
          return rejectWithValue("Invalid id supplied");
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 404:
          return rejectWithValue(
            "Document file not found. Contact your Account Rep."
          );
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue(
            "There was an issue retrieving the requested document"
          );
      }
    } else {
      return rejectWithValue(
        "There was an issue retrieving the requested document"
      );
    }
  }
});

// --- Historical Price Sheets --- //

export type FetchHistoricalPriceSheetsRequest = {
  id: string;
  shipToId?: string;
  pointInTime: DateString;
};

export const fetchHistoricalPriceSheets = createAsyncThunk<
  void,
  FetchHistoricalPriceSheetsRequest,
  { rejectValue: string | ErrorResponse }
>(
  "document/fetchHistoricalPriceSheets",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosWithAuth.get(
        `${process.env.REACT_APP_API_URL}/internal/history/pricesheet/${params.id}/pdf?pointInTime=${params.pointInTime}${
          params.shipToId ? "&shipToId=" + params.shipToId : ""
        }`,
        {
          responseType: "blob",
        }
      );
      const file = new Blob([response.data], { type: "application/pdf" });
      const filename = response.headers["content-disposition"].split("=")[1];
      downloadFile(file, filename);
      return;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (isBlobError(error.response?.data) && error.response) {
          error.response.data = await blobToErrorResponse(error.response.data);
        }
        switch (error.response?.status) {
          case 400:
            return rejectWithValue("Invalid id supplied");
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue(error.response?.data);
          case 404:
            return rejectWithValue(error.response?.data.message);
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue(error.response?.data);
          default:
            return rejectWithValue(
              "There was an issue retrieving the historical price sheet."
            );
        }
      } else {
        return rejectWithValue(
          "There was an issue retrieving the historical price sheet."
        );
      }
    }
  }
);

export const fetchHistoricalPriceSheetsExcel = createAsyncThunk<
  void,
  FetchHistoricalPriceSheetsRequest,
  { rejectValue: string | ErrorResponse }
>(
  "document/fetchHistoricalPriceSheetsExcel",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosWithAuth.get(
        `${process.env.REACT_APP_API_URL}/internal/history/parts/${params.id}/excel?pointInTime=${params.pointInTime}`,
        {
          responseType: "blob",
        }
      );
      const file = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const filename = response.headers["content-disposition"].split("=")[1];
      downloadFile(file, filename);
      return;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (isBlobError(error.response?.data) && error.response) {
          error.response.data = await blobToErrorResponse(error.response.data);
        }
        switch (error.response?.status) {
          case 400:
            return rejectWithValue("Invalid id supplied");
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue(error.response?.data);
          case 404:
            return rejectWithValue(error.response?.data.message);
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue(error.response?.data);
          default:
            return rejectWithValue(
              "There was an issue retrieving the historical price sheet."
            );
        }
      } else {
        return rejectWithValue(
          "There was an issue retrieving the historical price sheet."
        );
      }
    }
  }
);

/** RELATED DOCS API */

type FetchRelatedDocsRequest = {
  documentType: DocumentType;
  id: string;
};

type DocumentObject = {
  documentType: DocumentType;
  documentNumber: string;
};

export type FetchRelatedDocsResponse = {
  currentDocKey: DocumentObject;
  relatedKeysMap: {
    [key: string]: DocumentObject[];
  };
};

export const fetchRelatedDocs = createAsyncThunk<
  FetchRelatedDocsResponse,
  FetchRelatedDocsRequest,
  { rejectValue: string | ErrorResponse }
>("document/fetchRelatedDocs", async (params, { rejectWithValue }) => {
  try {
    const queryString = objectToQueryString(params);
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/document/search/related_docs${queryString}`
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 400:
          return rejectWithValue("Invalid id supplied");
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 404:
          return rejectWithValue(error.response?.data.message);
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue(
            "There was an issue retrieving related documents."
          );
      }
    } else {
      return rejectWithValue(
        "There was an issue retrieving related documents."
      );
    }
  }
});

export const addQuickQuote = createAsyncThunk<
  QuickQuoteView,
  AddQuoteForm,
  { rejectValue: string | ErrorResponse }
>("qq", async (quote: AddQuoteForm, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.post(
      `${process.env.REACT_APP_API_URL}/qq`,
      quote
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 400:
          return rejectWithValue(error.response?.data);
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue("There was an issue creating the portal quote");
      }
    } else {
      return rejectWithValue("There was an issue creating the portal quote");
    }
  }
});

export const updateQuickQuoteLine = createAsyncThunk<
  QuickQuoteView,
  { quoteId: number, lineId: number, line: QQLineUpdate },
  { rejectValue: string | ErrorResponse }
>("qq/line/update", async ( { quoteId, lineId, line }, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.put(
      `${process.env.REACT_APP_API_URL}/qq/${quoteId}/lines/${lineId}`,
      line
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 400:
          console.log('updateQuickQuoteLine error', error.response?.data)
          return rejectWithValue(error.response?.data);
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue("There was an issue updating the portal quote");
      }
    } else {
      return rejectWithValue("There was an issue updating the portal quote");
    }
  }
});

export const addQuickQuoteLine = createAsyncThunk<
  QuickQuoteView,
  { quoteId: number, line: QQLineUpdate },
  { rejectValue: string | ErrorResponse }
>("qq/linedelete", async ( { quoteId, line }, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.post(
      `${process.env.REACT_APP_API_URL}/qq/${quoteId}/lines`,
      line
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 400:
          return rejectWithValue(error.response?.data);
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue("There was an issue updating the portal quote");
      }
    } else {
      return rejectWithValue("There was an issue updating the portal quote");
    }
  }
});

export const fetchQuickQuoteLineDetail = createAsyncThunk<
  QuickQuoteLineDetail,
  { quoteId: number, lineId: number },
  { rejectValue: string | ErrorResponse }
>("document/fetchQuickQuoteLineDetail", async ({quoteId, lineId}, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/qq/${quoteId}/lines/${lineId}/detail`
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 400:
          return rejectWithValue("Invalid id supplied");
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 404:
          return rejectWithValue(error.response?.data.message);
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue(
            "There was an issue retrieving quick quote line details."
          );
      }
    } else {
      return rejectWithValue(
        "There was an issue retrieving quick quote line details."
      );
    }
  }
});

export const deleteQuickQuoteLine = createAsyncThunk<
  QuickQuoteView,
  { quoteId: number, lineId: number },
  { rejectValue: string | ErrorResponse }
>("qq/line", async ( { quoteId, lineId }, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.delete(
      `${process.env.REACT_APP_API_URL}/qq/${quoteId}/lines/${lineId}`,
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 400:
          return rejectWithValue(error.response?.data);
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue("There was an issue deleting the portal quote line");
      }
    } else {
      return rejectWithValue("There was an issue deleting the portal quote line");
    }
  }
});

export const fetchQuickQuote = createAsyncThunk<
  QuickQuoteView,
  number,
  { rejectValue: string | ErrorResponse }
>("document/fetchQuickQuote", async (quoteId, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/qq/${quoteId}`
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 400:
          return rejectWithValue("Invalid id supplied");
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 404:
          return rejectWithValue(error.response?.data.message);
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue(
            "There was an issue retrieving quick quote."
          );
      }
    } else {
      return rejectWithValue(
        "There was an issue retrieving quick quote."
      );
    }
  }
});

// type FetchQuickQuoteSetupRequest = {
//   customerId?: string;
//   prospectiveCustomer: boolean;
//   quickQuoteId?: number;
// };

export const fetchQuickQuoteSetup = createAsyncThunk<
  QuickQuoteSetup,
  QQSetupRequestParams,
  { rejectValue: string | ErrorResponse }
>("document/fetchQuickQuoteSetup", async (params, { rejectWithValue }) => {
  try {
    const queryString = objectToQueryString(params);
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/qq/setup${queryString}`
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 400:
          return rejectWithValue("Invalid id supplied");
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 404:
          return rejectWithValue(error.response?.data.message);
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue(
            "There was an issue retrieving quick quote setup."
          );
      }
    } else {
      return rejectWithValue(
        "There was an issue retrieving quick quote setup."
      );
    }
  }
});

export const fetchQuickQuoteItemPricing = createAsyncThunk<
  QuickQuoteItemPricing,
  QQItemPricingRequestParams,
  { rejectValue: string | ErrorResponse }
>("document/fetchQuickQuoteItemPricing", async (params, { rejectWithValue }) => {
  try {
    const queryString = objectToQueryString(params);
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/qq/item_pricing${queryString}`
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 400:
          return rejectWithValue("Invalid id supplied");
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 404:
          return rejectWithValue(error.response?.data.message);
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue(
            "There was an issue retrieving quick quote item pricing."
          );
      }
    } else {
      return rejectWithValue(
        "There was an issue retrieving quick quote item pricing."
      );
    }
  }
});

export const updateQuickQuote = createAsyncThunk<
  QuickQuoteView,
  AddQuoteForm,
  { rejectValue: string | ErrorResponse }
>("qq/update", async ({ quoteId, ...quote }: AddQuoteForm,  { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.put(
      `${process.env.REACT_APP_API_URL}/qq/${quoteId}`,
      quote
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 400:
          console.log('rejecting with value', error.response?.data)
          return rejectWithValue(error.response?.data);
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue("There was an issue creating the portal quote");
      }
    } else {
      return rejectWithValue("There was an issue creating the portal quote");
    }
  }
});


export const updateQuickQuoteLineDetail = createAsyncThunk<
  QuickQuoteView,
  QQLineDetailParams,
  { rejectValue: string | ErrorResponse }
>("qq/updateQuickQuoteLineDetail", async ({ quoteId, lineId, ...lineParams }: QQLineDetailParams,  { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.put(
      `${process.env.REACT_APP_API_URL}/qq/${quoteId}/lines/${lineId}/detail`,
      lineParams
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 400:
          console.log('rejecting updateQuickQuoteLineDetail with value', error.response?.data)
          return rejectWithValue(error.response?.data);
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue("There was an issue updating the portal quote line details");
      }
    } else {
      console.log('rejecting updateQuickQuoteLineDetail with generic error')
      return rejectWithValue("There was an issue updating the portal quote line details");
    }
  }
});

export const updateQuickQuotePrices = createAsyncThunk<
  QuickQuoteView,
  QQPriceUpdateParams,
  { rejectValue: string | ErrorResponse }
>("qq/updateQuickQuotePrices", async ({ quoteId, unitPrice  }: QQPriceUpdateParams,  { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.put(
      `${process.env.REACT_APP_API_URL}/qq/${quoteId}/update_all_prices`,
      { unitPrice }
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 400:
          console.log('rejecting updateQuickQuoteLineDetail with value', error.response?.data)
          return rejectWithValue(error.response?.data);
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue("There was an issue updating the portal quote prices");
      }
    } else {
      console.log('rejecting updateQuickQuoteLineDetail with generic error')
      return rejectWithValue("There was an issue updating the portal quote prices");
    }
  }
});

export const updateQuickQuoteDueDates = createAsyncThunk<
  QuickQuoteView,
  QQDateUpdateParams,
  { rejectValue: string | ErrorResponse }
>("qq/updateQuickDueDates", async ({ quoteId, customerRequestDate  }: QQDateUpdateParams,  { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.put(
      `${process.env.REACT_APP_API_URL}/qq/${quoteId}/update_all_due_dates`,
      { customerRequestDate }
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 400:
          console.log('rejecting updateQuickQuoteLineDetail with value', error.response?.data)
          return rejectWithValue(error.response?.data);
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue("There was an issue updating the portal quote due dates");
      }
    } else {
      console.log('rejecting updateQuickQuoteLineDetail with generic error')
      return rejectWithValue("There was an issue updating the portal quote due dates");
    }
  }
});


export const fetchQuickQuotePDF = createAsyncThunk<
void,
{quoteId: number, pdfType: string},
{rejectValue: string | ErrorResponse}
>(
"qq/summaryPDF",
async (params, { rejectWithValue }) => {
  try {


    const response = await axiosWithAuth.get(
      `${
        process.env.REACT_APP_API_URL
      }/qq/${params.quoteId}/${params.pdfType}`,
      {
        responseType: "blob",
      }
    );
    const file = new Blob([response.data], {
      type: "application/pdf",
    });
    const filename = response.headers["content-disposition"].split("=")[1];
    downloadFile(file, filename);
    return;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      if (isBlobError(error.response?.data) && error.response) {
        error.response.data = await blobToErrorResponse(error.response.data);
      }
      return rejectWithValue(error.response?.data);
    } else {
      return rejectWithValue("There was an issue retrieving pdf");
    }
  }
}
);

