/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import { ClaimsInput } from "../../../../types/types";
import tw from "twin.macro";
import { generatePhoneNumber } from "../../../../helpers/phonenumber";
import { useAppSelector } from "../../../../app/hooks";
import { selectClaimsDocumentsSelectionData } from "../../../../store/document.reducer";
import Button from "../../../../components/atoms/Button";

type Props = {
  children?: ReactNode;
  formSubmission: ClaimsInput;
  onConfirm: () => void;
};

const CreateClaimFormConfirmation: React.FC<Props> = ({
  formSubmission,
  onConfirm,
}) => {
  const selectionData = useAppSelector(selectClaimsDocumentsSelectionData);
  const customer = useAppSelector(
    (state) => state.customer.currentCustomer?.name
  );

  return (
    <div css={[tw`mt-2 text-xs flex flex-col gap-2 p-1`]}>
      <p css={tw`text-nucor-green font-semibold`}>
        Your claim form was successfully submitted
      </p>
      <div>
        <label css={tw`inline-block w-40`}>Customer Contact</label>
        <span css={tw``}>{customer}</span>
      </div>
      <div>
        <label css={tw`inline-block w-40`}>Customer Contact</label>
        <span css={tw``}>{formSubmission.contactName}</span>
      </div>
      <div css={tw`flex items-center`}>
        <label css={tw`inline-block w-40`}>Phone</label>
        <span>{`${generatePhoneNumber(formSubmission.contactPhone)} (${
          formSubmission.contactPhoneExtension
        })`}</span>
      </div>
      <div>
        <label css={tw`inline-block w-40`}>Email</label>
        <span>{formSubmission.contactEmail}</span>
      </div>
      <div>
        <label css={tw`inline-block w-40`}>Division</label>
        <span>
          {
            selectionData?.sites.find((item) => item.id === formSubmission.site)
              ?.description
          }
        </span>
      </div>
      <div css={tw`flex`}>
        <label css={tw`inline-block w-40`} htmlFor="notes">
          Description
        </label>
        <span>{formSubmission.description}</span>
      </div>
      <div css={tw`flex`}>
        <label css={tw`w-40`}>Material Disposition</label>
        <span>
          {
            selectionData?.materialDispositions.find(
              (item) => item.id === formSubmission.materialDisposition
            )?.description
          }
        </span>
      </div>
      <div css={tw`flex items-center`}>
        <label css={tw` w-40 `}>Packing Slip</label>
        <span>{formSubmission.packingSlipNumber}</span>
      </div>
      <div css={tw`flex`}>
        <label css={tw`w-40  `}>Invoice</label>
        <span>{formSubmission.invoiceNumber}</span>
      </div>
      <div css={tw`w-full flex gap-4 mt-4 justify-start`}>
        <Button css={tw`min-w-[10ch]`} onClick={onConfirm}>
          Ok
        </Button>
      </div>
    </div>
  );
};

export default CreateClaimFormConfirmation;
