/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import tw from "twin.macro";
import { OrderDetailPagedResult } from "../../../../../../types/types";
import { SerializedStyles, css } from "@emotion/react";
import { numberFormatter } from "../../../../../../helpers/numberFormat";
import { dateStringShorten } from "../../../../../../helpers/dateHelpers";
import { Tooltip } from 'react-tooltip'

type Props = {
  children?: ReactNode;
  title?: string;
  items?: OrderDetailPagedResult;
};

const BookingOrderDetails: React.FC<Props> = ({ title, items }) => {
  return (
    <div css={tw`mt-4`}>
      <span css={tw`font-bold`}>{title}</span>
      <div className="max-h-[300px] overflow-y-auto">
        <table className="ntp-portal-table" css={tw`w-full table-fixed`}>
          <thead>
            <tr css={rowWidth}>
              <th>Order Number</th>
              <th>Description</th>
              <th>Customer</th>
              <th>Length</th>
              <th>
                Order
                <br />
                Pieces
              </th>
              <th>
                Order
                <br />
                Weight
              </th>
              <th>
                Rolling
                <br />
                Pieces
              </th>
              <th>
                Rolling
                <br />
                Weight
              </th>
              <th>
                Rolling
                <br />
                Footage
              </th>
              <th>Due Date</th>
            </tr>
          </thead>
          <tbody>
            {items?.list?.map((item) => (
              <tr key={item.inventoryTransactionId}>
                <td>{`${item.salesOrderNumber}-${item.lineNumber ?? ""}`}</td>
                <td>{item.lineDescription}</td>
                <td>
                  <a
                    data-tooltip-id={`tip${item.inventoryTransactionId}`}
                    data-tooltip-content={item.deliveryAddressName}
                    data-tooltip-place="left"
                  >{item.customerId}
                  </a>
                  <Tooltip id={`tip${item.inventoryTransactionId}`} 
                    style={{ backgroundColor: "rgb(255, 255, 255)", color: "#000", fontSize: "12px" }}
                  />
                </td>
                <td>{item.formattedLength}</td>
                <td css={tw`text-right`}>{numberFormatter(item.totalLineOrderedPieces)}</td>
                <td css={tw`text-right`}>{numberFormatter(item.totalLineWeight)}</td>
                <td css={tw`text-right`}>{numberFormatter(item.rollingPieces)}</td>
                <td css={tw`text-right`}>{numberFormatter(item.rollingWeight)}</td>
                <td css={tw`text-right`}>{item.formattedTotalFootage}</td>
                <td>{dateStringShorten(item.dueDate)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BookingOrderDetails;

const rowWidth: SerializedStyles = css`
  & > th:nth-of-type(1) {
    width: 9%;
  }
  & > th:nth-of-type(2) {
    width: 20%;
  }
  & > th:nth-of-type(3) {
    width: 5%;
  }
  & > th:nth-of-type(4) {
    width: 5%;
  }
  & > th:nth-of-type(5) {
    width: 5%;
  }
  & > th:nth-of-type(6) {
    width: 5%;
  }
  & > th:nth-of-type(7) {
    width: 5%;
  }
  & > th:nth-of-type(8) {
    width: 5%;
  }
  & > th:nth-of-type(9) {
    width: 5%;
  }
  & > th:nth-of-type(10) {
    width: 5%;
  }
`;
