/** @jsxImportSource @emotion/react */
import React, { ReactNode, useMemo } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { generatePhoneNumber } from "../../../../helpers/phonenumber";
import { getUserInfo } from "../../../../store/admin.actions";
import { setCurrentResultsPage } from "../../../../store/admin.reducer";
import Pagination from "../../../../components/molecules/Pagination";
import Loading from "../../../../components/atoms/Loading";
import TextButton from "../../../../components/atoms/TextButton";

type Props = {
  children?: ReactNode;
};
const MAX_PER_PAGE = 25;

const UsersResults: React.FC<Props> = () => {
  const hasSearchedUsers = useAppSelector((state) => state.admin.hasSearchedUsers);
  const { exportExcel } = useAppSelector(
    (state) => state.admin.searchSettings
  );

  const userList = useAppSelector((state) => state.admin.userList);
  const currentResultsPage = useAppSelector(
    (state) => state.admin.currentUserListResultsPage
  );
  const loading = useAppSelector((state) => state.admin.loading);
  const maxPage = Math.ceil(userList.length / MAX_PER_PAGE - 1);
  const dispatch = useAppDispatch();

  const paginatedResults = useMemo(() => {
    const first = MAX_PER_PAGE * currentResultsPage;
    return userList?.slice(first, first + MAX_PER_PAGE);
  }, [userList, currentResultsPage]);

  const itemFrom = currentResultsPage * MAX_PER_PAGE + 1;
  const itemTo =
    currentResultsPage === maxPage
      ? currentResultsPage * MAX_PER_PAGE +
        (paginatedResults.length % MAX_PER_PAGE)
      : currentResultsPage * MAX_PER_PAGE + MAX_PER_PAGE;

  return (
    <>
      {!hasSearchedUsers && (
        <span css={tw`text-nucor-green p-2 text-xs font-bold`}>
          * Enter criteria and click search to retrieve the matching users.
        </span>
      )}
      {hasSearchedUsers && (
        <>
          <div css={[tw`flex w-full justify-between bg-gradient-to-b from-[#e6e6e6] to-[#fdfdfd] p-2 mt-2`,]}>

            {paginatedResults && paginatedResults.length > 0 ? (
              <>
                <Pagination
                  currentPage={currentResultsPage}
                  maxPage={maxPage}
                  onFirst={() => dispatch(setCurrentResultsPage(0))}
                  onLast={() => dispatch(setCurrentResultsPage(maxPage))}
                  onNext={() => {
                    if (currentResultsPage < maxPage) {
                      dispatch(setCurrentResultsPage(currentResultsPage + 1));
                    }
                  }}
                  onPrevious={() => {
                    if (currentResultsPage > 0) {
                      dispatch(setCurrentResultsPage(currentResultsPage - 1));
                    }
                  }}
                  onSetPage={(pageNumber: number) => {
                    dispatch(setCurrentResultsPage(pageNumber));
                  }}
                />
                <span tw="text-xs">{`Displaying users ${itemFrom} - ${itemTo} of ${userList?.length}`}</span>
              </>
            ) : (
              <>
                <span tw="text-xs">&nbsp;</span>
                <span tw="text-xs">{`No Results`}</span>
              </>
            )}
          </div>
          <div css={[tw`relative`]}>
            {loading && !exportExcel && <Loading />}
            <table className="ntp-portal-table" css={tw`text-xs w-full`}>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Status</th>
                  <th>Customer Classification</th>
                  <th>External</th>
                  <th>Internal</th>
                  <th>Admin</th>
                  <th>IT Admin</th>
                  <th>Phone</th>
                  <th>Email</th>
                  <th>Last Changed Date</th>
                  <th>Last Logged In Date</th>
                </tr>
              </thead>
              <tbody>
                {paginatedResults && paginatedResults.length > 0 ? (
                  paginatedResults?.map((item, index) => (
                    <tr key={index} className={item.status === "D" ? "text-red-600" : ""}>
                      <td tw="text-center">{item.userId}</td>
                      <td>{`${item.lastName}, ${item.firstName}`}</td>
                      <td tw="text-center">{item.statusDescription}</td>
                      <td>{item.customerClassification}</td>
                      <td tw="text-center">{(item.external && "Yes") || "No"}</td>
                      <td tw="text-center">{(item.internal && "Yes") || "No"}</td>
                      <td tw="text-center">{(item.admin && "Yes") || "No"}</td>
                      <td tw="text-center">{(item.itAdmin && "Yes") || "No"}</td>
                      <td>{generatePhoneNumber(item.officePhone)}</td>
                      <td>
                        <TextButton onClick={() => dispatch(getUserInfo(item?.userId))}>
                          {item.email}
                        </TextButton>
                      </td>
                      <td>
                        {item.updatedDate &&
                          new Date(item.updatedDate).toLocaleString("en-US", {
                            month: "2-digit",
                            day: "2-digit",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                      </td>
                    <td>
                      {item.lastLoggedIn &&
                        new Date(item.lastLoggedIn).toLocaleString("en-US", {
                          month: "2-digit",
                          day: "2-digit",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                    </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td css={tw`text-nucor-gray !px-2 !py-3 hover:!bg-white`} colSpan={12}>
                      No Users for the selected criteria.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
};

export default UsersResults;
