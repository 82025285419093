/** @jsxImportSource @emotion/react */
import React, { ReactNode, useEffect, useState } from "react";
import tw from "twin.macro";
import { selectCurrentCustomerId } from "../../../store/customer.reducer";
import { useAppSelector } from "../../../app/hooks";
// import Loading from "../../../../atoms/Loading";

type Props = {
  children?: ReactNode;
  browse?: ReactNode;
  browseOnClick?: () => void;
  search?: ReactNode;
};

const BrowseSearch: React.FC<Props> = ({ browse, search, browseOnClick }) => {
  const [currentTab, setCurrentTab] = useState<"browse" | "search">("browse");

  const customerId = useAppSelector(selectCurrentCustomerId);

  useEffect(() => {
    setCurrentTab("browse");
  }, [customerId]);

  return (
    <div css={[tw`text-xs relative w-full`]}>
      {/* {loading && <Loading />} */}
      <div css={[tw`flex gap-1`]}>
        <button
          onClick={() => {
            setCurrentTab("browse");
            browseOnClick && browseOnClick();
          }}
          css={[
            tw`py-1 px-2 bg-[#ececec] text-nucor-gray font-bold focus-visible:outline-1 focus-visible:outline-dotted focus-visible:-outline-offset-1 focus-visible:outline-nucor-gray focus:outline-none`,
            currentTab !== "browse" && tw`bg-[#cecece] text-[#999]`,
          ]}
        >
          Browse
        </button>
        <button
          onClick={() => setCurrentTab("search")}
          css={[
            tw`py-1 px-2 bg-[#ececec] text-nucor-gray font-bold focus-visible:outline-1 focus-visible:outline-dotted focus-visible:-outline-offset-1 focus-visible:outline-nucor-gray focus:outline-none`,
            currentTab !== "search" && tw`bg-[#cecece] text-[#999]`,
          ]}
        >
          Search
        </button>
      </div>
      <div css={tw`w-full bg-[#ececec] p-2`}>
        {currentTab === "browse" && browse}
        {currentTab === "search" && search}
      </div>
    </div>
  );
};

export default BrowseSearch;
