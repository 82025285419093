/** @jsxImportSource @emotion/react */
import React, { useEffect } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
  selectProductSelectionShapes,
  setProductSelectionShape,
} from "../../../../../store/product.reducer";

type Props = Record<string, never>;

const ProductShapeComponent: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const shapes = useAppSelector(selectProductSelectionShapes);
  const { productSelectionDetails } = useAppSelector((state) => state.product);
  const currentShape = useAppSelector(
    (state) => state.product.productSelectionDetails.shape
  );
  const { loading } = useAppSelector((state) => state.rollings);
  const { inventorySearchStatus } = useAppSelector((state) => state.product.prime);

  useEffect(() => {
    /** Checking the current product selections shape allows
     *  default setting of shape if no product selection is
     */
    if (
      shapes?.shapeSelections?.length === 1 &&
      productSelectionDetails.shape === "Unknown"
    ) {
      dispatch(setProductSelectionShape(shapes?.shapeSelections[0].shape));
    }
  }, [shapes]);

  return (
    <>
      <div css={[tw`flex gap-1`]}>
        {shapes?.shapeSelections?.find((item) => item.shape === "Square") && (
          <button
            disabled={loading == 'pending' || inventorySearchStatus == 'pending'}
            css={[tw`border-0 h-10`]}
            onClick={() => dispatch(setProductSelectionShape("Square"))}
          >
            <SquareShape highlight={currentShape === "Square"} />
          </button>
        )}
        {shapes?.shapeSelections.find((item) => item.shape === "Rectangle") && (
          <button
            disabled={loading == 'pending' || inventorySearchStatus == 'pending'}
            css={[tw`border-0 h-10`]}
            onClick={() => dispatch(setProductSelectionShape("Rectangle"))}
          >
            <RectShape highlight={currentShape === "Rectangle"} />
          </button>
        )}
        {shapes?.shapeSelections.find((item) => item.shape === "Round") && (
          <button
            disabled={loading == 'pending' || inventorySearchStatus == 'pending'}
            css={[tw`border-0 h-10`]}
            onClick={() => dispatch(setProductSelectionShape("Round"))}
          >
            <TubeShape highlight={currentShape === "Round"} />
          </button>
        )}
      </div>
      <div css={[tw`py-2`]}>
        <span css={[tw`font-bold text-nucor-gray`]}>Current Shape: </span>
        <span css={[tw`font-bold text-red-600`]}>
          {currentShape === "Unknown" ? "" : currentShape}
        </span>
      </div>
    </>
  );
};

export default ProductShapeComponent;

const SquareShape: React.FC<{ highlight: boolean }> = ({ highlight }) => {
  return (
    <div
      css={[
        tw`w-10 h-10 rounded-[20%] transition-[border-color]`,
        { border: "9px solid #6d756e" },
        highlight && tw`border-nucor-green`,
      ]}
    />
  );
};

const RectShape: React.FC<{ highlight: boolean }> = ({ highlight }) => {
  return (
    <div
      css={[
        tw`w-16 h-10 rounded-[20%] transition-[border-color]`,
        { border: "9px solid #6d756e" },
        highlight && tw`border-nucor-green`,
      ]}
    />
  );
};

const TubeShape: React.FC<{ highlight: boolean }> = ({ highlight }) => {
  return (
    <div
      css={[
        tw`w-10 h-10 rounded-full transition-[border-color]`,
        { border: "9px solid #6d756e" },
        highlight && tw`border-nucor-green`,
      ]}
    />
  );
};
