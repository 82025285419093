/** @jsxImportSource @emotion/react */
import { yupResolver } from "@hookform/resolvers/yup";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import Button from "../../../../../components/atoms/Button";
import DatePicker from "../../../../../components/atoms/DatePicker";
import Loading from "../../../../../components/atoms/Loading";
import TextButton from "../../../../../components/atoms/TextButton";
import {
  dateStringShorten,
  dateToString,
  stringToDate,
} from "../../../../../helpers/dateHelpers";
import { fractions } from "../../../../../helpers/fractions";
import {
  inchesToFormattedString,
  inchesToLength,
  lengthToInches,
} from "../../../../../helpers/lengthHelpers";
import { updateRollingCartItem } from "../../../../../store/shoppingCart.actions";
import {
  selectCurrentCustomerId,
  selectCurrentShipTo,
} from "../../../../../store/customer.reducer";
import { selectCurrentRollingData } from "../../../../../store/product.reducer";
import {
  ErrorResponse,
  RollingInput,
  SalesOrderItem,
} from "../../../../../types/types";
import EnterTonsDialog from "./EnterTonsDialog";
import { RollingFormData, schema } from "./rollingOrderForm";
import { calculatePiecesFromTons } from "../../../../../store/product.actions";
import ComboBox from "../../../../../components/atoms/ComboBox";
import Toast from "../../../../../components/molecules/Toast";
import Select from "../../../../../components/atoms/Select";

type Props = {
  children?: ReactNode;
  item?: SalesOrderItem;
  onCancel: () => void;
};

const EditRollingForm: React.FC<Props> = ({ onCancel, item }) => {
  const dispatch = useAppDispatch();
  const customerId = useAppSelector(selectCurrentCustomerId);
  const rollings = useAppSelector(selectCurrentRollingData);
  const products = rollings?.products;
  const customerParts = rollings?.customerParts;
  const currentShipToId = useAppSelector(selectCurrentShipTo);
  const orderRollingItemsByBundle = useAppSelector(
    (state) =>
      state.user.currentContext?.userPreferences?.orderRollingItemsByBundle
  );

  const [isLoading, setIsLoading] = useState(false);
  const [showTons, setShowTons] = useState(false);
  const [showFeetError, setShowFeetError] = useState(false);
  const [formError, setFormError] = useState<string | ErrorResponse>();

  const initialLoad = useRef(true);

  const itemLength = inchesToLength(item?.length);

  const bundleConfig = rollings?.itemBundleConfig;

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    trigger,
    formState: { errors, touchedFields },
  } = useForm<RollingFormData>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      rollingId: `${item?.rollingCycle}-${item?.rollingSequence}`,
      orderby: item?.customerPartNumber
        ? "CustomerPartNumber"
        : "ProductItemNumber",
      customerPartNumber: item?.customerPartNumber ?? "",
      product: item?.itemNumber ?? "",
      fraction: itemLength?.fraction ? itemLength.fraction : 0,
      feet: itemLength?.feet ? itemLength.feet : NaN,
      inches: itemLength?.inches ? itemLength.inches : NaN,
      date: stringToDate(item?.requestedShippingDate),
      pieces: orderRollingItemsByBundle ? (item?.bundles ? item.bundles : 0) : (item?.pieces ? item.pieces : 0),
    },
  });
  const orderBy = watch("orderby");
  const rollingId = watch("rollingId");
  const dueDate = watch("date");
  const itemNumber = watch("product");
  const customerPartNumber = watch("customerPartNumber");
  const feet = Number.isNaN(watch("feet")) ? 0 : watch("feet");
  const inches = Number.isNaN(watch("inches")) ? 0 : watch("inches");
  const fraction = watch("fraction");
  const length = feet * 12 + inches + fraction;
  const pieces = watch("pieces");

  useEffect(() => {
    if (initialLoad.current) return;
    setValue("rollingId", `${item?.rollingCycle}-${item?.rollingSequence}`);
    setValue(
      "orderby",
      item?.customerPartNumber ? "CustomerPartNumber" : "ProductItemNumber"
    );
    setValue(
      "customerPartNumber",
      item?.customerPartNumber ? item.customerPartNumber : ""
    );
    setValue("product", item?.itemNumber ? item.itemNumber : "");
    setValue("fraction", itemLength?.fraction ? itemLength.fraction : 0);
    setValue("feet", itemLength?.feet ? itemLength.feet : NaN);
    setValue("inches", itemLength?.inches ? itemLength.inches : NaN);
    setValue("date", stringToDate(item?.requestedShippingDate));
    setValue("pieces", orderRollingItemsByBundle ? (item?.bundles ? item.bundles : 0) : (item?.pieces ? item.pieces : 0));
  }, [item]);

  const selectedRolling = rollings?.rollings?.find(
    (rolling) =>
      `${rolling.rollingCycleId}-${rolling.cycleLinePerSequence}` === rollingId
  );

  const minDate = selectedRolling?.minDueDate;
  const maxDate = selectedRolling?.maxDueDate;

  const validLength = orderBy === "CustomerPartNumber" ? (selectedRolling &&  (0 == length || length >= selectedRolling.internalMinLength && length <= selectedRolling.maxLength)) : (selectedRolling && (0 == length || length >= selectedRolling.minLength && length <= selectedRolling.maxLength));
 
  const submitHandler = handleSubmit((data) => {
    if (!item?.id) return;
    if (!customerId) return;
    if (!data.date || !data.pieces) return;
    const itemNumber =
      data.orderby === "CustomerPartNumber"
        ? data.customerPartNumber
        : data.product;
    if (!itemNumber) return;
    const length = lengthToInches({
      feet: Number.isNaN(data.feet) ? 0 : data.feet,
      inches: Number.isNaN(data.inches) ? 0 : data.inches,
      fraction: data.fraction,
    });
    const newItem: RollingInput = {
      length,
      itemNumber,
      shipToId: currentShipToId,
      rollingItemType: data.orderby,
      rollingCycleId: data.rollingId?.split("-")[0],
      dueDate: dateToString(data.date),
      quantityType: orderRollingItemsByBundle ? "Bundles" : "Pieces",
      quantity: data.pieces,
      cycleLinePerSequence: data.rollingId?.split("-")[1],
    };
    setIsLoading(true);
    setFormError(undefined);
    dispatch(
      updateRollingCartItem({
        customerId,
        salesOrderItemId: item?.id,
        item: newItem,
      })
    )
      .unwrap()
      .then(() => {
        setIsLoading(false);
        onCancel();
      })
      .catch((error) => {
        setFormError(error);
        setIsLoading(false);
      });
  });

  useEffect(() => {
    if (customerParts?.length === 0 && orderBy === "CustomerPartNumber") {
      setValue("orderby", "ProductItemNumber");
    }
  }, [customerParts]);

  const calculatePiecesFromBundle = () => {
    if (!bundleConfig || !pieces) return undefined;
    const item = itemNumber;
    const target = bundleConfig[item]?.find(
      (record) => record.length === undefined || record.length <= length
    );
    if (!target) return undefined;
    return { piecesPerBundle: target.target, pieces: target.target * pieces };
  };

  const piecesFromBundle = calculatePiecesFromBundle();

  const selectedProperty =
    orderBy === "CustomerPartNumber"
      ? customerParts?.find(
          (item) => item.customerPartNumber === customerPartNumber
        )?.property
      : products?.find((item) => item.itemNumber === itemNumber)?.property;
  const filteredRollings = rollings?.rollings?.filter(
    (item) => item.property === selectedProperty
  );
  const rollingIds = filteredRollings?.map((roll) => {
    const start = dateStringShorten(roll.startDate);
    const stop = dateStringShorten(roll.stopDate);
    return {
      value: `${roll.rollingCycleId}-${roll.cycleLinePerSequence}`,
      label: `${roll.resource} - ${start} - ${stop}`,
    };
  });

  useEffect(() => {
    if (!initialLoad.current) {
      setValue("rollingId", rollingIds?.[0]?.value ?? "");
    }
    initialLoad.current = false;
  }, [itemNumber, customerPartNumber]);

  const isMultiple = (() => {
    if (!rollings) return false;
    const piecesPerBundle = piecesFromBundle?.piecesPerBundle;
    const configs = rollings.itemBundleConfig[itemNumber].map(
      (item) => item.target
    );
    return piecesPerBundle
      ? pieces % piecesPerBundle === 0
      : configs.some((config) => pieces % config === 0);
  })();

  return (
    <>
      {formError && (
        <Toast
          type="error"
          message={formError}
          onConfirm={() => setFormError(undefined)}
        />
      )}
      {showTons && (
        <EnterTonsDialog
          onConfirm={(value) => {
            if (!customerId) return;
            if (!currentShipToId) return;
            dispatch(
              calculatePiecesFromTons({
                itemNumber,
                length,
                tons: value,
                customerId: customerId,
                shipToId: currentShipToId,
              })
            )
              .unwrap()
              .then((result) => {
                setValue("pieces", result);
                trigger("pieces");
                setShowTons(false);
              })
              .catch((error) => setFormError(error));
          }}
          onCancel={() => setShowTons(false)}
        />
      )}
      <div css={tw`text-xs p-2 pb-px bg-[#ececec]`}>
        <p css={tw`font-semibold`}>Edit Items to Order:</p>
        <p>Due date must be seven days after the rolling end date.</p>
      </div>
      <form onSubmit={submitHandler} css={tw`text-xs w-full bg-[#ececec] p-2`}>
        {isLoading && <Loading />}
        {customerParts && customerParts.length > 0 && (
          <div css={tw`flex mt-2 gap-2`}>
            <p>Order By:</p>
            <fieldset css={tw`flex items-center`}>
              <input
                id="prodId"
                checked={orderBy === "ProductItemNumber"}
                css={tw`mr-1`}
                type="radio"
                value="ProductItemNumber"
                {...(register("orderby"),
                {
                  onChange: () => {
                    setValue("feet", NaN);
                    setValue("inches", NaN);
                    setValue("fraction", 0);
                    setValue("orderby", "ProductItemNumber");
                  },
                })}
              />
              <label htmlFor="prodId" css={tw`bg-nucor-yellow`}>
                Product
              </label>
            </fieldset>
            <fieldset css={tw`flex items-center`}>
              <input
                id="cpn"
                checked={orderBy === "CustomerPartNumber"}
                css={tw`mr-1`}
                type="radio"
                value="CustomerPartNumber"
                {...(register("orderby"),
                {
                  onChange: () => {
                    setValue("orderby", "CustomerPartNumber");
                    const selectedPart = customerParts?.find(
                      (item) => item.customerPartNumber === customerPartNumber
                    );
                    if (!selectedPart) {
                      setValue("feet", NaN);
                      setValue("inches", NaN);
                      setValue("fraction", 0);
                      return;
                    }
                    const len = inchesToLength(selectedPart.length);
                    if (!len) return;
                    setValue("feet", len.feet);
                    setValue("inches", len.inches);
                    setValue("fraction", len.fraction);
                  },
                })}
              />
              <label htmlFor="cpn" css={tw`bg-nucor-yellow`}>
                Part
              </label>
            </fieldset>
          </div>
        )}
        {orderBy === "ProductItemNumber" && (
          <fieldset css={tw`mt-1`}>
            <label css={tw`block`}>
              Product: <span css={tw`text-red-600`}>*</span>
            </label>

            <Select
              hasError={
                orderBy === "ProductItemNumber" && errors.product !== undefined
              }
              highlight
              name="product"
              minWidth="37ch"
              maxWidth="37ch"
              alignTo="right"
              data={products?.map((item) => ({
                value: item.itemNumber,
                label: item.name,
              }))}
              value={itemNumber}
              onChange={(val: string) => {
                setValue("product", val);
              }}
            />
          </fieldset>
        )}
        {orderBy === "CustomerPartNumber" && (
          <fieldset css={tw`mt-1 w-[24ch]`}>
            <label css={tw`block`}>
              Customer Part Number: <span css={tw`text-red-600`}>*</span>
            </label>
            <ComboBox
              value={customerPartNumber}
              highlight
              rightAlignOptions
              hasError={
                orderBy === "CustomerPartNumber" &&
                errors.customerPartNumber !== undefined
              }
              name="customerPartNumber"
              onChange={(value: string) => {
                const part = customerParts?.find(
                  (item) => item.customerPartNumber === value
                );
                if (!part) return;
                const len = inchesToLength(part.length);
                if (!len) return;
                setValue("feet", len.feet);
                setValue("inches", len.inches);
                setValue("fraction", len.fraction);
                setValue("customerPartNumber", part.customerPartNumber);
              }}
              data={customerParts?.map((item) => ({
                value: item.customerPartNumber,
                label: (
                  <span>
                    {item.customerPartNumber}
                    <span css={tw`ml-4`}>{item.displayDescription}</span>
                  </span>
                ),
              }))}
              width="37ch"
            />
          </fieldset>
        )}
        {rollingIds && rollingIds.length > 0 ? (
          <>
            <fieldset css={tw`mt-1`}>
              <label css={tw`block`}>
                Rolling: <span css={tw`text-red-600`}>*</span>
              </label>
              <Select
                minWidth="37ch"
                highlight
                data={rollingIds}
                name="rollingId"
                value={rollingId}
                onChange={(val: string) => {
                  setValue("rollingId", val);
                }}
              />
            </fieldset>
            <fieldset css={tw`flex items-center mt-2`}>
              <label css={tw`w-5/12`}>
                Due Date: <span css={tw`text-red-600`}>*</span>
              </label>
              <DatePicker
                hasError={errors.date !== undefined}
                hideWeekends
                hidePrevious
                highlight
                minDate={stringToDate(minDate)}
                maxDate={stringToDate(maxDate)}
                onChange={(date) => setValue("date", date)}
                value={dueDate}
                name="date"
              />
            </fieldset>
            <div css={tw`flex gap-2 mt-2`}>
              <fieldset css={tw`relative`}>
                {itemNumber &&
                  !validLength &&
                  showFeetError &&
                  selectedRolling && (
                    <div
                      css={tw`absolute -top-5 -left-2 whitespace-nowrap bg-white border border-red-600 text-red-600 px-1 py-[2px] rounded z-50`}
                    >
                      {`Length must be between ${inchesToFormattedString(
                        orderBy === "CustomerPartNumber" ? selectedRolling.internalMinLength : selectedRolling.minLength
                      )} to ${inchesToFormattedString(
                        selectedRolling.maxLength
                      )}`}
                    </div>
                  )}
                <label css={tw`block`}>
                  Feet<span css={tw`text-red-600`}>*</span>
                </label>
                <div onMouseEnter={() => setShowFeetError(true)} onMouseLeave={() => setShowFeetError(false)}>
                  <input
                    disabled={orderBy === "CustomerPartNumber"}
                    type="number"
                    css={[
                      tw`bg-nucor-yellow max-w-[5ch]`,
                      errors.feet && tw`border-red-600`,
                      touchedFields.feet &&
                        itemNumber &&
                        !validLength &&
                        tw`border-red-600`,
                    ]}
                    {...register("feet", { valueAsNumber: true })}
                  />
                </div>
              </fieldset>
              <fieldset css={tw``}>
                <label css={tw`block`}>Inches</label>
                <input
                  disabled={orderBy === "CustomerPartNumber"}
                  type="number"
                  css={tw`bg-nucor-yellow max-w-[5ch]`}
                  {...register("inches", { valueAsNumber: true })}
                />
              </fieldset>
              <fieldset css={tw``}>
                <label css={tw`block`}>Fraction:</label>
                <Select
                  disabled={orderBy === "CustomerPartNumber"}
                  highlight
                  name="fraction"
                  data={fractions}
                  value={fraction}
                  onChange={(frac: number) => {
                    setValue("fraction", frac);
                  }}
                  minWidth="10ch"
                />
              </fieldset>
            </div>
            <fieldset css={tw`flex items-center mt-2`}>
              <label>
                {orderRollingItemsByBundle ? "Bundles:" : "Pieces:"}{" "}
                <span css={tw`text-red-600`}>*</span>
              </label>
              <input
                css={[
                  tw`bg-nucor-yellow max-w-[8ch] ml-2`,
                  errors.pieces && tw`border-red-600`,
                ]}
                type="number"
                autoComplete="off"
                {...register("pieces")}
              />
              <TextButton
                disabled={
                  length < 0.01 || // Disable if no length entered. 0.01 allows lowest fraction.
                  orderRollingItemsByBundle === true ||
                  (orderBy === "ProductItemNumber" && !itemNumber) ||
                  (orderBy === "CustomerPartNumber" && !customerPartNumber)
                }
                css={tw`ml-2`}
                onClick={() => setShowTons(true)}
              >
                Tons
              </TextButton>
            </fieldset>
            {orderRollingItemsByBundle && piecesFromBundle && (
              <div css={tw`mt-1`}>
                <span>Pieces: </span>
                <span>{`${piecesFromBundle.pieces} (${piecesFromBundle.piecesPerBundle} pcs/bdl)`}</span>
              </div>
            )}
            {!orderRollingItemsByBundle && !isMultiple && pieces > 0 ? (
              <p css={tw`text-nucor-green my-1`}>
                Pieces are not a multiple of standard bundling
              </p>
            ) : null}
            <div css={tw`flex justify-end gap-2 mt-2`}>
              <Button type="submit" css={tw`text-xs font-normal py-[2px]`}>
                Update
              </Button>
              <Button onClick={onCancel}>Cancel</Button>
            </div>
            <p css={tw`italic mt-2`}>
              <span css={tw`text-red-600`}>*</span> indicates required fields
            </p>
          </>
        ) : (
          <div className="w-full text-right">
            <p css={tw`mt-4 w-full text-left`}>
              No rollings available for selected item
            </p>
            <Button className="mt-2 text-end shrink-0" onClick={onCancel}>
              Cancel
            </Button>
          </div>
        )}
      </form>
    </>
  );
};

export default EditRollingForm;
