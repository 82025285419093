export type Length = {
  feet: number;
  inches: number;
  fraction: number;
};

export const inchesToLength = (lengthInInches?: number) => {
  if (!lengthInInches) return undefined;
  if (lengthInInches && isNaN(lengthInInches)) return undefined;
  const feet = (lengthInInches - (lengthInInches % 12)) / 12;
  const inches = Math.trunc(lengthInInches % 12);
  const fraction = +((lengthInInches % 12) - inches).toFixed(4);
  return { feet, inches, fraction };
};

export const lengthToInches = (length?: Length) => {
  if (!length) return undefined;
  const result = length?.feet * 12 + length?.inches + length?.fraction;
  if (isNaN(result)) return undefined;
  return result;
};

export const inchesToFormattedString = (inchesToFormat?: number) => {
  const length = inchesToLength(inchesToFormat);
  if (!length) return "";
  const feet = length.feet && length.feet > 0 ? `${length.feet}'` : "";
  const inches =
    length.inches && length.inches > 0
      ? `${length.inches}${length.fraction === 0 ? `"` : ""}`
      : "";
  const fraction =
    length.fraction && length.fraction > 0
      ? `.${length.fraction}"`.slice(2)
      : "";
  return feet + inches + fraction;
};
