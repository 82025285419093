/** @jsxImportSource @emotion/react */
import React, { ReactNode, useState } from "react";
import tw from "twin.macro";
import TextButton from "../../../../components/atoms/TextButton";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  fetchCustomerPartsExcel,
  fetchCustomerPartsPDF,
} from "../../../../store/parts.actions";
import { selectCurrentCustomerId } from "../../../../store/customer.reducer";
import PartListTable from "./PartListTable";
import AnimatedLoadingSpinner from "../../../../components/atoms/AnimatedLoadingSpinner";
import HistoricalPriceModal from "../../../../components/molecules/HistoricalPriceModal";
import { fetchHistoricalPriceSheetsExcel } from "../../../../store/document.actions";
import { selectCurrentAuthorities } from "../../../../store/user.reducer";
import { hasAuthority } from "../../../../helpers/hasAuthority";
import { CustomerPart, CustomerPartDetail } from "../../../../types/types";
import PartDescriptionModal from "../../../../components/molecules/PartDescriptionModal";
import { selectCurrentCustomerParts } from "../../../../store/parts.reducer";

type Props = {
  children?: ReactNode;
};

const Parts: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const currentAuthorities = useAppSelector(selectCurrentAuthorities);
  const currentCustomerParts = useAppSelector(selectCurrentCustomerParts);
  const customerId = useAppSelector(selectCurrentCustomerId);
  const { docStatus } = useAppSelector((state) => state.parts);
  const [showHistoricalPricesModal, setShowHistoricalPricesModal] =
    useState(false);
  const [selectedPart, setSelectedPart] = useState<CustomerPart>();

  const excelHandler = () => {
    if (!customerId) return;
    dispatch(fetchCustomerPartsExcel(customerId));
  };

  const pdfHandler = () => {
    if (!customerId) return;
    dispatch(fetchCustomerPartsPDF(customerId));
  };

  const isInternal = hasAuthority(["internal"], currentAuthorities);
  const canViewPrices = hasAuthority("viewPriceSheets", currentAuthorities);

  return (
    <div css={tw`p-2 text-xs w-full`}>
      {selectedPart && (
        <PartDescriptionModal
          onCancel={() => setSelectedPart(undefined)}
          details={selectedPart}
        />
      )}
      {showHistoricalPricesModal && (
        <HistoricalPriceModal
          checkShipToPricing={false}
          action={fetchHistoricalPriceSheetsExcel}
          onCancel={() => setShowHistoricalPricesModal(false)}
        />
      )}
      <div
        css={tw`text-white bg-nucor-green w-full px-2 py-1 flex gap-4 items-center`}
      >
        <h3 css={tw`uppercase font-semibold`}>Customer Part List</h3>
        <TextButton
          onClick={excelHandler}
          disabled={status === "pending"}
          css={tw`text-white`}
        >
          View Excel
        </TextButton>
        {customerId && canViewPrices && (
          <TextButton onClick={pdfHandler} disabled={status === "pending"} css={tw`text-white`}>
            View PDF
          </TextButton>
        )}
        {customerId && isInternal && (
          <TextButton
            onClick={() => setShowHistoricalPricesModal(true)}
            css={tw`text-white`}
          >
            Historical Part Prices
          </TextButton>
        )}
        {docStatus === "pending" && <AnimatedLoadingSpinner />}
      </div>
      <PartListTable
        onSelect={(selectedPart: CustomerPartDetail) => {
          const part = currentCustomerParts?.find(
            (item) =>
              item.customerPartNumber === selectedPart.customerPartNumber
          );
          setSelectedPart(part);
        }}
      />
    </div>
  );
};

export default Parts;
