/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import tw from "twin.macro";

type Props = {
  children?: ReactNode;
};

const ReleaseOrdersFooter: React.FC<Props> = () => {
  return (
    <>
      <p css={tw`mt-8`}>Weight displayed in pounds</p>{" "}
      <p css={tw`mt-2`}>
        Release Ready Orders includes all items that have completed processing
        and are available to ship immediately.
      </p>
      <p>
        It does not include items on hold (for processing, cutting, or credit)
        or items without allocations.
      </p>
      <p>Due Date searches are based on current due date.</p>
      <p>Past Due searches are based on original due date.</p>
      <p>Original Due Date shown in parentheses if the due date was changed.</p>
    </>
  );
};

export default ReleaseOrdersFooter;
