import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  searchUsers,
  getUserInfo,
  searchCustomers,
  addUser,
  updateUser,
  updateAccessControl,
  exportUsersAsExcel,
  getUserInfoFormSetup,
} from "./admin.actions";
import {
  ErrorResponse,
  UserAdminReadView,
  UserReadView,
  UserUpdateFormSetup,
} from "../types/types";

type InitialState = {
  userList: UserReadView[];
  searchType: "email" | "name" | "customer_id" | "user_id" | "user_search_type" | "all";
  searchSettings: {
    includeDisabled: boolean;
    exportExcel: boolean;
  };
  selectedUser?: UserAdminReadView;
  loading: boolean;
  error?: string | ErrorResponse;
  currentUserListResultsPage: number;
  userFormSetup?: UserUpdateFormSetup;
  hasSearchedUsers: boolean;
};

const initialState: InitialState = {
  userList: [],
  searchType: "email",
  searchSettings: {
    exportExcel: false,
    includeDisabled: false,
  },
  selectedUser: undefined,
  loading: false,
  error: undefined,
  currentUserListResultsPage: 0,
  hasSearchedUsers: false
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setSearchSettings: (
      state,
      action: PayloadAction<{ includeDisabled: boolean; exportExcel: boolean }>
    ) => {
      state.searchSettings = action.payload;
    },
    setSearchType: (state, action: PayloadAction<string>) => {
      state.searchType = action.payload as
        | "email"
        | "name"
        | "customer_id"
        | "user_id"
        | "all";
    },
    resetUsersPage: (state) => {
      state.selectedUser = undefined;
      state.userList = [];
      state.currentUserListResultsPage = 0;
      state.error = undefined;
      state.searchType = "email";
      state.searchSettings = { includeDisabled: false, exportExcel: false };
      state.hasSearchedUsers = false;
    },
    setCurrentResultsPage: (state, action: PayloadAction<number>) => {
      state.currentUserListResultsPage = action.payload;
    },
    clearCurrentUser: (state) => {
      state.selectedUser = undefined;
    },
    assignCustomerToUser: (
      state,
      action: PayloadAction<string[] | undefined>
    ) => {
      if (!state.selectedUser || !action.payload) return;
      state.selectedUser.assignedCustomerIds = [
        ...state.selectedUser.assignedCustomerIds,
        ...action.payload.filter(
          (newId) => !state.selectedUser?.assignedCustomerIds.includes(newId)
        ),
      ];
    },
    removeCustomerFromUser: (
      state,
      action: PayloadAction<string[] | undefined>
    ) => {
      if (!state.selectedUser || !action.payload) return;
      state.selectedUser.assignedCustomerIds =
        state.selectedUser.assignedCustomerIds.filter(
          (customerId) => !action.payload?.includes(customerId)
        );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchUsers.pending, (state) => {
      state.loading = true;
      state.hasSearchedUsers = true;
    });
    builder.addCase(searchUsers.rejected, (state, action) => {
      state.loading = false;
      state.userList = [];
      state.error = action.payload;
    });
    builder.addCase(searchUsers.fulfilled, (state, action) => {
      state.loading = false;
      state.hasSearchedUsers = true;
      state.error = undefined;
      state.userList = action.payload;
    });
    builder.addCase(getUserInfo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserInfo.rejected, (state, action) => {
      state.loading = false;
      state.selectedUser = undefined;
      state.error = action.error.message;
    });
    builder.addCase(getUserInfo.fulfilled, (state, action) => {
      state.loading = false;
      state.error = undefined;
      state.selectedUser = action.payload;
    });
    builder.addCase(searchCustomers.pending, (state) => {
      state.error = undefined;
    });
    builder.addCase(searchCustomers.rejected, (state, action) => {
      state.error = action.error.message;
    });
    builder.addCase(searchCustomers.fulfilled, (state) => {
      state.error = undefined;
    });
    builder.addCase(addUser.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(addUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(addUser.fulfilled, (state, action) => {
      state.loading = false;
      state.error = undefined;
      state.userList = [...state.userList, action.payload];
    });
    builder.addCase(updateUser.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.loading = false;
      state.error = undefined;
      state.userList = [
        ...state.userList.map((user) => {
          if (user.userId === action.payload.userId) {
            return {
              ...action.payload,
              lastLoggedIn: user.lastLoggedIn,
            };
          }
          return user;
        }),
      ];
    });
    builder.addCase(updateAccessControl.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(updateAccessControl.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(updateAccessControl.fulfilled, (state) => {
      state.loading = false;
      state.error = undefined;
    });
    builder.addCase(exportUsersAsExcel.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(exportUsersAsExcel.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(exportUsersAsExcel.fulfilled, (state) => {
      state.loading = false;
      state.error = undefined;
    });
    builder.addCase(getUserInfoFormSetup.fulfilled, (state, action) => {
      state.error = undefined;
      state.userFormSetup = action.payload;
    });
  },
});

export default adminSlice.reducer;
export const {
  clearCurrentUser,
  assignCustomerToUser,
  removeCustomerFromUser,
  setCurrentResultsPage,
  resetUsersPage,
  setSearchType,
  setSearchSettings,
} = adminSlice.actions;
