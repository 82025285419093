import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { AxiosError } from "axios";
import { axiosWithAuth } from "../app/axios";
import {
  SiteSelection,
  AccessControl,
  SalesRep,
  NewAccountRequest,
  ErrorResponse,
  DSM,
  AppVersion
} from "../types/types";
import { fetchProductSelection } from "./product.actions";
import { isBlobError } from "../types/predicates";
import { blobToErrorResponse } from "../helpers/blobToErrorResponse";
import { downloadFile } from "../helpers/downloadExcel";

export const getAccessControl = createAsyncThunk<
  AccessControl,
  undefined,
  { rejectValue: string | ErrorResponse }
>("app/getAccessControl", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.get<AccessControl>(
      `${process.env.REACT_APP_API_URL}/access/find`
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue("Error fetching access restrictions.");
      }
    } else {
      return rejectWithValue("Error fetching access restrictions.");
    }
  }
});

export const getSiteSelectionList = createAsyncThunk<
  SiteSelection[],
  string,
  { rejectValue: string | ErrorResponse }
>(
  "app/getSiteSelectionList",
  async (customerId: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosWithAuth.get<SiteSelection[]>(
        `${process.env.REACT_APP_API_URL}/config/sites${
          customerId ? "?customerId=" + customerId : ""
        }`
      );
      dispatch(fetchProductSelection());
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue(
              "There was an error fetching site selection list."
            );
        }
      } else {
        return rejectWithValue(
          "There was an error fetching site selection list."
        );
      }
    }
  }
);

export const fetchSalesReps = createAsyncThunk<
  SalesRep[],
  undefined,
  { rejectValue: string | ErrorResponse }
>("app/fetchSalesReps", async (_, { rejectWithValue }) => {
  try {
    // API call is not authenticated.
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/request_account/sales_reps`
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal Server Error");
        default:
          rejectWithValue("There was an error fetching list of sales reps");
      }
    }
    return rejectWithValue("There was an error fetching list of sales reps");
  }
});

export const fetchDsms = createAsyncThunk<
  DSM[],
  undefined,
  { rejectValue: string | ErrorResponse }
>("app/fetchDsms", async (_, { rejectWithValue }) => {
  try {
    // API call is not authenticated.
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/internal/customer/dsms`
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal Server Error");
        default:
          rejectWithValue("There was an error fetching list of dsms");
      }
    }
    return rejectWithValue("There was an error fetching list of dsms");
  }
});

export const requestNewAccount = createAsyncThunk<
  NewAccountRequest,
  NewAccountRequest,
  { rejectValue: string | ErrorResponse }
>("app/requestNewAccount", async (data, { rejectWithValue }) => {
  try {
    // API call is not authenticated.
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/request_account`,
      data
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue(
            "There was an issue submitting new account request form."
          );
      }
    } else {
      return rejectWithValue(
        "There was an issue submitting new account request form."
      );
    }
  }
});

export const getSiteContactEmail = createAsyncThunk<
  { email: string },
  void,
  { rejectValue: string | ErrorResponse }
>("app/getSiteContactEmail", async (_, { rejectWithValue }) => {
  try {
    // API call is not authenticated.
    // Had to slice env variable because this endpoint
    // does not begin with /api.
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL?.slice(0, -4)}/public/contact_email`
    );
    return response.data;
  } catch (error: unknown) {
    return rejectWithValue("There was an error fetching site contact email.");
  }
});

export const getVersion = createAsyncThunk<
  AppVersion,
  void,
  { rejectValue: string | ErrorResponse }
>("app/getVersion", async (_, { rejectWithValue }) => {
  try {
    // API call is not authenticated.
    // Had to slice env variable because this endpoint
    // does not begin with /api.
    const response = await axios.get(`${process.env.PUBLIC_URL}/version.json`);
    return response.data;
  } catch (error: unknown) {
    return rejectWithValue("There was an error fetching app version.");
  }
});

export const fetchEPDPdf = createAsyncThunk<
  void,
  undefined,
  { rejectValue: string | ErrorResponse }
>("app/fetchEPDPdf", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/download/epd`,
      {
        responseType: "blob",
      }
    );
    const file = new Blob([response.data], { type: "application/pdf" });
    const filename = response.headers["content-disposition"].split("=")[1];
    downloadFile(file, filename);
    return;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      if (isBlobError(error.response?.data) && error.response) {
        error.response.data = await blobToErrorResponse(error.response.data);
      }
      switch (error.response?.status) {
        case 400:
          return rejectWithValue(error.response?.data);
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue(
            "There was an issue fetching rolling data PDF."
          );
      }
    }
    return rejectWithValue("There was an issue fetching rolling data PDF.");
  }
});
