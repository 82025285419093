/** @jsxImportSource @emotion/react */
import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import tw from "twin.macro";
import { useAppSelector } from "../../../app/hooks";
import SubNavTabs from "../../../components/organisms/SubNavTabs";
import Announcements from "./Announcements";
import RequestNewShipTo from "./requestShipTo/RequestNewShipTo";
import NotFound from "../../app/NotFound";
import CustomerSummary from "./customersummary/CustomerSummary";
import Dashboard from "./dashboard/Dashboard";
import AuthorityRoutes from "../../../routing/AuthorityRoutes";

function Home() {
  const location = useLocation();
  const internal = useAppSelector((state) =>
    state.user.currentContext?.user?.authorities.includes("internal")
  );
  const submenu = [
    { title: "Announcements", to: "announcements", showCustId: true },
    { title: "Request New Ship To", to: "shiptorequest", showCustId: true }
  ];
  if (internal) {
    submenu.push({ title: "Customer Summary", to: "customersummary", showCustId: true });
  }
  if (internal) {
    submenu.push({ title: "ISR Dashboard", to: "dashboard", showCustId: true });
  }

  return (
    <>
      <SubNavTabs submenu={submenu} />
      <div css={tw`p-2 w-full`}>
        <Routes>
          <Route
            path=""
            element={
              <Navigate
                to={{ pathname: "announcements", search: location.search }}
              />
            }
          />
          <Route path="announcements" element={<Announcements />} />
          <Route path="shiptorequest" element={<RequestNewShipTo />} />

          <Route element={<AuthorityRoutes authority={["internal"]} />}>
            <Route path="customersummary" element={<CustomerSummary />} />
            <Route path="dashboard" element={<Dashboard />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </>
  );
}

export default Home;
