// eslint-disable-next-line
export const objectToQueryString = (obj?: Record<string, any>) => {
  if (!obj) return "";
  const keys = Object.keys(obj).filter((key) => obj[key] !== undefined);
  if (keys.length < 1) return "";

  const queryString = ["?"];
  keys.forEach((key, index) => {
    if (obj[key] !== undefined) {
      if (Array.isArray(obj[key])) {
        queryString.push(
          (obj[key] as Array<string>)
            .map(
              (item) => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`
            )
            .join("&")
        );
      } else {
        queryString.push(
          `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`
        );
      }

      if (index < keys.length - 1) {
        queryString.push("&");
      }
    }
  });
  return queryString.join("");
};
