/** @jsxImportSource @emotion/react */
import { yupResolver } from "@hookform/resolvers/yup";
import React, { ReactNode, useState } from "react";
import Button from "../../../../components/atoms/Button";
import { useForm } from "react-hook-form";
import tw from "twin.macro";
import AccountStatus from "../components/AccountStatus";
import { AddUserForm, initialForm, schema } from "./addUserForm";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { addUser } from "../../../../store/admin.actions";
import Roles from "../components/Roles";
import { ErrorResponse } from "../../../../types/types";
import ErrorDisplay from "../../../../components/molecules/ErrorDisplay";
import Loading from "../../../../components/atoms/Loading";
import { selectCurrentUserAuthorities } from "../../../../store/user.reducer";
import Authorities from "../components/Authorities";
import FieldsetSection from "../components/FieldsetSection";
import AssignCustomers from "./components/AssignCustomers";
import PersonalInfo from "./components/PersonalInfo";

type Props = {
  children?: ReactNode;
};

const UserDetail: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const authorities = useAppSelector(selectCurrentUserAuthorities);
  const { userFormSetup } = useAppSelector((state) => state.admin);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | ErrorResponse>();
  const [userAdded, setUserAdded] = useState(false);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm<AddUserForm>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: initialForm,
  });

  const isExternal = watch("selectedRole") === "external";
  const canSelectItAdmin = authorities?.includes("itAdmin") ?? false;

  const submitHandler = handleSubmit((data) => {
    if (data.selectedRole !== "external") {
      delete data.viewNonPrimeStock;
      delete data.viewOrderInvoice;
      delete data.viewPriceSheets;
      delete data.viewPrimeStock;
      delete data.viewRollings;
      delete data.viewShippingDocs;
      delete data.createOrderQuoteLoad;
    }
    const dataAfterRoleUpdate = {
      ...data,
      external: data.selectedRole === "external",
      internal: data.selectedRole === "internal",
      admin: data.selectedRole === "admin",
      itAdmin: data.selectedRole === "itAdmin",
    };
    delete dataAfterRoleUpdate.selectedRole;
    setUserAdded(false);
    dataAfterRoleUpdate.assignedCustomerIds = dataAfterRoleUpdate.internal
      ? []
      : dataAfterRoleUpdate.assignedCustomerIds;
    setLoading(true);
    setError(undefined);
    dispatch(addUser(dataAfterRoleUpdate))
      .unwrap()
      .then(() => {
        setLoading(false);
        setUserAdded(true);
        reset(initialForm);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  });

  return (
    <>
      <div css={[tw`bg-[#eee] text-xs`]}>
        {userAdded && (
          <p css={tw`text-nucor-green font-semibold pt-2 px-2`}>
            User detail saved.
          </p>
        )}
        <div css={tw`pt-2 px-2`}>
          <ErrorDisplay error={error} />
        </div>
        <div css={tw`h-4 border-b border-nucor-gray`} />
        <form css={tw`p-2 relative`}>
          {loading && <Loading>Saving</Loading>}
          <p css={tw`italic text-xs`}>
            <span css={tw`text-red-600`}>*</span> indicates required fields
          </p>
          <div css={[tw`flex justify-between`]}>
            <div css={[tw`w-[40%]`]}>
              <FieldsetSection title="Personal Information">
                <PersonalInfo errors={errors} register={register} />
              </FieldsetSection>
            </div>
            <div css={[tw`w-[42%]`]}>
              <FieldsetSection title="User Account Status">
                <AccountStatus
                  control={control}
                  errors={errors}
                  register={register}
                />
              </FieldsetSection>
              <FieldsetSection css={tw`mt-4`} title="Roles on Portal">
                <Roles disableItAdmin={!canSelectItAdmin} register={register} />
              </FieldsetSection>
              {isExternal && (
                <FieldsetSection css={tw`mt-4`} title="External Authorities">
                  <Authorities
                    register={register}
                    externalAuthorities={userFormSetup?.externalAuthorities}
                  />
                </FieldsetSection>
              )}
            </div>
            <div css={[tw``]}>
              <div css={tw`flex flex-col gap-2`}>
                <Button
                  disabled={!isDirty || !isValid || loading}
                  css={[
                    tw`text-xs text-white py-1 font-medium m-0 mr-2 min-w-[12ch]`,
                    isDirty && isValid && tw`bg-red-700`,
                    loading && tw`bg-gray-300 text-nucor-gray`,
                  ]}
                  type="button"
                  onClick={submitHandler}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
          {isExternal && (
            <FieldsetSection className="mt-4" title="Assign Customers">
              <AssignCustomers
                userAdded={userAdded}
                onChange={(ids: string[]) =>
                  setValue("assignedCustomerIds", ids)
                }
                register={register}
              />
            </FieldsetSection>
          )}
        </form>
      </div>
    </>
  );
};

export default UserDetail;
