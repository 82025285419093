/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { ComponentProps, ReactNode } from "react";
import tw from "twin.macro";

type Props = {
  children?: ReactNode;
  colorStyle?: "light" | "dark";
  message?: string;
} & ComponentProps<"div">;

const AnimatedLoadingSpinner: React.FC<Props> = ({
  colorStyle = "dark",
  message,
  ...remainingProps
}) => {
  const borderColor = colorStyle === "dark" ? "#179A44" : "#FFFFFF44";
  const rotatingColor = colorStyle === "dark" ? "#0B6325" : "#FFF";
  const spinnerCss = css`
    @keyframes spinner {
      to {
        transform: rotate(360deg);
      }
    }
    & {
      content: "";
      box-sizing: border-box;
      position: absolute;
      top: 0%;
      left: 0%;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      border: 2px solid ${borderColor};
      border-top-color: ${rotatingColor};
      animation: spinner 0.6s linear infinite;
    }
  `;
  return (
    <div css={tw`text-xs`} {...remainingProps}>
      <div css={tw`relative`}>
        <div css={spinnerCss} />
      </div>
      <span css={tw`italic ml-5 font-semibold text-nucor-green`}>
        {message ?? "Loading"}
      </span>
    </div>
  );
};

export default AnimatedLoadingSpinner;
