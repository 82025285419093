/** @jsxImportSource @emotion/react */
import React, { ReactNode, useState } from "react";
import tw from "twin.macro";
import Chart from "./Chart";

type Props = {
  children?: ReactNode;
};

export type ChartState = "readyToShip" | "orderHistory" | "shipmentHistory";

const AccountChart: React.FC<Props> = () => {
  const [chartType, setChartType] = useState<ChartState>("readyToShip");

  return (
    <div css={[tw`max-w-[500px]`]}>
      <div css={tw`flex items-center gap-1 mb-2`}>
        <span css={tw`text-gray-500 font-bold text-xs`}>Chart type: </span>
        <input
          checked={chartType === "readyToShip"}
          id="readyToShip"
          css={tw`ml-2`}
          type="radio"
          name="chartType"
          value="readyToShip"
          onChange={() => setChartType("readyToShip")}
        />
        <label htmlFor="readyToShip" css={tw`text-xs`}>
          Ready To Ship
        </label>
        <input
          checked={chartType === "orderHistory"}
          css={tw`ml-2`}
          type="radio"
          name="chartType"
          id="orderHistory"
          value="orderHistory"
          onChange={() => setChartType("orderHistory")}
        />
        <label htmlFor="orderHistory" css={tw`text-xs`}>
          Order History
        </label>
        <input
          checked={chartType === "shipmentHistory"}
          css={tw`ml-2`}
          type="radio"
          name="chartType"
          id="shipmentHistory"
          value="shipmentHistory"
          onChange={() => setChartType("shipmentHistory")}
        />
        <label htmlFor="shipmentHistory" css={tw`text-xs`}>
          Shipment History
        </label>
      </div>
      <Chart chartType={chartType} />
      <div css={tw`text-xs mt-2`}>
        <p>
          <span css={tw`text-[#6d756e]`}>Ready To Ship</span> is a total of the
          weight ready to ship based on the original due date of each order
          item.
        </p>

        <p>
          <span css={tw`text-[#6d756e]`}>Order History</span> is a total by
          month of all the orders submitted for the current customer for the
          past year.
        </p>

        <p>
          <span css={tw`text-[#6d756e]`}>Shipment History</span> is a total by
          month of all the shipments for the current customer for the past year.
        </p>

        <p css={tw`mt-2`}>
          If you have any questions please contact your Sales Rep.
        </p>
      </div>
    </div>
  );
};

export default AccountChart;
