import * as yup from "yup";
import { Phone } from "../../../../types/types";
import { Authority } from "../../../../types/types";

export const initialForm: AddUserForm = {
  firstName: "",
  lastName: "",
  title: "",
  email: "",
  officePhone: {
    areaCode: "",
    exchange: "",
    local4: "",
  },
  officePhoneExt: "",
  mobilePhone: {
    areaCode: "",
    exchange: "",
    local4: "",
  },
  faxPhone: {
    areaCode: "",
    exchange: "",
    local4: "",
  },
  comments: "",
  status: "A",
  admin: false,
  internal: false,
  external: true,
  itAdmin: false,
  assignedCustomerIds: [],
  selectedRole: "external",
  viewNonPrimeStock: false,
  viewOrderInvoice: false,
  viewPriceSheets: false,
  createOrderQuoteLoad: false,
  viewPrimeStock: false,
  viewRollings: false,
  viewShippingDocs: false,
};

export type AddUserForm = {
  firstName: string;
  lastName: string;
  email?: string;
  title: string;
  officePhone: Phone;
  officePhoneExt?: string;
  mobilePhone: Phone;
  faxPhone: Phone;
  comments: string;
  status: "A" | "P" | "D";
  admin: boolean;
  internal: boolean;
  external: boolean;
  itAdmin: boolean;
  assignedCustomerIds?: string[];
  selectedRole?: "admin" | "internal" | "external" | "itAdmin";
} & Partial<Record<Authority, boolean>>;

export const schema = yup.object({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  email: yup
    .string()
    .email("Email must be a valid email address and not contain spaces")
    .required("Email is required"),
  title: yup.string(),
  comments: yup.string(),
  officePhone: yup.object().shape({
    areaCode: yup.string().matches(/^[0-9]{3}$/, {
      message: "Area code must contain 3 numbers",
      excludeEmptyString: true,
    }),
    exchange: yup.string().matches(/^[0-9]{3}$/, {
      message: "Exchange must contain 3 numbers",
      excludeEmptyString: true,
    }),
    local4: yup.string().matches(/^[0-9]{4}$/, {
      message: "Local must contain 4 numbers",
      excludeEmptyString: true,
    }),
  }),
  officePhoneExt: yup.string().matches(/^[0-9]+$/, {
    message: "Extension may only contain numbers",
    excludeEmptyString: true,
  }),
  mobilePhone: yup.object().shape({
    areaCode: yup.string().matches(/^[0-9]{3}$/, {
      message: "Area code must contain 3 numbers",
      excludeEmptyString: true,
    }),
    exchange: yup.string().matches(/^[0-9]{3}$/, {
      message: "Exchange must contain 3 numbers",
      excludeEmptyString: true,
    }),
    local4: yup.string().matches(/^[0-9]{4}$/, {
      message: "Local must contain 4 numbers",
      excludeEmptyString: true,
    }),
  }),
  faxPhone: yup.object().shape({
    areaCode: yup.string().matches(/^[0-9]{3}$/, {
      message: "Area code must contain 3 numbers",
      excludeEmptyString: true,
    }),
    exchange: yup.string().matches(/^[0-9]{3}$/, {
      message: "Exchange must contain 3 numbers",
      excludeEmptyString: true,
    }),
    local4: yup.string().matches(/^[0-9]{4}$/, {
      message: "Local must contain 4 numbers",
      excludeEmptyString: true,
    }),
  }),
});
