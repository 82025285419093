/** @jsxImportSource @emotion/react */
import React, { ReactNode, useContext, useState } from "react";
import tw from "twin.macro";
import QuoteResults from "./quoteResults";
import QuoteEdit from "./quoteEdit";
import {
  clearQuickQuoteView,
  updateSelectedQuoteId
} from "../../../../store/document.reducer";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import PDFEmbed from "../../../../components/molecules/PDFEmbed";
import { PDFContext } from "./quoteList";
import { hasAuthority } from "../../../../helpers/hasAuthority";
import { selectCurrentAuthorities } from "../../../../store/user.reducer";
import { useNavigate } from "react-router-dom";
import TextButton from "../../../../components/atoms/TextButton";

type Props = {
  children?: ReactNode;
  setIsSidebarHidden: React.Dispatch<React.SetStateAction<boolean>>;
};

const QuoteContent: React.FC<Props> = ({ setIsSidebarHidden }) => {
  const dispatch = useAppDispatch();
  const currentAuthorities = useAppSelector(selectCurrentAuthorities);
  const isInternal = hasAuthority("internal", currentAuthorities);
  const [newQuote, setNewQuote] = useState(false);
  const [prospectiveCustomer, setProspectiveCustomer] = useState(false);
  const selectedQuoteId = useAppSelector((state) => state.document.quickquote.selectedQuoteId);
  const navigate = useNavigate();
  const { status } = useAppSelector(
    (state) => state.document.quickquote
  );
  const ctx = useContext(PDFContext)

  const newQuoteHandler = (value: boolean, prospective: boolean) => {
    dispatch(updateSelectedQuoteId(0))
    dispatch(clearQuickQuoteView());
    setNewQuote(value);
    navigate(`/portal/mydocuments/portalquote/`);
    setProspectiveCustomer(prospective);
    setIsSidebarHidden(value);
  }


  return (
    <div>

      <div css={tw`text-white font-bold bg-nucor-green text-xs flex justify-between py-1 px-2 mb-2`}>
        <div css={tw`flex w-2/5`}>
          <span css={tw`uppercase`}>Portal Quote</span>

          {isInternal && !newQuote && selectedQuoteId === 0 && (
              <>
                <TextButton onClick={() => newQuoteHandler(true, false)} disabled={status === "pending"} css={tw`ml-4 text-white disabled:text-nucor-gray`}>
                  New Quote
                </TextButton>
                <TextButton onClick={() => newQuoteHandler(true, true)} disabled={status === "pending"} css={tw`ml-4 text-white disabled:text-nucor-gray`}>
                  Quote Prospective Customer
                </TextButton>
              </>
            )}
        </div>
      
        {(selectedQuoteId !== 0 || newQuote) && (
          <TextButton onClick={() => newQuoteHandler(false, false)} css={tw`text-white`}>
            Back to List
          </TextButton>
        )}

        <div className="w-2/5"></div>
      </div>
      <div css={[ctx?.file && tw`hidden`]}>
        {newQuote || selectedQuoteId !== 0 ? <QuoteEdit selectedQuote={selectedQuoteId || 0} newProspectiveCustomer={prospectiveCustomer || false} cancelNewQuote={() => newQuoteHandler(false, false)} /> : <QuoteResults />}
      </div>
      {ctx?.file && (
        <PDFEmbed
          setFile={ctx?.setFile}
          onCancel={() => ctx?.setFile(null)}
          file={ctx?.file}
        />
      )}

      {(selectedQuoteId !== 0 || newQuote) && (
        <p css={tw`my-4 w-full text-xs p-2`}>
          Disclaimer: All quotes are valid for 5 days from the original quote date
          unless otherwise specified. All Cutting Quotes 60&quot; and under are
          subject to Manager approval. All orders submitted with a purchase order
          are not considered firm or binding until NUCOR TUBULAR PRODUCTS INC.
          acknowledges with a sales order number. All inventories are subject to
          prior sale. Mill promise dates are subject to available capacity and raw
          material constraints. Please add one week to those dates for delivery.
          All quoted prices are price in effect at time of shipment unless
          otherwise noted. All orders are subiect to the{" "}
          <a
            target="_blank"
            rel="noreferrer"
            css={tw`text-nucor-link hover:text-nucor-link-hover font-bold focus-visible:text-nucor-link-hover focus-visible:outline-none`}
            href="/terms"
          >
            terms and conditions
          </a>{" "}
          of sale
        </p>
      )}

    </div>
  );
};

export default QuoteContent;
