/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import tw from "twin.macro";
import { numberFormatter } from "../../../../helpers/numberFormat";
import { LoadLineSummary } from "../../../../types/types";

type Props = {
  children?: ReactNode;
  lineDetail: LoadLineSummary;
};

const OpenLoadsItemDetail: React.FC<Props> = ({ lineDetail }) => {
  return (
    <tr>
      <td css={tw`w-[8%]`}>{lineDetail.site}</td>
      <td
        css={tw`w-[30%]`}
      >{`${lineDetail.shippingCustomerId} - ${lineDetail.customerName}`}</td>
      <td>{lineDetail.customerPONumber}</td>
      <td css={tw`w-[15%]`}>{lineDetail.salesOrderNumber}</td>
      <td>{lineDetail.lineDescription}</td>
      <td css={tw`text-right`}>{lineDetail.lengthDescription}</td>
      <td css={tw`w-[10%] text-right`}>{numberFormatter(lineDetail.weight)}</td>
    </tr>
  );
};

export default OpenLoadsItemDetail;
