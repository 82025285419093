/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import tw from "twin.macro";

type Props = {
  children?: ReactNode;
};

const Terms: React.FC<Props> = () => {

  return (
    <div css={tw`w-full min-h-[80vh] flex justify-center items-center`}>
      <div css={tw`min-w-[300px] border shadow p-6 bg-white text-sm`}>
        <h2 css={tw`text-xl underline font-bold flex justify-center`}>TERMS AND CONDITIONS OF USE</h2>
        <p css={tw`mt-10 font-bold uppercase`} >PLEASE READ THESE TERMS AND CONDITIONS OF USE CAREFULLY BEFORE USING THIS SITE.</p>

        <p css={tw`mt-5`}>Nucor Tubular Products
        Inc., together with its parent and its divisions, affiliates and subsidiaries (collectively hereinafter “<span css={tw`underline`}>Company</span>”), owns
        and operates the websites <span css={tw`text-nucor-link`}><a target="_blank" rel="noreferrer" href="https://www.nucortubular.com">https://www.nucortubular.com</a></span> 
        and <span css={tw`text-nucor-link`}><a target="_blank" rel="noreferrer" href="https://www.ntpportal.com">https://www.ntpportal.com</a></span> (collectively hereinafter the “<span css={tw`underline`}>Site</span>”).
        By using the Site, you signify your agreement to these Terms and Conditions of
        Use (“<span css={tw`underline`}>Terms and Conditions</span>”). If you do not agree to all of these Terms
        and Conditions, do not use the Site. Company may revise and update these Terms
        and Conditions at any time. Your continued usage of the Site will mean you
        accept those changes.</p>

        <p css={tw`mt-5 ml-10 font-bold underline`}>1. Use of Content.</p>

        <p css={tw`mt-5 ml-20`}>a. The contents of the Site, such as text,
          graphics, user interfaces, visual interfaces, images, logos, trademarks, servicemarks,
          specifications, certifications, technical guidelines, catalogs, Tools (as
          defined below), software, data, and other Company material contained on the
          Site, including the design, structure, selection, coordination, expression,
          “look and feel” and arrangement of the foregoing (collectively “<span css={tw`underline`}>Company Content</span>”),
          is protected by copyright, trademark, and/or other laws of the United States, Canada,
          and/or Mexico. Ownership of the Company Content remains with Company, its
          licensors, or third-party content providers. Any use of the Company Content
          not expressly permitted by these Terms and Conditions is a breach of these
          Terms and Conditions and may violate copyright, trademark, and other laws. No
          part of the Site or Company Content may be copied, reproduced, republished,
          uploaded, posted, publicly displayed, encoded, translated, transmitted, sold,
          used to prepare derivative works, or distributed in any way (including “mirroring”)
          to any other computer, server, website or other medium for publication or distribution
          or for any commercial enterprise, without Company’s express prior written
          consent. In addition to the use of the Company Content provided under this
          Section 1, the portion of the Company Content related to the Tools is further
          subject to Section 3 below. The Site also includes your
          information, such as your orders, order information, delivery schedules,
          quotes, your pricing, or other information related to your business
          (collectively “<span css={tw`underline`}>Your Content</span>”). Ownership of Your Content remains
          with you. Company Content and Your Content are collectively referred to herein
          as “<span css={tw`underline`}>Content</span>.” Content may contain technical inaccuracies or
          typographical errors.</p>



          <p css={tw`mt-5 ml-20`}>b. Company authorizes you to view, download, or
          print Your Content; however, you are not authorized to download or print
          Company Content, except that you may download or print no more than five (5)
          copies of Company’s catalogs, specifications, data sheets, or forms for your
          internal business use if you include all copyright and proprietary rights
          notices that are contained in such documents. Any special rules for the use of
          certain Content accessible on the Site may be included elsewhere within the
          Site and are incorporated into these Terms and Conditions by reference.</p>

          <p css={tw`mt-5 ml-20`}>c. If you violate any of these Terms and
          Conditions, your permission to use the Company Content automatically
          terminates and you must immediately destroy any copies you have made of
          any portion of the Company Content.</p>

          <p css={tw`mt-5 ml-10 font-bold underline`}>2. Use of the Site.</p>

          <p css={tw`mt-5 ml-20`}>a. In your use of the Site, you agree to act
          responsibly in a manner demonstrating the exercise of good judgment. For
          example and without limitation, you agree not to (i) use the Site for any
          purpose in violation of local, state, federal, provincial, international, or
          other applicable laws; (ii) insert your own or a third party’s advertising,
          branding or other promotional content into any of the Company Content or use,
          redistribute, republish or exploit the Company Content for any further commercial
          or promotional purposes; (iii) infringe or violate the rights of any third
          party, including without limitation, intellectual property, privacy, publicity
          or contractual rights; (iv) engage in spidering, “screen scraping”, “database
          scraping”, harvesting of e-mail addresses, wireless addresses, other contact or
          personal information, any Company Content, or any other automatic means of
          obtaining lists of users or other information from or through the Site or the
          services offered on or through the Site, including without limitation any
          information residing on any server or database connected to the Site; (v) attempt
          to interfere with, interrupt, damage, disable, overburden, or impair the Site
          or interfere with any other party’s use and enjoyment of the Site or the
          Content, through the use of any device, software, or routine, including,
          without limitation, through the use of viruses, cancel bots, Trojan horses,
          harmful code, flood pings, denial of service attacks, packet or IP spoofing,
          forged routing or electronic mail address information or similar methods or
          technology; (vi) probe, scan, or test the vulnerability of the Site or any
          network connected to the Site, nor breach the security or authentication
          measures on the Site or any network connected to the Site; (vii) attempt to
          gain unauthorized access to other computer systems through the Site; (viii)
          assist anyone else in doing any of the foregoing; or (ix) attempt (or encourage
          or support anyone else’s attempt) to circumvent, reverse engineer, decrypt, or
          otherwise alter or interfere with the Site or any Content thereof, or make any
          unauthorized use thereof. You shall not obtain or attempt to obtain any
          materials or information through any means not intentionally made publicly
          available or provided through the Site.</p>

          <p css={tw`mt-5 ml-20`}>b. Unless products or services are sold directly through a
          location on the Site, such as through a Tool, any cost estimates provided elsewhere on the Site, or
          through a Tool, if any, are simply estimates and should not be relied on by you
          for any purpose, as such, these estimates are not quotes or offers by Company
          to sell any products or services to you.</p>

          <p css={tw`mt-5 ml-10 underline font-bold`}>3. Use of Tools.</p>

          <p css={tw`mt-5 ml-20`}>a. You further agree that your use of the
          functionality and tools provided on the Site, including the functionality
          and tools for providing product information,
          engineering specifications or designs, through web-based applications or
          downloadable software or add-ins, (collectively “Tools”), is
          for the sole purpose of identifying potential products or services
          (except for Tools through which products or services are sold directly).
          Output returned by a Tool may include suggestions from Company’s catalogs,
          specifications, or data sheets, or calculations made therefrom; as such, the information accessed through use of any Tool
          is presented for general information only and is not to be used or relied
          upon for any application without independent verification by a licensed
          professional engineer, or other qualified professional.</p>

          <p css={tw`mt-5 ml-20`}>b. In addition to the obligations provided
          under Section 1 with respect to the Content, you further agree (i) not
          to, or allow any third party to, disassemble, decompile, or otherwise
          reverse engineer any Tool provided by or through the Site, including the
          executable, object, or source code of such Tool; and (ii) to receive independent verification
          by a licensed professional engineer, or other qualified professional, of
          the information accessed through the use of any Tool.</p>

          <p css={tw`mt-5 ml-20`}>c. You hereby agree, warrant and represent
          to Company that you own or otherwise have rights, including any
          intellectual property and other proprietary right, in and to any data or
          other information that you provide in connection with your use of any
          Tool and you hereby grant Company a license to use such data and other
          information, including in connection with any Tool. Moreover, you warrant
          and represent that none of the data or other information that you provide
          in connection with your use of any Tool infringes any third party’s
          intellectual property and other proprietary rights.</p>

          <p css={tw`mt-5 ml-10 underline font-bold`}>4. Liability.</p>

          <p css={tw`mt-5 ml-20 uppercase`}>a. Company IS NOT RESPONSIBLE FOR ANY VIRUSES OR OTHER
          ROUTINES THAT HARM YOUR COMPUTER OR SOFTWARE, WHICH YOU MAY COME IN
          CONTACT WITH WHILE USING THE SITE; NOR IS Company RESPONSIBLE FOR ANY FAILURE, MECHANICAL OR
          OTHERWISE, OF THE SITE OR OF ANY CONTENT OR SERVICES, INCLUDING THE
          TOOLS, AVAILABLE THROUGH THE SITE. FURTHERMORE,
          WHEN using the Site, information will be transmitted over a medium that
          may be beyond the control and jurisdiction of Company. Accordingly,
          Company assumes no liability for or relating to ANY delay, failure,
          interruption, or corruption of any data or other information or material
          transmitted to or received from the Site.</p>


          <p css={tw`mt-5 ml-20`}>b. The Site and
          the Content, including the Tools and any information provided through the
          Tools, are provided on an “as is” basis. Company,
          ITS LICENSORS, AND ITS THIRD-PARTY CONTENT PROVIDERS, TO THE FULLEST
          EXTENT PERMITTED BY LAW, DISCLAIM ALL REPRESENTATIONS, WARRANTIES, AND
          CONDITIONS, EITHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING
          BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
          NON-INFRINGEMENT OF THIRD PARTIES’ RIGHTS, AND FITNESS FOR PARTICULAR
          PURPOSE.</p>

          <p css={tw`mt-5 ml-20`}>c. To the best of
          Company’s knowledge, the information contained in the Content is
          accurate. However, Company, its licensors, and its
          third-party content providers, including any third-party providers of any
          portion of the Tools, make no representations or warranties, either
          express or implied, that the Content included in or available through the
          site is accurate, reliable, current or correct FOR ANY PARTICULAR PURPOSE
          OR USE; that the Content will be available at any particular time or
          location; Or that any defects or errors will be corrected.</p>

          <p css={tw`mt-5 ml-20`}>d. In no event
          shall Company, its licensors, OR its third-party Content providers be
          liable for any damages (including, without limitation, dIRECT, indirect,
          exemplary, incidental, And consequential damages, personal
          injury/wrongful death, DAMAGE TO PROPERTY, lost profits, or damages
          resulting from lost data or business interruption) resulting from YOUR
          use of or inability to use the Site or The Content, including the Tools,
          whether based on warranty, contract, tort, or any other legal theory, and
          whether or not Company, its licensors, or its third party content
          providers are advised of the possibility of such damages.</p>

          <p css={tw`mt-5 ml-20`}>e. Any claims arising in connection with
          your use of the Site or any Content must be brought within one (1) year
          of the date the event giving rise to such action occurred. Your remedies
          arising from any claim relating to the Site or the Content that are set
          forth in these Terms and Conditions are exclusive and are limited to
          those expressly provided for in these Terms and Conditions.</p>

          <p css={tw`mt-5 ml-10 underline font-bold`}>5. User Submissions. </p>
 

          <p css={tw`mt-5 ml-20`}>a. By submitting to Company via e-mail or the Site any ideas, 
          suggestions, concepts, methods, systems, designs, plans, techniques, business information, 
          inventions, how-to or product information or material relating to Company’s business, products, 
          or services, including any submissions made to the Tools (collectively, “Ideas”), 
          you: (i) agree such submission is non-confidential for all purposes, 
          (ii) grant Company an unrestricted, irrevocable, perpetual, sub-licensable, transferrable, 
          royalty free license to use, reproduce, display, perform, modify, transmit and distribute the Ideas, 
          where applicable, and (iii) represent and warrant that you own or have a license to, 
          or otherwise control all of the rights to, the Ideas and that Company is free 
          to use the Ideas that you provide Company for any purpose.  
          Company may sublicense its rights through multiple tiers of sublicenses.</p>
  
 
          <p css={tw`mt-5 ml-20`}>b. You acknowledge and agree that any personal information you provide to Licensor 
          (including, without limitation, your name, company, title, address, telephone number,
           e-mail address, social media contact information) will be subject to our privacy policy 
           (<span css={tw`text-nucor-link`}><a href="/privacy" target="_blank">Privacy Policy</a></span> and <span css={tw`text-nucor-link`}><a href="/privacy#California" target="_blank">California Privacy Disclosure</a></span>) which is incorporated into these Terms and Conditions by reference.</p>

           <p css={tw`mt-5`}><span css={tw`font-bold mr-3 underline`}>6. Links to Other Sites.</span> Company may provide links to third-party websites and 
           websites of parent, subsidiary, and affiliate companies (“Linked Sites”). 
            THESE LINKED SITES ARE NOT UNDER COMPANY’S CONTROL, AND COMPANY IS NOT RESPONSIBLE FOR, 
            AND DOES NOT ENDORSE, THE CONTENT OF LINKED SITES, AND DOES NOT MAKE ANY REPRESENTATIONS 
            OR WARRANTIES REGARDING THEIR CONTENT OR ACCURACY. YOUR USE OF LINKED SITES IS AT YOUR OWN 
            RISK AND SUBJECT TO THE TERMS AND CONDITIONS OF USE FOR SUCH LINKED SITES.</p>

            <p css={tw`mt-5`}><span css={tw`font-bold mr-3 underline`}>7. Indemnity.</span>  YOU AGREE TO DEFEND (USING COUNSEL ACCEPTABLE TO COMPANY, IN ITS SOLE DISCRETION), 
            INDEMNIFY AND HOLD COMPANY, ITS OFFICERS, DIRECTORS, SHAREHOLDERS, EMPLOYEES, AGENTS, 
            LICENSORS, AFFILIATE, AND SUPPLIERS, HARMLESS FROM AND AGAINST ANY AND ALL LIABILITIES, 
            CLAIMS, EXPENSES, CAUSES OF ACTION, DEMANDS, SETTLEMENTS, AND/OR DAMAGES 
            (INCLUDING REASONABLE LEGAL FEES AND COSTS) RESULTING FROM, OR ALLEGED TO RESULT FROM:
             (I) YOUR USE OF AND ACCESS TO THE SITE AND CONTENT; (II) YOUR VIOLATION OF ANY 
             TERM OF THESE TERMS AND CONDITIONS; (III) INFORMATION OR MATERIAL PROVIDED TO COMPANY BY 
             YOU; (IV) A THIRD PARTY’S RIGHTS (INCLUDING, BUT NOT LIMITED TO, PATENTS, COPYRIGHTS, 
             TRADEMARK, TRADE SECRETS, OR OTHER INTELLECTUAL OR PROPRIETARY RIGHT, MORAL RIGHTS, 
             RIGHTS OF PRIVACY, AND REPUTATIONAL RIGHTS) THAT WERE VIOLATED BY ANY INFORMATION OR
              MATERIAL PROVIDED TO COMPANY BY YOU OR BY COMPANY’S PUBLICATION OR OTHER LAWFUL USE OF 
              ANY INFORMATION OR MATERIAL PROVIDED TO COMPANY BY YOU.  COMPANY RESERVES THE RIGHT TO 
              ASSUME, AT YOUR COST, THE DEFENSE AND CONTROL OF ANY MATTER OTHERWISE SUBJECT TO 
              INDEMNIFICATION BY YOU, IN WHICH EVENT YOU WILL COOPERATE WITH COMPANY IN ASSERTING ANY AVAILABLE DEFENSES.</p>

              <p css={tw`mt-5`}><span css={tw`font-bold mr-3 underline`}>8. General.</span></p>
              <p css={tw`mt-5 ml-20`}>a. Company has locations throughout the United States.  
              Company makes no claims that the Site and the Content are appropriate or may be downloaded 
              outside of the United States, Canada, and Mexico.  
              Access to the Content may not be legal by certain persons or in certain countries. 
              If you access the Site from outside of the United States, Canada, or Mexico, 
              you do so at your own risk and are responsible for compliance with the laws of your jurisdiction.</p>
  
              <p css={tw`mt-5 ml-20`}>b. Company reserves the right to do any of the following: (i) to modify, 
              suspend or terminate operation of or access to any portion of the Site or Content thereon, 
              for any reason, including without limitation, if Company believes that your conduct violates 
              applicable laws or is harmful to the interests of Company; (ii) to modify or change any 
              portion of the Site or Content thereon; or (iii) to interrupt the operation of any portion 
              of the Site or Content thereon, as necessary to perform routine or non-routine maintenance, 
              error correction, or other changes. All rights not expressly granted herein are reserved to Company and its licensors.</p>
  
              <p css={tw`mt-5 ml-20`}>c. If Company takes any legal action against you as a result of your violation, 
              or pursuant to any provision, of these Terms and Conditions, Company will be entitled to
               recover from you, and you agree to pay all reasonable legal fees and costs of such action, 
               in addition to any other relief granted to Company. You agree that Company will not be 
               liable to you or to any third party for termination of your access to the Site or Content 
               as a result of any violation of these Terms and Conditions.</p>
  
              <p css={tw`mt-5 ml-20`}>d. The following provisions survive the expiration or termination of 
              these Terms and Conditions and your use of the Site for any reason whatsoever: Section 3, 
              Section 4, Section 5, Section 6, Section 7, Section 8c, this Section 8d, Section 9, Section 10, 
              and any provision that by its nature would survive expiration or termination.</p>
  
              <p css={tw`mt-5 ml-20`}>e. With respect to users located in Canada, 
              the parties to these Terms and Conditions hereby confirm their express wish that 
              these Terms and Conditions, as well as all other documents related to it, 
              including but not limited to all confirmations, notices, waivers, consents and other 
              communications between the parties in connection with these Terms and Conditions 
              be in the English language only and declare themselves satisfied with this; 
              <span css={tw`italic`}>Les parties aux présentes conditions générales par les présentes confirment leur 
              volonté expresse que ces termes et Conditions, ainsi que tous les autres documents concernant, 
              y compris mais non limité à tous les confirmations, les avis, les dispenses, 
              consentements et autres communications entre les parties dans le cadre de ces 
              modalités et Conditions en langue anglaise seulement et se déclarent satisfaits de cette</span></p>

              <p css={tw`mt-5`}><span css={tw`font-bold mr-3`}>9. Jurisdiction.</span></p>
 

              <p css={tw`mt-5 ml-20 underline font-bold`}>a.United States</p>
  
 
              <p css={tw`mt-5 ml-40 underline`}>i.  With respect to users located in the United States, 
              you expressly agree that exclusive jurisdiction for any dispute with Company,
               or in any way relating to your use of the Site, resides in the state or federal courts 
               located in Charlotte, North Carolina, and you further agree and expressly consent to the 
               exercise of personal jurisdiction of such courts in connection with any such dispute 
               including any claim involving Company or its parent companies, affiliates, subsidiaries, 
               employees, contractors, officers, and directors.</p>
 
  
 
               <p css={tw`mt-5 ml-40 underline`}>ii. With respect to users located in the United States, 
               these Terms and Conditions are governed by the laws of the State of North Carolina 
               without respect to the conflict of law principles. If any provision of these Terms 
               and Conditions is found to be invalid by any court having competent jurisdiction, 
               the invalidity of such provision shall not affect the validity of the remaining 
               provisions of these Terms and Conditions, which shall remain in full force and 
               effect. No waiver of any provision of the Terms and Conditions shall be deemed a 
               further or continuing waiver of such provision of the Terms and Conditions or any other provision therein.</p>
 
  
 
               <p css={tw`mt-5 ml-20 underline font-bold`}>b. Canada</p>
  
 
               <p css={tw`mt-5 ml-40 underline`}>i. With respect to users located in Canada, 
               you expressly agree that exclusive jurisdiction for any dispute with Company, 
               or in any way relating to your use of the Site, resides in the courts in the 
               City of Toronto, Ontario, and you further agree and expressly consent to the 
               exercise of personal jurisdiction of such courts in connection with any such 
               dispute including any claim involving Company or its parent companies, affiliates, 
               subsidiaries, employees, contractors, officers, and directors.</p>
 
  
 
               <p css={tw`mt-5 ml-40 underline`}>ii. With respect to users located in Canada, 
               these Terms and Conditions are governed by the internal substantive 
               laws of Ontario, Canada, without respect to the conflict of law principles. 
               If any provision of these Terms and Conditions is found to be invalid by 
               any court having competent jurisdiction, the invalidity of such provision 
               shall not affect the validity of the remaining provisions of these Terms 
               and Conditions, which shall remain in full force and effect. No waiver of 
               any provision of the Terms and Conditions shall be deemed a further or 
               continuing waiver of such provision of the Terms and Conditions or any other provision therein.</p>
 
  
 
              <p css={tw`mt-5 ml-20 underline font-bold`}>c. Mexico</p>
  
 
              <p css={tw`mt-5 ml-40 underline`}>i. With respect to users located in Mexico, 
              you expressly agree that exclusive jurisdiction for any dispute with Company, 
              or in any way relating to your use of the Site, resides in the courts in Mexico City, 
              Mexico, and you further agree and expressly consent to the exercise of personal 
              jurisdiction of such courts in connection with any such dispute including any 
              claim involving Company or its parent companies, affiliates, subsidiaries, 
              employees, contractors, officers, and directors.</p>
 
  
 
              <p css={tw`mt-5 ml-40 underline`}>ii. With respect to users located in Mexico,
               these Terms and Conditions are governed by the internal substantive laws of Mexico, 
               without reference to, or application of, its conflict of laws rules and principles. 
               If any provision of these Terms and Conditions is found to be invalid by any 
               court having competent jurisdiction, the invalidity of such provision shall 
               not affect the validity of the remaining provisions of these Terms and Conditions, 
               which shall remain in full force and effect. No waiver of any provision of the 
               Terms and Conditions shall be deemed a further or continuing waiver of such 
               provision of the Terms and Conditions or any other provision therein.</p>
 
  
 
               <p  css={tw`mt-5`}><span css={tw`mt-5 font-bold underline`}>10. Complete Agreement.</span> These Terms and Conditions, along with any End User License Agreement, 
               if any, for the Tools, constitutes the entire agreement between you and 
               Company with respect to the use of the Site and Content, including the Tools. 
               Company’s obligations, if any, with regard to its products and services are 
               governed solely by the agreements pursuant to which such products and 
               services are provided, and nothing on this Site should be construed to alter such agreements.</p>
  
               <p  css={tw`mt-5`}>Questions or comments regarding the Site should be submitted to: Eldrick Tate at eldrick.tate@nucor.com.</p>
               <p  css={tw`mt-5`}>Copyright © 2019 Nucor Corporation. All rights reserved.</p>
 
      </div>
    </div>
  );
};

export default Terms;
