/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import tw from "twin.macro";
import { useAppDispatch } from "../../app/hooks";
import { fetchUser } from "../../store/user.actions";
import { useNavigate } from "react-router-dom";

type Props = {
  children?: ReactNode;
};

const Restricted: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const fetchUserHandler = () => {
    dispatch(fetchUser())
      .unwrap()
      .then(() => {
        navigate("/portal");
      })
      .catch((error) => console.log(error));
  };

  return (
    <div css={tw`w-full mt-20 flex justify-center`}>
      <div css={tw`w-[60%] max-w-[700px] border shadow p-6 bg-white`}>
        <h2 css={tw`text-xl text-red-600 font-bold`}>
          Temporarily Unavailable
        </h2>
        <p css={tw`text-xs my-4`}>
          The Nucor Tubular Products portal is currently performing scheduled
          maintenance and updates. We apologize for any inconvenience. Please
          try again later.
        </p>{" "}
        <button
          css={tw`text-xs font-semibold text-nucor-link underline hover:(text-nucor-link-hover no-underline)`}
          onClick={fetchUserHandler}
        >
          Refresh
        </button>
      </div>
    </div>
  );
};

export default Restricted;
