/** @jsxImportSource @emotion/react */
import React, { ReactNode, useState } from "react";
import tw from "twin.macro";
import FullCustomerList from "./FullCustomerList";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import ChangeShipToDialog from "../../features/portal/stock/prime/inventory/ChangeShipToDialog";
import { setCurrentCustomerDetails } from "../../store/customer.actions";
import { useLocation, useNavigate } from "react-router-dom";
import CustomerDisplay from './CustomerDisplay';
import { selectCurrentCustomerId } from "../../store/customer.reducer";

type Props = {
  children?: ReactNode;
  isStockPage: boolean;
};

const InternalCustomerList: React.FC<Props> = ({ isStockPage }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const currentCustomer = useAppSelector(
    (state) => state.customer.currentCustomer
  );
  const [showFullList, setShowFullList] = useState(false);
  const [requestedCustomer, setRequestedCustomer] = useState("");
  const selectedCustomerId = useAppSelector(selectCurrentCustomerId);

  const changeCustomerHandler = (id?: string) => {
    if (!id || id === selectedCustomerId) return;
    if (isStockPage) {
      setRequestedCustomer(id);
    } else {
      dispatch(setCurrentCustomerDetails(id));
      navigate({ pathname: location.pathname, search: "?customer_id=" + id }, { replace: true });
    }
  };

  return (
    <>
      {requestedCustomer !== "" && (
        <ChangeShipToDialog
          onConfirm={() => {
            const id = requestedCustomer;
            dispatch(setCurrentCustomerDetails(requestedCustomer));
            setRequestedCustomer("");
            navigate({ pathname: location.pathname, search: "?customer_id=" + id }, { replace: true });
          }}
          onCancel={() => setRequestedCustomer("")}
        />
      )}

        <div css={tw`text-xs w-full flex items-center justify-end`}>
          <button
            css={tw`max-w-[calc(100% - 10px)] text-left flex items-center gap-2 border-b border-b-nucor-light-green bg-transparent border-0 hover:(border-b-white)
              focus-visible:outline-1 focus-visible:outline-dotted focus-visible:outline-offset-2 focus-visible:outline-nucor-light-gray focus:outline-none`}
            type="button"
            onClick={() => setShowFullList(true)}
          >
            <CustomerDisplay currentCustomer={currentCustomer}/>
          </button>
        </div>
      {showFullList && (
        <FullCustomerList
          onConfirm={(id?: string) => {
            if (!id) return;
            changeCustomerHandler(id);
            setShowFullList(false);
          }}
          onCancel={() => setShowFullList(false)}
        />
      )}
    </>
  );
};

export default InternalCustomerList;
