import React from "react";
import ReactDOM from "react-dom/client";
import Auth0ProviderWithHistory from "./auth0-provider-with-history";
import { Provider } from "react-redux";
import store from "./store/store";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <Auth0ProviderWithHistory>
        <App />
      </Auth0ProviderWithHistory>
    </Provider>
  </BrowserRouter>
);
