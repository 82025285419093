/** @jsxImportSource @emotion/react */
import React, { ReactNode, useEffect, useState } from "react";
import tw from "twin.macro";
import { useAppDispatch } from "../../../../../../app/hooks";
import Select from "../../../../../../components/atoms/Select";
import {
  updateCartNotes,
  updateDocType,
  updatePrePayAndAdd,
} from "../../../../../../store/shoppingCart.reducer";
import { CartHeader, DocType } from "../../../../../../types/types";
import { formatDateToShort } from "../../../../../../helpers/dateHelpers";

type Props = {
  children?: ReactNode;
  confirmation?: boolean;
  cart?: CartHeader;
};

const OrderDetails: React.FC<Props> = ({ confirmation, cart }) => {
  const dispatch = useAppDispatch();
  const [notesValue, setNotesValue] = useState(cart?.notes ?? "");
  const docTypes = cart?.docTypes?.map((docType) => ({
    value: docType.id,
    label: docType.description,
  }));

  useEffect(() => {
    setNotesValue(cart?.notes ?? "");
  }, [cart]);

  return (
    <dl css={tw`grid grid-cols-[30% 70%] gap-[2px]`}>
      <dt css={tw`font-bold text-nucor-gray`}>Order Date:</dt>
      <dd>{formatDateToShort(cart?.orderDate)}</dd>
      <dt css={tw`font-bold text-nucor-gray`}>Pricing:</dt>
      <dd>{cart?.pricingModel}</dd>
      {cart?.canChangeDeliveryTerms && (
        <>
          <dt css={tw`font-bold text-nucor-gray`}>Prepay & Add:</dt>
          <dd>
            {confirmation ? (
              cart?.prePayandAdd ? (
                "Yes"
              ) : (
                "No"
              )
            ) : (
              <input
                type="checkbox"
                name="prepay"
                checked={cart?.prePayandAdd}
                css={tw`cursor-pointer`}
                onChange={(e) =>
                  dispatch(updatePrePayAndAdd(e.currentTarget.checked))
                }
              />
            )}
          </dd>
        </>
      )}
      {cart?.useDocType && (
        <>
          {confirmation ? (
            <dt css={tw`font-bold text-nucor-gray`}>Doc Type</dt>
          ) : (
            <dt css={tw`font-bold text-nucor-gray`}>
              Doc Type <span css={tw`text-red-600`}>*</span>
            </dt>
          )}
          <dd>
            {confirmation ? (
              docTypes?.find((docType) => docType.value === cart?.docType)
                ?.label
            ) : (
              <Select
                highlight
                name="docType"
                minWidth="19ch"
                data={docTypes}
                value={cart?.docType}
                onChange={(value: DocType) => dispatch(updateDocType(value))}
              />
            )}
          </dd>
        </>
      )}
      <dt css={tw`font-bold text-nucor-gray`}>Notes:</dt>
      <dd>
        {confirmation ? (
          cart?.notes ?? ""
        ) : (
          <textarea
            css={tw`w-full border border-[#bbb] h-12 resize-none`}
            value={notesValue}
            onChange={(e) => setNotesValue(e.target.value)}
            onBlur={() => dispatch(updateCartNotes(notesValue))}
          />
        )}
      </dd>
    </dl>
  );
};

export default OrderDetails;
