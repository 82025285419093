/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import tw from "twin.macro";
import AnimatedLoadingSpinner from "../../../../components/atoms/AnimatedLoadingSpinner";
import { CustomerSummaryItemResult } from "../../../../types/types";
import CustomerSummaryItem from "./CustomerSummaryItem";

type Props = {
  children?: ReactNode;
  results?: CustomerSummaryItemResult;
  isLoading: boolean;
  groupShipToFlag: boolean;
};

const AllCustomerSummaryResults: React.FC<Props> = ({ results, isLoading, groupShipToFlag }) => {
  return (
    <div css={tw`mt-2 text-xs`}>
      {isLoading && <AnimatedLoadingSpinner css={tw`ml-4`} />}
      {!results && !isLoading && (
        <p css={tw`text-nucor-green text-xs font-semibold`}>
          * Select a Sales Rep and click search to view all the summary.
        </p>
      )}
      {results?.customerDisplayList?.length &&
      results.customerDisplayList?.length > 0 &&
      !isLoading ? (
        <>
          <table className="ntp-portal-table ntp-shrink-y-padding" css={tw`w-full`}>
            <thead>
              <tr>
                <th css={tw`w-[80px]`}>Id</th>
                <th css={tw`w-[300px]`}>Customer Name</th>
                {groupShipToFlag && <th css={tw`w-[100px]`}>Ship To ID</th>}
                {groupShipToFlag && <th css={tw`w-[300px]`}>Ship To Name</th>}
                <th css={tw`w-[150px]`}>City & State</th>
                <th css={tw`w-[80px]`}>Tons<br/>YTD</th>
                <th css={tw`w-[80px]`}>Tons<br/>Last Year</th>
                <th css={tw`w-[110px]`}>Load Bill<br/>Totals</th>
                <th css={tw`w-[80px]`}>Open<br/>Orders</th>
                <th css={tw`w-[110px]`}>Wgt Bal</th>
                <th css={tw`w-[110px]`}>Ready to<br/>Ship</th>
                <th css={tw`w-[110px]`}>Past Due<br/>30+</th>
                <th css={tw`w-[90px]`}>Delivery<br/>Terms</th>
                <th css={tw`w-[80px]`}>Delivery<br/>Mode</th>
                <th css={tw`w-[90px]`}>Base<br/>Price</th>
                <th css={tw`w-[80px]`}>Price<br/>Sheet</th>
              </tr>
            </thead>
         
            <tbody>
              {results?.customerDisplayList.map((item, index) => {
                return (
                  <CustomerSummaryItem
                    groupShipToFlag={groupShipToFlag}
                    key={index}
                    index={index}
                    item={item}
                  />
                );
              })}
            </tbody>
          </table>
        </>
      ) : null}
      {!isLoading &&
        (results?.customerDisplayList?.length === 0 ||
          (results && !results.customerDisplayList)) && (
          <p css={tw`text-nucor-gray font-semibold mt-4 ml-2`}>
            No customers found with the current search parameters
          </p>
        )}
    </div>
  );
};

export default AllCustomerSummaryResults;