import { createAsyncThunk } from "@reduxjs/toolkit";
import { ErrorResponse } from "../types/types";
import { AxiosError } from "axios";
import { axiosWithAuth } from "../app/axios";

type D365SyncResponse = {
  message: string;
};

export const loadProducts = createAsyncThunk<
  D365SyncResponse,
  void,
  { rejectValue: string | ErrorResponse }
>("itAdmin/loadProducts", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.post(
      `${process.env.REACT_APP_API_URL}/it_admin/load_products`
    );
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue("Error loading products.");
      }
    } else {
      return rejectWithValue("Error loading products.");
    }
  }
});

export const loadValidationConfig = createAsyncThunk<
  D365SyncResponse,
  void,
  { rejectValue: string | ErrorResponse }
>("itAdmin/loadValidationConfig", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.post(
      `${process.env.REACT_APP_API_URL}/it_admin/load_validation_config`
    );
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 400:
          return rejectWithValue(error.response?.data);
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue("Error loading validation config.");
      }
    } else {
      return rejectWithValue("Error loading validation config.");
    }
  }
});

export const loadProductSpecs = createAsyncThunk<
  D365SyncResponse,
  void,
  { rejectValue: string | ErrorResponse }
>("itAdmin/loadProductSpecs", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.post(
      `${process.env.REACT_APP_API_URL}/it_admin/load_product_specs`
    );
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue("Error loading product specs.");
      }
    } else {
      return rejectWithValue("Error loading product specs.");
    }
  }
});

export const loadCustomers = createAsyncThunk<
  D365SyncResponse,
  void,
  { rejectValue: string | ErrorResponse }
>("itAdmin/loadCustomers", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.post(
      `${process.env.REACT_APP_API_URL}/it_admin/load_customers`
    );
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue("Error loading customers.");
      }
    } else {
      return rejectWithValue("Error loading customers.");
    }
  }
});

export const clearCaches = createAsyncThunk<
  D365SyncResponse,
  void,
  { rejectValue: string | ErrorResponse }
>("itAdmin/clearCaches", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.post(
      `${process.env.REACT_APP_API_URL}/it_admin/clear_caches`
    );
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue("Error clearing caches.");
      }
    } else {
      return rejectWithValue("Error clearing caches.");
    }
  }
});

export type TierConfigItem = {
  key: string;
  value: string;
};

type FetchAllTierConfigsResponse = TierConfigItem[];

export const fetchAllTierConfigs = createAsyncThunk<
  FetchAllTierConfigsResponse,
  void,
  { rejectValue: string | ErrorResponse }
>("itAdmin/fetchAllTierConfigs", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/it_admin/tier_config_all`
    );
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue("Error fetching all tier configs.");
      }
    } else {
      return rejectWithValue("Error fetching all tier configs.");
    }
  }
});

type UpdateTierConfigResponse = {
  data: TierConfigItem;
  message: string;
};

export const updateTierConfig = createAsyncThunk<
  UpdateTierConfigResponse,
  TierConfigItem,
  { rejectValue: string | ErrorResponse }
>("itAdmin/updateTierConfig", async (params, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.put(
      `${process.env.REACT_APP_API_URL}/it_admin/tier_config`,
      params
    );
    return {
      data: response.data,
      message: `${response.data.key} successfully updated.`,
    };
  } catch (error) {
    if (error instanceof Error && error.name === "SyntaxError") {
      return rejectWithValue("JSON syntax is invalid.");
    }
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 400:
          return rejectWithValue(error.response?.data);
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue("Error updating tier config.");
      }
    } else {
      return rejectWithValue("Error updating tier config.");
    }
  }
});
