/** @jsxImportSource @emotion/react */
import React, { ReactNode, useState } from "react";
import tw from "twin.macro";
import PriceSheetModal from "./PriceSheetModal";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectCurrentCustomerId } from "../../store/customer.reducer";
import { fetchProductPriceSheetPDF } from "../../store/product.actions";
import Spinner from "../atoms/Spinner";

type Props = {
  children?: ReactNode;
};

const RollingSchedule: React.FC<Props> = () => {
  const dispatch = useAppDispatch();

  const [showModal, setShowModal] = useState(false);
  const linkFonts = [tw`mx-[10px]`];

  const [priceSheetLoading, setPriceSheetLoading] = useState(false);
  const selectedCustomerId = useAppSelector(selectCurrentCustomerId);
  const currentCustomer = useAppSelector(
    (state) => state.customer.currentCustomer
  );

  const hideModalHandler = () => {
    setShowModal(false);
  };

  const getPriceSheet = async () => {
    if (!selectedCustomerId) return;
    setPriceSheetLoading(true);
    dispatch(fetchProductPriceSheetPDF({customerId: selectedCustomerId}))
      .unwrap()
      .then(() => setPriceSheetLoading(false))
      .catch(() => setPriceSheetLoading(false));
  };

  return (
    <div className="relative">

      {!currentCustomer?.shipToPricing && (
        <button
          disabled={priceSheetLoading === true}
          css={[linkFonts, tw`mt-1`]}
          onClick={getPriceSheet}
        >
          <span css={priceSheetLoading && tw`opacity-50`}>
            Price Sheets
          </span>
          {priceSheetLoading && <Spinner css={tw`h-[2px]`}/>}
        </button>)
      }

      {currentCustomer?.shipToPricing && (
        <>
          <button
            css={[linkFonts, tw`mt-1`]}
            onClick={() => setShowModal((prev) => !prev)}
          >
            Price Sheets
          </button>
          {showModal && <PriceSheetModal onCancel={hideModalHandler} />}
        </>)
      }
    </div>
  );
};

export default RollingSchedule;
