/** @jsxImportSource @emotion/react */
import React, { ReactNode, useState } from "react";
import Modal from "../../../../../../components/molecules/Modal";
import tw from "twin.macro";
import {
  ErrorResponse,
  OrderDetailPagedResult,
  RequestStatus,
  RollingCustomerView,
} from "../../../../../../types/types";
import BookingTable from "./BookingTable";
import BookingOrderDetails from "./BookingOrderDetails";
import {
  FetchOpenRollingsOrdersRequest,
  fetchOpenRollingsOrders,
} from "../../../../../../store/rollings.actions";
import { useAppDispatch } from "../../../../../../app/hooks";
import Toast from "../../../../../../components/molecules/Toast";
import { isErrorResponse } from "../../../../../../types/predicates";
import AnimatedLoadingSpinner from "../../../../../../components/atoms/AnimatedLoadingSpinner";

type Props = {
  children?: ReactNode;
  rollingData?: Partial<RollingCustomerView>;
  onCancel: () => void;
};

const BookingsModal: React.FC<Props> = ({ onCancel, rollingData }) => {
  const dispatch = useAppDispatch();
  const sites = rollingData?.bookingsRollings?.reduce((acc, rolling) => {
    if ((acc as string[]).includes(rolling.site)) return acc;
    return [...acc, rolling.site];
  }, [] as string[]);

  const [selectedSite, setSelectedSite] = React.useState("ALL");
  const [status, setStatus] = useState<RequestStatus>("idle");
  const [error, setError] = useState<string | ErrorResponse>();
  const [identifier, setIdentifier] = useState<string>();
  const [openRollingOrders, setOpenRollingOrders] =
    React.useState<OrderDetailPagedResult>();

  const fetchOpenRollingOrdersHandler = (
    params: FetchOpenRollingsOrdersRequest
  ) => {
    setStatus("pending");
    setOpenRollingOrders(undefined);
    setIdentifier(params?.identifier);
    delete params.identifier;
    dispatch(fetchOpenRollingsOrders(params))
      .unwrap()
      .then((data) => {
        setOpenRollingOrders(data);
      })
      .catch((error) => {
        if (isErrorResponse(error) || typeof error === "string") {
          setError(error);
        } else {
          setError("An unknown error occurred while fetching rolling orders.");
        }
      })
      .finally(() => {
        setStatus("idle");
      });
  };

  return (
    <Modal title="Product Bookings" onCancel={onCancel}>
      {error && (
        <Toast
          onConfirm={() => setError(undefined)}
          type="error"
          message={error}
        />
      )}
      <div css={tw`max-h-[80vh] max-w-[80vw] py-2 overflow-y-auto`}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          css={tw`flex gap-2 items-center`}
        >
          <span css={tw`text-xs font-bold text-nucor-gray`}>Product: </span>
          <span css={tw`font-bold`}>{rollingData?.productIndex?.name}</span>
          <fieldset css={tw`flex items-center ml-4`}>
            <span css={tw`font-bold text-nucor-gray`}>Division:</span>
            <label css={tw`flex items-center gap-1 hover:cursor-pointer`}>
              <input
                css={tw`ml-2 hover:cursor-pointer`}
                type="radio"
                name="site"
                value="ALL"
                onChange={() => {
                  setOpenRollingOrders(undefined);
                  setSelectedSite("ALL");
                }}
                checked={selectedSite === "ALL"}
              />
              All
            </label>
            {sites?.map((site) => (
              <label
                css={tw`flex items-center gap-1 hover:cursor-pointer`}
                key={site}
              >
                <input
                  css={tw`ml-2 hover:cursor-pointer`}
                  type="radio"
                  name="site"
                  value={site}
                  onChange={() => {
                    setOpenRollingOrders(undefined);
                    setSelectedSite(site);
                  }}
                  checked={selectedSite === site}
                />
                {site}
              </label>
            ))}
          </fieldset>
        </form>
        <BookingTable
          onChange={(params: FetchOpenRollingsOrdersRequest) => {
            console.log('params', params)
            fetchOpenRollingOrdersHandler(params);
          }}
          rollings={rollingData?.bookingsRollings}
          selectedSite={selectedSite}
          status={status}
        />
        {status === "pending" && <AnimatedLoadingSpinner css={tw`mt-4`} />}
        {openRollingOrders && (
          <BookingOrderDetails title={identifier} items={openRollingOrders} />
        )}
      </div>
    </Modal>
  );
};

export default BookingsModal;
