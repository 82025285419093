import * as yup from "yup";

export type PreferencesFormData = {
  startPage: string;
  defaultPriceUnit: string;
  defaultMill: string;
  hideBrowseHistory: boolean;
  defaultOrderDueDates: boolean;
  orderRollingItemsByBundle: boolean;
  sortOrdersByDescription: boolean;
  confirmShipToOnSubmit: boolean;
  showOddLengths: boolean;
};

export const schema = yup.object({
  startPage: yup.string(),
  defaultPriceUnit: yup.string(),
  defaultMill: yup.string(),
  hideBrowseHistory: yup.bool(),
  defaultOrderDueDates: yup.bool(),
  orderRollingItemsByBundle: yup.bool(),
  sortOrdersByDescription: yup.bool(),
  confirmShipToOnSubmit: yup.bool(),
  showOddLengths: yup.bool(),
});
