/** @jsxImportSource @emotion/react */
import React, { ReactNode, useRef, useState, useEffect } from "react";
import { createPortal } from "react-dom";
import tw from "twin.macro";
import { useAppSelector } from "../../../../../app/hooks";
import Button from "../../../../../components/atoms/Button";
import Select from "../../../../../components/atoms/Select";
import { selectShipToDropdownData } from "../../../../../store/customer.reducer";

type Props = {
  children?: ReactNode;
  onCancel: () => void;
  onConfirm: (customerId: string) => void;
};

const ChangeShipToDialog: React.FC<Props> = ({
  onConfirm,
  onCancel,
  ...remainingProps
}) => {
  const [selectedNewShipTo, setSelectedNewShipTo] = useState("");
  const shipTos = useAppSelector(selectShipToDropdownData);
  const selectableData = [
    { value: "", label: "Select new ship to" },
    ...shipTos,
  ];
  const closeRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    closeRef.current?.focus();
  }, []);

  return createPortal(
    <div
      onClick={onCancel}
      css={[
        tw`fixed top-0 left-0 w-full h-full flex justify-center items-center bg-[#cccccc77] z-[100]`,
      ]}
      {...remainingProps}
    >
      <div
        css={[tw`bg-nucor-green p-[7px] min-w-[50ch] w-[65ch] text-xs`]}
        onClick={(e) => e.stopPropagation()}
      >
        <div css={tw`flex justify-between`}>
          <h2 css={tw`font-bold text-white pb-1 text-xs flex-1`}>
            Change Ship To
          </h2>
          <button
            ref={closeRef}
            type="button"
            onClick={onCancel}
            css={tw`text-nucor-green bg-[#ccc] h-[13px] w-[13px] flex justify-center items-center font-bold mt-px`}
          >
            &times;
          </button>
        </div>
        <div css={tw`bg-white p-1`}>
          <p css={tw`text-nucor-green`}>
            The listed product prices are for the selected &ldquo;Ship To&rdquo;
            location. A change to this location can affect the price on items in
            your shopping cart.
          </p>
          <div css={tw`flex gap-4 items-center mt-2`}>
            <label css={tw`text-nucor-gray font-bold flex-shrink-0`}>
              Ship To
            </label>
            <Select
              data={selectableData}
              minWidth="70%"
              name="shipTo"
              value={selectedNewShipTo}
              onChange={setSelectedNewShipTo}
            />
          </div>
          <div css={tw`text-center mt-2 mb-1`}>
            <Button
              css={tw`m-0 text-xs font-normal py-[3px] mr-4`}
              type="button"
              disabled={selectedNewShipTo === ""}
              onClick={() => onConfirm(selectedNewShipTo)}
            >
              Submit
            </Button>
            <Button
              css={tw`m-0 text-xs font-normal py-[3px]`}
              type="button"
              onClick={onCancel}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>,
    document.getElementById("modal") as HTMLElement
  );
};

export default ChangeShipToDialog;
