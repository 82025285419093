import React, { Fragment, ReactNode, useState } from "react";
import fuzzy from "fuzzy";
import { Combobox } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

type Props = {
  data?: {
    value: string;
    label: ReactNode;
    name?: string;
    searchName?: string;
  }[];
  hasError?: boolean;
  fuzzySearch?: boolean;
  stringIncludes?: boolean;
  onChange: (value: string) => void;
  name?: string;
  highlight?: boolean;
  defaultValue?: string;
  rightAlignOptions?: boolean;
  value: string;
  width?: string;
};

const ComboBox: React.FC<Props> = (props) => {
  const [query, setQuery] = useState("");

  const filteredItems =
    props.data?.filter((item) => {
      if (props.fuzzySearch) {
        if (React.isValidElement(item.label)) {
          return fuzzy.test(query, item.label.props.children.join(' '));
        }
      } else if (props.stringIncludes) {
        return item.value.toLowerCase().includes(query?.toLowerCase()) || item.name?.toLowerCase().includes(query?.toLowerCase()) || item.searchName?.toLowerCase().startsWith(query?.toLowerCase());
      } else {
        return item.value.toLowerCase().startsWith(query?.toLowerCase());
      }
    }) ?? [];

  const containerClasses = `inline-flex outline-none border border-gray-300 focus-within:border-blue-400 focus-within:shadow text-xs ${props.hasError && "border-red-600"}`;
  const inputClasses = `ntp-remove-borders ${props.highlight && "bg-nucor-yellow"}`;
  
  const inputWidth = props.width ? 'calc(' + props.width + ' - 21px)' : 'calc(22ch - 21px)';

  return (
    <Combobox
      as="div"
      nullable
      className="relative ntp-standard-combo"
      value={props.value}
      onChange={(value: string) => {
        props.onChange(value);
      }}
    >
      <div className={containerClasses}>
        <Combobox.Input
          name={props.name}
          autoComplete="off"
          className={inputClasses}
          onChange={(e) => {
            setQuery(e.target.value);
          }}
          displayValue={(item: string) => item}
          style={{
            width: inputWidth
          }}
        />
        <Combobox.Button className="border-l">
          <FontAwesomeIcon
            style={{ aspectRatio: "1/1" }}
            className="px-[3px] text-[#1E40AF] mt-1"
            icon={faAngleDown}
            onClick={() => setQuery("")}
          />
        </Combobox.Button>
      </div>

      {filteredItems && filteredItems.length > 0 && (
      <Combobox.Options
        className={`bg-white top-[21px] z-50 overflow-y-scroll max-h-[300px] overflow-x-visible absolute min-w-full outline-none border border-blue-400 ${
          props.rightAlignOptions ? "right-0" : "left-0"
        }`}
      >
        {filteredItems.map((item) => {
          return (
            <Combobox.Option as={Fragment} key={item.value} value={item.value}>
              {({ active }) => {
                return (
                  <li
                    style={{
                      backgroundColor: active ? "#DFE8F6" : undefined,
                    }}
                    className="cursor-pointer whitespace-nowrap px-2 hover:bg-[#dfe8f6]"
                  >
                    {item.label}
                  </li>
                );
              }}
            </Combobox.Option>
          );
        })}
      </Combobox.Options>
      )}
    </Combobox>
  );
};

export default ComboBox;
