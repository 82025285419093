import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ErrorResponse, RequestStatus } from "../types/types";
import {
  TierConfigItem,
  clearCaches,
  fetchAllTierConfigs,
  loadCustomers,
  loadProductSpecs,
  loadProducts,
  loadValidationConfig,
  updateTierConfig,
} from "./itadmin.actions";

type InitialState = {
  sync: {
    error?: string | ErrorResponse;
    message?: string;
    loadProductsStatus: RequestStatus;
    loadProductSpecsStatus: RequestStatus;
    loadCustomersStatus: RequestStatus;
    loadValidationConfigStatus: RequestStatus;
    clearCachesStatus: RequestStatus;
  };
  tierConfig: {
    error?: string | ErrorResponse;
    message?: string;
    status: RequestStatus | "loading" | "updating";
    configs: TierConfigItem[];
  };
};

const initialState: InitialState = {
  sync: {
    loadProductsStatus: "idle",
    loadProductSpecsStatus: "idle",
    loadCustomersStatus: "idle",
    loadValidationConfigStatus: "idle",
    clearCachesStatus: "idle",
  },
  tierConfig: {
    status: "idle",
    configs: [],
  },
};

const itAdminSlice = createSlice({
  name: "itAdmin",
  initialState,
  reducers: {
    clearError: (state) => {
      state.sync.error = undefined;
      state.tierConfig.error = undefined;
    },
    clearMessage: (state) => {
      state.sync.message = undefined;
      state.tierConfig.message = undefined;
    },
    load: (state, action: PayloadAction<string>) => {
      switch (action.payload) {
        case "products":
          state.sync.loadProductsStatus = "pending";
          break;
        case "productSpecs":
          state.sync.loadProductSpecsStatus = "pending";
          break;
        default:
          break;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadProducts.pending, (state) => {
      state.sync.loadProductsStatus = "pending";
    });
    builder.addCase(loadProducts.fulfilled, (state, action) => {
      state.sync.loadProductsStatus = "idle";
      state.sync.message = action.payload.message;
    });
    builder.addCase(loadProducts.rejected, (state, action) => {
      state.sync.loadProductsStatus = "idle";
      state.sync.error = action.payload;
    });
    builder.addCase(loadCustomers.pending, (state) => {
      state.sync.loadCustomersStatus = "pending";
    });
    builder.addCase(loadCustomers.fulfilled, (state, action) => {
      state.sync.message = action.payload.message;
      state.sync.loadCustomersStatus = "idle";
    });
    builder.addCase(loadCustomers.rejected, (state, action) => {
      state.sync.loadCustomersStatus = "idle";
      state.sync.error = action.payload;
    });
    builder.addCase(loadProductSpecs.pending, (state) => {
      state.sync.loadProductSpecsStatus = "pending";
    });
    builder.addCase(loadProductSpecs.fulfilled, (state, action) => {
      state.sync.message = action.payload.message;
      state.sync.loadProductSpecsStatus = "idle";
    });
    builder.addCase(loadProductSpecs.rejected, (state, action) => {
      state.sync.loadProductSpecsStatus = "idle";
      state.sync.error = action.payload;
    });
    builder.addCase(loadValidationConfig.pending, (state) => {
      state.sync.loadValidationConfigStatus = "pending";
    });
    builder.addCase(loadValidationConfig.fulfilled, (state, action) => {
      state.sync.message = action.payload.message;
      state.sync.loadValidationConfigStatus = "idle";
    });
    builder.addCase(loadValidationConfig.rejected, (state, action) => {
      state.sync.loadValidationConfigStatus = "idle";
      state.sync.error = action.payload;
    });
    builder.addCase(clearCaches.pending, (state) => {
      state.sync.clearCachesStatus = "pending";
    });
    builder.addCase(clearCaches.fulfilled, (state, action) => {
      state.sync.message = action.payload.message;
      state.sync.clearCachesStatus = "idle";
    });
    builder.addCase(clearCaches.rejected, (state, action) => {
      state.sync.clearCachesStatus = "idle";
      state.sync.error = action.payload;
    });
    builder.addCase(fetchAllTierConfigs.pending, (state) => {
      state.tierConfig.status = "loading";
    });
    builder.addCase(fetchAllTierConfigs.fulfilled, (state, action) => {
      state.tierConfig.status = "idle";
      state.tierConfig.configs = action.payload;
    });
    builder.addCase(fetchAllTierConfigs.rejected, (state, action) => {
      state.tierConfig.status = "idle";
      state.tierConfig.error = action.payload;
    });
    builder.addCase(updateTierConfig.pending, (state) => {
      state.tierConfig.status = "updating";
    });
    builder.addCase(updateTierConfig.fulfilled, (state, action) => {
      state.tierConfig.status = "idle";
      state.tierConfig.message = action.payload.message;
      state.tierConfig.configs = state.tierConfig.configs.map((config) => {
        if (config.key === action.payload.data.key) {
          return action.payload.data;
        } else {
          return config;
        }
      });
    });
    builder.addCase(updateTierConfig.rejected, (state, action) => {
      state.tierConfig.status = "idle";
      state.tierConfig.error = action.payload;
    });
  },
});

export default itAdminSlice.reducer;
export const { clearError, load, clearMessage } = itAdminSlice.actions;
