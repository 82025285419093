/** @jsxImportSource @emotion/react */
import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import tw from "twin.macro";
import { ErrorResponse } from "../../types/types";

type Props = {
  onConfirm: () => void;
  message?: string | ErrorResponse;
  type: "warning" | "error" | "success";
  duration?: number;
};

const Toast: React.FC<Props> = ({
  onConfirm,
  message,
  type,
  duration = 5000,
}) => {
  useEffect(() => {
    if (duration === 0) return;
    const timer = setTimeout(() => {
      onConfirm();
    }, duration);
    return () => clearTimeout(timer);
  }, []);

  return createPortal(
    <button
      onClick={(e) => {
        e.stopPropagation();
        onConfirm();
      }}
      css={[
        tw`fixed right-12 bottom-12 max-w-[100ch] text-white text-xs rounded flex items-center animate-enter text-left z-[1000] py-1`,
        type === "error" && tw`bg-red-600`,
        type === "success" && tw`bg-nucor-light-green`,
        type === "warning" && tw`bg-yellow-600`,
      ]}
    >
      {typeof message === "string" ? (
        <span css={tw`flex-1 m-4`}>{message}</span>
      ) : (
        <div>
          <p css={tw`ml-4 font-semibold`}>{message?.message}</p>
          <ul css={tw`ml-4`}>
            {(message as ErrorResponse)?.validations?.map((item, index) => (
              <li className="break-all" key={index}>{item.message}</li>
            ))}
            {message?.additionalInfo &&
              message.additionalInfo.map((item, index) => (
                <li
                  className="mt-2 break-all"
                  key={index + (message?.validations?.length ?? 0)}
                >
                  {item}
                </li>
              ))}
          </ul>
        </div>
      )}
      <span css={tw`m-4 font-bold`} onClick={onConfirm}>
        &times;
      </span>
    </button>,
    document.getElementById("toast") as HTMLElement
  );
};

export default Toast;
