/** @jsxImportSource @emotion/react */
import React, { ReactNode, useEffect, useState } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { fetchInsideShortPays } from "../../../../store/customer.actions";
import { ErrorResponse, RequestStatus, TotalByRep } from "../../../../types/types";
import Loading from "../../../../components/atoms/Loading";
import Toast from "../../../../components/molecules/Toast";
import { currencyFormatter } from "../../../../helpers/currencyFormatter";
import { useNavigate, createSearchParams } from "react-router-dom";
import { selectCurrentCustomerId } from "../../../../store/customer.reducer";
import TextButton from "../../../../components/atoms/TextButton";
import { clearInvoiceSearchCriteria } from "../../../../store/document.reducer";

type Props = {
  children?: ReactNode;
};

const Dashboard: React.FC<Props> = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  
  const [isrShortPays, setIsrShortPays] = useState<TotalByRep[]>([]);
  const [error, setError] = useState<string | ErrorResponse>();
  const [status, setStatus] = useState<RequestStatus>("idle");

  const customerId = useAppSelector(selectCurrentCustomerId); 
  const customerParam = customerId ? `&customer_id=${customerId}`: "";

  useEffect(() => {
    setStatus("pending");
    dispatch(fetchInsideShortPays())
    .unwrap()
    .then((repTotals) => setIsrShortPays(repTotals))
    .catch((error) => setError(error))
    .finally(() => setStatus("idle"));
  }, []);

  return (
    <>
      {error && (
        <Toast
          type="error"
          message={error}
          onConfirm={() => setError(undefined)}
        />
      )}
      <div css={tw`text-xs relative min-h-[100px] ml-4`}>
        {status === "pending" && <Loading />}

        <p className="font-bold text-sm">Partially Paid Invoices to Review</p>

          <table className="ntp-portal-table mt-1" css={tw`text-xs w-[25%]`}>
            <thead>
              <tr>
                <th>Count</th>
                <th>Total</th>
                <th>ISR</th>
              </tr>
            </thead>
            <tbody>
              {isrShortPays && isrShortPays.length > 0 ? (
                isrShortPays?.map((item, index) => (
                  <tr key={index}>
                    <td tw="text-right">{item.count}</td>
                    <td tw="text-right">{currencyFormatter(item.total)}</td>
                    <td>
                      <TextButton
                      onClick={() => {
                        dispatch(clearInvoiceSearchCriteria());
                        navigate({
                          pathname: "/portal/mydocuments/invoicelist",
                          search: `?${createSearchParams({
                            status: "shortpay",
                            dateRange: "all",
                            site: "ALL",
                            salesRep: item.rep,
                          })}` + customerParam
                        });
                      }}>
                      {item.rep}
                      </TextButton>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td css={tw`text-nucor-gray !px-2 !py-3 hover:!bg-white`} colSpan={3}>
                    No Invoices to Review
                  </td>
                </tr>
              )}
            </tbody>
            </table>
      </div>
    </>
  );
};

export default Dashboard;
