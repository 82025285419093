import { Authority } from "../types/types";

export const hasAuthority = (
  authority: Authority | Authority[],
  currentAuthorities: Authority[]
): boolean => {
  if (!currentAuthorities) {
    return false;
  }
  if (typeof authority === "string") {
    return currentAuthorities.includes(authority);
  } else {
    return authority.some((auth) => currentAuthorities.includes(auth));
  }
};
