/** @jsxImportSource @emotion/react */
import React, { ReactNode, useEffect, useState } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { clearCurrentUser, resetUsersPage } from "../../../store/admin.reducer";
import AddUser from "./add_user/AddUser";
import UserDetail from "./edit_user/UserDetail";
import UsersResults from "./user_search/UsersResults";
import UsersSearchBar from "./user_search/user_search_bar/UsersSearchBar";
import { getUserInfoFormSetup } from "../../../store/admin.actions";
import TextButton from "../../../components/atoms/TextButton";

type Props = {
  children?: ReactNode;
};

const Users: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const selectedUser = useAppSelector((state) => state.admin.selectedUser);
  const { userFormSetup } = useAppSelector((state) => state.admin);
  const [addUser, setAddUser] = useState(false);

  useEffect(() => {
    if (!userFormSetup) {
      // Form setup data for user detail form
      dispatch(getUserInfoFormSetup());
    }
    return () => {
      dispatch(resetUsersPage());
    };
  }, []);

  return (
    <>
      <div css={tw`text-white font-bold bg-nucor-green text-xs flex justify-between py-1 px-2`}>

        <span className="uppercase w-24">
          {!selectedUser && !addUser ? "User List" : "User Detail"} 
        </span>

        {(addUser || selectedUser) && (
          <TextButton onClick={() => {
            dispatch(clearCurrentUser());
            setAddUser(false);
            }} css={tw`text-white`}>
            View User List
          </TextButton>
        )}

        <div css={tw`flex w-24 justify-end`}>
          {!addUser && (
            <TextButton onClick={() => setAddUser(true)} css={tw`text-white`}>
              Add User
            </TextButton>
          )}
        </div>
      </div>
      {!addUser && !selectedUser && (
        <>
          <UsersSearchBar />
          <UsersResults />
        </>
      )}
      {addUser && <AddUser />}
      {!addUser && selectedUser && <UserDetail />}
    </>
  );
};

export default Users;
