/** @jsxImportSource @emotion/react */
import React, { ReactNode, useContext, useState } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import TextButton from "../../../../components/atoms/TextButton";
import { numberFormatter } from "../../../../helpers/numberFormat";
import {
  fetchOrderDocumentDetailPDF,
  FetchOrderDocumentDetailPDFRequest,
  searchOrderDocumentOrder,
} from "../../../../store/document.actions";
import { ordersSort, updateOrdersPagination } from "../../../../store/document.reducer";
import TableSortButton from "../../../../components/molecules/TableSortButton";
import Toast from "../../../../components/molecules/Toast";
import { formatDateToShort } from "../../../../helpers/dateHelpers";
import { PDFContext } from "./OrdersList";
import { selectCurrentCustomerId } from "../../../../store/customer.reducer";
import Pagination, { PaginationValues } from "../../../../components/molecules/PaginationComponent";
import { getNewSort } from "../../../../helpers/getNewSort";
import Loading from "../../../../components/atoms/Loading";

type Props = {
  children?: ReactNode;
};

const OrdersResults: React.FC<Props> = () => {
  const dispatch = useAppDispatch();

  const { searchCriteria, status, ordersResults } = useAppSelector((state) => state.document.orders);

  const customerId = useAppSelector(selectCurrentCustomerId);

  const [errorMessage, setErrorMessage] = useState("");
  const ctx = useContext(PDFContext);

  const viewOrderHandler = (params: FetchOrderDocumentDetailPDFRequest) => {
    setErrorMessage("");
    dispatch(fetchOrderDocumentDetailPDF(params))
      .unwrap()
      .then((result) => {
        ctx?.setFile(result);
      })
      .catch((error) => {
        setErrorMessage(error);
      });
  };

  const sortChanged = (field: string) => {
    if (!customerId || !ordersResults?.lastCriteria) return;
    
    const searchSort = getNewSort(field, searchCriteria);

    const c = {...ordersResults.lastCriteria, ...searchSort, page: searchCriteria.page};

    dispatch(ordersSort(searchSort))
    dispatch(searchOrderDocumentOrder({customerId, criteria: c}));
  };

  const pageChanged = (page: PaginationValues) => {
    if (!customerId || !ordersResults?.lastCriteria) return;
    
    const c = {...ordersResults.lastCriteria, ...page};

    dispatch(updateOrdersPagination(page))
    dispatch(searchOrderDocumentOrder({customerId, criteria: c}));
  };

  return (
    <>
      {errorMessage && (
        <Toast
          type="error"
          message={errorMessage}
          onConfirm={() => setErrorMessage("")}
        />
      )}
      <Pagination
        currentPage={searchCriteria.page}
        items={ordersResults?.count ?? 0}
        isLoading={status === "pending"}
        onChange={(value) => pageChanged(value)}
      />
      <div css={tw`relative`}>
        {status === "pending" && <Loading />}
        <table className="ntp-portal-table" css={[tw`w-full text-xs`]}>
          <thead>
            <tr>
              <th css={tw`w-[11%]`}>
                <TableSortButton
                  field="billingCustomerId"
                  sort={searchCriteria}
                  onClick={(field) => sortChanged(field)}
                >
                  Customer Id
                </TableSortButton>
              </th>
              <th css={tw`w-[11%]`}>
                <TableSortButton
                  field="customerPONumber"
                  sort={searchCriteria}
                  onClick={(field) => sortChanged(field)}
                >
                  PO Number
                </TableSortButton>
              </th>
              <th>
                <TableSortButton
                  field="salesOrderNumber"
                  sort={searchCriteria}
                  onClick={(field) => sortChanged(field)}
                >
                  Sales Order
                </TableSortButton>
              </th>
              <th css={tw`w-[9%]`}>
                <TableSortButton
                  field="site"
                  sort={searchCriteria}
                  onClick={(field) => sortChanged(field)}
                >
                  Mill
                </TableSortButton>
              </th>
              <th css={tw`w-[11%]`}>
                <TableSortButton
                  field="orderDate"
                  sort={searchCriteria}
                  onClick={(field) => sortChanged(field)}
                >
                  Order Date
                </TableSortButton>
              </th>
              <th css={tw`w-[6%]`}>
                <TableSortButton
                  field="totalNumItems"
                  sort={searchCriteria}
                  onClick={(field) => sortChanged(field)}
                >
                  Items Ord
                </TableSortButton>
              </th>
              <th>
                <TableSortButton
                  field="totalWeightOrdered"
                  sort={searchCriteria}
                  onClick={(field) => sortChanged(field)}
                >
                  Wgt Ord
                </TableSortButton>
              </th>
              <th>
                <TableSortButton
                  field="totalWeightBalance"
                  sort={searchCriteria}
                  onClick={(field) => sortChanged(field)}
                >
                  Wgt Bal
                </TableSortButton>
              </th>
              <th>
                <TableSortButton
                  field="status"
                  sort={searchCriteria}
                  onClick={(field) => sortChanged(field)}
                >
                  Status
                </TableSortButton>
              </th>
              <th css={tw`w-[5%]`}>
                <TableSortButton
                  field="portalOrder"
                  sort={searchCriteria}
                  onClick={(field) => sortChanged(field)}
                >
                  Portal Ord
                </TableSortButton>
              </th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>

            {ordersResults?.list && ordersResults?.list.length > 0 ? (
              ordersResults?.list.map((order, index) => (
                <tr key={index}>
                  <td>{order.billingCustomerId}</td>
                  <td>{order.customerPONumber}</td>
                  <td>{order.salesOrderNumber}</td>
                  <td>{order.site}</td>
                  <td>
                    {formatDateToShort(order.orderDate)}
                  </td>
                  <td css={tw`text-right w-[7%]`}>
                    {numberFormatter(order.totalNumItems)}
                  </td>
                  <td css={tw`text-right`}>
                    {numberFormatter(order.totalWeightOrdered)}
                  </td>
                  <td css={tw`text-right`}>
                    {numberFormatter(order.totalWeightBalance)}
                  </td>
                  <td>{order.status}</td>
                  <td css={tw`text-center`}>{order.portalOrder ? "Y" : "N"}</td>
                  <td css={tw`text-center`}>
                    {order.hideDocumentLink ? null : (
                      <TextButton
                        disabled={status === "pending"}
                        onClick={() =>
                          viewOrderHandler({
                            customerId: order.billingCustomerId,
                            id: order.salesOrderNumber,
                          })
                        }
                      >
                        View
                      </TextButton>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td css={tw`text-nucor-gray !px-2 !py-3 hover:!bg-white`} colSpan={11}>
                  No Orders found for the selected criteria
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default OrdersResults;
