export const fractions = [
  { value: 0, label: "Select" },
  { value: 0.0625, label: "1/16" },
  { value: 0.125, label: "1/8" },
  { value: 0.1875, label: "3/16" },
  { value: 0.25, label: "1/4" },
  { value: 0.3125, label: "5/16" },
  { value: 0.375, label: "3/8" },
  { value: 0.4375, label: "7/16" },
  { value: 0.5, label: "1/2" },
  { value: 0.5625, label: "9/16" },
  { value: 0.625, label: "5/8" },
  { value: 0.6875, label: "11/16" },
  { value: 0.75, label: "3/4" },
  { value: 0.8125, label: "13/16" },
  { value: 0.875, label: "7/8" },
  { value: 0.9375, label: "15/16" },
];
