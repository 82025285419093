/** @jsxImportSource @emotion/react */
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactNode } from "react";
import { createPortal } from "react-dom";
import tw from "twin.macro";
import Button from "../../../../../../components/atoms/Button";

type Props = {
  children?: ReactNode;
  onConfirm?: () => void;
  onCancel?: () => void;
  title: string;
};

const ConfirmShipTo: React.FC<Props> = ({
  onConfirm,
  onCancel,
  title,
  children,
  ...remainingProps
}) => {
  return createPortal(
    <div
      onClick={onCancel}
      css={[
        tw`fixed top-0 left-0 w-full h-full flex justify-center items-center bg-[#CCCCCC77] z-50`,
      ]}
      {...remainingProps}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        css={tw`max-w-[60ch] border border-[3px] rounded-lg border-nucor-light-green bg-white p-2 text-xs`}
      >
        <h3 css={tw`font-bold text-nucor-light-green mb-2`}>{title}</h3>
        <div css={tw`flex gap-4 mb-4 mx-4 items-center`}>
          <FontAwesomeIcon
            css={tw`text-nucor-light-green`}
            size={"2x"}
            icon={faQuestionCircle}
          />
          <div>{children}</div>
        </div>
        <div css={tw`flex justify-center gap-2`}>
          <Button onClick={onConfirm}>Yes</Button>
          <Button onClick={onCancel}>No</Button>
        </div>
      </div>
    </div>,
    document.getElementById("modal") as HTMLElement
  );
};

export default ConfirmShipTo;
