export const downloadExcel = (DOM: Document, fileUrl: string) => {
  const link = DOM.createElement("a");
  const date = new Date();
  link.href = fileUrl;
  link.setAttribute(
    "download",
    `users__${date.getFullYear()}${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}${date.getDate().toString().padStart(2, "0")}.xlsx`
  );
  DOM.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
};

export const downloadFile = (file: Blob, filename: string) => {
  const fileURL = window.URL.createObjectURL(file);
  const tempLink = document.createElement("a");
  tempLink.href = fileURL;
  tempLink.setAttribute("download", filename);
  tempLink.click();
  tempLink.parentNode?.removeChild(tempLink);
};
