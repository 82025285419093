/** @jsxImportSource @emotion/react */
import React, { ReactNode, useEffect, useState } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  ClaimsPagedResults,
} from "../../../../types/types";
import Button from "../../../../components/atoms/Button";
import Select from "../../../../components/atoms/Select";
import { selectMillsForSelectComponent } from "../../../../store/app.reducer";
import { selectDefaultMill } from "../../../../store/user.reducer";
import Toast from "../../../../components/molecules/Toast";
import { clearDocumentErrors, selectClaimsSearchSelectionData } from "../../../../store/document.reducer";
import AnimatedLoadingSpinner from "../../../../components/atoms/AnimatedLoadingSpinner";
import { selectCurrentCustomerId } from "../../../../store/customer.reducer";
import { updateQuoteSearchCriteria } from "../../../../store/document.reducer";
import Input from "../../../../components/atoms/Input";
import DatePicker from "../../../../components/atoms/DatePicker";
import { dateToString } from "../../../../helpers/dateHelpers";
import { fetchClaimsSearchSetup, fetchClaims, fetchClaimsExcel } from "../../../../store/document.actions";
import ClaimListResults from "./ClaimListResults";
import { Link } from "react-router-dom";

type Props = {
  children?: ReactNode;
};
type QuoteBrowseCriteria = {
  myCustomers?: boolean;
  grade?: string;
  dateRangeType?: string;
  quoteStatus?: string;
};

const ClaimList: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const defaultMill = useAppSelector(selectDefaultMill);
  const [selectedMill, setSelectedMill] = useState(defaultMill);
  const millSelection = useAppSelector(selectMillsForSelectComponent);
  const [excelFlag, setExcelFlag] = useState(false);
  const customerId = useAppSelector(selectCurrentCustomerId);

  const [claimsResults, setClaimsResults] = useState<ClaimsPagedResults>({count: 0, list:[]});

  const [loading, setLoading] = useState("idle");
  const selectionData = useAppSelector(selectClaimsSearchSelectionData);
  const { searchCriteria, error } = useAppSelector(
    (state) => state.document.claims
  );
  const [customerSearchType, setCustomerSearchType] = useState(
    searchCriteria.myCustomers ?? false
  );
  const onSelectionsChanged = (criteria: QuoteBrowseCriteria) => {
    if (!customerId) return;
    dispatch(updateQuoteSearchCriteria({ ...criteria, page: 1 }));
  };
  const [selectedCaseId, setSelectedCaseId] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedOrderNumber, setSelectedOrderNumber] = useState("");
  const [selectedInvoiceNumber, setSelectedInvoiceNumber] = useState("");
  const [selectedPONumber, setSelectedPONumber] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [fromDate, setFromDate] = useState<Date | undefined>();
  const [toDate, setToDate] = useState<Date | undefined>();
  const [closedFromDate, setClosedFromDate] = useState<Date | undefined>();
  const [closedToDate, setClosedToDate] = useState<Date | undefined>();
  const [selectedPriority, setSelectedPriority] = useState("");
  const [selectedResolution, setSelectedResolution] = useState("");
  const [selectedRMANumber, setSelectedRMANumber] = useState("");
  const [isDetailShowing, setIsDetailShowing] = useState(false);

  const mills = useAppSelector((state) => state.app.siteSelections);

  interface Criteria {
    page?: number;
    pageSize?: number;
    start?: number;
    limit?: number;
    caseCategory?: string;
    priority?: string;
    sites?: string[];
    caseId?: string;
    status?: string;
    salesOrderNumber?: string;
    invoiceNumber?: string;
    customerPONumber?: string;
    fromDate?: string;
    toDate?: string;
    caseResolution?: string;
    rMANumber?: string;
    fromCloseDate?: string;
    toCloseDate?: string;
  }

  const criteria: Criteria = {
    ...(selectedCategory && selectedCategory !== 'all' && { caseCategory: selectedCategory }),
    ...(selectedPriority && selectedPriority !== 'all' && { priority: selectedPriority }),
    ...(selectedMill && selectedMill !== "ALL" && { sites: mills.find((site) => site.key === selectedMill)?.sites }),
    ...(selectedCaseId && { caseId: selectedCaseId }),
    ...(selectedStatus && selectedStatus !== 'all' && { status: selectedStatus }),
    ...(selectedOrderNumber && { salesOrderNumber: selectedOrderNumber }),
    ...(selectedInvoiceNumber && { invoiceNumber: selectedInvoiceNumber }),
    ...(selectedPONumber && { customerPONumber: selectedPONumber }),
    ...(fromDate && { fromDate: dateToString(fromDate) }),
    ...(toDate && { toDate: dateToString(toDate) }),
    ...(closedFromDate && { closedFromDate: dateToString(closedFromDate) }),
    ...(closedToDate && { closedToDate: dateToString(closedToDate) }),
    ...(selectedResolution && selectedResolution !== 'all' && { caseResolution: selectedResolution }),
    ...(selectedRMANumber && { rMANumber: selectedRMANumber }),
    page: searchCriteria.page,
  };
  useEffect(() => {
    if (customerId)
      dispatch(fetchClaimsSearchSetup(customerId));
      searchClaimsHandler();
  }, [customerId]);

  useEffect(() => {
    searchClaimsHandler();
  }, [customerSearchType]);

  useEffect(() => {
    if (!customerId || !selectionData) return;
    setLoading("grid");
    const custId = customerSearchType ? '' : customerId;
    dispatch(
      fetchClaims({ customerId: custId ?? '', criteria: criteria })
    )
      .unwrap()
      .then((result) => {
        setClaimsResults(result);
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading("idle"));

  }, [searchCriteria.page]);

  const clickHandler = () => {
    if (excelFlag) {
      excelHandler();
    } else {
      searchClaimsHandler();
    }
  };

  const handleDetailShowingChange = (isShowing: boolean) => {
    setIsDetailShowing(isShowing);
  };

  const searchClaimsHandler = () => {
    setLoading("grid");
    const custId = customerSearchType ? '' : customerId;
    criteria.page = 1;
    dispatch(
      fetchClaims({ customerId: custId ?? '', criteria: criteria })
    )
      .unwrap()
      .then((result) => {
        setClaimsResults(result);
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading("idle"));
  };

  const excelHandler = () => {
    setLoading("excel");
    const custId = customerSearchType ? '' : customerId;
    dispatch(
      fetchClaimsExcel({ customerId: custId ?? '', criteria: criteria })
    )
      .unwrap()
      .then(() => setLoading("idle"))
      .catch((error: Error) => {
        console.log(error);
      })
      .finally(() => setLoading("idle"));
  };

  if (!customerId)
    return (
      <div css={[tw`flex p-4 w-full`]}>
        <p css={tw`text-nucor-green text-xs font-bold`}>Select a customer</p>
      </div>
    );

  return (
    <div css={tw`p-2 w-full`}>
      {error && (
        <Toast
          message={error}
          type="error"
          onConfirm={() => dispatch(clearDocumentErrors())}
        />
      )}
      {!isDetailShowing && (
        <>
          <div
            css={tw`flex justify-between items-center bg-nucor-green w-full text-xs py-1 px-2`}
          >
            <div css={[tw`text-white uppercase font-bold`]}>Search</div>
            <div css={[tw`text-white font-bold underline`]}>
              <Link css={tw`focus:text-nucor-link-hover focus:outline-none`} to="/portal/mydocuments/createclaim">Add Claim</Link>
            </div>
          </div>
          <div
            css={tw`p-2 bg-gradient-to-b from-[#e6e6e6] via-[#fdfdfd] to-[#e6e6e6] gap-2 text-xs items-center mb-2`}
          >
            <div css={tw`flex flex-wrap from-[#e6e6e6] via-[#fdfdfd] to-[#e6e6e6] gap-2 text-xs items-center`}>
            <span>Show:
              <Select
                minWidth={"19ch"}
                name="showBy"
                data={selectionData?.customerSearchType.map((item) => ({
                  value: item.id,
                  label: item.description,
                }))}
                onChange={(value: boolean) => {
                  setCustomerSearchType(value);
                  onSelectionsChanged({
                    ...criteria,
                    myCustomers: value,
                  });
                }}
                value={customerSearchType}
              /></span>
            <span>Id:
              <Input
                name="caseid"
                value={selectedCaseId}
                onChange={(e) => setSelectedCaseId(e.target.value)}
                css={tw`w-[13ch]`}
              /></span>
            <span>Mill:
              <Select
                name="mill"
                value={selectedMill}
                onChange={(mill: string) => setSelectedMill(mill)}
                minWidth={"20ch"}
                data={millSelection}
              /></span>
            <span>Status:
              <Select
                name="status"
                value={selectedStatus || "all"}
                onChange={(status: string) => setSelectedStatus(status)}
                minWidth={"13ch"}
                data={[
                  { value: "all", label: "All" },
                  ...(selectionData?.statuses.map((status) => ({
                    value: status.id,
                    label: status.description,
                  })) || []),
                ]}
              /></span>
            <span>Order Number:
              <Input
                name="orderNumber"
                value={selectedOrderNumber}
                onChange={(e) => setSelectedOrderNumber(e.target.value)}
                css={tw`w-[15ch]`}
              /></span>
            <span>Invoice Number:
              <Input
                name="invoiceNumber"
                value={selectedInvoiceNumber}
                onChange={(e) => setSelectedInvoiceNumber(e.target.value)}
                css={tw`w-[15ch]`}
              /></span>
            <span>Customer PO:
              <Input
                name="poNumber"
                value={selectedPONumber}
                onChange={(e) => setSelectedPONumber(e.target.value)}
                css={tw`w-[20ch]`}
              />
            </span>
          </div>
          <div css={tw`flex flex-wrap pt-2 from-[#e6e6e6] via-[#fdfdfd] to-[#e6e6e6] gap-2 text-xs items-center`}>
            <span>Category:
              <Select
                name="status"
                value={selectedCategory || "all"}
                onChange={(category: string) => setSelectedCategory(category)}
                minWidth={"15ch"}
                data={[
                  { value: "all", label: "All" },
                  ...(selectionData?.categories.map((status) => ({
                    value: status.id,
                    label: status.description,
                  })) || []),
                ]}
              /></span>
              <span>Priority:
              <Select
                name="priority"
                value={selectedPriority || "all"}
                onChange={(priority: string) => setSelectedPriority(priority)}
                minWidth={"22ch"}
                data={[
                  { value: "all", label: "All" },
                  ...(selectionData?.priorities.map((status) => ({
                    value: status.id,
                    label: status.description,
                  })) || []),
                ]}
              /></span>
              <span>Resolution:
              <Select
                name="priority"
                value={selectedResolution || "all"}
                onChange={(resolution: string) => setSelectedResolution(resolution)}
                minWidth={"14ch"}
                data={[
                  { value: "all", label: "All" },
                  ...(selectionData?.resolutions.map((status) => ({
                    value: status.id,
                    label: status.description,
                  })) || []),
                ]}
              /></span>
              <span>RMA Number:
              <Input
                name="rmaNumber"
                value={selectedRMANumber}
                onChange={(e) => setSelectedRMANumber(e.target.value)}
                css={tw`w-[15ch]`}
              /></span>
              </div>
              <div css={tw`flex flex-wrap pt-2 from-[#e6e6e6] via-[#fdfdfd] to-[#e6e6e6] gap-2 text-xs items-center`}>
                <div tw="flex items-center">
                  <span>Opened From Date:</span>
                  <DatePicker
                    css={tw`bg-white`}
                    name="fromDate"
                    value={fromDate}
                    onChange={setFromDate}
                  />
                </div>
                <div tw="flex items-center">
                  <span>Opened To Date:</span>
                  <DatePicker
                    css={tw`bg-white`}
                    name="toDate"
                    value={toDate}
                    onChange={setToDate}
                  />
                </div>
                <div tw="flex items-center">
                  <span>Closed From Date:</span>
                  <DatePicker
                    css={tw`bg-white`}
                    name="fromDate"
                    value={closedFromDate}
                    onChange={setClosedFromDate}
                  />
                </div>
                <div tw="flex items-center">
                  <span>Closed To Date:</span>
                  <DatePicker
                    css={tw`bg-white`}
                    name="toDate"
                    value={closedToDate}
                    onChange={setClosedToDate}
                  />
                </div>
                
                
                <span css={tw`ml-2`}>Run as Excel:</span>
                <input
                  type="checkbox"
                  name="excel"
                  checked={excelFlag}
                  onChange={(e) => {
                    setExcelFlag(e.target.checked);
                  }}
                />
                <Button
                  disabled={'idle' != loading}
                  onClick={clickHandler}
                  css={tw`ml-4 py-[2px] text-xs`}
                  type="button"
                >
                  Search
                </Button>

                {loading == 'excel' && (
                  <AnimatedLoadingSpinner message="Loading Excel..." css={tw`ml-1`}/>
                )}
            </div>
          </div>
        </>
      )}
      
      {claimsResults && (
        <ClaimListResults isLoading={loading == 'grid'} results={claimsResults} onDetailShowingChange={handleDetailShowingChange} />
      )}
    </div>
  );

};

export default ClaimList;
