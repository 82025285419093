/** @jsxImportSource @emotion/react */
import React, { ReactNode, useEffect } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import RollingForm from "./RollingForm";
import { selectCurrentCustomerId } from "../../../../../store/customer.reducer";
import { selectCurrentRollingData } from "../../../../../store/product.reducer";
import { formatDateToShort } from "../../../../../helpers/dateHelpers";
import SectionHeader from "../../../../../components/molecules/SectionHeader";
import { selectCurrentAuthorities } from "../../../../../store/user.reducer";
import { hasAuthority } from "../../../../../helpers/hasAuthority";
import TextButton from "../../../../../components/atoms/TextButton";
import BookingsModal from "./bookings/BookingsModal";
import { setRollingItemToEdit } from "../../../../../store/rollings.reducer";
import AnimatedLoadingSpinner from "../../../../../components/atoms/AnimatedLoadingSpinner";

type Props = {
  children?: ReactNode;
};

const PrimeRight: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const customerId = useAppSelector(selectCurrentCustomerId);
  const currentAuthorities = useAppSelector(selectCurrentAuthorities);
  const rollingData = useAppSelector(selectCurrentRollingData);
  const productSelectionDetails = useAppSelector(
    (state) => state.product.productSelectionDetails
  );
  const isInternal = hasAuthority("internal", currentAuthorities);
  const [bookingModalShowing, setBookingModalShowing] = React.useState(false);
  const { loading } = useAppSelector((state) => state.rollings);

  useEffect(() => {
    return () => {
      dispatch(setRollingItemToEdit(undefined));
    };
  }, []);

  return (
    <>
      <SectionHeader title="ORDER FROM ROLLINGS" css={tw`mt-2`} />
      {bookingModalShowing && isInternal && (
        <BookingsModal
          onCancel={() => setBookingModalShowing(false)}
          rollingData={rollingData}
        />
      )}
      {productSelectionDetails.wall !== 0 && (
        <div css={tw`bg-[#ececec] text-xs p-1`}>
          <p css={tw`font-bold border-b border-nucor-light-gray w-full`}>
            Estimated Rollings Dates:
          </p>
          {loading == 'pending' && <AnimatedLoadingSpinner message="Loading..." css={tw`mt-1`} />}
          {loading != 'pending' && rollingData?.resourceRollings.length == 0 && <div className="mt-1">No rollings</div>}
          {loading != 'pending' && rollingData?.resourceRollings.map((resource) => (
            <div
              key={resource.resource}
              css={tw`border-b border-nucor-light-gray`}
            >
              <p>{resource.resource}</p>
              {resource?.rollings.map((rolling) => (
                <p
                  key={rolling.rollingCycleId + rolling.cycleLinePerSequence}
                  css={[
                    tw`font-bold flex items-center`,
                    rolling.rollingDisplayColor === "red" && tw`bg-red-300`,
                    rolling.rollingDisplayColor === "yellow" && tw`bg-nucor-yellow`,
                  ]}
                >
                  <span css={tw`w-[14px] ml-[2px]`}>
                    {rolling.prodCreated ? "✓" : ""}
                  </span>
                  <span>{`${formatDateToShort(
                    rolling.startDate
                  )} - ${formatDateToShort(rolling.stopDate)}`}</span>
                  {rolling.subjectToAccumulation && (
                    <span css={tw`ml-2 text-red-600`}>STA</span>
                  )}
                  {rolling.productOfCoil && (
                    <span css={tw`ml-2 text-red-600`}>POC</span>
                  )}
                </p>
              ))}
            </div>
          ))}

          {rollingData?.additionalMessage && (
            <p css={tw`mt-4 font-bold`}>{rollingData.additionalMessage}</p>  
          )
          }

          {isInternal && (
            <TextButton
              onClick={() => setBookingModalShowing(true)}
              css={tw`mt-4`}
            >
              View Bookings
            </TextButton>
          )}
          <p css={tw`mt-4`}>
            Dates in <span css={tw`bg-red-200 font-bold`}>red</span> are
            <strong> Not Available</strong>. For dates in{" "}
            <span css={tw`bg-nucor-yellow font-bold`}>yellow</span>, contact
            your Account Representative.
          </p>
        </div>
      )}
      {productSelectionDetails.wall !== 0 &&
        customerId &&
        rollingData &&
        hasAuthority("createOrderQuoteLoad", currentAuthorities) &&
        rollingData.rollings.length > 0 && <RollingForm />}
    </>
  );
};

export default PrimeRight;
