/** @jsxImportSource @emotion/react */
import React, { ReactNode, useState } from "react";
import tw from "twin.macro";
import Button from "../../../../../components/atoms/Button";
import NumericInput from "../../../../../components/atoms/NumericInput";
import InputDialog from "../../../../../components/molecules/InputDialog";

type Props = {
  children?: ReactNode;
  onCancel: () => void;
  onConfirm: (value: number) => void;
};

const EnterTonsDialog: React.FC<Props> = ({ onCancel, onConfirm }) => {
  const [value, setValue] = useState("");

  return (
    <InputDialog onCancel={onCancel} title="Enter Tons">
      <span css={tw`text-nucor-gray font-semibold`}>Tons:</span>
      <NumericInput
        type="text"
        name="tons"
        value={value}
        css={tw`w-[8ch] mx-2`}
        onChange={(e) => {
          if (!/^\d*/.test(e.target.value)) return;
          setValue(e.target.value);
        }}
      />
      <Button
        onClick={() => {
          onConfirm(value === "" ? 0 : +value);
        }}
        disabled={+value === 0}
      >
        Calculate
      </Button>
    </InputDialog>
  );
};

export default EnterTonsDialog;
