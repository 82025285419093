import { CurrentContext } from "../types/types";

export const userType = (context?: CurrentContext | null) => {
  if (!context) return "UNKNOWN";
  const { external, internal, admin, itAdmin } = context.user;
  switch (true) {
    case external:
      return "EXTERNAL";
    case internal:
      return "INTERNAL";
    case admin:
      return "ADMIN";
    case itAdmin:
      return "IT_ADMIN";
    default:
      return "UNKNOWN";
  }
};
