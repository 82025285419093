/** @jsxImportSource @emotion/react */
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { fetchCustomers } from "../../store/customer.actions";
import { CustomerIndex } from "../../types/types";
import Input from "../../components/atoms/Input";

type Props = {
  children?: ReactNode;
  onCancel?: () => void;
  onConfirm: (id?: string) => void;
};

const FullCustomerList: React.FC<Props> = ({
  onCancel,
  onConfirm,
  ...remainingProps
}) => {

  type CustomerClassificationTypes = "all" | "hss" | "a53";

  const initialLoad = useRef(true);
  const inputRef = useRef<HTMLInputElement>(null);
  const [customers, setCustomers] = useState<CustomerIndex[]>([]);
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.customer);
  const [criteria, setCriteria] = useState("");
  const [customerClassification, setCustomerClassification] = useState<CustomerClassificationTypes>("all");

  useEffect(() => {
    inputRef.current?.focus();
    if (initialLoad) {
      initialLoad.current = false;
      dispatch(fetchCustomers())
        .unwrap()
        .then((customers) => {
          setCustomers(customers);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  const filterCriteria = useCallback(
    (customer: CustomerIndex) => {
      if (criteria === "") {
        let classificationMatch = true;
        if (customerClassification != 'all') {
          classificationMatch = ((customerClassification == 'hss' && !customer.shipToPricing) || (customerClassification == 'a53' && customer.shipToPricing));  
        }
        return classificationMatch;
      } else {
        if (customer.name) {
          const pattern = new RegExp(`${criteria}`, "gi");
          let classificationMatch = true;
          if (customerClassification != 'all') {
            classificationMatch = ((customerClassification == 'hss' && !customer.shipToPricing) || (customerClassification == 'a53' && customer.shipToPricing));  
          }
          return (classificationMatch && (pattern.test(customer.id.toLowerCase()) || pattern.test(customer.name.toLowerCase()))
          );
        }
      }
      return false;
    },
    [criteria, customerClassification]
  );

  const filteredCustomers = customers.filter(filterCriteria);

  return createPortal(
    <div
      onClick={onCancel}
      css={[
        tw`fixed top-0 left-0 w-full h-full flex justify-center items-start bg-[#cccccc77] z-[100]`,
      ]}
      {...remainingProps}
    >
      <div
        css={[tw`bg-nucor-green p-[7px] min-w-[50ch] w-[105ch] text-xs mt-40`]}
        onClick={(e) => e.stopPropagation()}
      >
        <div css={tw`flex justify-between`}>
          <h2 css={tw`font-bold text-white pb-1 text-xs flex-1`}>
            Change Customer
          </h2>
          <button
            type="button"
            onClick={onCancel}
            css={tw`text-nucor-green bg-[#ccc] h-[13px] w-[13px] flex justify-center items-center font-bold mt-px`}
          >
            &times;
          </button>
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (filteredCustomers.length === 1) {
              onConfirm(filteredCustomers[0].id);
            }
          }}
          css={tw`bg-white flex items-center p-1 pt-2`}
        >
          <span>Customer Filter:</span>

          <Input
            ref={inputRef}
            value={criteria}
            onChange={(e) => setCriteria(e.target.value)}
            placeholder="Enter filter criteria"
            css={tw`ml-2`}
          />

          <input
            checked={customerClassification === "all"}
            id="allCustomersRadio"
            type="radio"
            name="customerClassification"
            value="all"
            onChange={() => setCustomerClassification("all")}
            css={tw`ml-2 cursor-pointer`}
          />
          <label htmlFor="allCustomersRadio" css={tw`text-xs ml-1 cursor-pointer`}>
            All
          </label>

          <input
            checked={customerClassification === "hss"}
            id="hssCustomersRadio"
            type="radio"
            name="customerClassification"
            value="hss"
            onChange={() => setCustomerClassification("hss")}
            css={tw`ml-2 cursor-pointer`}
          />
          <label htmlFor="hssCustomersRadio" css={tw`text-xs ml-1 cursor-pointer`}>
            HSS
          </label>

          <input
            checked={customerClassification === "a53"}
            id="a53CustomersRadio"
            type="radio"
            name="customerClassification"
            value="a53"
            onChange={() => setCustomerClassification("a53")}
            css={tw`ml-2 cursor-pointer`}
          />
          <label htmlFor="a53CustomersRadio" css={tw`text-xs ml-1 cursor-pointer`}>
            A53-SP
          </label>
        </form>
        <div
          css={tw`sm:flex py-[3px] bg-white text-nucor-gray px-1 border-b border-[#d5d5d5]`}
        >
          <div css={tw`sm:w-[55ch]`}><span css={tw`font-bold`}>Customer</span></div>
          <div css={tw`sm:w-[15ch]`}><span css={tw`font-bold`}>Classification</span></div>
          <div><span css={tw`font-bold`}>City, State</span></div>
        </div>
        {loading && (
          <div css={tw`font-bold text-nucor-gray bg-white px-1`}>Loading</div>
        )}
        {!loading && filteredCustomers.length === 0 && (
          <div css={tw`text-nucor-gray bg-white px-1 pt-2`}>No customers meet criteria</div>
        )}
        <div css={[tw`bg-white p-1 max-h-[300px] overflow-y-auto`]}>
          {filteredCustomers
            .sort((a, b) => (a.id > b.id ? 1 : -1))
            .map((customer, index) => (
              <div
                css={[
                  tw`sm:flex py-[3px]`,
                  index % 2 === 0 && tw`bg-[#d5d5d5]`,
                  tw`hover:bg-nucor-yellow`,
                ]}
                key={customer.id}
              >
                <button
                  css={tw`sm:w-[55ch] text-left cursor-pointer text-nucor-link underline hover:text-nucor-link-hover hover:no-underline focus-visible:text-nucor-link-hover focus-visible:outline-none`}
                  onClick={() => onConfirm(customer.id)}
                >
                  {customer.id} {customer.name}
                </button>
                <div css={tw`sm:w-[15ch]`}>{customer.classification}</div>
                <div>{`${customer.city ?? ""}, ${
                  customer.state ?? ""
                }`}</div>
              </div>
            ))}
        </div>
      </div>
    </div>,
    document.getElementById("modal") as HTMLElement
  );
};

export default FullCustomerList;
