/** @jsxImportSource @emotion/react */
import {
  faBackwardStep,
  faCaretLeft,
  faCaretRight,
  faForwardStep,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactNode, useEffect, useState } from "react";
import tw from "twin.macro";

type Props = {
  children?: ReactNode;
  currentPage: number;
  maxPage: number;
  onFirst?: () => void;
  onLast?: () => void;
  onNext?: () => void;
  onPrevious?: () => void;
  onSetPage: (page: number) => void;
};

const Pagination: React.FC<Props> = ({
  currentPage,
  maxPage,
  onFirst,
  onLast,
  onNext,
  onPrevious,
  onSetPage,
}) => {
  const [page, setPage] = useState(currentPage + 1);

  useEffect(() => {
    setPage(currentPage + 1);
  }, [currentPage]);

  return (
    <div css={tw`flex text-xs gap-2 items-center`}>
      <button className="ntp-paging-button" onClick={onFirst} disabled={currentPage === 0}>
        <FontAwesomeIcon
          size="lg"
          css={[
            tw`text-nucor-green cursor-pointer`,
            currentPage === 0 && tw`text-nucor-gray cursor-default`,
          ]}
          icon={faBackwardStep}
        />
      </button>
      <button className="ntp-paging-button" onClick={onPrevious} disabled={currentPage === 0}>
        <FontAwesomeIcon
          css={[
            tw`text-nucor-green cursor-pointer`,
            currentPage === 0 && tw`text-nucor-gray cursor-default`,
          ]}
          size="lg"
          icon={faCaretLeft}
        />
      </button>
      <Seperator />
      <span>
        Page{" "}
        <input
          css={[tw`max-w-[4ch] text-right`]}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.currentTarget.blur();
              if (page > maxPage) {
                setPage(maxPage + 1);
                onSetPage(maxPage);
                return;
              }
              if (page < 1) {
                setPage(1);
                onSetPage(0);
                return;
              }
              onSetPage(page - 1);
            }
            if (e.key === "Escape") {
              setPage(currentPage + 1);
            }
          }}
          onChange={(e) => {
            if (isNaN(+e.target.value)) return;
            setPage(+e.target.value);
          }}
          value={page}
        />{" "}
        of {maxPage + 1}
      </span>
      <Seperator />
      <button className="ntp-paging-button" onClick={onNext} disabled={currentPage === maxPage}>
        <FontAwesomeIcon
          css={[
            tw`text-nucor-green cursor-pointer`,
            currentPage === maxPage && tw`text-nucor-gray cursor-default`,
          ]}
          size="lg"
          icon={faCaretRight}
        />
      </button>
      <button className="ntp-paging-button" onClick={onLast} disabled={currentPage === maxPage}>
        <FontAwesomeIcon
          css={[
            tw`text-nucor-green cursor-pointer`,
            currentPage === maxPage && tw`text-nucor-gray cursor-default`,
          ]}
          size="lg"
          icon={faForwardStep}
        />
      </button>
    </div>
  );
};

export default Pagination;

const Seperator = () => {
  return <span tw="h-full w-[1px] inline-block" />;
};
