/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";
import tw from "twin.macro";
import { useAppSelector } from "../../app/hooks";
import { selectCartSummary } from "../../store/shoppingCart.reducer";
import Sprites from "../atoms/Sprites";

type Props = {
  children?: ReactNode;
};

const InquiryCart: React.FC<Props> = () => {
  const cartSummary = useAppSelector(selectCartSummary);
  const location = useLocation();

  return (
    <div css={tw`pl-4`}>
      <Link
        to={{ pathname: "/portal/stock/cart/inquiry", search: location.search }}
        css={tw`text-nucor-link hover:text-nucor-link-hover underline hover:no-underline text-xs font-bold flex items-center h-6 focus:text-nucor-link-hover focus:outline-none`}
      >
        <Sprites icon="pencil" />
        {`Inquiry Cart: ${cartSummary?.totalQuoteItems ?? 0} ${
          cartSummary?.totalQuoteItems === 1 ? "Item" : "Items"
        } `}
      </Link>
    </div>
  );
};

export default InquiryCart;
