/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { ReactNode, useState } from "react";
import tw from "twin.macro";
import Button from "../../../../components/atoms/Button";
import { useForm } from "react-hook-form";
import { RequestNewShipToData, createSchema } from "./requestNewShipForm";
import { yupResolver } from "@hookform/resolvers/yup";
import { DeliveryModeType, ShipToRequest } from "../../../../types/types";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { requestNewShipTo } from "../../../../store/customer.actions";
import InputWithValidation from "./InputWithValidation";

type Props = {
  children?: ReactNode;
  shipMethod?: DeliveryModeType;
  customerPayingForFreight?: boolean;
  freightOnInvoice?: boolean;
  onStatusChange: (status: "idle" | "submitting" | "error" | "sent") => void;
  onReset: () => void;
  onSubmitted: (details: ShipToRequest) => void;
};

// API endpoint does not require fax number but will not treat value "" as optional.
// React hook forms does not treat empty fields as undefined. This function was created
// to allow the form to be submitted propery if fax fields are blank.
const removeEmptyFax = (data: RequestNewShipToData) => {
  const hasFax =
    data.shipToFax?.areaCode !== "" &&
    data.shipToFax?.exchange !== "" &&
    data.shipToFax?.local4 !== "";
  if (!hasFax) {
    data.shipToFax = undefined;
  }
  return data;
};

const RequestNewShipTo: React.FC<Props> = ({
  shipMethod,
  customerPayingForFreight,
  freightOnInvoice,
  onStatusChange,
  onReset,
  onSubmitted,
}) => {
  const dispatch = useAppDispatch();
  const customerId = useAppSelector(
    (state) => state.customer.currentCustomer?.id
  );
  const user = useAppSelector((state) => state.user.currentContext?.user);

  const [error, setError] = useState("");

  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, errors },
  } = useForm<RequestNewShipToData>({
    resolver: yupResolver(createSchema(shipMethod)),
    mode: "onBlur",
    defaultValues: {
      contactName: `${user?.firstName} ${user?.lastName}`,
      contactPhone: user?.officePhone,
      contactPhoneExtension: user?.officePhoneExt,
    },
  });

  const formHandler = handleSubmit((data) => {
    if (!shipMethod || !customerId) return;
    setError("");
    onStatusChange("submitting");
    // See comment above in function definition.
    const newData = removeEmptyFax(data);
    const body: ShipToRequest = {
      transportType: shipMethod,
      billOnInvoice: freightOnInvoice,
      customerPayingForFreight: customerPayingForFreight,
      ...newData,
    };
    dispatch(requestNewShipTo({ customerId, body }))
      .unwrap()
      .then((details) => {
        onStatusChange("sent");
        onSubmitted(details);
      })
      .catch((error) => {
        setError(error);
        onStatusChange("error");
      });
  });

  const resetHandler = () => {
    setError("");
    onReset();
    reset();
  };

  return (
    <>
      {error && <p css={tw`text-red-600`}>{error}</p>}
      <form
        onSubmit={formHandler}
        css={[
          tw`mb-8 text-xs`,
          css`
            & fieldset {
              border: none;
              margin: 4px;
              margin-left: 8px;
              margin-right: 8px;
              padding: 4px;
              & label {
                font-weight: bold;
                color: #6d756e;
              }
            }
          `,
        ]}
      >
        <fieldset css={tw`flex gap-4 items-center`}>
          <label css={{ width: "20%" }} htmlFor="contactName">
            Contact Name <span css={[tw`text-red-600`]}>*</span>
          </label>
          <InputWithValidation
            error={errors?.contactName?.message}
            {...register("contactName")}
            css={tw`w-full`}
            highlight
          />
        </fieldset>
        <fieldset css={tw`flex gap-4 items-center`}>
          <label css={{ width: "20%" }} htmlFor="contactPhoneArea">
            Contact Phone <span css={[tw`text-red-600`]}>*</span>
          </label>
          <div css={[tw`flex gap-2 items-center`]}>
            <InputWithValidation
              error={errors?.contactPhone?.areaCode?.message}
              {...register("contactPhone.areaCode")}
              restrictToNumber
              maxLength={3}
              css={tw`w-12`}
              highlight
            />
            <InputWithValidation
              error={errors?.contactPhone?.exchange?.message}
              {...register("contactPhone.exchange")}
              restrictToNumber
              maxLength={3}
              css={tw`w-12`}
              highlight
            />
            <InputWithValidation
              error={errors?.contactPhone?.local4?.message}
              {...register("contactPhone.local4")}
              restrictToNumber
              maxLength={4}
              css={tw`w-16`}
              highlight
            />
            <span css={tw`text-xs text-[#6d756e] font-bold`}>Ext.</span>
            <InputWithValidation
              error={errors?.contactPhoneExtension?.message}
              {...register("contactPhoneExtension")}
              restrictToNumber
              maxLength={10}
              css={tw`w-16`}
            />
          </div>
        </fieldset>
        <fieldset css={tw`flex gap-4 items-center`}>
          <label css={{ flexBasis: "20%" }} htmlFor="shipToName">
            Ship To Name <span css={[tw`text-red-600`]}>*</span>
          </label>
          <InputWithValidation
            {...register("shipToName")}
            error={errors?.shipToName?.message}
            highlight
            css={tw`w-full`}
          />
          <span css={tw`text-xs`}>{`e.g. xyz steel ${
            shipMethod === "Rail" ? "(Rail)" : ""
          }`}</span>
        </fieldset>
        <fieldset css={tw`flex gap-4 items-center`}>
          <label css={{ flexBasis: "20%" }} htmlFor="address1">
            Address 1 <span css={[tw`text-red-600`]}>*</span>
          </label>
          <InputWithValidation
            {...register("address1")}
            css={tw`w-full`}
            error={errors?.address1?.message}
            highlight
          />
          <span css={tw`text-xs`}>Street Address</span>
        </fieldset>
        <fieldset css={tw`flex gap-4 items-center`}>
          <label css={{ flexBasis: "20%" }} htmlFor="address2">
            {shipMethod === "Rail" ? "Rail Service Provider " : "Address 2"}
            {shipMethod === "Rail" && <span css={[tw`text-red-600`]}>*</span>}
          </label>
          <InputWithValidation
            {...register("address2")}
            highlight={shipMethod === "Rail"}
            css={tw`w-full`}
            error={errors.address2?.message}
          />
          {shipMethod === "Rail" && (
            <span css={tw`text-xs`}>e.g. BN - Burlington Northern</span>
          )}
        </fieldset>
        <fieldset css={tw`flex gap-4 items-center`}>
          <label css={{ flexBasis: "20%" }} htmlFor="address3">
            {shipMethod === "Rail" ? "Routing " : "Address 3"}
            {shipMethod === "Rail" && <span css={[tw`text-red-600`]}>*</span>}
          </label>
          <InputWithValidation
            highlight={shipMethod === "Rail"}
            {...register("address3")}
            css={tw`w-full`}
            error={errors.address3?.message}
          />
          {shipMethod === "Rail" && (
            <span css={tw`text-xs`}>e.g. Track # 123; Zone 001</span>
          )}
        </fieldset>
        <fieldset css={tw`flex gap-4 items-center`}>
          <label css={{ flexBasis: "20%" }} htmlFor="city">
            City <span css={[tw`text-red-600`]}>*</span>
          </label>
          <InputWithValidation
            {...register("city")}
            error={errors?.city?.message}
            css={tw`w-7/12`}
            highlight
          />
        </fieldset>
        <fieldset css={tw`flex gap-4 items-center`}>
          <label css={{ flexBasis: "20%" }} htmlFor="state">
            State <span css={[tw`text-red-600`]}>*</span>
          </label>
          <div css={tw`flex gap-2 items-center`}>
            <InputWithValidation
              {...register("stateCode")}
              maxLength={2}
              error={errors.stateCode?.message}
              css={tw`w-[7ch]`}
              highlight
            />
            <label css={tw`ml-4`} htmlFor="zip">
              Zip <span css={[tw`text-red-600`]}>*</span>
            </label>
            <InputWithValidation
              {...register("postalCode")}
              maxLength={10}
              error={errors.postalCode?.message}
              css={tw`w-[15ch]`}
              highlight
            />
            <label css={tw`ml-4`} htmlFor="country">
              Country <span css={[tw`text-red-600`]}>*</span>
            </label>
            <InputWithValidation
              {...register("country")}
              maxLength={3}
              error={errors?.country?.message}
              css={tw`w-[8ch]`}
              highlight
            />
          </div>
        </fieldset>
        <fieldset css={tw`flex gap-4 items-center`}>
          <label css={{ flexBasis: "20%" }} htmlFor="phone">
            Phone <span css={[tw`text-red-600`]}>*</span>
          </label>
          <div css={[tw`flex gap-2 items-center`]}>
            <InputWithValidation
              error={errors?.shipToPhone?.areaCode?.message}
              {...register("shipToPhone.areaCode")}
              restrictToNumber
              maxLength={3}
              highlight
              css={tw`w-12`}
            />
            <InputWithValidation
              error={errors?.shipToPhone?.exchange?.message}
              {...register("shipToPhone.exchange")}
              restrictToNumber
              maxLength={3}
              highlight
              css={tw`w-12`}
            />
            <InputWithValidation
              error={errors?.shipToPhone?.local4?.message}
              {...register("shipToPhone.local4")}
              restrictToNumber
              maxLength={4}
              highlight
              css={tw`w-16`}
            />
            <span css={tw`text-xs text-[#6d756e] font-bold`}>Ext.</span>
            <InputWithValidation
              error={errors?.shipToPhoneExtension?.message}
              {...register("shipToPhoneExtension")}
              restrictToNumber
              maxLength={10}
              css={tw`w-16`}
            />
          </div>
        </fieldset>
        <fieldset css={tw`flex gap-4 items-center`}>
          <label css={{ flexBasis: "20%" }} htmlFor="fax">
            Fax
          </label>
          <div css={[tw`flex gap-2 items-center`]}>
            <InputWithValidation
              error={errors?.shipToFax?.areaCode?.message}
              {...register("shipToFax.areaCode")}
              restrictToNumber
              maxLength={3}
              css={tw`w-12`}
            />
            <InputWithValidation
              error={errors?.shipToFax?.exchange?.message}
              {...register("shipToFax.exchange")}
              restrictToNumber
              maxLength={3}
              css={tw`w-12`}
            />
            <InputWithValidation
              error={errors?.shipToFax?.local4?.message}
              {...register("shipToFax.local4")}
              restrictToNumber
              maxLength={4}
              css={tw`w-16`}
            />
            <span css={tw`text-xs text-[#6d756e] font-bold`}>Ext.</span>
            <InputWithValidation
              error={errors?.shipToFaxExtension?.message}
              {...register("shipToFaxExtension")}
              restrictToNumber
              maxLength={10}
              css={tw`w-16`}
            />
          </div>
        </fieldset>
        <div css={[tw`flex mt-4`]}>
          <div css={[{ flexBasis: "20%" }]} />
          <p css={[tw`text-xs ml-1`, { flexBasis: "75%" }]}>
            Add any special shipping remarks to the notes below.
          </p>
        </div>
        <fieldset css={tw`flex`}>
          <label css={{ flexBasis: "20%" }} htmlFor="notes">
            Notes
          </label>
          <textarea
            rows={4}
            {...register("notes")}
            css={[tw`ml-4 w-full`, { resize: "none" }]}
          />
        </fieldset>
        <div css={[tw`flex justify-end gap-4 w-10/12 items-center`]}>
          <Button
            disabled={!isValid}
            type="submit"
            css={[tw`font-normal text-xs`]}
          >
            Submit
          </Button>
          <Button
            onClick={resetHandler}
            type="button"
            css={[tw`font-normal text-xs`]}
          >
            Reset
          </Button>
        </div>
      </form>
    </>
  );
};

export default RequestNewShipTo;
