/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import tw from "twin.macro";
import Button from "../../../components/atoms/Button";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Toast from "../../../components/molecules/Toast";
import { clearError, clearMessage } from "../../../store/itadmin.reducer";
import Loading from "../../../components/atoms/Loading";
import {
  clearCaches,
  loadCustomers,
  loadProductSpecs,
  loadProducts,
  loadValidationConfig,
} from "../../../store/itadmin.actions";

type Props = {
  children?: ReactNode;
};

const SyncD365: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const {
    error,
    message,
    loadProductsStatus,
    loadProductSpecsStatus,
    loadCustomersStatus,
    loadValidationConfigStatus,
    clearCachesStatus,
  } = useAppSelector((state) => state.itadmin.sync);

  return (
    <>
      {message && (
        <Toast
          type="success"
          message={message}
          onConfirm={() => {
            dispatch(clearMessage());
          }}
        />
      )}
      {error && (
        <Toast
          type="error"
          message={error}
          onConfirm={() => {
            dispatch(clearError());
          }}
        />
      )}
      <div css={tw`flex flex-col gap-8 p-8 relative`}>
        <div className="flex items-center gap-4">
          <Button
            disabled={loadProductsStatus === "pending"}
            onClick={() => {
              dispatch(loadProducts());
            }}
          >
            Load Products
          </Button>
          {loadProductsStatus === "pending" && (
            <Loading>Loading Products</Loading>
          )}
        </div>
        <div className="flex items-center gap-4">
          <Button
            disabled={loadProductSpecsStatus === "pending"}
            onClick={() => {
              dispatch(loadProductSpecs());
            }}
          >
            Load Product Specs
          </Button>
          {loadProductSpecsStatus === "pending" && (
            <Loading>Loading Product Specs</Loading>
          )}
        </div>
        <div className="flex items-center gap-4">
          <Button
            disabled={loadCustomersStatus === "pending"}
            onClick={() => {
              dispatch(loadCustomers());
            }}
          >
            Load Customers
          </Button>
          {loadCustomersStatus === "pending" && (
            <Loading>Loading Customers</Loading>
          )}
        </div>
        <div className="flex items-center gap-4">
          <Button
            disabled={loadValidationConfigStatus === "pending"}
            onClick={() => {
              dispatch(loadValidationConfig());
            }}
          >
            Load Validation Config
          </Button>
          {loadValidationConfigStatus === "pending" && (
            <Loading>Loading Validation Config</Loading>
          )}
        </div>
        <div className="flex items-center gap-4">
          <Button
            disabled={clearCachesStatus === "pending"}
            onClick={() => {
              dispatch(clearCaches());
            }}
          >
            Clear Caches
          </Button>
          {clearCachesStatus === "pending" && (
            <Loading>Clearing Caches</Loading>
          )}
        </div>
      </div>
    </>
  );
};

export default SyncD365;
