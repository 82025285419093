/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { setProductDetailsFromHistory } from "../../../../../store/product.reducer";
import { ProductShape } from "../../../../../types/types";

type Props = {
  children?: ReactNode;
};

const BrowsingHistory: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const browseHistory = useAppSelector(
    (state) => state.product.prime.browseHistory
  );
  const { loading } = useAppSelector((state) => state.rollings);
  const { inventorySearchStatus } = useAppSelector((state) => state.product.prime);

  const disabled= (loading == 'pending' || inventorySearchStatus == 'pending');

  if (browseHistory.length === 0) return null;

  return (
    <div css={tw`bg-nucor-component-background px-2 py-4`}>
      <div
        css={tw`bg-nucor-green text-white text-xs font-bold text-center py-1 rounded-t-lg`}
      >
        Browsing History
      </div>
      <div css={tw`bg-white p-1`}>
        {browseHistory
          .filter((item) => item.compoundProductKey !== undefined)
          .map((item, index) => (
            <div key={index} css={tw`flex justify-between items-center my-1`}>
              <button
                disabled={disabled}
                type="button"
                css={[
                  tw`text-nucor-link underline hover:text-nucor-link-hover hover:no-underline text-left max-w-[88%] focus:text-nucor-link-hover focus:outline-none`,
                  disabled && tw`text-nucor-gray cursor-default hover:text-nucor-gray no-underline`
                ]}
                onClick={() => {
                  dispatch(setProductDetailsFromHistory(item));
                }}
              >
                {item.name}
              </button>
              <TubeShape shape={item.shape} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default BrowsingHistory;

type ShapeProps = {
  shape: ProductShape;
};

const TubeShape: React.FC<ShapeProps> = ({ shape }) => {
  if (shape === "Round") {
    return <div css={tw`h-4 w-4 border border-nucor-green rounded-full`} />;
  }

  if (shape === "Rectangle") {
    return <div css={tw`h-4 w-6 border border-nucor-green rounded`} />;
  }

  if (shape === "Square") {
    return <div css={tw`h-4 w-4 border border-nucor-green rounded`} />;
  }

  return null;
};
