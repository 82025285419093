/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import tw from "twin.macro";

type Props = {
  children?: ReactNode;
};

const OpenOrdersFooter: React.FC<Props> = () => {
  return (
    <>
      <p>HLD - Not Ready to Ship</p>
      <p>RDY - Ready to Ship</p>
      <p>LDG - On a Load Bill</p>
      <p css={tw`my-4`}>Weight displayed in pounds.</p>
      <p>Open Orders includes all ordered items that have not shipped.</p>
      <p>Due Date searches are based on current due date.</p>
      <p>Past Due searches are based on original due date.</p>
      <p>Original Due Date shown in parentheses if the due date was changed.</p>
    </>
  );
};

export default OpenOrdersFooter;
