export const generateMonthList = (months: number) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const today = new Date();
  const arr: Date[] = [];
  for (let i = 0; i < months; i++) {
    const firstOfMonth = new Date(
      today.getFullYear(),
      today.getMonth(),
      1,
      0,
      0,
      0,
      0
    );
    firstOfMonth.setMonth(firstOfMonth.getMonth() + i);
    arr.push(firstOfMonth);
  }
  return arr.map((date) => ({
    month: monthNames[date.getMonth()],
    year: date.getFullYear(),
  }));
};
