/** @jsxImportSource @emotion/react */
import React, {
  ReactNode,
  useContext,
  useState,
} from "react";
import tw from "twin.macro";
import { dateToString } from "../../../../helpers/dateHelpers";
import Button from "../../../../components/atoms/Button";
import DatePicker from "../../../../components/atoms/DatePicker";
import Input from "../../../../components/atoms/Input";
import Select from "../../../../components/atoms/Select";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  clearDocumentErrors,
  selectOrderDocumentsSelectionData,
  updateOrderSearchCriteria,
} from "../../../../store/document.reducer";
import DimensionsSelectComponent from "../../myshipping/components/DimensionsSelectComponent";
import { ProductSelectionDetails } from "../../../../types/types";
import LengthComponent from "../../../../components/molecules/LengthComponent";
import { selectCurrentCustomerId } from "../../../../store/customer.reducer";
import { searchOrderDocumentOrder } from "../../../../store/document.actions";
import ComboBox from "../../../../components/atoms/ComboBox";
import Toast from "../../../../components/molecules/Toast";
import { PDFContext } from "./OrdersList";
import { lengthToInches } from "../../../../helpers/lengthHelpers";
import { searchParameterExists } from "../../../../helpers/searchParameterExists";

type Props = {
  children?: ReactNode;
};

const OrdersSearch: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const customerId = useAppSelector(selectCurrentCustomerId);
  const orderDocumentsSelectionData = useAppSelector(
    selectOrderDocumentsSelectionData
  );
  const { searchCriteria, error } = useAppSelector(
    (state) => state.document.orders
  );
  const ctx = useContext(PDFContext);

  const [customerSearchType, setCustomerSearchType] = useState(false);
  const [customerPONumber, setCustomerPONumber] = useState("");
  const [salesOrderNumber, setSalesOrderNumber] = useState("");
  const [fromDate, setFromDate] = useState<Date | undefined>();
  const [toDate, setToDate] = useState<Date | undefined>();
  const [customerPart, setCustomerPart] = useState("");
  const [dimensions, setDimensions] = useState<ProductSelectionDetails>({
    grade: "",
    shape: "Unknown",
    height: 0,
    width: 0,
    wall: 0,
  });
  const [length, setLength] = useState({
    feet: 0,
    inches: 0,
    fraction: 0,
  });

  const lengthInInches = lengthToInches(length);

  const criteria = {
    myCustomers: customerSearchType,
    customerPONumber: searchParameterExists(customerPONumber) ? customerPONumber : undefined,
    salesOrderNumber: searchParameterExists(salesOrderNumber) ? salesOrderNumber : undefined,
    fromDate: fromDate ? dateToString(fromDate) : undefined,
    toDate: fromDate ? dateToString(toDate) : undefined,
    customerPartNumber: searchParameterExists(customerPart) ? customerPart : undefined,
    length: lengthInInches === 0 ? undefined : lengthInInches,
    grade: dimensions?.grade === "" ? undefined : dimensions?.grade,
    shape: dimensions?.shape === "Unknown" ? undefined : dimensions?.shape,
    height: dimensions?.height === 0 ? undefined : dimensions?.height,
    width: dimensions?.width === 0 ? undefined : dimensions?.width,
    decimalWallThickness: dimensions?.wall === 0 ? undefined : dimensions?.wall,
  };

  const resetForm = () => {
    setCustomerSearchType(false);
    setCustomerPONumber("");
    setSalesOrderNumber("");
    setCustomerPart("");
    setFromDate(undefined);
    setToDate(undefined);
    setDimensions({
      grade: "",
      shape: "Unknown",
      height: 0,
      width: 0,
      wall: 0,
    });
    setLength({
      feet: 0,
      inches: 0,
      fraction: 0,
    });
  };

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Close pdf if open
    if (ctx?.file) {
      ctx.setFile(null);
    }

    dispatch(updateOrderSearchCriteria({ ...searchCriteria, page: 1}));
    dispatch(searchOrderDocumentOrder({customerId, criteria: { ...criteria, page: 1, pageSize: 25}}));
  };

  return (
    <form onSubmit={submitHandler} css={tw`text-xs`}>
      {error && (
        <Toast
          type="error"
          message={error}
          onConfirm={() => dispatch(clearDocumentErrors())}
        />
      )}
      <p css={tw`text-nucor-gray mt-2`}>Enter any combination:</p>
      <fieldset css={tw`mt-2`}>
        <label css={tw`block`}>Show</label>
        <Select
          name="customerSearchType"
          minWidth="22ch"
          value={customerSearchType}
          onChange={setCustomerSearchType}
          data={orderDocumentsSelectionData?.customerSearchType.map((item) => ({
            value: item.id,
            label: item.description,
          }))}
        />
      </fieldset>
      <fieldset css={tw`mt-2`}>
        <label htmlFor="customerPO" css={tw`block`}>Customer PO #</label>
        <Input
          name="customerPONumber"
          value={customerPONumber}
          onChange={(e) => setCustomerPONumber(e.target.value)}
          css={tw`block w-[22ch]`}
        />
      </fieldset>
      <fieldset css={tw`mt-2`}>
        <label htmlFor="fromDate" css={tw`block`}>From Order Date</label>
        <DatePicker
          css={tw`bg-white`}
          name="fromDate"
          value={fromDate}
          onChange={setFromDate}
        />
      </fieldset>
      <fieldset css={tw`mt-2`}>
        <label htmlFor="toDate" css={tw`block`}>To Order Date</label>
        <DatePicker
          css={tw`bg-white`}
          name="toDate"
          value={toDate}
          onChange={setToDate}
        />
      </fieldset>
      <fieldset css={tw`mt-2`}>
        <label htmlFor="salesOrder" css={tw`block`}>Sales Order #</label>
        <Input
          name="salesOrderNumber"
          value={salesOrderNumber}
          onChange={(e) => setSalesOrderNumber(e.target.value)}
          css={tw`block w-[22ch]`}
        />
      </fieldset>
      {orderDocumentsSelectionData?.customerParts &&
        orderDocumentsSelectionData.customerParts.length > 0 && (
          <fieldset css={tw`mt-2`}>
            <label css={tw`block`}>Customer Part #</label>
            <ComboBox
              value={customerPart}
              name="customerPartNumber"
              defaultValue={customerPart}
              onChange={setCustomerPart}
              data={orderDocumentsSelectionData?.customerParts.map((item) => ({
                value: item.customerPartNumber,
                label: (
                  <span>
                    {item.customerPartNumber}
                    <span css={tw`ml-4`}>{item.displayDescription}</span>
                  </span>
                ),
              }))}
            />
          </fieldset>
        )}
      <LengthComponent
        value={length}
        css={tw`mt-2`}
        onLengthChange={(len) => setLength(len)}
      />
      <DimensionsSelectComponent
        value={dimensions}
        onChange={(dim) => setDimensions(dim)}
      />
      <div css={tw`w-full text-right`}>
        <Button css={tw`mx-4 py-[2px] text-xs`} onClick={resetForm}>
          Clear
        </Button>
        <Button css={tw`m-0 py-[2px] font-normal text-xs mt-3`} type="submit">
          Search
        </Button>
      </div>
    </form>
  );
};

export default OrdersSearch;
