/** @jsxImportSource @emotion/react */
import React, { ReactNode, useRef, useState } from "react";
import {
  DeepRequired,
  FieldErrorsImpl,
  UseFormRegister,
} from "react-hook-form";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { searchCustomers } from "../../../../../store/admin.actions";
import {
  assignCustomerToUser,
  removeCustomerFromUser,
} from "../../../../../store/admin.reducer";
import { CustomerIndex } from "../../../../../types/types";
import Button from "../../../../../components/atoms/Button";
import Input from "../../../../../components/atoms/Input";
import { PersonalInfoForm } from "../personalInfoForm";

type Props = {
  children?: ReactNode;
  register: UseFormRegister<PersonalInfoForm>;
  errors?: FieldErrorsImpl<DeepRequired<PersonalInfoForm>>;
};

const AssignCustomers: React.FC<Props> = ({ register }) => {
  const dispatch = useAppDispatch();
  const assignedCustomerIndexes = useAppSelector(
    (state) => state.admin.selectedUser?.assignedCustomerIndexes
  );

  const [customers, setCustomers] = useState<CustomerIndex[]>([]);
  const [assignedCustomerIndex, setAssignedCustomerIndex] = useState<
    CustomerIndex[] | undefined
  >(assignedCustomerIndexes);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const searchRef = useRef<HTMLSelectElement>(null);
  const customersRef = useRef<HTMLSelectElement | null>(null);
  const { ref, ...rest } = register("assignedCustomerIds");

  const searchHandler = () => {
    if (!searchTerm) return;
    setLoading(true);
    dispatch(searchCustomers(searchTerm))
      .unwrap()
      .then((results) => {
        setLoading(false);
        setCustomers(results);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const isCustomerInactive = (id: string) => {
    return assignedCustomerIndexes?.find(
      (item) => item.id === id && item.inactive === true
    );
  };

  return (
    <>
      <div css={tw`flex items-center`}>
        <Input
          name="searchTerm"
          value={searchTerm}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              searchHandler();
            }
          }}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button
          disabled={loading || !searchTerm}
          css={tw`m-0 py-[3px] text-xs ml-4`}
          type="button"
          onClick={searchHandler}
        >
          Search
        </Button>
      </div>
      <p>Enter customer name or id</p>
      <div css={tw`flex mt-4`}>
        <div css={tw`w-[30%]`}>
          <p css={tw`text-xs`}>Enter customer or id</p>
          <select
            ref={searchRef}
            multiple
            css={[tw`w-full h-20 p-1`]}
          >
            {loading && <option>Searching...</option>}
            {!loading && customers.length === 0 && (
              <option>No results found</option>
            )}
            {!loading &&
              customers.map((item, index) => (
                <option key={index} value={item.id}>
                  {!item.shipToPricing && `${item.id} - ${item.name} (${item.city}, ${item.state})`}
                  {item.shipToPricing && `${item.id} (${item.classification}) - ${item.name} (${item.city}, ${item.state})`}
                </option>
              ))}
          </select>
        </div>
        <div css={tw`px-8 text-center flex flex-col justify-center gap-4 pt-3`}>
          <Button
            css={tw`m-0 block text-xs font-normal px-2 py-1`}
            type="button"
            onClick={() => {
              if (!searchRef.current?.selectedOptions) return;
              const ids = Array.from(
                searchRef.current?.selectedOptions,
                (option) => option.value
              );
              setAssignedCustomerIndex((prev) => [
                ...(prev ?? []),
                ...customers.filter(
                  (customer) =>
                    ids.includes(customer.id) &&
                    !prev?.find((cust) => customer.id === cust.id)
                ),
              ]);
              dispatch(assignCustomerToUser(ids));
            }}
          >
            Assign
          </Button>
          <Button
            css={tw`m-0 block text-xs font-normal px-2 py-1`}
            type="button"
            onClick={() => {
              if (!customersRef.current?.selectedOptions) return;
              const ids = Array.from(
                customersRef.current?.selectedOptions,
                (option) => option.value
              );
              setAssignedCustomerIndex((prev) =>
                prev?.filter((item) => !ids.includes(item.id))
              );
              dispatch(removeCustomerFromUser(ids));
            }}
          >
            Remove
          </Button>
        </div>
        <div css={tw`w-[30%]`}>
          <p css={tw`text-xs`}>Assigned customers</p>
          <select
            ref={(e) => {
              ref(e);
              customersRef.current = e;
            }}
            multiple
            css={[tw`h-20 w-full p-1`]}
            {...rest}
          >
            {assignedCustomerIndex?.map((item, index) => (
              <option css={[isCustomerInactive(item.id) && tw`text-red-500`]} key={index} value={item.id}>
                  { !item.shipToPricing && `${item.id} - ${item.name} (${item.city}, ${item.state})`}
                  { item.shipToPricing && `${item.id} (${item.classification}) - ${item.name} (${item.city}, ${item.state})`}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
};

export default AssignCustomers;
