/** @jsxImportSource @emotion/react */
import React, { ReactNode, useContext, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import ButtonSelect from "../../../../components/atoms/ButtonSelect";
import Select from "../../../../components/atoms/Select";
import TabGroupSubGroup from "../../../../components/atoms/TabGroupSubGroup";
import { selectCurrentCustomerId } from "../../../../store/customer.reducer";
import { searchInvoiceDocuments } from "../../../../store/document.actions";
import {
  clearDocumentErrors,
  selectInvoiceDocumentsSelectionData,
  updateInvoiceSearchCriteria,
} from "../../../../store/document.reducer";
import { selectDefaultMill, selectCurrentAuthorities } from "../../../../store/user.reducer";
import Toast from "../../../../components/molecules/Toast";
import { PDFContext } from "./InvoicesList";
import { useSearchParams } from "react-router-dom";
import { hasAuthority } from "../../../../helpers/hasAuthority";
import { SearchCriteria } from "../../../../types/types";

type InvoiceDocumentsBrowseCriteria = {
  dateRangeType?: string;
  shipToCustomerId?: string;
  invoiceSearchStatus?: string;
  siteKey?: string;
  sites?: string[];
  salesPersonCode?: string
  customerSearchType?: string;
  myCustomers?: boolean
};

type Props = {
  children?: ReactNode;
};

const InvoicesBrowse: React.FC<Props> = () => {
  const dispatch = useAppDispatch();

  const currentAuthorities = useAppSelector(selectCurrentAuthorities);
  const isInternal = hasAuthority(["internal"], currentAuthorities);
  
  const customerId = useAppSelector(selectCurrentCustomerId);
  const defaultMill = useAppSelector(selectDefaultMill);
  const invoiceDocumentsSelectionData = useAppSelector(
    selectInvoiceDocumentsSelectionData
  );
  const { searchCriteria, error } = useAppSelector(
    (state) => state.document.invoices
  );
  const ctx = useContext(PDFContext);

  const [searchParams] = useSearchParams();

  const checkForAllCustomers = (value: string | undefined) => {
    return ("true" == value || "rep" == value);
  };

  const getSites = (siteKey: string | undefined) : string[] | undefined => {
    if (!siteKey || !invoiceDocumentsSelectionData) {
      return undefined;
    }
    return siteKey === "ALL" ? undefined : invoiceDocumentsSelectionData.sites.find((item) => item.key === siteKey)?.sites;
  };

  const getBrowseCriteria = () : InvoiceDocumentsBrowseCriteria  => {
    const searchTypeSet = undefined != searchCriteria.customerSearchType;
    const repParam = (isInternal && !searchTypeSet) ? searchParams.get("salesRep") : undefined;
    const statusParam = (isInternal && !searchTypeSet) ? searchParams.get("status") : undefined;
    const dateParam = (isInternal && !searchTypeSet) ? searchParams.get("dateRange") : undefined;
    const siteParam = (isInternal && !searchTypeSet) ? searchParams.get("site") : undefined;

    const customerSearchType = repParam ? "rep" : searchCriteria.customerSearchType ?? "false";
    const myCustomers = checkForAllCustomers(customerSearchType);
    const salesPersonCode = repParam ?? searchCriteria.salesPersonCode ?? "";
    const invoiceSearchStatus = statusParam ?? searchCriteria.invoiceSearchStatus ?? 'all';
    const siteKey = siteParam ?? searchCriteria.siteKey ?? defaultMill ?? "ALL";
    const dateRangeType = dateParam ?? searchCriteria.dateRangeType ?? "last30Days";
    const shipToCustomerId = searchCriteria.shipToCustomerId ?? "all";

    const criteria = {customerSearchType, myCustomers, shipToCustomerId, salesPersonCode, invoiceSearchStatus, siteKey, dateRangeType};
  
    return criteria;
  };

  const getQueryCriteria = (browseCriteria: InvoiceDocumentsBrowseCriteria) : SearchCriteria => {
    const {customerSearchType, shipToCustomerId, salesPersonCode, invoiceSearchStatus, siteKey, dateRangeType} = browseCriteria;

    const criteria = {
      ...searchCriteria,
      customerSearchType,
      myCustomers: checkForAllCustomers(customerSearchType),
      invoiceSearchStatus,
      dateRangeType,
      shipToCustomerId:
        shipToCustomerId === "all" || checkForAllCustomers(customerSearchType)
          ? undefined
          : shipToCustomerId,
      siteKey: siteKey,
      sites: getSites(siteKey),
      salesPersonCode: 
        salesPersonCode === "" || customerSearchType !== "rep" 
        ? undefined
        : salesPersonCode
    };

    return criteria;
  };

  const browseCriteria = getBrowseCriteria();
  const {customerSearchType, shipToCustomerId, salesPersonCode, invoiceSearchStatus, siteKey, dateRangeType} = browseCriteria;
  const criteria = getQueryCriteria(browseCriteria);

  const shipTos =
  invoiceDocumentsSelectionData?.shipTos.map((shipTo) => {
    return {
      value: shipTo.id,
      label: <span>{`${shipTo.id} - ${shipTo.name}`}</span>,
    };
  }) ?? [];

  const salesReps =
  invoiceDocumentsSelectionData?.salesReps.map((rep) => {
    return {
      value: rep.key,
      label: rep.contactName
    };
  }) ?? [];

  const checkSalesRep = (value: string | undefined) => {
    const rep = salesReps.find((rep) => rep.value === value);
    return rep !== undefined;
  };

  const checkStatus = (value: string | undefined) => {
    const statuses = invoiceDocumentsSelectionData?.invoiceStatuses ?? [];
    const status = statuses.find((s) => s.id === value);
    return status !== undefined;
  };

  const checkDateRange = (value: string | undefined) => {
    const dateRanges = invoiceDocumentsSelectionData?.dateSelections ?? [];
    const dateRange = dateRanges.find((dr) => dr.id === value);
    return dateRange !== undefined;
  };

  const checkSite = (value: string | undefined) => {
    const sites = invoiceDocumentsSelectionData?.sites ?? [];
    const site = sites.find((s) => s.key === value);
    return site !== undefined;
  };

  const search = (criteria: SearchCriteria) => {
    if (criteria.customerSearchType === "rep" && criteria.salesPersonCode !== undefined && !checkSalesRep(criteria.salesPersonCode)) {
      return;
    }

    if (!checkStatus(criteria.invoiceSearchStatus)) {
      return;
    }

    if (!checkDateRange(criteria.dateRangeType)) {
      return;
    }

    if (criteria.siteKey !== "ALL" && !checkSite(criteria.siteKey)) {
      return;
    }
    
    dispatch(updateInvoiceSearchCriteria({ ...criteria, page: 1}));
    dispatch(searchInvoiceDocuments({customerId, criteria: { ...criteria, customerSearchType: undefined, siteKey: undefined }}));
  };

  useEffect(() => {
    if (invoiceDocumentsSelectionData) {
      const criteria = getQueryCriteria(getBrowseCriteria());
      search(criteria);
    }
  }, [invoiceDocumentsSelectionData]);
  
  const onSelectionsChanged = (criteria: InvoiceDocumentsBrowseCriteria) => {
    // Close pdf if open
    if (ctx?.file) {
      ctx?.setFile(null);
    }
    search(criteria);
  };

  return (
    <>
      {error && (
        <Toast
          type="error"
          message={error}
          onConfirm={() => dispatch(clearDocumentErrors())}
        />
      )}
      <TabGroupSubGroup title="Show">
        <Select
          minWidth="22ch"
          name="showBy"
          data={invoiceDocumentsSelectionData?.customerSearchType.map(
            (item) => ({
              value: item.id,
              label: item.description,
            })
          )}
          onChange={(value: string) => {
            onSelectionsChanged({
              ...criteria,
              customerSearchType: value,
              myCustomers: checkForAllCustomers(customerSearchType)
            });
          }}
          value={customerSearchType}
        />
      </TabGroupSubGroup>
      {!checkForAllCustomers(customerSearchType) && (
        <TabGroupSubGroup title="By Ship To">
          <Select
            minWidth="22ch"
            maxWidth="22ch"
            name="byShipTo"
            data={[{ value: "all", label: "All" }, ...shipTos]}
            onChange={(value: string) => {
              onSelectionsChanged({
                ...criteria,
                shipToCustomerId: value
              });
            }}
            value={shipToCustomerId}
          />
        </TabGroupSubGroup>
      )}
      {"rep" === customerSearchType && (
        <TabGroupSubGroup title="By Sales Rep">
          <Select
            minWidth="22ch"
            maxWidth="22ch"
            name="bySalesRep"
            data={[{ value: "", label: "All" }, ...salesReps]}
            onChange={(value: string) => {
              onSelectionsChanged({
                ...criteria,
                salesPersonCode: value
              });
            }}
            value={salesPersonCode}
          />
        </TabGroupSubGroup>
      )}

      <TabGroupSubGroup title="By Invoice Date">
        {invoiceDocumentsSelectionData?.dateSelections.map((date, index) => {
          return (
            <ButtonSelect
              key={index}
              onClick={() => {
                onSelectionsChanged({
                  ...criteria,
                  dateRangeType: date.id,
                });
              }}
              isActive={dateRangeType === date.id}
            >
              {date.description}
            </ButtonSelect>
          );
        })}
      </TabGroupSubGroup>
      <TabGroupSubGroup title="By Mill">
        <ButtonSelect
          isActive={siteKey === "ALL"}
          onClick={() => {
            onSelectionsChanged({
              ...criteria,
              siteKey: "ALL",
              sites: undefined,
            });
          }}
        >
          All
        </ButtonSelect>
        {invoiceDocumentsSelectionData?.sites.map((item, index) => {
          return (
            <ButtonSelect
              isActive={siteKey === item.key}
              onClick={() => {
                onSelectionsChanged({
                  ...criteria,
                  siteKey: item.key,
                  sites: getSites(item.key),
                });
              }}
              key={index}
            >
              {item.description}
            </ButtonSelect>
          );
        })}
      </TabGroupSubGroup>
      <TabGroupSubGroup title="By Invoice Status">
        {invoiceDocumentsSelectionData?.invoiceStatuses.map((status, index) => (
          <ButtonSelect
            key={index}
            isActive={invoiceSearchStatus === status.id}
            onClick={() => {
              onSelectionsChanged({
                ...criteria,
                invoiceSearchStatus: status.id,
              });
            }}
          >
            {status.description}
          </ButtonSelect>
        ))}
      </TabGroupSubGroup>
    </>
  );
};

export default InvoicesBrowse;
