import React, { ReactNode } from "react";
import { UseFormRegister } from "react-hook-form";
import { AddUserForm } from "../add_user/addUserForm";
import { Authority } from "../../../../types/types";
import { PersonalInfoForm } from "../edit_user/personalInfoForm";

type Props = {
  children?: ReactNode;
  externalAuthorities?: { id: Authority; description: string }[];
  register: UseFormRegister<AddUserForm | PersonalInfoForm>;
};

const Authorities: React.FC<Props> = ({ externalAuthorities, register }) => {
  return (
    <>
      {externalAuthorities?.map((authority) => (
        <div className="flex items-center gap-2 my-1" key={authority.id}>
          <input
            className="hover:cursor-pointer disabled:cursor-default peer"
            id={authority.id}
            type="checkbox"
            {...register(authority.id)}
          />
          <label
            className="hover:cursor-pointer peer-disabled:cursor-default"
            htmlFor={authority.id}
          >
            {authority.description}
          </label>
        </div>
      ))}
    </>
  );
};

export default Authorities;
