/** @jsxImportSource @emotion/react */
import React, { ReactNode, useContext, useState } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import TextButton from "../../../../components/atoms/TextButton";
import {
  exportPackingSlipPDF,
  ExportPackingSlipPDFRequest,
  fetchInvoiceDocumentDetailPDF,
  FetchInvoiceDocumentDetailPDFRequest,
  fetchOrderDocumentDetailPDF,
  FetchOrderDocumentDetailPDFRequest,
  searchInvoiceDocuments,
} from "../../../../store/document.actions";
import { invoiceSort, updateInvoicePagination } from "../../../../store/document.reducer";
import Toast from "../../../../components/molecules/Toast";
import { formatDateToShort } from "../../../../helpers/dateHelpers";
import { currencyFormatter } from "../../../../helpers/currencyFormatter";
import { hasAuthority } from "../../../../helpers/hasAuthority";
import { selectCurrentAuthorities } from "../../../../store/user.reducer";
import { PDFContext } from "./InvoicesList";
import { selectCurrentCustomerId } from "../../../../store/customer.reducer";
import { getNewSort } from "../../../../helpers/getNewSort";
import Pagination, { PaginationValues } from "../../../../components/molecules/PaginationComponent";
import Loading from "../../../../components/atoms/Loading";
import TableSortButton from "../../../../components/molecules/TableSortButton";

type Props = {
  children?: ReactNode;
};

const InvoicesResults: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const currentAuthorities = useAppSelector(selectCurrentAuthorities);
  const { searchCriteria, status, invoiceResults } = useAppSelector((state) => state.document.invoices);

  const customerId = useAppSelector(selectCurrentCustomerId);

  const [psStatus, setPsStatus] = useState("idle");
  const [invoiceStatus, setInvoiceStatus] = useState("idle");
  const [orderStatus, setOrderStatus] = useState("idle");
  const [errorMessage, setErrorMessage] = useState("");

  const ctx = useContext(PDFContext);

  const loading = (status === "pending") || (psStatus === "pending") || (invoiceStatus === "pending") || (orderStatus === "pending");

  const packingSlipPDFHandler = (params: ExportPackingSlipPDFRequest) => {
    setPsStatus("pending");
    setErrorMessage("");
    dispatch(exportPackingSlipPDF(params))
      .unwrap()
      .then((result) => {
        ctx?.setFile?.(result);
        setPsStatus("success");
      })
      .catch((error) => {
        setPsStatus("error");
        setErrorMessage(error);
      });
  };

  const invoicePDFHandler = (params: FetchInvoiceDocumentDetailPDFRequest) => {
    setInvoiceStatus("pending");
    setErrorMessage("");
    dispatch(fetchInvoiceDocumentDetailPDF(params))
      .unwrap()
      .then((result) => {
        ctx?.setFile?.(result);
        setInvoiceStatus("success");
      })
      .catch((error) => {
        setInvoiceStatus("error");
        setErrorMessage(error);
      });
  };

  const viewOrderHandler = (params: FetchOrderDocumentDetailPDFRequest) => {
    setOrderStatus("pending");
    setErrorMessage("");
    dispatch(fetchOrderDocumentDetailPDF(params))
      .unwrap()
      .then((result) => {
        setOrderStatus("success");
        ctx?.setFile?.(result);
      })
      .catch((error) => {
        setOrderStatus("error");
        setErrorMessage(error);
      });
  };

  const sortChanged = (field: string) => {
    if (!customerId || !invoiceResults?.lastCriteria) return;
    
    const searchSort = getNewSort(field, searchCriteria);

    const c = {...invoiceResults.lastCriteria, ...searchSort, page: searchCriteria.page};

    dispatch(invoiceSort(searchSort))
    dispatch(searchInvoiceDocuments({customerId, criteria: c}));
  };

  const pageChanged = (page: PaginationValues) => {
    if (!customerId || !invoiceResults?.lastCriteria) return;
    
    const c = {...invoiceResults.lastCriteria, ...page};

    dispatch(updateInvoicePagination(page))
    dispatch(searchInvoiceDocuments({customerId, criteria: c}));
  };

  return (
    <>
      {errorMessage && (
        <Toast
          type="error"
          message={errorMessage}
          onConfirm={() => setErrorMessage("")}
        />
      )}
      <Pagination
        currentPage={searchCriteria.page}
        isLoading={status === "pending"}
        items={invoiceResults?.count ?? 0}
        onChange={(value) => pageChanged(value)}
      />
      <div css={tw`relative`}>
        {loading && <Loading />}
        <table className="ntp-portal-table" css={tw`w-full text-xs`}>
          <thead>
            <tr>
              <th>
                <TableSortButton field="invoiceDate" sort={searchCriteria} onClick={(field) => sortChanged(field)}>
                  Invoice Date
                </TableSortButton>
              </th>
              <th>
                <TableSortButton field="billingCustomerId" sort={searchCriteria} onClick={(field) => sortChanged(field)}>
                  Customer
                </TableSortButton>
              </th>
              <th>
                <TableSortButton field="invoiceNumber" sort={searchCriteria} onClick={(field) => sortChanged(field)}>
                  Invoice #
                </TableSortButton>
              </th>
              <th>Amount</th>
              <th>Balance</th>
              <th>Mill</th>
              <th>PO Number</th>
              <th>Bill of Lading</th>
              <th>Order #</th>
            </tr>
          </thead>
          <tbody>
            {invoiceResults?.list && invoiceResults.list.length > 0 ? (
              invoiceResults?.list.map((invoice, index) => (
                <tr key={index}>
                  <td>
                    {formatDateToShort(invoice.invoiceDate)}
                  </td>
                  <td css={tw`max-w-[350px]`}>{`${invoice.billingCustomerId} - ${invoice.customerName}`}</td>
                  <td>
                    <TextButton
                      disabled={loading}
                      onClick={() => {
                        invoicePDFHandler({
                          customerId: invoice.billingCustomerId,
                          id: invoice.invoiceNumber,
                        });
                      }}
                      key={index}
                    >
                      {invoice.invoiceNumber}
                    </TextButton>
                  </td>
                  <td css={tw`text-right`}>{currencyFormatter(invoice.totalAmount)} {invoice.currencyDisplay}</td>
                  <td css={tw`text-right`}>
                    {(invoice.balance && invoice.balance != 0) ? (<>{currencyFormatter(invoice.balance)} {invoice.currencyDisplay}</>) : ""}
                  </td>
                  <td>{invoice.site}</td>
                  <td>
                    {invoice.customerPONumberList?.map((item) => (
                      <p key={item}>{item}</p>
                    ))}
                  </td>
                  <td>
                    {invoice.bolNumberList?.map((item) => (
                      <TextButton
                        key={item}
                        disabled={
                          loading ||
                          !hasAuthority("viewShippingDocs", currentAuthorities)
                        }
                        onClick={() =>
                          packingSlipPDFHandler({
                            customerId: invoice.billingCustomerId,
                            id: item,
                          })
                        }
                        css={tw`block`}
                      >
                        {item}
                      </TextButton>
                    ))}
                  </td>
                  <td>
                    {invoice.salesOrderNumberList?.map((item) => (
                      <TextButton
                        key={item}
                        disabled={loading}
                        onClick={() =>
                          viewOrderHandler({
                            customerId: invoice.billingCustomerId,
                            id: item,
                          })
                        }
                        css={tw`block`}
                      >
                        {item}
                      </TextButton>
                    ))}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td css={tw`text-nucor-gray !px-2 !py-3 hover:!bg-white`} colSpan={12}>
                No invoices found for the selected criteria
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default InvoicesResults;
