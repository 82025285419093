/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import { ErrorResponse } from "../../types/types";
import tw from "twin.macro";

type Props = {
  children?: ReactNode;
  error?: string | ErrorResponse;
  hideTitle?: boolean;
};

const ErrorDisplay: React.FC<Props> = ({ error, hideTitle }) => {
  if (!error) return null;

  if (typeof error === "string") {
    return <p css={tw`text-red-600 text-xs`}>{error}</p>;
  }

  const errorAsObject = error as ErrorResponse;
  return (
    <div css={tw`text-xs`}>
      {!hideTitle && (
        <p css={tw`text-red-600 font-semibold`}>{errorAsObject?.message}</p>
      )}
      <ul css={tw`text-red-600 ml-4 list-disc`}>
        {errorAsObject?.validations?.map((err, index) => (
          <li key={index}>{err.message}</li>
        ))}
      </ul>
    </div>
  );
};

export default ErrorDisplay;
