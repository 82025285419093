/** @jsxImportSource @emotion/react */
import React, { FormEvent, ReactNode, useState } from "react";
import tw from "twin.macro";
import Input from "../../../../components/atoms/Input";
import Button from "../../../../components/atoms/Button";
import Select from "../../../../components/atoms/Select";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { fetchDocument } from "../../../../store/document.actions";
import { Authority, DocumentType, PDFFile } from "../../../../types/types";
import Toast from "../../../../components/molecules/Toast";
import { selectCurrentAuthorities } from "../../../../store/user.reducer";
import { hasAuthority } from "../../../../helpers/hasAuthority";

type Props = {
  children?: ReactNode;
  setFile: (file: PDFFile | null) => void;
};

type SelectionsType = {
  id: string;
  description: string;
  authority?: Authority | Authority[];
};

const selections: SelectionsType[] = [
  { id: "", description: "Select" },
  { id: "invoice", description: "Invoice", authority: ["viewOrderInvoice"] },
  { id: "quote", description: "Quote", authority: ["viewOrderInvoice"] },
  { id: "portalquote", description: "Portal Quote Detail", authority: ["viewOrderInvoice"] },
  { id: "portalquotesummary", description: "Portal Quote Summary", authority: ["viewOrderInvoice"] },
  { id: "order", description: "Order", authority: ["viewOrderInvoice"] },
  {
    id: "testcert",
    description: "Test Certificate",
    authority: ["viewShippingDocs"],
  },
  {
    id: "packingslip",
    description: "Bills of Lading",
    authority: ["viewShippingDocs"],
  },
  {
    id: "load",
    description: "Load",
    authority: ["viewShippingDocs"],
  },
];

const DocumentSearchLeft: React.FC<Props> = ({ setFile }) => {
  const dispatch = useAppDispatch();
  const currentAuthorities = useAppSelector(selectCurrentAuthorities);
  const { status } = useAppSelector((state) => state.document.docSearch);

  const [documentType, setDocumentType] = useState<DocumentType | "">("");
  const [documentNumber, setDocumentNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const docTypeData = selections.filter((item) => {
    if (!item.authority) return true;
    return hasAuthority(item.authority, currentAuthorities);
  });

  const submitHandler = (e: FormEvent) => {
    setFile(null);
    e.preventDefault();
    if (documentType === "") return;
    setErrorMessage("");
    dispatch(
      fetchDocument({
        id: documentNumber,
        documentType: documentType,
      })
    )
      .unwrap()
      .then((result) => {
        setFile(result);
        setErrorMessage("");
      })
      .catch((error) => {
        setErrorMessage(error);
      });
  };
  return (
    <>
      <div css={[tw`text-xs relative w-full`]}>
        {errorMessage && (
          <Toast
            type="error"
            message={errorMessage}
            onConfirm={() => setErrorMessage("")}
          />
        )}
        {successMessage && (
          <Toast
            type="success"
            message={successMessage}
            onConfirm={() => setSuccessMessage("")}
          />
        )}
        <div css={[tw`flex gap-1`]}>
          <div css={[tw`py-1 px-2 bg-[#ececec] text-nucor-gray font-bold`]}>
            Search
          </div>
        </div>
        <div css={tw`w-full bg-[#ececec] p-2`}>
          <form onSubmit={submitHandler} css={tw`text-xs`}>
            <fieldset>
              <div css={tw`mt-2`}>
                <label css={tw`block`}>Document Type</label>
                <Select
                  name="documentType"
                  minWidth="23ch"
                  value={documentType}
                  onChange={setDocumentType}
                  data={docTypeData.map((item) => ({
                    value: item.id,
                    label: item.description,
                  }))}
                />
              </div>
              <div css={tw`mt-2`}>
                <label htmlFor="customerPO" css={tw`block`}>Document #</label>
                <Input
                  name="documentNumber"
                  value={documentNumber}
                  onChange={(e) => setDocumentNumber(e.target.value)}
                  css={tw`block w-[23ch]`}
                />
              </div>
            </fieldset>
            <div css={tw`w-full text-right`}>
              <Button
                css={tw`m-0 py-[2px] font-normal text-xs mt-3`}
                type="submit"
                disabled={
                  documentNumber === "" ||
                  documentType === "" ||
                  status === "pending"
                }
              >
                Search
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default DocumentSearchLeft;
