/** @jsxImportSource @emotion/react */
import React, { HTMLAttributes, ReactNode, useEffect, useState } from "react";
import tw from "twin.macro";
import {
  DocumentType,
  ErrorResponse,
  PDFFile,
  RequestStatus,
} from "../../types/types";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  FetchRelatedDocsResponse,
  fetchDocument,
  fetchRelatedDocs,
} from "../../store/document.actions";
import Spinner from "../atoms/Spinner";
import { labelFromDocType } from "../../helpers/docTypeLabels";
import { selectCurrentCustomerId } from "../../store/customer.reducer";
import Toast from "./Toast";
import { isErrorResponse } from "../../types/predicates";

type Props = {
  children?: ReactNode;
  showAsModal?: boolean;
  onCancel: () => void;
  file: PDFFile;
  setFile: React.Dispatch<React.SetStateAction<PDFFile | null>>;
};

declare global {
  interface Window {
    AdobeDC: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      View: any;
    };
  }
}

const Group = ({
  title,
  children,
  ...remainingProps
}: { title: string } & HTMLAttributes<HTMLDivElement>) => {
  return (
    <div>
      <div
        css={tw`uppercase text-white font-bold bg-nucor-green text-xs py-1 px-2 flex`}
        {...remainingProps}
      >
        {title}
      </div>
      {children}
    </div>
  );
};

const PDFEmbed: React.FC<Props> = ({
  file,
  onCancel,
  showAsModal,
  setFile,
}) => {
  const dispatch = useAppDispatch();
  const customerId = useAppSelector(selectCurrentCustomerId);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [relatedDocs, setRelatedDocs] =
    React.useState<FetchRelatedDocsResponse>();
  const [relatedDocsStatus, setRelatedDocsStatus] =
    React.useState<RequestStatus>("idle");
  const [error, setError] = useState<ErrorResponse | string>("");

  const fetchRelatedDocsHandler = async () => {
    try {
      setRelatedDocsStatus("pending");
      const data = await dispatch(
        fetchRelatedDocs({
          documentType: file?.documentType,
          id: file?.id,
        })
      ).unwrap();
      setRelatedDocs(data);
    } catch (error) {
      console.log(error);
    } finally {
      setRelatedDocsStatus("idle");
    }
  };

  const displayPDFHandler = () => {
    if (window.AdobeDC && file?.fileUrl && file?.fileName) {
      const adobeDCView = new window.AdobeDC.View({
        clientId: process.env.REACT_APP_ADOBE_CLIENT_ID,
        divId: "adobe-dc-view",
      });
      adobeDCView.previewFile(
        {
          content: {
            location: {
              url: file.fileUrl,
            },
          },
          metaData: { fileName: file.fileName },
        },
        { showAnnotationTools: false }
      );
      if (!showAsModal) {
        containerRef.current?.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const fetchPDFHandler = async (id: string, documentType: DocumentType) => {
    try {
      setRelatedDocsStatus("pending");
      setError("");
      const result = await dispatch(
        fetchDocument({ id, documentType, customerId })
      ).unwrap();
      setFile?.(result);
    } catch (error) {
      if (typeof error === "string" || isErrorResponse(error)) {
        setError(error);
      }
    } finally {
      setRelatedDocsStatus("idle");
    }
  };

  useEffect(() => {
    fetchRelatedDocsHandler();
    displayPDFHandler();
  }, [file?.fileUrl]);

  const sections = Object.keys(relatedDocs?.relatedKeysMap ?? {});

  const embeddedPDF = (
    <>
      {error && (
        <Toast onConfirm={() => setError("")} type="error" message={error} />
      )}
      <div
        ref={containerRef}
        className="w-full mx-auto grid grid-cols-12 mt-2 min-h-[800px]"
      >
        <div id="adobe-dc-view" className="col-span-9 border" />
        <div className="bg-white h-full col-span-3 border border-l-2 p-2 pl-4 relative">
          {relatedDocsStatus === "pending" && (
            <div className="bg-[#00000033] absolute inset-0">
              <Spinner />
            </div>
          )}
          <div>
            <h3 className="font-bold">Related Documents:</h3>
            {showAsModal && (
              <button
                onClick={onCancel}
                className="border rounded-md font-bold px-2 py-[2px] bg-nucor-green text-white"
              >
                &times;
              </button>
            )}
          </div>
          {sections?.length === 0 && <span className="text-xs">No related documents found</span>}
          {sections?.map((section) => (
            <Group
              key={section}
              className="mt-4"
              title={labelFromDocType(section as DocumentType)}
            >
              {relatedDocs?.relatedKeysMap[section]?.map((doc) => (
                <button
                  key={doc.documentNumber}
                  onClick={() =>
                    fetchPDFHandler(doc.documentNumber, doc.documentType)
                  }
                  className="link-style block text-xs leading-relaxed"
                >
                  {doc.documentNumber}
                </button>
              ))}
            </Group>
          ))}
        </div>
      </div>
    </>
  );

  return embeddedPDF;
};

export default PDFEmbed;
