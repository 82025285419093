import * as Yup from "yup";

const digitsOnly = (value: string | undefined) => {
  if (!value) return false;
  return /^\d+$/.test(value);
};
Yup.addMethod(Yup.string, "integer", function () {
  return this.matches(/^\d+$/, "The field should have digits only");
});

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().required("Email is required").email("Email is invalid"),
  title: Yup.string().required("Job Title is required"),
  organization: Yup.string().required("Organization is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  notes: Yup.string(),
  workPhone: Yup.object({
    areaCode: Yup.string()
      .required("Area code is invalid")
      .test("Digits only", "area code have digits only", digitsOnly)
      .test(
        "len",
        "area code must be exactly 3 numbers",
        (workPhoneAreaCode) => workPhoneAreaCode?.length === 3
      ),
    exchange: Yup.string()
      .required("Exchange code is invalid")
      .test("Digits only", "exchange code should have digits only", digitsOnly)
      .test(
        "len",
        "exchange code must be exactly 3 numbers",
        (workPhoneExchange) => workPhoneExchange?.length === 3
      ),
    local4: Yup.string()
      .required("local code is invalid")
      .test("Digits only", "local code should have digits only", digitsOnly)
      .test(
        "len",
        "local code must be exactly 4 numbers",
        (workPhoneLocal4) => workPhoneLocal4?.length === 4
      ),
  }),
  supervisor: Yup.string().when("requestFor", {
    is: "NucorEmployee",
    then: Yup.string().required("Supervisor is required"),
  }),
  salesRepCode: Yup.string().when("requestFor", {
    is: "CurrentCustomer",
    then: Yup.string().test(
      "not select",
      "Sales rep is required",
      (salesRepCode) => salesRepCode !== "select"
    ),
  }),
});
