/** @jsxImportSource @emotion/react */
import React, { ReactNode, useContext, useState } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Pagination, { PaginationValues } from "../../../../components/molecules/PaginationComponent";
import {
  quoteSort,
  updateQuotePagination,
} from "../../../../store/document.reducer";
import TextButton from "../../../../components/atoms/TextButton";
import { fetchQuotePDF, fetchQuoteResults } from "../../../../store/document.actions";
import { selectCurrentCustomerId } from "../../../../store/customer.reducer";
import Loading from "../../../../components/atoms/Loading";
import TableSortButton from "../../../../components/molecules/TableSortButton";
import Toast from "../../../../components/molecules/Toast";
import { formatDateToShort } from "../../../../helpers/dateHelpers";
import { PDFContext } from "./InquiryList";
import { numberFormatter } from "../../../../helpers/numberFormat";
import { getNewSort } from "../../../../helpers/getNewSort";

type Props = {
  children?: ReactNode;
};

const InquiryResults: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const customerId = useAppSelector(selectCurrentCustomerId);
  const { searchCriteria, status, quoteResults } = useAppSelector((state) => state.document.quote);
  const ctx = useContext(PDFContext);

  const [errorMessage, setErrorMessage] = useState("");

  const clickHandler = (quoteCustomerId: string, quoteNumber: string) => {
    if (!customerId) return;
    setErrorMessage("");
    dispatch(fetchQuotePDF({ customerId: quoteCustomerId, id: quoteNumber }))
      .unwrap()
      .then((result) => {
        ctx?.setFile(result);
        setErrorMessage("");
      })
      .catch((error) => setErrorMessage(error));
  };

  const sortChanged = (field: string) => {
    if (!customerId || !quoteResults?.lastCriteria) return;
    
    const searchSort = getNewSort(field, searchCriteria);

    const c = {...quoteResults.lastCriteria, ...searchSort, page: searchCriteria.page};

    dispatch(quoteSort(searchSort))
    dispatch(fetchQuoteResults({customerId, criteria: c}));
  };

  const pageChanged = (page: PaginationValues) => {
    if (!customerId || !quoteResults?.lastCriteria) return;
    
    const c = {...quoteResults.lastCriteria, ...page};

    dispatch(updateQuotePagination(page))
    dispatch(fetchQuoteResults({customerId, criteria: c}));
  };

  return (
    <>
      {errorMessage && (
        <Toast
          type="error"
          message={errorMessage}
          onConfirm={() => setErrorMessage("")}
        />
      )}
      <Pagination
        currentPage={searchCriteria.page}
        items={quoteResults?.count ?? 0}
        isLoading={status === "pending"}
        onChange={(value) => pageChanged(value)}
      />
      <div css={tw`relative`}>
        {status === "pending" && <Loading />}
        <table className="ntp-portal-table" css={tw`w-full text-xs`}>
          <thead>
            <tr>
              <th>
                <TableSortButton
                  sort={searchCriteria}
                  field="quoteDate"
                  onClick={(field) => sortChanged(field)}
                >
                  Inquiry Date
                </TableSortButton>
              </th>
              <th>
                <TableSortButton
                  sort={searchCriteria}
                  field="shippingCustomerId"
                  onClick={(field) => sortChanged(field)}
                >
                  Ship To
                </TableSortButton>
              </th>
              <th>
                {" "}
                <TableSortButton
                  sort={searchCriteria}
                  field="quoteNumber"
                  onClick={(field) => sortChanged(field)}
                >
                  Inquiry Id
                </TableSortButton>
              </th>
              <th>
                {" "}
                <TableSortButton
                  sort={searchCriteria}
                  field="status"
                  onClick={(field) => sortChanged(field)}
                >
                  Status
                </TableSortButton>
              </th>
              <th>
                {" "}
                <TableSortButton
                  sort={searchCriteria}
                  field="totalWeight"
                  onClick={(field) => sortChanged(field)}
                >
                  Wgt Tot
                </TableSortButton>
              </th>
            </tr>
          </thead>
          <tbody>
            {quoteResults?.list && quoteResults?.list.length > 0 ? (
              quoteResults?.list.map((quote) => (
                <tr key={quote.quoteNumber}>
                  <td>{formatDateToShort(quote.quoteDate)}</td>
                  <td>{`${quote.shippingCustomerId} - ${quote.customerName}`}</td>
                  <td>
                    {quote.hideDocumentLink ? (
                      <span css={tw`font-semibold text-nucor-gray`}>
                        {quote.quoteNumber}
                      </span>
                    ) : (
                      <TextButton
                        disabled={status === "pending"}
                        onClick={() =>
                          clickHandler(quote.billingCustomerId, quote.quoteNumber)
                        }
                      >
                        {quote.quoteNumber}
                      </TextButton>
                    )}
                  </td>
                  <td>{quote.status}</td>
                  <td css={tw`text-right`}>{numberFormatter(quote.totalWeight)}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td css={tw`text-nucor-gray !px-2 !py-3 hover:!bg-white`} colSpan={5}>
                  No Inquiries found for the selected criteria
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default InquiryResults;
