/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import tw from "twin.macro";
import PrimeContent from "./inventory/PrimeContent";
import PrimeLeft from "./selection/PrimeLeft";
import PrimeRight from "./rolling/PrimeRight";
import ShipToCartHeader from "../../../../components/molecules/ShipToCartHeader";
import { useAppSelector } from "../../../../app/hooks";
import { selectCurrentAuthorities } from "../../../../store/user.reducer";

type Props = {
  children?: ReactNode;
};

const StockList: React.FC<Props> = () => {
  const { currentContext } = useAppSelector((state) => state.user);
  const currentAuthorities = useAppSelector(selectCurrentAuthorities);

  if (!currentContext) return null;

  return (
    <div css={[tw`flex p-2 px-1 w-full`]}>
      <div css={[tw`w-[220px] px-1 flex-shrink-0`]}>
        <PrimeLeft />
      </div>
      <div css={tw`w-full px-1`}>
        {currentAuthorities?.includes("createOrderQuoteLoad") && (
          <ShipToCartHeader />
        )}
        <div css={tw`flex w-full gap-2`}>
          <div css={[tw`flex-grow`]}>
            <PrimeContent />
          </div>
          <div css={[tw`w-[308px] flex-shrink-0`]}>
            <PrimeRight />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StockList;
