import { configureStore, createListenerMiddleware } from "@reduxjs/toolkit";
import adminReducer from "./admin.reducer";
import appReducer from "./app.reducer";
import customerReducer from "./customer.reducer";
import documentReducer from "./document.reducer";
import productReducer from "./product.reducer";
import shippingReducer from "./shipping.reducer";
import userReducer from "./user.reducer";
import partsReducer from "./parts.reducer";
import quoteCartReducer from "./quoteCart.reducer";
import rollingsReducer from "./rollings.reducer";
import itadminReducer from "./itadmin.reducer";
import shoppingCartReducer from "./shoppingCart.reducer";
import analyticsReducer from "./analytics.reducer";

const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
  predicate: (action) => {
    return (
      action.type === "customer/setCurrentCustomer" ||
      action.type === "customer/setCurrentCustomerDetails/fulfilled"
    );
  },
  effect: async (action) => {
    window.localStorage.setItem("ntpLastCustomer", action.payload?.id);
  },
});

const store = configureStore({
  reducer: {
    user: userReducer,
    product: productReducer,
    customer: customerReducer,
    admin: adminReducer,
    app: appReducer,
    shipping: shippingReducer,
    document: documentReducer,
    parts: partsReducer,
    rollings: rollingsReducer,
    itadmin: itadminReducer,
    shoppingCart: shoppingCartReducer,
    quoteCart: quoteCartReducer,
    analytics: analyticsReducer,
  },
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(listenerMiddleware.middleware),
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
