/** @jsxImportSource @emotion/react */
import React, { ReactNode, createContext, useEffect } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { selectCurrentCustomerId } from "../../../../store/customer.reducer";
import { fetchOpenOrdersSelectionData } from "../../../../store/shipping.actions";
import OpenOrdersContent from "./OpenOrdersContent";
import OpenOrdersLeft from "./OpenOrdersLeft";
import { resetOpenOrderSearchCriteria } from "../../../../store/shipping.reducer";
import { PDFFile } from "../../../../types/types";
import PDFEmbed from "../../../../components/molecules/PDFEmbed";
import TextButton from "../../../../components/atoms/TextButton";

type Props = {
  children?: ReactNode;
};

export const PDFContext = createContext<{
  file: PDFFile | null;
  setFile: React.Dispatch<React.SetStateAction<PDFFile | null>>;
} | null>(null);

const OpenOrders: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const customerId = useAppSelector(selectCurrentCustomerId);
  const [file, setFile] = React.useState<PDFFile | null>(null);

  useEffect(() => {
    if (!customerId) return;
    dispatch(fetchOpenOrdersSelectionData(customerId));
    return () => {
      dispatch(resetOpenOrderSearchCriteria());
    };
  }, [customerId]);

  if (!customerId)
    return (
      <div css={[tw`flex p-4 w-full`]}>
        <p css={tw`text-nucor-green text-xs font-bold`}>Select a customer</p>
      </div>
    );

  return (
    <PDFContext.Provider value={{ file, setFile }}>
      <div css={[tw`flex p-2 w-full`]}>
        <div css={[tw`w-[210px] px-1 flex-shrink-0`]}>
          <OpenOrdersLeft />
        </div>
        <div css={[tw`flex-grow px-1`, file && tw`hidden`]}>
          <OpenOrdersContent />
        </div>
        {file && (
          <div className="flex-1">
            <div css={tw`flex justify-between text-white font-bold bg-nucor-green text-xs py-1 px-2`}>
              <span css={tw`uppercase w-32`}>Open Orders</span>
              <TextButton onClick={() => setFile(null)} css={tw`text-white`}>
                Back to List
              </TextButton>
              <span className="w-32"></span>
            </div>
            <PDFEmbed
              onCancel={() => setFile(null)}
              setFile={setFile}
              file={file}
            />
          </div>
        )}
      </div>
    </PDFContext.Provider>
  );
};

export default OpenOrders;
