/** @jsxImportSource @emotion/react */
import React, { ReactNode, useEffect, useState } from "react";
import { fetchRollingSchedulePDF } from "../../store/product.actions";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { generateMonthList } from "../../helpers/generateMonthList";
import Toast from "./Toast";
import { ErrorResponse, RequestStatus } from "../../types/types";
import Modal from "./Modal";
import Select from "../atoms/Select";
import Button from "../atoms/Button";
import { fetchSiteResources } from "../../store/rollings.actions";
import AnimatedLoadingSpinner from "../atoms/AnimatedLoadingSpinner";

type Props = {
  children?: ReactNode;
  onCancel: () => void;
};

const RollingSchedule: React.FC<Props> = ({ onCancel }) => {
  const monthList = generateMonthList(6).map(({ month, year }) => ({
    label: `${month} ${year}`,
    value: `${month}-${year}`,
  }));
  const dispatch = useAppDispatch();
  const [siteList, setSiteList] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);
  const [status, setStatus] = useState<RequestStatus>("idle");
  const [selectedSite, setSelectedSite] = useState("ALL");
  const [selectedMonth, setSelectedMonth] = useState(monthList[0].value);
  const [error, setError] = useState<ErrorResponse | string>();

  const currentCustomer = useAppSelector(
    (state) => state.customer.currentCustomer
  );

  const submitHandler = async () => {
    setStatus("pending");
    const [month, year] = selectedMonth.split("-");
    const customerId = currentCustomer?.id;
    dispatch(
      fetchRollingSchedulePDF({
        customerId: customerId,
        month,
        year,
        site: selectedSite === "ALL" ? undefined : selectedSite,
      })
    )
      .unwrap()
      .then(() => {
        setStatus("idle");
        onCancel();
      })
      .catch((responseError) => {
        setError(responseError);
        setStatus("idle");
      });
  };

  useEffect(() => {
    setStatus("pending");
    const customerId = currentCustomer?.id;
    dispatch(fetchSiteResources(customerId))
      .unwrap()
      .then((results) => {
        setSiteList([
          { label: "All", value: "ALL" },
          ...results.map(({ site }) => ({ label: site, value: site })),
        ]);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => setStatus("idle"));
  }, []);

  return (
    <>
      {error && (
        <Toast
          message={error}
          type="error"
          onConfirm={() => setError(undefined)}
        />
      )}
      <Modal onCancel={onCancel} title="Rolling Schedule">
        <form className="p-1 px">
          <fieldset disabled={status !== "idle"} className="flex gap-4">
            <div className="flex flex-col gap-1">
              <label>Site</label>
              <Select
                minWidth="15ch"
                value={selectedSite}
                onChange={(val: string) => {
                  setSelectedSite(val);
                }}
                name="site"
                data={siteList}
              />
            </div>
            <div className="flex flex-col gap-1">
              <label>Month / Year</label>
              <Select
                value={selectedMonth}
                onChange={(val: string) => setSelectedMonth(val)}
                minWidth="17ch"
                name="month"
                data={monthList}
              />
            </div>
          </fieldset>
          <div className="mt-4 flex justify-end gap-2 items-center">
            {status === "pending" && (
              <AnimatedLoadingSpinner className="mr-auto" />
            )}
            <Button type="button" disabled={status !== "idle"} onClick={onCancel}>Cancel</Button>
            <Button type="button" disabled={status !== "idle"} onClick={submitHandler}>Submit</Button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default RollingSchedule;
