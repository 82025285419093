/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import tw from "twin.macro";
import Select from "../../../../../components/atoms/Select";
import SearchAll from "./SearchAll";
import SearchByCustomerId from "./SearchByCustomerId";
import SearchByEmail from "./SearchByEmail";
import SearchByName from "./SearchByName";
import SearchByUserId from "./SearchByUserId";
import SearchByUserSearchType from "./SearchByUserSearchType";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { setSearchType } from "../../../../../store/admin.reducer";
import AnimatedLoadingSpinner from "../../../../../components/atoms/AnimatedLoadingSpinner";

export type SearchByTypes =
  | "email"
  | "name"
  | "customer_id"
  | "user_id"
  | "user_search_type"
  | "all";

  
type Props = {
  children?: ReactNode;
};
const UsersSearchBar: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const { searchType } = useAppSelector((state) => state.admin);
  const { exportExcel } = useAppSelector((state) => state.admin.searchSettings);
  const loading = useAppSelector((state) => state.admin.loading);

  return (
    <div
      css={[
        tw`w-full text-xs p-2 bg-gradient-to-b from-[#e6e6e6] via-[#fdfdfd] to-[#e6e6e6] flex gap-4 items-center`,
      ]}
    >
      <span>Search By:</span>
      <Select
        name="search_by"
        value={searchType}
        minWidth="20ch"
        onChange={(e: SearchByTypes) => dispatch(setSearchType(e))}
        data={[
          { value: "email", label: "Email" },
          { value: "name", label: "Name" },
          { value: "customer_id", label: "Customer Id" },
          { value: "user_id", label: "User Id" },
          { value: "user_search_type", label: "User Search Type" },
          { value: "all", label: "All" },
        ]}
      />
      {searchType === "email" && <SearchByEmail />}
      {searchType === "name" && <SearchByName />}
      {searchType === "customer_id" && <SearchByCustomerId />}
      {searchType === "user_id" && <SearchByUserId />}
      {searchType === "user_search_type" && <SearchByUserSearchType />}
      {searchType === "all" && <SearchAll />}

      {loading && exportExcel && (
        <AnimatedLoadingSpinner message="Loading Excel..." css={tw`ml-4`} />
      )}

    </div>
  );
};

export default UsersSearchBar;
