/** @jsxImportSource @emotion/react */
import React, { ReactNode, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import tw from "twin.macro";

type Props = {
  children?: ReactNode;
  onCancel?: () => void;
  title: string;
} & React.HTMLAttributes<HTMLDivElement>;

const Modal: React.FC<Props> = ({
  onCancel,
  children,
  title,
  ...remainingProps
}) => {
  const closeRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return createPortal(
    <div
      onClick={onCancel}
      css={[
        tw`fixed top-0 left-0 w-full h-full flex justify-center items-center bg-[#cccccc77] z-[100]`,
      ]}
      {...remainingProps}
    >
      <div
        css={[tw`bg-nucor-green p-[7px] text-xs`]}
        onClick={(e) => e.stopPropagation()}
      >
        <div css={tw`flex justify-between`}>
          <h2 css={tw`font-bold text-white pb-1 text-xs flex-1`}>{title}</h2>
          <button
            ref={closeRef}
            type="button"
            onClick={onCancel}
            css={tw`text-nucor-green bg-[#ccc] h-[13px] w-[13px] flex justify-center items-center font-bold mt-px`}
          >
            &times;
          </button>
        </div>
        <div css={tw`bg-white p-1`}>{children}</div>
      </div>
    </div>,
    document.getElementById("modal") as HTMLElement
  );
};

export default Modal;
