/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import { SearchCriteria } from "../../types/types";
import SortDirection from "./SortDirection";

type Props = {
  children?: ReactNode;
  onClick: (field: string) => void;
  field: string;
  sort?: SearchCriteria;
};

const TableSortButton: React.FC<Props> = ({
  onClick,
  children,
  field,
  sort,
}) => {
  return (
    <button onClick={() => onClick(field)}>
      {children}
      {sort?.sort === field && <SortDirection dir={sort.dir} />}
    </button>
  );
};

export default TableSortButton;
