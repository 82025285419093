import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { axiosWithAuth } from "../app/axios";
import {
  CartHeader,
  CartOrderAndInquirySummaryView,
  CartSummaryView,
  DateString,
  ErrorResponse,
  PrimeStockInput,
  ProductInstructionsView,
  RollingInput,
  SalesOrderHeaderInput,
  SalesOrderItem,
  SalesOrderItemInput,
  LastShipTo,
} from "../types/types";
import { setCurrentShipTo } from "./customer.reducer";
import { fetchQuoteCart } from "./quoteCart.actions";
import {
  removeSalesOrderItemFromInventoryItem,
  updateInventoryItemWithSalesOrder,
} from "./product.reducer";
import { RootState } from "./store";
import { stringToBase64 } from "../helpers/stringToBase64";

export const fetchOpenCarts = createAsyncThunk<
  CartSummaryView[],
  boolean,
  { rejectValue: string | ErrorResponse }
>(
  "shoppingCart/fetchOpenCarts",
  async (inquiry: boolean, { rejectWithValue }) => {
    try {
      const response = await axiosWithAuth.get(
        `${process.env.REACT_APP_API_URL}/carts${
          inquiry ? "?inquiry=true" : ""
        }`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue(error.response?.data);
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue(
              "There was an issue while fetching all open shopping carts."
            );
        }
      } else {
        return rejectWithValue(
          "There was an issue while fetching all open shopping carts."
        );
      }
    }
  }
);

export const fetchCurrentShoppingCart = createAsyncThunk<
  CartHeader,
  string,
  { rejectValue: string | ErrorResponse }
>(
  "shoppingCart/fetchCurrentShoppingCart",
  async (customerId, { rejectWithValue }) => {
    try {
      const response = await axiosWithAuth.get(
        `${process.env.REACT_APP_API_URL}/carts/${customerId}`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue(error.response?.data);
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue(
              "There was an issue fetching the shopping cart."
            );
        }
      } else {
        return rejectWithValue(
          "There was an issue fetching the shopping cart."
        );
      }
    }
  }
);

export const fetchCartSummary = createAsyncThunk<
  CartOrderAndInquirySummaryView,
  string,
  { rejectValue: string | ErrorResponse }
>(
  "shoppingCart/fetchCartSummary",
  async (customerId: string, { rejectWithValue }) => {
    try {
      const response = await axiosWithAuth.get(
        `${process.env.REACT_APP_API_URL}/carts/${customerId}/summary`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue(error.response?.data);
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue(
              "There was an issue fetching the cart summary."
            );
        }
      } else {
        return rejectWithValue("There was an issue fetching the cart summary.");
      }
    }
  }
);

type FetchProdcutInstructionsRequest = {
  customerId: string;
  salesOrderItemId: number;
};

export const fetchProductInstructions = createAsyncThunk<
  ProductInstructionsView,
  FetchProdcutInstructionsRequest,
  { rejectValue: string | ErrorResponse }
>(
  "shoppingCart/fetchProductInstructions",
  async (params: FetchProdcutInstructionsRequest, { rejectWithValue }) => {
    try {
      const response = await axiosWithAuth.get(
        `${process.env.REACT_APP_API_URL}/carts/${params.customerId}/item/${params.salesOrderItemId}/instructions`
      );
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 400:
            return rejectWithValue(error.response?.data);
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue(error.response?.data);
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal Server Error");
          default:
            return rejectWithValue(
              "There was an issue fetching product instructions"
            );
        }
      }
      return rejectWithValue(
        "There was an issue fetching product instructions"
      );
    }
  }
);

type AddPrimeStockToCartRequest = {
  customerId: string;
  item: PrimeStockInput;
};

export const addPrimeStockToCart = createAsyncThunk<
  SalesOrderItem,
  AddPrimeStockToCartRequest,
  { rejectValue: string | ErrorResponse }
>(
  "shoppingCart/addPrimeStockToCart",
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosWithAuth.post(
        `${process.env.REACT_APP_API_URL}/carts/${params.customerId}/prime_stock`,
        params.item
      );
      dispatch(updateInventoryItemWithSalesOrder(response.data));
      dispatch(fetchCartSummary(params.customerId));
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 400:
            return rejectWithValue(error.response?.data);
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue(error.response?.data);
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue(
              "There was an issue adding prime stock to the cart."
            );
        }
      } else {
        return rejectWithValue(
          "There was an issue adding prime stock to the cart."
        );
      }
    }
  }
);

type UpdatePrimeStockCartItemRequest = {
  customerId: string;
  item: PrimeStockInput;
} & { salesOrderItemId: number };

export const updatePrimeStockCartItem = createAsyncThunk<
  SalesOrderItem,
  UpdatePrimeStockCartItemRequest,
  { rejectValue: string | ErrorResponse }
>(
  "shoppingCart/updatePrimeStockCartItem",
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosWithAuth.put(
        `${process.env.REACT_APP_API_URL}/carts/${params.customerId}/prime_stock/${params.salesOrderItemId}`,
        params.item
      );
      dispatch(updateInventoryItemWithSalesOrder(response.data));
      dispatch(fetchCartSummary(params.customerId));
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 400:
            return rejectWithValue(error.response?.data);
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue(error.response?.data);
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue(
              "There was an error updating prime stock in the cart."
            );
        }
      } else {
        return rejectWithValue(
          "There was an error updating prime stock in the cart."
        );
      }
    }
  }
);

type RemovePrimeStockFromCartRequest = {
  customerId: string;
  salesOrderItemId: number;
};

type RemovePrimeStockFromCartResponse = {
  salesOrderItemId: number;
};

export const removePrimeStockFromCart = createAsyncThunk<
  RemovePrimeStockFromCartResponse,
  RemovePrimeStockFromCartRequest,
  { rejectValue: string | ErrorResponse }
>(
  "shoppingCart/removePrimeStockFromCart",
  async (params, { rejectWithValue, dispatch }) => {
    try {
      if (!params.salesOrderItemId)
        throw new AxiosError("No sales order item id was provided.");
      const response = await axiosWithAuth.delete(
        `${process.env.REACT_APP_API_URL}/carts/${params.customerId}/prime_stock/${params.salesOrderItemId}`
      );
      if (response.status !== 204)
        throw Error("There was an error with the request");
      dispatch(removeSalesOrderItemFromInventoryItem(params.salesOrderItemId));
      dispatch(fetchCartSummary(params.customerId));
      return { salesOrderItemId: params.salesOrderItemId };
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 400:
            return rejectWithValue(error.response?.data);
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue(error.response?.data);
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue(
              error.message ?? "Error removing prime stock item from cart."
            );
        }
      } else {
        return rejectWithValue("Error removing prime stock item from cart.");
      }
    }
  }
);

type AddRollingItemToCartRequest = {
  customerId: string;
  item: RollingInput;
};

export const addRollingItemToCart = createAsyncThunk<
  SalesOrderItem[],
  AddRollingItemToCartRequest,
  { rejectValue: string | ErrorResponse }
>(
  "shoppingCart/addRollingItemToCart",
  async (
    params: AddRollingItemToCartRequest,
    { rejectWithValue, dispatch }
  ) => {
    try {
      if (!params.customerId)
        throw new AxiosError("No customer id was provided.");
      const response = await axiosWithAuth.post(
        `${process.env.REACT_APP_API_URL}/carts/${params.customerId}/rolling_item`,
        params.item
      );
      dispatch(fetchCartSummary(params.customerId));
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 400:
            return rejectWithValue(error.response?.data);
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue(error.response?.data);
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue(
              error.message ?? "Error adding rolling item to the cart."
            );
        }
      } else {
        return rejectWithValue("Error adding rolling item to the cart.");
      }
    }
  }
);

type AddShortsToCartRequest = {
  customerId: string;
  body: {
    shipToId: string;
    tagNumber: string;
    itemNumber: string;
    encodedNotes: string;
    dueDate: DateString;
    inventoryStatus: string;
  };
};

export const addShortsToCart = createAsyncThunk<
  SalesOrderItem,
  AddShortsToCartRequest,
  { rejectValue: string | ErrorResponse }
>(
  "shoppingCart/addShortsToCart",
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosWithAuth.post(
        `${process.env.REACT_APP_API_URL}/carts/${params.customerId}/shorts_stock`,
        params.body
      );
      dispatch(fetchCartSummary(params.customerId));
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 400:
            return rejectWithValue(error.response?.data);
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue(error.response?.data);
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue(
              "There was an issue adding short stock to the cart"
            );
        }
      } else {
        return rejectWithValue(
          "There was an issue adding short stock to the cart"
        );
      }
    }
  }
);

type AddSecondaryToCartRequest = {
  customerId: string;
  body: {
    shipToId: string;
    tagNumber: string;
    itemNumber: string;
    encodedNotes: string;
    dueDate: DateString;
    inventoryStatus: string;
  };
};

export const addSecondaryToCart = createAsyncThunk<
  SalesOrderItem,
  AddSecondaryToCartRequest,
  { rejectValue: string | ErrorResponse }
>(
  "shoppingCart/addSecondaryToCart",
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosWithAuth.post(
        `${process.env.REACT_APP_API_URL}/carts/${params.customerId}/secondary_stock`,
        params.body
      );
      dispatch(fetchCartSummary(params.customerId));
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 400:
            return rejectWithValue(error.response?.data);
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue(error.response?.data);
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue(
              "There was an issue adding secondary stock to the cart"
            );
        }
      } else {
        return rejectWithValue(
          "There was an issue adding secondary stock to the cart"
        );
      }
    }
  }
);

export type UpdateRollingCartItemRequest = {
  customerId: string;
  salesOrderItemId: number;
  item: RollingInput;
};

export const updateRollingCartItem = createAsyncThunk<
  SalesOrderItem[],
  UpdateRollingCartItemRequest,
  { rejectValue: string | ErrorResponse }
>(
  "shoppingCart/updateRollingCartItem",
  async (
    params: UpdateRollingCartItemRequest,
    { rejectWithValue, dispatch }
  ) => {
    try {
      if (!params.customerId)
        throw new AxiosError("No customer id was provided.");
      if (!params.salesOrderItemId)
        throw new AxiosError("No sales order item id was provided.");
      const response = await axiosWithAuth.put(
        `${process.env.REACT_APP_API_URL}/carts/${params.customerId}/rolling_item/${params.salesOrderItemId}`,
        params.item
      );
      dispatch(fetchCartSummary(params.customerId));
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 400:
            return rejectWithValue(error.response?.data);
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue(error.response?.data);
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue(
              error.message ?? "Error updating rolling item."
            );
        }
      } else {
        return rejectWithValue("Error updating rolling item.");
      }
    }
  }
);

export type RemoveRollingItemFromCartRequest = {
  customerId: string;
  salesOrderItemId: number;
};

export const removeRollingItemFromCart = createAsyncThunk<
  SalesOrderItem[],
  RemoveRollingItemFromCartRequest,
  { rejectValue: string | ErrorResponse }
>(
  "shoppingCart/removeRollingItemFromCart",
  async (
    params: RemoveRollingItemFromCartRequest,
    { rejectWithValue, dispatch }
  ) => {
    try {
      if (!params.salesOrderItemId)
        throw new AxiosError("No sales order item id was provided.");
      const response = await axiosWithAuth.delete(
        `${process.env.REACT_APP_API_URL}/carts/${params.customerId}/rolling_item/${params.salesOrderItemId}`
      );
      dispatch(fetchCartSummary(params.customerId));
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 400:
            return rejectWithValue(error.response?.data);
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue(error.response?.data);
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue(
              error.message ?? "Error removing rolling item from the cart."
            );
        }
      } else {
        return rejectWithValue("Error removing rolling item from the cart.");
      }
    }
  }
);

export const clearCustomerCart = createAsyncThunk<
  string,
  string,
  { rejectValue: string | ErrorResponse }
>(
  "shoppingCart/clearCart",
  async (customerId: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosWithAuth.delete(
        `${process.env.REACT_APP_API_URL}/carts/${customerId}`
      );
      if (response.status !== 204)
        throw new AxiosError("There was an issue clearing the cart");
      dispatch(refreshCart({ customerId: customerId }));
      return customerId;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue(error.response?.data);
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue(
              error.message ?? "There was an issue clearing the cart."
            );
        }
      } else {
        return rejectWithValue("There was an issue clearing the cart.");
      }
    }
  }
);

type ChangeShipToRequest = {
  customerId: string;
  body: {
    shipToId: string;
  };
};

export const changeShipTo = createAsyncThunk<
  string,
  ChangeShipToRequest,
  { rejectValue: string | ErrorResponse }
>(
  "shoppingCart/changeShipTo",
  async (params: ChangeShipToRequest, { rejectWithValue, dispatch }) => {
    try {
      await axiosWithAuth.put(
        `${process.env.REACT_APP_API_URL}/carts/${params.customerId}/change_ship_to`,
        params.body
      );
      dispatch(setCurrentShipTo(params.body.shipToId));
      return params.body.shipToId;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 400:
            return rejectWithValue(error.response?.data);
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue(error.response?.data);
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue(
              "There was an issue changing the ship to customer."
            );
        }
      } else {
        return rejectWithValue(
          "There was an issue changing the ship to customer."
        );
      }
    }
  }
);

type VailidateCartRequest = {
  customerId: string;
  body: SalesOrderHeaderInput;
};

export const validateCart = createAsyncThunk<
  CartHeader,
  VailidateCartRequest,
  { rejectValue: string | ErrorResponse }
>("shoppingCart/validateCart", async (params, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.post(
      `${process.env.REACT_APP_API_URL}/carts/${params.customerId}/validate`,
      params.body
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 400:
          return rejectWithValue(error.response?.data);
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 404:
          return rejectWithValue(error.response?.data);
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue("There was an issue validating the cart.");
      }
    } else {
      return rejectWithValue("There was an issue validating the cart.");
    }
  }
});

export const submitCart = createAsyncThunk<
  CartHeader,
  string,
  { rejectValue: string | ErrorResponse }
>(
  "shoppingCart/submitCart",
  async (customerId, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosWithAuth.post(
        `${process.env.REACT_APP_API_URL}/carts/${customerId}/submit`
      );
      dispatch(fetchCartSummary(customerId));
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 400:
            return rejectWithValue(error.response?.data);
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue(error.response?.data);
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue("There was an issue submitting the cart.");
        }
      } else {
        return rejectWithValue("There was an issue submitting the cart.");
      }
    }
  }
);

type UpdateCartItemsRequest = {
  customerId: string;
  poAtLineLevel: boolean;
};

export const updateCartItemDetails = createAsyncThunk<
  CartHeader,
  UpdateCartItemsRequest,
  { rejectValue: string | ErrorResponse }
>(
  "shoppingCart/updateCartItemDetails",
  async (params, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      const cart = state.shoppingCart.currentShoppingCart;
      const poAtLineLevel = params.poAtLineLevel;
      const itemsArr = cart?.sites.reduce((acc, site) => {
        const items = site.items.map((item) => {
          return {
            id: item.id,
            poLineNumber: item.poLineNumber,
            requestedShippingDate: item.requestedShippingDate,
            customerPartNumber: item.customerPartNumber,
            newCustomerPartNumber: item.newCustomerPartNumber,
            customerPONumber: params.poAtLineLevel
              ? item.customerPONumber
              : undefined,
          } as SalesOrderItemInput;
        });
        return [...acc, ...items];
      }, [] as SalesOrderItemInput[]);
      const response = await axiosWithAuth.put(
        `${process.env.REACT_APP_API_URL}/carts/${params.customerId}`,
        {
          customerPONumber: poAtLineLevel ? undefined : cart?.customerPONumber,
          docType: cart?.docType,
          prePayandAdd: cart?.prePayandAdd,
          encodedNotes: cart?.notes ? stringToBase64(cart?.notes) : "",
          poAtLineLevel: poAtLineLevel,
          items: itemsArr,
        }
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 400:
            return rejectWithValue(error.response?.data);
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue(error.response?.data);
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue("There was an error updating cart items.");
        }
      } else {
        return rejectWithValue("There was an error updating cart items.");
      }
    }
  }
);

type RefreshCartRequest = {
  customerId: string;
};

export const refreshCart = createAsyncThunk<
  void,
  RefreshCartRequest,
  { rejectValue: string | ErrorResponse }
>("shoppingCart/refreshCart", async (params, { rejectWithValue, dispatch }) => {
  try {
    const shoppingCartRequest = dispatch(
      fetchCurrentShoppingCart(params.customerId)
    ).unwrap();
    const quoteCartRequest = dispatch(
      fetchQuoteCart(params.customerId)
    ).unwrap();
    const summaryRequest = dispatch(
      fetchCartSummary(params.customerId)
    ).unwrap();
    await Promise.all([shoppingCartRequest, quoteCartRequest, summaryRequest]);
    return;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        default:
          return rejectWithValue(
            "There was an issue refreshing the customer cart"
          );
      }
    } else {
      return rejectWithValue("There was an issue refreshing the customer cart");
    }
  }
});

type UpdateItemInstructionsRequest = {
  customerId: string;
  salesOrderItemId: number;
  body: {
    cutLength?: number;
    cutInHalf: boolean;
    quoteRequested: boolean;
    encodedNotes: string;
  };
};

export const updateItemInstructions = createAsyncThunk<
  CartHeader,
  UpdateItemInstructionsRequest,
  { rejectValue: string | ErrorResponse }
>(
  "shoppingCart/updateItemInstructions",
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosWithAuth.put(
        `${process.env.REACT_APP_API_URL}/carts/${params.customerId}/item/${params.salesOrderItemId}/instructions`,
        params.body
      );
      dispatch(fetchCartSummary(params.customerId));
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 400:
            return rejectWithValue(error.response?.data);
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue(error.response?.data);
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue(
              "There was an issue updating the item instructions"
            );
        }
      } else {
        return rejectWithValue(
          "There was an issue updating the item instructions"
        );
      }
    }
  }
);

type UpdateShortsStockCartItemRequest = {
  customerId: string;
  salesOrderItemId: number;
  body: {
    shipToId: string;
    tagNumber: string;
    itemNumber: string;
    encodedNotes: string;
    dueDate: DateString;
    inventoryStatus: string;
  };
};

export const updateShortsStockCartItem = createAsyncThunk<
  SalesOrderItem,
  UpdateShortsStockCartItemRequest,
  { rejectValue: string | ErrorResponse }
>(
  "shoppingCart/updateShortsStockCartItem",
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosWithAuth.put(
        `${process.env.REACT_APP_API_URL}/carts/${params.customerId}/shorts_stock/${params.salesOrderItemId}`,
        params.body
      );
      dispatch(fetchCartSummary(params.customerId));
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 400:
            return rejectWithValue(error.response?.data);
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue(error.response?.data);
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue(
              "There was an issue updating shorts stock item in the cart"
            );
        }
      } else {
        return rejectWithValue(
          "There was an issue updating shorts stock item in the cart"
        );
      }
    }
  }
);

type UpdateSecondaryStockCartItemRequest = {
  customerId: string;
  salesOrderItemId: number;
  body: {
    shipToId: string;
    tagNumber: string;
    itemNumber: string;
    encodedNotes: string;
    dueDate: DateString;
    inventoryStatus: string;
  };
};

export const updateSecondaryStockCartItem = createAsyncThunk<
  SalesOrderItem,
  UpdateSecondaryStockCartItemRequest,
  { rejectValue: string | ErrorResponse }
>(
  "shoppingCart/updateSecondaryStockCartItem",
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosWithAuth.put(
        `${process.env.REACT_APP_API_URL}/carts/${params.customerId}/secondary_stock/${params.salesOrderItemId}`,
        params.body
      );
      dispatch(fetchCartSummary(params.customerId));
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 400:
            return rejectWithValue(error.response?.data);
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue(error.response?.data);
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue(
              "There was an issue updating secondary stock item in the cart"
            );
        }
      } else {
        return rejectWithValue(
          "There was an issue updating secondary stock item in the cart"
        );
      }
    }
  }
);

type RemoveCartItemRequest = {
  customerId: string;
  salesOrderItemId: number;
};

export const removeCartItem = createAsyncThunk<
  CartHeader,
  RemoveCartItemRequest,
  { rejectValue: string | ErrorResponse }
>(
  "shoppingCart/removeCartItem",
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosWithAuth.delete(
        `${process.env.REACT_APP_API_URL}/carts/${params.customerId}/item/${params.salesOrderItemId}`
      );
      dispatch(fetchCartSummary(params.customerId));
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 400:
            return rejectWithValue(error.response?.data);
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue(error.response?.data);
          case 423:
            return rejectWithValue("Locked");
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue("There was an issue removing cart item");
        }
      } else {
        return rejectWithValue("There was an issue removing cart item");
      }
    }
  }
);

type RemoveSecondaryCartItemRequest = {
  customerId: string;
  salesOrderItemId: number;
};

export const removeSecondaryCartItem = createAsyncThunk<
  number,
  RemoveSecondaryCartItemRequest,
  { rejectValue: string | ErrorResponse }
>(
  "shoppingCart/removeSecondaryCartItem",
  async (params, { rejectWithValue, dispatch }) => {
    try {
      await axiosWithAuth.delete(
        `${process.env.REACT_APP_API_URL}/carts/${params.customerId}/secondary_stock/${params.salesOrderItemId}`
      );
      dispatch(fetchCartSummary(params.customerId));
      return params.salesOrderItemId;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 400:
            return rejectWithValue(error.response?.data);
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue(error.response?.data);
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue(
              "There was an issue updating shorts stock item in the cart"
            );
        }
      } else {
        return rejectWithValue(
          "There was an issue removing secondary stock item from the cart"
        );
      }
    }
  }
);

type RemoveShortsCartItemRequest = {
  customerId: string;
  salesOrderItemId: number;
};

export const removeShortsCartItem = createAsyncThunk<
  number,
  RemoveShortsCartItemRequest,
  { rejectValue: string | ErrorResponse }
>(
  "shoppingCart/removeShortsCartItem",
  async (params, { rejectWithValue, dispatch }) => {
    try {
      await axiosWithAuth.delete(
        `${process.env.REACT_APP_API_URL}/carts/${params.customerId}/shorts_stock/${params.salesOrderItemId}`
      );
      dispatch(fetchCartSummary(params.customerId));
      return params.salesOrderItemId;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 400:
            return rejectWithValue(error.response?.data);
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue(error.response?.data);
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue(
              "There was an issue removing shorts stock item from the cart"
            );
        }
      } else {
        return rejectWithValue(
          "There was an issue removing shorts stock item from the cart"
        );
      }
    }
  }
);

export const fetchLastShipTo = createAsyncThunk<
  LastShipTo,
  string,
  { rejectValue: string | ErrorResponse }
>(
  "shoppingCart/fetchLastShipTo",
  async (customerId, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosWithAuth.get(
        `${process.env.REACT_APP_API_URL}/carts/${customerId}/lastshipto`
      );

      if(response.data.shipToId) {
        dispatch(setCurrentShipTo(response.data.shipToId));
      }
      
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 400:
            return rejectWithValue(error.response?.data);
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue(error.response?.data);
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue(
              "There was an issue fetching the latest ship to."
            );
        }
      } else {
        return rejectWithValue(
          "There was an issue fetching the latest ship to."
        );
      }
    }
  }
);

type UpdatePriceRequest = {
  customerId: string;
  salesOrderItemId: number;
  body: {
    totalPrice?: number;
    priceUnit?: string;
  };
};

export const updateItemPrice = createAsyncThunk<
  CartHeader,
  UpdatePriceRequest,
  { rejectValue: string | ErrorResponse }
>(
  "shoppingCart/updateItemPrice",
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosWithAuth.put(
        `${process.env.REACT_APP_API_URL}/internal/carts/${params.customerId}/item/${params.salesOrderItemId}/price`,
        params.body
      );
      dispatch(fetchCartSummary(params.customerId));
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 400:
            return rejectWithValue(error.response?.data);
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue(error.response?.data);
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue(
              "There was an issue updating the item price"
            );
        }
      } else {
        return rejectWithValue(
          "There was an issue updating the item price"
        );
      }
    }
  }
);
