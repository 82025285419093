/** @jsxImportSource @emotion/react */
import React, { ReactNode, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RequestStatus } from "../../types/types";
import Modal from "./Modal";
import Button from "../atoms/Button";
import { selectCurrentCustomerId } from "../../store/customer.reducer";
import { fetchProductPriceSheetPDF, fetchAllShipToProductPriceSheetPDF } from "../../store/product.actions";
import AnimatedSpinner from "../atoms/AnimatedSpinner";
import ShipToSelect from "./ShipToSelect";
import tw from "twin.macro";

type Props = {
  children?: ReactNode;
  onCancel: () => void;
};

const PriceSheet: React.FC<Props> = ({ onCancel }) => {

  const dispatch = useAppDispatch();
  const [status, setStatus] = useState<RequestStatus>("idle");
  const [selectedShipTo, setSelectedShipTo] = useState("all");
  const selectedCustomerId = useAppSelector(selectCurrentCustomerId);

  const submitHandler = async () => {
    if (!selectedCustomerId) return;

    setStatus("pending");
   
    if ('all' == selectedShipTo) {
      dispatch(
        fetchAllShipToProductPriceSheetPDF({customerId: selectedCustomerId})
      )
        .unwrap()
        .then(() => {
          setStatus("idle");
          onCancel();
        })
        .catch(() => {
          setStatus("idle");
          onCancel();
        });
    } else {
      dispatch(
        fetchProductPriceSheetPDF({customerId: selectedCustomerId, shipToId: selectedShipTo})
      )
        .unwrap()
        .then(() => {
          setStatus("idle");
          onCancel();
        })
        .catch(() => {
          setStatus("idle");
          onCancel();
        });
    }
  };

  return (
    <>
      <Modal onCancel={onCancel} title="Price Sheet">
        <form className="p-1">
          <fieldset disabled={status !== "idle"} className="flex gap-4">

            <div className="flex gap-2 mt-2">
              <label css={tw`w-[45px] mt-1`}>Ship To</label>
              <ShipToSelect
                value={selectedShipTo}
                onChange={(value) => {
                  setSelectedShipTo(value);
                }}
                minWidth="50ch"
                addAll={true}
              />
              {status === "pending" ? <AnimatedSpinner css={tw`mt-1`} /> : <div css={tw`w-[16px]`}></div>}
            </div>
          </fieldset>
          <div className="mt-4 flex justify-end gap-2 items-center">
            <Button type="button" disabled={status !== "idle"} onClick={onCancel}>Cancel</Button>
            <Button type="button" disabled={status !== "idle"} onClick={submitHandler}>Submit</Button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default PriceSheet;
