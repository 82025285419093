/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import tw from "twin.macro";
import SubNavTabs from "../../../components/organisms/SubNavTabs";
import Downloads from "./Downloads";
import NotFound from "../../app/NotFound";

type Props = {
  children?: ReactNode;
};

const submenu = [
  { title: "Downloads", to: "downloads", showCustId: true },
];

const UserInfo: React.FC<Props> = () => {
  const location = useLocation();

  return (
    <>
      <SubNavTabs submenu={submenu} />
      <div css={tw`p-4 w-full`}>
        <Routes>
          <Route
            path=""
            element={
              <Navigate
                to={{ pathname: "downloads", search: location.search }}
              />
            }
          />
          <Route path="downloads" element={<Downloads />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </>
  );
};

export default UserInfo;
