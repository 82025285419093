import { GetTokenSilentlyVerboseResponse } from "@auth0/auth0-spa-js";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { NavigateFunction, To } from "react-router-dom";

let observers: Array<() => void> = [];
let navigation: Array<NavigateFunction> = [];

export const axiosObservable = Object.freeze({
  notify: () => observers.forEach((obs) => obs()),
  subscribe: (func: () => void) => {
    observers.push(func);
  },
  unsubscribe: (func: () => void) => {
    observers = observers.filter((obs) => obs === func);
  },
});

export const navigationObservable = Object.freeze({
  notify: (to: To) => navigation.forEach((obs) => obs(to)),
  subscribe: (func: NavigateFunction) => {
    navigation.push(func);
  },
  unsubscribe: (func: NavigateFunction) => {
    navigation = navigation.filter((obs) => obs === func);
  },
});

export const axiosWithAuth = axios.create();
axiosWithAuth.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: unknown) => {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 401: {
          if (error.config.url?.includes("current_context")) {
            return Promise.reject(error);
          }
          axiosObservable.notify();
          break;
        }
        case 403: {
          navigationObservable.notify("/forbidden");
          break;
        }
        case 423: {
          navigationObservable.notify("/restricted");
          break;
        }
        case 500: {
          navigationObservable.notify("/500");
          break;
        }
        default:
          return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

export const authInterceptor = (
  getAccessTokenSilently: () => Promise<
    GetTokenSilentlyVerboseResponse | string
  >
) => {
  axiosWithAuth.interceptors.request.use(async (config: AxiosRequestConfig) => {
    if (!config.headers) return;
    const token = await getAccessTokenSilently();
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });
};
