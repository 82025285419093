/** @jsxImportSource @emotion/react */
import React, { ReactNode, useState } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import Select from "../../../../../components/atoms/Select";
import InventoryItemDetails from "./InventoryItemDetails";
import PrimeProductTable from "./PrimeProductTable";
import { selectMillsForSelectComponent } from "../../../../../store/app.reducer";
import {
  selectCurrentAuthorities,
  selectDefaultMill,
  selectShowOddLengths,
  setShowOddLengths,
} from "../../../../../store/user.reducer";
import { selectCurrentProductKey } from "../../../../../store/product.reducer";
import TextButton from "../../../../../components/atoms/TextButton";
import { AxiosError } from "axios";
import { fetchRobinhoodExcel } from "../../../../../store/product.actions";
import Toast from "../../../../../components/molecules/Toast";
import { hasAuthority } from "../../../../../helpers/hasAuthority";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { ErrorResponse, RequestStatus } from "../../../../../types/types";
import { isErrorResponse } from "../../../../../types/predicates";
import {
  fetchPrimePDF,
  fetchPrimePDFByPart,
} from "../../../../../store/product.actions";
import {
  selectCurrentCustomerId,
  selectCurrentShipTo,
  setCurrentCustomerVmiChecked,
} from "../../../../../store/customer.reducer";

type Props = {
  children?: ReactNode;
};

const PrimeContent: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const showVmiFilter = useAppSelector(
    (state) => state.customer.currentCustomer?.showVmiFilter
  );
  const vmiChecked = useAppSelector(
    (state) => state.customer.currentCustomer?.vmi
  );
  const { currentInventoryResults } = useAppSelector(
    (state) => state.product.prime
  );
  const currentCustomerId = useAppSelector(selectCurrentCustomerId);
  const currentProductKey = useAppSelector(selectCurrentProductKey);
  const defaultMill = useAppSelector(selectDefaultMill);
  const mills = useAppSelector(selectMillsForSelectComponent);
  const showOddLengths = useAppSelector(selectShowOddLengths);
  const currentShipTo = useAppSelector(selectCurrentShipTo);

  const [site, setSite] = useState(defaultMill);
  const [oddLengths, setOddLengths] = useState(showOddLengths);
  const [showVmi, setShowVmi] = useState(vmiChecked || false);
  const [robinhoodStatus, setRobinhoodStatus] = useState<RequestStatus>("idle");
  const [robinhoodError, setRobinhoodError] = useState<string>("");
  const currentAuthorities = useAppSelector(selectCurrentAuthorities);

  const isInternal = hasAuthority("internal", currentAuthorities);

  const robinhoodHandler = async () => {
    if (!currentProductKey) return;
    setRobinhoodStatus("pending");
    setRobinhoodError("");
    try {
      await dispatch(
        fetchRobinhoodExcel({ compoundProductKey: currentProductKey?.key })
      );
    } catch (error) {
      setRobinhoodError((error as AxiosError)?.message);
    } finally {
      setRobinhoodStatus("idle");
    }
  };

  const [pdfStatus, setPdfStatus] = useState<RequestStatus>("idle");
  const [pdfError, setPdfError] = useState<ErrorResponse | string>();

  const showPdfLink = currentInventoryResults && hasAuthority("viewPrimeStock", currentAuthorities);

  const pdfHandler = async () => {
    if (currentInventoryResults?.customerPartNumber) {
      try {
        if (
          !currentProductKey?.customerPartNumber ||
          !currentCustomerId ||
          !currentShipTo
        ) {
          throw new Error("No customer part number");
        }
        setPdfStatus("pending");
        await dispatch(
          fetchPrimePDFByPart({
            customerPartNumber: currentProductKey?.customerPartNumber,
            customerId: currentCustomerId,
            shipToId: currentShipTo,
          })
        ).unwrap();
      } catch (error) {
        if (isErrorResponse(error)) {
          console.log(error);
          setPdfError(error);
        }
      } finally {
        setPdfStatus("idle");
      }
    } else {
      try {
        if (!currentProductKey?.key || !currentCustomerId || !currentShipTo) {
          throw new Error("No product key");
        }
        setPdfStatus("pending");
        await dispatch(
          fetchPrimePDF({
            compoundProductKey: currentProductKey?.key,
            customerId: currentCustomerId,
            shipToId: currentShipTo,
          })
        ).unwrap();
      } catch (error) {
        if (isErrorResponse(error)) {
          console.log(error);
          setPdfError(error);
        }
      } finally {
        setPdfStatus("idle");
      }
    }
  };

  return (
    <div css={tw`relative`}>
      {robinhoodError && (
        <Toast
          message={robinhoodError}
          type="error"
          onConfirm={() => setRobinhoodError("")}
        />
      )}
      {pdfError && (
        <Toast
          message={pdfError}
          type="error"
          onConfirm={() => setPdfError("")}
        />
      )}
      <div
        css={[
          tw`text-white text-sm uppercase bg-nucor-green w-full p-[2px] px-2 flex justify-between items-center mt-2`,
        ]}
      >
        <h2 css={[tw`font-bold`]}>Floor Stock</h2>
        {isInternal ? (
          <TextButton
            onClick={robinhoodHandler}
            disabled={!currentProductKey || robinhoodStatus === "pending"}
            css={tw`text-white disabled:text-nucor-gray`}
          >
            Robin Hood Report
          </TextButton>
        ) : null}
      </div>
      {!currentProductKey && (
        <p css={tw`text-nucor-green font-bold text-xs mt-2`}>
          * Continue by selecting the product dimensions.
        </p>
      )}
      {currentProductKey && (
        <>
          <InventoryItemDetails
            currentInventoryResults={currentInventoryResults}
          />
          <div
            css={tw`text-xs text-nucor-gray bg-gradient-to-b from-[#e6e6e6] via-[#fdfdfd] to-[#e6e6e6] p-1`}
          >
            <div css={tw`flex items-center pb-1`}>
              <span css={tw`mr-2`}>Show:</span>
              <Select
                name="mill"
                value={site}
                onChange={(value: string) => {
                  setSite(value);
                }}
                minWidth="16ch"
                data={mills}
              />
              <div css={tw`flex items-center`}>
                <input
                  name="oddLengths"
                  id="oddLengths"
                  checked={oddLengths}
                  onChange={(e) => {
                    setOddLengths(e.target.checked)
                    dispatch(setShowOddLengths(e.target.checked))
                    }
                  }
                  css={tw`ml-4 mr-2`}
                  type="checkbox"
                />
                <label htmlFor="oddLengths">Odd lengths</label>
              </div>
              {showVmiFilter && (
                <div css={tw`flex items-center`}>
                  <input
                    name="vmi"
                    id="vmi"
                    checked={showVmi}
                    onChange={(e) => {
                        setShowVmi(e.target.checked);
                        dispatch(setCurrentCustomerVmiChecked(e.target.checked));
                      }
                    }
                    css={tw`ml-4 mr-2`}
                    type="checkbox"
                  />
                  <label htmlFor="vmi">VMI</label>
                </div>
              )}
              <div css={tw`flex-1`}></div>
              {showPdfLink && (
                <button
                  className="group"
                  disabled={pdfStatus === "pending"}
                  onClick={pdfHandler}
                >
                  <FontAwesomeIcon
                    css={tw`h-5 text-red-600 group-disabled:text-nucor-gray`}
                    icon={faFilePdf}
                  />
                </button>
              )}
            </div>
          </div>
          <PrimeProductTable
            oddLengths={oddLengths}
            showVmi={showVmi}
            site={site}
            allowCutInHalf={currentInventoryResults?.allowCutInHalf}
          />
        </>
      )}
    </div>
  );
};

export default PrimeContent;
