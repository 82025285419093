/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import tw from "twin.macro";
import { useAppSelector } from "../../../../app/hooks";
import AnimatedLoadingSpinner from "../../../../components/atoms/AnimatedLoadingSpinner";

type Props = {
  children?: ReactNode;
};

const DocumentSearchResults: React.FC<Props> = () => {
  const { status } = useAppSelector((state) => state.document.docSearch);

  if (status === "pending") {
    return <AnimatedLoadingSpinner css={tw`m-4`} />;
  }

  return (
    <p css={tw`m-4  text-xs`}>
      Choose a document type, branch and document number.
    </p>
  );
};

export default DocumentSearchResults;
