/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import BrowseSearch from "../BrowseSearch";
import InvoicesBrowse from "./InvoicesBrowse";
import InvoicesSearch from "./InvoicesSearch";

type Props = {
  children?: ReactNode;
};

const InvoicesLeft: React.FC<Props> = () => {

  return (
    <BrowseSearch browse={<InvoicesBrowse />} search={<InvoicesSearch />} />
  );
};

export default InvoicesLeft;
