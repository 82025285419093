/** @jsxImportSource @emotion/react */
import React, { ReactNode, useContext } from "react";
import tw from "twin.macro";
import OrdersResults from "./OrdersResults";
import PDFEmbed from "../../../../components/molecules/PDFEmbed";
import { PDFContext } from "./OrdersList";
import TextButton from "../../../../components/atoms/TextButton";

type Props = {
  children?: ReactNode;
};

const OrdersContent: React.FC<Props> = () => {

  const ctx = useContext(PDFContext);

  return (
    <div>
      <div css={tw`text-white font-bold bg-nucor-green text-xs flex justify-between py-1 px-2 mb-2`}>
        <span className="uppercase w-16">Orders</span>
        {ctx?.file && (
          <TextButton onClick={() => ctx?.setFile(null)} css={tw`text-white`}>
            Back to List
          </TextButton>
        )}
        <span className="w-16"></span>
      </div>
      
      <div css={[ctx?.file && tw`hidden`]}>
        <OrdersResults />
      </div>
      {ctx?.file && (
        <PDFEmbed
          setFile={ctx?.setFile}
          onCancel={() => ctx?.setFile(null)}
          file={ctx?.file}
        />
      )}
    </div>
  );
};

export default OrdersContent;
