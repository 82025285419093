/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import tw from "twin.macro";
import { useAppSelector } from "../../app/hooks";
import { Link } from "react-router-dom";

type Props = {
  children?: ReactNode;
};

const Forbidden: React.FC<Props> = () => {
  const errorEmail = useAppSelector(
    (state) => state.user.currentContext?.appContext.errorEmail
  );

  return (
    <div css={tw`w-full mt-20 flex justify-center`}>
      <div css={tw`w-[60%] max-w-[700px] border shadow p-6 bg-white`}>
        <h2 css={tw`text-xl text-red-600 font-bold`}>Forbidden - 403</h2>
        <p css={tw`text-xs my-4 text-nucor-light-green italic font-semibold`}>
          Sorry, but the requested action is forbidden at this time. Please see
          below for options.
        </p>
        <ul css={tw`text-xs list-disc ml-6`}>
          <li>
            <Link
              css={tw`text-base font-semibold text-nucor-link underline hover:(text-nucor-link-hover no-underline)`}
              to="/portal"
            >
              Go to homepage
            </Link>
          </li>
          <li>
            Submit an error report to{" "}
            <a
              className="text-nucor-link underline hover:text-nucor-link-hover hover:no-underline"
              href={`mailto:${errorEmail}`}
            >
              Nucor Tubular Products
            </a>
            .
          </li>
          <li>
            If you can&apos;t wait for an email response, call 800-376-6000.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Forbidden;
