/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import TextButton from "../../../../../components/atoms/TextButton";
import { inchesToFormattedString } from "../../../../../helpers/lengthHelpers";
import { removeRollingItemFromCart } from "../../../../../store/shoppingCart.actions";
import { selectCurrentCustomerId } from "../../../../../store/customer.reducer";
import { SalesOrderItem } from "../../../../../types/types";
import { clearCurrentRollingItem } from "../../../../../store/rollings.reducer";
import { formatDateToShort } from "../../../../../helpers/dateHelpers";
import { numberFormatter } from "../../../../../helpers/numberFormat";

type Props = {
  children?: ReactNode;
  rollingItem?: SalesOrderItem;
  itemNumber: number;
  onLoading?: (loading: boolean) => void;
  onEdit: (item?: SalesOrderItem) => void;
};

const ConfirmationItem: React.FC<Props> = ({
  rollingItem,
  itemNumber,
  onLoading,
  onEdit,
}) => {
  const dispatch = useAppDispatch();
  const customerId = useAppSelector(selectCurrentCustomerId);

  const removeHandler = (id?: number) => {
    if (!customerId || !id) return;
    onLoading && onLoading(true);
    dispatch(
      removeRollingItemFromCart({
        customerId,
        salesOrderItemId: id,
      })
    )
      .unwrap()
      .then(() => {
        onLoading && onLoading(false);
        dispatch(clearCurrentRollingItem());
      })
      .catch(() => onLoading && onLoading(false));
  };

  return (
    <div css={tw`flex border border-nucor-light-gray m-1`}>
      <div
        css={tw`w-[3ch] text-center flex items-center justify-center bg-nucor-green text-white`}
      >
        {itemNumber + 1}
      </div>
      <div css={tw`grid grid-cols-5 p-1 bg-white w-full`}>
        <div css={tw`text-nucor-gray col-span-2`}>Mill:</div>
        <div css={tw`col-span-3`}>{rollingItem?.site}</div>
        <div css={tw`text-nucor-gray col-span-2`}>Size:</div>
        <div css={tw`col-span-3`}>{rollingItem?.itemDescription}</div>
        <div css={tw`text-nucor-gray col-span-2`}>Length</div>
        <div css={tw`col-span-3`}>
          {inchesToFormattedString(rollingItem?.length)}
        </div>
        <div css={tw`text-nucor-gray col-span-2`}>Part #: </div>
        <div css={tw`col-span-3`}>{rollingItem?.customerPartNumber}</div>
        <div css={tw`text-nucor-gray col-span-2`}>Qty:</div>
        <div css={tw`col-span-3`}>{rollingItem?.pieces} Pcs</div>
        <div css={tw`text-nucor-gray col-span-2`}>Weight:</div>
        <div css={tw`col-span-3`}>{numberFormatter(rollingItem?.weight)}</div>
        <div css={tw`text-nucor-gray col-span-2`}>Due Date:</div>
        <div css={tw`col-span-3`}>
          {formatDateToShort(rollingItem?.requestedShippingDate)}
        </div>
        {rollingItem?.quote && (
          <p css={tw`text-nucor-green col-span-5 pt-1`}>
            &#42; {rollingItem?.quoteReason}
          </p>
        )}
        <div css={tw`col-span-5 text-nucor-link flex gap-4 mt-1`}>
          <TextButton css={tw`font-normal`} onClick={() => onEdit(rollingItem)}>
            Edit
          </TextButton>
          <TextButton
            css={tw`font-normal`}
            onClick={() => removeHandler(rollingItem?.id)}
          >
            Remove
          </TextButton>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationItem;
