/** @jsxImportSource @emotion/react */
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactNode } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
  setProductSelectionHeight,
  setProductSelectionWidth,
  setCurrentProductIndex,
  updateBrowseHistory,
  selectProductSelectionObject,
} from "../../../../../store/product.reducer";

type Props = {
  children?: ReactNode;
};

const SelectStockDetails: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const productSelections = useAppSelector(selectProductSelectionObject);
  const { width, height, wall } = useAppSelector(
    (state) => state.product.productSelectionDetails
  );
  const { loading } = useAppSelector((state) => state.rollings);
  const { inventorySearchStatus } = useAppSelector((state) => state.product.prime);

  return (
    <>
      {productSelections.shape !== "Unknown" && (
        <div css={[tw`p-[2px] bg-nucor-green text-white`]}>
          <div
            css={[
              tw` grid grid-cols-2`,
              productSelections.shape !== "Round" && tw`grid-cols-3`,
            ]}
          >
            <span css={[tw`col-span-1`]}>
              {productSelections.width?.headerLabel}
            </span>
            {productSelections.shape !== "Round" && (
              <span css={[tw`col-span-1`]}>Height</span>
            )}
            <span css={[tw`col-span-1`]}>Wall</span>
          </div>
          <div
            css={[
              tw`grid grid-cols-2 min-h-[16px]`,
              productSelections.shape !== "Round" && tw`grid-cols-3`,
            ]}
          >
            <span css={[tw`col-span-1 font-semibold`]}>
              {width !== 0
                ? productSelections.width?.available?.find(
                    (item) => item.value === width
                  )?.label
                : ""}
            </span>
            {productSelections.shape !== "Round" && (
              <span css={[tw`col-span-1 font-semibold`]}>
                {productSelections.shape === "Rectangle" &&
                  (height === 0
                    ? ""
                    : productSelections.height?.available?.find(
                        (item) => item.value === height
                      )?.label)}
                {productSelections.shape === "Square" &&
                  (width === 0
                    ? ""
                    : productSelections.width?.available?.find(
                        (item) => item.value === width
                      )?.label)}
              </span>
            )}
            <span css={[tw`col-span-1 font-semibold`]}>
              {wall !== 0 &&
                productSelections.wall?.available?.find(
                  (item) => item.value === wall
                )?.label}
            </span>
          </div>
        </div>
      )}
      <div
        css={[
          tw`grid grid-cols-2`,
          productSelections.shape !== "Round" && tw`grid-cols-3`,
        ]}
      >
        <div css={[tw`col-span-1`, { flexBasis: "33%" }]}>
          {productSelections.width?.available?.map((item, index) => (
            <DimensionButton
              disabled={(item.value === width || loading == 'pending' || inventorySearchStatus == 'pending')}
              selected={item.value === width}
              onClick={() => {
                dispatch(setProductSelectionWidth(item.value));
              }}
              key={index}
              showCaret={item.value !== width}
              css={tw`truncate`}
            >
              {item.label}
            </DimensionButton>
          ))}
        </div>
        {(productSelections.shape === "Square" ||
          productSelections.shape === "Rectangle") && (
          <div css={[tw`col-span-1`, { flexBasis: "33%" }]}>
            {productSelections?.height?.available?.map((item, index) => (
              <DimensionButton
                disabled={(item.value === height || loading == 'pending' || inventorySearchStatus == 'pending')}
                selected={item.value === height}
                key={index}
                onClick={() => {
                  dispatch(setProductSelectionHeight(item.value));
                }}
              >
                {item.label}
              </DimensionButton>
            ))}
          </div>
        )}
        <div css={[tw`col-span-1`, { flexBasis: "33%" }]}>
          {productSelections?.wall?.available?.map((item, index) => (
            <DimensionButton
            disabled={(item.value === wall || loading == 'pending' || inventorySearchStatus == 'pending')}
              selected={item.value === wall}
              onClick={() => {
                dispatch(updateBrowseHistory(item.productIndex));
                dispatch(setCurrentProductIndex(item.productIndex));
              }}
              key={index}
              title={item.value?.toString()}
            >
              {item.label}
            </DimensionButton>
          ))}
        </div>
      </div>
    </>
  );
};

export default SelectStockDetails;

type DimensionButtonProps = {
  children?: ReactNode;
  showCaret?: boolean;
  selected?: boolean;
  disabled?: boolean;
} & Omit<React.ComponentProps<"button">, "disabled">;

const DimensionButton: React.FC<DimensionButtonProps> = ({
  children,
  disabled,
  showCaret,
  selected,
  ...remainingProps
}) => {
  return (
    <button
      css={[
        tw`text-[#999] py-[1px] leading-none font-semibold bg-white w-full border border-[#ccc]`,
        !disabled && tw`hover:(bg-yellow-200 text-[#999]) focus-visible:(bg-yellow-200 text-[#999]) focus-visible:outline-none`,
        selected && tw`bg-nucor-light-green text-white`,
      ]}
      disabled={disabled}
      {...remainingProps}
    >
      <div css={[tw` flex justify-between px-1 py-[1px]`]}>
        <span>{children}</span>
        {showCaret && <FontAwesomeIcon icon={faCaretRight} />}
      </div>
    </button>
  );
};
