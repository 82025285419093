import React from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  formatDateToAnalyticsFormat,
  sendPageTimeSpent,
} from "../store/analytics.actions";
import { userType } from "../helpers/userType";

export type PageTimeSpentPayload = {
  Login?: string;
  PageName: string;
  UserType: string;
  DateStart: string;
  DateEnd?: string;
  Source: string;
};

/**
 * Page tracking hook that sends the page time spent to the analytics API
 */

export const usePageTracking = () => {
  // Excluded routes from page tracking. These are first level routes
  // that are immedidiatly directed to subroutes by default.
  const exclusionList = [
    "/",
    "/portal",
    "/portal/home",
    "/portal/stock",
    "/portal/myshipping",
    "/portal/mydocuments",
    "/admin",
  ];
  const location = useLocation();
  const dispatch = useAppDispatch();
  const detailsRef = React.useRef<PageTimeSpentPayload>();
  const { currentContext } = useAppSelector((state) => state.user);

  React.useEffect(() => {
    detailsRef.current = {
      Login: currentContext?.user?.email,
      PageName: location.pathname,
      UserType: userType(currentContext),
      DateStart: formatDateToAnalyticsFormat(new Date()),
      Source: "TB",
    };
    return () => {
      if (!exclusionList.includes(location.pathname) && currentContext) {
        const details = {
          ...detailsRef.current,
          DateEnd: formatDateToAnalyticsFormat(new Date()),
        };
        dispatch(sendPageTimeSpent(details));
      }
    };
  }, [location.pathname]);
};
