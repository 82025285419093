/** @jsxImportSource @emotion/react */
import React, { ReactNode, useEffect, useRef, useState } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import Loading from "../../../../../components/atoms/Loading";
import { SalesOrderItem } from "../../../../../types/types";
import AddRollingForm from "./AddRollingForm";
import EditRollingForm from "./EditRollingForm";
import RollingItemConfirmation from "./RollingItemConfirmation";
import { selectCurrentCustomerId } from "../../../../../store/customer.reducer";
import {
  selectCurrentRollings,
  setRollingItemToEdit,
} from "../../../../../store/rollings.reducer";

type Props = {
  children?: ReactNode;
};

const RollingForm: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const initialLoad = useRef(true);
  const customerId = useAppSelector(selectCurrentCustomerId);
  const [isLoading, setIsLoading] = useState(false);

  const rollingItemIdToEdit = useAppSelector(
    (state) => state.rollings.rollingItemIdToEdit
  );
  const currentRollings = useAppSelector(selectCurrentRollings);
  const editRollingItem = currentRollings.find(
    (rolling) => rolling.id === rollingItemIdToEdit
  );

  useEffect(() => {
    if (initialLoad?.current) {
      initialLoad.current = false;
      return;
    }
    dispatch(setRollingItemToEdit(undefined));
  }, [customerId]);

  return (
    <div css={tw`relative`}>
      {isLoading && <Loading />}
      {editRollingItem ? (
        <EditRollingForm
          item={editRollingItem}
          onCancel={() => {
            dispatch(setRollingItemToEdit(undefined));
          }}
        />
      ) : (
        <AddRollingForm />
      )}
      <div css={tw`w-full bg-[#ececec] text-xs pb-4`}>
        {currentRollings.length > 0 && (
          <>
            <p css={tw`font-semibold`}>
              Items added ({currentRollings.length})
            </p>
            <RollingItemConfirmation
              onLoading={(loading: boolean) => setIsLoading(loading)}
              onEdit={(item?: SalesOrderItem) => {
                dispatch(setRollingItemToEdit(item?.id));
              }}
              rollingItems={currentRollings}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default RollingForm;
