/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { ReactNode, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { updateUserInfo } from "../../../store/user.actions";
import Button from "../../../components/atoms/Button";
import { PersonalInfoFormData, schema } from "./personalInfoForm";
import Loading from "../../../components/atoms/Loading";
import ErrorDisplay from "../../../components/molecules/ErrorDisplay";
import { resetPersonalInfoState } from "../../../store/user.reducer";

type Props = {
  children?: ReactNode;
};

// This component uses an 'editableUser' object from the Redux store
// due to rerendering issues when updating the state.user in store.

const PersonalInfo: React.FC<Props> = () => {
  const user = useAppSelector((state) => state.user.currentContext?.user);
  const dispatch = useAppDispatch();
  const { status, error } = useAppSelector((state) => state.user.personalInfo);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PersonalInfoFormData>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      mobilePhone: {
        areaCode: user?.mobilePhone?.areaCode,
        exchange: user?.mobilePhone?.exchange,
        local4: user?.mobilePhone?.local4,
      },
      officePhone: {
        areaCode: user?.officePhone?.areaCode,
        exchange: user?.officePhone?.exchange,
        local4: user?.officePhone?.local4,
      },
      officePhoneExt: user?.officePhoneExt,
      faxPhone: {
        areaCode: user?.faxPhone?.areaCode,
        exchange: user?.faxPhone?.exchange,
        local4: user?.faxPhone?.local4,
      },
    },
  });

  useEffect(() => {
    return () => {
      dispatch(resetPersonalInfoState());
    };
  }, []);

  const formHandler = handleSubmit((data: PersonalInfoFormData) => {
    dispatch(updateUserInfo(data));
  });

  return (
    <>
      {status === "saved" && (
        <p css={[tw`text-xs text-nucor-green font-bold`]}>
          Personal information saved.
        </p>
      )}
      {error && <ErrorDisplay error={error} />}
      <div css={tw`flex gap-4`}>
        <div css={{ border: "1px solid #ccc" }}>
          <div
            css={[
              tw`text-xs font-bold text-white bg-nucor-green w-[550px] text-center py-1`,
            ]}
          >
            Personal Information
          </div>
          <div css={[tw`relative`]}>
            {status === "loading" && <Loading>Loading</Loading>}
            {status === "saving" && <Loading>Saving</Loading>}
            <p css={[tw`text-xs italic mt-1 ml-2`]}>
              <span css={[tw`text-red-600`]}>*</span> indicates required fields
            </p>
            <form
              onSubmit={formHandler}
              css={[
                tw`max-w-3xl text-xs`,
                css`
                  & fieldset {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    border: none;
                    margin: 0px;
                    margin-left: 8px;
                    margin-right: 8px;
                    padding: 4px;
                    & label {
                      font-size: 12px;
                      font-weight: bold;
                      color: #6d756e;
                      min-width: 20%;
                    }
                  }
                `,
              ]}
            >
              <fieldset>
                <label htmlFor="firstName">
                  First Name: <span css={tw`text-red-600`}>*</span>
                </label>
                <input
                  css={[
                    tw`bg-nucor-yellow min-w-[49%]`,
                    errors?.firstName && tw`border-red-600!`,
                  ]}
                  {...register("firstName")}
                  id="firstName"
                />
              </fieldset>
              <fieldset>
                <label htmlFor="lastName">
                  Last Name: <span css={tw`text-red-600`}>*</span>
                </label>
                <input
                  css={[
                    tw`bg-nucor-yellow min-w-[49%]`,
                    errors?.lastName && tw`border-red-600!`,
                  ]}
                  {...register("lastName")}
                  id="lastName"
                />
              </fieldset>
              <fieldset>
                <label htmlFor="email">Email: </label>
                <input
                  css={[tw`min-w-[49%] bg-gray-200 text-black/75`]}
                  defaultValue={user?.email}
                  readOnly
                  id="email"
                />
              </fieldset>
              <fieldset>
                <label htmlFor="phone">Office:</label>
                <div css={[tw`flex gap-1 items-center`]}>
                  <input
                    {...register("officePhone.areaCode")}
                    css={[
                      tw`w-12`,
                      errors?.officePhone?.areaCode && tw`border-red-500! `,
                    ]}
                    type="text"
                  />
                  <input
                    {...register("officePhone.exchange")}
                    css={[
                      tw`w-12`,
                      errors?.officePhone?.exchange && tw`border-red-500! `,
                    ]}
                    type="text"
                  />
                  <input
                    {...register("officePhone.local4")}
                    css={[
                      tw`w-16`,
                      errors?.officePhone?.local4 && tw`border-red-500! `,
                    ]}
                    type="text"
                  />
                  <span css={tw`text-xs text-[#6d756e] font-bold`}>Ext.</span>
                  <input
                    {...register("officePhoneExt")}
                    css={[
                      tw`w-16`,
                      errors?.officePhoneExt && tw`border-red-500! `,
                    ]}
                    type="text"
                  />
                </div>
              </fieldset>
              <fieldset>
                <label htmlFor="phone">Mobile:</label>
                <div css={[tw`flex gap-1 items-center`]}>
                  <input
                    {...register("mobilePhone.areaCode")}
                    css={[
                      tw`w-12`,
                      errors?.mobilePhone?.areaCode && tw`border-red-500! `,
                    ]}
                    type="text"
                  />
                  <input
                    {...register("mobilePhone.exchange")}
                    css={[
                      tw`w-12`,
                      errors?.mobilePhone?.exchange && tw`border-red-500! `,
                    ]}
                    type="text"
                  />
                  <input
                    {...register("mobilePhone.local4")}
                    css={[
                      tw`w-16`,
                      errors?.mobilePhone?.local4 && tw`border-red-500! `,
                    ]}
                    type="text"
                  />
                </div>
              </fieldset>
              <fieldset>
                <label htmlFor="phone">Fax:</label>
                <div css={[tw`flex gap-1 items-center`]}>
                  <input
                    {...register("faxPhone.areaCode")}
                    css={[
                      tw`w-12`,
                      errors?.faxPhone?.areaCode && tw`border-red-500! `,
                    ]}
                    type="text"
                  />
                  <input
                    {...register("faxPhone.exchange")}
                    css={[
                      tw`w-12`,
                      errors?.faxPhone?.exchange && tw`border-red-500! `,
                    ]}
                    type="text"
                  />
                  <input
                    {...register("faxPhone.local4")}
                    css={[
                      tw`w-16`,
                      errors?.faxPhone?.local4 && tw`border-red-500! `,
                    ]}
                    type="text"
                  />
                </div>
              </fieldset>
              <Button
                disabled={status === "saving"}
                css={[tw`ml-[22%] text-xs my-2 font-semibold`]}
                type="submit"
              >
                Save
              </Button>
            </form>
          </div>
        </div>
        <div css={[tw`text-xs`]}>
          <Link
            css={[tw`mb-2 block text-nucor-link underline font-semibold hover:(text-nucor-link-hover no-underline) focus:text-nucor-link-hover focus:outline-none`]}
            to="../preferences"
          >
            Change Preferences
          </Link>
          <Link
            css={[tw`block text-nucor-link underline font-semibold hover:(text-nucor-link-hover no-underline) focus:text-nucor-link-hover focus:outline-none`]}
            to="../notifications"
          >
            Change Notifications
          </Link>
        </div>
      </div>
    </>
  );
};

export default PersonalInfo;
