/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import tw from "twin.macro";

type Props = {
  children?: ReactNode;
};

const Notifications: React.FC<Props> = () => {
  return (
    <div>
      <p css={tw`text-nucor-green text-xs`}>
        Please contact your Inside Sales Representative to make any updates to
        your email notifications.
      </p>
    </div>
  );
};

export default Notifications;
