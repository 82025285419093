/** @jsxImportSource @emotion/react */
import React, { ReactNode, useState } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  quickQuoteSort,
  updateQuickQuotePagination,
} from "../../../../store/document.reducer";
import { fetchQuickQuotePDF, fetchQuickQuoteResults } from "../../../../store/document.actions";
import { selectCurrentCustomerId } from "../../../../store/customer.reducer";
import Loading from "../../../../components/atoms/Loading";
import TableSortButton from "../../../../components/molecules/TableSortButton";
import Toast from "../../../../components/molecules/Toast";
import Pagination, { PaginationValues } from "../../../../components/molecules/PaginationComponent";
import TextButton from "../../../../components/atoms/TextButton";
import { numberFormatter } from "../../../../helpers/numberFormat";
import { hasAuthority } from "../../../../helpers/hasAuthority";
import { selectCurrentAuthorities } from "../../../../store/user.reducer";
import { useNavigate } from 'react-router-dom';
import { getNewSort } from "../../../../helpers/getNewSort";

type Props = {
  children?: ReactNode;
};

const QuoteResults: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const customerId = useAppSelector(selectCurrentCustomerId);

  const { searchCriteria, quoteResults, status } = useAppSelector(
    (state) => state.document.quickquote
  );
  const currentAuthorities = useAppSelector(selectCurrentAuthorities);
  const isInternal = hasAuthority("internal", currentAuthorities);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const pdfHandler = (quoteId: number) => {
    if (!customerId) return;
    setErrorMessage("");
    dispatch(
      fetchQuickQuotePDF({
        quoteId: quoteId,
        pdfType: "pdf",
      })
    )
      .unwrap()
      .catch((error) => {
        setErrorMessage(error);
      });
  };

  const idClickHandler = (quoteId: number) => {
    isInternal ? navigate(`./${quoteId}`) : pdfHandler(quoteId);
  };

  const sortChanged = (field: string) => {
    if (!customerId || !quoteResults?.lastCriteria) return;
    
    const searchSort = getNewSort(field, searchCriteria);

    const c = {...quoteResults.lastCriteria, ...searchSort, page: searchCriteria.page};

    dispatch(quickQuoteSort(searchSort))
    dispatch(fetchQuickQuoteResults({customerId, criteria: c}));
  };

  const pageChanged = (page: PaginationValues) => {
    if (!customerId || !quoteResults?.lastCriteria) return;
    
    const c = {...quoteResults.lastCriteria, ...page};

    dispatch(updateQuickQuotePagination(page))
    dispatch(fetchQuickQuoteResults({customerId, criteria: c}));
  };

  return (
    <>
      {errorMessage && (
        <Toast
          type="error"
          message={errorMessage}
          onConfirm={() => setErrorMessage("")}
        />
      )}
      <div css={tw`relative`}>
        {status === "pending" && <Loading />}
        <Pagination
          currentPage={searchCriteria.page}
          isLoading={status === "pending"}
          items={quoteResults?.count ?? 0}
          onChange={(value) => pageChanged(value)}
        />
        <table className="ntp-portal-table" css={tw`w-full text-xs`}>
          <thead>
            <tr>
              <th>
                <TableSortButton
                  sort={searchCriteria}
                  field="id"
                  onClick={(field) => sortChanged(field)}
                >
                  Id
                </TableSortButton>
              </th>
              <th>
                <TableSortButton
                  sort={searchCriteria}
                  field="createdDate"
                  onClick={(field) => sortChanged(field)}
                >
                  Created Date
                </TableSortButton>
              </th>
              <th>
                {" "}
                <TableSortButton
                  sort={searchCriteria}
                  field="jobName"
                  onClick={(field) => sortChanged(field)}
                >
                  Job Name
                </TableSortButton>
              </th>
              <th>
                {" "}
                <TableSortButton
                  sort={searchCriteria}
                  field="billingCustomerId"
                  onClick={(field) => sortChanged(field)}
                >
                  Customer
                </TableSortButton>
              </th>
              <th>
                {" "}
                <TableSortButton
                  sort={searchCriteria}
                  field="customerRefNumber"
                  onClick={(field) => sortChanged(field)}
                >
                  Customer Ref#
                </TableSortButton>
              </th>
              <th>
                {" "}
                <TableSortButton
                  sort={searchCriteria}
                  field="status"
                  onClick={(field) => sortChanged(field)}
                >
                  Status
                </TableSortButton>
              </th>
              <th> Total Weight</th>
            </tr>
          </thead>
          <tbody>
            {quoteResults?.list && quoteResults.list.length > 0 ? (
              quoteResults?.list.map((quote) => (
                <tr key={quote.id}>
                  <td>
                    <TextButton onClick={() => idClickHandler(quote.id)} disabled={status === "pending"}>
                      {quote.formattedId}
                    </TextButton>
                  </td>
                  <td>{quote.formattedCreatedDate}</td>
                  <td>{quote.jobName}</td>
                  <td>
                    {quote.prospectiveCustomer
                      ? quote.customerName
                      : `${quote.billingCustomerId} - ${quote.customerName}`}
                  </td>
                  <td>{quote.customerRefNumber}</td>
                  <td>{quote.status}</td>
                  <td css={tw`text-right!`}>
                    {quote.totalWeight !== 0 &&
                      numberFormatter(quote.totalWeight)}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td css={tw`text-nucor-gray !px-2 !py-3 hover:!bg-white`} colSpan={7}>
                No quotes found for the selected criteria
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      
    </>
  );
};

export default QuoteResults;
