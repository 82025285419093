/** @jsxImportSource @emotion/react */
import React, { FormEvent, ReactNode, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import tw from "twin.macro";
import Select from "../../../../components/atoms/Select";
import Input from "../../../../components/atoms/Input";
import Button from "../../../../components/atoms/Button";
import {
  fetchPOSearchResults,
  fetchPOSearchSelectionData,
} from "../../../../store/document.actions";
import { selectCurrentCustomerId } from "../../../../store/customer.reducer";

type Props = {
  children?: ReactNode;
};

const POSearchForm: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const { selectionData, status } = useAppSelector(
    (state) => state.document.poSearch
  );
  const customerId = useAppSelector(selectCurrentCustomerId);

  const [customerPONumber, setCustomerPONumber] = useState("");
  const [customerSearchType, setCustomerSearchType] = useState(false);

  useEffect(() => {
    dispatch(fetchPOSearchSelectionData());
  }, []);

  const searchHandler = (e: FormEvent) => {
    e.preventDefault();
    dispatch(
      fetchPOSearchResults({
        customerId: customerSearchType ? undefined : customerId,
        customerPONumber,
      })
    );
  };

  return (
    <form
      onSubmit={searchHandler}
      css={tw`w-full bg-gradient-to-b from-nucor-gradient-outer via-nucor-gradient-inner to-nucor-gradient-outer py-1 px-2 mt-2 flex gap-2 items-center`}
    >
      <div css={tw`flex items-center gap-2`}>
        <label css={tw`text-nucor-gray`}>
          Show: <span css={tw`text-red-600`}>*</span>
        </label>
        <Select
          name="customerSearchType"
          minWidth="20ch"
          data={selectionData?.customerSearchType.map((item) => ({
            value: item.id,
            label: item.description,
          }))}
          value={customerSearchType}
          onChange={(value: boolean) => setCustomerSearchType(value)}
        />
      </div>
      <div css={tw`flex items-center gap-2`}>
        <label css={tw`text-nucor-gray ml-4`}>
          Customer PO #: <span css={tw`text-red-600`}>*</span>
        </label>
        <Input
          value={customerPONumber}
          onChange={(e) => setCustomerPONumber(e.target.value)}
          name="customerPONumber"
        />
      </div>
      <Button
        type="submit"
        disabled={!customerPONumber || status === "pending"}
        css={tw`ml-4`}
      >
        Search
      </Button>
    </form>
  );
};

export default POSearchForm;
