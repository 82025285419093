/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Loading from "../../../../components/atoms/Loading";
import OpenLoadsItem from "./OpenLoadsItem";
import TableSortButton from "../../../../components/molecules/TableSortButton";
import { openLoadsSort } from "../../../../store/document.reducer";
import spinner from "../../../../assets/images/spinner.gif";
import { selectCurrentCustomerId } from "../../../../store/customer.reducer";
import { getNewSort } from "../../../../helpers/getNewSort";
import { searchOpenLoads } from "../../../../store/document.actions";

type Props = {
  children?: ReactNode;
};

const OpenLoadsResults: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const customerId = useAppSelector(selectCurrentCustomerId);
  const { openLoadsResults, status, searchCriteria, lastCriteria } = useAppSelector(
    (state) => state.document.openLoads
  );

  const hasResults = status !== "pending" && openLoadsResults && openLoadsResults.length > 0;
  const noResults = status !== "pending" && (!openLoadsResults || openLoadsResults.length === 0);
  
  const sortChanged = (field: string) => {
    if (!customerId || !lastCriteria) return;
    
    const searchSort = getNewSort(field, searchCriteria);

    const c = {...lastCriteria, ...searchSort};

    dispatch(openLoadsSort(searchSort))
    dispatch(searchOpenLoads({customerId, criteria: c}));
  };

  return (
    <div css={tw`relative`}>
      {status === "pending" && <Loading />}

      <div css={tw`flex text-xs justify-end items-center bg-gradient-to-b from-nucor-gradient-outer via-nucor-gradient-inner to-nucor-gradient-outer mt-2 py-1 px-2`}>
        {hasResults && (<div>{openLoadsResults.length} Load{openLoadsResults.length > 1 && "s"}</div>)}
        {noResults && (<div>No Results</div>)}
        {status === "pending" && (<><img css={tw`h-4 mr-1`} src={spinner} /> <div>Loading</div></>)}
      </div>
      <table className="ntp-portal-table ntp-remove-hover ntp-remove-stripe" css={tw`w-full text-xs`}>
        <thead>
          <tr>
            <th>
              <TableSortButton
                sort={searchCriteria}
                field="loadId"
                onClick={(field) => sortChanged(field)}
              >
                Load Id
              </TableSortButton>
            </th>
            <th>
              <TableSortButton
                sort={searchCriteria}
                field="createDate"
                onClick={(field) => sortChanged(field)}
              >
                Release Date
              </TableSortButton>
            </th>
            <th>
              <TableSortButton
                sort={searchCriteria}
                field="site"
                onClick={(field) => sortChanged(field)}
              >
                Mill
              </TableSortButton>
            </th>
            <th>
              <TableSortButton
                sort={searchCriteria}
                field="deliveryRouteId"
                onClick={(field) => sortChanged(field)}
              >
                Delivery Route ID
              </TableSortButton>
            </th>
            <th>
              <TableSortButton
                sort={searchCriteria}
                field="billingCustomerId"
                onClick={(field) => sortChanged(field)}
              >
                Customer
              </TableSortButton>
            </th>
            <th>Ship To</th>
            <th>
              <TableSortButton
                sort={searchCriteria}
                field="scheduledShipDate"
                onClick={(field) => sortChanged(field)}
              >
                Scheduled Ship Date
              </TableSortButton>
            </th>
            <th>
              <TableSortButton
                sort={searchCriteria}
                field="deliveryMode"
                onClick={(field) => sortChanged(field)}
              >
                Delivery Mode
              </TableSortButton>
            </th>
            <th>
              <TableSortButton
                sort={searchCriteria}
                field="proBillNumber"
                onClick={(field) => sortChanged(field)}
              >
                Release/Pro Bill
              </TableSortButton>
            </th>
            <th>
              <TableSortButton
                sort={searchCriteria}
                field="totalWeight"
                onClick={(field) => sortChanged(field)}
              >
                Weight
              </TableSortButton>
            </th>
            <th>
              <TableSortButton
                sort={searchCriteria}
                field="carrier"
                onClick={(field) => sortChanged(field)}
              >
                Carrier
              </TableSortButton>
            </th>
          </tr>
        </thead>
        <tbody>
          {openLoadsResults && openLoadsResults.length > 0 ? (
            openLoadsResults.map((item, index) => (
              <OpenLoadsItem key={item.loadId} index={index} line={item} />
            ))
          ) : (
            <tr>
              <td css={tw`text-nucor-gray !px-2 !py-3 hover:!bg-white`} colSpan={11}>
                No open loads found for the selected criteria
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default OpenLoadsResults;
