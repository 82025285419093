/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import { Route, Routes } from "react-router-dom";
import InquiryCart from "./inquiry/InquiryCart";
import ReviewShoppingCart from "./shopping/ReviewShoppingCart";
import ShoppingCart from "./shopping/ShoppingCart";
import NotFound from "../../../app/NotFound";

type Props = {
  children?: ReactNode;
};

const Cart: React.FC<Props> = () => {
  return (
    <Routes>
      <Route path="" element={<ShoppingCart />} />
      <Route path="shopping" element={<ShoppingCart />} />
      <Route path="inquiry" element={<InquiryCart />} />
      <Route path="shoppingreview" element={<ReviewShoppingCart />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Cart;
