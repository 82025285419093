/** @jsxImportSource @emotion/react */
import React, { ReactNode, useState } from "react";
import tw from "twin.macro";
import { useAppSelector } from "../../../../../app/hooks";
import BrowseTab from "./BrowseTab";
import BrowsingHistory from "./BrowsingHistory";
import SearchTab from "./SearchTab";

type Props = {
  children?: ReactNode;
};

const PrimeLeft: React.FC<Props> = () => {
  const [currentTab, setCurrentTab] = useState<"browse" | "search">("browse");
  const showBrowseHistory = useAppSelector(
    (state) =>
      state.user.currentContext?.userPreferences?.hideBrowseHistory !== true
  );

  return (
    <div css={[tw`text-xs relative`]}>
      <p css={[tw`text-sm font-bold text-nucor-green py-3`]}>Select Stock:</p>
      <div css={[tw`flex gap-1`]}>
        <button
          onClick={() => setCurrentTab("browse")}
          css={[
            tw`py-1 px-2 bg-nucor-component-background text-nucor-gray font-bold focus-visible:outline-1 focus-visible:outline-dotted focus-visible:-outline-offset-1 focus-visible:outline-nucor-gray focus:outline-none`,
            currentTab !== "browse" &&
              tw`bg-nucor-component-background-disabled text-[#999]`,
          ]}
        >
          Browse
        </button>
          <button
            onClick={() => setCurrentTab("search")}
            css={[
              tw`py-1 px-2 bg-nucor-component-background text-nucor-gray font-bold focus-visible:outline-1 focus-visible:outline-dotted focus-visible:-outline-offset-1 focus-visible:outline-nucor-gray focus:outline-none`,
              currentTab !== "search" &&
                tw`bg-nucor-component-background-disabled text-[#999]`,
            ]}
          >
            Search
          </button>
      </div>
      {currentTab === "browse" && <BrowseTab />}
      {currentTab === "search" && <SearchTab />}
      {showBrowseHistory && (
        <div>
          <BrowsingHistory />
        </div>
      )}
    </div>
  );
};

export default PrimeLeft;
