/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import tw from "twin.macro";
import { ShipToLoad } from "../../../../../types/types";
import ShipTo from "../../../stock/cart/components/ShipTo";
import LoadTable from "./LoadTable";

type Props = {
  children?: ReactNode;
  shipToLoad: ShipToLoad;
  readOnly?: boolean;
  onCancel: () => void;
};

const ReviewLoadSite: React.FC<Props> = ({
  shipToLoad,
  readOnly,
  onCancel,
}) => {
  return (
    <>
      <div css={tw`bg-[#eee] p-2 text-xs`}>
        <ShipTo customer={shipToLoad.shipTo} />
      </div>
      <LoadTable
        onCancel={onCancel}
        readOnly={readOnly}
        lines={shipToLoad.lines}
      />
      <div css={tw`h-6 bg-[#eee]`} />
    </>
  );
};

export default ReviewLoadSite;
