/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import Modal from "./Modal";
import tw from "twin.macro";
import { CustomerPart } from "../../types/types";

type Props = {
  children?: ReactNode;
  details: CustomerPart;
  onCancel: () => void;
};

const PartDescriptionModal: React.FC<Props> = ({ details, onCancel }) => {
  return (
    <Modal title="Customer Part Detail" onCancel={onCancel}>
      <dl css={tw`p-2 grid grid-cols-[1fr_2fr]`}>
        <dt>Part Number:</dt>
        <dd>{details.customerPartNumber}</dd>
        <dt>Description 1:</dt>
        <dd>{details.customerPartDescription}</dd>
        <dt>Description 2:</dt>
        <dd>{details.externalItemText}</dd>
        <dt>Description 3:</dt>
        <dd>{details.itemDescription}</dd>
      </dl>
    </Modal>
  );
};

export default PartDescriptionModal;
