/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import tw from "twin.macro";

type Props = {
  children?: ReactNode;
};

const Disclaimer: React.FC<Props> = () => {
  return (
    <>
      <p css={tw`mt-6`}>
        Quoting ASTM A500 Grade B unless otherwise specified. Mill length
        tolerances are +2&quot;/ -0&quot;. 45,000 lbs minimum per truckload with
        lengths 53&apos; and shorter and 180,000 lbs. minimum per rail with
        lengths 80&apos; and shorter required to ship. All prices are FOB Mill
        unless otherwise stated. Tube price, freight and fuel surcharge are
        price in effect at time of shipment.
      </p>
      <p css={tw`my-4`}>
        Disclaimer: All quotes are valid for 5 days from the original quote date
        unless otherwise specified. All Cutting Quotes 60&quot; and under are
        subject to Manager approval. All orders submitted with a purchase order
        are not considered firm or binding until NUCOR TUBULAR PRODUCTS INC.
        acknowledges with a sales order number. All inventories are subject to
        prior sale. Mill promise dates are subject to available capacity and raw
        material constraints. Please add one week to those dates for delivery.
        All quoted prices are price in effect at time of shipment unless
        otherwise noted. All orders are subject to the{" "}
        <a
          target="_blank"
          rel="noreferrer"
          css={tw`text-nucor-link hover:text-nucor-link-hover font-bold focus-visible:text-nucor-link-hover focus-visible:outline-none`}
          href="/terms"
        >
          terms and conditions
        </a>{" "}
        of sale
      </p>
    </>
  );
};

export default Disclaimer;
