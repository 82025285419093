/** @jsxImportSource @emotion/react */
import { useAuth0 } from "@auth0/auth0-react";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactNode } from "react";
import tw from "twin.macro";
import { useAppSelector } from "../../app/hooks";
import Button from "../atoms/Button";
import Spinner from "../atoms/Spinner";
import { isErrorResponse } from "../../types/predicates";

type Props = {
  children?: ReactNode;
};

const FetchingUserDialog: React.FC<Props> = () => {
  const error = useAppSelector((state) => state.user.error);
  const loading = useAppSelector((state) => state.user.loading);
  const { logout } = useAuth0();

  return (
    <section css={tw`flex-grow`}>
      <div
        css={[
          tw`min-w-[45ch] p-6 shadow-lg mt-36 flex flex-col text-sm`,
          { border: "1px solid #ddd" },
        ]}
      >
        <p css={tw`text-nucor-light-green`}>
          <FontAwesomeIcon css={tw`mr-2`} icon={faLock} />
          Login
        </p>
        {loading && (
          <>
            <p
              css={[tw`mt-4 mb-1 text-center font-bold text-nucor-light-green`]}
            >
              Fetching user data
            </p>
            <Spinner css={tw`mt-4`} />
          </>
        )}
        {error && isErrorResponse(error) ? (
          <p css={[tw`mt-4 mb-1 text-red-600 text-center`]}>{error.message}</p>
        ) : (
          <p css={[tw`mt-4 mb-1 text-red-600 text-center`]}>{error}</p>
        )}
        {error && (
          <Button
            css={[
              tw`mt-4 bg-nucor-light-green text-white font-bold rounded-sm py-1 px-2 text-center`,
            ]}
            onClick={() => logout({ returnTo: window.location.origin })}
          >
            Back to Login
          </Button>
        )}
      </div>
    </section>
  );
};

export default FetchingUserDialog;
