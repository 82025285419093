/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import SubNavTabs from "../../../components/organisms/SubNavTabs";
import BillsOfLading from "./BillsOfLading/BillsOfLading";
import OpenLoads from "./OpenLoads/OpenLoads";
import OpenOrders from "./OpenOrders/OpenOrders";
import ReleaseOrders from "./ReleaseOrders/ReleaseOrders";
import NotFound from "../../app/NotFound";
import AuthorityRoutes from "../../../routing/AuthorityRoutes";
import { SubmenuType } from "../../admin/Admin";
import { useAppSelector } from "../../../app/hooks";
import { selectCurrentAuthorities } from "../../../store/user.reducer";

type Props = {
  children?: ReactNode;
};

const submenu: SubmenuType[] = [
  {
    title: "Release Orders",
    to: "orderreleaselist",
    showCustId: true,
    authority: "createOrderQuoteLoad",
  },
  {
    title: "Open Orders",
    to: "openorders",
    showCustId: true,
    authority: "viewShippingDocs",
  },
  {
    title: "Open Loads",
    to: "openloads",
    showCustId: true,
    authority: "viewShippingDocs",
  },
  {
    title: "Bills of Lading",
    to: "shippingshippinglist",
    showCustId: true,
    authority: "viewShippingDocs",
  },
];

const MyShipping: React.FC<Props> = () => {
  const location = useLocation();
  const currentAuthorities = useAppSelector(selectCurrentAuthorities);

  const defaultRoute = currentAuthorities.includes("createOrderQuoteLoad")
    ? "orderreleaselist"
    : "openorders";

  return (
    <>
      <SubNavTabs submenu={submenu} />
      <Routes>
        <Route
          path=""
          element={
            <Navigate
              to={{ pathname: defaultRoute, search: location.search }}
            />
          }
        />
        <Route
          element={<AuthorityRoutes authority={["createOrderQuoteLoad"]} />}
        >
          <Route path="orderreleaselist" element={<ReleaseOrders />} />
        </Route>
        <Route element={<AuthorityRoutes authority={["viewShippingDocs"]} />}>
          <Route path="openorders" element={<OpenOrders />} />
          <Route path="openloads" element={<OpenLoads />} />
          <Route path="shippingshippinglist" element={<BillsOfLading />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default MyShipping;
