import { createSlice } from "@reduxjs/toolkit";
import { CustomerPart, RequestStatus } from "../types/types";
import {
  fetchCustomerPartDetails,
  fetchCustomerParts,
  fetchCustomerPartsExcel,
  fetchCustomerPartsPDF
} from "./parts.actions";
import { RootState } from "./store";

type InitialState = {
  status: RequestStatus;
  detailStatus: RequestStatus;
  docStatus: RequestStatus;
  currentCustomerParts: CustomerPart[];
};

const initialState: InitialState = {
  status: "idle",
  detailStatus: "idle",
  docStatus: "idle",
  currentCustomerParts: [],
};

const partsSlice = createSlice({
  name: "parts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCustomerParts.pending, (state) => {
      state.status = "pending";
      state.currentCustomerParts = [];
    });
    builder.addCase(fetchCustomerParts.fulfilled, (state, action) => {
      state.status = "success";
      state.currentCustomerParts = action.payload;
    });
    builder.addCase(fetchCustomerParts.rejected, (state) => {
      state.status = "error";
    });
    builder.addCase(fetchCustomerPartDetails.pending, (state) => {
      state.detailStatus = "pending";
    });
    builder.addCase(fetchCustomerPartDetails.fulfilled, (state) => {
      state.detailStatus = "success";
    });
    builder.addCase(fetchCustomerPartDetails.rejected, (state) => {
      state.detailStatus = "error";
    });
    builder.addCase(fetchCustomerPartsExcel.pending, (state) => {
      state.docStatus = "pending";
    });
    builder.addCase(fetchCustomerPartsExcel.fulfilled, (state) => {
      state.docStatus = "success";
    });
    builder.addCase(fetchCustomerPartsExcel.rejected, (state) => {
      state.docStatus = "error";
    });
    builder.addCase(fetchCustomerPartsPDF.pending, (state) => {
      state.docStatus = "pending";
    });
    builder.addCase(fetchCustomerPartsPDF.fulfilled, (state) => {
      state.docStatus = "success";
    });
    builder.addCase(fetchCustomerPartsPDF.rejected, (state) => {
      state.docStatus = "error";
    });
  },
});

export default partsSlice.reducer;

export const selectCurrentCustomerParts = (state: RootState) =>
  state.parts.currentCustomerParts;
