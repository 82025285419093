/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import SubNavTabs from "../../../components/organisms/SubNavTabs";
import BillsOfLading from "../myshipping/BillsOfLading/BillsOfLading";
import OpenLoads from "../myshipping/OpenLoads/OpenLoads";
import ClaimList from "./claims/ClaimList";
import InquiryList from "./inquiryList/InquiryList";
import PortalQuote from "./portalQuote/quoteList";
import InvoicesList from "./invoices/InvoicesList";
import OrdersList from "./orders/OrdersList";
import CertsList from "./testcerts/CertsList";
import POSearch from "./poSearch/POSearch";
import DocumentSearch from "./documentSearch/DocumentSearch";
import NotFound from "../../app/NotFound";
import { SubmenuType } from "../../admin/Admin";
import AuthorityRoutes from "../../../routing/AuthorityRoutes";
import { useAppSelector } from "../../../app/hooks";
import { selectCurrentAuthorities } from "../../../store/user.reducer";
import { Authority } from "../../../types/types";
import CreateClaimForm from "./claims/CreateClaimForm";

type Props = {
  children?: ReactNode;
};

const submenu: SubmenuType[] = [
  {
    title: "Portal Quotes",
    to: "portalquote",
    showCustId: true,
    authority: ["viewOrderInvoice"],
  },
  {
    title: "Inquiries",
    to: "inquirylist",
    showCustId: true,
    authority: ["viewOrderInvoice"],
  },
  {
    title: "Open Loads",
    to: "openloads",
    showCustId: true,
    authority: "viewShippingDocs",
  },
  {
    title: "Orders",
    to: "orderlist",
    showCustId: true,
    authority: ["viewOrderInvoice"],
  },
  {
    title: "Bills of lading",
    to: "shippinglist",
    showCustId: true,
    authority: "viewShippingDocs",
  },
  {
    title: "Invoices",
    to: "invoicelist",
    showCustId: true,
    authority: ["viewOrderInvoice"],
  },
  {
    title: "Test Certs",
    to: "testcertificatelist",
    showCustId: true,
    authority: "viewShippingDocs",
  },
  {
    title: "Claims",
    to: "claimslist",
    showCustId: true,
    authority: "viewShippingDocs",
  },
  {
    title: "Search",
    to: "documentsearch",
    showCustId: true,
    authority: ["viewOrderInvoice", "viewShippingDocs"],
  },
  {
    title: "PO Search",
    to: "podocumentsearch",
    showCustId: true,
    authority: ["viewPoSearch"],
  },
];

const MyDocuments: React.FC<Props> = () => {
  const location = useLocation();
  const currentAuthorities = useAppSelector(selectCurrentAuthorities);

  const defaultRoute = !(
    ["viewOrderInvoice", "viewShippingDocs"] as Authority[]
  ).some((authority) => currentAuthorities.includes(authority))
    ? "documentsearch"
    : currentAuthorities.includes("viewOrderInvoice")
    ? "inquirylist"
    : "openloads";

  return (
    <>
      <SubNavTabs submenu={submenu} />
      <Routes>
        <Route
          path=""
          element={
            <Navigate
              to={{ pathname: defaultRoute, search: location.search }}
            />
          }
        />
        <Route element={<AuthorityRoutes authority={["viewOrderInvoice"]} />}>
          <Route path="portalquote" element={<PortalQuote />} />
          <Route path="portalquote/:quoteId" element={<PortalQuote />} />
          <Route path="inquirylist" element={<InquiryList />} />
          <Route path="orderlist" element={<OrdersList />} />
          <Route path="invoicelist" element={<InvoicesList />} />
        </Route>
        <Route element={<AuthorityRoutes authority={["viewShippingDocs"]} />}>
          <Route path="openloads" element={<OpenLoads />} />
          <Route path="shippinglist" element={<BillsOfLading />} />
          <Route path="testcertificatelist" element={<CertsList />} />
          <Route path="claimslist" element={<ClaimList />} />
          <Route path="createclaim" element={<CreateClaimForm />} />
        </Route>
        <Route
          element={
            <AuthorityRoutes
              authority={["viewOrderInvoice", "viewShippingDocs"]}
            />
          }
        >
          <Route path="documentsearch" element={<DocumentSearch />} />
        </Route>
        <Route element={<AuthorityRoutes authority={["viewPoSearch"]} />}>
          <Route path="podocumentsearch" element={<POSearch />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default MyDocuments;
