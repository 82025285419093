/** @jsxImportSource @emotion/react */
import React, {
  ReactNode,
  useContext,
  useState,
} from "react";
import tw from "twin.macro";
import Button from "../../../../components/atoms/Button";
import Input from "../../../../components/atoms/Input";
import { ProductSelectionDetails } from "../../../../types/types";
import LengthComponent from "../../../../components/molecules/LengthComponent";
import DimensionsSelectComponent from "../../myshipping/components/DimensionsSelectComponent";
import DatePicker from "../../../../components/atoms/DatePicker";
import { dateToString } from "../../../../helpers/dateHelpers";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { selectCurrentCustomerId } from "../../../../store/customer.reducer";
import { searchTestCertDocument } from "../../../../store/document.actions";
import Toast from "../../../../components/molecules/Toast";
import { PDFContext } from "./CertsList";
import { lengthToInches } from "../../../../helpers/lengthHelpers";
import { searchParameterExists } from "../../../../helpers/searchParameterExists";
import { updateTestCertSearchCriteria } from "../../../../store/document.reducer";

type Props = {
  children?: ReactNode;
};

const CertsSearch: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const customerId = useAppSelector(selectCurrentCustomerId);
  const { searchCriteria, error } = useAppSelector(
    (state) => state.document.testCert
  );
  const ctx = useContext(PDFContext);

  const [customerPONumber, setCustomerPONumber] = useState("");
  const [fromDate, setFromDate] = useState<Date | undefined>();
  const [toDate, setToDate] = useState<Date | undefined>();
  const [salesOrderNumber, setSalesOrderNumber] = useState("");
  const [heatNumber, setHeatNumber] = useState("");
  const [dimensions, setDimensions] = useState<ProductSelectionDetails>({
    grade: "",
    shape: "Unknown",
    height: 0,
    width: 0,
    wall: 0,
  });
  const [length, setLength] = useState({
    feet: 0,
    inches: 0,
    fraction: 0,
  });

  const lengthInInches = lengthToInches(length);

  const resetForm = () => {
    setCustomerPONumber("");
    setFromDate(undefined);
    setToDate(undefined);
    setSalesOrderNumber("");
    setHeatNumber("");
    setDimensions({
      grade: "",
      shape: "Unknown",
      height: 0,
      width: 0,
      wall: 0,
    });
    setLength({
      feet: 0,
      inches: 0,
      fraction: 0,
    });
  };

  const criteria = {
    customerPONumber: searchParameterExists(customerPONumber) ? customerPONumber : undefined,
    fromDate: fromDate ? dateToString(fromDate) : undefined,
    toDate: fromDate ? dateToString(toDate) : undefined,
    salesOrderNumber: searchParameterExists(salesOrderNumber) ? salesOrderNumber : undefined,
    heat: searchParameterExists(heatNumber) ? heatNumber : undefined,
    length: lengthInInches === 0 ? undefined : lengthInInches,
    grade: dimensions?.grade === "" ? undefined : dimensions?.grade,
    shape: dimensions?.shape === "Unknown" ? undefined : dimensions?.shape,
    height: dimensions?.height === 0 ? undefined : dimensions?.height,
    width: dimensions?.width === 0 ? undefined : dimensions?.width,
    decimalWallThickness: dimensions?.wall === 0 ? undefined : dimensions?.wall,
  };

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Close pdf if open
    if (ctx?.file) {
      ctx.setFile(null);
    }

    dispatch(updateTestCertSearchCriteria({ ...searchCriteria, page: 1}));
    dispatch(searchTestCertDocument({customerId, criteria: { ...criteria, page: 1, pageSize: 25}}));
  };

  return (
    <div>
      {error && <Toast type="error" message={error} onConfirm={console.log} />}
      <p css={tw`text-nucor-gray mt-2`}>Enter any combination:</p>
      <form onSubmit={submitHandler} css={tw`flex w-full flex-col`}>
        <fieldset css={tw`mt-2`}>
          <label htmlFor="customerPO" css={tw`block`}>Customer PO #</label>
          <Input
            name="customerPONumber"
            value={customerPONumber}
            onChange={(e) => setCustomerPONumber(e.target.value)}
            css={tw`block w-[22ch]`}
          />
        </fieldset>
        <fieldset css={tw`mt-2`}>
          <label htmlFor="fromDate">From Certificate Date</label>
          <DatePicker
            css={tw`bg-white`}
            name="fromDate"
            value={fromDate}
            onChange={setFromDate}
          />
        </fieldset>
        <fieldset css={tw`mt-2`}>
          <label htmlFor="toDate">To Certificate Date</label>
          <DatePicker
            css={tw`bg-white`}
            name="toDate"
            value={toDate}
            onChange={setToDate}
          />
        </fieldset>
        <fieldset css={tw`mt-2`}>
          <label htmlFor="salesOrder" css={tw`block`}>Sales Order #</label>
          <Input
            name="salesOrderNumber"
            value={salesOrderNumber}
            onChange={(e) => setSalesOrderNumber(e.target.value)}
            css={tw`block w-[22ch]`}
          />
        </fieldset>
        <fieldset css={tw`mt-2`}>
          <label htmlFor="heatNumber" css={tw`block`}>Heat #</label>
          <Input
            name="heatNumber"
            value={heatNumber}
            onChange={(e) => setHeatNumber(e.target.value)}
            css={tw`block w-[22ch]`}
          />
        </fieldset>
        <LengthComponent
          value={length}
          css={tw`mt-2`}
          onLengthChange={(len) => setLength(len)}
        />
        <DimensionsSelectComponent
          value={dimensions}
          onChange={(dim) => setDimensions(dim)}
        />
        <div css={tw`w-full text-right`}>
          <Button css={tw`mx-4 py-[2px] text-xs`} onClick={resetForm}>
            Clear
          </Button>
          <Button css={tw`m-0 py-[2px] font-normal text-xs mt-3`} type="submit">
            Search
          </Button>
        </div>
      </form>
    </div>
  );
};

export default CertsSearch;
