/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import tw from "twin.macro";

type Props = {
  children?: ReactNode;
  title?: string;
  disabled?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const FieldsetSection: React.FC<Props> = ({
  children,
  disabled,
  title,
  ...remainingProps
}) => {
  return (
    <div css={tw`border border-nucor-gray`} {...remainingProps}>
      <p
        css={tw`bg-nucor-green text-white font-bold text-xs text-center py-px`}
      >
        {title}
      </p>
      <fieldset disabled={disabled} css={tw`p-2`}>
        {children}
      </fieldset>
    </div>
  );
};

export default FieldsetSection;
