/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import tw from "twin.macro";
import Input from "../../../../components/atoms/Input";
import DatePicker from "../../../../components/atoms/DatePicker";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Button from "../../../../components/atoms/Button";
import { stringToBase64 } from "../../../../helpers/stringToBase64";
import Select from "../../../../components/atoms/Select";
import { dateToString, stringToDate } from "../../../../helpers/dateHelpers";
import {
  ErrorResponse,
  QQStatus,
  QuickQuoteLine,
  QQCustomer,
  QQSetupRequestParams,
  QQDateUpdateParams,
} from "../../../../types/types";
import {
  addQuickQuote,
  deleteQuickQuoteLine,
  fetchQuickQuotePDF,
  fetchQuickQuoteSetup,
  updateQuickQuote,
  updateQuickQuoteDueDates,
  fetchQuickQuoteItemPricing,
} from "../../../../store/document.actions";
import { clearDocumentErrors, clearQuickQuoteView, updateQuickQuotePagination, updateSelectedQuoteId } from "../../../../store/document.reducer";
import Loading from "../../../../components/atoms/Loading";
import QuoteLines from "./quoteLines";
import AnimatedLoadingSpinner from "../../../../components/atoms/AnimatedLoadingSpinner";
import TextArea from "../../../../components/atoms/TextArea";
import ChangeAllPrices from "./quoteChangeAllPrices";
import ChangeDueDates from "../../../../components/molecules/ChangeDueDates";
import TextButton from "../../../../components/atoms/TextButton";
import Toast from "../../../../components/molecules/Toast";
import ErrorDisplay from "../../../../components/molecules/ErrorDisplay";


type Props = {
  children?: ReactNode;
  selectedQuote: number;
  newProspectiveCustomer: boolean;
  cancelNewQuote: () => void;
};

const QuoteEdit: React.FC<Props> = ({ selectedQuote, newProspectiveCustomer, cancelNewQuote }) => {
  const { status: loadStatus } = useAppSelector(
    (state) => state.document.quickquote
  );
  const [quoteStatus, setQuoteStatus] = useState("New");
  const [error, setError] = useState<string | ErrorResponse>();
  const [linesError, setLinesError] = useState<string | ErrorResponse>();
  const [purchaseOrder, setPurchaseOrder] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [notesToCustomer, setNotesToCustomer] = useState("");
  const [internalNotes, setInternalNotes] = useState("");
  const [jobName, setJobName] = useState("");
  const [customerRefNumber, setCustomerRefNumber] = useState("");
  const [expirationDate, setExpirationDate] = useState<Date | undefined>();
  const [areaCode, setAreaCode] = useState("");
  const [exchange, setExchange] = useState("");
  const [local4, setLocal4] = useState("");
  const [officePhoneExt, setOfficePhoneExt] = useState("");
  const [newShipToAddress, setNewShipToAddress] = useState("");
  const [lines, setLines] = useState<QuickQuoteLine[]>([]);
  const [newShipTo, setNewShipTo] = useState(false);
  const [statuses, setStatuses] = useState<QQStatus[]>([]);
  const [customers, setCustomers] = useState<QQCustomer[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [hideAddRow, setHideAddRow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [dateModalOpen, setDateModalOpen] = useState(false);
  const dispatch = useAppDispatch();
  const selectedCustomerAddress = customers.find(
    (customer) => customer.id === selectedCustomer
  );
  const [formattedQuoteID, setFormattedQuoteID] = useState("");
  const initialRender = useRef(true);

  const currentCustomer = useAppSelector(
    (state) => state.customer.currentCustomer
  );

  const currentQuickQuote = useAppSelector(
    (state) => state.document.quickquote.quickQuoteSetup?.quickQuote
  );

  const currentErrors = useAppSelector(
    (state) => state.document.quickquote.quickQuoteSetup?.quickQuote?.errors
  );

  const clearErrors = () => {
    dispatch(clearDocumentErrors());
    setError("");
  }

  useEffect(() => {
    if (currentErrors && currentErrors.length > 0) {
      const errorResponse: ErrorResponse = {
        message: "",
        validations: currentErrors.map((error) => ({
          path: "",
          field: "",
          message: error,
        })),
        path: "",
        status: 400,
        timestamp: new Date().toISOString(),
      };
      setLinesError(errorResponse);
    } else {
      setLinesError(undefined); 
    }
  }
  , [currentErrors]);

  useEffect(() => {

    if (currentQuickQuote) {
      setContactEmail(currentQuickQuote.contactEmail || "");
      setContactName(currentQuickQuote.contactName || "");
      setCustomerRefNumber(currentQuickQuote.customerRefNumber || "");
      setExpirationDate(stringToDate(currentQuickQuote.expirationDate));
      setInternalNotes(currentQuickQuote.internalNotes || "");
      setJobName(currentQuickQuote.jobName || "");
      setNotesToCustomer(currentQuickQuote.customerNotes || "");
      setOfficePhoneExt(currentQuickQuote.contactPhoneExt || "");
      setAreaCode(currentQuickQuote.contactPhone?.areaCode || "");
      setExchange(currentQuickQuote.contactPhone?.exchange || "");
      setLocal4(currentQuickQuote.contactPhone?.local4 || "");
      setPurchaseOrder(currentQuickQuote.customerPoNumber || "");
      setQuoteStatus(currentQuickQuote.status);
      setLines(currentQuickQuote.lines);
      setNewShipTo(currentQuickQuote.newShipTo);
      if (currentQuickQuote.newShipTo) {
        setNewShipToAddress(currentQuickQuote.address || "");
      }
      setSelectedCustomer(currentQuickQuote.shippingCustomerId);
    }
  }, [currentQuickQuote]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      dispatch(updateSelectedQuoteId(0));
      dispatch(clearQuickQuoteView());
      updateQuickQuotePagination({ page: 1, pageSize: 1 });
      cancelNewQuote();
    }
  }, [currentCustomer]);
  
  useEffect(() => {
    // get the setup data
    const requestParams: QQSetupRequestParams = {
      quickQuoteId: selectedQuote === 0 ? undefined : selectedQuote,
    };

    if (newProspectiveCustomer) {
      requestParams.prospectiveCustomer = newProspectiveCustomer;
    }
    if (selectedQuote === 0 && !newProspectiveCustomer) {
      requestParams.customerId = currentCustomer?.id || "";
    }

    dispatch(fetchQuickQuoteSetup(requestParams))
      .unwrap()
      .then((response) => {
        setStatuses(response.statuses);
        setCustomers(response.shipTos);
        setSelectedCustomer(response.quickQuote.shippingCustomerId);
        setFormattedQuoteID(response.quickQuote.formattedId);
        setCustomerName(response.quickQuote.customerName || "");
      });
  }, [selectedQuote, dispatch]);

  const addNewRow = () => {
    const formattedDate = new Date().toISOString().split('T')[0];
    const newLine: QuickQuoteLine = {
      createdById: 0,
      createdDate: formattedDate,
      customerRequestDate: undefined,
      detail: {
        notes: "",
        rollingSelections: [],
        stockSelections: [],
      },
      errors: [],
      estimatedReadyDate: undefined,
      grade: "",
      id: 990000 + Math.floor(Math.random() * 10000),
      itemId: "",
      itemName: "",
      itemType: "Item",
      length: 0,
      lengthDescription: "",
      notes: "",
      ordinal: 0,
      quantity: 0,
      quantityType: "Pieces",
      quantityDescription: "",
      quickQuoteId: 0,
      quotingUserId: 0,
      totalCost: 0,
      totalLength: 0,
      totalPieces: 0,
      totalWeight: 0,
      unitPrice: 0,
      unitPriceUOM: "CWT",
      updatedById: 0,
      valid: false,
      formattedTotalLengthFt: "",
      formattedTotalPieces: "",
      formattedTotalWeight: "",
    };
    setLines([...lines, newLine]);
  };

  const saveHandler = () => {
    const date = dateToString(expirationDate);
    const payload = {
      newShipTo: selectedCustomer === "" || currentQuickQuote?.prospectiveCustomer ? true : false,
      status: quoteStatus,
      encodedAddress:
        selectedCustomer === "" || currentQuickQuote?.prospectiveCustomer
          ? stringToBase64(newShipToAddress)
          : undefined,
      deliveryMode: "Truck",
      jobName: jobName,
      billingCustomerId: currentQuickQuote?.customer?.id || "",
      contactName: contactName,
      contactPhone: {
        areaCode: areaCode,
        exchange: exchange,
        local4: local4,
      },
      contactEmail: contactEmail,
      prospectiveCustomer: currentQuickQuote?.prospectiveCustomer || false,
      shippingCustomerId: selectedCustomer,
      contactPhoneExt: officePhoneExt,
      customerRefNumber: customerRefNumber,
      customerPoNumber: purchaseOrder,
      customerNotes: notesToCustomer,
      internalNotes: internalNotes,
      expirationDate: date,
      resolution: "",
      customerName: customerName,
      encodedCustomerNotes: stringToBase64(notesToCustomer),
      encodedInternalNotes: stringToBase64(internalNotes),
      quoteId: selectedQuote || undefined,
    };

    if (selectedQuote) {

      dispatch(updateQuickQuote(payload))
        .unwrap()
        .then((response) => {
          dispatch(updateSelectedQuoteId(response.id));
        })
        .catch(
          (error: React.SetStateAction<string | ErrorResponse | undefined>) => {
            setError(error);
          }
        );
    } else {
      dispatch(addQuickQuote(payload))
        .unwrap()
        .then((response) => {
          dispatch(updateSelectedQuoteId(response.id));
        })
        .catch(
          (error: React.SetStateAction<string | ErrorResponse | undefined>) => {
            setError(error);
          }
        );
    }
  };

  const handleRemoveLine = (id: number) => {
    const newLines = lines.filter((line) => line.id !== id);
    setLines(newLines);
    
    if (id > 990000) {
      return;
    }
    dispatch(deleteQuickQuoteLine({ quoteId: selectedQuote, lineId: id }));
  };

  const handleHideAddRow = (hide: boolean) => {
    setHideAddRow(hide);
  };

  const pdfHandler = (pdfType: string) => {
    setIsLoading(true);
    dispatch(
      fetchQuickQuotePDF({
        quoteId: selectedQuote,
        pdfType: pdfType,
      })
    )
      .unwrap()
      .then(() => setIsLoading(false))
      .catch((error) => {
        setError(error);
      });
  };

  const submitDateChange = (params: QQDateUpdateParams) => {
    dispatch(updateQuickQuoteDueDates(params))
      .unwrap()
      .then(() => {
        setDateModalOpen(false);
      })
      .catch(
        (error: React.SetStateAction<string | ErrorResponse | undefined>) => {
          setError(error);
          setDateModalOpen(false);
        }
      );
  };

  const retrieveItemPricing = (shipToId: string) => {
    dispatch(fetchQuickQuoteItemPricing({ customerId: currentQuickQuote?.customer?.id, shipToId: shipToId }));
  };

  const formStyles = css`
    & fieldset label {
      font-weight: bold;
      color: #6c757d;
    }
  `;

  return (
    <>
    {error && (
        <Toast
          type="error"
          message={error}
          onConfirm={() => clearErrors()}
        />
      )}
      <div css={tw`relative`}>
        <p css={tw`italic text-xs mt-2`}>
          <span css={tw`text-red-600`}>*</span> indicates required fields
        </p>
        <p css={tw`font-semibold`}>
          <span css={tw`text-red-600`}>Portal Quote</span>
          <span css={tw`text-black ml-5`}>
            {selectedQuote === 0 ? "" : formattedQuoteID}
          </span>
        </p>
        <ErrorDisplay error={linesError} />
        {loadStatus === "pending" && <Loading />}
        <div css={[tw`w-full bg-[#e2e2e2]`]}>
          <form css={[tw`mt-2 text-xs`, formStyles]}>
            <table css={
              css`
                tbody tr td {
                  padding: 4px .3rem;
                }
              `
            }>
              <tbody>
                <tr>
                  <td>
                    <fieldset css={tw`space-x-2 self-start`}>
                      <label>Status:</label>
                      <Select
                        minWidth="28ch"
                        value={quoteStatus}
                        onChange={(e: string) => setQuoteStatus(e)}
                        name={"QuoteStatus"}
                        data={statuses.map((status) => ({
                          value: status.id,
                          label: status.description,
                        }))}
                      />
                    </fieldset>
                  </td>
                  <td css={tw`text-right`}>
                    <fieldset css={tw`space-x-2`}>
                      <label>Contact Name:</label>
                      <Input
                        type="text"
                        value={contactName}
                        onChange={(e) => setContactName(e.target.value)}
                      />
                    </fieldset>
                  </td>
                  <td css={tw`text-right`}>
                    <fieldset css={tw`space-x-2`}>
                      <label>Contact Email:</label>
                      <Input
                        type="email"
                        value={contactEmail}
                        onChange={(e) => setContactEmail(e.target.value)}
                      />
                    </fieldset>
                  </td>
                  <td css={tw`text-right`} colSpan={2}>
                    <fieldset css={tw`space-x-2`}>
                      <label>Contact Phone:</label>

                      <Input
                        maxLength={3}
                        value={areaCode}
                        onChange={(e) => setAreaCode(e.target.value)}
                        css={[tw`w-12`]}
                        type="text"
                      />
                      <Input
                        maxLength={3}
                        value={exchange}
                        onChange={(e) => setExchange(e.target.value)}
                        css={[tw`w-12`]}
                        type="text"
                      />
                      <Input
                        maxLength={4}
                        value={local4}
                        onChange={(e) => setLocal4(e.target.value)}
                        css={[tw`w-16`]}
                        type="text"
                      />
                      <label css={tw`w-40`}>Ext:</label>
                      <Input
                        value={officePhoneExt}
                        onChange={(e) => setOfficePhoneExt(e.target.value)}
                        css={tw`w-16`}
                        type="text"
                      />
                    </fieldset>
                  </td>
                </tr>
                <tr>
                  <td css={tw`text-right`}></td>
                </tr>
                <tr css={tw`mt-5`}>
                  <td css={tw`text-right`}>
                    <fieldset css={tw`space-x-2`}>
                      <label>Purchase Order:</label>
                      <Input
                        type="text"
                        value={purchaseOrder}
                        onChange={(e) => setPurchaseOrder(e.target.value)}
                      />
                    </fieldset>
                  </td>
                  <td css={tw`text-right`}>
                    <fieldset css={tw`self-start`}>
                      <label>Job Name:</label>
                      <Input
                        css={tw`ml-4`}
                        type="text"
                        value={jobName}
                        onChange={(e) => setJobName(e.target.value)}
                      />
                    </fieldset>
                  </td>
                  <td css={tw`text-right`}>
                    <fieldset>
                      <label>Customer Ref #:</label>
                      <Input
                        css={tw`ml-4`}
                        type="text"
                        value={customerRefNumber}
                        onChange={(e) => setCustomerRefNumber(e.target.value)}
                      />
                    </fieldset>
                  </td>
                  <td css={tw`text-left`}>
                    <fieldset css={tw`mt-1`}>
                      <label>Expiration Date:</label>
                    </fieldset>
                  </td>
                  <td>
                    <fieldset css={tw`mr-24`}>
                      <DatePicker
                        css={tw`bg-white`}
                        name="dueDate"
                        value={expirationDate}
                        onChange={setExpirationDate}
                      />
                    </fieldset>
                  </td>
                </tr>
                <tr>
                  <td css={tw`text-right`}></td>
                </tr>
              </tbody>
            </table>
            <table  css={
              css`
                tbody tr td {
                  padding: 4px .3rem;
                }
              `
            }>
              <tbody>
                <tr>
                  <td>
                    <fieldset>
                      <label>Notes to Customer:</label>
                    </fieldset>
                    <fieldset>
                      <TextArea
                        rows={3}
                        cols={60}
                        value={notesToCustomer}
                        onChange={(e) => setNotesToCustomer(e.target.value)}
                      />
                    </fieldset>
                  </td>
                  <td colSpan={2}>
                    <fieldset>
                      <label>Internal Notes:</label>
                    </fieldset>
                    <fieldset>
                      <TextArea
                        rows={3}
                        cols={60}
                        value={internalNotes}
                        onChange={(e) => setInternalNotes(e.target.value)}
                      />
                    </fieldset>
                  </td>
                </tr>
                <tr>
                  <td css={[tw`align-top`]}>
                    {currentQuickQuote?.prospectiveCustomer ? (
                      <fieldset>
                        <label>
                          Prospective Customer:
                          <span css={tw`text-red-600 mt-2`}>*</span>
                        </label>
                        <fieldset>
                          <Input
                            css={tw`bg-nucor-yellow w-full`}
                            type="text"
                            value={customerName}
                            onChange={(e) => setCustomerName(e.target.value)}
                          />
                        </fieldset>
                      </fieldset>
                    ) : (
                      <>
                        <fieldset>
                          <label css={[tw`gap-1`]}>Submit To:</label>
                        </fieldset>                  
                        <label css={[tw`flex gap-1 items-center`]}>
                          {currentQuickQuote?.customer?.id} -{" "}
                          {currentQuickQuote?.customer?.name}
                        </label>
                        <label css={[tw`flex gap-1 items-center`]}>
                          {currentQuickQuote?.customer?.address &&
                            `${currentQuickQuote?.customer?.address.address1}`}
                        </label>
                        <label css={[tw`flex gap-1 items-center`]}>
                          {currentQuickQuote?.customer?.address &&
                            `${currentQuickQuote?.customer?.address.city}`}{" "}
                          {currentQuickQuote?.customer?.address &&
                            `${currentQuickQuote?.customer?.address.stateCode}`}
                        </label>
                      </>
                    )}
                  </td>
                  <td css={[tw`align-top w-[65ch]`]}>
                    <div>
                      <fieldset>
                        <label css={[tw`flex gap-1`]}>Ship To:</label>
                      </fieldset>
                      <Select
                        value={selectedCustomer}
                        onChange={(e: string) => {
                          if(e !== selectedCustomer) {
                            retrieveItemPricing(e);
                          }
                          setSelectedCustomer(e);
                          e !== "" && setNewShipTo(false);
                        }}
                        name="shipto"
                        data={customers.map((customer) => ({
                          value: customer.id,
                          label: `${
                            customer.id !== "" ? `${customer.id} - ` : ""
                          }${customer.name}`,
                        }))}
                        minWidth="55ch"
                        maxWidth="55ch"
                      />
                    </div>

                    {(selectedCustomer === "" || currentQuickQuote?.prospectiveCustomer || newShipTo) && loadStatus !== "pending" ? (
                      <>
                        <span css={tw`text-red-600 mt-2`}>*</span> (enter
                        address below)
                        <fieldset>
                          <TextArea
                            css={tw`bg-nucor-yellow`}
                            rows={5}
                            cols={46}
                            value={newShipToAddress}
                            onChange={(e) =>
                              setNewShipToAddress(e.target.value)
                            }
                          />
                        </fieldset>
                      </>
                    ) : (
                      <div>
                        <div>{selectedCustomerAddress?.address.address1}</div>
                        <div>
                          {selectedCustomerAddress?.address.cityStateZip}
                        </div>
                        <div>{selectedCustomerAddress?.address.country}</div>
                        <div>{selectedCustomerAddress?.phoneNumber}</div>
                      </div>
                    )}
                  </td>
                  <td css={[tw`align-top border-none pl-10`]}>
                    {selectedCustomerAddress && selectedCustomerAddress?.id != "" && 
                      <div>
                        <fieldset>
                          <label css={[tw`flex gap-1`]}>Delivery Mode:</label>
                        </fieldset>
                        <div>{selectedCustomerAddress?.deliveryModeOriginal}</div>
                      </div>
                    }
                  </td>
                </tr>
              </tbody>
            </table>
            <div css={tw`flex justify-center mt-5`}>
              {!lines.some((line) => String(line.id).startsWith("99")) ||
              !hideAddRow ? (
                <Button
                  onClick={saveHandler}
                  type="button"
                  css={tw`text-xs ml-2 w-16 text-center px-0 py-[2px] font-normal`}
                >
                  Save
                </Button>
              ) : (
                <Button
                  disabled={true}
                  css={tw`text-xs ml-2 w-16 text-center px-0 py-[2px] font-normal`}
                >
                  Save
                </Button>
              )}
              {selectedQuote !== 0 && (
                <>
                  <Button
                    disabled={false}
                    css={tw`text-xs ml-2 w-24 text-center px-0 py-[2px] font-normal`}
                    onClick={() => pdfHandler("pdf")}
                  >
                    Detail PDF
                  </Button>
                  <Button
                    disabled={false}
                    css={tw`text-xs ml-2 w-24 text-center px-0 py-[2px] font-normal`}
                    onClick={() => pdfHandler("summary_pdf")}
                  >
                    Summary PDF
                  </Button>
                </>
              )}
            </div>
            &nbsp;
          </form>
        </div>
        {selectedQuote === 0 && (
          <div css={[tw`w-[100%]`]}>
            <p css={tw`text-nucor-green text-xs font-bold`}>
              * Save new quote to add lines
            </p>
          </div>
        )}

        {isLoading && <AnimatedLoadingSpinner css={tw`mt-4 ml-4`} />}
        {selectedQuote !== 0 && (
          <>
            <div css={[tw`mt-5 w-[100%]`]}>
              {!lines.some((line) => String(line.id).startsWith("99")) &&
                !hideAddRow &&
                lines.length !== 0 && (
                  <>
                    {dateModalOpen && (
                      <ChangeDueDates
                        onCancel={() => setDateModalOpen(false)}
                        onConfirm={(date) => {
                          submitDateChange({
                            quoteId: selectedQuote,
                            customerRequestDate: date,
                          });
                        }}
                      />
                    )}
                    <ChangeAllPrices selectedQuote={selectedQuote} matchingUOM={lines?.every((line) => line.unitPriceUOM === lines[0].unitPriceUOM)} />
                    <TextButton
                      onClick={() => setDateModalOpen(true)}
                      css={tw`text-xs mx-4`}
                    >
                      Change All Due Dates
                    </TextButton>
                  </>
                )}
              <QuoteLines
                quoteId={selectedQuote}
                lines={lines}
                removeLine={handleRemoveLine}
                hideAddRow={handleHideAddRow}
              />
            </div>
            <div css={[tw`mt-4 text-center`]}>
              {!lines.some((line) => String(line.id).startsWith("99")) &&
              !hideAddRow ? (
                <button
                  css={tw`ml-2 text-xs font-semibold text-nucor-link underline hover:(text-nucor-link-hover no-underline) focus-visible:text-nucor-link-hover focus-visible:outline-none`}
                  onClick={addNewRow}
                >
                  Add Row
                </button>
              ) : (
                <>
                  <div css={tw`relative inline-block`} className="group">
                    <div
                      css={tw`absolute -top-1 -left-14 whitespace-nowrap bg-white border border-red-600 text-red-600 px-1 py-[2px] rounded z-50 hidden group-hover:block`}
                    >
                      Save or Cancel to Continue
                    </div>
                    <button css={tw`ml-2 text-xs font-semibold text-nucor-gray`} disabled={true}>
                        Add Row
                    </button>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default QuoteEdit;
