/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import React from "react";
import { NavLink } from "react-router-dom";
import CustomerSelect from "./CustomerSelect";
import { useAppSelector } from "../../app/hooks";
import { selectCurrentAuthorities } from "../../store/user.reducer";
import { SubmenuType } from "../../features/admin/Admin";
import { hasAuthority } from "../../helpers/hasAuthority";

type Props = {
  hideCustomerList?: boolean;
  submenu?: SubmenuType[];
};

const SubNavTabs: React.FC<Props> = ({ submenu, hideCustomerList }) => {
  const customerId = useAppSelector(
    (state) => state.customer.currentCustomer?.id
  );
  const authorities = useAppSelector(selectCurrentAuthorities);

  return (
    <div css={tw`w-full grid grid-cols-12`}>
      <div
        css={[
          tw`uppercase list-none flex pl-4 items-center bg-nucor-green font-bold w-full text-sm col-span-9`,
          hideCustomerList && tw`col-span-12`,
        ]}
      >
        {submenu?.map((navItem, index) => {
          if (
            navItem.authority &&
            !hasAuthority(navItem.authority, authorities)
          )
            return null;
          const hasItems = navItem.count !== undefined;
          const countText = hasItems
            ? navItem.count === 1
              ? `(1 item)`
              : `(${navItem.count} items)`
            : "";
          return (
            <NavLink
              key={index}
              css={tw`py-4 px-1 mx-1 text-[13px] relative focus-visible:outline-1 focus-visible:outline-dotted focus-visible:-outline-offset-2 focus-visible:outline-nucor-light-gray focus:outline-none`}
              to={
                navItem.showCustId && customerId
                  ? navItem?.to + `?customer_id=${customerId}`
                  : navItem?.to
              }
            >
              {({ isActive }) => (
                <span
                  css={[
                    tw`text-white opacity-50 hover:opacity-100`,
                    isActive && tw`opacity-100`,
                  ]}
                >
                  {`${navItem?.title} ${countText}`}
                  {isActive && (
                    <span
                      css={tw`w-full h-[3px] bg-white absolute bottom-1 left-0`}
                    />
                  )}
                </span>
              )}
            </NavLink>
          );
        })}
      </div>
      {!hideCustomerList && <CustomerSelect />}
    </div>
  );
};

export default SubNavTabs;
