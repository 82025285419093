/** @jsxImportSource @emotion/react */
import React, { ReactNode, useContext, useEffect, useState } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Loading from "../../../../components/atoms/Loading";
import TextButton from "../../../../components/atoms/TextButton";
import PaginationComponent, {
  PaginationValues,
} from "../../../../components/molecules/PaginationComponent";
import { numberFormatter } from "../../../../helpers/numberFormat";
import { selectCurrentCustomerId } from "../../../../store/customer.reducer";
import {
  GetBundlesForOrderDetailRequest,
  addItemToLoad,
  autoLoadShipment,
  fetchLoadCart,
  fetchReleaseOrders,
  removeLoadCartItem,
  // updateLoadCartItem,
} from "../../../../store/shipping.actions";
import {
  CustomerPart,
  OrderDetail,
  ReadyToReleaseShipToTotals,
  RequestStatus,
  SearchCriteria,
  ShippableItem,
  ShippableItemPagedResult,
} from "../../../../types/types";
import { formatDateToShort } from "../../../../helpers/dateHelpers";
import { fetchOrderDocumentDetailPDF } from "../../../../store/document.actions";
import Toast from "../../../../components/molecules/Toast";
import { selectCurrentCustomerParts } from "../../../../store/parts.reducer";
import PartDescriptionModal from "../../../../components/molecules/PartDescriptionModal";
import BundleEntryModal from "./BundleEntryModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { PDFContext } from "./ReleaseOrders";

type Props = {
  children?: ReactNode;
  totals: ReadyToReleaseShipToTotals;
  currentSort: Pick<SearchCriteria, "sort" | "dir">;
};

export type LoadHandlerType = {
  inventoryTransactionId: string;
  allBundles: boolean;
  loadLineBundles?: {
    tag: string;
    piecesPerBundle: number;
  }[];
};

const ReleaseOrdersDetails: React.FC<Props> = ({
  totals,
  currentSort
}) => {
  const dispatch = useAppDispatch();
  const loadCartLines = useAppSelector(
    (state) => state.shipping.loads.loadCart?.lines
  );
  const compatibleShipTos = useAppSelector(
    (state) => state.shipping.releaseOrder.selectionData?.compatibleShipTos
  );
  const compatibleSites = useAppSelector(
    (state) => state.shipping.releaseOrder.selectionData?.compatibleSites
  );
  const showPrices = useAppSelector(
    (state) => state.shipping.releaseOrder.selectionData?.showPrices
  );
  const currentCustomerParts = useAppSelector(selectCurrentCustomerParts);

  const customerId = useAppSelector(selectCurrentCustomerId);
  const [result, setResult] = useState<ShippableItemPagedResult>();
  const [showBundleEntry, setShowBundleEntry] = useState<
    GetBundlesForOrderDetailRequest | undefined
  >();
  const [fetchStatus, setFetchStatus] = useState<RequestStatus>("idle");
  const [addStatus, setAddStatus] = useState<RequestStatus>("idle");
  const [removeStatus, setRemoveStatus] = useState<RequestStatus>("idle");
  const [autoLoadStatus, setAutoLoadStatus] = useState<RequestStatus>("idle");
  const [detailStatus, setDetailStatus] = useState("idle");

  const [currentPagination, setCurrentPagination] = useState<PaginationValues>({
    page: 1,
  });
  
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedPart, setSelectedPart] = useState<CustomerPart>();

  const { searchCriteria, searchTabCriteria, requestType } = useAppSelector(
    (state) => state.shipping.releaseOrder
  );
  const ctx = useContext(PDFContext);

  const disableCheckBox = (fetchStatus === "pending" || addStatus === "pending" || removeStatus === "pending" || autoLoadStatus === "pending");

  const viewOrderHandler = (id: string) => {
    if (!customerId) return;
    setDetailStatus("pending");
    setErrorMessage("");
    dispatch(
      fetchOrderDocumentDetailPDF({
        customerId,
        id,
      })
    )
      .unwrap()
      .then((result) => {
        ctx?.setFile(result);
        setDetailStatus("success");
      })
      .catch((error) => {
        setDetailStatus("error");
        setErrorMessage(error);
      });
  };
  
  const getCriteria = () => {
    const criteria =
      requestType === "search"
        ? {
            ...searchTabCriteria,
            ...currentPagination,
            shipToCustomerId: totals.shipToId,
            ...currentSort,
          }
        : {
            ...searchCriteria,
            ...currentPagination,
            shipToCustomerId: totals.shipToId,
            ...currentSort,
            siteKey: undefined,
          };
    return criteria;
  };

  const fetchResults = () => {
    setFetchStatus("pending");
    const criteria = getCriteria();
    dispatch(fetchReleaseOrders({customerId: totals.customerId, criteria}))
      .unwrap()
      .then((orders) => {
        setResult(orders);
        setFetchStatus("success");
      })
      .catch((error) => {
        setErrorMessage(error);
        setFetchStatus("error");
      });
  };

  const addToLoadHandler = (params: LoadHandlerType) => {
    if (!customerId) return;
    setAddStatus("pending");
    dispatch(addItemToLoad({ customerId, body: params }))
      .unwrap()
      .then((response) => {
        const id = params.inventoryTransactionId;
        const newLoadLine = response.lines.find(
          (item) => item.inventoryTransactionId === id
        );
        if (newLoadLine) {
          const lines =
            result?.list?.map((item) => {
              if (item.orderDetail.inventoryTransactionId === id) {
                return {
                  ...item,
                  loadLine: newLoadLine,
                };
              } else return item;
            }) ?? [];
          setResult({ count: result?.count ?? 0, list: lines });
        }
        setAddStatus("success");
      })
      .catch((error) => {
        if (error?.message === "Invalid load line input") {
          setErrorMessage({
            ...error,
            additionalInfo: ["Cart is out of sync. Please refresh the page."],
          });
        } else {
          setErrorMessage(error);
        }
        setAddStatus("error");
        dispatch(fetchLoadCart(customerId));
        fetchResults();
      });
  };

  const removeFromLoadHandler = (id: number) => {
    if (!customerId) return;
    setRemoveStatus("pending");
    dispatch(removeLoadCartItem({ customerId, id }))
      .unwrap()
      .then(() => {
        const lines = result?.list?.map(
          (item) =>
            ({
              bundleSize: item.bundleSize,
              bundleWeight: item.bundleWeight,
              orderDetail: item.orderDetail,
              loadLine: item.loadLine?.id === id ? undefined : item.loadLine,
              releaseBlocked: item.releaseBlocked,
              releaseBlockedMessage: item.releaseBlockedMessage,
            } as ShippableItem)
        );
        setResult({ count: result?.count ?? 0, list: lines ?? [] });
        setRemoveStatus("success");
      })
      .catch((error) => {
        setErrorMessage(error);
        setRemoveStatus("error");
      });
  };

  const autoLoadHandler = () => {
    if (!customerId) return;

    const criteria = getCriteria();
    
    setAutoLoadStatus("pending");
    dispatch(
      autoLoadShipment({
        customerId,
        shipToId: totals.shipToId,
        criteria
      })
    )
      .unwrap()
      .then(() => {
        fetchResults();
        setAutoLoadStatus("success");
      })
      .catch((error) => {
        setErrorMessage(error);
        setAutoLoadStatus("error");
      });
  };

  useEffect(() => {
    if (searchCriteria) {
      fetchResults();
    }
  }, [currentPagination, currentSort]);

  const checkIfAbleToLoad = (item: OrderDetail) => {
    if (!loadCartLines || loadCartLines.length === 0) return true;
    if (!compatibleSites || !compatibleShipTos) return false;
    const isCompatibleSite = compatibleSites[loadCartLines[0].site]?.includes(
      item.site
    );
    const isCompatibleShipTo = compatibleShipTos[
      loadCartLines[0].shipToCustomerId
    ]?.includes(item.shipToId);
    return isCompatibleSite && isCompatibleShipTo;
  };

  const canAutoLoad = () => {
    return (
      result?.list?.some((item) => checkIfAbleToLoad(item.orderDetail)) ?? false
    );
  };

  return (
    <div>
      {selectedPart && (
        <PartDescriptionModal
          onCancel={() => setSelectedPart(undefined)}
          details={selectedPart}
        />
      )}
      {errorMessage && (
        <Toast
          type="error"
          message={errorMessage}
          onConfirm={() => setErrorMessage("")}
          duration={7000}
        />
      )}
      {showBundleEntry && (
        <BundleEntryModal
          details={showBundleEntry}
          onCancel={(refresh) => {
            setShowBundleEntry(undefined);
            if (refresh) {
              fetchResults();
            }
          }}
        />
      )}
      <div
        css={tw`bg-gradient-to-b from-nucor-gradient-outer via-nucor-gradient-inner to-nucor-gradient-outer py-1 px-2`}
      >
        <PaginationComponent
          isLoading={fetchStatus === "pending"}
          items={result?.count ?? 0}
          pageSize={searchCriteria?.pageSize}
          onChange={(value) => setCurrentPagination(value)}
        />
      </div>
      <div css={tw`relative`}>
        {fetchStatus === "pending" && <Loading />}
        {addStatus === "pending" && <Loading>Adding Item...</Loading>}
        {removeStatus === "pending" && <Loading>Removing Item...</Loading>}
        {autoLoadStatus === "pending" && <Loading>Auto Loading...</Loading>}
        {detailStatus === "pending" && <Loading>Loading Detail...</Loading>}
        <table className="ntp-portal-table" css={tw`w-full`}>
          <tbody>
          {result?.list && result?.list.length > 0 ? (
            result?.list.map((item, index) => (
              <tr key={index}>
                <td css={tw`w-[10%]`}>{item.orderDetail.customerPONumber}</td>
                <td css={tw`w-[9%]`}>
                  <TextButton
                    onClick={() =>
                      viewOrderHandler(item.orderDetail.salesOrderNumber)
                    }
                    disabled={
                      detailStatus === "pending" ||
                      item.orderDetail.hideDocumentLink
                    }
                  >{`${item.orderDetail.salesOrderNumber}-${
                    item.orderDetail.lineNumberUnprocessed ?? ""
                  }`}</TextButton>
                </td>
                {showPrices && (
                  <td css={tw`w-[10%] text-right`}>{`${Intl.NumberFormat(
                    "en-US",
                    { style: "currency", currency: "USD" }
                  ).format(item.orderDetail.totalPrice)} ${
                    item.orderDetail.priceUnit
                  }`}</td>
                )}
                <td css={tw`w-[4%]`}>{item.orderDetail.site}</td>
                <td css={tw`w-[6%]`}>
                  {formatDateToShort(item.orderDetail.orderDate)}
                </td>
                <td css={tw`w-[6%]`}>
                  {formatDateToShort(item.orderDetail.dueDate)}
                </td>
                <td css={tw`w-[21%]`}>
                  {item.orderDetail.customerPartNumber ? (
                    <TextButton
                      onClick={() => {
                        const part = currentCustomerParts?.find(
                          (part) =>
                            part.customerPartNumber ===
                            item.orderDetail.customerPartNumber
                        );
                        setSelectedPart(part);
                      }}
                    >
                      {item.orderDetail.customerPartNumber}
                    </TextButton>
                  ) : null}
                  <p>{item.orderDetail.lineDescription}</p>
                </td>
                <td css={tw`w-[6%] text-center`}>{item.orderDetail.grade}</td>
                <td css={tw`w-[6%] text-right`}>
                  {!item.releaseBlocked &&
                  checkIfAbleToLoad(item.orderDetail) &&
                  customerId ? (
                    <button
                      onClick={() =>
                        setShowBundleEntry({
                          customerId,
                          inventoryTransactionId:
                            item.orderDetail.inventoryTransactionId,
                          id: item.loadLine?.id,
                        })
                      }
                      css={tw`text-nucor-link underline hover:(no-underline text-nucor-link-hover) focus:text-nucor-link-hover focus:outline-none`}
                    >
                      {numberFormatter(item.orderDetail.readyToLoadWeight)}
                    </button>
                  ) : (
                    <span>
                      {numberFormatter(item.orderDetail.readyToLoadWeight)}
                    </span>
                  )}
                </td>
                <td
                  className="group"
                  css={[
                    tw`w-[4%] text-right relative`,
                    item.allocationAlert && tw`bg-[#f99]`,
                  ]}
                >
                  <span>
                    {numberFormatter(item.orderDetail.readyToLoadPieces)}
                  </span>
                  {item.allocationAlertMessage && (
                    <span
                      css={tw`hidden group-hover:inline absolute bg-white px-2 py-1 border rounded border-red-600 text-red-600 whitespace-nowrap top-0 left-0 -translate-y-3/4`}
                    >
                      {item.allocationAlertMessage}
                    </span>
                  )}
                </td>
                <td css={tw`w-[5%] text-right`}>
                  {numberFormatter(item.loadLine?.loadedWeight)}
                </td>
                <td css={tw`w-[4%] text-right`}>
                  {numberFormatter(item.loadLine?.quantity)}
                </td>
                {item.releaseBlocked && (
                  <td css={tw`w-[4%] text-center relative`}>
                    <FontAwesomeIcon
                      className="text-red-600 peer"
                      size="lg"
                      icon={faCircleXmark}
                    />
                    <span className="hidden absolute peer-hover:block bg-white text-red-600 border border-red-600 rounded px-2 py-1 z-50 top-0 right-8 lg:right-12 xl:right-20 translate-y-1/4 whitespace-nowrap">
                      {item.releaseBlockedMessage}
                    </span>
                  </td>
                )}
                {!item.releaseBlocked && (
                  <td css={tw`w-[4%] text-center`}>
                    {checkIfAbleToLoad(item.orderDetail) && (
                      <input
                        type="checkbox"
                        checked={item.loadLine !== undefined}
                        disabled={disableCheckBox}
                        onChange={(e) => {
                          if (e.currentTarget.checked) {
                            addToLoadHandler({
                              inventoryTransactionId:
                                item.orderDetail.inventoryTransactionId,
                              allBundles: true,
                            });
                          } else {
                            removeFromLoadHandler(item.loadLine?.id);
                          }
                        }}
                      />
                    )}
                  </td>
                )}
              </tr>
            ))
          ) : (
            <tr>
              <td css={tw`text-nucor-gray !px-2 !py-3 hover:!bg-white`} colSpan={12}>
                {(fetchStatus === "pending") && <div>&nbsp;</div>}
                {!(fetchStatus === "pending") && <div>No Orders found for the selected criteria</div>}
              </td>
            </tr>
          )}
          {(!(fetchStatus === "pending") && result?.list?.length && canAutoLoad()) && (<tr css={tw`h-[2rem]`}>
              <td css={tw`text-right !bg-white`} colSpan={12}>
                <TextButton onClick={autoLoadHandler}>Auto Load</TextButton>
              </td>
            </tr>
          )}
          </tbody>
          </table>
      </div>
    </div>
  );
};

export default ReleaseOrdersDetails;
