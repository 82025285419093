/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import { useAppSelector } from "../../app/hooks";
import tw from "twin.macro";
import { css } from "@emotion/react";
import InternalCustomerList from "../molecules/InternalCustomerList";
import ExternalCustomerList from "../molecules/ExternalCustomerList";
import { useLocation } from "react-router-dom";

type Props = {
  children?: ReactNode;
};

const CustomerSelect: React.FC<Props> = () => {
  const location = useLocation();
  const internal = useAppSelector((state) =>
    state.user.currentContext?.user?.authorities.includes("internal")
  );

  return (
    <div
      css={[
        tw`bg-nucor-light-green text-white pr-2 flex flex-col justify-center items-end text-sm col-span-3 relative`,
        css`
          &::before {
            border-bottom: 52px solid #179a44;
            border-left: 40px solid transparent;
            content: "";
            position: absolute;
            left: -40px;
            width: 0;
            z-index: 10;
          }
        `,
      ]}
    >
      {!internal && (
        <ExternalCustomerList
          isStockPage={location.pathname.includes("/stock/")}
        />
      )}
      {internal && (
        <InternalCustomerList
          isStockPage={location.pathname.includes("/stock/")}
        />
      )}
    </div>
  );
};

export default CustomerSelect;
