import * as yup from "yup";
import { Phone } from "../../../types/types";

export type PersonalInfoFormData = {
  firstName: string;
  lastName: string;
  officePhone: Phone;
  officePhoneExt?: string;
  mobilePhone: Phone;
  faxPhone: Phone;
};

export const schema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  officePhone: yup.object().shape({
    areaCode: yup.string().matches(/^[0-9]{3}$/, {
      message: "Area code must contain 3 numbers",
      excludeEmptyString: true,
    }),
    exchange: yup.string().matches(/^[0-9]{3}$/, {
      message: "Exchange must contain 3 numbers",
      excludeEmptyString: true,
    }),
    local4: yup.string().matches(/^[0-9]{4}$/, {
      message: "Local must contain 4 numbers",
      excludeEmptyString: true,
    }),
  }),
  officePhoneExtension: yup.string().matches(/^[0-9]+$/, {
    message: "Extension may only contain numbers",
    excludeEmptyString: true,
  }),
  mobilePhone: yup.object().shape({
    areaCode: yup.string().matches(/^[0-9]{3}$/, {
      message: "Area code must contain 3 numbers",
      excludeEmptyString: true,
    }),
    exchange: yup.string().matches(/^[0-9]{3}$/, {
      message: "Exchange must contain 3 numbers",
      excludeEmptyString: true,
    }),
    local4: yup.string().matches(/^[0-9]{4}$/, {
      message: "Local must contain 4 numbers",
      excludeEmptyString: true,
    }),
  }),
  faxPhone: yup.object().shape({
    areaCode: yup.string().matches(/^[0-9]{3}$/, {
      message: "Area code must contain 3 numbers",
      excludeEmptyString: true,
    }),
    exchange: yup.string().matches(/^[0-9]{3}$/, {
      message: "Exchange must contain 3 numbers",
      excludeEmptyString: true,
    }),
    local4: yup.string().matches(/^[0-9]{4}$/, {
      message: "Local must contain 4 numbers",
      excludeEmptyString: true,
    }),
  }),
});
