/** @jsxImportSource @emotion/react */
import React, {
  ReactNode,
  useContext,
  useState,
} from "react";
import tw from "twin.macro";
import Button from "../../../../components/atoms/Button";
import DatePicker from "../../../../components/atoms/DatePicker";
import Input from "../../../../components/atoms/Input";
import Select from "../../../../components/atoms/Select";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { selectCurrentCustomerId } from "../../../../store/customer.reducer";
import { dateToString } from "../../../../helpers/dateHelpers";
import { fetchQuickQuoteResults } from "../../../../store/document.actions";
import Toast from "../../../../components/molecules/Toast";
import { clearDocumentErrors, updateQuickQuoteSearchCriteria } from "../../../../store/document.reducer";
import { PDFContext } from "./quoteList";
import { searchParameterExists } from "../../../../helpers/searchParameterExists";


type Props = {
  children?: ReactNode;
};

const QuoteSearch: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const customerId = useAppSelector(selectCurrentCustomerId);
  const selectionData = useAppSelector(
    (state) => state.document.quickquote.quoteSelectionData
  );
  const { searchCriteria, error } = useAppSelector(
    (state) => state.document.quickquote
  );

  const [myCustomers, setMyCustomers] = useState(false);
  const [formattedId, setFormattedId] = useState("");
  const [dueDate, setDueDate] = useState<Date | undefined>();
  const [jobName, setJobName] = useState("");
  const [customerRefNumber, setCustomerRefNumber] = useState("");
  const ctx = useContext(PDFContext);

  const criteria = {
    myCustomers: myCustomers,
    formattedId: searchParameterExists(formattedId) ? formattedId : undefined,
    dueDate: dueDate ? dateToString(dueDate) : undefined,
    jobName: searchParameterExists(jobName) ? jobName : undefined,
    customerRefNumber: searchParameterExists(customerRefNumber) ? customerRefNumber : undefined,
  };

  const resetForm = () => {
    setMyCustomers(false);
    setFormattedId("");
    setDueDate(undefined);
    setJobName("");
    setCustomerRefNumber("");
  };

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!customerId) return;
    // Close pdf if open
    if (ctx?.file) {
      ctx.setFile(null);
    }

    dispatch(updateQuickQuoteSearchCriteria({ ...searchCriteria, page: 1}));
    dispatch(fetchQuickQuoteResults({customerId, criteria: { ...criteria, page: 1, pageSize: 25}}));
  };

  return selectionData ? (
    <>
      {error && (
        <Toast
          type="error"
          message={error}
          onConfirm={() => dispatch(clearDocumentErrors())}
        />
      )}
      <p css={tw`text-nucor-gray mt-2`}>Enter any combination:</p>
      <form onSubmit={submitHandler} css={tw`flex w-full flex-col`}>
        <fieldset css={tw`mt-2`}>
          <label css={tw`block`}>Show</label>
          <Select
            name="customerSearchType"
            minWidth="22ch"
            value={myCustomers}
            onChange={setMyCustomers}
            data={selectionData.customerSearchType.map((item) => ({
              value: item.id,
              label: item.description,
            }))}
          />
        </fieldset>
        <fieldset css={tw`mt-2`}>
          <label htmlFor="formattedId" css={tw`block`}>ID #</label>
          <Input
            css={tw`w-[22ch]`}
            name="formattedId"
            value={formattedId}
            onChange={(e) => setFormattedId(e.target.value)}
          />
        </fieldset>
        <fieldset css={tw`mt-2`}>
          <label htmlFor="dueDate" css={tw`block`}>Due Date</label>
          <DatePicker
            css={tw`bg-white`}
            name="dueDate"
            value={dueDate}
            onChange={setDueDate}
          />
        </fieldset>
        <fieldset css={tw`mt-2`}>
          <label htmlFor="jobName" css={tw`block`}>Job Name</label>
          <Input
            css={tw`w-[22ch]`}
            name="jobName"
            value={jobName}
            onChange={(e) => setJobName(e.target.value)}
          />
        </fieldset>
        <fieldset css={tw`mt-2`}>
          <label htmlFor="quoteNumber" css={tw`block`}>Customer Ref #</label>
          <Input
            css={tw`w-[22ch]`}
            name="customerRefNumber"
            value={customerRefNumber}
            onChange={(e) => setCustomerRefNumber(e.target.value)}
          />
        </fieldset>

        
        <div css={tw`w-full text-right`}>
          <Button css={tw`mx-4 py-[2px] text-xs`} onClick={resetForm}>
            Clear
          </Button>
          <Button css={tw`m-0 py-[2px] font-normal text-xs mt-3`} type="submit">
            Search
          </Button>
        </div>
      </form>
    </>
  ) : null;
};

export default QuoteSearch;
