import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { axiosWithAuth } from "../app/axios";
import { objectToQueryString } from "../helpers/objectToQueryString";
import {
  CustomerPart,
  ErrorResponse,
  InventoryBrowseHistory,
  InventorySearch,
  InventoryStatusType,
  Item,
  PrimeInventoryItemSummaryResult,
  PrimeStockDetails,
  ProductSelection,
  ProductShape,
  RobinHoodResults,
  RollingCustomerView,
  SecondaryShortsDetails,
} from "../types/types";
import { clearRollingData } from "./product.reducer";
import { RootState } from "./store";
import { isBlobError } from "../types/predicates";
import { blobToErrorResponse } from "../helpers/blobToErrorResponse";
import { downloadFile } from "../helpers/downloadExcel";

export const fetchProductSelection = createAsyncThunk<
  ProductSelection,
  undefined,
  { rejectValue: string | ErrorResponse }
>("product/fetchProductSelection", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.get<ProductSelection>(
      `${process.env.REACT_APP_API_URL}/products/selections`
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 404:
          return rejectWithValue("Product selections not found");
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue("Error fetching product selections.");
      }
    }
    return rejectWithValue("Error fetching product selections.");
  }
});

export const searchInventory = createAsyncThunk<
  PrimeStockDetails,
  InventorySearch,
  { rejectValue: string | ErrorResponse }
>(
  "product/inventorySearch",
  async (params: InventorySearch, { rejectWithValue }) => {
    try {
      const response = await axiosWithAuth.get(
        `${
          process.env.REACT_APP_API_URL
        }/inventory/search_prime${objectToQueryString(params)}`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 400:
            return rejectWithValue("Invalid inventory criteria");
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue("Not found");
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue(
              "There was an error performing an inventory search."
            );
        }
      }
      return rejectWithValue(
        "There was an error performing an inventory search."
      );
    }
  }
);

export const searchInventoryByPart = createAsyncThunk<
  PrimeStockDetails,
  { customerId: string; customerPartNumber?: string; itemNumber?: string; length?: number; shipToId?: string },
  { rejectValue: string | ErrorResponse }
>(
  "product/searchInventoryByPart",
  async (
    params: { customerId: string; customerPartNumber?: string; itemNumber?: string; length?: number },
    { rejectWithValue }
  ) => {
    try {
      const queryParams = params.customerPartNumber
        ? { customerId: params.customerId, customerPartNumber: params.customerPartNumber }
        : { customerId: params.customerId, itemNumber: params.itemNumber, length: params.length };

      const response = await axiosWithAuth.get(
        `${
          process.env.REACT_APP_API_URL
        }/inventory/search_prime_by_part${objectToQueryString(queryParams)}`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 400:
            return rejectWithValue("Invalid customer id or customer part");
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue("Not found");
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue(
              "There was an error searching inventory by part."
            );
        }
      }
      return rejectWithValue("There was an error searching inventory by part.");
    }
  }
);

type RollingSearchDetails = {
  customerId?: string;
  compoundProductKey?: string;
  shipToId?: string;
  itemNumber?: string;
  length?: number;
};

export const getRollingData = createAsyncThunk<
  RollingCustomerView,
  RollingSearchDetails,
  { rejectValue: string | ErrorResponse }
>(
  "product/rollingSearch",
  async (params: RollingSearchDetails, { rejectWithValue, dispatch }) => {
    try {
      dispatch(clearRollingData);
      const response = await axiosWithAuth.get(
        `${
          process.env.REACT_APP_API_URL
        }/rollings/rollings_data${objectToQueryString(params)}`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 400:
            return rejectWithValue(
              "Invalid customer id or compound product key"
            );
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue("There was an error getting rolling data.");
        }
      }
      return rejectWithValue("There was an error getting rolling data.");
    }
  }
);

type RollingSearchByPartDetails = {
  customerId: string;
  customerPartNumber: string;
  shipToId?: string;
};

export const getRollingDataByPart = createAsyncThunk<
  RollingCustomerView,
  RollingSearchByPartDetails,
  { rejectValue: string | ErrorResponse }
>(
  "product/getRollingDataByPart",
  async (params, { rejectWithValue, dispatch }) => {
    try {
      dispatch(clearRollingData);
      const response = await axiosWithAuth.get(
        `${
          process.env.REACT_APP_API_URL
        }/rollings/rollings_data_by_part${objectToQueryString(params)}`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 400:
            return rejectWithValue(
              "Invalid customer id or customer part number"
            );
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue(
              "There was an error retreiving rolling data"
            );
        }
      }
      return rejectWithValue("There was an error retreiving rolling data");
    }
  }
);

export const getBrowseHistory = createAsyncThunk<
  InventoryBrowseHistory[],
  undefined,
  { rejectValue: string | ErrorResponse }
>("product/getBrowseHistory", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/inventory/browse_history`
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 404:
          return rejectWithValue("Not found");
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue(
            "There was an error fetching inventory browse history."
          );
      }
    }
    return rejectWithValue(
      "There was an error fetching inventory browse history."
    );
  }
});

export const getCustomerParts = createAsyncThunk<
  CustomerPart[],
  string,
  { rejectValue: string | ErrorResponse }
>(
  "product/getCustomerParts",
  async (customerId: string, { rejectWithValue }) => {
    try {
      const response = await axiosWithAuth.get(
        `${process.env.REACT_APP_API_URL}/parts?customerId=${customerId}`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 400:
            return rejectWithValue("Invalid customer id supplied");
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue(error.response?.data);
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue(
              "There was an error fetching customer parts."
            );
        }
      }
      return rejectWithValue("There was an error fetching customer parts.");
    }
  }
);

export const getActiveList = createAsyncThunk<
  Item[],
  string,
  { rejectValue: string | ErrorResponse }
>(
  "product/getActiveList",
  async (customerId: string, { rejectWithValue }) => {
    try {
      const response = await axiosWithAuth.get(
        `${process.env.REACT_APP_API_URL}/products/active_list`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 400:
            return rejectWithValue("Invalid customer id supplied");
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue(error.response?.data);
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue(
              "There was an error fetching customer parts."
            );
        }
      }
      return rejectWithValue("There was an error fetching customer parts.");
    }
  }
);

type SearchStockListRequest = {
  customerId?: string;
  site: string;
  grade: string;
  roundFlag: boolean;
};

export const searchStockList = createAsyncThunk<
  PrimeInventoryItemSummaryResult,
  SearchStockListRequest,
  { rejectValue: string | ErrorResponse; state: RootState }
>(
  "product/searchStockList",
  async (params: SearchStockListRequest, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const siteList = state.app.siteSelections
        .find((site) => site.key === params.site)
        ?.sites.map((site) => `siteList=${site}`)
        .join("&");
      const response = await axiosWithAuth.get(
        `${
          process.env.REACT_APP_API_URL
        }/inventory/search_stock_list?roundFlag=${
          params.roundFlag ? "true" : "false"
        }&grade=${params.grade}&${siteList}${
          params.customerId ? "&customerId=" + params.customerId : ""
        }`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 400:
            return rejectWithValue("Invalid inventory criteria");
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue("Not found");
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue(
              "There was an error searching the stock list."
            );
        }
      }
      return rejectWithValue("There was an error searching the stock list.");
    }
  }
);

export const refreshPrimeInventory = createAsyncThunk<
  PrimeStockDetails,
  undefined,
  { rejectValue: string | ErrorResponse }
>("product/refreshPrimeInventory", async (_, { rejectWithValue, getState }) => {
  try {
    const state = getState() as RootState;
    const currentCustomerId = state.customer.currentCustomer?.id;
    const compoundProductKey =
      state.product.prime.currentProductIndex?.compoundProductKey;
    if (!compoundProductKey)
      throw new AxiosError("Compound product key is missing from request");
    const response = await axiosWithAuth.get(
      `${
        process.env.REACT_APP_API_URL
      }/inventory/search_prime${objectToQueryString({
        customerId: currentCustomerId,
        compoundProductKey: compoundProductKey,
      })}`
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 400:
          return rejectWithValue("Invalid customer id or compound product key");
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue(
            error.message ??
              "There was an error performing an inventory search."
          );
      }
    }
    return rejectWithValue(
      "There was an error performing an inventory search."
    );
  }
});

type PriceSheetSearch = {
  customerId: string;
  shipToId?: string;
};

export const fetchProductPriceSheetPDF = createAsyncThunk<
  void,
  PriceSheetSearch,
  { rejectValue: string | ErrorResponse }
>(
  "product/fetchProductPriceSheetPDF",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosWithAuth.get(
        `${process.env.REACT_APP_API_URL}/products/pricesheet/pdf${objectToQueryString(params)}`,
        {
          responseType: "blob",
        }
      );
      const file = new Blob([response.data], { type: "application/pdf" });
      const filename = response.headers["content-disposition"].split("=")[1];
      downloadFile(file, filename);
      return;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (isBlobError(error.response?.data) && error.response) {
          error.response.data = await blobToErrorResponse(error.response.data);
        }
        switch (error.response?.status) {
          case 400:
            return rejectWithValue("Invalid customer id");
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue("Customer not found");
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue(
              "There was an error fetching product price sheet."
            );
        }
      }
      return rejectWithValue(
        "There was an error fetching product price sheet."
      );
    }
  }
);

export const fetchAllShipToProductPriceSheetPDF = createAsyncThunk<
  void,
  PriceSheetSearch,
  { rejectValue: string | ErrorResponse }
>(
  "product/fetchAllShipToProductPriceSheetPDF",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosWithAuth.get(
        `${process.env.REACT_APP_API_URL}/products/pricesheet_all/pdf${objectToQueryString(params)}`,
        {
          responseType: "blob",
        }
      );
      const file = new Blob([response.data], { type: "application/pdf" });
      const filename = response.headers["content-disposition"].split("=")[1];
      downloadFile(file, filename);
      return;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (isBlobError(error.response?.data) && error.response) {
          error.response.data = await blobToErrorResponse(error.response.data);
        }
        switch (error.response?.status) {
          case 400:
            return rejectWithValue("Invalid customer id");
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");
          case 404:
            return rejectWithValue("Customer not found");
          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue(
              "There was an error fetching product price sheet."
            );
        }
      }
      return rejectWithValue(
        "There was an error fetching product price sheet."
      );
    }
  }
);

type FetchRollingSchedulePDFRequest = {
  customerId?: string;
  month: string;
  year: string;
  site?: string;
};

export const fetchRollingSchedulePDF = createAsyncThunk<
  void,
  FetchRollingSchedulePDFRequest,
  { rejectValue: string | ErrorResponse }
>("product/fetchRollingSchedulePDF", async (params, { rejectWithValue }) => {
  try {
    const query = objectToQueryString(params);
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/rollings/pdf${query}`,
      {
        responseType: "blob",
      }
    );
    const file = new Blob([response.data], { type: "application/pdf" });
    const filename = response.headers["content-disposition"].split("=")[1];
    downloadFile(file, filename);
    return;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      if (isBlobError(error.response?.data) && error.response) {
        error.response.data = await blobToErrorResponse(error.response.data);
      }
      switch (error.response?.status) {
        case 400:
          return rejectWithValue("Invalid criteria");
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue(
            "There was an issue fetching rolling data PDF."
          );
      }
    }
    return rejectWithValue("There was an issue fetching rolling data PDF.");
  }
});

type CalculatePiecesFromTonsRequest = {
  itemNumber: string;
  length: number;
  tons: number;
  customerId: string;
  shipToId: string;
};

export const calculatePiecesFromTons = createAsyncThunk<
  number,
  CalculatePiecesFromTonsRequest,
  { rejectValue: string | ErrorResponse }
>("product/calculatePiecesFromTons", async (params, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.get(
      `${
        process.env.REACT_APP_API_URL
      }/products/calculate_pieces_from_tons${objectToQueryString(params)}`
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 404:
          return rejectWithValue("Product not found");
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue(
            "There was an issue calculating pieces from tons"
          );
      }
    }
    return rejectWithValue("There was an issue calculating pieces from tons");
  }
});

type SearchInventoryBundlesRequest = {
  customerId?: string;
  shape?: ProductShape;
  sites?: string[];
  grade?: string;
  stockStatus?: InventoryStatusType;
  dimension1?: number;
  dimension2?: number;
  nominalWallThickness?: number;
};

export const searchInventoryBundles = createAsyncThunk<
  SecondaryShortsDetails,
  SearchInventoryBundlesRequest,
  { rejectValue: string | ErrorResponse }
>("product/searchInventoryBundles", async (params, { rejectWithValue }) => {
  try {
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/inventory/bundles${objectToQueryString(
        params
      )}`
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 400:
          return rejectWithValue("Invalid inventory criteria");
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 404:
          return rejectWithValue("Not found");
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue(
            "There was an issue searching bundle inventory"
          );
      }
    }
    return rejectWithValue("There was an issue searching bundle inventory");
  }
});

export const fetchShortSecondaryExcel = createAsyncThunk<
  void,
  SearchInventoryBundlesRequest,
  { rejectValue: string | ErrorResponse }
>("document/fetchShortSecondaryExcel", async (params, { rejectWithValue }) => {
  try {
    const query = objectToQueryString(params);
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/inventory/bundles/external/excel${query}`,
      {
        responseType: "blob",
      }
    );
    const file = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const filename = response.headers["content-disposition"].split("=")[1];
    downloadFile(file, filename);
    return;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      if (isBlobError(error.response?.data) && error.response) {
        error.response.data = await blobToErrorResponse(error.response.data);
      }
      switch (error.response?.status) {
        case 400:
          return rejectWithValue("Invalid criteria");
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");

        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue("There was an issue retrieving excel sheet");
      }
    } else {
      return rejectWithValue("There was an issue retrieving excel sheet");
    }
  }
});

export type FetchStockListDocumentRequest = {
  customerId?: string;
  site: string;
  roundFlag: boolean;
  grade: string;
};

export const fetchStockListExcel = createAsyncThunk<
  void,
  FetchStockListDocumentRequest,
  { rejectValue: string | ErrorResponse; state: RootState }
>(
  "document/fetchStockListExcel",
  async (params, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const siteList = state.app.siteSelections
        .find((site) => site.key === params.site)
        ?.sites.map((site) => `siteList=${site}`)
        .join("&");
      const response = await axiosWithAuth.get(
        `${
          process.env.REACT_APP_API_URL
        }/inventory/search_stock_list/excel?roundFlag=${
          params.roundFlag ? "true" : "false"
        }&grade=${params.grade}&${siteList}${
          params.customerId ? "&customerId=" + params.customerId : ""
        }`,
        {
          responseType: "blob",
        }
      );
      const file = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const filename = response.headers["content-disposition"].split("=")[1];
      downloadFile(file, filename);
      return;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (isBlobError(error.response?.data) && error.response) {
          error.response.data = await blobToErrorResponse(error.response.data);
        }
        switch (error.response?.status) {
          case 400:
            return rejectWithValue("Invalid criteria");
          case 401:
            return rejectWithValue("Unauthorized");
          case 403:
            return rejectWithValue("Forbidden");

          case 423:
            return rejectWithValue(error.response?.data);
          case 500:
            return rejectWithValue("Internal server error");
          default:
            return rejectWithValue("There was an issue retrieving excel sheet");
        }
      } else {
        return rejectWithValue("There was an issue retrieving excel sheet");
      }
    }
  }
);

export const fetchStockListPDF = createAsyncThunk<
  void,
  FetchStockListDocumentRequest,
  { rejectValue: string | ErrorResponse; state: RootState }
>(
  "document/fetchStockListPDF",
  async (params, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const siteList = state.app.siteSelections
        .find((site) => site.key === params.site)
        ?.sites.map((site) => `siteList=${site}`)
        .join("&");
      const response = await axiosWithAuth.get(
        `${
          process.env.REACT_APP_API_URL
        }/inventory/search_stock_list/pdf?roundFlag=${
          params.roundFlag ? "true" : "false"
        }&grade=${params.grade}&${siteList}${
          params.customerId ? "&customerId=" + params.customerId : ""
        }`,
        {
          responseType: "blob",
        }
      );
      const file = new Blob([response.data], {
        type: "application/pdf",
      });
      const filename = response.headers["content-disposition"].split("=")[1];
      downloadFile(file, filename);
      return;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (isBlobError(error.response?.data) && error.response) {
          error.response.data = await blobToErrorResponse(error.response.data);
        }
        return rejectWithValue(error.response?.data);
      } else {
        return rejectWithValue("There was an issue retrieving pdf");
      }
    }
  }
);

type FetchPrimeDocumentRequest = {
  customerId: string;
  compoundProductKey?: string;
  customerPartNumber?: string;
  shipToId: string;
};

export const fetchPrimePDF = createAsyncThunk<
  void,
  FetchPrimeDocumentRequest,
  { rejectValue: string | ErrorResponse; state: RootState }
>("document/fetchPrimePDF", async (params, { rejectWithValue }) => {
  try {
    const query = objectToQueryString(params);
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/inventory/search_prime/pdf${query}`,
      {
        responseType: "blob",
      }
    );
    const file = new Blob([response.data], {
      type: "application/pdf",
    });
    const filename = response.headers["content-disposition"].split("=")[1];
    downloadFile(file, filename);
    return;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      if (isBlobError(error.response?.data) && error.response) {
        error.response.data = await blobToErrorResponse(error.response.data);
      }
      return rejectWithValue(error.response?.data);
    } else {
      return rejectWithValue("There was an issue retrieving the pdf");
    }
  }
});

export const fetchPrimePDFByPart = createAsyncThunk<
  void,
  FetchPrimeDocumentRequest,
  { rejectValue: string | ErrorResponse; state: RootState }
>("document/fetchPrimePDFByPart", async (params, { rejectWithValue }) => {
  try {
    const query = objectToQueryString(params);
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/inventory/search_prime_by_part/pdf${query}`,
      {
        responseType: "blob",
      }
    );
    const file = new Blob([response.data], {
      type: "application/pdf",
    });
    const filename = response.headers["content-disposition"].split("=")[1];
    downloadFile(file, filename);
    return;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      if (isBlobError(error.response?.data) && error.response) {
        error.response.data = await blobToErrorResponse(error.response.data);
      }
      return rejectWithValue(error.response?.data);
    } else {
      return rejectWithValue("There was an issue retrieving the pdf");
    }
  }
});

export type FetchRobinhoodExcelRequest = {
  compoundProductKey: string;
};

export const fetchRobinhoodExcel = createAsyncThunk<
  void,
  FetchRobinhoodExcelRequest,
  { rejectValue: string | ErrorResponse; state: RootState }
>("product/fetchRobinhoodExcel", async (params, { rejectWithValue }) => {
  try {
    const query = objectToQueryString(params);
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/internal/order/robin_hood/excel${query}`,
      {
        responseType: "blob",
      }
    );
    const file = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const filename = response.headers["content-disposition"].split("=")[1];
    downloadFile(file, filename);
    return;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      if (isBlobError(error.response?.data) && error.response) {
        error.response.data = await blobToErrorResponse(error.response.data);
      }
      switch (error.response?.status) {
        case 400:
          return rejectWithValue("Invalid criteria");
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");

        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue("There was an issue retrieving excel sheet");
      }
    } else {
      return rejectWithValue("There was an issue retrieving excel sheet");
    }
  }
});


export type FetchRobinhoodRequest = {
  compoundProductKey: string;
  selectedMill: string;
  length: number | undefined;
};

export const fetchRobinhood = createAsyncThunk<
  RobinHoodResults,
  FetchRobinhoodRequest,
  { rejectValue: string | ErrorResponse; state: RootState }
>("product/fetchRobinhood", async (params, { rejectWithValue, getState }) => {
  try {
    const state = getState();
    // don't send length if it is undefined or 0
    if (!params.length || params.length === 0) {
      delete params.length;
    }
    const query = objectToQueryString(params);
    const sites = state.app.siteSelections
        .find((site) => site.key === params.selectedMill)
        ?.sites.map((site) => `sites=${site}`)
        .join("&");
    const response = await axiosWithAuth.get(
      `${process.env.REACT_APP_API_URL}/internal/order/robin_hood${query}&${sites}`
    );
    
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        case 400:
          return rejectWithValue("Invalid criteria");
        case 401:
          return rejectWithValue("Unauthorized");
        case 403:
          return rejectWithValue("Forbidden");
        case 423:
          return rejectWithValue(error.response?.data);
        case 500:
          return rejectWithValue("Internal server error");
        default:
          return rejectWithValue("There was an issue retrieving report data");
      }
    } else {
      return rejectWithValue("There was an issue retrieving report data");
    }
  }
});

