/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import tw from "twin.macro";
import AnimatedLoadingSpinner from "../../../../components/atoms/AnimatedLoadingSpinner";
import { PrimeInventoryItemSummaryResult } from "../../../../types/types";
import AllStockListItem from "./AllStockListItem";

type Props = {
  children?: ReactNode;
  results?: PrimeInventoryItemSummaryResult;
  isLoading: boolean;
};

const AllStockListResults: React.FC<Props> = ({ results, isLoading }) => {
  return (
    <div css={tw`mt-4 text-xs`}>
      {isLoading && <AnimatedLoadingSpinner css={tw`ml-4`} />}
      {!results && !isLoading && (
        <p css={tw`text-nucor-green text-xs font-semibold`}>
          * Select criteria and click search to view all the inventory.
        </p>
      )}
      {results?.standardLengths.length &&
      results.standardLengths.length > 0 &&
      !isLoading ? (
        <>
          <table className="ntp-portal-table ntp-remove-stripe" css={tw`w-full`}>
            <thead>
              <tr>
                <th css={tw`!border-0 bg-white`} colSpan={3}>
                  &nbsp;
                </th>
                {results?.standardLengths.sort().map((length) => (
                  <th key={length} colSpan={2}>{`${length / 12}'`}</th>
                ))}
              </tr>
              <tr>
                <th css={tw`w-[22%]`}>Size</th>
                <th css={tw`w-[7%]`}>Grade</th>
                <th css={tw`w-[6%]`}>Mill</th>
                {results?.standardLengths.sort().map((length) => (
                  <React.Fragment key={length}>
                    <th
                      style={{
                        width: `${65 / (results?.standardLengths.length * 2)}%`,
                      }}
                    >
                      Pieces
                    </th>
                    <th
                      style={{
                        width: `${65 / (results?.standardLengths.length * 2)}%`,
                      }}
                    >
                      Weight
                    </th>
                  </React.Fragment>
                ))}
              </tr>
            </thead>
            <tbody>
              {results?.productTotals.length && results.productTotals.length > 0 ? (

                results?.productTotals.map((item, index) => {
                  const productIndex = results?.productIndexMap[item.itemNumber];
                  const isEven = index % 2 === 0;
                  return (
                    <AllStockListItem
                      key={item.itemNumber}
                      isEven={isEven}
                      sites={item.sites}
                      productIndex={productIndex}
                      lengths={results?.standardLengths}
                    />
                  );
                })
              ) : (
                <tr>
                  <td css={tw`text-nucor-gray !px-2 !py-3 hover:!bg-white`} colSpan={3 + results.standardLengths.length * 2}>
                    No inventory found for the selected criteria.
                  </td>
                </tr>
              )
              }
            </tbody>
          </table>
            
          {results?.productTotals && results.productTotals.length > 0 &&
            <p css={tw`mt-6 text-xs`}>
              {`Stock is sorted by product family (e.g. 4" SQ family), then width or diameter and wall thickness.`}
            </p>
          }
        </>
      ) : null}
    </div>
  );
};

export default AllStockListResults;
