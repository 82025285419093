import { DateString } from "../types/types";

export const dateToString = (date?: Date): DateString | undefined => {
  if (!date) return undefined;
  const month = date.getMonth() + 1;
  const formatedMonth =
    month.toString().length === 1 ? `0${month}` : month.toString();
  const day = date.getDate();
  const formattedDay = day.toString().length === 1 ? `0${day}` : day.toString();
  const year = date.getFullYear();
  return `${year.toString()}-${formatedMonth}-${formattedDay}` as DateString;
};

export const stringToDate = (date?: DateString) => {
  if (!date) return undefined;
  const regex = /\d{4}-\d{2}-\d{2}/;
  if (!regex.test(date)) {
    return undefined;
  }
  return new Date(`${date}T00:00:00`);
};

export const dateStringShorten = (date?: DateString) => {
  if (!date) return undefined;
  const d = stringToDate(date) as Date;
  return `${d.getMonth() + 1}/${d.getDate()}/${d
    .getFullYear()
    .toString()
    .slice(2, 4)}`;
};

export const formatDateToShort = (date?: DateString) => {
  if (!date) return "";
  const [year, month, day] = date.split("-");
  return `${Number(month).toString()}/${Number(day).toString()}/${year.slice(
    2
  )}`;
};
