/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import { CartCustomer } from "../../../../../types/types";
import tw from "twin.macro";

type Props = {
  children?: ReactNode;
  shipTos?: CartCustomer[];
};

const ShipToDisplay: React.FC<Props> = ({ shipTos }) => {
  if (!shipTos || shipTos.length === 0) {
    return null;
  }
  if (shipTos?.length > 1) {
    return (
      <>
        <p css={tw`text-nucor-gray font-bold`}>Ship To: </p>
        <p css={tw`font-bold`}>Multiple Locations</p>
      </>
    );
  }
  return (
    <>
      <p css={tw`text-nucor-gray font-bold`}>Ship To: </p>
      <p
        css={tw`font-bold uppercase`}
      >{`${shipTos[0].id} - ${shipTos[0].name}`}</p>
      <p
        css={tw`uppercase`}
      >{`${shipTos[0].address.city}, ${shipTos[0].address.stateCode}`}</p>
    </>
  );
};

export default ShipToDisplay;
