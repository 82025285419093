/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import {
  DeepRequired,
  FieldErrorsImpl,
  UseFormRegister,
} from "react-hook-form";
import tw from "twin.macro";
import Input from "../../../../../components/atoms/Input";
import { AddUserForm } from "../addUserForm";

type Props = {
  children?: ReactNode;
  register: UseFormRegister<AddUserForm>;
  errors: FieldErrorsImpl<DeepRequired<AddUserForm>>;
};

const AddUserPersonalInfo: React.FC<Props> = ({ register, errors }) => {
  return (
    <>
      <div css={tw`flex flex-col gap-2`}>
        <div css={tw`flex`}>
          <label css={tw`w-[30%]`} htmlFor="firstName">
            First Name: <span css={tw`text-red-600`}>*</span>
          </label>
          <Input
            id="firstName"
            errors={errors?.firstName}
            css={[tw`bg-nucor-yellow min-w-[49%]`]}
            {...register("firstName")}
          />
        </div>
        <div css={tw`flex`}>
          <label css={tw`w-[30%]`} htmlFor="lastName">
            Last Name: <span css={tw`text-red-600`}>*</span>
          </label>
          <Input
            errors={errors?.lastName}
            css={[tw`bg-nucor-yellow min-w-[49%]`]}
            {...register("lastName")}
            id="lastName"
          />
        </div>
        <div css={tw`flex`}>
          <label css={tw`w-[30%]`} htmlFor="title">
            Title:{" "}
          </label>
          <Input
            errors={errors?.title}
            css={[tw`min-w-[49%]`]}
            {...register("title")}
            id="title"
          />
        </div>
        <div css={tw`flex`}>
          <label css={tw`w-[30%]`} htmlFor="email">
            e-Mail: <span css={tw`text-red-600`}>*</span>{" "}
          </label>
          <Input
            errors={errors?.email}
            css={[tw`min-w-[49%] bg-nucor-yellow`]}
            {...register("email")}
            id="email"
          />
        </div>
        <div css={tw`flex`}>
          <label css={tw`w-[30%]`}>Office:</label>
          <div css={[tw`flex gap-1 items-center`]}>
            <Input
              maxLength={3}
              errors={errors?.officePhone?.areaCode}
              {...register("officePhone.areaCode")}
              css={[tw`w-12`]}
              type="text"
            />
            <Input
              maxLength={3}
              {...register("officePhone.exchange")}
              errors={errors?.officePhone?.exchange}
              css={[tw`w-12`]}
              type="text"
            />
            <Input
              maxLength={4}
              errors={errors?.officePhone?.local4}
              {...register("officePhone.local4")}
              css={[tw`w-16`]}
              type="text"
            />
            <span css={tw`text-xs text-[#6d756e] font-bold`}>Ext.</span>
            <Input
              errors={errors?.officePhoneExt}
              {...register("officePhoneExt")}
              css={tw`w-16`}
              type="text"
            />
          </div>
        </div>
        <div css={tw`flex`}>
          <label css={tw`w-[30%]`}>Mobile:</label>
          <div css={[tw`flex gap-1 items-center`]}>
            <Input
              maxLength={3}
              errors={errors?.mobilePhone?.areaCode}
              {...register("mobilePhone.areaCode")}
              css={[tw`w-12`]}
              type="text"
            />
            <Input
              maxLength={3}
              errors={errors?.mobilePhone?.exchange}
              {...register("mobilePhone.exchange")}
              css={[tw`w-12`]}
              type="text"
            />
            <Input
              maxLength={4}
              errors={errors?.mobilePhone?.local4}
              {...register("mobilePhone.local4")}
              css={[tw`w-16`]}
              type="text"
            />
          </div>
        </div>
        <div css={tw`flex`}>
          <label css={tw`w-[30%]`}>Fax:</label>
          <div css={[tw`flex gap-1 items-center`]}>
            <Input
              errors={errors?.faxPhone?.areaCode}
              maxLength={3}
              {...register("faxPhone.areaCode")}
              css={tw`w-12`}
              type="text"
            />
            <Input
              errors={errors?.faxPhone?.exchange}
              maxLength={3}
              {...register("faxPhone.exchange")}
              css={tw`w-12`}
              type="text"
            />
            <Input
              errors={errors?.faxPhone?.local4}
              maxLength={4}
              {...register("faxPhone.local4")}
              css={tw`w-16`}
              type="text"
            />
          </div>
        </div>
        <div>
          <label css={tw`block`} htmlFor="notes">
            Notes:
          </label>
          <textarea
            {...register("comments")}
            css={tw`w-full h-20 resize-none`}
            id="notes"
          />
        </div>
      </div>
    </>
  );
};

export default AddUserPersonalInfo;
