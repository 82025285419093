/** @jsxImportSource @emotion/react */
import React, { ReactNode, useState } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
  exportUsersAsExcel,
  searchUsers,
} from "../../../../../store/admin.actions";
import Button from "../../../../../components/atoms/Button";
import Select from "../../../../../components/atoms/Select";

import { setSearchSettings } from "../../../../../store/admin.reducer";

type Props = {
  children?: ReactNode;
};

const SearchByClassification: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const { includeDisabled, exportExcel } = useAppSelector(
    (state) => state.admin.searchSettings
  );
  const [searchType, setSearchType] = useState("a53sp");
  const loading = useAppSelector((state) => state.admin.loading);

  const formHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const searchTerms = {
      includeDisabled,
      searchType: searchType,
    };
    if (exportExcel) {
      dispatch(exportUsersAsExcel(searchTerms));
    } else {
      dispatch(searchUsers(searchTerms));
    }
  };

  return (
    <form onSubmit={formHandler} css={[tw`flex gap-4 items-center`]}>
      
      <Select
          name="userSearchType"
          minWidth={"12ch"}
          value={searchType}
          onChange={(searchType: string) => setSearchType(searchType)}
          data={[
            { value: "a53sp", label: "A53-SP" },
            { value: "hss", label: "HSS" },
            { value: "internal", label: "Internal" },
            { value: "admin", label: "Admin" },
            { value: "itAdmin", label: "IT Admin" }
          ]}
          testId="user-search-type-select"
      />

      <fieldset tw="flex items-center">
        <input
          id="includeDisabled"
          checked={includeDisabled}
          onChange={(e) =>
            dispatch(
              setSearchSettings({
                exportExcel,
                includeDisabled: e.target.checked,
              })
            )
          }
          tw="mr-1"
          type="checkbox"
        />
        <label htmlFor="includeDisabled">Include disabled users</label>
      </fieldset>
      <fieldset tw="flex items-center">
        <input
          id="exportExcel"
          checked={exportExcel}
          onChange={(e) =>
            dispatch(
              setSearchSettings({
                exportExcel: e.target.checked,
                includeDisabled,
              })
            )
          }
          tw="mr-1"
          type="checkbox"
        />
        <label htmlFor="exportExcel">Export as Excel</label>
      </fieldset>
      <Button
        type="submit"
        disabled={!searchType || loading}
        css={[tw`text-xs font-normal px-3 py-1 m-0`]}
      >
        Search
      </Button>
    </form>
  );
};

export default SearchByClassification;
