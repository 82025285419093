/** @jsxImportSource @emotion/react */
import React, {
  ReactNode,
  FocusEvent,
  useState,
  useEffect,
  useRef,
} from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { numberFormatter } from "../../../../../helpers/numberFormat";
import {
  addPrimeStockToCart,
  removePrimeStockFromCart,
  updatePrimeStockCartItem,
} from "../../../../../store/shoppingCart.actions";
import { selectCurrentShipTo } from "../../../../../store/customer.reducer";
import {
  ErrorResponse,
  PrimeStockInput,
  PrimeStockOrderableItem,
} from "../../../../../types/types";
import { useAuth0 } from "@auth0/auth0-react";
import { keyFromInventoryItem } from "../../../../../helpers/inventoryKeys";
import { selectCurrentAuthorities } from "../../../../../store/user.reducer";
import { hasAuthority } from "../../../../../helpers/hasAuthority";
import Toast from "../../../../../components/molecules/Toast";
import Tooltip from "./Tooltip";

type Props = {
  children?: ReactNode;
  item: PrimeStockOrderableItem;
};

const PrimeProductTableItem: React.FC<Props> = ({ item }) => {
  const dispatch = useAppDispatch();
  const currentAuthorities = useAppSelector(selectCurrentAuthorities);
  const isLoading = useAppSelector((state) => state.shoppingCart.isLoading);
  const shipToId = useAppSelector(selectCurrentShipTo);
  const customer = useAppSelector((state) => state.customer.currentCustomer);
  const inputRef = useRef<HTMLInputElement>(null);
  const customerPartNumber = useAppSelector(
    (state) => state.product.prime.currentInventoryResults?.customerPartNumber
  );
  const { logout } = useAuth0();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = item.salesOrderItem?.bundles.toString() ?? "";
    }
  }, [item.salesOrderItem]);

  const [error, setError] = useState(false);
  const [cartEditError, setCartEditError] = useState<ErrorResponse | string>();

  const canOrder =
    customer &&
    customer.id &&
    hasAuthority("createOrderQuoteLoad", currentAuthorities);

  const isInternal = hasAuthority("internal", currentAuthorities);

  const updateBundleSize = (
    event: FocusEvent<HTMLInputElement>,
    item: PrimeStockOrderableItem
  ) => {
    setError(false);
    const quantity = +event.target.value;
    if (
      !customer?.id ||
      !item.inventoryItem.numberOfBundles ||
      !inputRef.current
    )
      return;
    if (quantity > item.inventoryItem.numberOfBundles) {
      setError(true);
      inputRef.current.value = item.salesOrderItem?.bundles.toString() ?? "";
      return;
    }
    if (event.target.value === undefined) return;
    if (quantity === item.salesOrderItem?.bundles) return;
    if (quantity === 0 && !item.salesOrderItem) {
      inputRef.current.value = "";
      return;
    }

    // ---- ADD ---- //
    if (!item.salesOrderItem && quantity > 0) {
      const orderItem = {
        bundles: quantity,
        length: item?.inventoryItem?.length,
        itemNumber: item?.inventoryItem?.itemNumber,
        shipToId: shipToId,
        bundleSize: item?.inventoryItem?.bundleSize,
        site: item?.inventoryItem?.site,
        warehouse: item?.inventoryItem?.warehouse,
        customerPartNumber,
        inventoryStatus: item?.inventoryItem?.inventoryStatus,
      };
      dispatch(
        addPrimeStockToCart({
          customerId: customer?.id,
          item: orderItem as PrimeStockInput,
        })
      )
        .unwrap()
        .catch((error) => {
          if (error.status === 400) {
            setCartEditError(error);
          }
          if (inputRef.current) {
            inputRef.current.value = "";
          }
          // if the error is Unathoirized, log the user out
          if (error === "Unauthorized") {
            logout({ returnTo: window.location.origin });
          }
        });
    }

    // --- EDIT --- //

    if (item.salesOrderItem && quantity > 0) {
      const previousQuantity = item.salesOrderItem.bundles;
      const orderItem = {
        bundles: quantity,
        length: item?.inventoryItem?.length,
        itemNumber: item?.inventoryItem?.itemNumber,
        shipToId: shipToId,
        bundleSize: item?.inventoryItem?.bundleSize,
        site: item?.inventoryItem?.site,
        warehouse: item?.inventoryItem?.warehouse,
        customerPartNumber,
        inventoryStatus: item?.inventoryItem?.inventoryStatus,
      };
      dispatch(
        updatePrimeStockCartItem({
          customerId: customer?.id,
          item: orderItem as PrimeStockInput,
          salesOrderItemId: item.salesOrderItem?.id,
        })
      )
        .unwrap()
        .catch((error) => {
          if (error.status === 400) {
            setCartEditError(error);
          }
          if (inputRef.current) {
            inputRef.current.value = previousQuantity.toString();
          }
          // if the error is Unathoirized, log the user out
          if (error === "Unauthorized") {
            logout({ returnTo: window.location.origin });
          }
        });
    }

    // ---- REMOVE --- //
    if (item.salesOrderItem && quantity === 0) {
      dispatch(
        removePrimeStockFromCart({
          customerId: customer.id,
          salesOrderItemId: item.salesOrderItem.id,
        })
      );
    }
  };

  return (
    <>
      {cartEditError && (
        <Toast
          type="error"
          message={cartEditError}
          onConfirm={() => setCartEditError(undefined)}
        />
      )}
      <tr>
        <td css={tw`relative`}>
          <span className="peer">{item.inventoryItem.productName}</span>
          {isInternal && (
            <Tooltip
              css={tw`right-2 -top-4`}
              message={`${item.inventoryItem.itemNumber} ${item.inventoryItem.inventoryStatus}`}
            />
          )}
        </td>
        <td css={tw`text-right`}>{item.inventoryItem.lengthDescription}</td>
        <td css={tw`text-center`}>
          {item.inventoryItem.site?.substring(
            item.inventoryItem.site.startsWith("NTP-") ? 4 : 0
          )}
        </td>
        <td css={tw`text-right`}>
          {item.inventoryItem.totalWeight &&
            numberFormatter(item.inventoryItem.totalWeight)}
        </td>
        <td css={tw`text-right`}>
          {item.inventoryItem.bundleWeight &&
            numberFormatter(item.inventoryItem.bundleWeight)}
        </td>
        <td css={tw`text-right`}>
          {item.inventoryItem.totalPieces &&
            numberFormatter(item.inventoryItem.totalPieces)}
        </td>
        <td css={tw`text-right`}>
          {item.inventoryItem.bundleSize &&
            numberFormatter(item.inventoryItem.bundleSize)}
        </td>
        <td css={tw`text-right`}>{item.inventoryItem.numberOfBundles}</td>
        {item.inventoryItem.bundleSize ? (
          <>
            <td css={tw`text-center relative`}>
              {item.inventoryItem.vmi ? (
                <>
                  <span className="peer">VMI</span>
                  <Tooltip
                    className="whitespace-nowrap z-50 left-full -top-1 -ml-4"
                    message={`${item.inventoryItem.vmiCustomerId} ${item.inventoryItem.vmiCustomerGroup}`}
                    style={{ backgroundColor: "rgb(255, 255, 255)", color: "#000" }}
                  />
                </>
              ) : (
                canOrder && (
                  <input
                    title=""
                    name={keyFromInventoryItem(item)}
                    ref={inputRef}
                    type="number"
                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                      if (isLoading) return e.preventDefault();
                      if (
                        [
                          "Tab",
                          "ArrowLeft",
                          "ArrowRight",
                          "Backspace",
                        ].includes(e.key)
                      )
                        return;
                      if (e.key === "Escape" || e.key === "Enter")
                        return e.currentTarget.blur();
                      if (!/[0-9]+/.test(e.key)) return e.preventDefault();
                    }}
                    min={1}
                    onBlur={(e) => updateBundleSize(e, item)}
                    css={[tw`w-[5ch] py-0`, error && tw`border-red-600`]}
                  />
                )
              )}
            </td>
            <td css={tw`text-right`}>
              {item.salesOrderItem?.weight &&
                numberFormatter(item.salesOrderItem.weight)}
            </td>
          </>
        ) : (
          <td colSpan={2} css={tw`text-center`}>
            Contact ISR to order
          </td>
        )}
      </tr>
    </>
  );
};

export default PrimeProductTableItem;
