/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import BrowseSearch from "../../mydocuments/BrowseSearch";
import OpenOrdersBrowse from "./OpenOrdersBrowse";
import OpenOrdersSearch from "./OpenOrdersSearch";

type Props = {
  children?: ReactNode;
};

const OpenOrdersLeft: React.FC<Props> = () => {
  
  return (
    <BrowseSearch
      browse={<OpenOrdersBrowse />}
      search={<OpenOrdersSearch />}
    />
  );
};

export default OpenOrdersLeft;
