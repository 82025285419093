/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import tw from "twin.macro";
import { CartHeader } from "../../../../../../types/types";
import { formatDateToShort } from "../../../../../../helpers/dateHelpers";

type Props = {
  children?: ReactNode;
  cart?: CartHeader;
};

const OrderDetails: React.FC<Props> = ({ cart }) => {
  return (
    <dl css={tw`grid grid-cols-[30% 70%] gap-[2px]`}>
      <dt css={tw`font-bold text-nucor-gray`}>Order Date:</dt>
      <dd>{formatDateToShort(cart?.orderDate)}</dd>
      <dt css={tw`font-bold text-nucor-gray`}>Pricing:</dt>
      <dd>{cart?.pricingModel}</dd>
    </dl>
  );
};

export default OrderDetails;
