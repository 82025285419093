/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import TextButton from "../../../../../components/atoms/TextButton";
import { numberFormatter } from "../../../../../helpers/numberFormat";
import { selectCurrentCustomerId } from "../../../../../store/customer.reducer";
import { removeLoadCartItem } from "../../../../../store/shipping.actions";
import { LoadLine } from "../../../../../types/types";
import { useNavigate } from "react-router-dom";
import { setReleaseOrderShipToIdToExpand } from "../../../../../store/shipping.reducer";

type Props = {
  children?: ReactNode;
  lines: LoadLine[];
  readOnly?: boolean;
  onCancel: () => void;
};

const LoadTable: React.FC<Props> = ({ lines, readOnly, onCancel }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const customerId = useAppSelector(selectCurrentCustomerId);

  const removeItemHandler = (id: number) => {
    if (!id || !customerId) return;
    dispatch(removeLoadCartItem({ customerId, id }));
  };

  return (
    <table className="ntp-portal-table" css={tw`text-xs w-full`}>
      <thead>
        <tr>
          <th css={tw`w-[3%]`}>#</th>
          <th css={tw`w-[8%]`}>PO Number</th>
          <th css={tw`w-[12%]`}>Sales Order</th>
          <th css={tw`w-[3%]`}>Mill</th>
          <th>Description</th>
          <th css={tw`w-[6%]`}>Delivery Mode</th>
          <th css={tw`w-[7%]`}>Delivery Terms</th>
          <th css={tw`w-[5%]`}>Grade</th>
          <th css={tw`w-[5%]`}>Pcs Loaded</th>
          <th css={tw`w-[5%]`}>Lbs Selected</th>
          {readOnly ? null : <th css={tw`w-[10%]`}>Actions</th>}
        </tr>
      </thead>
      <tbody>
        {lines?.map((line, index) => (
          <tr css={[index % 2 !== 0 && tw`bg-[#d5d5d5]`]} key={line.id}>
            <td css={tw`text-center`}>{line.id}</td>
            <td>{line.customerPONumber}</td>
            <td>{`${line.salesOrderNumber}-${line.lineNumber ?? ""}`}</td>
            <td>{line.site?.slice(4)}</td>
            <td>
              {" "}
              {line.customerPartNumber ? (
                <p>{line.customerPartNumber}</p>
              ) : null}
              <p>{line.lineDescription}</p>
            </td>
            <td>{line.deliveryMode}</td>
            <td>{line.deliveryTerms}</td>
            <td>{line.grade}</td>
            <td css={tw`text-right`}>{line.quantity}</td>
            <td css={tw`text-right`}>{numberFormatter(line.loadedWeight)}</td>
            {readOnly ? null : (
              <td css={tw`text-center`}>
                <TextButton
                  onClick={() => {
                    dispatch(
                      setReleaseOrderShipToIdToExpand(line.shipToCustomerId)
                    );
                    navigate(
                      `/portal/myshipping/orderreleaselist?customerId=${customerId}`
                    );
                    onCancel();
                  }}
                >
                  Edit
                </TextButton>
                <TextButton
                  onClick={() => removeItemHandler(line.id)}
                  css={tw`ml-2`}
                >
                  Remove
                </TextButton>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default LoadTable;
