/** @jsxImportSource @emotion/react */
import React, { ComponentProps, ReactNode } from "react";
import tw from "twin.macro";
import Input from "../atoms/Input";
import Select from "../atoms/Select";
import { fractions } from "../../helpers/fractions";

type Props = {
  children?: ReactNode;
  disabled?: boolean;
  value: { feet: number; inches: number; fraction: number };
  onLengthChange: (length: {
    feet: number;
    inches: number;
    fraction: number;
  }) => void;
} & ComponentProps<"div">;

const LengthComponent: React.FC<Props> = ({
  disabled,
  value: { feet, inches, fraction },
  onLengthChange,
  ...remainingProps
}) => {
  return (
    <div css={tw`flex w-[80%] gap-2`} {...remainingProps}>
      <fieldset css={tw`flex flex-col`}>
        <label htmlFor="feet">Feet</label>
        <Input
          css={tw`w-[4ch] py-[2px]`}
          name="feet"
          value={feet === 0 ? "" : feet}
          onChange={(e) => {
            if (Number.isNaN(+e.target.value)) return;
            onLengthChange({ feet: +e.target.value, inches, fraction });
          }}
          disabled={disabled}
        />
      </fieldset>
      <fieldset css={tw`flex flex-col`}>
        <label htmlFor="inches">Inches</label>
        <Input
          css={tw`w-[4ch] py-[2px]`}
          name="inches"
          value={inches === 0 ? "" : inches}
          onChange={(e) => {
            if (Number.isNaN(+e.target.value)) return;
            onLengthChange({ feet, inches: +e.target.value, fraction });
          }}
          disabled={disabled}
        />
      </fieldset>
      <fieldset css={tw`flex flex-col`}>
        <label htmlFor="fraction">Fraction</label>
        <Select
          name="fraction"
          data={fractions}
          value={fraction}
          onChange={(fraction: number) => {
            onLengthChange({ feet, inches, fraction });
          }}
          disabled={disabled}
          minWidth="10ch"
        />
      </fieldset>
    </div>
  );
};

export default LengthComponent;
