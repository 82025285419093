/** @jsxImportSource @emotion/react */
import React, { ReactNode, useState } from "react";
import { createPortal } from "react-dom";
import tw from "twin.macro";
import Input from "../../../../../components/atoms/Input";
import Button from "../../../../../components/atoms/Button";
import TextButton from "../../../../../components/atoms/TextButton";

type Props = {
  children?: ReactNode;
  onCancel: () => void;
  onConfirm: (newCustomerPartNumber: string) => void;
  defaultValue: string;
};

const CustomerPartNumberDialog: React.FC<Props> = ({
  onCancel,
  onConfirm,
  defaultValue,
  ...remainingProps
}) => {
  const [newPartNumber, setNewPartNumber] = useState<string>(
    defaultValue ?? ""
  );

  const formHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onConfirm(newPartNumber);
  };

  return createPortal(
    <div
      css={[
        tw`fixed top-0 left-0 w-full h-full flex justify-center items-center bg-[#cccccc77] z-[100]`,
      ]}
      {...remainingProps}
    >
      <div
        css={[tw`bg-nucor-green p-[7px] text-xs`]}
        onClick={(e) => e.stopPropagation()}
      >
        <div css={tw`flex justify-between`}>
          <h2 css={tw`font-bold text-white pb-1 text-xs flex-1`}>
            Customer Part Number
          </h2>
          <button
            type="button"
            onClick={onCancel}
            css={tw`text-nucor-green bg-[#ccc] h-[13px] w-[13px] flex justify-center items-center font-bold mt-px`}
          >
            &times;
          </button>
        </div>
        <form onSubmit={formHandler} css={tw`bg-white p-2`}>
          <p>Enter new part number</p>
          <div css={tw`flex justify-center my-2`}>
            <Input
              autoFocus
              css={tw`min-w-[55ch]`}
              autoComplete="off"
              maxLength={50}
              value={newPartNumber}
              onChange={(e) => {
                setNewPartNumber(e.target.value);
              }}
              name="newCustomerPartNumber"
            />
          </div>
          <div css={tw`flex items-center mt-4 mb-1`}>
            <TextButton
              onClick={() => onConfirm("")}
              css={tw`self-start mr-auto`}
            >
              Remove
            </TextButton>
            <Button
              css={tw`mx-6 text-xs font-normal py-[3px] bg-nucor-gray`}
              type="button"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button css={tw`m-0 text-xs font-normal py-[3px]`} type="submit">
              Submit
            </Button>
          </div>
        </form>
      </div>
    </div>,
    document.getElementById("modal") as HTMLElement
  );
};

export default CustomerPartNumberDialog;
