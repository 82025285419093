/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import tw from "twin.macro";
import { ShipToRequest } from "../../../../types/types";
import { generatePhoneNumber } from "../../../../helpers/phonenumber";
import Button from "../../../../components/atoms/Button";

type Props = {
  children?: ReactNode;
  details?: ShipToRequest;
  onReset: () => void;
};

const RequestNewShipToConfirmation: React.FC<Props> = ({
  details,
  onReset,
}) => {
  return (
    <>
      <h2
        css={tw`bg-nucor-green text-white font-bold py-1 px-2 uppercase w-full text-xs`}
      >
        Request New Ship To
      </h2>
      <div css={tw`max-w-3xl ml-2 mt-4`}>
        <p css={tw`text-xs text-nucor-green my-2`}>
          Thank you for your request. You will be notified by a Nucor rep after
          your new ship to has been setup and is ready for use.
        </p>
        <div css={tw`text-xs flex flex-col gap-2`}>
          <p>
            How will this ship?{" "}
            <span css={tw`font-semibold text-nucor-gray ml-2`}>
              {details?.transportType}
            </span>
          </p>
          <p>
            Who pays the freight bill?{" "}
            <span css={tw`font-semibold text-nucor-gray ml-2`}>
              {details?.customerPayingForFreight ? "Customer" : "NTP"}
            </span>
          </p>
          <div css={tw`flex gap-4 items-center`}>
            <label
              css={[tw`font-semibold text-nucor-gray`, { width: "20%" }]}
              htmlFor="contactName"
            >
              Contact Name
            </label>
            <span>{details?.contactName}</span>
          </div>
          <div css={tw`flex gap-4 items-center`}>
            <label
              css={[tw`font-semibold text-nucor-gray`, { width: "20%" }]}
              htmlFor="contactPhoneArea"
            >
              Contact Phone
            </label>
            <span>{generatePhoneNumber(details?.contactPhone)}</span>
          </div>
          <div css={tw`flex gap-4 items-center`}>
            <label
              css={[tw`font-semibold text-nucor-gray`, { flexBasis: "20%" }]}
              htmlFor="shipToName"
            >
              Ship To Name
            </label>
            <span>{details?.shipToName}</span>
          </div>
          <div css={tw`flex gap-4 items-center`}>
            <label
              css={[tw`font-semibold text-nucor-gray`, { flexBasis: "20%" }]}
              htmlFor="address1"
            >
              Address 1
            </label>
            <span>{details?.address1}</span>
          </div>
          <div css={tw`flex gap-4 items-center`}>
            <label
              css={[tw`font-semibold text-nucor-gray`, { flexBasis: "20%" }]}
              htmlFor="address2"
            >
              {details?.transportType === "Rail"
                ? "Rail Service Provider"
                : "Address 2"}
            </label>
            <span>{details?.address2}</span>
          </div>
          <div css={tw`flex gap-4 items-center`}>
            <label
              css={[tw`font-semibold text-nucor-gray`, { flexBasis: "20%" }]}
              htmlFor="address3"
            >
              {details?.transportType === "Rail" ? "Routing" : "Address 3"}
            </label>
            <span>{details?.address3}</span>
          </div>
          <div css={tw`flex gap-2 items-center`}>
            <label css={[tw`font-semibold text-nucor-gray`]} htmlFor="city">
              City
            </label>
            <span>{details?.city}</span>
            <label
              css={[tw`font-semibold text-nucor-gray ml-4`]}
              htmlFor="state"
            >
              State
            </label>
            <span>{details?.stateCode}</span>
            <label css={tw`ml-4 font-semibold text-nucor-gray`} htmlFor="zip">
              Zip
            </label>
            <span>{details?.postalCode}</span>
            <label
              css={tw`ml-4 font-semibold text-nucor-gray`}
              htmlFor="country"
            >
              Country
            </label>
            <span>{details?.country}</span>
          </div>
          <div css={tw`flex gap-4 items-center`}>
            <label
              css={[tw`font-semibold text-nucor-gray`, { flexBasis: "20%" }]}
              htmlFor="phone"
            >
              Phone
            </label>
            <div css={[tw`flex gap-2 items-center`]}>
              <span>{generatePhoneNumber(details?.shipToPhone)}</span>
              <span css={tw`text-xs text-[#6d756e] font-bold`}>Ext.</span>
              <span>{details?.shipToPhoneExtension}</span>
            </div>
          </div>
          <div css={tw`flex gap-4 items-center`}>
            <label
              css={[tw`font-semibold text-nucor-gray`, { flexBasis: "20%" }]}
              htmlFor="fax"
            >
              Fax
            </label>
            <div css={[tw`flex gap-2 items-center`]}>
              <span>{generatePhoneNumber(details?.shipToFax)}</span>
              <span css={tw`text-xs text-[#6d756e] font-bold`}>Ext.</span>
              <span>{details?.shipToFaxExtension}</span>
            </div>
          </div>
          <div css={tw`flex gap-4`}>
            <label css={{ flexBasis: "20%" }} htmlFor="notes">
              Notes
            </label>
            <span>{details?.notes}</span>
          </div>
          <div css={[tw`flex justify-center gap-4 w-10/12 items-center mt-6`]}>
            <Button
              onClick={onReset}
              type="button"
              css={[tw`font-normal text-xs`]}
            >
              New Request
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestNewShipToConfirmation;
