import { PrimeStockOrderableItem, SalesOrderItem } from "../types/types";

// Helper functions for creating keys for inventory items on prime stock
// Must use the proper order of concatenation to ensure matching salesOrderItem key
// These keys are used to maintain unique key for mapping components and matching
// salesOrderItem to inventory items in state.

export const keyFromInventoryItem = (inventory?: PrimeStockOrderableItem) => {
  if (!inventory?.inventoryItem) return undefined;
  const {
    itemNumber,
    site,
    warehouse,
    length,
    bundleSize,
    vmi,
    inventoryStatus,
    vmiCustomerId,
  } = inventory.inventoryItem;
  return `${itemNumber}${site}${warehouse}${length}${bundleSize}${vmi}${inventoryStatus}${vmiCustomerId}`;
};

export const keyFromSalesOrderItem = (salesOrderItem?: SalesOrderItem) => {
  if (!salesOrderItem) return undefined;
  const {
    itemNumber,
    site,
    warehouse,
    length,
    piecesPerBundle,
    inventoryStatus,
  } = salesOrderItem;
  return `${itemNumber}${site}${warehouse}${length}${piecesPerBundle}false${inventoryStatus}undefined`;
};
