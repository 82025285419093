/** @jsxImportSource @emotion/react */
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import AnimatedLoadingSpinner from "../../../../../../components/atoms/AnimatedLoadingSpinner";
import { numberFormatter } from "../../../../../../helpers/numberFormat";
import { fetchQuoteCartSummaries } from "../../../../../../store/quoteCart.actions";
import { setCurrentCustomerDetails } from "../../../../../../store/customer.actions";

type Props = {
  children?: ReactNode;
  onCancel: () => void;
};

const OpenInquiryCartsDialog: React.FC<Props> = ({
  onCancel,
  ...remainingProps
}) => {
  const initialLoad = useRef(true);
  const dispatch = useAppDispatch();
  const { quoteCartSummaries } = useAppSelector((state) => state.quoteCart);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (initialLoad.current) {
      setIsLoading(true);
      dispatch(fetchQuoteCartSummaries()).finally(() => setIsLoading(false));
      initialLoad.current = false;
    }
  }, []);

  return createPortal(
    <div
      onClick={onCancel}
      css={[
        tw`fixed top-0 left-0 w-full h-full flex justify-center items-center bg-[#cccccc77] z-[100]`,
      ]}
      {...remainingProps}
    >
      <div
        css={[tw`bg-nucor-green p-[7px] min-w-[50ch] w-[90ch] text-xs`]}
        onClick={(e) => e.stopPropagation()}
      >
        <div css={tw`flex justify-between`}>
          <h2 css={tw`font-bold text-white pb-1 text-xs flex-1`}>
            Change Customer
          </h2>
          <button
            type="button"
            onClick={onCancel}
            css={tw`text-nucor-green bg-[#ccc] h-[13px] w-[13px] flex justify-center items-center font-bold mt-px`}
          >
            &times;
          </button>
        </div>
        <div css={tw`bg-white p-1 overflow-auto max-h-[500px]`}>
          {isLoading && <AnimatedLoadingSpinner />}
          {!isLoading && (
            <>
              <table css={tw`w-full !text-xs`}>
                <thead>
                  <tr>
                    <th>Save Date</th>
                    <th css={tw`w-2/5`}>Customer</th>
                    <th>Items Total</th>
                    <th>Wgt Total</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {quoteCartSummaries?.map((cart) => {
                    return (
                      <tr key={cart.id}>
                        <td>{cart.updatedDate.slice(0, 10)}</td>
                        <td>{cart.customerName}</td>
                        <td>{cart.totalItems}</td>
                        <td>{numberFormatter(cart.totalWeight)}</td>
                        <td>
                          <button
                            type="button"
                            onClick={() => {
                              dispatch(
                                setCurrentCustomerDetails(cart.customerId)
                              );
                              onCancel();
                            }}
                            css={tw`text-nucor-link hover:text-nucor-link-hover`}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <p css={tw`mt-2`}>
                Clicking on View link will save your current shopping/inquiry
                cart and open the selected customer&apos;s cart.
              </p>
            </>
          )}
        </div>
      </div>
    </div>,
    document.getElementById("modal") as HTMLElement
  );
};

export default OpenInquiryCartsDialog;
