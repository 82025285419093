/** @jsxImportSource @emotion/react */
import React, { ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";
import tw from "twin.macro";
import { useAppDispatch } from "../../../../app/hooks";
import { numberFormatter } from "../../../../helpers/numberFormat";
import {
  PrimeInventorySiteTotals,
  ProductIndex,
  SummaryCustomer,
} from "../../../../types/types";
import TextButton from "../../../../components/atoms/TextButton";
import { updateOpenOrderSearchCriteria } from "../../../../store/shipping.reducer";
import { fetchProductPriceSheetPDF } from "../../../../store/product.actions";
import { setCurrentCustomerDetails } from "../../../../store/customer.actions";

type Props = {
  children?: ReactNode;
  sites?: PrimeInventorySiteTotals[];
  itemNumber?: string;
  productIndex?: ProductIndex;
  item: SummaryCustomer;
  index: number;
  lengths?: number[];
  groupShipToFlag: boolean;
};

const AllCustomerSummaryItem: React.FC<Props> = ({ item, index, groupShipToFlag }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [priceSheetLoading, setPriceSheetLoading] = useState(false);

  const getPriceSheet = async (selectedCustomerId: string) => {
    // if (!selectedCustomerId) return;
    setPriceSheetLoading(true);
    dispatch(fetchProductPriceSheetPDF({customerId: selectedCustomerId}))
      .unwrap()
      .then(() => setPriceSheetLoading(false))
      .catch(() => setPriceSheetLoading(false));
  };

  return (
    <>
      <tr key={index}>
        <td>{item.customerId}</td>
        <td>{item.customerName}</td>
        {groupShipToFlag && <td>{item.shipToId}</td>}
        {groupShipToFlag && <td>{item.shipToName}</td>}
        <td>{item.cityState}</td>
        <td css={tw`text-right`}>
          {item.ytdInvoiceTons && numberFormatter(item.ytdInvoiceTons)}
        </td>
        <td css={tw`text-right`}>
          {item.lastYearInvoiceTons && numberFormatter(item.lastYearInvoiceTons)}
        </td>
        <td css={tw`text-right`}>
          <TextButton
            onClick={async () => {
              await dispatch(
                setCurrentCustomerDetails(item.customerId)
              ).unwrap();
              dispatch(
                updateOpenOrderSearchCriteria({
                  siteKey: "ALL",
                  dateRangeType: "all",
                  shipToCustomerId: undefined,
                  page: 1,
                  sort: "salesOrderNumber,lineNumber",
                  dir: "asc",
                })
              );
              navigate({
                pathname: "/portal/myshipping/openloads",
                search: item.customerId
                  ? `?customer_id=${item.customerId}`
                  : undefined,
              });
            }}
          >
            {item.loadWgt && item.loadWgt !== 0
              ? numberFormatter(item.loadWgt)
              : null}
          </TextButton>
        </td>
        <td css={tw`text-right`}>
          <TextButton
            onClick={async () => {
              await dispatch(
                setCurrentCustomerDetails(item.customerId)
              ).unwrap();
              dispatch(
                updateOpenOrderSearchCriteria({
                  siteKey: "ALL",
                  dateRangeType: "all",
                  shipToCustomerId: undefined,
                  page: 1,
                  sort: "salesOrderNumber,lineNumber",
                  dir: "asc",
                })
              );
              navigate({
                pathname: "/portal/myshipping/openorders",
                search: item.customerId
                  ? `?customer_id=${item.customerId}`
                  : undefined,
              });
            }}
          >
            {item.openOrderCount && item.openOrderCount !== 0
              ? numberFormatter(item.openOrderCount)
              : null}
          </TextButton>
        </td>
        <td css={tw`text-right`}>
          <TextButton
            onClick={async () => {
              await dispatch(
                setCurrentCustomerDetails(item.customerId)
              ).unwrap();
              dispatch(
                updateOpenOrderSearchCriteria({
                  siteKey: "ALL",
                  dateRangeType: "all",
                  shipToCustomerId: undefined,
                  page: 1,
                  sort: "salesOrderNumber,lineNumber",
                  dir: "asc",
                })
              );
              navigate({
                pathname: "/portal/myshipping/openorders",
                search: item.customerId
                  ? `?customer_id=${item.customerId}`
                  : undefined,
              });
            }}
          >
            {item.balanceWgt && item.balanceWgt !== 0
              ? numberFormatter(item.balanceWgt)
              : null}
          </TextButton>
        </td>
        <td css={tw`text-right`}>
          <TextButton
            onClick={async () => {
              await dispatch(
                setCurrentCustomerDetails(item.customerId)
              ).unwrap();
              dispatch(
                updateOpenOrderSearchCriteria({
                  siteKey: "ALL",
                  dateRangeType: "all",
                  shipToCustomerId: undefined,
                  page: 1,
                  sort: "salesOrderNumber,lineNumber",
                  dir: "asc",
                })
              );
              navigate({
                pathname: "/portal/myshipping/orderreleaselist",
                search: item.customerId
                  ? `?customer_id=${item.customerId}`
                  : undefined,
              });
            }}
          >
            {item.readyToShipWgt && item.readyToShipWgt !== 0
              ? numberFormatter(item.readyToShipWgt)
              : null}
          </TextButton>
        </td>
        <td css={tw`text-right`}>
          <TextButton
            onClick={async () => {
              await dispatch(
                setCurrentCustomerDetails(item.customerId)
              ).unwrap();
              dispatch(
                updateOpenOrderSearchCriteria({
                  siteKey: "ALL",
                  dateRangeType: "over30Days",
                  shipToCustomerId: undefined,
                  page: 1,
                  sort: "salesOrderNumber,lineNumber",
                  dir: "asc",
                })
              );
              navigate({
                pathname: "/portal/myshipping/openorders",
                search: item.customerId
                  ? `?customer_id=${item.customerId}`
                  : undefined,
              });
            }}
          >
            {item.pastDue30Wgt && item.pastDue30Wgt !== 0
              ? numberFormatter(item.pastDue30Wgt)
              : null}
          </TextButton>
        </td>
        <td>{item.deliveryTerms}</td>
        <td>{item.deliveryMode}</td>
        <td css={tw`text-right`}>
          {item.basePrice && item.basePrice !== 0
            ? `${Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(item.basePrice)} ${item.currency}`
            : null}
        </td>
        <td css={tw`text-center`}>
          <TextButton
            disabled={priceSheetLoading}
            onClick={() => {
              getPriceSheet(item.customerId);
            }}
          >
            View
          </TextButton>
        </td>
      </tr>
    </>
  );
};

export default AllCustomerSummaryItem;