/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import tw from "twin.macro";
import { useAppSelector } from "../../../../app/hooks";
import TableSortButton from "../../../../components/molecules/TableSortButton";
import { SearchCriteria } from "../../../../types/types";

type Props = {
  children?: ReactNode;
  currentSort: Pick<SearchCriteria, "sort" | "dir">;
  onSort: React.Dispatch<
    React.SetStateAction<{ sort: string; dir: "asc" | "desc" }>
  >;
};

const ReleaseOrdersTable: React.FC<Props> = ({ currentSort, onSort }) => {
  const showPrices = useAppSelector(
    (state) => state.shipping.releaseOrder.selectionData?.showPrices
  );
  const sortOrdersByDescription = useAppSelector(
    (state) =>
      state.user.currentContext?.userPreferences?.sortOrdersByDescription
  );

  const sortHandler = (sort: string) => {
    onSort({
      sort: sort,
      dir:
        currentSort.sort !== sort
          ? "desc"
          : currentSort.dir === "asc"
          ? "desc"
          : "asc",
    });
  };

  return (
    <table className="ntp-portal-table" css={tw`w-full`}>
      <thead>
        <tr>
          <th css={tw`w-[10%]`}>
            <TableSortButton
              sort={currentSort}
              onClick={(field) => sortHandler(field)}
              field="customerPONumber"
            >
              PO Number
            </TableSortButton>
          </th>
          <th css={tw`w-[9%]`}>
            <TableSortButton
              sort={currentSort}
              field="salesOrderNumber,lineNumber"
              onClick={(field) => sortHandler(field)}
            >
              Sales Order
            </TableSortButton>
          </th>
          {showPrices && (
            <th css={tw`w-[9%]`}>
              <TableSortButton
                sort={currentSort}
                field="totalPrice"
                onClick={(field) => sortHandler(field)}
              >
                Price
              </TableSortButton>
            </th>
          )}
          <th css={tw`w-[4%]`}>
            <TableSortButton
              sort={currentSort}
              field="site"
              onClick={(field) => sortHandler(field)}
            >
              Mill
            </TableSortButton>
          </th>
          <th css={tw`w-[6%]`}>
            <TableSortButton
              sort={currentSort}
              field="orderDate"
              onClick={(field) => sortHandler(field)}
            >
              Order Date
            </TableSortButton>
          </th>
          <th css={tw`w-[6%]`}>
            <TableSortButton
              sort={currentSort}
              field="requestedShippingDate"
              onClick={(field) => sortHandler(field)}
            >
              Due Date
            </TableSortButton>
          </th>
          <th css={tw`w-[21%]`}>
            <TableSortButton
              sort={currentSort}
              field={
                sortOrdersByDescription
                  ? "lineDescription"
                  : "customerPartNumber"
              }
              onClick={(field) => sortHandler(field)}
            >
              Description
            </TableSortButton>
          </th>
          <th css={tw`w-[6%]`}>Grd</th>
          <th css={tw`w-[6%]`}>
            <TableSortButton
              sort={currentSort}
              field={"readyToLoadWeight"}
              onClick={(field) => sortHandler(field)}
            >
              Wgt Alc
            </TableSortButton>
          </th>
          <th css={tw`w-[4%]`}>
            <TableSortButton
              sort={currentSort}
              field={"readyToLoadPieces"}
              onClick={(field) => sortHandler(field)}
            >
              Pcs Alc
            </TableSortButton>
          </th>
          <th css={tw`w-[5%]`}>Wgt Load</th>
          <th css={tw`w-[4%]`}>Pcs Load</th>
          <th css={tw`w-[4%]`}>Load All</th>
        </tr>
      </thead>
    </table>
  );
};

export default ReleaseOrdersTable;
