/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import React, { ReactNode } from "react";
import { css } from "@emotion/react";

type Props = {
  children?: ReactNode;
} & React.ComponentProps<"button">;

const Button: React.FC<Props> = ({ children, ...remainingProps }) => {
  const { disabled } = remainingProps;
  return (
    <button
      type="button"
      css={[
        tw`bg-nucor-light-green border-0 text-white px-4 py-[3px] text-xs font-medium`,
        css`
          &:focus {
            outline: none;
            box-shadow: 2px 4px 2px -1px rgb(0 0 0 / 0.3),
              2px 2px 4px -2px rgb(0 0 0 / 0.2);
          }
          &:hover {
            outline: none;
            box-shadow: 2px 4px 2px -1px rgb(0 0 0 / 0.3),
              2px 2px 4px -2px rgb(0 0 0 / 0.2);
          }
        `,
        disabled && tw`bg-gray-300 text-gray-800`,
      ]}
      {...remainingProps}
    >
      {children}
    </button>
  );
};

export default Button;
