/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import BrowseSearch from "../../mydocuments/BrowseSearch";
import BillsOfLadingBrowse from "./BillsOfLadingBrowse";
import BillsOfLadingSearch from "./BillsOfLadingSearch";

type Props = {
  children?: ReactNode;
};

const OpenOrdersLeft: React.FC<Props> = () => {

  return (
    <BrowseSearch
      browse={<BillsOfLadingBrowse />}
      search={<BillsOfLadingSearch />}
    />
  );
};

export default OpenOrdersLeft;
