/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import StockList from "./prime/StockList";
import Cart from "./cart/Cart";
import AllStockList from "./stock_list/AllStockList";
import SecondaryList from "./secondary/SecondaryList";
import SubNavTabs from "../../../components/organisms/SubNavTabs";
import PartList from "./parts/PartList";
import RobinHood from "./robinhood/RobinHoodList";
import { useAppSelector } from "../../../app/hooks";
import { selectCartSummary } from "../../../store/shoppingCart.reducer";
import NotFound from "../../app/NotFound";
import AuthorityRoutes from "../../../routing/AuthorityRoutes";
import { SubmenuType } from "../../admin/Admin";
import ShortsList from "./shorts/ShortsList";
import { hasAuthority } from "../../../helpers/hasAuthority";
import { selectCurrentAuthorities } from "../../../store/user.reducer";

type Props = {
  children?: ReactNode;
};

const Stock: React.FC<Props> = () => {
  const cartSummary = useAppSelector(selectCartSummary);
  const location = useLocation();
  const showParts = useAppSelector(
    (state) => state.customer.currentCustomer?.showParts
  );
  const currentAuthorities = useAppSelector(selectCurrentAuthorities);

  const submenu: SubmenuType[] = [
    { title: "Prime", to: "stocklist", showCustId: true },
    { title: "Stock List", to: "allstocklist", showCustId: true, authority: ["viewPrimeStock"]},
    {
      title: "Secondary",
      to: "secondarystocklist",
      showCustId: true,
      authority: ["viewNonPrimeStock"],
    },
    {
      title: "Shorts",
      to: "shortsstocklist",
      showCustId: true,
      authority: ["viewNonPrimeStock"],
    },
  ];

  if (showParts) {
    submenu.push({ title: "Part List", to: "partlist", showCustId: true });
  }

  if (hasAuthority("internal", currentAuthorities)) {
    submenu.push({ title: "Robin Hood", to: "robinhood", showCustId: true });
  }

  const cartMenu = [
    {
      title: "Shopping Cart",
      to: "cart/shopping",
      count: cartSummary?.totalOrderItems,
      showCustId: true,
    },
    {
      title: "Inquiry Cart",
      to: "cart/inquiry",
      count: cartSummary?.totalQuoteItems,
      showCustId: true,
    },
    {
      title: "Back to Stock Selection",
      to: "/portal/stock/stocklist",
      showCustId: true,
    },
  ];

  // Partlist route below is only available if customer.showParts is true
  // If customer.showParts is false, then the user is redirected to the portal home page

  return (
    <>
      <SubNavTabs
        submenu={
          location.pathname.startsWith("/portal/stock/cart")
            ? cartMenu
            : submenu
        }
      />
      <Routes>
        <Route
          path=""
          element={
            <Navigate to={{ pathname: "stocklist", search: location.search }} />
          }
        />
        <Route path="stocklist" element={<StockList />} />
        <Route element={<AuthorityRoutes authority={["viewPrimeStock"]} />}>
          <Route path="allstocklist" element={<AllStockList />} />
        </Route>
        <Route
          path="partlist"
          element={showParts ? <PartList /> : <Navigate to="/portal" />}
        />
        <Route element={<AuthorityRoutes authority={["internal"]} />}>
        <Route
          path="robinhood"
          element={<RobinHood />}
        />
        </Route>
        <Route element={<AuthorityRoutes authority={["viewNonPrimeStock"]} />}>
          <Route path="secondarystocklist" element={<SecondaryList />} />
          <Route path="shortsstocklist" element={<ShortsList />} />
        </Route>
        <Route path="cart/*" element={<Cart />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default Stock;
