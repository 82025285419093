/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import tw from "twin.macro";
import { RobinHoodResults } from "../../../../types/types";
import { numberFormatter } from "../../../../helpers/numberFormat";
import { Tooltip } from 'react-tooltip'
import { formatDateToShort } from "../../../../helpers/dateHelpers";

type Props = {
  children?: ReactNode;
  results?: RobinHoodResults | null;
};

const VmiResultsList: React.FC<Props> = ({ results }) => {
  return (
    <div css={tw`mt-4 text-xs`}>
      {
        <>
          <table className="ntp-portal-table ntp-shrink-y-padding" css={tw`w-full`}>
            <thead>
              <tr>
                <th css={tw`w-[4%]`}>Item</th>
                <th css={tw`w-[15%]`}>Product</th>
                <th css={tw`w-[4%]`}>Site</th>
                <th css={tw`w-[4%]`}>Length</th>
                <th css={tw`w-[4%]`}>Customer</th>
                <th css={tw`w-[5%]`}>Ship To</th>
                <th css={tw`w-[10%]`}>Sales Order</th>
                <th css={tw`w-[4%]`}>Due Date</th>
                <th css={tw`w-[4%]`}>Origin Code</th>
                <th css={tw`w-[4%]`}>Pieces<br/>Allocated</th>
                <th css={tw`w-[4%]`}>Weight<br/>Allocated</th>
                <th css={tw`w-[4%]`}>Pieces<br/>Ready</th>
                <th css={tw`w-[4%]`}>Weight<br/>Ready</th>
              </tr>
            </thead>
            <tbody>
              {results?.vmiOrderList.length && results.vmiOrderList.length > 0 ? (
                results?.vmiOrderList.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td css={tw`w-[4%]`}>{item?.itemNumber}</td>
                      <td css={tw`w-[15%]`}>{item?.lineDescription}</td>
                      <td css={tw`w-[4%]`}>{item?.site}</td>
                      <td css={tw`w-[4%] text-right`}>{item?.formattedLength}</td>
                      <td css={tw`w-[4%]`}>{item?.customerId}</td>
                      <td css={tw`w-[5%]`} className="relative">
                      <a
                          data-tooltip-id={`tip${index}`}
                          data-tooltip-content={item.deliveryAddressName}
                          data-tooltip-place="left"
                        >{item.shipToId}
                        </a>
                        <Tooltip id={`tip${index}`} 
                          style={{ backgroundColor: "rgb(255, 255, 255)", color: "#000", fontSize: "12px" }}
                        />
                      </td>
                      <td css={tw`w-[10%]`}>{item?.salesOrderNumber} - {item?.lineNumber}</td>
                      <td css={tw`w-[4%]`}>{formatDateToShort(item?.dueDate)}</td>
                      <td css={tw`w-[4%]`}>
                        {item?.salesOrderOriginCode}</td>
                      <td css={tw`w-[4%] text-right`}>
                      {item.totalLinePiecesAllocated && item.totalLinePiecesAllocated !== 0
                        ? numberFormatter(item.totalLinePiecesAllocated)
                        : null}
                      </td>
                      <td css={tw`w-[4%] text-right`}>
                        {item.totalLineWeightAllocated && item.totalLineWeightAllocated !== 0
                          ? numberFormatter(item.totalLineWeightAllocated)
                          : null}
                      </td>
                      <td css={tw`w-[4%] text-right`}>
                        {item.readyToLoadPieces && item.readyToLoadPieces !== 0
                          ? numberFormatter(item.readyToLoadPieces)
                          : null}
                      </td>
                      <td css={tw`w-[4%] text-right`}>
                        {item.readyToLoadWeight && item.readyToLoadWeight !== 0
                          ? numberFormatter(item.readyToLoadWeight)
                          : null}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td css={tw`text-nucor-gray !px-2 !py-3 hover:!bg-white`} colSpan={13}>
                    No results for the selected criteria.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </>
      }
    </div>
  );
};

export default VmiResultsList;
