/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import tw from "twin.macro";
import AccountChart from "./chart/AccountChart";
import AccountSummary from "./AccountSummary";
import { useAppSelector } from "../../../app/hooks";
import { selectCurrentAuthorities } from "../../../store/user.reducer";
import { hasAuthority } from "../../../helpers/hasAuthority";

type Props = {
  children?: ReactNode;
};

const Announcements: React.FC<Props> = () => {
  const currentContext = useAppSelector((state) => state.user.currentContext);
  const currentAuthorities = useAppSelector(selectCurrentAuthorities);

  if (!currentContext) return null;

  return (
    <div css={tw`flex justify-between`}>
      <AccountSummary />
      {hasAuthority(["viewOrderInvoice"], currentAuthorities) && (
        <AccountChart />
      )}
    </div>
  );
};

export default Announcements;
