/** @jsxImportSource @emotion/react */
import React, { ComponentProps, ReactNode } from "react";
import tw from "twin.macro";

type Props = {
  children?: ReactNode;
  maxLength?: number;
} & ComponentProps<"input">;

const NumericInput: React.FC<Props> = ({ maxLength, ...remainingProps }) => {
  return (
    <input
      type="number"
      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
        if (["Tab", "ArrowLeft", "ArrowRight", "Backspace"].includes(e.key))
          return;
        if (e.key === "Escape" || e.key === "Enter")
          return e.currentTarget.blur();
        if (maxLength && e.currentTarget.value.length === maxLength)
          return e.preventDefault();
        if (!/[0-9]+/.test(e.key)) return e.preventDefault();
      }}
      min={1}
      css={tw`w-[5ch] border border-gray-300 px-1`}
      {...remainingProps}
    />
  );
};

export default NumericInput;
