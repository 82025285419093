/** @jsxImportSource @emotion/react */
import React, { ReactNode, useContext } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import InvoicesResults from "./InvoicesResults";
import PDFEmbed from "../../../../components/molecules/PDFEmbed";
import { PDFContext } from "./InvoicesList";
import AnimatedLoadingSpinner from "../../../../components/atoms/AnimatedLoadingSpinner";
import TextButton from "../../../../components/atoms/TextButton";
import { ErrorResponse, RequestStatus } from "../../../../types/types";
import Toast from "../../../../components/molecules/Toast";
import { fetchInvoiceDocumentExcel } from "../../../../store/document.actions";

type Props = {
  children?: ReactNode;
};

const InvoicesContent: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const { invoiceResults, status } = useAppSelector((state) => state.document.invoices);

  const ctx = useContext(PDFContext);
  const [excelStatus, setExcelStatus] = React.useState<RequestStatus>("idle");
  const [error, setError] = React.useState<string | ErrorResponse>();

  const excelHandler = () => {
    if (!invoiceResults?.query) return;
    setExcelStatus("pending");
    dispatch(fetchInvoiceDocumentExcel(invoiceResults.query))
    .unwrap()
    .catch((error) => {
      setError(error);
    })
    .finally(() => setExcelStatus("idle"));
  };

  return (
    <div>
      {error && (
        <Toast
          message={error}
          type="error"
          onConfirm={() => setError(undefined)}
        />
      )}
      
      <div css={tw`text-white font-bold bg-nucor-green text-xs flex justify-between py-1 px-2 mb-2`}>
        <span className="uppercase w-20">Invoices</span>
          {ctx?.file && (
            <TextButton onClick={() => ctx?.setFile(null)} css={tw`text-white`}>
              Back to List
            </TextButton>
          )}
        
        <div css={tw`flex w-20 justify-end`}>
          {excelStatus === "pending" && (
            <AnimatedLoadingSpinner colorStyle="light" message="" className="mr-2"/>
          )}
          {(!ctx?.file && invoiceResults?.query && invoiceResults.count > 0) && (
            <TextButton disabled={status === "pending" || excelStatus === "pending"} onClick={excelHandler} css={(status !== "pending" && excelStatus !== "pending") && tw`text-white`}>
              Excel
          </TextButton>
          )}
        </div>
      </div>
      <div css={[ctx?.file && tw`hidden`]}>
        <InvoicesResults />
      </div>
      {ctx?.file && (
        <PDFEmbed
          setFile={ctx?.setFile}
          file={ctx?.file}
          onCancel={() => ctx?.setFile(null)}
        />
      )}
    </div>
  );
};

export default InvoicesContent;
