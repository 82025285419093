/** @jsxImportSource @emotion/react */
import React, { ReactNode, useState } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Button from "../../../../components/atoms/Button";
import DatePicker from "../../../../components/atoms/DatePicker";
import TextArea from "../../../../components/atoms/TextArea";
import Modal from "../../../../components/molecules/Modal";
import { dateToString, stringToDate } from "../../../../helpers/dateHelpers";
import { numberFormatter } from "../../../../helpers/numberFormat";
import {
  addSecondaryToCart,
  updateSecondaryStockCartItem,
} from "../../../../store/shoppingCart.actions";
import {
  selectCurrentCustomerId,
  selectCurrentShipTo,
} from "../../../../store/customer.reducer";
import {
  DateString,
  ErrorResponse,
  InventoryBundleOrderableItem,
  SalesOrderItem,
} from "../../../../types/types";
import ErrorDisplay from "../../../../components/molecules/ErrorDisplay";
import { stringToBase64 } from "../../../../helpers/stringToBase64";

type Props = {
  children?: ReactNode;
  onClose: (reason: "add" | "edit" | "cancel", result?: SalesOrderItem) => void;
  bundle?: InventoryBundleOrderableItem;
  minDate?: DateString;
  maxDate?: DateString;
};

const SecondaryItemDialog: React.FC<Props> = ({
  onClose,
  bundle,
  minDate,
  maxDate,
}) => {
  const dispatch = useAppDispatch();
  const customerId = useAppSelector(selectCurrentCustomerId);
  const shipToId = useAppSelector(selectCurrentShipTo);
  const salesOrderItem = bundle && bundle.salesOrderItem;
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(
    () => salesOrderItem && stringToDate(salesOrderItem.requestedShippingDate)
  );
  const [error, setError] = useState<ErrorResponse | string>();
  const [instructions, setInstructions] = useState(
    () => (salesOrderItem && salesOrderItem.notes) ?? ""
  );
  const status = useAppSelector((state) => state.shoppingCart.status);

  const saveSecondary = () => {
    if (!selectedDate || !bundle || !customerId || !shipToId) return;
    if (salesOrderItem) {
      dispatch(
        updateSecondaryStockCartItem({
          customerId,
          salesOrderItemId: salesOrderItem.id,
          body: {
            shipToId: shipToId,
            tagNumber: bundle.bundle.tagNumber,
            itemNumber: bundle.bundle.itemNumber,
            encodedNotes: stringToBase64(instructions),
            dueDate: dateToString(selectedDate) as DateString,
            inventoryStatus: bundle.bundle.inventoryStatus,
          },
        })
      )
        .unwrap()
        .then(() => onClose("edit"))
        .catch((error) => setError(error));
    } else {
      dispatch(
        addSecondaryToCart({
          customerId,
          body: {
            shipToId: shipToId,
            tagNumber: bundle.bundle.tagNumber,
            itemNumber: bundle.bundle.itemNumber,
            encodedNotes: stringToBase64(instructions),
            dueDate: dateToString(selectedDate) as DateString,
            inventoryStatus: bundle.bundle.inventoryStatus,
          },
        })
      )
        .unwrap()
        .then(() => onClose("add"))
        .catch((error) => setError(error));
    }
  };

  return (
    <Modal onCancel={() => onClose("cancel")} title="Stock - Secondary">
      <div css={tw`p-1`}>
        <ErrorDisplay error={error} />
        <div css={tw`grid grid-cols-6 gap-2 w-[500px] mt-2`}>
          <span css={tw`font-bold text-nucor-gray`}>Size:</span>
          <span css={tw`col-span-5`}> {bundle?.bundle.productName}</span>
          <span css={tw`font-bold text-nucor-gray`}>Length:</span>
          <span>{bundle?.bundle.lengthDescription}</span>
          <span css={tw`font-bold text-nucor-gray`}>Grade:</span>
          <span>{bundle?.bundle.grade}</span>
          <span css={tw`font-bold text-nucor-gray`}>Mill:</span>
          <span>{bundle?.bundle.site}</span>
          <span css={tw`font-bold text-nucor-gray`}>Weight</span>
          <span>{numberFormatter(bundle?.bundle.weight)}</span>
          <span css={tw`font-bold text-nucor-gray`}>Pieces:</span>
          <span>{bundle?.bundle.bundleSize}</span>
          <div css={tw`col-span-2`} />
          <span css={tw`font-bold text-nucor-gray`}>Remarks:</span>
          <p css={tw`col-span-5`}>{bundle?.bundle.tagRemarks}</p>
          <span css={tw`font-bold text-nucor-gray`}>Due Date:</span>
          <div css={tw`col-span-2`}>
            <DatePicker
              highlight
              name="duedate"
              value={selectedDate}
              hideWeekends
              minDate={stringToDate(minDate)}
              maxDate={stringToDate(maxDate)}
              onChange={(value) => setSelectedDate(value)}
            />
          </div>
          <div css={tw`col-span-3`} />
          <span css={tw`font-bold text-nucor-gray col-span-6`}>
            Item Instructions:
          </span>
          <TextArea
            css={tw`col-span-6`}
            value={instructions}
            onChange={(e) => setInstructions(e.target.value)}
          />
          <div css={tw`col-span-6 text-right`}>
            <Button
              onClick={saveSecondary}
              disabled={!selectedDate || status === "pending"}
              css={tw`mx-4`}
            >
              Save
            </Button>
            <Button onClick={() => onClose("cancel")}>Cancel</Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SecondaryItemDialog;
