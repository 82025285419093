/** @jsxImportSource @emotion/react */
import React from "react";
import tw from "twin.macro";
import { Customer_Customer } from "../../types/types";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
    currentCustomer: Customer_Customer | undefined;
};

const CustomerDisplay: React.FC<Props> = ({ currentCustomer }: Props) => {

  return (
    currentCustomer ? (
        <>
          <div className="peer" css={tw`truncate`}>
            {currentCustomer.classification ?
              <>{`${currentCustomer.id} (${currentCustomer.classification}) - ${currentCustomer.name}`}</>
              :
              <>{`${currentCustomer.id} - ${currentCustomer.name}`}</>
            }
            <br/>
            {`${currentCustomer.address.city ?? ""}${
              currentCustomer.address.city ? "," : ""
            } ${currentCustomer.address.stateCode ?? ""} ${
              currentCustomer.phoneNumber ? "|" : ""
            } ${currentCustomer.phoneNumber ?? ""}`}
          </div>
          <div css={tw`flex flex-col`}>
          <FontAwesomeIcon css={[tw`text-white`]} icon={faCaretUp} />
          <FontAwesomeIcon css={[tw`text-white`]} icon={faCaretDown} />
          </div>

          <span css={tw`absolute bg-white px-2 py-1 rounded border shadow-lg right-3 -top-8 text-nucor-gray whitespace-nowrap animate-tooltipdelay hidden peer-hover:block`}>
            {`${currentCustomer.id} - ${currentCustomer.name}`}
          </span>
        </>
      ) : (
        <>
          <div>Select Customer</div>
          <div css={tw`flex flex-col`}>
            <FontAwesomeIcon css={[tw`text-white`]} icon={faCaretUp} />
            <FontAwesomeIcon css={[tw`text-white`]} icon={faCaretDown} />
          </div>
        </>
      )
  );
};

export default CustomerDisplay;