/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import { RobinHoodResults, RobinHoodViewKeys } from "../../../../types/types";
import D365ResultsList from "./D365ResultsList";
import PortalResultsList from "./PortalResultsList";
import ReservationResultsList from "./ReservationResultsList";
import VmiResultsList from "./VmiResultsList";

type Props = {
  children?: ReactNode;
  results?: RobinHoodResults | null;
  currentView: RobinHoodViewKeys;
};

const viewComponents = {
  inPortal: PortalResultsList,
  orderReservations: ReservationResultsList,
  vmiOrderReservations: VmiResultsList,
  inD365: D365ResultsList,
};

const RobinHoodListResults: React.FC<Props> = ({ results, currentView }) => {
  const ViewComponent = viewComponents[currentView] || D365ResultsList;
  return <ViewComponent results={results}/>;
};

export default RobinHoodListResults;
