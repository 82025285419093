/** @jsxImportSource @emotion/react */
import React, { ReactNode, useEffect } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
  clearPrimeError,
  selectCurrentProductKey,
  selectProductSelectionGrades,
  setProductSelectionGrade,
} from "../../../../../store/product.reducer";
import Select from "../../../../../components/atoms/Select";
import ProductShapeComponent from "./ProductShapeComponent";
import SelectStockDetails from "./SelectStockDetails";
import {
  getRollingData,
  searchInventory,
} from "../../../../../store/product.actions";
import {
  selectCurrentCustomerId,
  selectCurrentShipTo,
} from "../../../../../store/customer.reducer";
import Toast from "../../../../../components/molecules/Toast";

type Props = {
  children?: ReactNode;
};

const BrowseTab: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const grades = useAppSelector(selectProductSelectionGrades);
  const { grade, shape } = useAppSelector(
    (state) => state.product.productSelectionDetails
  );
  const error = useAppSelector((state) => state.product.prime.primeError);

  const customerId = useAppSelector(selectCurrentCustomerId);
  const currentShipTo = useAppSelector(selectCurrentShipTo);
  const currentProductKey = useAppSelector(selectCurrentProductKey);
  const { loading } = useAppSelector((state) => state.rollings);
  const { inventorySearchStatus } = useAppSelector((state) => state.product.prime);

  useEffect(() => {
    if (!currentProductKey) return;
    dispatch(
      searchInventory({
        customerId: customerId,
        compoundProductKey: currentProductKey.key,
        shipToId: currentShipTo,
      })
    );
    dispatch(
      getRollingData({
        customerId,
        compoundProductKey: currentProductKey.key,
        shipToId: currentShipTo,
      })
    );
  }, [currentProductKey, customerId, currentShipTo]);

  return (
    <>
      {error && (
        <Toast
          onConfirm={() => {
            dispatch(clearPrimeError());
          }}
          message={error}
          type="error"
        />
      )}
      <div css={[tw`bg-nucor-component-background py-2 px-1`]}>
        <fieldset>
          <label css={tw`block`}>Grade Family</label>
          <Select
            disabled={loading == 'pending' || inventorySearchStatus == 'pending'}
            onChange={(value: string) =>
              dispatch(setProductSelectionGrade(value))
            }
            value={grade}
            minWidth="18ch"
            name="grades"
            data={grades?.map((item) => ({ value: item, label: item }))}
          />
        </fieldset>
      </div>
      <div css={[tw`bg-nucor-component-background px-1`]}>
        <ProductShapeComponent />
      </div>
      <div css={[tw`bg-nucor-component-background px-1 pb-4`]}>
        {shape !== "Unknown" && <SelectStockDetails />}
      </div>
    </>
  );
};

export default BrowseTab;
