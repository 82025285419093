/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import BrowseSearch from "../../mydocuments/BrowseSearch";
import OpenLoadsBrowse from "./OpenLoadsBrowse";
import OpenLoadsSearch from "./OpenLoadsSearch";

type Props = {
  children?: ReactNode;
};

const OpenLoadsLeft: React.FC<Props> = () => {
  return (
    <BrowseSearch
      browse={<OpenLoadsBrowse />}
      search={<OpenLoadsSearch />}
    />
  );
};

export default OpenLoadsLeft;
