/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import BrowseSearch from "../BrowseSearch";
import CertsBrowse from "./CertsBrowse";
import CertsSearch from "./CertsSearch";

type Props = {
  children?: ReactNode;
};

const CertsLeft: React.FC<Props> = () => {

  return (
    <BrowseSearch
      browse={<CertsBrowse />}
      search={<CertsSearch />}
    />
  );
};

export default CertsLeft;
