/** @jsxImportSource @emotion/react */
import React, { ReactNode, useEffect, useState } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
  clearCurrentQuoteCart,
  selectCurrentQuoteCart,
  updateAllDueDates,
} from "../../../../../store/quoteCart.reducer";
import {
  clearQuoteCart,
  fetchQuoteCart,
  submitQuoteCart,
  updateQuoteCartItems,
} from "../../../../../store/quoteCart.actions";
import { selectCurrentCustomerId } from "../../../../../store/customer.reducer";
import TextButton from "../../../../../components/atoms/TextButton";
import Loading from "../../../../../components/atoms/Loading";
import Button from "../../../../../components/atoms/Button";
import SoldTo from "../components/SoldTo";
import ShipTo from "../components/ShipTo";
import OrderDetails from "./components/OrderDetails";
import OrderSite from "./components/OrderSite";
import ConfirmationDialog from "../../../../../components/molecules/ConfirmationDialog";
import OpenInquiryCartsDialog from "./components/OpenInquiryCartsDialog";
import ChangeDueDates from "../components/ChangeDueDates";
import {
  CartHeader,
  DateString,
  ErrorResponse,
  QuoteItemInput,
} from "../../../../../types/types";
import ChangeShipToDialog from "../components/ChangeShipToDialog";
import { changeShipTo } from "../../../../../store/shoppingCart.actions";
import InquiryCartConfirmation from "./components/InquiryCartConfirmation";
import Toast from "../../../../../components/molecules/Toast";
import Disclaimer from "./components/Disclaimer";
import { isErrorResponse } from "../../../../../types/predicates";

type Props = {
  children?: ReactNode;
};

const InquiryCart: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const customerId = useAppSelector(selectCurrentCustomerId);
  const currentQuoteCart = useAppSelector(selectCurrentQuoteCart);

  const [showChangeDueDate, setShowChangeDueDate] = useState(false);
  const [showOpenCarts, setShowOpenCarts] = useState(false);
  const [showClearCartDialog, setShowClearCartDialog] = useState(false);
  const [showChangeShipTo, setShowChangeShipTo] = useState(false);

  const [status, setStatus] = useState<
  | "idle"
  | "loading"
  | "saving"
  | "submitting"
  >("idle");

  const [submittedCart, setSubmittedCart] = useState<CartHeader>();
  const [cartErrors, setCartErrors] = useState<string | ErrorResponse>();

  useEffect(() => {
    if (!customerId) return;
    setStatus("loading");
    dispatch(fetchQuoteCart(customerId))
      .unwrap()
      .finally(() => setStatus("idle"))
  }, [customerId]);

  useEffect(() => {
    setSubmittedCart(undefined);
  }, [customerId]);

  const updateQuoteCartItemsHandler = async () => {
    if (!customerId) return;
    try {
      setStatus("saving");
      setCartErrors(undefined);
      await dispatch(updateQuoteCartItems(customerId)).unwrap();
    } catch (error) {
      if (isErrorResponse(error)) {
        setCartErrors(error);
      } else {
        setCartErrors("An error occurred while updating the inquiry cart.");
      }
    } finally {
      setStatus("idle");  
    }
  };

  const submitQuoteCartHandler = () => {
    if (!customerId) return;
    const itemsArr = currentQuoteCart?.sites.reduce((acc, site) => {
      const items = site.items.map((item) => {
        return {
          id: item.id,
          poLineNumber: item.poLineNumber,
          customerPartNumber: item.customerPartNumber,
          newCustomerPartNumber: item.newCustomerPartNumber,
          requestedShippingDate: item.requestedShippingDate,
        } as QuoteItemInput;
      });
      return [...acc, ...items];
    }, [] as QuoteItemInput[]);
    setStatus("submitting");
    setCartErrors(undefined);
    dispatch(submitQuoteCart({ customerId, body: itemsArr ?? [] }))
      .unwrap()
      .then((result) => {
        if (result.quoteSubmitted) {
          setSubmittedCart(result);
          dispatch(clearCurrentQuoteCart());
        }
      })
      .catch((error) => setCartErrors(error))
      .finally(() => setStatus("idle"));
  };

  const datesFulfilled = currentQuoteCart?.sites?.every((site) =>
    site.items.every((item) => item.requestedShippingDate)
  );
  const cartIsValid = datesFulfilled;

  if (submittedCart) {
    return <InquiryCartConfirmation cart={submittedCart} />;
  }

  if (!currentQuoteCart || currentQuoteCart?.empty) {
    return (
      <div css={tw`w-full text-left p-4 relative min-h-[300px]`}>
        {status !== "idle" ? (
          <Loading><span className="capitalize">{status}</span></Loading>
        ) : (
          <>
            <h2 css={tw`text-red-600 text-lg font-bold flex-1`}>
              Inquiry Cart Details
            </h2>
            <p css={tw`text-xs p-4`}>There are no items in the inquiry cart.</p>
          </>
        )}
      </div>
    );
  }

  return (
    <div css={tw`relative mt-2 self-start text-xs w-full px-2`}>
      {status !== "idle" ? <Loading><span className="capitalize">{status}</span></Loading> : null}
      {cartErrors && (
        <Toast
          type="error"
          onConfirm={() => setCartErrors(undefined)}
          message={cartErrors}
        />
      )}
      {showChangeDueDate && (
        <ChangeDueDates
          onConfirm={(newDueDate: DateString) => {
            dispatch(updateAllDueDates(newDueDate));
            setShowChangeDueDate(false);
          }}
          onCancel={() => setShowChangeDueDate(false)}
        />
      )}
      {showChangeShipTo && (
        <ChangeShipToDialog
          onConfirm={(id: string) => {
            if (!customerId) return;
            setCartErrors(undefined);
            dispatch(
              changeShipTo({
                customerId: customerId,
                body: { shipToId: id },
              })
            )
              .unwrap()
              .then(() => {
                dispatch(fetchQuoteCart(customerId));
              })
              .catch((error) => {
                setCartErrors(error);
              })
              .finally(() => {
                setShowChangeShipTo(false);
              });
          }}
          onCancel={() => setShowChangeShipTo(false)}
        />
      )}
      {showOpenCarts && (
        <OpenInquiryCartsDialog onCancel={() => setShowOpenCarts(false)} />
      )}
      {showClearCartDialog && (
        <ConfirmationDialog
          title="Clear cart?"
          onCancel={() => setShowClearCartDialog(false)}
          onConfirm={() => {
            if (customerId) {
              dispatch(clearQuoteCart(customerId));
            }
            setShowClearCartDialog(false);
          }}
        >
          Are you sure you want to clear your inquiry cart?
        </ConfirmationDialog>
      )}
      <div css={tw`flex items-center`}>
        <h2 css={tw`text-red-600 text-lg font-bold flex-1`}>
          Inquiry Cart Details
        </h2>
        <div css={tw`flex gap-2 mr-2`}>
          <TextButton
            type="button"
            disabled={status !== "idle"}
            onClick={() => setShowClearCartDialog(true)}
          >
            Clear Inquiry Cart
          </TextButton>
          <TextButton
            disabled={status !== "idle"}
            type="button"
            onClick={() => setShowOpenCarts(true)}
          >
            View Open Inquiry Carts
          </TextButton>
        </div>
      </div>
      <form css={tw`relative mt-2`}>
        {(currentQuoteCart?.errors.length > 0 ||
          currentQuoteCart?.warnings.length > 0) && (
          <>
            <p>
              Please resolve the following before submitting the shopping cart:
            </p>
            <ul css={tw`list-disc ml-6 mt-2 mb-3 list-outside`}>
              {currentQuoteCart?.errors?.map((error, index) => (
                <pre css={tw`whitespace-pre-wrap`} key={index}>
                  <li css={tw`text-red-600`}>{error}</li>
                </pre>
              ))}
              {currentQuoteCart?.warnings?.map((warning, index) => (
                <pre css={tw`whitespace-pre-wrap`} key={index}>
                  <li css={tw`text-nucor-green`}>{warning}</li>
                </pre>
              ))}
            </ul>
          </>
        )}
        <div
          css={tw`bg-gradient-to-b from-[#e6e6e6] via-[#fdfdfd] to-[#e6e6e6] px-2 py-1 flex gap-2 my-1 items-center`}
        >
          <Button
            type="button"
            disabled={status !== "idle"}
            onClick={updateQuoteCartItemsHandler}
            css={tw`m-0 text-xs font-normal px-2 py-[3px]`}
          >
            Save Inquiry
          </Button>
          <Button
            type="button"
            css={tw`m-0 text-xs font-normal px-2 py-[3px]`}
            disabled={!cartIsValid || status !== "idle"}
            onClick={submitQuoteCartHandler}
          >
            Send Inquiry
          </Button>
          <div css={tw`flex-1 text-right flex gap-2 justify-end`}>
            <TextButton
              disabled={status !== "idle"}
              type="button"
              onClick={() => setShowChangeShipTo(true)}
            >
              Change Ship To
            </TextButton>
            <TextButton
              disabled={status !== "idle"}
              type="button"
              onClick={() => setShowChangeDueDate(true)}
            >
              Change All Due Dates
            </TextButton>
          </div>
        </div>
        <p css={tw`italic text-nucor-gray my-1`}>
          <span css={tw`mx-2 text-red-600`}>*</span>indicates required fields
        </p>
        <div css={tw`bg-[#e6e6e6] flex justify-evenly items-start p-1 mt-2`}>
          <div css={tw`w-full`}>
            <SoldTo customer={currentQuoteCart?.billingCustomer} />
          </div>
          <div css={tw`w-full`}>
            <ShipTo customer={currentQuoteCart?.shipTo} />
          </div>
          <div css={tw`w-full`}>
            <OrderDetails cart={currentQuoteCart} />
          </div>
        </div>
        <div>
          {currentQuoteCart?.sites.map((site, index) =>
            site.items.length > 0 ? (
              <OrderSite
                saveCart={updateQuoteCartItemsHandler}
                site={site}
                key={index}
              />
            ) : null
          )}
        </div>
      </form>
      <Disclaimer />
    </div>
  );
};

export default InquiryCart;
