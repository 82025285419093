/** @jsxImportSource @emotion/react */
import { yupResolver } from "@hookform/resolvers/yup";
import React, { ReactNode, useEffect, useState } from "react";
import Button from "../../../../components/atoms/Button";
import { useForm } from "react-hook-form";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import AccountStatus from "../components/AccountStatus";
import PersonalInfo from "./components/PersonalInfo";
import { PersonalInfoForm, schema, setDefaults } from "./personalInfoForm";
import AssignCustomers from "./components/AssignCustomers";
import { updateUser } from "../../../../store/admin.actions";
import Roles from "../components/Roles";
import { clearCurrentUser } from "../../../../store/admin.reducer";
import { ErrorResponse } from "../../../../types/types";
import ErrorDisplay from "../../../../components/molecules/ErrorDisplay";
import Loading from "../../../../components/atoms/Loading";
import { selectCurrentUserAuthorities } from "../../../../store/user.reducer";
import FieldsetSection from "../components/FieldsetSection";
import Authorities from "../components/Authorities";

type Props = {
  children?: ReactNode;
};

const UserDetail: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const authorities = useAppSelector(selectCurrentUserAuthorities);
  const { userFormSetup } = useAppSelector((state) => state.admin);
  const selectedUser = useAppSelector((state) => state.admin.selectedUser);
  const selectedUserIsItAdmin = selectedUser?.itAdmin ?? false;
  const { loading } = useAppSelector((state) => state.admin);
  const {
    register,
    setValue,
    control,
    handleSubmit,
    watch,
    trigger,
    formState: { errors, isValid, isDirty },
  } = useForm<PersonalInfoForm>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: setDefaults(selectedUser),
  });

  useEffect(() => {
    trigger();
  }, []);

  const isExternal = watch("selectedRole") === "external";
  const canSelectItAdmin = authorities?.includes("itAdmin") ?? false;

  const [error, setError] = useState<string | ErrorResponse>();

  const submitHandler = handleSubmit((data) => {
    if (data.selectedRole !== "external") {
      delete data.viewNonPrimeStock;
      delete data.viewOrderInvoice;
      delete data.viewPriceSheets;
      delete data.viewPrimeStock;
      delete data.viewRollings;
      delete data.viewShippingDocs;
      delete data.createOrderQuoteLoad;
    }
    const dataAfterRoleUpdate = {
      ...data,
      external: data.selectedRole === "external",
      internal: data.selectedRole === "internal",
      admin: data.selectedRole === "admin",
      itAdmin: data.selectedRole === "itAdmin",
    };
    delete dataAfterRoleUpdate.selectedRole;
    setError("");
    const userId = selectedUser?.userId;
    const assignedCustomerIds = selectedUser?.assignedCustomerIds;
    if (userId && assignedCustomerIds) {
      setError(undefined);
      dispatch(
        updateUser({ ...dataAfterRoleUpdate, userId, assignedCustomerIds })
      )
        .unwrap()
        .then(() => {
          dispatch(clearCurrentUser());
        })
        .catch((error) => {
          setError(error);
        });
    }
  });

  useEffect(() => {
    setValue("assignedCustomerIds", selectedUser?.assignedCustomerIds, {
      shouldDirty: true,
    });
  }, [selectedUser?.assignedCustomerIds]);

  return (
    <>
      <div css={[tw`bg-[#eee] text-xs`]}>
        {error && <ErrorDisplay error={error} />}
        <div css={tw`h-6 border-b border-nucor-gray`} />
        <form css={tw`p-2 relative`}>
          {loading && <Loading>Saving</Loading>}
          <p css={tw`italic text-xs`}>
            <span css={tw`text-red-600`}>*</span> indicates required fields
          </p>
          <div css={[tw`flex justify-between`]}>
            <div css={[tw`w-[40%]`]}>
              <FieldsetSection title="Personal Information">
                <PersonalInfo
                  selectedUser={selectedUser}
                  errors={errors}
                  register={register}
                />
              </FieldsetSection>
            </div>
            <div css={[tw`w-[42%]`]}>
              <FieldsetSection title="User Account Status">
                <AccountStatus
                  control={control}
                  errors={errors}
                  register={register}
                />
              </FieldsetSection>
              <FieldsetSection title="Roles on Portal" className="mt-4">
                <Roles
                  disabled={selectedUserIsItAdmin && !canSelectItAdmin}
                  disableItAdmin={!canSelectItAdmin}
                  register={register}
                />
              </FieldsetSection>
              {isExternal && (
                <FieldsetSection css={tw`mt-4`} title="External Authorities">
                  <Authorities
                    register={register}
                    externalAuthorities={userFormSetup?.externalAuthorities}
                  />
                </FieldsetSection>
              )}
            </div>
            <div css={[tw``]}>
              <div css={tw`flex flex-col gap-2`}>
                <Button
                  disabled={!isDirty || !isValid || loading}
                  css={[
                    tw`text-xs py-1 font-medium m-0 mr-2 min-w-[12ch]`,
                    isDirty && isValid && tw`bg-red-700`,
                    loading && tw`bg-gray-300`,
                  ]}
                  type="button"
                  onClick={submitHandler}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
          {isExternal && (
            <FieldsetSection className="mt-4" title="Assign Customers">
              <AssignCustomers register={register} />
            </FieldsetSection>
          )}
        </form>
      </div>
    </>
  );
};

export default UserDetail;
