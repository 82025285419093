/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import React, { useEffect, ReactNode } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "../../components/atoms/Button";
import Link from "../../components/atoms/Link";
import { useNavigate } from "react-router-dom";

type Props = {
  children?: ReactNode;
};
const Login: React.FC<Props> = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/callback");
    } 
  }, [isAuthenticated]);

  return (
    <section css={tw`flex-grow mx-4`}>
      <div
        css={[
          tw`min-w-[40ch] p-6 shadow-lg mt-36 flex flex-col text-sm`,
          { border: "1px solid #ddd" },
        ]}
      >
        <p css={tw`text-nucor-light-green`}>
          <FontAwesomeIcon css={tw`mr-2`} icon={faLock} />
          Login
        </p>
        <Button
          css={tw`my-6 font-semibold py-1 text-base rounded`}
          onClick={loginWithRedirect}
        >
          Login
        </Button>
        <div css={tw`text-right`}>
          <Link
            css={tw` text-nucor-light-green bg-transparent border-0`}
            to={"/requestaccount"}
          >
            Request New Account
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Login;
