import { ErrorResponse } from "./types";

export const isErrorResponse = (
  error: string | ErrorResponse | unknown | undefined
): error is ErrorResponse => {
  if (!error) return false;
  if (typeof error === "string") return false;
  return Object.hasOwn(error, "message") ? true : false;
};

export const isBlobError = (error: unknown): error is Blob => {
  return error instanceof Blob && error.type === "application/json";
};
