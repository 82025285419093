/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import { UseFormRegister } from "react-hook-form";
import tw from "twin.macro";
import { PersonalInfoForm } from "../edit_user/personalInfoForm";

type Props = {
  children?: ReactNode;
  register: UseFormRegister<PersonalInfoForm>;
  disabled?: boolean;
  disableItAdmin?: boolean;
};

const Roles: React.FC<Props> = ({ register, disableItAdmin, disabled }) => {
  return (
    <>
      <div css={tw`grid grid-cols-4`}>
        <div css={tw`flex gap-2`}>
          <input
            disabled={disabled}
            id="external"
            className="peer hover:cursor-pointer disabled:cursor-default"
            {...register("selectedRole")}
            type="radio"
            value="external"
          />
          <label
            className="peer-disabled:opacity-50 hover:cursor-pointer peer-disabled:hover:cursor-default"
            htmlFor="external"
          >
            External
          </label>
        </div>
        <div css={tw`flex gap-2`}>
          <input
            disabled={disabled}
            id="internal"
            className="peer hover:cursor-pointer disabled:cursor-default"
            {...register("selectedRole")}
            type="radio"
            value="internal"
          />
          <label
            className="peer-disabled:opacity-50 hover:cursor-pointer peer-disabled:hover:cursor-default"
            htmlFor="internal"
          >
            Internal
          </label>
        </div>
        <div css={tw`flex gap-2`}>
          <input
            disabled={disabled}
            id="admin"
            className="peer hover:cursor-pointer disabled:cursor-default"
            {...register("selectedRole")}
            type="radio"
            value="admin"
          />
          <label
            className="peer-disabled:opacity-50 hover:cursor-pointer peer-disabled:hover:cursor-default"
            htmlFor="admin"
          >
            Admin
          </label>
        </div>
        <div css={tw`flex gap-2`}>
          <input
            disabled={disabled || disableItAdmin}
            id="itAdmin"
            className="peer hover:cursor-pointer disabled:cursor-default"
            {...register("selectedRole")}
            type="radio"
            value="itAdmin"
          />
          <label
            className="peer-disabled:opacity-50 hover:cursor-pointer peer-disabled:hover:cursor-default"
            htmlFor="itAdmin"
          >
            IT Admin
          </label>
        </div>
      </div>
    </>
  );
};

export default Roles;
