/** @jsxImportSource @emotion/react */
import { useAuth0 } from "@auth0/auth0-react";
import React, { ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { fetchUser } from "../../store/user.actions";
import FetchingUserDialog from "../molecules/FetchingUserDialog";
import { formatDateToAnalyticsFormat, sendLoginAnalytics } from "../../store/analytics.actions";
import { userType } from "../../helpers/userType";

type Props = {
  children?: ReactNode;
};

const Callback: React.FC<Props> = () => {
  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const redirected = params.get("redirected");
  const customerIdSearchParam = redirected ? new URL(window.location.origin + redirected).searchParams.get("customer_id") : null;

  const dispatch = useAppDispatch();
  const userContext = useAppSelector(
    (state) => state.user.currentContext?.user
  );
  const startPage = useAppSelector(
    (state) => state.user.currentContext?.userPreferences?.startPage
  );

  const navigateToStartPage = (startPage: string | undefined) => {
    switch (startPage) {
      case "STOCK":
        navigate("/portal/stock");
        break;
      case "MY_SHIPPING":
        navigate("/portal/myshipping");
        break;
      case "MY_DOCUMENTS":
        navigate("/portal/mydocuments");
        break;
      default:
        navigate("/portal/home");
        break;
    }
  };

  useEffect(() => {
    if (isAuthenticated && !userContext) {
      dispatch(fetchUser(customerIdSearchParam ?? undefined))
        .unwrap().
        then((context) => {
          dispatch(
            sendLoginAnalytics({
              Login: context.user?.email,
              LoginDate: formatDateToAnalyticsFormat(new Date()),
              Source: "TB",
              UserType: userType(context),
            })
          );
          if (redirected) {
            const rd = redirected.includes("shoppingreview") ? redirected.replace("shoppingreview", "shopping") : redirected;
            navigate(rd);
          } else {
            navigateToStartPage(context.userPreferences?.startPage);
          }
        })
        .catch((error) => console.log(error));
    } else {
      navigateToStartPage(startPage);
    }
  }, [isAuthenticated]);

  return <FetchingUserDialog />;
};

export default Callback;
