/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";

type Props = {
  children?: ReactNode;
  icon: "pencil" | "cart" | "shipto";
} & React.ComponentProps<"div">;

const Sprites: React.FC<Props> = ({ icon, ...remainingProps }) => {
  const selectedIcon = icons[icon];
  return (
    <div
      css={{
        background: `url(/images/sprites.gif) ${selectedIcon.x}px ${selectedIcon.y}px`,
        backgroundOrigin: "0 0",
        backgroundRepeat: "no-repeat",
        width: `${selectedIcon.width}px`,
        height: `${selectedIcon.height}px`,
      }}
      {...remainingProps}
    />
  );
};

export default Sprites;

const icons = {
  pencil: {
    height: 15,
    width: 15,
    x: 0,
    y: -238,
  },
  cart: {
    height: 12,
    width: 12,
    x: 0,
    y: -1231,
  },
  shipto: {
    height: 15,
    width: 16,
    x: 0,
    y: -1866,
  },
};
