/** @jsxImportSource @emotion/react */
import React, { ReactNode, useEffect, useState } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import Button from "../../../../../components/atoms/Button";
import {
  selectCurrentCustomerId,
  selectCurrentShipTo,
} from "../../../../../store/customer.reducer";
import {
  getCustomerParts,
  getActiveList,
  getRollingDataByPart,
  searchInventoryByPart,
  searchInventory,
  getRollingData,
} from "../../../../../store/product.actions";
import ComboBox from "../../../../../components/atoms/ComboBox";
import TextButton from "../../../../../components/atoms/TextButton";
import PartDescriptionModal from "../../../../../components/molecules/PartDescriptionModal";
import LengthComponent from "../../../../../components/molecules/LengthComponent";
import { lengthToInches } from "../../../../../helpers/lengthHelpers";

type Props = {
  children?: ReactNode;
};

const SearchTab: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const customerId = useAppSelector(selectCurrentCustomerId);
  const shipToId = useAppSelector(selectCurrentShipTo);
  const parts = useAppSelector((state) => state.product.customerParts);
  const items = useAppSelector((state) => state.product.activeItems);
  const [selectedItemValue, setSelectedItemValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [showPartModal, setShowPartModal] = useState(false);
  const [length, setLength] = useState({
    feet: 0,
    inches: 0,
    fraction: 0,
  });
  const lengthInInches = lengthToInches(length);

  const selectedPart = parts?.find(
    (item) => item.customerPartNumber === searchValue
  );
  const selectedItem = items?.find(
    (item) => item.itemNumber === selectedItemValue
  );

  const searchHandler = () => {
    if (customerId && (selectedItemValue || searchValue)) {
      if (selectedItemValue) {
        dispatch(
          searchInventory({
            customerId: customerId,
            itemNumber: selectedItemValue,
            length: lengthInInches,
            shipToId,
            fromPartSearch: true,
          })
        );
        dispatch(
          getRollingData({
            customerId,
            itemNumber: selectedItemValue,
            length: lengthInInches,
            shipToId,
          })
        );
      } else {
        dispatch(
          searchInventoryByPart({
            customerId: customerId ?? "",
            customerPartNumber: searchValue,
            shipToId,
          })
        );
        dispatch(
          getRollingDataByPart({
            customerId: customerId ?? "",
            customerPartNumber: searchValue,
            shipToId,
          })
        );
      }
    }
  };

  useEffect(() => {
    if (!customerId) return;
    setSearchValue("");
    setLength({ feet: 0, inches: 0, fraction: 0 });
    dispatch(getCustomerParts(customerId));
    dispatch(getActiveList(customerId));
  }, [customerId]);

  return (
    <div css={tw`bg-nucor-component-background py-2 px-1`}>
      {showPartModal && selectedPart && (
        <PartDescriptionModal
          onCancel={() => setShowPartModal(false)}
          details={selectedPart}
        />
      )}
      {customerId && parts?.length > 0 && (
        <>
          <p css={tw`text-xs`}>Customer Part #</p>
          <ComboBox
            onChange={(value) => {
              setSelectedItemValue("");
              setLength({ feet: 0, inches: 0, fraction: 0 });
              setSearchValue(value);
            }}
            value={searchValue}
            data={parts.map((item) => ({
              value: item.customerPartNumber,
              label: (
                <span>
                  {item.customerPartNumber}
                  <span css={tw`ml-4`}>{item.displayDescription}</span>
                </span>
              ),
            }))}
          />
        </>
      )}
      <p css={tw`text-xs mt-3`}>Item Number</p>
      <ComboBox
        stringIncludes
        onChange={(value) => {
          setSearchValue("");
          setSelectedItemValue(value);
        }}
        value={selectedItemValue || ""}
        data={items.map((item) => ({
          value: item.itemNumber,
          label: (
            <span>
              {item.itemNumber} ({item.searchName}) - {item.name}
            </span>
          ),
          name: item.name,
          searchName: item.searchName,
        }))}
      />
      <LengthComponent
        value={length}
        onLengthChange={(len) => setLength(len)}
        css={tw`mb-2 mt-3`}
        disabled={searchValue !== ""}
      />
      {selectedItem && <p css={tw`my-1`}>{selectedItem?.name}</p>}
      {selectedPart && <p css={tw`my-1`}>{selectedPart?.itemDescription}</p>}
      {selectedPart && (
        <TextButton
          onClick={() => {
            if (searchValue) {
              setShowPartModal(true);
            }
          }}
        >
          Part Detail
        </TextButton>
      )}
      <div css={tw`flex justify-end w-full mt-4`}>
        <Button
          onClick={searchHandler}
          disabled={searchValue === "" && selectedItemValue === ""}
          css={tw`block text-xs font-normal px-3 mr-1`}
        >
          Search
        </Button>
      </div>
    </div>
  );
};

export default SearchTab;
