import * as yup from "yup";

export type RollingFormData = {
  rollingId?: string;
  date?: Date;
  orderby: "ProductItemNumber" | "CustomerPartNumber";
  product: string;
  customerPartNumber: string;
  feet: number;
  inches: number;
  fraction: number;
  pieces: number;
};

export const schema = yup.object({
  rollingId: yup.string(),
  date: yup.date(),
  orderby: yup.string(),
  customerPartNumber: yup.string().when("orderby", {
    is: "CustomerPartNumber",
    then: yup.string().required(),
  }),
  product: yup.string().when("orderby", {
    is: "ProductItemNumber",
    then: yup.string().required(),
  }),
  feet: yup.number().required(),
  fraction: yup.number(),
  pieces: yup.number().required(),
});
