/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import tw from "twin.macro";
import SubNavTabs from "../../../components/organisms/SubNavTabs";
import Notifications from "./Notifications";
import PersonalInfo from "./PersonalInfo";
import Preferences from "./Preferences";
import NotFound from "../../app/NotFound";

type Props = {
  children?: ReactNode;
};

const submenu = [
  { title: "Personal Info", to: "personalinfo" },
  { title: "Preferences", to: "preferences" },
  { title: "Notifications", to: "notifications" },
];

const UserInfo: React.FC<Props> = () => {
  const location = useLocation();

  return (
    <>
      <SubNavTabs submenu={submenu} />
      <div css={tw`p-4 w-full`}>
        <Routes>
          <Route
            path=""
            element={
              <Navigate
                to={{ pathname: "personalinfo", search: location.search }}
              />
            }
          />
          <Route path="personalinfo" element={<PersonalInfo />} />
          <Route path="preferences" element={<Preferences />} />
          <Route path="notifications" element={<Notifications />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </>
  );
};

export default UserInfo;
