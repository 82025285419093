/** @jsxImportSource @emotion/react */
import React, { ComponentProps, ReactNode } from "react";
import tw from "twin.macro";
import { numberFormatter } from "../../../../helpers/numberFormat";
import { ReadyToReleaseSiteTotals } from "../../../../types/types";

type Props = {
  children?: ReactNode;
  siteTotals?: ReadyToReleaseSiteTotals[];
} & ComponentProps<"div">;

const Totals: React.FC<Props> = ({ siteTotals, ...remainingProps }) => {
  const totals = siteTotals?.reduce((acc, cur) => {
    return { ...acc, [cur.site]: cur };
  }, {} as Record<string, ReadyToReleaseSiteTotals>);

  return (
    <div css={tw`grid grid-cols-3`} {...remainingProps}>
      {totals && (
        <>
          <div css={tw`font-bold grid grid-cols-2 justify-evenly gap-1`}>
            <span css={tw`text-nucor-gray text-right`}>BHM</span>
            <span>
              {totals["NTP-BHM"] &&
                numberFormatter(totals["NTP-BHM"]?.poundsReadyToLoad)}
            </span>
          </div>
          <div css={tw`font-bold grid grid-cols-2 justify-evenly gap-1`}>
            <span css={tw`text-nucor-gray text-right`}>DCR</span>
            <span>
              {totals["NTP-DCR"] &&
                numberFormatter(totals["NTP-DCR"]?.poundsReadyToLoad)}
            </span>
          </div>
          <div css={tw`font-bold grid grid-cols-2 justify-evenly`}>
            <div css={tw`font-bold grid grid-cols-2 justify-evenly gap-1`}>
              <span css={tw`text-nucor-gray text-right`}>TRI</span>
              <span>
                {totals["NTP-TRI"] &&
                  numberFormatter(totals["NTP-TRI"]?.poundsReadyToLoad)}
              </span>
            </div>
          </div>
          <div css={tw`font-bold grid grid-cols-2 justify-evenly gap-1`}>
            <span css={tw`text-nucor-gray text-right`}>CHI</span>
            <span>
              {totals["NTP-CHI"] &&
                numberFormatter(totals["NTP-CHI"]?.poundsReadyToLoad)}
            </span>
          </div>
          <div css={tw`font-bold grid grid-cols-2 justify-evenly gap-1`}>
            <span css={tw`text-nucor-gray text-right`}>MAR</span>
            <span>
              {totals["NTP-MAR"] &&
                numberFormatter(totals["NTP-MAR"]?.poundsReadyToLoad)}
            </span>
          </div>
          <div css={tw`font-bold grid grid-cols-2 justify-evenly`}>
            <div css={tw`font-bold grid grid-cols-2 justify-evenly gap-1`}>
              <span css={tw`text-nucor-gray text-right`}>GHE</span>
              <span>
                {totals["NTP-GHE"] &&
                  numberFormatter(totals["NTP-GHE"]?.poundsReadyToLoad)}
              </span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Totals;
