/** @jsxImportSource @emotion/react */
import React, { ReactNode, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { fetchCustomerPartDetails } from "../../../../store/parts.actions";
import { selectCurrentCustomerId } from "../../../../store/customer.reducer";
import { CustomerPartDetail } from "../../../../types/types";
import tw from "twin.macro";
import { selectCurrentAuthorities } from "../../../../store/user.reducer";
import { hasAuthority } from "../../../../helpers/hasAuthority";
import TextButton from "../../../../components/atoms/TextButton";
import Loading from "../../../../components/atoms/Loading";

type Props = {
  children?: ReactNode;
  onSelect: (part: CustomerPartDetail) => void;
};

const PartListTable: React.FC<Props> = ({ onSelect }) => {
  const dispatch = useAppDispatch();
  const customerId = useAppSelector(selectCurrentCustomerId);
  const currentAuthorities = useAppSelector(selectCurrentAuthorities);
  const [results, setResults] = useState<CustomerPartDetail[]>([]);
  const [error, setError] = useState("");
  const canViewPrices = hasAuthority("viewPriceSheets", currentAuthorities);
  const { detailStatus } = useAppSelector((state) => state.parts);

  useEffect(() => {
    if (!customerId) return;
    setResults([]);
    dispatch(fetchCustomerPartDetails(customerId))
      .unwrap()
      .then((parts) => setResults(parts))
      .catch((error) => setError(error));
  }, [customerId]);

  if (error) {
    return <p css={tw`text-red-600`}>{error}</p>;
  }

  return (
    <div css={tw`relative`}>
      {detailStatus === "pending" && <Loading />}
      <table className="ntp-portal-table" css={tw`w-full mt-2`}>
        <thead>
          <tr>
            <th css={tw`w-2/12`}>Part #</th>
            <th css={tw`w-7/12`}>Description</th>
            <th css={tw`w-1/12`}>Length</th>
            <th css={tw`w-1/12`}>Bdl</th>
            {canViewPrices && <th css={tw`w-2/12`}>Price</th>}
          </tr>
        </thead>
        <tbody>
          {results && results.length > 0 ? (
            results.map((part) => (
              <tr key={part.customerPartNumber}>
                <td css={tw`w-2/12`}>
                  <TextButton
                    onClick={() => {
                      onSelect(part);
                    }}
                  >
                    {part.customerPartNumber}
                  </TextButton>
                </td>
                <td css={tw`w-7/12`}>{part.displayDescription}</td>
                <td css={tw`w-1/12 text-right`}>{part.lengthDescription}</td>
                <td css={tw`w-1/12`}>
                  {part.shipToBundleSizes.map((bundleSize, idx) => (
                    <span key={idx} css={tw`block`}>
                      {bundleSize}
                    </span>
                  ))}
                </td>
                {canViewPrices && (
                  <td css={tw`w-2/12 text-right`}>
                    {part.price &&
                      `${Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(part.price)} ${part.priceUnit} ${part.currency}`} 
                  </td>
                )}
              </tr>
            ))) : (
              <tr>
                <td css={tw`text-nucor-gray !px-2 !py-3 hover:!bg-white`} colSpan={5}>
                    No parts found
                </td>
              </tr>
            )}
        </tbody>
      </table>
    </div>
  );
};

export default PartListTable;
