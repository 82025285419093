/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import tw from "twin.macro";
import { useAppSelector } from "../../../../app/hooks";
import TableSortButton from "../../../../components/molecules/TableSortButton";
import { SearchCriteria } from "../../../../types/types";

type Props = {
  children?: ReactNode;
  currentSort: Pick<SearchCriteria, "sort" | "dir">;
  onSort: React.Dispatch<
    React.SetStateAction<{ sort: string; dir: "asc" | "desc" }>
  >;
};

const OpenOrdersTable: React.FC<Props> = ({ currentSort, onSort }) => {
  const sortOrdersByDescription = useAppSelector(
    (state) =>
      state.user.currentContext?.userPreferences?.sortOrdersByDescription
  );

  const sortHandler = (sort: string) => {
    onSort({
      sort: sort,
      dir:
        currentSort.sort !== sort
          ? "desc"
          : currentSort.dir === "asc"
          ? "desc"
          : "asc",
    });
  };

  return (
    <table className="ntp-portal-table" css={tw`w-full mt-2`}>
      <thead>
        <tr>
          <th css={tw`w-[10%]`}>
            <TableSortButton
              sort={currentSort}
              field="customerPONumber"
              onClick={(field) => sortHandler(field)}
            >
              PO Number
            </TableSortButton>
          </th>
          <th css={tw`w-[12%]`}>
            <TableSortButton
              sort={currentSort}
              field="salesOrderNumber,lineNumber"
              onClick={(field) => sortHandler(field)}
            >
              Sales Order
            </TableSortButton>
          </th>
          <th css={tw`w-[6%]`}>
            <TableSortButton
              sort={currentSort}
              field="site"
              onClick={(field) => sortHandler(field)}
            >
              Mill
            </TableSortButton>
          </th>
          <th css={tw`w-[8%]`}>
            <TableSortButton
              sort={currentSort}
              field="requestedShippingDate"
              onClick={(field) => sortHandler(field)}
            >
              Due Date
            </TableSortButton>
          </th>
          <th css={tw`w-[24%]`}>
            <TableSortButton
              sort={currentSort}
              field={
                sortOrdersByDescription
                  ? "lineDescription"
                  : "customerPartNumber"
              }
              onClick={(field) => sortHandler(field)}
            >
              Description
            </TableSortButton>
          </th>
          <th css={tw`w-[6%]`}>
            <TableSortButton
              sort={currentSort}
              field="grade"
              onClick={(field) => sortHandler(field)}
            >
              Grd
            </TableSortButton>
          </th>
          <th css={tw`w-[4%]`}>
            <TableSortButton
              sort={currentSort}
              field="totalLineOrderedPieces"
              onClick={(field) => sortHandler(field)}
            >
              Pcs Ord
            </TableSortButton>
          </th>
          <th css={tw`w-[4%]`}>
            <TableSortButton
              sort={currentSort}
              field="totalLinePiecesBalance"
              onClick={(field) => sortHandler(field)}
            >
              Pcs Bal
            </TableSortButton>
          </th>
          <th css={tw`w-[4%]`}>
            <TableSortButton
              sort={currentSort}
              field="readyToLoadPieces"
              onClick={(field) => sortHandler(field)}
            >
              Pcs Alc
            </TableSortButton>
          </th>
          <th css={tw`w-[6%]`}>
            <TableSortButton
              sort={currentSort}
              field="totalLineWeight"
              onClick={(field) => sortHandler(field)}
            >
              Wgt Ord
            </TableSortButton>
          </th>
          <th css={tw`w-[6%]`}>
            <TableSortButton
              sort={currentSort}
              field="totalLineWeightBalance"
              onClick={(field) => sortHandler(field)}
            >
              Wgt Bal
            </TableSortButton>
          </th>
          <th css={tw`w-[6%]`}>
            <TableSortButton
              sort={currentSort}
              field="readyToLoadWeight"
              onClick={(field) => sortHandler(field)}
            >
              Wgt Alc
            </TableSortButton>
          </th>
          <th css={tw`w-[4%]`}>
            <TableSortButton
              sort={currentSort}
              field="portalStatus"
              onClick={(field) => sortHandler(field)}
            >
              Sts
            </TableSortButton>
          </th>
        </tr>
      </thead>
    </table>
  );
};

export default OpenOrdersTable;
