import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorResponse, RequestStatus, SalesOrderItem } from "../types/types";
import { RootState } from "./store";
import { getRollingData, getRollingDataByPart } from "./product.actions";
import {
  addRollingItemToCart,
  removeRollingItemFromCart,
  updateRollingCartItem,
} from "./shoppingCart.actions";

type InitialState = {
  loading: RequestStatus;
  error?: string | ErrorResponse;
  compoundProductKey?: string;
  currentRollingItem?: SalesOrderItem;
  rollingItemIdToEdit?: number;
  currentCustomerRollings?: SalesOrderItem[];
};

const initialState: InitialState = {
  loading: "idle",
  error: undefined,
};

const rollingsSlice = createSlice({
  name: "rollings",
  initialState,
  reducers: {
    setCompoundProductKey: (state, action: PayloadAction<string>) => {
      state.compoundProductKey = action.payload;
    },
    setRollingItemToEdit: (
      state,
      action: PayloadAction<number | undefined>
    ) => {
      state.rollingItemIdToEdit = action.payload;
    },
    clearCurrentRollingItem: (state) => {
      state.currentRollingItem = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRollingData.fulfilled, (state, action) => {
      state.currentCustomerRollings = action.payload.salesOrderItems;
      state.loading = "idle";
    });
    builder.addCase(getRollingData.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getRollingData.rejected, (state) => {
      state.loading = "idle";
    });
    builder.addCase(getRollingDataByPart.fulfilled, (state, action) => {
      state.currentCustomerRollings = action.payload.salesOrderItems;
      state.loading = "idle";
    });
    builder.addCase(getRollingDataByPart.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getRollingDataByPart.rejected, (state) => {
      state.loading = "idle";
    });
    builder.addCase(addRollingItemToCart.fulfilled, (state, action) => {
      state.currentCustomerRollings = action.payload;
    });
    builder.addCase(updateRollingCartItem.fulfilled, (state, action) => {
      state.currentCustomerRollings = action.payload;
    });
    builder.addCase(removeRollingItemFromCart.fulfilled, (state, action) => {
      state.currentCustomerRollings = action.payload;
    });
  },
});

export const selectCurrentRollings = createSelector(
  [(state: RootState) => state.rollings.currentCustomerRollings],
  (rollings) =>
    rollings ? [...rollings]?.sort((a, b) => (a.id < b.id ? -1 : 1)) : []
);

export default rollingsSlice.reducer;
export const {
  setCompoundProductKey,
  clearCurrentRollingItem,
  setRollingItemToEdit,
} = rollingsSlice.actions;
