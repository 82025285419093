/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import tw from "twin.macro";
import { useAppSelector } from "../../app/hooks";

type Props = {
  children?: ReactNode;
};

const Error500: React.FC<Props> = () => {
  const errorEmail = useAppSelector(
    (state) => state.user.currentContext?.appContext.errorEmail
  );
  return (
    <div css={tw`w-full min-h-[80vh] flex justify-center items-center`}>
      <div css={tw`min-w-[300px] border shadow p-6 bg-white`}>
        <h2 css={tw`text-xl text-red-600 font-bold`}>
          Unexpected Problem - 500
        </h2>
        <p css={tw`text-xs my-4 font-semibold italic text-nucor-light-green`}>
          Sorry, but the action you just requested resulted in an unexpected
          problem. Please see below for options.
        </p>
        <ul css={tw`text-xs list-disc ml-6`}>
          <li>
            <Link
              css={tw`text-base font-semibold text-nucor-link underline hover:(text-nucor-link-hover no-underline)`}
              to="/portal"
            >
              Go to homepage
            </Link>
          </li>
          <li>Use the browser back arrow key and try again.</li>
          <li>
            Submit an error report to{" "}
            <a
              className="text-nucor-link underline hover:text-nucor-link-hover hover:no-underline"
              href={`mailto:${errorEmail}`}
            >
              Nucor Tubular Products
            </a>
            .
          </li>
          <li>
            If you can&apos;t wait for an email response, call 800-376-6000.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Error500;
