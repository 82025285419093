/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import tw from "twin.macro";
import DocumentSearchResults from "./DocumentSearchResults";
import { PDFFile } from "../../../../types/types";
import PDFEmbed from "../../../../components/molecules/PDFEmbed";
import TextButton from "../../../../components/atoms/TextButton";

type Props = {
  children?: ReactNode;
  file: PDFFile | null;
  setFile: React.Dispatch<React.SetStateAction<PDFFile | null>>;
};

const DocumentSearchContent: React.FC<Props> = ({ file, setFile }) => {
  return (
    <div css={tw`text-xs`}>
      <div css={tw`text-white font-bold bg-nucor-green text-xs flex justify-between py-1 px-2 mb-2`}>
        <span className="uppercase w-40">Document Search</span>
        {file && (
          <TextButton onClick={() => setFile(null)} css={tw`text-white`}>
            Close
          </TextButton>
        )}
        <span className="w-40"></span>
      </div>

      {file ? (
        <PDFEmbed
          onCancel={() => setFile(null)}
          setFile={setFile}
          file={file}
        />
      ) : (
        <DocumentSearchResults />
      )}
    </div>
  );
};

export default DocumentSearchContent;
