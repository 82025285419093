/** @jsxImportSource @emotion/react */
import React, { ReactNode, memo } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { ChartState } from "./AccountChart";
import { useAppSelector } from "../../../../app/hooks";
import { selectCurrentCustomerSummary } from "../../../../store/customer.reducer";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

type Props = {
  children?: ReactNode;
  chartType: ChartState;
};

const chartDetails = {
  readyToShip: {
    title: "Ready To Ship",
    xTitle: "Days Past Original Due Date",
    yTitle: "Tons",
  },
  orderHistory: {
    title: "Order History",
    xTitle: "Month",
    yTitle: "Tons",
  },
  shipmentHistory: {
    title: "Shipment History",
    xTitle: "Month",
    yTitle: "Tons",
  },
};

const Chart: React.FC<Props> = ({ chartType = "readyToShip" }) => {
  const currentCustomerSummary = useAppSelector(selectCurrentCustomerSummary);

  const chartOptions = {
    responsive: true,
    aspectRatio: 1.5 / 1,
    plugins: {
      title: {
        display: true,
        text: chartDetails[chartType].title,
        color: "black",
      },
      legend: {
        display: false,
      },
    },

    scales: {
      x: {
        grid: { display: false },
        title: {
          display: true,
          text: chartDetails[chartType].xTitle,
          color: "black",
        },
        ticks: {
          color: "black",
        },
      },
      y: {
        title: {
          display: true,
          text: chartDetails[chartType].yTitle,
          color: "black",
        },
        ticks: { color: "black" },
      },
    },
    layout: {
      padding: 10,
    },
  };

  if (chartType === "readyToShip") {
    return (
      <Bar
        css={[{ border: "1px solid #bbb", background: "#f0f0f0" }]}
        options={{ ...chartOptions }}
        data={{
          labels: ["0-30", "30-60", "60-90", "90+"],
          datasets: [
            {
              backgroundColor: "rgb(217,36,0)",
              data: [
                currentCustomerSummary?.readyToShipWeight0To30,
                currentCustomerSummary?.readyToShipWeight30To60,
                currentCustomerSummary?.readyToShipWeight60To90,
                currentCustomerSummary?.readyToShipWeightOver90,
              ],
            },
          ],
        }}
      />
    );
  }

  if (chartType === "orderHistory") {
    return (
      <Bar
        css={[{ border: "1px solid #bbb", background: "#f0f0f0" }]}
        options={{ ...chartOptions }}
        data={{
          labels: currentCustomerSummary?.orderHistory.map(
            (item) => item.monthYear
          ),
          datasets: [
            {
              backgroundColor: "rgb(217,36,0)",
              data: currentCustomerSummary?.orderHistory.map(
                (item) => item.weight
              ),
            },
          ],
        }}
      />
    );
  }

  if (chartType === "shipmentHistory") {
    return (
      <Bar
        css={[{ border: "1px solid #bbb", background: "#f0f0f0" }]}
        options={{ ...chartOptions }}
        data={{
          labels: currentCustomerSummary?.shipmentHistory.map(
            (item) => item.monthYear
          ),
          datasets: [
            {
              backgroundColor: "rgb(217,36,0)",
              data: currentCustomerSummary?.shipmentHistory.map(
                (item) => item.weight
              ),
            },
          ],
        }}
      />
    );
  }

  return null;
};

export default memo(Chart);
