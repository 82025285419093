/** @jsxImportSource @emotion/react */
import React, { ComponentProps, ReactNode, useEffect, useState } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useClickOutside } from "../../hooks/useClickOutside";
import { RequestStatus, SalesTeam } from "../../types/types";
import AnimatedLoadingSpinner from "../atoms/AnimatedLoadingSpinner";
import { fetchSalesTeam } from "../../store/customer.actions";
import ContactInfoBlock from "../molecules/ContactInfoBlock";

type Props = {
  children?: ReactNode;
  onClose: () => void;
} & ComponentProps<"div">;

const ContactInfo: React.FC<Props> = ({ onClose, ...remainingProps }) => {
  const ref = useClickOutside<HTMLDivElement>(onClose);
  const customerId = useAppSelector(
    (state) => state.customer.currentCustomer?.id
  );
  const dispatch = useAppDispatch();

  const [status, setStatus] = useState<RequestStatus>("pending");
  const [results, setResults] = useState<SalesTeam>();

  useEffect(() => {
    if (!customerId) return;
    setStatus("pending");
    dispatch(fetchSalesTeam(customerId))
      .unwrap()
      .then((results) => {
        setStatus("success");
        setResults(results);
      })
      .catch(() => {
        setStatus("error");
      });
  }, []);

  return (
    <div
      ref={ref}
      css={[tw`w-80 bg-nucor-green p-2 flex flex-col text-xs text-black`]}
      {...remainingProps}
    >
      <div css={[tw`flex`]}>
        <p css={[tw`text-white text-left text-xs font-bold flex-grow`]}>
          If you have questions or comments please contact your sales team.
        </p>
        <button
          onClick={onClose}
          css={[
            tw`bg-white text-nucor-green text-xs h-4 font-bold w-6 flex justify-center items-center ml-8 border-0`,
          ]}
          dangerouslySetInnerHTML={{ __html: `\u00d7` }}
        />
      </div>
      {status === "pending" && (
        <div css={tw`p-2 bg-white`}>
          <AnimatedLoadingSpinner />
        </div>
      )}
      {results && (
        <>
          <div css={[tw`bg-white mt-2 flex-grow py-2 px-4`]}>
            {results.phoneNumber && (
              <>
                <p css={[tw`text-[#6d756e] font-bold`]}>Toll Free</p>
                <p css={[tw`font-bold`]}>{results.phoneNumber}</p>
              </>
            )}
            {results.insideSalesRep && (
              <ContactInfoBlock
                contact={results.insideSalesRep}
                title="Inside Sales Representative"
              />
            )}
            {results.salesManager && (
              <ContactInfoBlock
                contact={results.salesManager}
                title="Sales Manager"
              />
            )}
            {results.districtSalesManager && (
              <ContactInfoBlock
                contact={results.districtSalesManager}
                title="District Sales Manager"
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ContactInfo;
