/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import {
  Control,
  DeepRequired,
  FieldErrorsImpl,
  FieldValues,
  UseFormRegister,
} from "react-hook-form";
import tw from "twin.macro";
import Select from "../../../../components/atoms/Select";
import { PersonalInfoForm } from "../edit_user/personalInfoForm";
import { useAppSelector } from "../../../../app/hooks";

type Props<T extends FieldValues> = {
  children?: ReactNode;
  register: UseFormRegister<PersonalInfoForm>;
  errors: FieldErrorsImpl<DeepRequired<PersonalInfoForm>>;
  control: Control<T>;
};

function AccountStatus<T extends FieldValues>({ control }: Props<T>) {
  const { userFormSetup } = useAppSelector((state) => state.admin);

  return (
    <>
      <div css={tw`flex items-center`}>
        <label css={tw`w-[30%]`}>Change Status:</label>
        <Select
          name="status"
          control={control}
          data={userFormSetup?.statuses?.map((status) => ({
            label: status.description,
            value: status.id,
          }))}
          minWidth="12ch"
        />
      </div>
    </>
  );
}

export default AccountStatus;
