/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import tw from "twin.macro";
import { CartCustomer } from "../../../../../types/types";

type Props = {
  children?: ReactNode;
  customer?: CartCustomer;
};

const ShipTo: React.FC<Props> = ({ customer }) => {
  return (
    <>
      <p css={tw`text-nucor-gray font-bold`}>Ship To:</p>
      <p>
        {customer?.id} - <span css={tw`font-bold`}>{customer?.name}</span>
      </p>
      <p>{customer?.address?.address1}</p>
      <p>{customer?.address?.address2}</p>
      <p>{customer?.address?.address3}</p>
      <p>{`${customer?.address?.city ? `${customer?.address?.city},` : ""} ${
        customer?.address?.stateCode ?? ""
      } ${customer?.address.postalCode ?? ""}`}</p>
      {customer?.address?.phone && <p>Tel: {customer?.address?.phone}</p>}
      {customer?.address?.fax && <p>Fax: {customer?.address?.fax}</p>}
    </>
  );
};

export default ShipTo;
