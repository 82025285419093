/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import tw from "twin.macro";
import { FetchStockListDocumentRequest } from "../../store/product.actions";

type Props = {
  children?: ReactNode;
  title: string;
  childrenPosition?: "start" | "end";
  criteria?: FetchStockListDocumentRequest;
} & React.HTMLAttributes<HTMLDivElement>;

const SectionHeader: React.FC<Props> = ({
  title,
  children,
  childrenPosition = "end",
  ...remainingProps
}) => {
  return (
    <div
      css={[
        tw`text-white text-sm uppercase bg-nucor-green w-full p-[2px] px-2 flex items-center`,
      ]}
      {...remainingProps}
    >
      <h2 css={[tw`font-bold`, childrenPosition === "end" && tw`flex-1`]}>
        {title}
      </h2>
      {children}
    </div>
  );
};

export default SectionHeader;
