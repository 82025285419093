/** @jsxImportSource @emotion/react */
import React, { ReactNode, useEffect, useState } from "react";
import tw from "twin.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSquareMinus,
  faSquarePlus,
} from "@fortawesome/free-regular-svg-icons";
import { OrderTotals, SearchCriteria } from "../../../../types/types";
import OpenOrdersDetails from "./OpenOrdersDetails";
import { numberFormatter } from "../../../../helpers/numberFormat";

type Props = {
  children?: ReactNode;
  order: OrderTotals;
  currentSort: Pick<SearchCriteria, "sort" | "dir">;
};

const Order: React.FC<Props> = ({ order, currentSort }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { poundsOrdered, poundsBalance, poundsReadyToLoad } = order;

  useEffect(() => {
    setIsExpanded(false);
  }, [order]);

  return (
    <div>
      <button
        css={tw`w-full text-left`}
        type="button"
        onClick={() => setIsExpanded((prev) => !prev)}
      >
        <div css={tw`py-1 flex border border-b-[#00000066]`}>
          <div css={tw`w-[3ch]`}>
            <FontAwesomeIcon
              css={tw`ml-1`}
              icon={isExpanded ? faSquareMinus : faSquarePlus}
            />
          </div>
          <div css={tw`flex-grow font-semibold text-nucor-gray`}>
            <span>Ship To: </span>
            <span css={tw`uppercase`}>{order.shipToId}</span>
            <span css={tw`uppercase ml-2`}>{order.shipName}</span>
            <span css={tw`ml-4`}>{order.deliveryMode?.toUpperCase()}</span>
          </div>
          <div css={tw`w-[25%] uppercase font-semibold text-nucor-gray`}>
              {order.shipCity}, {order.shipState}
          </div>
          <div css={tw`w-[8%] font-semibold text-nucor-gray text-right`}>
            Totals
          </div>
          <div css={tw`w-[6%] text-right`}>
            {numberFormatter(poundsOrdered ?? 0)}
          </div>
          <div css={tw`w-[6%] text-right`}>
            {numberFormatter(poundsBalance ?? 0)}
          </div>
          <div css={tw`w-[6%] text-right`}>
            {numberFormatter(poundsReadyToLoad ?? 0)}
          </div>
          <div css={tw`w-[4%] text-right`}>
            &nbsp;
          </div>   
        </div>
      </button>

      {isExpanded && (
        <OpenOrdersDetails order={order} currentSort={currentSort} />
      )}
    </div>
  );
};

export default Order;
