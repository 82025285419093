/** @jsxImportSource @emotion/react */
import React, { ReactNode, useContext } from "react";
import tw from "twin.macro";
import CertsResults from "./CertsResults";
import PDFEmbed from "../../../../components/molecules/PDFEmbed";
import { PDFContext } from "./CertsList";
import TextButton from "../../../../components/atoms/TextButton";

type Props = {
  children?: ReactNode;
};

const CertsContent: React.FC<Props> = () => {
  const ctx = useContext(PDFContext);

  return (
    <div>
      <div css={tw`text-white font-bold bg-nucor-green text-xs flex justify-between py-1 px-2 mb-2`}>
        <span css={tw`uppercase w-40`}>Test Certificates</span>
        {ctx?.file && (
          <TextButton onClick={() => ctx?.setFile(null)} css={tw`text-white`}>
            Back to List
          </TextButton>
        )}
        <span className="w-40"></span>
      </div>

      <div css={[ctx?.file && tw`hidden`]}>
        <CertsResults />
      </div>
      {ctx?.file && (
        <PDFEmbed
          onCancel={() => ctx?.setFile(null)}
          file={ctx?.file}
          setFile={ctx?.setFile}
        />
      )}
    </div>
  );
};

export default CertsContent;
