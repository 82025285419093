/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import BrowseSearch from "../BrowseSearch";
import OrdersBrowse from "./OrdersBrowse";
import OrdersSearch from "./OrdersSearch";

type Props = {
  children?: ReactNode;
};

const OrdersLeft: React.FC<Props> = () => {
  return <BrowseSearch browse={<OrdersBrowse />} search={<OrdersSearch />} />;
};

export default OrdersLeft;
