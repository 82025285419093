import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Listbox } from "@headlessui/react";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import truck from "../../assets/images/icon_truck.png";
import barge from "../../assets/images/icon_barge.png";
import rail from "../../assets/images/icon_rail.png";

type Props = {
  children?: ReactNode;
  value?: string;
  onChange: (value: string) => void;
  minWidth?: string;
  alignTo?: "left" | "right";
  addAll?: boolean;
};

function alignment(list: HTMLDivElement | null): "left" | "right" {
  if (list?.offsetWidth && list?.offsetLeft) {
    const rightmost = list.offsetWidth + list.offsetLeft;
    return rightmost < document.body.clientWidth * 0.75 ? "left" : "right";
  } else {
    return "left";
  }
}

const ShipToSelect: React.FC<Props> = ({
  value,
  onChange,
  alignTo,
  minWidth,
  addAll
}) => {
  const shipTos = useAppSelector(
    (state) => state.customer.currentCustomer?.shipTos
  );
  const currentShipTo = shipTos?.find((item) => item.id === value);

  const [alignOptionsTo, setAlignOptionsTo] = useState<"left" | "right">(
    alignTo ?? "left"
  );
  const listboxRef = useRef<HTMLDivElement>(null);

  // Have to use className prop to style
  // component based on conflicts with twin-macro and headlessui

  useEffect(() => {
    if (!alignTo) {
      setAlignOptionsTo(alignment(listboxRef.current));
    }
  }, [listboxRef.current]);

  if (!shipTos) return null;

  const getIcon = (deliveryMode: string) => {
    switch (deliveryMode) {
      case "Truck":
        return truck;
      case "Barge":
        return barge;
      case "Rail":
        return rail;
    }
  };

  return (
    <Listbox value={value} onChange={onChange}>
      <div
        ref={listboxRef}
        className={`inline-block relative text-xs bg-white border border-gray-300 p-[1px] pl-1 focus-within:border-blue-400 focus-within:shadow`}
        style={{
          minWidth: minWidth,
        }}
      >
        <Listbox.Button className="flex w-full outline-none">
          <span className="pr-6 text-left h-[18px] leading-[18px] overflow-hidden">
            {'all' == value && (
              <>All</>
            )}
            {'all' != value && (
              <>{`${currentShipTo?.id} - ${currentShipTo?.name}`}</>
            )}
          </span>
          <div className="absolute right-0 top-0 h-full w-5 bg-[#e2e2e2]">
            <FontAwesomeIcon
              className="text-[#1E40AF] mt-1"
              icon={faAngleDown}
            />
          </div>
        </Listbox.Button>
        <Listbox.Options
          className={`absolute bg-white top-[20px] ${
            alignOptionsTo === "left" ? "left-[-1px]" : "right-[-2px]"
          } outline-none focus-within:border-blue-400 border border-gray-300 z-20 max-h-60 overflow-y-scroll`}
          style={{
            minWidth: minWidth,
          }}
          as="div"
        >
          <table>
            <tbody>

              {addAll && (
                <Listbox.Option key={'all'} value={'all'} as={React.Fragment}>
                  {({ active }) => (
                      <tr
                        className="w-full cursor-pointer"
                        style={{
                          background: active ? "#DFE8F6" : undefined,
                        }}
                      >
                        <td className="border-none px-1 py-[3px]" colSpan={5}>All</td>
                      </tr>
                  )}
                </Listbox.Option>
              )}

              {shipTos?.map((item, index) => (
                <Listbox.Option key={index} value={item.id} as={React.Fragment}>
                  {({ active }) => (
                    <tr
                      className="w-full cursor-pointer"
                      style={{
                        background: active ? "#DFE8F6" : undefined,
                      }}
                    >
                      <td className="border-none px-1 py-[3px]">{item.id}</td>
                      <td className="border-none px-1 py-[3px] whitespace-nowrap">
                        {item.description}
                      </td>
                      <td className="border-none px-1 py-[3px] whitespace-nowrap">{`${item.address.city}, ${item.address.stateCode}`}</td>
                      <td className="border-none px-1 py-[3px] w-10 inline-block">
                        <img src={getIcon(item.deliveryMode)} />
                      </td>
                      <td className="border-none px-1 py-[3px]">
                        {item.pricingModel && item.pricingModel}
                      </td>
                    </tr>
                  )}
                </Listbox.Option>
              ))}
            </tbody>
          </table>
        </Listbox.Options>
      </div>
    </Listbox>
  );
};

export default ShipToSelect;
