/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import React, { ReactNode } from "react";
import { Link as RLink, LinkProps, To } from "react-router-dom";
import { Interpolation, Theme } from "@emotion/react";
import { useAppSelector } from "../../app/hooks";

type Props = {
  to: To;
  children?: ReactNode;
  onClick?: () => void;
  css?: Interpolation<Theme>;
  showCustId?: boolean;
} & LinkProps;

const Link: React.FC<Props> = ({
  to,
  children,
  showCustId,
  ...remainingProps
}) => {
  const customerId = useAppSelector(
    (state) => state.customer.currentCustomer?.id
  );

  return (
    <RLink
      css={tw`text-current`}
      to={showCustId ? `${to}?customer_id=${customerId}` : to}
      {...remainingProps}
    >
      {children}
    </RLink>
  );
};

export default Link;
