/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { css } from "@emotion/react";
import Link from "../../../components/atoms/Link";
import Button from "../../../components/atoms/Button";
import { NewAccountRequest } from "../../../types/types";
import { validationSchema } from "./requestAccountForm";
import { useAppDispatch } from "../../../app/hooks";
import { fetchSalesReps, requestNewAccount } from "../../../store/app.actions";
import Loading from "../../../components/atoms/Loading";
import { useNavigate } from "react-router-dom";

type SalesRepSelection = { key: string; contactName: string };

function Home() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [requestFor, setRequestFor] = useState("NewCustomer");
  const [salesReps, setSalesReps] = useState<SalesRepSelection[]>([]);
  const [status, setStatus] = useState<
    "idle" | "submitting" | "error" | "submitted"
  >("idle");
  const initialLoad = useRef(true);
  const [submitError, setSubmitError] = useState("");

  useEffect(() => {
    if (initialLoad) {
      dispatch(fetchSalesReps())
        .unwrap()
        .then((reps) => setSalesReps(reps));
    }
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm<NewAccountRequest>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data: NewAccountRequest) => {
    if (data.requestFor === "CurrentCustomer") {
      data.supervisor = undefined;
    }
    if (data.requestFor === "NucorEmployee") {
      data.salesRepCode = undefined;
    }

    setStatus("submitting");
    dispatch(requestNewAccount(data))
      .unwrap()
      .then(() => {
        setStatus("submitted");
      })
      .catch((error) => {
        setSubmitError(error);
        setStatus("error");
      });
  };

  const formStyles = css`
    input {
      width: 100%;
      padding: 8px;
      border: 1px solid #ddd;
      font-size: 12px;
      &:focus {
        outline: none;
        box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1),
          0 2px 4px -2px rgb(0 0 0 / 0.1);
      }
    }
    select {
      width: 100%;
      padding: 8px;
      border: 1px solid #ddd;
      font-size: 12px;
      &:focus {
        outline: none;
        box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1),
          0 2px 4px -2px rgb(0 0 0 / 0.1);
      }
    }
    label {
      color: #757575;
      display: block;
      margin-bottom: 0.3rem;
    }
    label span {
      color: #bb1100;
      font-size: 13px;
      margin-left: 0.4rem;
    }
    textarea {
      padding: 8px;
      width: 100%;
      height: 60px;
      border: 1px solid #ddd;
      resize: none;
      &:focus {
        outline: none;
        box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1),
          0 2px 4px -2px rgb(0 0 0 / 0.1);
      }
    }
  `;

  if (status === "submitted") {
    return (
      <div
        css={tw`mt-20 max-w-md border border-nucor-green rounded-xl p-4 text-nucor-green text-center text-sm`}
      >
        <p css={tw`col-span-full`}>
          Thank you for requesting an account with us, you will be notified by
          an email after your account has been set up and ready for use.{" "}
        </p>
        <Button css={tw`mt-4`} onClick={() => navigate("/")} type="button">
          Ok
        </Button>
      </div>
    );
  }

  return (
    <div
      css={[
        tw`w-1/2 mx-auto shadow-lg my-12 p-6 relative`,
        { border: "1px solid #ddd" },
      ]}
    >
      {status === "submitting" && <Loading>Submitting</Loading>}
      <div css={tw`text-nucor-light-green`}>
        <FontAwesomeIcon icon={faUser} />{" "}
        <span css={tw`ml-1 font-medium`}>Request Account</span>
      </div>
      <div css={tw`mt-4`}>
        {status === "error" && (
          <p css={tw`col-span-full`}>
            <p css={tw`text-xs text-red-600 text-center mb-4`}>{submitError}</p>
            Unable to create account. Contact us via email at{" "}
            <a href="mailto=ntp-accountrequest@nucor.com">
              ntp-accountrequest@nucor.com
            </a>{" "}
            to request your new account.
          </p>
        )}
      </div>
      <div css={tw`mt-4`}>
        The NTP Portal is intended for customers of Nucor Tubular Products.
        Please refer to
        <a
          css={tw`text-nucor-light-green mx-2 underline`}
          target="_blank"
          href="https://www.nucortubular.com"
          rel="noreferrer"
        >
          www.nucortubular.com
        </a>
        for additional information and resources.
      </div>
      <form
        css={[tw`grid grid-cols-1 md:grid-cols-2 gap-4 mt-4`, formStyles]}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div>
          <label htmlFor="requestFor">
            Request For
            <span css={tw`text-red-500`}>*</span>
          </label>
          <select
            {...register("requestFor")}
            name="requestFor"
            id="requestFor"
            defaultValue={requestFor}
            onChange={(e) => setRequestFor(e.target.value)}
          >
            <option value="NewCustomer">New Customer</option>
            <option value="CurrentCustomer">Current Customer</option>
            <option value="NucorEmployee">Current Nucor Employee</option>
          </select>
        </div>
        {
          // Occupies space for grid layout if new customer is selected
          requestFor === "NewCustomer" && <div css={tw`opacity-0`}></div>
        }
        {requestFor === "CurrentCustomer" && (
          <div id="salesRepRow">
            <label css={tw`block`}>
              Sales Rep
              <span>*</span>
            </label>
            <select
              {...register("salesRepCode")}
              name="salesRepCode"
              id="salesRepCode"
              onChange={(e) => setValue("salesRepCode", e.target.value)}
            >
              <option value="select">Select</option>
              {salesReps.map((item: SalesRepSelection, index) => (
                <option key={index} value={item.key}>
                  {item.contactName}
                </option>
              ))}
            </select>
            {errors?.salesRepCode && (
              <p css={tw`text-red-500 text-sm`}>
                {errors.salesRepCode.message}
              </p>
            )}
          </div>
        )}
        {requestFor === "NucorEmployee" && (
          <div id="supervisorRow">
            <label css={tw`block`}>
              Supervisor
              <span css={tw`text-red-500`}>*</span>
            </label>
            <input
              {...register("supervisor")}
              type="text"
              name="supervisor"
              id="supervisor"
            />
            {errors?.supervisor && (
              <p css={tw`text-red-500 text-sm`}>{errors.supervisor.message}</p>
            )}
          </div>
        )}
        <div>
          <label css={tw`block`} htmlFor="firstName">
            First Name
            <span>*</span>
          </label>
          <input
            {...register("firstName")}
            type="text"
            name="firstName"
            id="firstName"
          />
          {errors?.firstName && (
            <p css={tw`text-red-500 text-sm`}>{errors.firstName.message}</p>
          )}
        </div>
        <div>
          <label css={tw`block`} htmlFor="lastName">
            Last Name
            <span>*</span>
          </label>
          <input
            {...register("lastName")}
            type="text"
            name="lastName"
            id="lastName"
          />
          {errors?.lastName && (
            <p css={tw`text-red-500 text-sm`}>{errors.lastName.message}</p>
          )}
        </div>
        <div>
          <div>
            <label css={tw`block`} htmlFor="lastName">
              e-Mail
              <span>*</span>
            </label>
            <input {...register("email")} type="text" name="email" id="email" />
            {errors?.email && (
              <p css={tw`text-red-500 text-sm`}>{errors.email.message}</p>
            )}
          </div>
        </div>
        <div css={tw`opacity-0`}></div>
        <div>
          <label css={tw`block`} htmlFor="workPhoneAreaCode">
            Work Phone
            <span>*</span>
          </label>
          <div css={tw`grid grid-cols-12 gap-4`}>
            <input
              css={tw`col-span-3`}
              {...register("workPhone.areaCode")}
              type="text"
              maxLength={3}
            />
            <input
              css={tw`col-span-3`}
              {...register("workPhone.exchange")}
              type="text"
              maxLength={3}
            />
            <input
              css={tw`col-span-6`}
              {...register("workPhone.local4")}
              type="text"
              maxLength={4}
            />
          </div>
          {errors?.workPhone?.areaCode && (
            <p css={tw`text-red-500 text-sm`}>
              {errors.workPhone.areaCode.message}
            </p>
          )}
          {errors?.workPhone?.exchange && (
            <p css={tw`text-red-500 text-sm`}>
              {errors.workPhone.exchange.message}
            </p>
          )}
          {errors?.workPhone?.local4 && (
            <p css={tw`text-red-500 text-sm`}>
              {errors.workPhone.local4.message}
            </p>
          )}
        </div>
        <div>
          <label css={tw`block`} htmlFor="title">
            Job Title
            <span>*</span>
          </label>
          <input {...register("title")} type="text" name="title" id="title" />
          {errors?.title && (
            <p css={tw`text-red-500 text-sm`}>{errors.title.message}</p>
          )}
        </div>
        <div>
          <label css={tw`block`} htmlFor="organization">
            Organization
            <span>*</span>
          </label>
          <input
            {...register("organization")}
            type="text"
            name="organization"
            id="organization"
          />
          {errors?.organization && (
            <p css={tw`text-red-500 text-sm`}>{errors.organization.message}</p>
          )}
        </div>
        <div css={tw`opacity-0`}></div>
        <div>
          <label css={tw`block`} htmlFor="city">
            City
            <span>*</span>
          </label>
          <input {...register("city")} type="text" name="city" id="city" />
          {errors?.city && (
            <p css={tw`text-red-500 text-sm`}>{errors.city.message}</p>
          )}
        </div>
        <div>
          <label css={tw`block`} htmlFor="state">
            State/Province
            <span>*</span>
          </label>
          <input {...register("state")} type="text" name="state" id="state" />
          {errors?.state && (
            <p css={tw`text-red-500 text-sm`}>{errors.state.message}</p>
          )}
        </div>
        <div css={tw`col-span-full`}>
          <label htmlFor="notes">Notes</label>
          <textarea {...register("notes")} maxLength={1000}></textarea>
        </div>

        <div css={tw`col-span-full gap-6 flex mx-auto`}>
          <Button
            value="submit"
            // disabled={isSubmitting}
            onClick={handleSubmit(onSubmit)}
          >
            Submit
          </Button>
          <Button
            value="reset"
            disabled={status === "submitting"}
            onClick={() => reset()}
          >
            Reset Form
          </Button>
        </div>
      </form>
      <Link css={tw`text-nucor-light-green text-sm underline`} to="/login">
        Return to login page
      </Link>
    </div>
  );
}

export default Home;
