/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import tw from "twin.macro";

type Props = {
  children?: ReactNode;
};

const Privacy: React.FC<Props> = () => {

  return (
    <div css={tw`w-full min-h-[80vh] flex justify-center items-center`}>
      <div css={tw`min-w-[300px] border shadow p-6 bg-white text-sm `}>
        <p css={tw`font-bold flex justify-center` }>PRIVACY POLICY</p>

        <p css={tw`font-bold flex justify-center`}>Last Updated: 12/3/2019</p>

        <p css={tw`mt-10`}>Nucor Tubular Products Inc., together with its parent and its
        divisions, affiliates and subsidiaries (collectively
        “we”, “us” or “our”) provides or otherwise makes available
        to you certain online services, including websites, software, software add-ins,
        tools, content, and other online programs (collectively, the “Services”). 
        This Privacy Policy (the “Policy”) describes our privacy practices and
        the possible uses of the information that we collect via the Services.  This
        Policy should be considered along with the applicable Terms and Conditions of
        Use of the website and any End User License Agreement, if any, for any
        applications provided through the website.</p>

        <p css={tw`mt-10`}>By accessing or using the Services, you agree with and consent to be bound by the
        terms and conditions of this Policy.  We may update this Policy from time to time
        in our sole discretion.  Any updates to this Policy will be posted on this
        website, or you will be notified of any updates when you access the website or
        log into the website, where applicable. Except in circumstances where we are
        required to obtain your express consent under applicable law, it is your
        responsibility to review the Policy for any changes each time that you use the Services
        and you are bound by such changes.  Use of the Services by you following our posting
        of a new privacy policy constitutes your acceptance of the Policy as modified.</p>

        <p css={tw`mt-10 font-bold capitalize`}>1. INFORMATION WE COLLECT</p>

        <p css={tw`mt-10`}>We collect information about our users in various ways, including by your
        voluntary submissions, participation in the Services and through cookies and
        other tracking technology.  This information helps us personalize and improve
        the Services and provide the Services’ functionality.  We may collect the
        following information: </p>

        <p css={tw`mt-10 ml-20`}>
          <span css={tw`font-bold mr-3`}>A. Personal Information.</span>“Personal
          Information” means information about an identifiable individual.  We may
          collect the following Personal Information from you in connection with your use
          of the Services: your name, company, title, phone number, email address, fax,
          postal address, city, state or province, zip code, credit information of you or
          your company (such as accounts payable contact information, corporate contacts,
          bank references, D&B numbers, trade references, tax exemption certificates,
          financial statements, W-9 forms, or other credit information), IP address,
          username, device name, or other identifying information that may be provided
          through your use of the Services and that may identify you.  This Personal Information
          is usually provided by you when you register to use certain Services or
          download certain software available via the Services, or is collected from you
          or the device you use to access the Services.  You can choose not to provide
          certain information by not entering or providing the information, but as a
          result, you may not be able to take advantage of some or all of the Services.</p>

        <p css={tw`mt-10 ml-20`}>
          <span css={tw`font-bold mr-2`}>B. User Generated Content.</span> 
          We may collect other information that you voluntarily provide that would not be
          considered Personal Information as part of the Services, including but not
          limited to comments, communications, specifications, drawings, computer models,
          or other information provided by you through the Services (“User Generated
          Content”).  We may, in our sole discretion, share your User Generated
          Content with others in connection with the Services provided, however, we will
          not share any information which may identify you together with such User
          Generated Content.  If you do not want certain information shared with others,
          you should not submit User Generated Content that contains information that you
          do not want shared.  </p>

        <p css={tw`mt-10 ml-20`}>
          <span css={tw`font-bold mr-3`}>C. Geolocation Information.</span>
          If you consent, we may collect geolocation information generated by your mobile
          computing device, computer or other devices in order to provide you with Services
          that are specifically tailored to your location, such as providing Company
          locations to you based on the geolocation information.  You can choose not to
          provide geolocation information, but as a result, you may not be able to take
          advantage of some of the Services’ features.  If you do not want this
          information shared with others, you should choose not to provide geolocation
          information.</p>

          <p css={tw`mt-10 ml-20`}>
          <span css={tw`font-bold mr-3`}>D. Automatic Information.</span>
          We may collect general, non-personal, statistical information about the use of
          the Services automatically, such as how many visitors visit a specific website
          page, how long they stay on that page, the hyperlinks users click, the usage of
          tools provided on the website or downloaded by the users, the functionally used
          within the tools, the frequency of the use of the functionality within the
          tools, or the like.  We collect this information by using cookies, web beacons
          and other technologies.  This information represents a generic overview of our
          users and allows us to modify the Services based on user traffic and behavior. 
          We collect this information in order to help enhance the Services and improve
          the user experience. </p>

        <p css={tw`mt-10 ml-20`}>Cookies are small text files that a website uses to
        recognize repeat users, track user behavior and target advertising and content.
        Cookies do not identify specific users, only a user’s device.  Like most websites,
        we may use cookies to store and track information about users of the Services.
        You may set your browser to notify you when you receive a new cookie or to
        disable cookies.  However, this may hinder performance of the Services and
        prevent you from using some of the features of the Services. </p>

        <p css={tw`mt-10 ml-20`}>Other tracking technologies may record information
        such as Internet domain and host names, Internet protocol (IP) addresses,
        browser software and operating system types, clickstream patterns, and dates
        and times the Services are accessed.  We may link the information we record
        using tracking technologies to Personal Information we collect.</p>

        <p css={tw`mt-10 ml-20`}>We may use Google Analytics to collect information
        regarding visitor behavior and visitor demographics on some of our Services,
        and to develop website content. For more information about Google Analytics,
        please visit www.google.com/policies/privacy/partners/. You can opt out of Google’s collection and
        processing of data generated by your use of the Services by going to http://tools.google.com/dlpage/gaoptout.</p>

        <p css={tw`mt-10 font-bold capitalize`}>2. HOW WE USE & SHARE INFORMATION </p>

        <p css={tw`mt-10`}>We use the information, including the Personal Information, we collect for a
        number of purposes, including to provide the Services’ functionality, improve
        the Services, communicate with users (including for marketing purposes subject
        to applicable anti-spam laws), and/or for internal business analysis of the use
        and effectiveness of the Services. We may use the Personal Information,
        including the credit information, to evaluate your
        eligibility for products and/or services that we offer, including the extension
        of credit to purchase products and/or services from us.  Additionally,
        subject to applicable anti-spam laws, we may use your contact information to
        send notifications to you regarding updates to the Services, such as updated
        software versions, as well as to provide our marketing material to you. </p> 

        <p css={tw`mt-10`}>On reasonable notice, you may withdraw your consent to use your Personal
        Information at any time, subject to certain restrictions prescribed by
        applicable law.</p>

        <p css={tw`mt-10`}>We do not disclose Personal Information to third parties, except (1) when we have
        your permission to make the disclosure, (2) when the disclosure is necessary
        for the purpose for which the information was obtained as set out in this
        Policy, (3) when the third party is acting on behalf of us to provide or manage
        the Services, or (4) where otherwise stated in this Policy.  We also may
        disclose Personal Information where required by applicable law or when the
        disclosure is necessary for the establishment or maintenance of legal claims or
        legal compliance, to satisfy any law, regulation, subpoena or government request,
        or in connection with litigation. </p>

        <p css={tw`mt-10`}>If you are located in Mexico, by accessing or using the Services you agree with
        and consent to such disclosure to third parties of your Personal Information in
        accordance with this Policy.  With respect to the obligations regarding your
        Personal Information under this Policy, if we disclose your Personal
        Information the recipient will receive your Personal Information under
        obligations that are not less stringent than the obligations under this Policy.</p>

        <p css={tw`mt-10`}>Some of the recipients to whom we disclose your Personal Information as contemplated
        in, and in accordance with, this Policy may be located in jurisdictions outside
        your jurisdiction of residence. As a result, your Personal Information may be
        used, stored and/or accessed in countries outside of your jurisdiction of
        residence, including for residents of Canada or Mexico within the United
        States. However, all such Personal Information, transferred to third parties
        located in either your country or abroad, will be protected in accordance with
        this Policy.  Please note that when your Personal Information is located
        outside of your jurisdiction of residence it will be subject to the laws of the
        country in which it is situated and these jurisdictions may not have an
        equivalent level of data protection laws as those in your country.</p>        

        <p css={tw`mt-10`}>We may share aggregated user data with third parties in order to describe the use
        of the Services to existing or potential business partners or in response to a
        government request.  We may also share aggregated demographic and preference
        data with third parties for research purposes or to enable the provision of
        targeted information, promotions, offers and/or discounts or to create a better
        overall experience for the users.</p>

        <p css={tw`mt-10`}>In the event that we are, or a division or subsidiary is, acquired by, or merged
        into, another entity, or if there is a sale of all or a portion of our assets,
        your Personal Information may be transferred to the entity acquiring us, or a
        division or subsidiary, or into which we are, or a division or subsidiary is,
        merged or the entity that is purchasing our assets.  You acknowledge and agree
        that in the foregoing circumstances, your Personal Information may be disclosed
        to such third-party acquirer or purchaser.</p>

        <p css={tw`mt-10 font-bold capitalize`}>3. ACCESSING AND UPDATING YOUR INFORMATION</p>

        <p css={tw`mt-10`}>You can always choose not to provide certain information to us, although this may
        prevent you from using some of the features of the Services.  Some portions of
        the Services may also enable you to access and update your Personal Information
        by visiting a specific webpage or similar functionality within the Services. 
        On these pages you can update your information as well as manage your email
        preferences.  Please note that not all portions of the Services may offer you
        the ability to update your information and/or manage your email preferences.</p>

        <p css={tw`mt-10`}>If you wish to access or update your Personal Information and are not able to do
        so through the Services, please contact us. You can also ask us whether we are
        keeping information about you and, if so, how it is being used and disclosed,
        including with respect to disclosures made to third parties outside your
        jurisdiction of residence.  To update your Personal Information, to ask us how
        we are keeping information about you, or if located in Mexico, to exercise your
        ARCO rights (by means of which you can access, rectify and cancel your Personal
        Information, oppose to its processing, limit its use or disclosure, or revoke
        the consent you have given us for the processing of your Personal Information),
        please use the addresses in Section 8 entitled CONTACT.</p>

        <p css={tw`mt-10`}>If you are located in Mexico, please include in your privacy request the following
        information: (i) your name and address or other means to notify you of the
        response to your privacy request; (ii) documents establishing your identity or,
        where appropriate, your legal representation; (iii) a clear and precise
        description of the Personal Information for which you seek to exercise any of your
        ARCO rights; (iv) any other item or document that facilitates locating the
        Personal Information; and (v) in the case of privacy requests for rectification
        of Personal Information documentation indicating and supporting the requested
        changes.  In accordance with Mexican privacy laws, we shall respond to a
        privacy request within 20 days from the date of receipt of such privacy
        request, if such request is possible; or if we require additional information
        for such privacy request, we shall respond to an affirmative response from you
        providing such additional information within 15 days from the date of the
        receipt of such affirmative response from you including such additional
        information, such 15 day period may be extended once for an additional period
        of 15 days. </p>

        <p css={tw`mt-10 font-bold capitalize`}>4. DATA SECURITY</p>

        <p css={tw`mt-10`}>We take the protection of your Personal Information seriously. We protect Personal
        Information against loss and theft as well as unauthorized access, disclosure,
        copying, use, and modification using security safeguards, including physical,
        organization and technological measures, commensurate with the sensitivity of
        your Personal Information.  Employees who have access to your information are
        made aware of the importance of keeping it confidential.</p>
        
        <p css={tw`mt-10`}>Where we use service providers who might have access to your Personal Information, we
        require them to have privacy and security standards that are comparable to
        ours. We use contracts and other measures with our service providers to
        maintain the confidentiality and security of your Personal Information and to
        prevent it from being used for any other purpose.</p>

        <p css={tw`mt-10`}>However, since no method of electronic storage is 100% secure, we cannot guarantee the
        absolute security of the information stored.  We also cannot guarantee that
        information you supply will not be intercepted while being transmitted to us over
        the Internet. </p>

        <p css={tw`mt-10`}>We retain Personal Information that we collect only as long as reasonably
        necessary to fulfill the purposes for which it was collected or to meet any
        applicable legal requirements.</p> 

        <p css={tw`mt-10`}>In some instances, you may be required to choose a user ID and password when you
        register for the Services.  To the extent user IDs and passwords are utilized,
        you may not share your user ID and password with anyone else, and you are
        solely responsible for maintaining and protecting the confidentiality of your
        user ID and password.  You are fully responsible for all activities that occur
        through the use of your user ID and password.</p>

        <p css={tw`mt-10 font-bold capitalize`}>5. California DO NOT TRACK DisclosureS</p>

        <p css={tw`mt-10`}>We do not respond to Do Not Track (DNT) signals. </p>
        <a id="California"></a>
        <p css={tw`mt-10 font-bold capitalize`}>6. CALIFORNIA – ACCESS TO PERSONAL INFORMATION</p>

        <p css={tw`mt-10`}>Except
        as otherwise set forth below, upon written request, we shall allow individuals
        to review and request changes to Personal Information collected by us via the
        Services. The individual will need to provide sufficient identifying
        information to us at the address provided below in Section 8 entitled CONTACT.</p>

        <p css={tw`mt-10 font-bold capitalize`}>7. USE BY MINORS</p>

        <p css={tw`mt-10`}>We
        do not intend the Services to be used by individuals under the age of eighteen.
        We also do not knowingly collect any Personal Information about children under
        the age of thirteen.  If we become aware that we have unknowingly collected
        Personal Information from a child under the age of thirteen, we will make
        commercially reasonable efforts to delete such information. </p>

        <p css={tw`mt-10 font-bold capitalize`}>8. CONTACT</p>

        <p css={tw`mt-10`}>You
        have the right to obtain information regarding our policies and practices with
        respect to the collection, use and disclosure of your Personal Information
        including with respect to our use of service providers outside your
        jurisdiction of residence. To obtain such information or for any other
        questions, comments, or concerns about this Policy, please contact us by mail
        or email as follows:</p>

        <p css={tw`mt-10 ml-20`}>Nucor Tubular Products</p>
        <p css={tw`ml-20`}>6226 West 74th Street</p>
        <p css={tw`ml-20`}>Chicago, IL 60638</p>

        <p css={tw`ml-20`}>Attention: Eldrick Tate</p>
        <p css={tw`ml-20`}>1-708-475-4250</p>

        <p css={tw`ml-20`}>eldrick.tate@nucor.com</p>
      </div>
    </div>
  );
};

export default Privacy;
