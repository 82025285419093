/** @jsxImportSource @emotion/react */
import React, { ReactNode, useState } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import AnimatedLoadingSpinner from "../../../../components/atoms/AnimatedLoadingSpinner";
import Loading from "../../../../components/atoms/Loading";
import { selectCurrentCustomerId } from "../../../../store/customer.reducer";
import {
  fetchOpenOrderPartialSoftAllocation,
  fetchOpenOrdersExcel,
} from "../../../../store/shipping.actions";
import OpenOrdersFooter from "./OpenOrdersFooter";
import OpenOrdersTable from "./OpenOrdersTable";
import Order from "./Order";
import TextButton from "../../../../components/atoms/TextButton";
import { ErrorResponse, RequestStatus } from "../../../../types/types";
import Toast from "../../../../components/molecules/Toast";
import { hasAuthority } from "../../../../helpers/hasAuthority";
import { selectCurrentAuthorities } from "../../../../store/user.reducer";
import { numberFormatter } from "../../../../helpers/numberFormat";

type Props = {
  children?: ReactNode;
};

const OpenOrdersContent: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const customerId = useAppSelector(selectCurrentCustomerId);
  const currentAuthorities = useAppSelector(selectCurrentAuthorities);
  const openOrdersTotals = useAppSelector(
    (state) => state.shipping.openOrders.openOrdersTotals
  );
  const {
    status,
    requestType,
    searchTabCriteria,
    searchCriteria,
    selectionData,
  } = useAppSelector((state) => state.shipping.openOrders);

  const [sort, setSort] = useState<{ sort: string; dir: "asc" | "desc" }>({
    sort: "salesOrderNumber,lineNumber",
    dir: "asc",
  });
  const [error, setError] = useState<ErrorResponse | string>();

  const grandTotals = openOrdersTotals?.reduce(
    (acc, curr) => {
      return {
        poundsOrdered: acc.poundsOrdered + curr.poundsOrdered ?? 0,
        poundsReadyToLoad: acc.poundsReadyToLoad + curr.poundsReadyToLoad ?? 0,
        poundsBalance: acc.poundsBalance + curr.poundsBalance ?? 0,
      };
    },
    {
      poundsOrdered: 0,
      poundsReadyToLoad: 0,
      poundsBalance: 0,
    }
  );
  const [excelStatus, setExcelStatus] = useState<RequestStatus>("idle");

  const downloadExcelHandler = () => {
    if (!customerId) return;
    const criteria =
      requestType === "search"
        ? {
            ...searchTabCriteria,
          }
        : {
            ...searchCriteria,
            sites:
              searchCriteria.siteKey === "ALL"
                ? undefined
                : selectionData?.sites.find(
                    (item) => item.key === searchCriteria.siteKey
                  )?.sites,
          };
    setExcelStatus("pending");
    dispatch(fetchOpenOrdersExcel({ customerId, body: criteria }))
      .unwrap()
      .then(() => setExcelStatus("success"))
      .catch((error) => {
        setError(error);
        setExcelStatus("error");
      });
  };

  const downloadPartialAllocationsExcel = () => {
    // Include all is set to false until ready to expose flag
    setExcelStatus("pending");
    dispatch(
      fetchOpenOrderPartialSoftAllocation({
        includeAll: false,
      })
    )
      .unwrap()
      .then(() => setExcelStatus("success"))
      .catch((error) => {
        setError(error);
        setExcelStatus("error");
      });
  };

  return (
    <div css={tw`text-xs`}>
      {error && (
        <Toast
          message={error}
          type="error"
          onConfirm={() => setError(undefined)}
        />
      )}
      <div
        css={tw`flex items-center text-white bg-nucor-green uppercase font-bold py-[3px] px-2`}
      >
        <h3 css={tw`flex-1`}>Open Orders</h3>
        {excelStatus === "pending" && (
          <AnimatedLoadingSpinner colorStyle="light" message="" css={tw`mr-3`}/>
        )}
        {hasAuthority("internal", currentAuthorities) ? (
            <TextButton
            disabled={excelStatus === "pending"}
            onClick={downloadPartialAllocationsExcel}
            type="button"
            css={[tw`mr-6`, excelStatus !== "pending" && tw`text-white`]}
            >
            Partial Bundles Soft Reservation
            </TextButton>

        ) : null}
        <TextButton
          disabled={excelStatus === "pending"}
          onClick={downloadExcelHandler}
          type="button"
          css={excelStatus !== "pending" && tw`text-white`}
        >
          Excel
        </TextButton>
      </div>
      <div css={tw`relative`}>
        <OpenOrdersTable currentSort={sort} onSort={setSort} />
        {status === "pending" && <Loading />}
        {openOrdersTotals?.map((order) => (
          <Order key={order.shipToId} order={order} currentSort={sort} />
        ))}
      </div>
      {openOrdersTotals && openOrdersTotals.length === 0 ? (
        <p css={tw`mt-2 ml-2 text-nucor-gray`}>
          No open orders found for the selected criteria
        </p>
      ) : null}
      {openOrdersTotals && openOrdersTotals.length > 0 ? (
        <>
          <div css={tw`mt-1 flex`}>
            <div css={tw`flex-grow`}>
              &nbsp;
            </div>
            <div css={tw`w-[8%] font-semibold text-nucor-gray text-right`}>
              Grand Totals
            </div>
            <div css={tw`w-[6%] text-right`}>
              {numberFormatter(grandTotals?.poundsOrdered ?? 0)}
            </div>
            <div css={tw`w-[6%] text-right`}>
              {numberFormatter(grandTotals?.poundsBalance ?? 0)}
            </div>
            <div css={tw`w-[6%] text-right`}>
              {numberFormatter(grandTotals?.poundsReadyToLoad ?? 0)}
            </div>
            <div css={tw`w-[4%] text-right`}>
              &nbsp;
            </div>   
          </div>
          <div css={tw`mt-8`}>
            <OpenOrdersFooter />
          </div>
        </>
      ) : null}
    </div>
  );
};

export default OpenOrdersContent;
