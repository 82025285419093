import truck from "../assets/images/icon_truck.png";
import barge from "../assets/images/icon_barge.png";
import rail from "../assets/images/icon_rail.png";

export const deliveryIcon = (mode?: string) => {
  if (!mode) return undefined;
  switch (mode) {
    case "Truck":
      return truck;
    case "Rail":
      return rail;
    case "Barge":
      return barge;
    default:
      return undefined;
  }
};
