/** @jsxImportSource @emotion/react */
import React, { ComponentProps, ReactNode, useState } from "react";
import tw from "twin.macro";
import { inputNumberRestriction } from "../../../../helpers/inputNumberRestriction";

type Props = {
  children?: ReactNode;
  error?: string;
  maxLength?: number;
  restrictToNumber?: boolean;
  highlight?: boolean;
  disabled?: boolean;
} & ComponentProps<"input">;

const InputWithValidation = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      error,
      maxLength,
      restrictToNumber,
      highlight,
      disabled,
      ...remainingProps
    },
    ref
  ) => {
    const [showError, setShowError] = useState(false);

    return (
      <div css={tw`relative flex-1`}>
        {error && showError && (
          <p
            css={tw`absolute z-50 bg-white rounded border border-red-600 text-xs whitespace-nowrap px-2 py-[2px] -top-7 left-1 text-red-600`}
          >
            {error}
          </p>
        )}
        <input
          ref={ref}
          css={[
            tw`w-12`,
            error && tw`border-red-500!`,
            highlight && tw`bg-nucor-yellow`,
            disabled && tw`text-nucor-gray`,
          ]}
          type="text"
          autoComplete="off"
          maxLength={maxLength}
          onKeyDown={restrictToNumber ? inputNumberRestriction : undefined}
          onMouseEnter={() => {
            setShowError(true);
          }}
          onMouseOut={() => setShowError(false)}
          disabled={disabled}
          {...remainingProps}
        />
      </div>
    );
  }
);

InputWithValidation.displayName = "InputWithValidation";
export default InputWithValidation;
