/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import React, { useEffect } from "react";
import AppRouter from "./routing/AppRouter";
import { useAuth0 } from "@auth0/auth0-react";
import { authInterceptor, navigationObservable } from "./app/axios";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import ConfirmationDialog from "./components/molecules/ConfirmationDialog";
import { setShowLogoutDialog } from "./store/user.reducer";
import Header from "./components/organisms/Header";
import Footer from "./components/organisms/Footer";
import { axiosObservable } from "./app/axios";
import { getSiteContactEmail, getVersion } from "./store/app.actions";
import { fetchCustomerSummary } from "./store/customer.actions";
import { selectCurrentCustomerId } from "./store/customer.reducer";
import { usePageTracking } from "./hooks/usePageTracking";
import {
  showPortalOutOfDateAlert,
  dismissPortalOutOfDateAlert,
} from "./store/app.reducer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

function App() {
  const { getAccessTokenSilently, logout } = useAuth0();
  
  const navigate = useNavigate();
  const location = useLocation();

  const showLogoutDialog = useAppSelector(
    (state) => state.user.showLogoutDialog
  );
  const portalOutOfDate = useAppSelector((state) => state.app.portalOutOfDate);
  const dispatch = useAppDispatch();
  const selectedCustomerId = useAppSelector(selectCurrentCustomerId);

  const frontEndVersion = "" + `${process.env.REACT_APP_RUN_NUMBER}`;

  /** Hook used for time spent on page analytics */
  usePageTracking();

  /** On app load, passes getAccessTokenSilently function to
   * custom axios isntance. This function is used within the
   * custom instance to create an interceptor for getting access
   * tokens prior to fetch.
   */
  useEffect(() => {
    authInterceptor(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  useEffect(() => {
    dispatch(getSiteContactEmail());

    const timer = setInterval(() => {
      dispatch(getVersion())
        .unwrap()
        .then((appVersion) => {
          if (frontEndVersion != appVersion.version) {
            dispatch(showPortalOutOfDateAlert());
          }
        });
    }, 1_800_000);

    const logoutFunc = () => logout({ returnTo: window.location.origin });
    axiosObservable.subscribe(logoutFunc);
    navigationObservable.subscribe(navigate);
    return () => {
      axiosObservable.unsubscribe(logoutFunc);
      navigationObservable.unsubscribe(navigate);
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (selectedCustomerId) {
      dispatch(fetchCustomerSummary(selectedCustomerId));
    }
  }, [selectedCustomerId]);

  const dismissOutOfDatePortal = () => {
    dispatch(dismissPortalOutOfDateAlert());
  };

  return (
    <div
      css={[
        tw`flex flex-col min-h-screen relative min-w-[1420px]`,
        ["/500", "/restricted", "/forbidden"].includes(location.pathname) &&
          tw`bg-[#f1f1f1]`,
        location.pathname === "/" && tw`min-w-min`,
      ]}
    >
      <Header />
      <main css={tw`flex-grow flex flex-col items-center`}>
        <AppRouter />
      </main>
      <Footer />
      {showLogoutDialog && (
        <ConfirmationDialog
          title={"Logout?"}
          onCancel={() => dispatch(setShowLogoutDialog(false))}
          onConfirm={() => logout({ returnTo: window.location.origin })}
        >
          Are you sure you want to logout?
        </ConfirmationDialog>
      )}
      {portalOutOfDate && (
        <ConfirmationDialog
          title={"Portal is out of date"}
          onConfirm={dismissOutOfDatePortal}
          confirmText="OK"
          icon={
            <FontAwesomeIcon
              css={tw`text-nucor-light-green`}
              size={"2x"}
              icon={faExclamationCircle}
            />
          }
        >
          Portal Web App is out of date. Please save your work and refresh the
          browser to get the latest code.
        </ConfirmationDialog>
      )}
    </div>
  );
}

export default App;
