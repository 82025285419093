import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { AppState, Auth0Provider } from "@auth0/auth0-react";

type Props = {
  children?: ReactNode;
};

const Auth0ProviderWithHistory: React.FC<Props> = ({ children }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN ?? "";
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID ?? "";

  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState) => {
    const url = appState?.returnTo
      ? `/callback?redirected=${encodeURIComponent(appState.returnTo)}`
      : "/callback";
    navigate(url);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
