import { withAuthenticationRequired } from "@auth0/auth0-react";
import React, { ComponentType, FC } from "react";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "../app/hooks";
import { isAdmin } from "../helpers/isAdmin";
import { selectCurrentAuthorities } from "../store/user.reducer";
import { useAuth0 } from "@auth0/auth0-react";

interface ProtectedRouteProps {
  component: ComponentType;
  adminRoute?: boolean;
}

const alreadyAuthenticated = <P extends object>(Component: ComponentType<P>): FC<P> => {
  return function AlreadyAuthenticated(props: P): JSX.Element {
    return <Component {...props} />;
  };
};

export const ProtectedRoute: React.FC<ProtectedRouteProps> = (props) => {
  const { isAuthenticated } = useAuth0();
  const authorities = useAppSelector(selectCurrentAuthorities);
  const portalUser = useAppSelector((state) => state.user.currentContext?.user);

  if (isAuthenticated) {
    if (!portalUser) {
      const redirect = "/callback?redirected=" + encodeURIComponent(window.location.pathname + window.location.search);
      return <Navigate to={redirect}/>;
    } else {
      if (props.adminRoute && !isAdmin(authorities)) {
        return <Navigate to="/portal/home" />;
      } else {
        const Authenticated = alreadyAuthenticated(props.component);
        return <Authenticated />;
      }
    }
  } else {
    const Component = withAuthenticationRequired(props.component, {
      onRedirecting: () => <div>Redirecting...</div>,
    });

    return <Component />;
  }
  
};