/** @jsxImportSource @emotion/react */
import React, { ComponentProps, ReactNode } from "react";
import tw from "twin.macro";
import AnimatedSpinner from "./AnimatedSpinner";

type Props = {
  children?: ReactNode;
} & ComponentProps<"div">;

const Loading: React.FC<Props> = ({ children, ...remainingProps }) => {
  return (
    <>
      <div
        css={[
          tw`bg-gray-100 absolute left-0 top-0 w-full h-full opacity-50 z-20`,
        ]}
        {...remainingProps}
      />
      <div
        css={[
          tw`absolute left-0 top-0 w-full h-full z-30 flex justify-center items-center`,
        ]}
      >
        <div
          css={tw`bg-white border-2 text-nucor-green font-semibold border-nucor-green px-3 py-1 text-sm leading-tight rounded flex gap-2`}
        >
          <AnimatedSpinner className="mt-[1px]" />
          {(children && children) ?? <span>Loading</span>}
        </div>
      </div>
    </>
  );
};

export default Loading;
