/** @jsxImportSource @emotion/react */
import React, { ReactNode, useState } from "react";
import tw from "twin.macro";
import Modal from 'react-modal';
import { useAppDispatch } from "../../../../app/hooks";
import Button from "../../../../components/atoms/Button";
import { updateQuickQuotePrices } from "../../../../store/document.actions";
import { ErrorResponse, QQPriceUpdateParams } from "../../../../types/types";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Toast from "../../../../components/molecules/Toast";
import { clearDocumentErrors } from "../../../../store/document.reducer";
import TextButton from "../../../../components/atoms/TextButton";

type Props = {
  children?: ReactNode;
  selectedQuote: number;
  matchingUOM: boolean;
};

const ChangeAllPrices: React.FC<Props> = ({ selectedQuote, matchingUOM }) => {
    Modal.setAppElement('#root');
    const [modalOpen, setModalOpen] = React.useState(false);
    const [selectedUnitPrice, setSelectedUnitPrice] = React.useState("");
    const [error, setError] = useState<string | ErrorResponse>();
    const dispatch = useAppDispatch();

    const params: QQPriceUpdateParams = {
        quoteId: selectedQuote,
        unitPrice: selectedUnitPrice
      };

    const submitPriceChange = () =>     {
        if (isNaN(Number(selectedUnitPrice)) || Number(selectedUnitPrice) === 0) {
            setError("Invalid Price");
            return
        }
        dispatch(updateQuickQuotePrices(params))
        .unwrap()
        .then(() => {
            setModalOpen(false);
        })
        .catch((error: React.SetStateAction<string | ErrorResponse | undefined>) => {
          setError(error);
          setModalOpen(false);
        });
    }
    const clearErrors = () => {
        dispatch(clearDocumentErrors());
        setError("");
    }

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          padding: '0px 0px 5px 0px',
          transform: 'translate(-50%, -50%)',
          width: matchingUOM ? '225' : '555px',
          backgroundColor: 'rgb(11 99 3)',
        },
        overlay: {
          zIndex: 11
        },
      };

  return (
    <>
        {error && (
            <Toast
            type="error"
            message={error}
            onConfirm={() => clearErrors()}
            />
        )}

        <TextButton
            onClick={() => {
                setSelectedUnitPrice("");
                setModalOpen(true);
            }}
            css={tw`ml-2 text-xs`}
        >
            Change All Prices
        </TextButton>

        <Modal
                isOpen={modalOpen}
                onRequestClose={() => setModalOpen(false)}
                style={customStyles}
                contentLabel="Example Modal"
            >

                <div css={tw`flex justify-between ml-1 mr-1 mt-1`}>
                    <h2 css={tw`font-bold text-white pb-1 text-xs flex-1`}>
                        Change All Prices
                    </h2>
                    <button
                        type="button"
                        onClick={() => setModalOpen(false)}
                        css={tw`text-nucor-green bg-[#ccc] h-[13px] w-[13px] flex justify-center items-center font-bold mt-px`}
                    >
                        &times;
                    </button>
                </div>
                <div css={tw`bg-[#ffffff] ml-1 mr-1`}>
                    {!matchingUOM ? (
                        <>
                            <div css={tw`ml-7 flex pt-4 text-xs font-bold text-nucor-gray`}>
                                <FontAwesomeIcon
                                    css={tw`text-orange-500 pr-2`}
                                    size={"2x"}
                                    icon={faExclamationTriangle}
                                />
                                Cannot change all the prices because the price units do not match.
                            </div>
                            <div css={tw`ml-7 mt-2 flex pt-4 text-xs font-bold text-nucor-gray pb-4 justify-center`}>
                                <Button
                                    css={[tw`text-xs text-white py-1 font-medium m-0 min-w-[12ch]`]}
                                    type="button"
                                    onClick={() => setModalOpen(false)}
                                    >
                                    Ok
                                </Button>  
                            </div>
                        </>
                    ) : (
                        <>
                            <div css={tw`ml-1 flex items-center pt-1 text-xs`}>
                                <div>Change By:$<span css={tw`text-red-600`}>*</span></div>
                                <input
                                    css={tw`w-16 mr-2 ml-2 bg-nucor-yellow`}
                                    name="unitPrice"
                                    type="number" 
                                    value={selectedUnitPrice} 
                                    onChange={(e) => setSelectedUnitPrice(e.target.value)}
                                    />
                                <span css={tw`mr-2`}>CWT</span>
                            </div>
                            <div css={[tw`flex gap-2 pt-2 pb-2`]}>
                            <Button
                                css={[tw`ml-2 text-xs text-white py-1 m-0 min-w-[12ch]`]}
                                type="button"
                                disabled={selectedUnitPrice === ""}
                                onClick={submitPriceChange}
                                >
                                Change
                                </Button>
                                <Button
                                    css={[tw`text-xs text-white py-1 m-0 min-w-[12ch]`]}
                                    type="button"
                                    onClick={() => setModalOpen(false)}
                                    >
                                    Cancel
                                </Button>  
                            </div>
                        </>
                    )}
                </div>     
          </Modal>
    </>
  );
};

export default ChangeAllPrices;