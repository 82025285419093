/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Home from "./home/Home";
import MyDocuments from "./mydocuments/MyDocuments";
import MyShipping from "./myshipping/MyShipping";
import Stock from "./stock/Stock";
import UserInfo from "./userinfo/UserInfo";
import Resources from "./resources/Resources";
import NavTabs, { NavMenuType } from "../../components/organisms/NavTabs";
import NotFound from "../app/NotFound";

type Props = {
  children?: ReactNode;
};
const navmenu: NavMenuType = [
  { title: "Home", to: "home", showCustId: true },
  { title: "Stock", to: "stock", showCustId: true },
  {
    title: "My Shipping",
    to: "myshipping",
    showCustId: true,
    authority: ["createOrderQuoteLoad", "viewShippingDocs"],
  },
  {
    title: "My Documents",
    to: "mydocuments",
    showCustId: true,
    authority: ["viewOrderInvoice", "viewShippingDocs", "viewPoSearch"],
  },
  {title: "Resources", to: "resources", showCustId: true}
];

const Portal: React.FC<Props> = () => {
  const location = useLocation();

  return (
    <>
      <NavTabs navmenu={navmenu} />
      <Routes>
        <Route
          path=""
          element={
            <Navigate to={{ pathname: "home", search: location.search }} />
          }
        />
        <Route path="home/*" element={<Home />} />
        <Route path="stock/*" element={<Stock />} />
        <Route path="myshipping/*" element={<MyShipping />} />
        <Route path="mydocuments/*" element={<MyDocuments />} />
        <Route path="userinfo/*" element={<UserInfo />} />
        <Route path="resources/*" element={<Resources />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default Portal;
