/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import tw from "twin.macro";
import { RobinHoodResults } from "../../../../types/types";
import { numberFormatter } from "../../../../helpers/numberFormat";

type Props = {
  children?: ReactNode;
  results?: RobinHoodResults | null;
};

const D365ResultsList: React.FC<Props> = ({ results }) => {
  return (
    <div css={tw`mt-4 text-xs`}>
      {
        <>
          <table className="ntp-portal-table" css={tw`w-full`}>
            <thead>
              <tr>
                <th css={tw`w-[6%]`}>Item</th>
                <th css={tw`w-[15%]`}>Product</th>
                <th css={tw`w-[6%]`}>Site</th>
                <th css={tw`w-[6%]`}>Inv Status</th>
                <th css={tw`w-[6%]`}>Length</th>
                <th css={tw`w-[6%]`}>Available</th>
                <th css={tw`w-[6%]`}>Reserved</th>
                <th css={tw`w-[6%]`}>Hold</th>
                <th css={tw`w-[6%]`}>Total Physical</th>
                <th css={tw`w-[6%]`}>Std Bdl Size</th>
                <th css={tw`w-[20%]`}>Available Bundle Details</th>
                <th css={tw`w-[20%]`}>All Bundle Details</th>

              </tr>
            </thead>
         
            <tbody>
              {results?.softReservationList.length && results.softReservationList.length > 0 ? (
                results?.softReservationList.map((item, index) => {
                  const productName = results.matchingProductMap[item.itemNumber]?.name || '';
                  return (
                    <tr key={index}>
                      <td css={tw`w-[6%]`}>{item?.itemNumber}</td>
                      <td css={tw`w-[15%]`}>{productName}</td>
                      <td css={tw`w-[6%]`}>{item?.site}</td>
                      <td css={tw`w-[6%]`}>{item?.inventoryStatus}</td>
                      <td css={tw`w-[6%] text-right`}>{item?.lengthDescription}</td>
                      <td css={tw`w-[6%] text-right`}>
                        {item.availableInventoryPieces && item.availableInventoryPieces !== 0
                          ? numberFormatter(item.availableInventoryPieces)
                          : null}
                      </td>
                      <td css={tw`w-[6%] text-right`}>
                        {item.reservedInventoryPieces && item.reservedInventoryPieces !== 0
                          ? numberFormatter(item.reservedInventoryPieces)
                          : null}
                      </td>
                      <td css={tw`w-[6%] text-right`}>
                        {item.onHoldPieces && item.onHoldPieces !== 0
                          ? numberFormatter(item.onHoldPieces)
                          : null}
                      </td>
                      <td css={tw`w-[6%] text-right`}>
                        {item.totalPhysicalInventoryPieces && item.totalPhysicalInventoryPieces !== 0
                          ? numberFormatter(item.totalPhysicalInventoryPieces)
                          : null}
                      </td>
                      <td css={tw`w-[6%] text-right`}>{item?.standardBundleSize}</td>
                      <td css={tw`w-[20%]`}>{item?.availableBundlesDetails}</td>
                      <td css={tw`w-[20%]`}>{item?.allBundlesDetails}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td css={tw`text-nucor-gray !px-2 !py-3 hover:!bg-white`} colSpan={12}>
                    No results for the selected criteria.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </>
      }
    </div>
  );
};

export default D365ResultsList;
