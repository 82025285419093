/** @jsxImportSource @emotion/react */
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ComponentProps, ReactNode } from "react";
import { createPortal } from "react-dom";
import tw from "twin.macro";
import Button from "../../../../../components/atoms/Button";

type Props = {
  children?: ReactNode;
  onCancel: () => void;
  onConfirm: () => void;
} & ComponentProps<"div">;

const ChangeShipToDialog: React.FC<Props> = ({
  onConfirm,
  onCancel,
  ...remainingProps
}) => {
  return createPortal(
    <div
      onClick={onCancel}
      css={[
        tw`fixed top-0 left-0 w-full h-full flex justify-center items-center bg-[#CCCCCC77] z-50`,
      ]}
      {...remainingProps}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        css={tw`max-w-[60ch] border border-[3px] rounded-lg border-nucor-green bg-white p-2 text-xs`}
      >
        <h3 css={tw`font-bold text-nucor-green mb-2`}>Change Ship To?</h3>
        <div css={tw`flex gap-4 mb-4`}>
          <div css={tw`min-w-[5ch] text-right`}>
            <FontAwesomeIcon
              css={tw`text-nucor-green`}
              size={"2x"}
              icon={faQuestionCircle}
            />
          </div>
          <div>
            <p>
              The listed product prices are for the selected Ship To location. A
              change to this location can affect the price on items in your
              shopping cart.
            </p>
            <p css={tw`mt-2`}>Are you sure you want to proceed?</p>
          </div>
        </div>
        <div css={tw`flex justify-center gap-2`}>
          <Button onClick={onConfirm} css={tw`my-0 py-1 text-xs font-semibold`}>
            Yes
          </Button>
          <Button onClick={onCancel} css={tw`my-0 py-1 text-xs font-semibold`}>
            No
          </Button>
        </div>
      </div>
    </div>,
    document.getElementById("modal") as HTMLElement
  );
};

export default ChangeShipToDialog;
