/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import tw from "twin.macro";
import { useAppSelector } from "../../../../app/hooks";
import TextButton from "../../../../components/atoms/TextButton";
import { numberFormatter } from "../../../../helpers/numberFormat";
import { InventoryBundleOrderableItem } from "../../../../types/types";
import { selectCurrentAuthorities } from "../../../../store/user.reducer";
import { hasAuthority } from "../../../../helpers/hasAuthority";

type Props = {
  children?: ReactNode;
  item?: InventoryBundleOrderableItem;
  onAdd: (bundle?: InventoryBundleOrderableItem) => void;
  onEdit: (bundle?: InventoryBundleOrderableItem) => void;
  onRemove: (bundle?: InventoryBundleOrderableItem) => void;
  showActions?: boolean;
};

const SecondaryListItem: React.FC<Props> = ({
  item,
  onAdd,
  onEdit,
  onRemove,
  showActions,
}) => {
  const hasSalesOrderItem = item?.salesOrderItem !== undefined;
  const status = useAppSelector((state) => state.shoppingCart.status);
  const currentAuthorities = useAppSelector(selectCurrentAuthorities);

  return (
    <tr>
      <td>{item?.bundle.productName}</td>
      <td css={tw`text-right`}>{item?.bundle.lengthDescription}</td>
      <td>{item?.bundle.site}</td>
      <td>{item?.bundle.grade}</td>
      <td css={tw`text-right`}>{numberFormatter(item?.bundle.weight)}</td>
      <td css={tw`text-right`}>{item?.bundle.bundleSize}</td>
      <td>{item?.bundle.tagNumber}</td>
      <td>{item?.bundle.d365InventoryStatus}</td>
      <td>{item?.bundle.d365InventoryStatusDescription}</td>
      <td>{item?.bundle.tagRemarks}</td>
      {hasAuthority("createOrderQuoteLoad", currentAuthorities) && (
        <>
          {!hasSalesOrderItem && (
            <td className="text-center">
              {showActions && (
                <TextButton
                  disabled={status === "pending"}
                  onClick={() => onAdd(item)}
                >
                  Add
                </TextButton>
              )}
            </td>
          )}
          {hasSalesOrderItem && (
            <td className="text-center">
              {showActions && (
                <>
                  <TextButton
                    disabled={status === "pending"}
                    css={tw`mr-2`}
                    onClick={() => onEdit(item)}
                  >
                    Edit
                  </TextButton>
                  <TextButton
                    disabled={status === "pending"}
                    onClick={() => onRemove(item)}
                  >
                    Remove
                  </TextButton>
                </>
              )}
            </td>
          )}
        </>
      )}
    </tr>
  );
};

export default SecondaryListItem;
