/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import React from "react";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { selectCurrentCustomerSummary } from "../../store/customer.reducer";
import { numberFormatter } from "../../helpers/numberFormat";
import { Authority } from "../../types/types";
import { selectCurrentAuthorities } from "../../store/user.reducer";
import { hasAuthority } from "../../helpers/hasAuthority";
import { formatDateToShort } from "../../helpers/dateHelpers";

export type NavMenuType = {
  title: string;
  to: string;
  showCustId?: boolean;
  authority?: Authority[];
}[];

type Props = {
  navmenu: NavMenuType;
  adminMenu?: boolean;
};

const NavTabs: React.FC<Props> = ({ navmenu, adminMenu }) => {
  const user = useAppSelector((state) => state.user.currentContext?.user);
  const tierLabel = useAppSelector(
    (state) => state.user.currentContext?.tierLabel
  );
  const customerId = useAppSelector(
    (state) => state.customer.currentCustomer?.id
  );
  const summary = useAppSelector(selectCurrentCustomerSummary);
  const currentAuthorities = useAppSelector(selectCurrentAuthorities);

  return (
    <div css={tw`grid grid-cols-12 w-full pl-4`}>
      <div
        css={[
          tw`uppercase list-none flex w-full items-center font-bold col-span-6 text-[13px]`,
          adminMenu && tw`col-span-3`,
        ]}
      >
        {navmenu?.map((item, index) => {
          if (
            item.authority &&
            !hasAuthority(item.authority, currentAuthorities)
          )
            return null;
          return (
            <NavLink
              style={({ isActive }) => ({
                background: isActive ? "#ddd" : undefined,
                color: isActive ? "#0B6325" : "#212529",
              })}
              key={index}
              css={[
                tw`relative p-[15px] hover:(bg-gray-200 text-nucor-green) focus-visible:outline-1 focus-visible:outline-dotted focus-visible:-outline-offset-4 focus-visible:outline-nucor-gray focus:outline-none`,
              ]}
              to={
                item.showCustId && customerId
                  ? item?.to + `?customer_id=${customerId}`
                  : item?.to
              }
            >
              {item?.title}
            </NavLink>
          );
        })}
      </div>
      <div
        css={[
          tw`flex justify-center items-center text-center col-span-2 text-nucor-light-green font-bold`,
          adminMenu && tw`col-span-7`,
        ]}
      >
        {tierLabel}
      </div>
      {!adminMenu && (
        <div
          css={tw`flex flex-col text-xs justify-center items-start px-4 col-span-2`}
        >
          {summary && (
            <>
              <p css={tw`font-bold`}>{`${numberFormatter(
                summary.readyToShipWeightLbs
              )} lbs ready to ship`}</p>
              <p>
                {summary.lastShipmentDate
                  ? `Last shipment ${formatDateToShort(
                      summary.lastShipmentDate
                    )}
              `
                  : ""}
              </p>
            </>
          )}
        </div>
      )}
      <div
        css={tw`flex justify-center items-center col-span-2 bg-[#6c757d] text-white text-sm`}
      >
        {user && `${user?.firstName} ${user?.lastName}`}
      </div>
    </div>
  );
};

export default NavTabs;
