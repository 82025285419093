/** @jsxImportSource @emotion/react */
import React, { ReactNode, useContext, useState } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Loading from "../../../../components/atoms/Loading";
import TextButton from "../../../../components/atoms/TextButton";
import Pagination, { PaginationValues } from "../../../../components/molecules/PaginationComponent";
import TableSortButton from "../../../../components/molecules/TableSortButton";
import { numberFormatter } from "../../../../helpers/numberFormat";
import {
  exportPackingSlipPDF,
  ExportPackingSlipPDFRequest,
  exportTestCertPDF,
  ExportTestCertPDFRequest,
  fetchCombinedInvoicePDF,
  fetchCombinedTestCertPDF,
  FetchCombinedTestCertPDFRequest,
  fetchInvoiceDocumentDetailPDF,
  FetchInvoiceDocumentDetailPDFRequest,
  fetchOpenLoadPDF,
  FetchOpenLoadPDFRequest,
  searchPackingSlips,
} from "../../../../store/document.actions";
import {
  packingSlipSort,
  updatePackingSlipPagination,
} from "../../../../store/document.reducer";
import Toast from "../../../../components/molecules/Toast";
import { formatDateToShort } from "../../../../helpers/dateHelpers";
import { selectCurrentAuthorities } from "../../../../store/user.reducer";
import { hasAuthority } from "../../../../helpers/hasAuthority";
import { PDFContext } from "./BillsOfLading";
import { selectCurrentCustomerId } from "../../../../store/customer.reducer";
import { getNewSort } from "../../../../helpers/getNewSort";

type Props = {
  children?: ReactNode;
};

const BillsOfLadingResults: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const currentAuthorities = useAppSelector(selectCurrentAuthorities);
  const { packingSlipResults, status, searchCriteria } = useAppSelector(
    (state) => state.document.packingSlip
  );

  const customerId = useAppSelector(selectCurrentCustomerId);

  const ctx = useContext(PDFContext);

  const [psStatus, setPsStatus] = useState("idle");
  const [invoiceStatus, setInvoiceStatus] = useState("idle");
  const [testCertStatus, setTestCertStatus] = useState("idle");
  const [combinedStatus, setCombinedStatus] = useState("idle");
  const [loadStatus, setLoadStatus] = useState("idle");
  const [errorMessage, setErrorMessage] = useState("");

  const loading = (status === "pending") || (psStatus === "pending") || (invoiceStatus === "pending") || (testCertStatus === "pending") 
    || (combinedStatus === "pending") || (loadStatus === "pending");

  const packingSlipPDFHandler = (params: ExportPackingSlipPDFRequest) => {
    setPsStatus("pending");
    setErrorMessage("");
    dispatch(exportPackingSlipPDF(params))
      .unwrap()
      .then((result) => {
        ctx?.setFile(result);
        setPsStatus("success");
      })
      .catch((error) => {
        setPsStatus("error");
        setErrorMessage(error);
      });
  };

  const invoicePDFHandler = (params: FetchInvoiceDocumentDetailPDFRequest) => {
    setInvoiceStatus("pending");
    setErrorMessage("");
    dispatch(fetchInvoiceDocumentDetailPDF(params))
      .unwrap()
      .then((result) => {
        ctx?.setFile(result);
        setInvoiceStatus("success");
      })
      .catch((error) => {
        setInvoiceStatus("error");
        setErrorMessage(error);
      });
  };

  const testCertPDFHandler = (params: ExportTestCertPDFRequest) => {
    setTestCertStatus("pending");
    setErrorMessage("");
    dispatch(exportTestCertPDF(params))
      .unwrap()
      .then((result) => {
        ctx?.setFile(result);
        setTestCertStatus("success");
      })
      .catch((error) => {
        setTestCertStatus("error");
        setErrorMessage(error);
      });
  };

  const combinedPDFHandler = (params: FetchCombinedTestCertPDFRequest) => {
    setCombinedStatus("pending");
    setErrorMessage("");
    dispatch(fetchCombinedTestCertPDF(params))
      .unwrap()
      .then((result) => {
        ctx?.setFile(result);
        setCombinedStatus("success");
      })
      .catch((error) => {
        setCombinedStatus("error");
        setErrorMessage(error);
      });
  };

  const combinedInvoicePDFHandler = (params: FetchCombinedTestCertPDFRequest) => {
    setCombinedStatus("pending");
    setErrorMessage("");
    dispatch(fetchCombinedInvoicePDF(params))
      .unwrap()
      .then((result) => {
        ctx?.setFile(result);
        setCombinedStatus("success");
      })
      .catch((error) => {
        setCombinedStatus("error");
        setErrorMessage(error);
      });
  };

  const loadPDFHandler = async (params: FetchOpenLoadPDFRequest) => {
    setErrorMessage("");
    setLoadStatus("pending");
    dispatch(fetchOpenLoadPDF(params))
      .unwrap()
      .then((result) => {
        ctx?.setFile(result);
        setLoadStatus("success");
      })
      .catch((error) => {
        setLoadStatus("error");
        setErrorMessage(error);
      });
  };

  const sortChanged = (field: string) => {
    if (!customerId || !packingSlipResults?.lastCriteria) return;
    
    const searchSort = getNewSort(field, searchCriteria);

    const c = {...packingSlipResults.lastCriteria, ...searchSort, page: searchCriteria.page};

    dispatch(packingSlipSort(searchSort))
    dispatch(searchPackingSlips({customerId, criteria: c}));
  };

  const pageChanged = (page: PaginationValues) => {
    if (!customerId || !packingSlipResults?.lastCriteria) return;
    
    const c = {...packingSlipResults.lastCriteria, ...page};

    dispatch(updatePackingSlipPagination(page))
    dispatch(searchPackingSlips({customerId, criteria: c}));
  };

  return (
    <div css={tw`relative`}>
      {loading && <Loading />}
      {errorMessage && (
        <Toast
          type="error"
          message={errorMessage}
          onConfirm={() => setErrorMessage("")}
        />
      )}
      <Pagination
        currentPage={searchCriteria.page}
        items={packingSlipResults?.count ?? 0}
        isLoading={status === "pending"}
        onChange={(value) => pageChanged(value)}
      />
      <table className="ntp-portal-table ntp-shrink-y-padding" css={tw`w-full text-xs`}>
        <thead>
          <tr>
            <th>
              <TableSortButton
                onClick={(field) => sortChanged(field)}
                sort={searchCriteria}
                field="billingCustomerId"
              >
                Cus Id
              </TableSortButton>
            </th>
            <th>Ship To</th>
            <th>
              <TableSortButton
                onClick={(field) => sortChanged(field)}
                sort={searchCriteria}
                field="shipDate"
              >
                Ship
                <br />
                Date
              </TableSortButton>
            </th>
            <th>
              <TableSortButton
                onClick={(field) => sortChanged(field)}
                sort={searchCriteria}
                field="weightLbs"
              >
                Total
                <br />
                Weight
              </TableSortButton>
            </th>
            <th>
              <TableSortButton
                onClick={(field) => sortChanged(field)}
                sort={searchCriteria}
                field="loadNumber"
              >
                Load
                <br />
                Number
              </TableSortButton>
            </th>
            <th>
              <TableSortButton
                onClick={(field) => sortChanged(field)}
                sort={searchCriteria}
                field="proBillNumber"
              >
                Release/Pro Bill
              </TableSortButton>
            </th>
            <th>
              <TableSortButton
                onClick={(field) => sortChanged(field)}
                sort={searchCriteria}
                field="transportType"
              >
                Transport
                <br />
                Type
              </TableSortButton>
            </th>
            <th>Bills of Lading</th>
            <th>
              <TableSortButton
                onClick={(field) => sortChanged(field)}
                sort={searchCriteria}
                field="site"
              >
                Mill
              </TableSortButton>
            </th>
            <th>Invoices</th>
            <th>Test Certs</th>
            <th>View PDFs</th>
          </tr>
        </thead>
        <tbody>
          {packingSlipResults?.list && packingSlipResults.list.length > 0 ? (
            packingSlipResults.list.map((item, index) => (
              <tr key={index}>
                <td>{item.billingCustomerId}</td>
                <td>
                  <ul>
                    {item.shippingCustomerLabelList?.map((label, index) => (
                      <li key={index}>{label}</li>
                    ))}
                  </ul>
                </td>
                <td>
                  {formatDateToShort(item.shipDate)}
                </td>
                <td css={tw`text-right!`}>
                  {numberFormatter(item.weightLbs)}
                </td>
                <td>
                  <TextButton
                    disabled={loading}
                    onClick={() =>
                      loadPDFHandler({
                        customerId: item.billingCustomerId,
                        id: item.loadNumber,
                      })
                    }
                  >
                    {item.loadNumber}
                  </TextButton>
                </td>
                <td>{item.proBillNumber}</td>
                <td>{item.transportType}</td>
                <td>
                  <TextButton
                    disabled={loading}
                    onClick={() =>
                      packingSlipPDFHandler({
                        customerId: item.billingCustomerId,
                        id: item.packingSlipNumber,
                      })
                    }
                  >
                    {item.packingSlipNumber}
                  </TextButton>
                </td>
                <td>{item.site}</td>
                <td>
                  {item.invoiceNumbers.map((invoice, index) => (
                    <TextButton
                      disabled={
                        loading ||
                        !hasAuthority("viewOrderInvoice", currentAuthorities)
                      }
                      onClick={() => {
                        invoicePDFHandler({
                          customerId: item.billingCustomerId,
                          id: invoice,
                        });
                      }}
                      css={tw`block`}
                      key={index}
                    >
                      {invoice}
                    </TextButton>
                  ))}
                </td>
                <td>
                  {item.testCertNumbers.map((testCert, index) => (
                    <TextButton
                      disabled={loading}
                      onClick={() => {
                        testCertPDFHandler({
                          customerId: item.billingCustomerId,
                          id: testCert,
                        });
                      }}
                      css={tw`block`}
                      key={index}
                    >
                      {testCert}
                    </TextButton>
                  ))}
                </td>
                <td>
                  <TextButton
                    css={tw`block`}
                    disabled={loading}
                    onClick={() => {
                      combinedPDFHandler({
                        customerId: item.billingCustomerId,
                        id: item.packingSlipNumber,
                      });
                    }}
                  >
                    Test Certs
                  </TextButton>
                  {item.invoiceNumbers && item.invoiceNumbers.length > 0 && (
                    <TextButton
                      css={tw`block`}
                      disabled={loading}
                      onClick={() => {
                        combinedInvoicePDFHandler({
                          customerId: item.billingCustomerId,
                          id: item.packingSlipNumber,
                        });
                      }}
                    >
                      Invoices
                    </TextButton>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td css={tw`text-nucor-gray !px-2 !py-3 hover:!bg-white`} colSpan={12}>
              No bills of lading found for the selected criteria
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default BillsOfLadingResults;
