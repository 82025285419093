import React from "react";
import { useAppSelector } from "../app/hooks";
import { selectCurrentAuthorities } from "../store/user.reducer";
import { Authority } from "../types/types";
import { Navigate, Outlet } from "react-router-dom";
import { hasAuthority } from "../helpers/hasAuthority";

type Props = {
  authority: Authority[];
};

const AuthorityRoutes: React.FC<Props> = ({ authority }) => {
  const currentUserAuthorities = useAppSelector(selectCurrentAuthorities);

  return hasAuthority(authority, currentUserAuthorities) ? (
    <Outlet />
  ) : (
    <Navigate to="/portal/home" />
  );
};

export default AuthorityRoutes;
