/** @jsxImportSource @emotion/react */
import { useAuth0 } from "@auth0/auth0-react";
import React, { ReactNode } from "react";
import tw from "twin.macro";
import AuthNav from "./AuthNav";
import { useLocation } from "react-router-dom";

type Props = {
  children?: ReactNode;
};

const Header: React.FC<Props> = () => {
  const { isAuthenticated } = useAuth0();
  const { pathname } = useLocation();
  return pathname.startsWith("/terms") ||
    pathname.startsWith("/privacy") ? null : (
    <div css={tw`bg-nucor-black px-4 flex items-center justify-between`}>
      <a className="ntp-header-logo" href="https://www.nucortubular.com" target="_blank" rel="noreferrer">
        <img css={tw`p-2`} src="/ntp-nucor-logo.png" alt="" />
      </a>
      {isAuthenticated && <AuthNav />}
    </div>
  );
};

export default Header;
