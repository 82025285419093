/** @jsxImportSource @emotion/react */
import React, { ReactNode, useContext, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import ButtonSelect from "../../../../components/atoms/ButtonSelect";
import Select from "../../../../components/atoms/Select";
import TabGroupSubGroup from "../../../../components/atoms/TabGroupSubGroup";
import { selectCurrentCustomerId } from "../../../../store/customer.reducer";
import {
  clearDocumentErrors,
  selectOpenLoadsSelectionData,
  updateOpenLoadsSearchCriteria,
} from "../../../../store/document.reducer";
import { searchOpenLoads } from "../../../../store/document.actions";
import Toast from "../../../../components/molecules/Toast";
import { PDFContext } from "./OpenLoads";
import { SearchCriteria } from "../../../../types/types";

type Props = {
  children?: ReactNode;
};

type OpenLoadsBrowseCriteria = {
  myCustomers?: boolean;
  shipToCustomerId?: string;
  siteKey?: string;
  sites?: string[];
};

const OpenLoadsBrowse: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const customerId = useAppSelector(selectCurrentCustomerId);
  const openLoadsSelectionData = useAppSelector(selectOpenLoadsSelectionData);
  const { searchCriteria, error } = useAppSelector(
    (state) => state.document.openLoads
  );
  const ctx = useContext(PDFContext);

  const getSites = (siteKey: string | undefined) : string[] | undefined => {
    if (!siteKey || !openLoadsSelectionData) {
      return undefined;
    }
    return siteKey === "ALL" ? undefined : openLoadsSelectionData.sites.find((item) => item.key === siteKey)?.sites;
  };

  const getBrowseCriteria = () : OpenLoadsBrowseCriteria  => {
    const myCustomers = searchCriteria.myCustomers ?? false;
    const shipToCustomerId = searchCriteria.shipToCustomerId ?? "all";
    const siteKey = searchCriteria.siteKey ?? "ALL"
  
    const criteria = {myCustomers, shipToCustomerId, siteKey};
  
    return criteria;
  };

  const getQueryCriteria = (browseCriteria: OpenLoadsBrowseCriteria) : SearchCriteria => {
    const {myCustomers, shipToCustomerId, siteKey} = browseCriteria;
    const criteria = {
      ...searchCriteria,
      myCustomers: myCustomers,
      shipToCustomerId:
        shipToCustomerId === "all" || myCustomers
          ? undefined
          : shipToCustomerId,
      siteKey: siteKey,
      sites: getSites(siteKey)
    };
    return criteria;
  };

  const browseCriteria = getBrowseCriteria();
  const {myCustomers, shipToCustomerId, siteKey} = browseCriteria;
  const criteria = getQueryCriteria(browseCriteria);

  const shipTos =
    openLoadsSelectionData?.shipTos.map((shipTo) => {
      return {
        value: shipTo.id,
        label: <span>{`${shipTo.id} - ${shipTo.name}`}</span>,
      };
    }) ?? [];

    const search = (criteria: SearchCriteria) => {
      dispatch(updateOpenLoadsSearchCriteria({ ...criteria, page: 1}));
      dispatch(searchOpenLoads({customerId, criteria: { ...criteria, siteKey: undefined }}));
    };

    useEffect(() => {
      if (openLoadsSelectionData) {
        const criteria = getQueryCriteria(getBrowseCriteria());
        search(criteria);
      }
    }, [openLoadsSelectionData]);
    
    const onSelectionsChanged = (criteria: OpenLoadsBrowseCriteria) => {
      // Close pdf if open
      if (ctx?.file) {
        ctx?.setFile(null);
      }
      search(criteria);
    };

  return (
    <>
      {error && (
        <Toast
          type="error"
          message={error}
          onConfirm={() => dispatch(clearDocumentErrors())}
        />
      )}
      <TabGroupSubGroup title="Show">
        <Select
          minWidth="22ch"
          name="showBy"
          data={openLoadsSelectionData?.customerSearchType.map((item) => ({
            value: item.id,
            label: item.description,
          }))}
          onChange={(value: boolean) => {
            onSelectionsChanged({
              ...criteria,
              myCustomers: value,
            });
          }}
          value={myCustomers}
        />
      </TabGroupSubGroup>
      {!myCustomers && (
        <TabGroupSubGroup title="By Ship To">
          <Select
            minWidth="22ch"
            maxWidth="22ch"
            name="byShipTo"
            data={[{ value: "all", label: "All" }, ...shipTos]}
            onChange={(value: string) => {
              onSelectionsChanged({
                ...criteria,
                shipToCustomerId: value === "all" ? undefined : value,
              });
            }}
            value={shipToCustomerId}
          />
        </TabGroupSubGroup>
      )}
      <TabGroupSubGroup title="By Mill">
        <ButtonSelect
          isActive={siteKey === "ALL"}
          onClick={() => {
            onSelectionsChanged({
              ...criteria,
              siteKey: "ALL",
              sites: undefined,
            });
          }}
        >
          All
        </ButtonSelect>
        {openLoadsSelectionData?.sites.map((item, index) => {
          return (
            <ButtonSelect
              isActive={siteKey === item.key}
              onClick={() => {
                onSelectionsChanged({
                  ...criteria,
                  siteKey: item.key,
                  sites: getSites(item.key),
                });
              }}
              key={index}
            >
              {item.description}
            </ButtonSelect>
          );
        })}
      </TabGroupSubGroup>
    </>
  );
};

export default OpenLoadsBrowse;
