/** @jsxImportSource @emotion/react */
import React, { ReactNode, useState } from "react";
import tw from "twin.macro";
import { PrimeStockDetails } from "../../../../../types/types";
import { useAppSelector } from "../../../../../app/hooks";
import {
  selectDefaultPriceUnit,
  selectCurrentAuthorities,
} from "../../../../../store/user.reducer";
import { hasAuthority } from "../../../../../helpers/hasAuthority";

type Props = {
  children?: ReactNode;
  currentInventoryResults?: PrimeStockDetails;
};

const InventoryItemDetails: React.FC<Props> = ({ currentInventoryResults }) => {
  const currentAuthorities = useAppSelector(selectCurrentAuthorities);
  const defaultPriceUnit = useAppSelector(selectDefaultPriceUnit);
  const [priceType, setPriceType] = useState<"CFT" | "CWT">(defaultPriceUnit);
  const { currentProductIndex } = useAppSelector(
    (state) => state.product.prime
  );
  const canViewPrices = hasAuthority("viewPriceSheets", currentAuthorities);

  return (
    <>
      <div css={[tw`flex gap-4`]}>
        <div css={[tw`flex-grow`]}>
          <span css={tw`relative`}>
            <span className="peer" css={[tw`text-2xl text-nucor-gray font-bold`]}>
              {currentProductIndex?.name}
            </span>
            <span css={tw`text-xs absolute bg-white px-2 py-1 rounded border shadow-lg right-[-70px] -top-2 text-nucor-gray whitespace-nowrap animate-tooltipdelay hidden peer-hover:block`}>
              {currentProductIndex?.itemNumber}
            </span>
          </span>
        </div>
        <div>
          <a
            href="https://www.nucortubular.com/resource/ntp-product-guide/"
            css={[
              tw`text-nucor-link underline hover:no-underline hover:text-nucor-link-hover text-xs font-bold focus:text-nucor-link-hover focus:outline-none`,
            ]}
            target="blank"
          >
            NTP Product Guide
          </a>
        </div>
        {/* <div>
          <a
            href="https://www.nucortubular.com/resource/bundle-charts/"
            css={[
              tw`text-nucor-link underline hover:no-underline hover:text-nucor-link-hover text-xs font-bold`,
            ]}
            target="blank"
          >
            Bundle Chart
          </a>
        </div> */}
      </div>
      <div css={[tw`flex justify-evenly`]}>
        <div css={tw`w-full`}>
          <span css={[tw`text-xs font-bold text-nucor-gray`]}>
            {`${currentInventoryResults?.productIndex?.shape ?? ""} ${
              currentInventoryResults?.weightPerFoot
                ? currentInventoryResults.weightPerFoot + " lbs/ft"
                : ""
            }`}
            &nbsp;
          </span>
        </div>
        <div css={tw`text-right w-full`}>
          {currentInventoryResults?.pricingModel && (
            <span css={[tw`text-xs text-nucor-gray`]}>
              Price Profile:
              <span css={tw`font-bold`}>
                {currentInventoryResults?.pricingModel}
              </span>
            </span>
          )}
        </div>
      </div>
      <div css={[tw`flex justify-evenly`]}>
        <div css={tw`w-full`}>
          <div css={[tw`text-xs text-nucor-gray flex`]}>
            <span tw="mr-1">Bundle:</span>
            <ul>
              {currentInventoryResults?.defaultBundlingList?.map(
                (item, index) => (
                  <li css={tw`text-black`} key={index}>
                    {item}
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
        <div css={tw`text-right w-full`}>
          {canViewPrices && (
            <span css={[tw`text-xs text-nucor-gray`]}>
              Price ({currentInventoryResults?.productIndex?.grade}):{" "}
              <button
                onClick={() =>
                  setPriceType((prev) => (prev === "CFT" ? "CWT" : "CFT"))
                }
                css={tw`text-nucor-link font-bold underline hover:text-nucor-link-hover hover:no-underline mr-2 focus:text-nucor-link-hover focus:outline-none`}
              >
                {priceType === "CFT" ? "CFT" : "CWT"}
              </button>
              {priceType === "CFT"
                ? currentInventoryResults?.cftDisplay
                : currentInventoryResults?.cwtDisplay}
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default InventoryItemDetails;
