/** @jsxImportSource @emotion/react */
import React, { ReactNode, useImperativeHandle, useState } from "react";
import tw from "twin.macro";
import { Phone } from "../../types/types";
import Input from "../atoms/Input";

type Props = {
  children?: ReactNode;
  highlight?: boolean;
  onChange: (value: Phone & { extension: string }) => void;
  defaultValue?: Partial<Phone> & { extension?: string };
};

type PhoneResetHandler = {
  reset: () => void;
  isValid: () => boolean;
};

const PhoneComponent: React.ForwardRefRenderFunction<
  PhoneResetHandler,
  Props
> = ({ highlight, onChange, defaultValue }: Props, ref) => {
  const [areaCode, setAreaCode] = useState(defaultValue?.areaCode ?? "");
  const [exchange, setExchange] = useState(defaultValue?.exchange ?? "");
  const [local4, setLocal4] = useState(defaultValue?.local4 ?? "");
  const [extension, setExtension] = useState(defaultValue?.extension ?? "");

  useImperativeHandle(ref, () => ({
    reset() {
      setAreaCode("");
      setExchange("");
      setLocal4("");
      setExtension("");
      onChange({ areaCode: "", exchange: "", local4: "", extension: "" });
    },
    isValid() {
      return (
        areaCode.length === 3 && exchange.length === 3 && local4.length === 4
      );
    },
  }));

  return (
    <span css={tw`flex items-center gap-2`}>
      <Input
        name="areaCode"
        css={[tw`w-[5ch]`, highlight && tw`bg-nucor-yellow`]}
        maxLength={3}
        value={areaCode}
        onChange={(e) => {
          if (!/^\d*$/.test(e.target.value)) return;
          setAreaCode(e.target.value);
          onChange({ areaCode: e.target.value, exchange, local4, extension });
        }}
      />
      <Input
        name="exchange"
        value={exchange}
        css={[tw`w-[5ch]`, highlight && tw`bg-nucor-yellow`]}
        maxLength={3}
        onChange={(e) => {
          if (!/^\d*$/.test(e.target.value)) return;
          setExchange(e.target.value);
          onChange({ areaCode, exchange: e.target.value, local4, extension });
        }}
      />
      <Input
        name="local4"
        value={local4}
        css={[tw`w-[6ch]`, highlight && tw`bg-nucor-yellow`]}
        maxLength={4}
        onChange={(e) => {
          if (!/^\d*$/.test(e.target.value)) return;
          setLocal4(e.target.value);
          onChange({ areaCode, exchange, local4: e.target.value, extension });
        }}
      />
      <span css={tw`font-semibold text-nucor-gray`}>Ext.</span>
      <Input
        name="extension"
        css={tw`w-[8ch]`}
        value={extension}
        onChange={(e) => {
          if (!/^\d*$/.test(e.target.value)) return;
          setExtension(e.target.value);
          onChange({ areaCode, exchange, local4, extension: e.target.value });
        }}
      />
    </span>
  );
};

export default React.forwardRef(PhoneComponent);
PhoneComponent.displayName = "PhoneComponent";
