import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  fetchProductSelection,
  getBrowseHistory,
  getCustomerParts,
  getActiveList,
  getRollingData,
  getRollingDataByPart,
  refreshPrimeInventory,
  searchInventory,
  searchInventoryBundles,
  searchInventoryByPart,
  searchStockList,
} from "./product.actions";
import {
  AvailableGrade,
  CustomerPart,
  DimensionSelections,
  ErrorResponse,
  HeightSelection,
  Item,
  PrimeInventoryFilter,
  PrimeStockDetails,
  ProductIndex,
  ProductSelection,
  ProductSelectionDetails,
  ProductShape,
  RequestStatus,
  RollingCustomerView,
  SalesOrderItem,
  SecondaryShortsDetails,
  SiteSelection,
  WallSelection,
  WidthSelection,
} from "../types/types";
import { RootState } from "./store";
import {
  addSecondaryToCart,
  addShortsToCart,
  removeSecondaryCartItem,
  removeShortsCartItem,
  updateSecondaryStockCartItem,
  updateShortsStockCartItem,
} from "./shoppingCart.actions";
import {
  keyFromInventoryItem,
  keyFromSalesOrderItem,
} from "../helpers/inventoryKeys";
import { FilterParams } from "../features/portal/stock/prime/inventory/PrimeProductTable";

interface InitialState {
  customerParts: CustomerPart[];
  activeItems: Item[];
  availableGrades: AvailableGrade[];
  productSelections?: ProductSelection;
  productSelectionDetails: ProductSelectionDetails;
  prime: {
    currentProductIndex?: ProductIndex;
    currentInventoryResults?: PrimeStockDetails;
    currentRollingData?: RollingCustomerView;
    browseHistory: ProductIndex[];
    inventoryFilter: PrimeInventoryFilter;
    inventorySearchStatus: RequestStatus;
    primeError?: string | ErrorResponse;
  };
  secondaryshorts: {
    currentInventoryResults?: SecondaryShortsDetails;
    status: RequestStatus;
  };
  shorts: {
    currentInventoryResults?: SecondaryShortsDetails;
    status: RequestStatus;
  };
  loading: boolean;
  error: string;
}

const initialState: InitialState = {
  customerParts: [],
  activeItems: [],
  availableGrades: [],
  productSelectionDetails: {
    wall: 0,
    width: 0,
    height: 0,
    grade: "",
    shape: "Unknown",
  },
  prime: {
    inventorySearchStatus: "idle",
    currentInventoryResults: undefined,
    currentRollingData: undefined,
    browseHistory: [],
    inventoryFilter: {
      oddLengths: false,
      site: "ALL",
    },
  },
  secondaryshorts: {
    currentInventoryResults: undefined,
    status: "idle",
  },
  shorts: {
    currentInventoryResults: undefined,
    status: "idle",
  },
  loading: false,
  error: "",
};

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    clearPrimeError: (state) => {
      state.prime.primeError = undefined;
    },
    setPrimeInventoryFilters: (
      state,
      action: PayloadAction<PrimeInventoryFilter>
    ) => {
      state.prime.inventoryFilter = action.payload;
    },
    clearRollingData: (state) => {
      state.prime.currentRollingData = undefined;
    },
    clearSecondaryShortsResults: (state) => {
      state.secondaryshorts.currentInventoryResults = undefined;
      state.shorts.currentInventoryResults = undefined;
    },
    setProductDetailsFromHistory: (
      state,
      action: PayloadAction<ProductIndex>
    ) => {
      state.productSelectionDetails.grade = action.payload.grade;
      state.productSelectionDetails.shape = action.payload.shape;
      state.productSelectionDetails.width = action.payload.dimension1;
      state.productSelectionDetails.height = action.payload.dimension2;
      state.productSelectionDetails.wall = +action.payload.nominalWallThickness;
      state.prime.currentProductIndex = action.payload;
    },
    setPrimeStockSelections: (state, action: PayloadAction<ProductIndex>) => {
      state.prime.currentInventoryResults = undefined;
      state.productSelectionDetails.grade = action.payload.grade;
      state.productSelectionDetails.shape = action.payload.shape;
      state.productSelectionDetails.width = action.payload.dimension1;
      state.productSelectionDetails.height = action.payload.dimension2;
      state.productSelectionDetails.wall = +action.payload.nominalWallThickness;
      state.prime.currentProductIndex = action.payload;
    },
    setCurrentProductIndex: (state, action) => {
      state.prime.currentInventoryResults = undefined;
      state.productSelectionDetails.wall = +action.payload.nominalWallThickness;
      state.prime.currentProductIndex = action.payload;
    },
    clearCurrentInventoryResults: (state) => {
      state.prime.currentInventoryResults = undefined;
      state.prime.currentRollingData = undefined;
    },
    setProductSelectionWidth: (state, action: PayloadAction<number>) => {
      state.productSelectionDetails.width = action.payload;
      if (state.productSelectionDetails.shape === "Square") {
        state.productSelectionDetails.height = action.payload;
      }
      state.productSelectionDetails.height = 0;
      state.productSelectionDetails.wall = 0;
      state.prime.currentProductIndex = undefined;
    },
    setProductSelectionHeight: (state, action: PayloadAction<number>) => {
      state.productSelectionDetails.height = action.payload;
      state.productSelectionDetails.wall = 0;
      state.prime.currentProductIndex = undefined;
    },
    setProductSelectionWall: (state, action: PayloadAction<number>) => {
      state.productSelectionDetails.wall = action.payload;
    },
    setProductSelectionGrade: (state, action: PayloadAction<string>) => {
      state.productSelectionDetails.grade = action.payload;
      state.productSelectionDetails.shape = "Unknown";
      state.productSelectionDetails.width = 0;
      state.productSelectionDetails.height = 0;
      state.productSelectionDetails.wall = 0;
      state.prime.currentProductIndex = undefined;
    },
    setProductSelectionShape: (state, action: PayloadAction<ProductShape>) => {
      state.productSelectionDetails.shape = action.payload;
      state.productSelectionDetails.width = 0;
      state.productSelectionDetails.height = 0;
      state.productSelectionDetails.wall = 0;
      state.prime.currentProductIndex = undefined;
    },
    updateBrowseHistory: (state, action: PayloadAction<ProductIndex>) => {
      state.prime.browseHistory = [
        action.payload,
        ...state.prime.browseHistory
          .filter(
            (item) =>
              item.compoundProductKey !== action.payload.compoundProductKey
          )
          .slice(0, 9),
      ];
    },
    updateInventoryItemWithSalesOrder: (
      state,
      action: PayloadAction<SalesOrderItem>
    ) => {
      if (!state.prime.currentInventoryResults) return;
      state.prime.currentInventoryResults.list =
        state.prime.currentInventoryResults.list.map((listItem) => {
          if (
            keyFromInventoryItem(listItem) ===
            keyFromSalesOrderItem(action.payload)
          ) {
            return { ...listItem, salesOrderItem: action.payload };
          } else {
            return listItem;
          }
        });
    },
    removeSalesOrderItemFromInventoryItem: (
      state,
      action: PayloadAction<number>
    ) => {
      if (!state.prime.currentInventoryResults) return;
      state.prime.currentInventoryResults.list =
        state.prime.currentInventoryResults.list.map((listItem) => {
          return {
            ...listItem,
            salesOrderItem:
              listItem?.salesOrderItem?.id === action.payload
                ? undefined
                : listItem.salesOrderItem,
          };
        });
    },
    removeCustomerPartNumberFromCurrentProduct: (state) => {
      if (!state.prime.currentProductIndex) return;
      state.prime.currentProductIndex.customerPartNumber = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProductSelection.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchProductSelection.fulfilled, (state, action) => {
      state.loading = false;
      state.productSelections = action.payload;
      state.availableGrades = action.payload.gradeSelections?.map((item) => ({
        grade: item.grade,
        onlyRound:
          item.shapeSelections.length === 1 &&
          item.shapeSelections[0].shape === "Round",
      }));
    });
    builder.addCase(fetchProductSelection.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(searchInventory.pending, (state) => {
      // state.loading = true;
      state.prime.inventorySearchStatus = "pending";
    });
    builder.addCase(searchInventory.fulfilled, (state, action) => {
      state.prime.inventorySearchStatus = "idle";
      state.prime.currentInventoryResults = action.payload;
      state.productSelectionDetails.grade = action.payload.productIndex.grade;
      state.productSelectionDetails.shape = action.payload.productIndex.shape;
      state.productSelectionDetails.width =
        action.payload.productIndex.dimension1;
      state.productSelectionDetails.height =
        action.payload.productIndex.dimension2 ?? 0;
      state.productSelectionDetails.wall =
        +action.payload.productIndex.nominalWallThickness;
      // Fixes bug where searchInventory from search tab causes constant
      // re-rendering of inventory when using browse tab. This is necessary
      // to populate product description in the inventory item details from search
      action.meta.arg.fromPartSearch &&
        (state.prime.currentProductIndex = {
          ...action.payload.productIndex,
          customerPartNumber: action.payload.customerPartNumber,
        });
    });
    builder.addCase(searchInventory.rejected, (state, action) => {
      state.prime.primeError = action.payload;
      state.prime.inventorySearchStatus = "idle";
      // state.loading = false;
    });
    builder.addCase(getRollingData.pending, (state) => {
      state.prime.currentRollingData = undefined;
      state.loading = true;
    });
    builder.addCase(getRollingData.fulfilled, (state, action) => {
      state.loading = false;
      state.prime.currentRollingData = action.payload;
    });
    builder.addCase(getRollingData.rejected, (state, action) => {
      state.prime.primeError = action.payload;
      state.loading = false;
    });
    builder.addCase(getRollingDataByPart.pending, (state) => {
      state.prime.currentRollingData = undefined;
      state.loading = true;
    });
    builder.addCase(getRollingDataByPart.fulfilled, (state, action) => {
      state.loading = false;
      state.prime.currentRollingData = action.payload;
    });
    builder.addCase(getRollingDataByPart.rejected, (state, action) => {
      state.prime.primeError = action.payload;
      state.loading = false;
    });
    builder.addCase(getBrowseHistory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBrowseHistory.fulfilled, (state, action) => {
      state.loading = false;
      state.prime.browseHistory = action.payload
        .filter((item) => item.productIndex)
        .map((item) => item.productIndex);
    });
    builder.addCase(getBrowseHistory.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(searchInventoryByPart.pending, (state) => {
      state.prime.inventorySearchStatus = "pending";

      // state.loading = true;
    });
    builder.addCase(searchInventoryByPart.fulfilled, (state, action) => {
      // state.loading = false;
      state.prime.inventorySearchStatus = "idle";

      state.prime.currentProductIndex = {
        ...action.payload.productIndex,
        customerPartNumber: action.payload.customerPartNumber,
      };
      state.prime.currentInventoryResults = action.payload;
      state.productSelectionDetails.grade = action.payload.productIndex.grade;
      state.productSelectionDetails.shape = action.payload.productIndex.shape;
      state.productSelectionDetails.width =
        action.payload.productIndex.dimension1;
      state.productSelectionDetails.height =
        action.payload.productIndex.dimension2 ?? 0;
      state.productSelectionDetails.wall =
        +action.payload.productIndex.nominalWallThickness;
    });
    builder.addCase(searchInventoryByPart.rejected, (state, action) => {
      state.prime.primeError = action.payload;
      state.prime.inventorySearchStatus = "idle";

      // state.loading = false;
    });
    builder.addCase(getCustomerParts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCustomerParts.fulfilled, (state, action) => {
      state.loading = false;
      state.customerParts = action.payload;
    });
    builder.addCase(getCustomerParts.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getActiveList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getActiveList.fulfilled, (state, action) => {
      state.loading = false;
      state.activeItems = action.payload;
    });
    builder.addCase(getActiveList.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(searchStockList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(searchStockList.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(searchStockList.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(refreshPrimeInventory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(refreshPrimeInventory.fulfilled, (state, action) => {
      state.loading = false;
      state.prime.currentInventoryResults = action.payload;
    });
    builder.addCase(refreshPrimeInventory.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(searchInventoryBundles.pending, (state) => {
      state.secondaryshorts.status = "pending";
    });
    builder.addCase(searchInventoryBundles.fulfilled, (state, action) => {
      state.secondaryshorts.status = "idle";
      state.secondaryshorts.currentInventoryResults = action.payload;
    });
    builder.addCase(searchInventoryBundles.rejected, (state) => {
      state.secondaryshorts.status = "idle";
    });
    builder.addCase(addSecondaryToCart.fulfilled, (state, action) => {
      state.secondaryshorts.currentInventoryResults = {
        ...state.secondaryshorts.currentInventoryResults,
        bundles:
          state.secondaryshorts.currentInventoryResults?.bundles?.map(
            (bundle) => {
              if (
                bundle.bundle.tagNumber === action.payload.tagNumber &&
                bundle.bundle.itemNumber === action.payload.itemNumber
              ) {
                return {
                  ...bundle,
                  salesOrderItem: action.payload,
                };
              }
              return bundle;
            }
          ) ?? [],
      };
    });
    builder.addCase(updateSecondaryStockCartItem.fulfilled, (state, action) => {
      state.secondaryshorts.currentInventoryResults = {
        ...state.secondaryshorts.currentInventoryResults,
        bundles:
          state.secondaryshorts.currentInventoryResults?.bundles?.map(
            (bundle) => {
              if (bundle.salesOrderItem?.id === action.payload.id) {
                return {
                  ...bundle,
                  salesOrderItem: action.payload,
                };
              }
              return bundle;
            }
          ) ?? [],
      };
    });
    builder.addCase(removeSecondaryCartItem.fulfilled, (state, action) => {
      state.secondaryshorts.currentInventoryResults = {
        ...state.secondaryshorts.currentInventoryResults,
        bundles:
          state.secondaryshorts.currentInventoryResults?.bundles?.map(
            (bundle) => {
              if (bundle.salesOrderItem?.id === action.payload) {
                delete bundle.salesOrderItem;
                return bundle;
              }
              return bundle;
            }
          ) ?? [],
      };
    });
    builder.addCase(addShortsToCart.fulfilled, (state, action) => {
      state.secondaryshorts.currentInventoryResults = {
        ...state.secondaryshorts.currentInventoryResults,
        bundles:
          state.secondaryshorts.currentInventoryResults?.bundles?.map(
            (bundle) => {
              if (
                bundle.bundle.tagNumber === action.payload.tagNumber &&
                bundle.bundle.itemNumber === action.payload.itemNumber
              ) {
                return {
                  ...bundle,
                  salesOrderItem: action.payload,
                };
              }
              return bundle;
            }
          ) ?? [],
      };
    });
    builder.addCase(updateShortsStockCartItem.fulfilled, (state, action) => {
      state.secondaryshorts.currentInventoryResults = {
        ...state.secondaryshorts.currentInventoryResults,
        bundles:
          state.secondaryshorts.currentInventoryResults?.bundles?.map(
            (bundle) => {
              if (bundle.salesOrderItem?.id === action.payload.id) {
                return {
                  ...bundle,
                  salesOrderItem: action.payload,
                };
              }
              return bundle;
            }
          ) ?? [],
      };
    });
    builder.addCase(removeShortsCartItem.fulfilled, (state, action) => {
      state.secondaryshorts.currentInventoryResults = {
        ...state.secondaryshorts.currentInventoryResults,
        bundles:
          state.secondaryshorts.currentInventoryResults?.bundles?.map(
            (bundle) => {
              if (bundle.salesOrderItem?.id === action.payload) {
                delete bundle.salesOrderItem;
                return bundle;
              }
              return bundle;
            }
          ) ?? [],
      };
    });
  },
});

export const selectAvailableGrades = (state: RootState) =>
  state.product.availableGrades;
// createSelector(
//   ,
//   (availableGrades) => {
//     return [
//       { value: "", label: "Select a Grade" },
//       ...availableGrades.map((grade) => ({ value: grade, label: grade })),
//     ];
//   }
// );

export const selectCurrentProductKey = createSelector(
  [(state: RootState) => state.product.prime.currentProductIndex],
  (state) => {
    if (!state?.compoundProductKey) return undefined;
    return {
      key: state?.compoundProductKey,
      customerPartNumber: state?.customerPartNumber,
    };
  }
);

export const selectProductSelectionGrades = createSelector(
  [(state: RootState) => state.product.productSelections],
  (selections) => {
    return selections?.gradeSelections?.map((item) => item.grade);
  }
);

export const selectProductSelectionShapes = createSelector(
  [
    (state: RootState) => state.product.productSelections,
    (state: RootState) => state.product.productSelectionDetails,
  ],
  (selections, details) => {
    return selections?.gradeSelections?.find(
      (item) => item.grade === details.grade
    );
  }
);

export const selectProductSelectionWidth = createSelector(
  [
    (state: RootState) => state.product.productSelections,
    (state: RootState) => state.product.productSelectionDetails,
  ],
  (selections, details) => {
    if (details.grade === "" || details.shape === "Unknown") return undefined;
    return selections?.gradeSelections
      ?.find((item) => item.grade === details.grade)
      ?.shapeSelections?.find((item) => item.shape === details.shape)
      ?.dimensionSelections?.map((item) => item.dimension);
  }
);

export const selectProductSelectionHeight = createSelector(
  [
    (state: RootState) => state.product.productSelections,
    (state: RootState) => state.product.productSelectionDetails,
  ],
  (selections, details) => {
    if (
      details.grade === "" ||
      details.shape === "Unknown" ||
      details.shape === "Round" ||
      details.width === 0
    )
      return undefined;
    const arr = selections?.gradeSelections
      ?.find((item) => item.grade === details.grade)
      ?.shapeSelections?.find((item) => item.shape === details.shape)
      ?.dimensionSelections as HeightSelection[];
    return arr
      ?.find((item) => item.dimension === details.width)
      ?.dimensionSelections?.map((item) => item.dimension);
  }
);

export const selectProductSelectionWall = createSelector(
  [
    (state: RootState) => state.product.productSelections,
    (state: RootState) => state.product.productSelectionDetails,
  ],
  (selections, details) => {
    if (
      details.grade === "" ||
      details.shape === "Unknown" ||
      details.width === 0 ||
      (details.height === 0 && details.shape === "Rectangle")
    )
      return undefined;

    const arr: unknown = selections?.gradeSelections
      ?.find((item) => item.grade === details.grade)
      ?.shapeSelections?.find(
        (item) => item.shape === details.shape
      )?.dimensionSelections;

    if (details.shape === "Rectangle") {
      return (arr as WidthSelection[])
        ?.find((item) => item.dimension === details.width)
        ?.dimensionSelections?.find((item) => item.dimension === details.height)
        ?.wallSelections as WallSelection[];
    } else {
      return (arr as DimensionSelections[])?.find(
        (item) => item.dimension === details.width
      )?.wallSelections;
    }
  }
);

export const selectProductSelectionObject = createSelector(
  [
    (state: RootState) => state.product.productSelections,
    (state: RootState) => state.product.productSelectionDetails,
  ],
  (selections, details) => {
    const { grade, shape, width, height } = details;
    const widths = selections?.gradeSelections
      ?.find((item) => item.grade === grade)
      ?.shapeSelections?.find((item) => item.shape === shape);
    const objWidth = {
      headerLabel: widths?.dimSelectionsLabel,
      available: widths?.dimensionSelections?.map((item) => ({
        value: item.dimension,
        label: item.dimensionLabel,
      })),
      standardLengths: widths?.standardLengths,
    };
    const heights =
      shape === "Rectangle"
        ? (widths?.dimensionSelections as HeightSelection[])?.find(
            (item) => item.dimension === width
          )
        : undefined;
    const objHeight =
      shape === "Rectangle"
        ? {
            headerLabel: heights?.dimSelectionsLabel,
            available: heights?.dimensionSelections?.map((item) => ({
              value: item.dimension,
              label: item.dimensionLabel ?? item.dimension.toString(),
            })),
          }
        : undefined;
    const walls =
      shape === "Rectangle"
        ? heights?.dimensionSelections?.find(
            (item) => item.dimension === height
          )
        : (widths?.dimensionSelections as DimensionSelections[])?.find(
            (item) => item.dimension === width
          );

    const objWall = {
      headerLabel: walls?.wallSelectionsLabel,
      available: walls?.wallSelections?.map((item) => ({
        value: item.decimalWallThickness,
        label: item.wall,
        productIndex: item.productIndex,
      })),
    };
    if (!grade || grade === "")
      return {
        grade: undefined,
        shape: undefined,
        width: undefined,
        height: undefined,
        wall: undefined,
      };
    return {
      grade,
      shape: shape,
      width: objWidth,
      height: objHeight,
      wall: objWall,
    };
  }
);

export const selectCurrentRollingData = (state: RootState) =>
  state.product.prime.currentRollingData;

export const selectFilteredInventoryResults = createSelector(
  [
    (state: RootState) => state.product.prime.currentInventoryResults,
    (state: RootState, params: FilterParams) => params,
  ],
  (items, params) => {
    return items?.list.filter((item) => {
      if (!params.vmi && item.inventoryItem.vmi) return false;
      if (
        !params.oddLengths &&
        item.inventoryItem.length &&
        !params.standardLengths?.includes(item.inventoryItem.length)
      )
        return false;
      if (
        params.selectedMill !== "ALL" &&
        !params.mills
          ?.find((mill: SiteSelection) => mill.key === params.selectedMill)
          ?.sites.includes(item.inventoryItem.site ?? "")
      ) {
        return false;
      }
      return true;
    });
  }
);

export default productSlice.reducer;
export const {
  setCurrentProductIndex,
  clearCurrentInventoryResults,
  setProductSelectionGrade,
  setProductSelectionHeight,
  setProductSelectionShape,
  setProductSelectionWall,
  setProductSelectionWidth,
  setProductDetailsFromHistory,
  clearRollingData,
  updateBrowseHistory,
  updateInventoryItemWithSalesOrder,
  removeSalesOrderItemFromInventoryItem,
  setPrimeInventoryFilters,
  clearSecondaryShortsResults,
  removeCustomerPartNumberFromCurrentProduct,
  setPrimeStockSelections,
  clearPrimeError,
} = productSlice.actions;
