/** @jsxImportSource @emotion/react */
import React, { ReactNode, useState } from "react";
import tw from "twin.macro";
import TextButton from "../../../components/atoms/TextButton";
import Toast from "../../../components/molecules/Toast";
import { useAppDispatch } from "../../../app/hooks";
import { priceSheetItemsExcel } from "../../../store/admin.actions";
import AnimatedLoadingSpinner from "../../../components/atoms/AnimatedLoadingSpinner";


type Props = {
  children?: ReactNode;
};

const AdminUtilities: React.FC<Props> = () => {

  const dispatch = useAppDispatch();

  const [status, setStatus] = useState("idle");
  const [errorMessage, setErrorMessage] = useState("");

  const downloadPriceSheetItems = () => {
    setStatus("pending");
    setErrorMessage("");
    dispatch(priceSheetItemsExcel())
      .unwrap()
      .then(() => {
        setStatus("success");
      })
      .catch((error) => {
        setStatus("error");
        setErrorMessage(error);
      });
  };

  return (
    <div css={tw`text-xs p-2`}>
      {errorMessage && (
        <Toast
          type="error"
          message={errorMessage}
          onConfirm={() => setErrorMessage("")}
        />
      )}

      <div  css={tw`flex items-center flex items-center`}>
        <TextButton disabled={status === "pending"} onClick={downloadPriceSheetItems}>
          Price Sheet Items
        </TextButton>
        {status === "pending" && (
            <AnimatedLoadingSpinner message="" className="ml-2"/>
          )}
      </div>
    </div>
  );
};

export default AdminUtilities;
