/** @jsxImportSource @emotion/react */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactNode } from "react";
import { faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import tw from "twin.macro";

type Props = {
  children?: ReactNode;
  dir?: "asc" | "desc";
};

const SortDirection: React.FC<Props> = ({ dir }) => {
  if (!dir) return null;
  return (
    <FontAwesomeIcon
      css={tw`ml-[2px]`}
      icon={dir === "asc" ? faCaretUp : faCaretDown}
    />
  );
};

export default SortDirection;
