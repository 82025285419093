import { createSlice } from "@reduxjs/toolkit";

type InitialState = {
  error?: string;
};

const initialState: InitialState = {};

const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {},
});

export default analyticsSlice.reducer;
