/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import tw from "twin.macro";
import { useAppSelector } from "../../../../../../app/hooks";
import { numberFormatter } from "../../../../../../helpers/numberFormat";
import { CartSite } from "../../../../../../types/types";
import LineItem from "./LineItem";

type Props = {
  children?: ReactNode;
  site: CartSite;
  poRequired?: boolean;
  saveCart: () => Promise<void>;
};

const OrderSite: React.FC<Props> = ({ site, poRequired, saveCart }) => {
  const {
    site: siteName,
    address: {
      address1,
      address2,
      address3,
      city,
      stateCode,
      postalCode,
      phone,
      fax,
    },
    items,
  } = site;
  const usePoLineNumber = useAppSelector(
    (state) => state.customer.currentCustomer?.useItemPoLineNumber
  );
  const hasItemWithPartList = useAppSelector(
    (state) => state.shoppingCart.currentShoppingCart?.hasItemWithPartList
  );
  const requiresCustomerPartNumber = useAppSelector(
    (state) =>
      state.shoppingCart.currentShoppingCart?.requiresCustomerPartNumber
  );

  const totalPieces = items.reduce((acc, current) => {
    return acc + current.pieces;
  }, 0);

  const totalWeight = items.reduce((acc, current) => {
    return acc + current.weight;
  }, 0);

  return (
    <div css={tw`flex mt-2`}>
      <div css={tw`bg-nucor-green w-4`} />
      <div css={tw`flex-1`}>
        <div css={tw`bg-[#e6e6e6] p-1`}>
          <p>Ship From:</p>
          <p css={tw`font-bold`}>{siteName}</p>
          <p>{address1}</p>
          <p>{address2}</p>
          <p>{address3}</p>
          <p>{`${city}, ${stateCode} ${postalCode}`}</p>
          {phone && <p>Tel: {phone}</p>}
          {fax && <p>Fax: {fax}</p>}
          <p></p>
        </div>
        <table className="ntp-portal-table ntp-remove-hover ntp-remove-stripe" css={tw`w-full`}>
          <thead>
            <tr>
              <th>Description</th>
              <th>Grade</th>
              <th>Pcs</th>
              <th>Lbs</th>
              <th>Price</th>
              <th>Source</th>
              {poRequired && (
                <th>
                  PO Number{" "}
                  {poRequired && <span css={tw`text-red-600`}>*</span>}
                </th>
              )}
              {usePoLineNumber && (
                <th>
                  PO Line # <span css={tw`text-red-600`}>*</span>
                </th>
              )}
              <th>
                Due Date <span css={tw`text-red-600`}>*</span>
              </th>
              {hasItemWithPartList && (
                <th css={tw`w-[35ch]`}>
                  Part Number
                  {requiresCustomerPartNumber && (
                    <span css={tw`text-red-600`}> *</span>
                  )}
                </th>
              )}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <LineItem
                saveCart={saveCart}
                poRequired={poRequired}
                item={item}
                key={index}
              />
            ))}
            <tr>
              <td css={tw`text-nucor-gray font-bold`} colSpan={2}>
                Sub Total:
              </td>
              <td css={tw`text-right`}>{totalPieces}</td>
              <td css={tw`text-right`}>{numberFormatter(totalWeight)}</td>
              <td colSpan={100}></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OrderSite;
