/** @jsxImportSource @emotion/react */
import React, { ReactNode, useContext, useState } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import TextButton from "../../../../components/atoms/TextButton";
import {
  exportTestCertPDF,
  ExportTestCertPDFRequest,
  searchTestCertDocument,
} from "../../../../store/document.actions";
import { testCertSort, updateTestCertPagination } from "../../../../store/document.reducer";
import Toast from "../../../../components/molecules/Toast";
import { formatDateToShort } from "../../../../helpers/dateHelpers";
import { PDFContext } from "./CertsList";
import Pagination, { PaginationValues } from "../../../../components/molecules/PaginationComponent";
import Loading from "../../../../components/atoms/Loading";
import { selectCurrentCustomerId } from "../../../../store/customer.reducer";
import { getNewSort } from "../../../../helpers/getNewSort";
import TableSortButton from "../../../../components/molecules/TableSortButton";

type Props = {
  children?: ReactNode;
};

const CertsResults: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const { searchCriteria, status, testCertResults } = useAppSelector((state) => state.document.testCert);
  const customerId = useAppSelector(selectCurrentCustomerId);

  const ctx = useContext(PDFContext);
  const [errorMessage, setErrorMessage] = useState("");

  const pdfHandler = (params: ExportTestCertPDFRequest) => {
    setErrorMessage("");
    dispatch(exportTestCertPDF(params))
      .unwrap()
      .then((result) => {
        ctx?.setFile(result);
      })
      .catch((error) => {
        setErrorMessage(error);
      });
  };

  const sortChanged = (field: string) => {
    if (!customerId || !testCertResults?.lastCriteria) return;
    
    const searchSort = getNewSort(field, searchCriteria);

    const c = {...testCertResults.lastCriteria, ...searchSort, page: searchCriteria.page};

    dispatch(testCertSort(searchSort))
    dispatch(searchTestCertDocument({customerId, criteria: c}));
  };

  const pageChanged = (page: PaginationValues) => {
    if (!customerId || !testCertResults?.lastCriteria) return;
    
    const c = {...testCertResults.lastCriteria, ...page};

    dispatch(updateTestCertPagination(page))
    dispatch(searchTestCertDocument({customerId, criteria: c}));
  };

  return (
    <>
      {errorMessage && (
        <Toast
          type="error"
          message={errorMessage}
          onConfirm={() => setErrorMessage("")}
        />
      )}
      <Pagination
        currentPage={searchCriteria.page}
        isLoading={status === "pending"}
        items={testCertResults?.count ?? 0}
        onChange={(value) => pageChanged(value)}
      />
      <div css={tw`relative`}>
        {status === "pending" && <Loading />}
        <table className="ntp-portal-table" css={tw`w-full text-xs`}>
          <thead>
            <tr>
              <th>
                <TableSortButton
                  field="mtrCreatedDate"
                  sort={searchCriteria}
                  onClick={(field) => sortChanged(field)}
                >
                  Cert Date
                </TableSortButton>
              </th>
              <th>
                <TableSortButton
                  field="materialCertificateId"
                  sort={searchCriteria}
                  onClick={(field) => sortChanged(field)}
                >
                  Cert Number
                </TableSortButton>
              </th>
              <th>
                <TableSortButton
                  field="site"
                  sort={searchCriteria}
                  onClick={(field) => sortChanged(field)}
                >
                  Mill
                </TableSortButton>
              </th>
              <th>
                <TableSortButton
                  field="customerPONumber"
                  sort={searchCriteria}
                  onClick={(field) => sortChanged(field)}
                >
                  PO Number
                </TableSortButton>
              </th>
              <th>
                <TableSortButton
                  field="shippingCustomerId"
                  sort={searchCriteria}
                  onClick={(field) => sortChanged(field)}
                >
                  Ship To
                </TableSortButton>
              </th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {testCertResults?.list && testCertResults.list.length > 0 ? (
              testCertResults?.list.map((cert, index) => (
                <tr key={index}>
                  <td>
                    {formatDateToShort(cert.mtrCreatedDate)}
                  </td>
                  <td>{cert.materialCertificateId}</td>
                  <td>{cert.site}</td>
                  <td>{cert.customerPONumber}</td>
                  <td>{`${cert.shippingCustomerId}-${cert.customerName}`}</td>
                  <td css={tw`text-center`}>
                    <TextButton
                      disabled={status === "pending"}
                      onClick={() =>
                        pdfHandler({
                          customerId: cert.billingCustomerId ?? "",
                          id: cert.materialCertificateId,
                        })
                      }
                    >
                      View
                    </TextButton>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td css={tw`text-nucor-gray !px-2 !py-3 hover:!bg-white`} colSpan={6}>
                  No test certificates found for the selected criteria
                </td>
              </tr>
            )} 
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CertsResults;
