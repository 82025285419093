/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import {
  DeepRequired,
  FieldErrorsImpl,
  UseFormRegister,
} from "react-hook-form";
import tw from "twin.macro";
import { UserAdminReadView } from "../../../../../types/types";
import Input from "../../../../../components/atoms/Input";
import { PersonalInfoForm } from "../personalInfoForm";

type Props = {
  children?: ReactNode;
  register: UseFormRegister<PersonalInfoForm>;
  errors: FieldErrorsImpl<DeepRequired<PersonalInfoForm>>;
  selectedUser?: UserAdminReadView;
};

const PersonalInfo: React.FC<Props> = ({ selectedUser, register, errors }) => {
  return (
    <>
      <div css={tw`flex flex-col gap-2`}>
        <fieldset css={tw`flex`}>
          <label css={tw`w-[30%]`} htmlFor="firstName">
            First Name: <span css={tw`text-red-600`}>*</span>
          </label>
          <Input
            errors={errors?.firstName}
            css={[tw`bg-nucor-yellow min-w-[49%]`]}
            id="firstName"
            {...register("firstName")}
          />
        </fieldset>
        <fieldset css={tw`flex`}>
          <label css={tw`w-[30%]`} htmlFor="lastName">
            Last Name: <span css={tw`text-red-600`}>*</span>
          </label>
          <Input
            errors={errors?.lastName}
            css={[tw`bg-nucor-yellow min-w-[49%]`]}
            {...register("lastName")}
            id="lastName"
          />
        </fieldset>
        <fieldset css={tw`flex`}>
          <label css={tw`w-[30%]`} htmlFor="title">
            Title:{" "}
          </label>
          <Input
            errors={errors?.title}
            css={[tw`min-w-[49%]`]}
            {...register("title")}
            id="title"
          />
        </fieldset>
        <fieldset css={tw`flex`}>
          <label css={tw`w-[30%]`}>e-Mail:</label>
          <p css={[tw`min-w-[49%]`]}>{selectedUser?.email}</p>
        </fieldset>
        <fieldset css={tw`flex`}>
          <label css={tw`w-[30%]`}>Office:</label>
          <div css={[tw`flex gap-1 items-center`]}>
            <Input
              maxLength={3}
              errors={errors?.officePhone?.areaCode}
              {...register("officePhone.areaCode")}
              css={[tw`w-12`]}
              type="text"
            />
            <Input
              maxLength={3}
              {...register("officePhone.exchange")}
              errors={errors?.officePhone?.exchange}
              css={[tw`w-12`]}
              type="text"
            />
            <Input
              maxLength={4}
              errors={errors?.officePhone?.local4}
              {...register("officePhone.local4")}
              css={[tw`w-16`]}
              type="text"
            />
            <span css={tw`text-xs text-[#6d756e] font-bold`}>Ext.</span>
            <Input
              errors={errors?.officePhoneExt}
              {...register("officePhoneExt")}
              css={tw`w-16`}
              type="text"
            />
          </div>
        </fieldset>
        <fieldset css={tw`flex`}>
          <label css={tw`w-[30%]`}>Mobile:</label>
          <div css={[tw`flex gap-1 items-center`]}>
            <Input
              maxLength={3}
              errors={errors?.mobilePhone?.areaCode}
              {...register("mobilePhone.areaCode")}
              css={[tw`w-12`]}
              type="text"
            />
            <Input
              maxLength={3}
              errors={errors?.mobilePhone?.exchange}
              {...register("mobilePhone.exchange")}
              css={[tw`w-12`]}
              type="text"
            />
            <Input
              maxLength={4}
              errors={errors?.mobilePhone?.local4}
              {...register("mobilePhone.local4")}
              css={[tw`w-16`]}
              type="text"
            />
          </div>
        </fieldset>
        <fieldset css={tw`flex`}>
          <label css={tw`w-[30%]`}>Fax:</label>
          <div css={[tw`flex gap-1 items-center`]}>
            <Input
              errors={errors?.faxPhone?.areaCode}
              maxLength={3}
              {...register("faxPhone.areaCode")}
              css={tw`w-12`}
              type="text"
            />
            <Input
              errors={errors?.faxPhone?.exchange}
              maxLength={3}
              {...register("faxPhone.exchange")}
              css={tw`w-12`}
              type="text"
            />
            <Input
              errors={errors?.faxPhone?.local4}
              maxLength={4}
              {...register("faxPhone.local4")}
              css={tw`w-16`}
              type="text"
            />
          </div>
        </fieldset>
        <fieldset>
          <label css={tw`block`} htmlFor="notes">
            Notes:
          </label>
          <textarea
            {...register("comments")}
            id="notes"
            css={tw`w-full h-20 resize-none`}
          />
        </fieldset>
        <div>
          <div>
            <span css={tw`w-[30%] text-nucor-gray inline-block`}>User ID:</span>
            {selectedUser?.userId}
          </div>
          <div>
            <span css={tw`w-[30%] text-nucor-gray inline-block`}>
              Last Changed By:
            </span>
            {selectedUser?.updatedByName}
          </div>
          <div>
            <span css={tw`w-[30%] text-nucor-gray inline-block`}>
              Last Changed Date:
            </span>
            {selectedUser?.updatedDate && (
              <span>
                {new Date(selectedUser?.updatedDate).toLocaleString("en-US", {
                  month: "2-digit",
                  day: "2-digit",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </span>
            )}
          </div>
          <div>
            <span css={tw`w-[30%] text-nucor-gray inline-block`}>
              Last Logged In Date:
            </span>
            {selectedUser?.lastLoggedIn && (
              <span>
                {new Date(selectedUser?.lastLoggedIn).toLocaleString("en-US", {
                  month: "2-digit",
                  day: "2-digit",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </span>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonalInfo;
