import { Phone } from "../types/types";

export const generatePhoneNumber = (phone?: Phone) => {
  if (!phone) return "";
  if (phone.areaCode && phone.exchange && phone.local4) {
    return `1-${phone.areaCode}-${phone.exchange}-${phone.local4}`;
  } else {
    return ``;
  }
};
