/** @jsxImportSource @emotion/react */
import React, { ReactNode, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import tw from "twin.macro";
import { numberFormatter } from "../../../../helpers/numberFormat";
import Loading from "../../../../components/atoms/Loading";
import { fetchDocument } from "../../../../store/document.actions";
import TextButton from "../../../../components/atoms/TextButton";
import { DocumentType, PDFFile } from "../../../../types/types";
import { selectCurrentAuthorities } from "../../../../store/user.reducer";
import { hasAuthority } from "../../../../helpers/hasAuthority";
import Toast from "../../../../components/molecules/Toast";
import { formatDateToShort } from "../../../../helpers/dateHelpers";

type Props = {
  children?: ReactNode;
  setFile: (file: PDFFile | null) => void;
};

const POSearchResults: React.FC<Props> = ({ setFile }) => {
  const dispatch = useAppDispatch();
  const currentAuthorities = useAppSelector(selectCurrentAuthorities);
  const { searchResults, status } = useAppSelector(
    (state) => state.document.poSearch
  );
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const pdfHandler = (id: string, documentType: DocumentType) => {
    setIsLoading(true);
    setError("");
    dispatch(
      fetchDocument({
        documentType,
        id,
      })
    )
      .unwrap()
      .then((result) => {
        setFile(result);
        setError("");
      })
      .catch((error) => setError(error))
      .finally(() => setIsLoading(false));
  };

  if (!searchResults && status !== "pending")
    return (
      <p css={tw`text-xs text-nucor-green font-semibold mt-2 ml-2`}>
        * Select criteria and click search
      </p>
    );

  return (
    <div css={tw`relative`}>
      {(status === "pending" || isLoading) && <Loading />}
      {error && (
        <Toast onConfirm={() => setError("")} message={error} type="error" />
      )}
      <table className="ntp-portal-table" css={[tw`w-full mt-2`]}>
        <thead>
          <tr>
            <th css={tw`w-1/4`}>Customer </th>
            <th>PO #</th>
            <th>Wgt</th>
            <th>Pcs</th>
            <th css={tw`w-[10%]`}>Order</th>
            <th>Order Date</th>
            <th css={tw`w-[10%]`}>BOL</th>
            <th css={tw`w-[10%]`}>Invoice</th>
            <th>Ship Date</th>
          </tr>
        </thead>
        <tbody>
          {searchResults && searchResults.length > 0 ? (
            searchResults.map((item, index) => (
              <tr key={index}>
                <td>
                  {item.customerId} - {item.customerName}
                </td>
                <td>{item.customerPONumber}</td>
                <td css={tw`text-right`}>{numberFormatter(item.weight)}</td>
                <td css={tw`text-right`}>{item.pieces}</td>
                <td>
                  <TextButton
                    disabled={
                      !hasAuthority(["viewOrderInvoice"], currentAuthorities)
                    }
                    onClick={() => pdfHandler(item.orderNumber, "order")}
                  >
                    {item.orderNumber}
                  </TextButton>
                </td>
                <td>{formatDateToShort(item.orderDate)}</td>
                <td>
                  {item.packingSlipNumber && <TextButton
                    disabled={
                      !hasAuthority(["viewShippingDocs"], currentAuthorities)
                    }
                    onClick={() =>
                      pdfHandler(item.packingSlipNumber, "packingslip")
                    }
                  >
                    {item.packingSlipNumber}
                  </TextButton>}
                </td>
                <td>
                  {item.invoiceNumbers?.split(",").map((num, invoiceIndex) => (
                    <div key={index + '-' + invoiceIndex}>
                      <TextButton
                        disabled={
                          !hasAuthority(["viewOrderInvoice"], currentAuthorities)
                        }
                        key={num}
                        onClick={() => pdfHandler(num, "invoice")}
                      >
                        {num}
                      </TextButton>
                    </div>
                  ))}
                </td>
                <td>{formatDateToShort(item.shipDate)}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td css={tw`text-nucor-gray !px-2 !py-3 hover:!bg-white`} colSpan={9}>
                No documents found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default POSearchResults;
