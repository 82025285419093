/** @jsxImportSource @emotion/react */
import React, { ReactNode, useEffect, useRef, useState } from "react";
import {
  DeepRequired,
  FieldErrorsImpl,
  UseFormRegister,
} from "react-hook-form";
import tw from "twin.macro";
import { useAppDispatch } from "../../../../../app/hooks";
import { searchCustomers } from "../../../../../store/admin.actions";
import { CustomerIndex, ErrorResponse } from "../../../../../types/types";
import Button from "../../../../../components/atoms/Button";
import Input from "../../../../../components/atoms/Input";
import { AddUserForm } from "../addUserForm";
import { isErrorResponse } from "../../../../../types/predicates";
import Toast from "../../../../../components/molecules/Toast";

type Props = {
  children?: ReactNode;
  register: UseFormRegister<AddUserForm>;
  errors?: FieldErrorsImpl<DeepRequired<AddUserForm>>;
  onChange: (ids: string[]) => void;
  userAdded?: boolean;
};

const AssignCustomers: React.FC<Props> = ({
  register,
  onChange,
  userAdded,
}) => {
  const dispatch = useAppDispatch();
  const [customers, setCustomers] = useState<CustomerIndex[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | ErrorResponse>();
  const [searchTerm, setSearchTerm] = useState("");
  const [assignedCustomerIndex, setAssignedCustomerIndex] = useState<
    CustomerIndex[]
  >([]);

  const searchRef = useRef<HTMLSelectElement>(null);
  const customersRef = useRef<HTMLSelectElement | null>(null);
  const { ref, ...rest } = register("assignedCustomerIds");

  useEffect(() => {
    if (userAdded) {
      setCustomers([]);
      setSearchTerm("");
      setAssignedCustomerIndex([]);
    }
  }, [userAdded]);

  const searchHandler = async () => {
    try {
      setError(undefined);
      setLoading(true);
      const results = await dispatch(searchCustomers(searchTerm)).unwrap();
      setCustomers(results);
    } catch (error) {
      if (isErrorResponse(error) || typeof error === "string") {
        setError(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const addId = (ids: string[]) => {
    const indexArray = [...assignedCustomerIndex];
    ids.forEach((id) => {
      const record = customers.find((item) => item.id === id);
      if (record && !indexArray.find((item) => item.id === id)) {
        indexArray.push(record);
      }
    });
    setAssignedCustomerIndex(indexArray);
    onChange(indexArray.map((item) => item.id));
  };

  const removeId = (ids: string[]) => {
    setAssignedCustomerIndex((prev) => {
      const arr = prev.filter((item) => !ids.includes(item.id));
      onChange(arr.map((item) => item.id));
      return arr;
    });
  };

  return (
    <>
      {error && (
        <Toast
          type="error"
          message={error}
          onConfirm={() => setError(undefined)}
        />
      )}
      <div css={tw`flex items-center`}>
        <Input
          name="searchTerm"
          value={searchTerm}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              searchHandler();
            }
          }}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button
          disabled={loading || !searchTerm}
          css={tw`m-0 py-[3px] text-xs ml-4`}
          type="button"
          onClick={searchHandler}
        >
          Search
        </Button>
      </div>
      <p>Enter customer name or id</p>
      <div css={tw`flex mt-4`}>
        <div css={tw`w-[30%]`}>
          <p css={tw`text-xs`}>Enter customer or id</p>
          <select
            ref={searchRef}
            multiple
            css={[tw`w-full h-20 p-1`]}
          >
            {loading && <option>Searching...</option>}
            {!loading && customers.length === 0 && (
              <option>No results found</option>
            )}
            {!loading &&
              customers.map((item, index) => (
                <option key={index} value={item.id}>
                  { !item.shipToPricing && `${item.id} - ${item.name} (${item.city}, ${item.state})`}
                  { item.shipToPricing && `${item.id} (${item.classification}) - ${item.name} (${item.city}, ${item.state})`}
                </option>
              ))}
          </select>
        </div>
        <div css={tw`px-8 text-center flex flex-col justify-center gap-4 pt-3`}>
          <Button
            css={tw`m-0 block text-xs font-normal px-2 py-1`}
            type="button"
            onClick={() => {
              if (!searchRef.current?.selectedOptions) return;
              const ids = Array.from(
                searchRef.current?.selectedOptions,
                (option) => option.value
              );
              addId(ids);
            }}
          >
            Assign
          </Button>
          <Button
            css={tw`m-0 block text-xs font-normal px-2 py-1`}
            type="button"
            onClick={() => {
              if (!customersRef.current?.selectedOptions) return;
              const ids = Array.from(
                customersRef.current?.selectedOptions,
                (option) => option.value
              );
              removeId(ids);
            }}
          >
            Remove
          </Button>
        </div>
        <div css={tw`w-[30%]`}>
          <p css={tw`text-xs`}>Assigned customers</p>
          <select
            ref={(e) => {
              ref(e);
              customersRef.current = e;
            }}
            multiple
            css={[tw`h-20 w-full p-1`]}
            {...rest}
          >
            {assignedCustomerIndex?.map((item) => {
              return (
                <option key={item.id} value={item.id}>
                  {!item.shipToPricing && `${item.id} - ${item.name} (${item.city}, ${item.state})`}
                  {item.shipToPricing && `${item.id} (${item.classification}) - ${item.name} (${item.city}, ${item.state})`}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    </>
  );
};

export default AssignCustomers;
