/** @jsxImportSource @emotion/react */
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactNode } from "react";
import { createPortal } from "react-dom";
import tw from "twin.macro";
import Button from "../atoms/Button";

type Props = {
  children?: ReactNode;
  onConfirm?: () => void;
  onCancel?: () => void;
  confirmText?: string;
  icon?: ReactNode;
  title: string;
};

const ConfirmationDialog: React.FC<Props> = ({
  onConfirm,
  onCancel,
  icon,
  confirmText,
  title,
  children,
  ...remainingProps
}) => {
  return createPortal(
    <div
      onClick={onCancel}
      css={[
        tw`fixed top-0 left-0 w-full h-full flex justify-center items-center bg-[#CCCCCC77] z-50`,
      ]}
      {...remainingProps}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        css={tw`max-w-[60ch] border border-[3px] rounded-lg border-nucor-light-green bg-white p-2 text-xs`}
      >
        <h3 css={tw`font-bold text-nucor-light-green mb-2`}>{title}</h3>
        <div css={tw`flex gap-4 mb-4 mx-4`}>
          {icon ? (
            icon
          ) : (
            <FontAwesomeIcon
              css={tw`text-nucor-light-green`}
              size={"2x"}
              icon={faQuestionCircle}
            />
          )}
          <div>
            <p>{children}</p>
          </div>
        </div>
        <div css={tw`flex justify-center gap-2`}>
          <Button onClick={onConfirm}>{confirmText ?? "Yes"}</Button>
          {onCancel ? <Button onClick={onCancel}>No</Button> : null}
        </div>
      </div>
    </div>,
    document.getElementById("modal") as HTMLElement
  );
};

export default ConfirmationDialog;
