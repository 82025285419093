import * as yup from "yup";
import { Authority, Phone, UserAdminReadView } from "../../../../types/types";

export type PersonalInfoForm = {
  firstName: string;
  lastName: string;
  title: string;
  officePhone: Phone;
  officePhoneExt?: string;
  mobilePhone: Phone;
  faxPhone: Phone;
  comments: string;
  userId?: number;
  status: "A" | "P" | "D";
  assignedCustomerIds?: string[];
  admin: boolean;
  internal: boolean;
  external: boolean;
  itAdmin: boolean;
  selectedRole?: "admin" | "internal" | "external" | "itAdmin";
} & Partial<Record<Authority, boolean>>;

export const schema = yup.object({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  title: yup.string(),
  comments: yup.string(),
  officePhone: yup.object().shape({
    areaCode: yup.string().matches(/^[0-9]{3}$/, {
      message: "Area code must contain 3 numbers",
      excludeEmptyString: true,
    }),
    exchange: yup.string().matches(/^[0-9]{3}$/, {
      message: "Exchange must contain 3 numbers",
      excludeEmptyString: true,
    }),
    local4: yup.string().matches(/^[0-9]{4}$/, {
      message: "Local must contain 4 numbers",
      excludeEmptyString: true,
    }),
  }),
  officePhoneExt: yup.string().matches(/^[0-9]+$/, {
    message: "Extension may only contain numbers",
    excludeEmptyString: true,
  }),
  mobilePhone: yup.object().shape({
    areaCode: yup.string().matches(/^[0-9]{3}$/, {
      message: "Area code must contain 3 numbers",
      excludeEmptyString: true,
    }),
    exchange: yup.string().matches(/^[0-9]{3}$/, {
      message: "Exchange must contain 3 numbers",
      excludeEmptyString: true,
    }),
    local4: yup.string().matches(/^[0-9]{4}$/, {
      message: "Local must contain 4 numbers",
      excludeEmptyString: true,
    }),
  }),
  faxPhone: yup.object().shape({
    areaCode: yup.string().matches(/^[0-9]{3}$/, {
      message: "Area code must contain 3 numbers",
      excludeEmptyString: true,
    }),
    exchange: yup.string().matches(/^[0-9]{3}$/, {
      message: "Exchange must contain 3 numbers",
      excludeEmptyString: true,
    }),
    local4: yup.string().matches(/^[0-9]{4}$/, {
      message: "Local must contain 4 numbers",
      excludeEmptyString: true,
    }),
  }),
});

export const setDefaults = (
  user?: UserAdminReadView
): PersonalInfoForm | undefined => {
  if (!user) return undefined;
  const currentRole = user?.admin
    ? "admin"
    : user?.internal
    ? "internal"
    : user?.external
    ? "external"
    : user?.itAdmin
    ? "itAdmin"
    : "external";
  return {
    firstName: user?.firstName,
    lastName: user?.lastName,
    officePhone: {
      areaCode: user?.officePhone?.areaCode,
      exchange: user?.officePhone?.exchange,
      local4: user?.officePhone?.local4,
    },
    officePhoneExt: user?.officePhoneExt,
    mobilePhone: {
      areaCode: user?.mobilePhone?.areaCode,
      exchange: user?.mobilePhone?.exchange,
      local4: user?.mobilePhone?.local4,
    },
    faxPhone: {
      areaCode: user?.faxPhone?.areaCode,
      exchange: user?.faxPhone?.exchange,
      local4: user?.faxPhone?.local4,
    },
    status: user?.status,
    comments: user?.comments,
    title: user?.title,
    assignedCustomerIds: user?.assignedCustomerIds,
    external: currentRole === "external",
    internal: currentRole === "internal",
    admin: currentRole === "admin",
    itAdmin: currentRole === "itAdmin",
    selectedRole: currentRole as "admin" | "internal" | "external" | "itAdmin",
    viewNonPrimeStock: user?.viewNonPrimeStock,
    viewOrderInvoice: user?.viewOrderInvoice,
    viewPriceSheets: user?.viewPriceSheets,
    createOrderQuoteLoad: user?.createOrderQuoteLoad,
    viewPrimeStock: user?.viewPrimeStock,
    viewRollings: user?.viewRollings,
    viewShippingDocs: user?.viewShippingDocs,
  };
};
