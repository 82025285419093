/** @jsxImportSource @emotion/react */
import React, { ComponentProps, ReactNode, useEffect, useState } from "react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Loading from "../../../../components/atoms/Loading";
import Select from "../../../../components/atoms/ControlledSelect";
import { selectCurrentCustomerId } from "../../../../store/customer.reducer";
import { changeDeliveryMode } from "../../../../store/shipping.actions";
import { DeliveryModeType, ErrorResponse, RequestStatus } from "../../../../types/types";
import truck from "../../../../assets/images/icon_truck.png";
import barge from "../../../../assets/images/icon_barge.png";
import rail from "../../../../assets/images/icon_rail.png";
import { numberFormatter } from "../../../../helpers/numberFormat";
import ShipToDisplay from "./components/ShipToDisplay";
import { fetchLoadCart } from "../../../../store/shipping.actions";
import { selectReleaseOrdersSelectionData } from "../../../../store/shipping.reducer";
import Toast from "../../../../components/molecules/Toast";

type Props = {
  children?: ReactNode;
  onIconClicked: React.Dispatch<React.SetStateAction<boolean>>;
} & ComponentProps<"div">;

const ReleaseOrdersLoadCart: React.FC<Props> = ({
  onIconClicked,
  ...remainingProps
}) => {
  const dispatch = useAppDispatch();
  const loadCart = useAppSelector((state) => state.shipping.loads.loadCart);
  const customerId = useAppSelector(selectCurrentCustomerId);
  const selectionData = useAppSelector(selectReleaseOrdersSelectionData);

  const [status, setStatus] = useState<RequestStatus>("idle");
  const [error, setError] = useState<string | ErrorResponse>();

  useEffect(() => {
    if (customerId) {
      setStatus("pending");
      dispatch(fetchLoadCart(customerId))
        .unwrap()
        .then(() => setStatus("success"))
        .catch(() => setStatus("error"));
    }
  }, [customerId]);

  const changeDeliveryModeHandler = (mode: DeliveryModeType) => {
    if (!customerId) return;
    if (mode === loadCart?.deliveryMode) return;
    setStatus("pending");
    dispatch(
      changeDeliveryMode({
        customerId,
        body: { deliveryMode: mode },
      })
    )
      .unwrap()
      .then(() => setStatus("success"))
      .catch((error) => {
        setStatus("error");
        setError(error);
    });
  };

  const deliveryModes =
    selectionData?.deliveryModes.map((item) => ({
      value: item.deliveryMode.toString(),
      label: item.deliveryMode.toString(),
    })) ?? [];

  let transportIcon: string | undefined;
  switch (loadCart?.deliveryMode) {
    case "Truck":
      transportIcon = truck;
      break;
    case "Rail":
      transportIcon = rail;
      break;
    case "Barge":
      transportIcon = barge;
      break;
    default:
      transportIcon = undefined;
  }

  return (
    <div css={tw`h-[80px] bg-gradient-to-b from-nucor-gradient-outer via-nucor-gradient-inner to-nucor-gradient-outer flex justify-end p-2`} {...remainingProps}>
      {error && (
        <Toast
          type="error"
          message={error}
          onConfirm={() => setError(undefined)}
        />
      )}
      <div css={tw`flex-1 w-full flex flex-col justify-center gap-1`}>
        <div css={tw`flex-1`}>
          <ShipToDisplay shipTos={loadCart?.shipTos} />
        </div>
        <div css={tw`flex justify-evenly w-full`}>
          <div css={tw`flex items-center justify-self-start flex-1`} />
          <button css={tw`flex-1`} onClick={() => onIconClicked(true)}>
            <img css={tw`h-5`} src={transportIcon} />
          </button>
        </div>
      </div>
      <div css={tw`border border-[#bbb] w-[500px] flex self-end`}>
        <div
          css={tw`bg-nucor-light-green text-white font-bold w-[90px] py-1 text-center`}
        >
          Shipping Cart
        </div>
        <div
          css={tw`flex text-xs text-nucor-gray justify-evenly w-full relative px-1`}
        >
          {status === "pending" && <Loading />}
          <fieldset css={tw`flex-1 p-[2px]`}>
            <label css={tw`block`}>Capacity</label>
            <Select
              name="transport"
              value={loadCart?.deliveryMode}
              onChange={changeDeliveryModeHandler}
              minWidth={"11ch"}
              data={deliveryModes}
            />
          </fieldset>
          <div css={tw`flex-1 p-[2px]`}>
            <label>Loaded %</label>
            <p css={tw`font-bold mt-1`}>{loadCart?.percentLoaded}%</p>
          </div>
          <div css={tw`flex-1 p-[2px]`}>
            <label>Loaded Lbs</label>
            <p css={tw`font-bold mt-1`}>
              {`${numberFormatter(loadCart?.loadedWeight)} of
            ${numberFormatter(loadCart?.capacity)}`}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReleaseOrdersLoadCart;
