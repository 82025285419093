/** @jsxImportSource @emotion/react */
import React, { ReactNode, useContext, useEffect } from "react";
import tw from "twin.macro";
import Select from "../../../../components/atoms/Select";
import TabGroupSubGroup from "../../../../components/atoms/TabGroupSubGroup";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { selectCurrentCustomerId } from "../../../../store/customer.reducer";
import {
  clearDocumentErrors,
  updateQuoteSearchCriteria,
} from "../../../../store/document.reducer";
import ButtonSelect from "../../../../components/atoms/ButtonSelect";
import { fetchQuoteResults } from "../../../../store/document.actions";
import Toast from "../../../../components/molecules/Toast";
import { PDFContext } from "./InquiryList";
import { SearchCriteria } from "../../../../types/types";

type Props = {
  children?: ReactNode;
};

type QuoteBrowseCriteria = {
  myCustomers?: boolean;
  grade?: string;
  dateRangeType?: string;
  quoteStatus?: string;
};

const InquiryBrowse: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const customerId = useAppSelector(selectCurrentCustomerId);
  const { searchCriteria, error } = useAppSelector(
    (state) => state.document.quote
  );
  const selectionData = useAppSelector(
    (state) => state.document.quote.quoteSelectionData
  );
  const ctx = useContext(PDFContext);

  const getBrowseCriteria = () : QuoteBrowseCriteria  => {
    const myCustomers = searchCriteria.myCustomers ?? false;
    const grade = searchCriteria.grade ?? "all";
    const dateRangeType = searchCriteria.dateRangeType ?? "last30Days"
    const quoteStatus = searchCriteria.quoteStatus ?? "all";

    const criteria = {myCustomers, grade, dateRangeType, quoteStatus};

    return criteria;
  };

  const getQueryCriteria = (browseCriteria: QuoteBrowseCriteria) : SearchCriteria => {
    const {myCustomers, grade, dateRangeType, quoteStatus} = browseCriteria;
    const criteria = {
      ...searchCriteria,
      myCustomers: myCustomers,
      grade: grade === "all" ? undefined : grade,
      dateRangeType: dateRangeType === "all" ? undefined : dateRangeType,
      quoteStatus: quoteStatus === "all" ? undefined : quoteStatus,
    };
    return criteria;
  };

  const browseCriteria = getBrowseCriteria();
  const {myCustomers, grade, dateRangeType, quoteStatus} = browseCriteria;
  const criteria = getQueryCriteria(browseCriteria);

  const search = (criteria: SearchCriteria) => {
    dispatch(updateQuoteSearchCriteria({ ...criteria, page: 1}));
    dispatch(fetchQuoteResults({customerId, criteria: { ...criteria, page: 1, pageSize: 25}}));
  };

  useEffect(() => {
    if (selectionData) {
      const criteria = getQueryCriteria(getBrowseCriteria());
      search(criteria);
    }
  }, [selectionData]);

  const onSelectionsChanged = (criteria: QuoteBrowseCriteria) => {
    // Close pdf if open
    if (ctx?.file) {
      ctx?.setFile(null);
    }
    search(criteria);
  };

  const productSelections = useAppSelector((state) => state.product.productSelections);
  const grades = productSelections?.gradeSelections.map((grade) => grade) ?? [];
  
  const gradeSelection = [
    { value: "all", label: "All" },
    ...grades.map((grade) => ({
      value: grade.grade,
      label: grade.grade,
    })),
  ];

  return (
    <>
      {error && (
        <Toast
          type="error"
          message={error}
          onConfirm={() => dispatch(clearDocumentErrors())}
        />
      )}
      <TabGroupSubGroup css={tw`mb-4`} title="Show">
        <Select
          minWidth="22ch"
          name="showBy"
          data={selectionData?.customerSearchType.map((item) => ({
            value: item.id,
            label: item.description,
          }))}
          onChange={(value: boolean) => {
            onSelectionsChanged({
              ...criteria,
              myCustomers: value,
            });
          }}
          value={myCustomers}
        />
        <p css={tw`mt-2`}>Grade Family</p>
        <Select
          minWidth="22ch"
          name="gradeType"
          data={gradeSelection}
          onChange={(value: string) => {
            onSelectionsChanged({
              ...criteria,
              grade: value === "all" ? undefined : value,
            });
          }}
          value={grade}
        />
      </TabGroupSubGroup>
      <TabGroupSubGroup css={tw`mb-4`} title="By Inquiry Date">
        {selectionData?.dateSelections.map((item) => (
          <ButtonSelect
            key={item.id}
            isActive={item.id === dateRangeType}
            onClick={() => {
              onSelectionsChanged({
                ...criteria,
                dateRangeType: item.id,
              });
            }}
          >
            {item.description}
          </ButtonSelect>
        ))}
      </TabGroupSubGroup>
      <TabGroupSubGroup title="By Inquiry Status">
        <ButtonSelect
          isActive={quoteStatus === "all"}
          onClick={() => {
            onSelectionsChanged({
              ...criteria,
              quoteStatus: undefined,
            });
          }}
        >
          All
        </ButtonSelect>
        {selectionData?.quoteStatuses.map((item) => (
          <ButtonSelect
            key={item.id}
            isActive={item.id === quoteStatus}
            onClick={() => {
              onSelectionsChanged({
                ...criteria,
                quoteStatus: item.id,
              });
            }}
          >
            {item.description}
          </ButtonSelect>
        ))}
      </TabGroupSubGroup>
    </>
  );
};

export default InquiryBrowse;
