/** @jsxImportSource @emotion/react */
import React, { ReactNode, useEffect, useState } from "react";
import tw from "twin.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSquareMinus,
  faSquarePlus,
} from "@fortawesome/free-regular-svg-icons";
import Totals from "./Totals";
import {
  ReadyToReleaseShipToTotals,
  SearchCriteria,
} from "../../../../types/types";
import ReleaseOrdersDetails from "./ReleaseOrdersDetails";
import truck from "../../../../assets/images/icon_truck.png";
import barge from "../../../../assets/images/icon_barge.png";
import rail from "../../../../assets/images/icon_rail.png";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { setReleaseOrderShipToIdToExpand } from "../../../../store/shipping.reducer";

type Props = {
  children?: ReactNode;
  totals: ReadyToReleaseShipToTotals;
  currentSort: Pick<SearchCriteria, "sort" | "dir">;
};

const Order: React.FC<Props> = ({ totals, currentSort }) => {
  const dispatch = useAppDispatch();
  const { shipToIdToExpand } = useAppSelector(
    (state) => state.shipping.releaseOrder
  );
  const [isExpanded, setIsExpanded] = useState(
    totals.shipToId === shipToIdToExpand ? true : false
  );

  const clickHandler = () => {
    setIsExpanded((prev) => !prev);
  };

  let icon;
  switch (totals.deliveryMode) {
    case "Truck":
      icon = truck;
      break;
    case "Barge":
      icon = barge;
      break;
    case "Rail":
      icon = rail;
      break;
  }

  useEffect(() => {
    if (totals.shipToId === shipToIdToExpand) {
      dispatch(setReleaseOrderShipToIdToExpand(undefined));
    }
  }, []);

  return (
    <div css={tw`border border-b-[#00000066]`}>
      <button css={tw`w-full`} type="button" onClick={clickHandler}>
        <div css={tw`flex gap-4 py-1 items-center`}>
          <FontAwesomeIcon
            css={tw`mt-1 ml-1`}
            icon={isExpanded ? faSquareMinus : faSquarePlus}
          />
          <div css={tw`font-bold text-nucor-gray uppercase flex-1 text-left`}>
            <p>{`${totals.shipToId} - ${totals.shipName}`}</p>
            <p>{`${totals.shipCity}, ${totals.shipState}`}</p>
          </div>
          <div css={tw`flex h-full items-end`}>
            <img css={tw`h-3`} src={icon} />
          </div>
          <Totals siteTotals={totals.siteTotals} css={tw`w-2/5`} />
        </div>
      </button>
      {isExpanded && (
        <ReleaseOrdersDetails
          currentSort={currentSort}
          totals={totals}
        />
      )}
    </div>
  );
};

export default Order;
