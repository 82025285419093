/** @jsxImportSource @emotion/react */
import React, { ReactNode, useState } from "react";
import DocumentSearchLeft from "./DocumentSearchLeft";
import DocumentSearchContent from "./DocumentSearchContent";
import tw from "twin.macro";
import { PDFFile } from "../../../../types/types";

type Props = {
  children?: ReactNode;
};

const DocumentSearch: React.FC<Props> = () => {
  const [file, setFile] = useState<PDFFile | null>(null);

  return (
    <div css={[tw`flex p-2 w-full`]}>
      <div css={[tw`w-[220px] px-1`]}>
        <DocumentSearchLeft setFile={setFile} />
      </div>
      <div css={[tw`flex-grow px-1`]}>
        <DocumentSearchContent file={file} setFile={setFile} />
      </div>
    </div>
  );
};

export default DocumentSearch;
