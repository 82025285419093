/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import tw from "twin.macro";

type Props = {
  children?: ReactNode;
};

const Disclaimer: React.FC<Props> = () => {
  return (
    <>
      <p css={tw`mt-6`}>
        All orders submitted with a purchase order are not considered firm or
        binding until NUCOR TUBULAR PRODUCTS INC. acknowledges with a sales
        order number. All inventories are subject to prior sale. Mill promise
        dates are subject to available capacity and raw material constraints.
        Please add one week to those dates for delivery. All quoted prices are
        price in effect at time of shipment unless otherwise noted. All orders
        are subject to the{" "}
        <a
          target="_blank"
          rel="noreferrer"
          css={tw`text-nucor-link hover:text-nucor-link-hover font-bold focus-visible:text-nucor-link-hover focus-visible:outline-none`}
          href="/terms"
        >
          terms and conditions
        </a>{" "}
        of sale
      </p>
      <p css={tw`mt-4`}>
        Prices are subject to change without any prior notice and billing will
        be made at our mill price basis and applicable extras in effect at the
        time of the shipment. Billing will also include where applicable the
        actual transportation charges and fuel surcharges on items priced at
        destination.
      </p>
    </>
  );
};

export default Disclaimer;
