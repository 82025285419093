import React, { ReactNode } from "react";
import InquiryBrowse from "./InquiryBrowse";
import InquirySearch from "./InquirySearch";
import BrowseSearch from "../BrowseSearch";

type Props = {
  children?: ReactNode;
};

const InquiryLeft: React.FC<Props> = () => {

  return (
    <BrowseSearch
      browse={<InquiryBrowse />}
      search={<InquirySearch />}
    />
  );
};

export default InquiryLeft;
