/** @jsxImportSource @emotion/react */
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import tw from "twin.macro";
import { dateToString, stringToDate } from "../../helpers/dateHelpers";
import { DateString } from "../../types/types";
import Button from "../../components/atoms/Button";
import DatePicker from "../../components/atoms/DatePicker";

type Props = {
  children?: ReactNode;
  onCancel: () => void;
  onConfirm: (newDueDate: DateString) => void;
};

const ChangeDueDates: React.FC<Props> = ({
  onCancel,
  onConfirm,
  ...remainingProps
}) => {
  const closeRef = useRef<HTMLButtonElement>(null);
  const [selectedDate, setSelectedDate] = useState<DateString>();

  useEffect(() => {
    closeRef.current?.focus();
  }, []);

  return createPortal(
    <div
      onClick={onCancel}
      css={[
        tw`fixed top-0 left-0 w-full h-full flex justify-center items-center bg-[#cccccc77] z-[100]`,
      ]}
      {...remainingProps}
    >
      <div
        css={[tw`bg-nucor-green p-[7px] text-xs`]}
        onClick={(e) => e.stopPropagation()}
      >
        <div css={tw`flex justify-between`}>
          <h2 css={tw`font-bold text-white pb-1 text-xs flex-1`}>
            Change Due Date
          </h2>
          <button
            ref={closeRef}
            type="button"
            onClick={onCancel}
            css={tw`text-nucor-green bg-[#ccc] h-[13px] w-[13px] flex justify-center items-center font-bold mt-px`}
          >
            &times;
          </button>
        </div>
        <div css={tw`bg-white p-1`}>
          <div css={tw`flex justify-center my-1`}>
            <DatePicker
              name="selectedDate"
              value={stringToDate(selectedDate)}
              onChange={(date) => setSelectedDate(dateToString(date))}
            />
          </div>
          <div css={tw`text-center mt-2 mb-1`}>
            <Button
              css={tw`m-0 text-xs font-normal py-[3px] mr-4`}
              type="button"
              onClick={() => {
                if (!selectedDate) {
                  onCancel();
                } else {
                  onConfirm(selectedDate);
                }
              }}
            >
              Submit
            </Button>
            <Button
              css={tw`m-0 text-xs font-normal py-[3px]`}
              type="button"
              onClick={onCancel}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>,
    document.getElementById("modal") as HTMLElement
  );
};

export default ChangeDueDates;
