import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  fetchUser,
  getUserPreferences,
  updateDefaultPriceUnit,
  updateUserInfo,
  updateUserPreferences,
} from "./user.actions";
import {
  AccessControl,
  CurrentContext,
  ErrorResponse,
  RequestStatus,
  User,
  UserPreferences,
} from "../types/types";
import { RootState } from "./store";

type InitialState = {
  loading: boolean;
  currentContext: CurrentContext | null;
  error?: string | ErrorResponse;
  token: string | null;
  showLogoutDialog: boolean;
  personalInfo: {
    status: RequestStatus | "saving" | "loading" | "saved";
    error?: ErrorResponse | string;
    editableUser?: User;
  };
};

const initialState: InitialState = {
  loading: false,
  currentContext: null,
  error: undefined,
  token: "",
  showLogoutDialog: false,
  personalInfo: {
    status: "idle",
  },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetPersonalInfoState: (state) => {
      state.personalInfo.error = undefined;
      state.personalInfo.status = "idle";
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setShowLogoutDialog: (state, action: PayloadAction<boolean>) => {
      state.showLogoutDialog = action.payload;
    },
    setAccessControl: (state, action: PayloadAction<AccessControl>) => {
      if (!state.currentContext) return;
      state.currentContext.accessControl = action.payload;
    },
    setShowOddLengths: (state, action: PayloadAction<boolean>) => {
      if (state.currentContext?.userPreferences) {
        state.currentContext.userPreferences.showOddLengths = action.payload;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchUser.fulfilled,
      (state, action: PayloadAction<CurrentContext>) => {
        state.loading = false;
        state.currentContext = action.payload;
        state.personalInfo.editableUser = action.payload.user;
        state.error = undefined;
      }
    );
    builder.addCase(fetchUser.rejected, (state, action) => {
      state.loading = false;
      state.currentContext = null;
      state.error =
        action.payload || action.error.message || "Something went wrong";
    });
    builder.addCase(getUserPreferences.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getUserPreferences.fulfilled,
      (state, action: PayloadAction<UserPreferences>) => {
        state.loading = false;
        if (!action.payload || !state.currentContext) return;
        state.currentContext.userPreferences = action.payload;
        state.error = "";
      }
    );
    builder.addCase(getUserPreferences.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Error";
    });
    builder.addCase(updateUserPreferences.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      updateUserPreferences.fulfilled,
      (state, action: PayloadAction<UserPreferences>) => {
        state.loading = false;
        if (!action.payload || !state.currentContext) return;
        state.currentContext.userPreferences = action.payload;
        state.error = "";
      }
    );
    builder.addCase(updateUserPreferences.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Error";
    });
    builder.addCase(updateUserInfo.pending, (state) => {
      state.personalInfo.status = "saving";
      state.personalInfo.error = undefined;
    });
    builder.addCase(
      updateUserInfo.fulfilled,
      (state, action: PayloadAction<User>) => {
        state.personalInfo.status = "saved";
        if (!state.currentContext) return;
        state.personalInfo.editableUser = action.payload;
        state.currentContext.user = action.payload;
        state.personalInfo.error = undefined;
      }
    );
    builder.addCase(updateUserInfo.rejected, (state, action) => {
      state.personalInfo.status = "error";
      state.personalInfo.error = action.payload;
    });
    builder.addCase(updateDefaultPriceUnit.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      updateDefaultPriceUnit.fulfilled,
      (state, action: PayloadAction<UserPreferences>) => {
        state.loading = false;
        if (!state.currentContext) return;
        state.currentContext.userPreferences = action.payload;
        state.error = undefined;
      }
    );
    builder.addCase(updateDefaultPriceUnit.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default userSlice.reducer;
export const {
  setToken,
  setShowLogoutDialog,
  setAccessControl,
  resetPersonalInfoState,
  setShowOddLengths,
} = userSlice.actions;

export const selectDefaultPriceUnit = (state: RootState) =>
  state.user.currentContext?.userPreferences?.defaultPriceUnit === "CFT"
    ? "CFT"
    : "CWT";

export const selectDefaultMill = (state: RootState) =>
  state.user.currentContext?.userPreferences?.defaultMill ?? "ALL";
export const selectShowOddLengths = (state: RootState) =>
  state.user.currentContext?.userPreferences?.showOddLengths ?? false;

export const selectCurrentUserAuthorities = (state: RootState) =>
  state.user.currentContext?.user.authorities;

export const selectCurrentAuthorities = createSelector(
  [(state: RootState) => state.user.currentContext?.user.authorities],
  (authorities) => {
    return authorities ?? [];
  }
);
